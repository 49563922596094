import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShiftsService } from '../../../shared/services/shifts.service';
import { AuthConst } from '../../../shared/services/const/authConst';

@Component({
  selector: 'app-shift-note',
  templateUrl: './shift-note.component.html',
  styleUrls: ['./shift-note.component.scss'],
})
export class ShiftNoteComponent implements OnInit {
  undisclosed: string | undefined;
  disclosed: string | undefined;
  hide = false;
  overWrite = false;

  constructor(
    public dialogRef: MatDialogRef<ShiftNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shiftsService: ShiftsService
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem(AuthConst.userRole)) {
      if (localStorage.getItem(AuthConst.userRole) == 'ROLE_COORDINATOR') {
        this.hide = true;
      }
    }
    if (this.data.toSave) {
      this.undisclosed = this.data.person.description;
      this.disclosed = this.data.person.employerNote;
    }
  }

  updateNote(): void {
    if (this.data.toSave) {
      if (this.undisclosed === '') {
        this.undisclosed = null;
      }

      if (this.disclosed === '') {
        this.disclosed = null;
      }

      const changes = {
        id: this.data.person.id,
        employerNote: this.disclosed,
        description: this.undisclosed,
      };

      this.shiftsService.updateNotes(changes).subscribe(() => {
        this.dialogRef.close(changes);
      });
    } else {
      if (this.data.showDisclosed) {
        const obj = {overwrite: this.overWrite, text: this.disclosed};
        this.dialogRef.close(obj);
      } else if (this.data.showUndisclosed) {
        const obj = {overwrite: this.overWrite, text: this.undisclosed};
        this.dialogRef.close(obj);
      }
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
