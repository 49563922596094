<section class="container">
  <form class="login" [formGroup]="logInForm">
    <img class="login__image" src="./assets/images/login-only-logo.png"/>
    <div class="login__box">
      <div class="login__title">{{'forgotPassword' | translate}}</div>
      <mat-form-field *ngIf="!changeInput" class="login__form-field"
                      style="margin-bottom: 21px">
        <mat-label class="login__label">{{'passwordLabelPass' | translate}}</mat-label>
        <input (keyup.enter)="send()" class="login__input" formControlName="email" matInput #emailText>
      </mat-form-field>
      <button *ngIf="!changeInput" type="button" class="button login__button"
              (click)="send()">{{'send' | translate}}</button>
    </div>
  </form>
  <div class="text">{{'loginText' | translate}}</div>

  <div class="version">
    V {{version}}
  </div>
</section>
