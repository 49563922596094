export interface Theme {
  name: string;
  properties: any;
}

export const main: Theme = {
  name: 'main',
  properties: {
    '--background--primary-solid': '#2c3e50',
    '--background-login': '#f4f4f4',
    '--white': '#fff',
    '--blue': '#3498d8',
    '--login-border': '#e4e5e6',
    '--text-black': '#333333',
    '--gray-text': '#929090',
    '--details-border': '#dddddd',
    '--message--text': '#c2c2c2',
    '--notification--box': '#e67e22',
    '--red': '#e74c3c',
    '--green': '#2ecc71',
    '--green-light': '#16A085',
    '--login-first': '#577288',
    '--login-second': '#2c3e50',
    '--orange': '#e67e22',
    '--yellow': '#FFD532',
    '--text-red': '#E74C3C',
    '--toggle-border': '#dddddd',
    '--disabled-text': '#C2C2C2',
    '--checkMark': '#3498D8',

    ////
    '--foreground-default': '#08090A',
    '--foreground-secondary': '#41474D',
    '--foreground-tertiary': '#797C80',
    '--foreground-quaternary': '#F4FAFF',
    '--foreground-light': '#41474D',

    '--background-secondary': '#A3B9CC',
    '--background-tertiary': '#5C7D99',
    '--background-light': '#FFFFFF',

    '--primary-default': '#5DFDCB',
    '--primary-dark': '#24B286',
    '--primary-light': '#B2FFE7',

    '--error-default': '#EF3E36',
    '--error-dark': '#800600',
    '--error-light': '#FFCECC',
    '--blue-primary': '#216996',
    '--background-tertiary-shadow': '0 1px 3px 0 rgba(92, 125, 153, 0.5)',
  },
};
