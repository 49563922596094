import { Component } from '@angular/core';
import { ThemeService } from './shared/services/theme.service';
import { main } from './theme/theme';
import { TranslateService } from '@ngx-translate/core';
import {MessagesService} from "./shared/services/messages.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  messages$: any;

  constructor(
    private theme: ThemeService,
    private translate: TranslateService,
    private messagesService: MessagesService
  ) {
    localStorage.removeItem('savedImages');
    this.theme.setActiveTheme(main);
    this.messages$ = this.messagesService.getAllConversations();
  }
}
