import {Component, OnDestroy, OnInit} from '@angular/core';
import { MessagesService } from '../../shared/services/messages.service';
import { Observable } from 'rxjs';
import { Conversations } from '../../shared/models/conversations.model';
import { AuthConst } from '../../shared/services/const/authConst';
import { HelperService } from '../../shared/services/helper.service';
import { EmployeesService } from '../../shared/services/employees.service';
import { DomSanitizer } from '@angular/platform-browser';
import {Router} from "@angular/router";
import {AppComponent} from "../../app.component";
import {group} from "@angular/animations";
import {UserService} from "../../shared/services/user.service";

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit, OnDestroy {
  messages$: Observable<Conversations> | undefined;
  messages: any[] = [];
  showChat = false;
  unreadMessage: boolean | undefined;
  chatId: number | undefined;
  loading = false;
  singleChat: number;
  groupId = 0;
  canSendMessages = true;
  messageSubscription: any;

  constructor(
    private messagesService: MessagesService,
    private helper: HelperService,
    private employeesService: EmployeesService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private main: AppComponent,
    private userService: UserService
  ) {}


  ngOnInit(): void {
    this.userService
      .canUserSendMessage(Number(localStorage.getItem(AuthConst.gruopId)))
      .subscribe((data) => {
        this.canSendMessages = data.cenSend;
      });
    this.messagesService.updateLastMessage.subscribe((data: any) => {
      if (data) {
        const index = this.messages.findIndex((m: any) => {
          return m.id == data.messageThreadId;
        });

        if (index != -1) {
          this.messages[index].headerMessage = data.message.message;
          if (data.message.iAmSender) {
            this.messages[index].headerMessage = "Du: " + data.message.message;
          } else {
            this.messages[index].headerMessage = data.message.message;
          }
        }
        this.setMessagesToLocaleStorage(this.groupId, this.messages);
      }
    });
    if (localStorage.getItem('messages')) {
      const messagesForAll: any[] = JSON.parse(localStorage.getItem('messages'));
      messagesForAll.forEach((data: any) => {
        if (data.groupId == Number(localStorage.getItem(AuthConst.gruopId))) {
          this.messages = data.messages;
        }
      });

    }
    localStorage.removeItem(AuthConst.scheduleDate);
    this.messages$ = this.main.messages$;
    this.helper.clickedSection.subscribe((page) => {
      if (page && page == '/conversations') {
        this.showChat = false;
        this.groupId = Number(localStorage.getItem('gruopId'));
        this.loading = true;
        this.messages$ = this.messagesService.getAllConversations();
        // @ts-ignore
        this.messageSubscription = this.messages$.subscribe((x: any[]) => {
          // @ts-ignore
          if (x.length > 0) {
            const groupIndex = x.findIndex((m) => m.groupId == this.groupId);
            if (groupIndex != -1) {
              // @ts-ignore
              this.messages = x[groupIndex]?.messageThreads.group;
              // @ts-ignore
              this.setMessagesToLocaleStorage(this.groupId, this.messages);

              let index = 0;
              // @ts-ignore
              x[0].messageThreads.group.forEach(
                (msg: { headerStatus: string }) => {
                  if (msg.headerStatus === 'READ') {
                    this.unreadMessage = false;
                  } else {
                    this.unreadMessage = true;
                  }
                }
              );
            }
          }
          this.loading = false;
        });
      }
    });

    this.groupId = Number(localStorage.getItem('gruopId'));
    this.loading = true;
    this.messages$ = this.messagesService.getAllConversations();
    // @ts-ignore
    this.messageSubscription = this.messages$.subscribe((x: any[]) => {
      // @ts-ignore
      if (x.length > 0) {
        const groupIndex = x.findIndex((m) => m.groupId == this.groupId);
        if (groupIndex != -1) {
          // @ts-ignore
          this.messages = x[groupIndex]?.messageThreads.group;

          let index = 0;
          // @ts-ignore
          x[0].messageThreads.group.forEach((msg: { headerStatus: string }) => {
            if (msg.headerStatus === 'READ') {
              this.unreadMessage = false;
            } else {
              this.unreadMessage = true;
            }
          });
        }
      }
      this.loading = false;
    });
  }

  setMessagesToLocaleStorage(groupId: any, messages: any): void {
    const obj = {
      groupId, messages
    };

    let existingMessages: any = localStorage.getItem('messages');
    if (existingMessages) {
      existingMessages = JSON.parse(existingMessages);
      const groupIndex = existingMessages.findIndex((m: any) => {
        return m.groupId == groupId;
      });

      if (groupIndex == -1) {
        existingMessages.push(obj);
      } else {
        existingMessages[groupIndex] = obj;
      }

    }  else {
      existingMessages = [obj];
    }

    localStorage.setItem("messages", JSON.stringify(existingMessages));
  }

  /**
   *
   * Get ID of user by clicking on his tab
   */
  showChatBox(chat: any): void {
    this.singleChat = chat.id;
    this.chatId = chat.id;
    this.setMessagesToLocaleStorage(this.groupId, this.messages);
    localStorage.setItem(AuthConst.messageChat, this.chatId.toString());
    this.messagesService.messagesSubject.next(this.chatId);
    this.messagesService.markThreadAsRead(chat.id).subscribe();
    chat.headerStatus = 'READ';
    this.helper.refreshChatCount.next('none');
    this.setMessagesToLocaleStorage(this.groupId, this.messages);
    if (this.chatId) {
      setTimeout(() => {
        this.showChat = true;
      }, 100);
    }
  }

  openNewMessages(): any {
    if (this.canSendMessages === false) {
      return false;
    }
    this.router.navigate(['/conversations/create']);
    this.helper.resetChatSubscriptions.next(true);
  }

  /**
   *
   *  Click outside directive
   */
  shouldCloseChat(): void {
    if (this.showChat) {
      this.showChat = false;
    }
  }

  isVisibleLine(message: any): boolean {
    const index = this.messages.findIndex((m: any) => {
      return m.id == message.id;
    });

    if (index == -1 ) {
      return false;
    }

    if (this.messages[index].id == this.singleChat || this.messages[index + 1]?.id == this.singleChat) {
      return true;
    } else {
      return false;
    }

  }

  isFirst(): boolean {
    if (this.singleChat) {
      const index = this.messages.findIndex((m: any) => m.id == this.singleChat);

      if (index == 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }


  isLast(): boolean {
    if (this.singleChat) {
      const index = this.messages.findIndex((m: any) => m.id == this.singleChat);

      if (index == this.messages.length - 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.messageSubscription?.unsubscribe();
  }

}
