import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {InlineSVGModule} from 'ng-inline-svg';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {MaterialModule} from './material/material.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MainComponent} from './components/main/main.component';
import {PersonalComponent} from './components/personal/personal.component';
import {LoginComponent} from './components/login/login.component';
import {RequestInterceptor} from './shared/interceptor';
import {ClickOutsideDirective} from './shared/directives/click-outside.directive';
import {UserDetailsUpdateComponent} from './components/user-details-update/user-details-update.component';
import {MessagesComponent} from './components/messages/messages.component';
import {CustomDatePipe} from './shared/pipes/custom-date.pipe';
import {DatePipe} from '@angular/common';
import {ConvertDate} from './shared/pipes/convert-date.pipe';
import {CreateShiftDatePipeDate} from './shared/pipes/create-shift-date.pipe';
import {ScrollToBottomDirective} from './shared/directives/scroll-to-bottom.directive';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {RecipientsComponent} from './components/recipients/recipients.component';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {FireBaseService} from './shared/services/fire-base.service';
import {UserDetailsComponent} from './components/user-details/user-details.component';
import {ActivityLogsListComponent} from './components/activityLogs/activity-logs-list/activity-logs-list.component';
import {LoadingComponent} from './components/loading/loading.component';
import {CreateNewUserComponent} from './components/create-new-user/create-new-user.component';
import {ConfirmDialogComponent} from './components/modals/confirm-dialog/confirm-dialog.component';
import {TooltipShiftsModalsComponent} from './components/modals/tooltip-shifts-modals/tooltip-shifts-modals.component';
import {CancelTooltipModalComponent} from './components/modals/cancel-tooltip-modal/cancel-tooltip-modal.component';
import {ShiftDeteilsComponent} from './components/modals/shift-deteils/shift-deteils.component';
import {ChatComponent} from './components/chat/chat.component';
import {ChatModalComponent} from './components/modals/chat-modal/chat-modal.component';
import {ActivityLogsComponent} from './components/activityLogs/activity-logs/activity-logs.component';
import {SwapDialogComponent} from './components/activityLogs/swap-dialog/swap-dialog.component';
import {CreateUserComponent} from './components/modals/create-user/create-user.component';
import {WrongPasswordComponent} from './components/modals/wrong-password/wrong-password.component';
import {ScheduleComponent} from './components/schedule/schedule.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ShiftNoteComponent} from './components/modals/shift-note/shift-note.component';
import {CreateNewShiftComponent} from './components/create-new-shift/create-new-shift.component';
import {CalendarComponent} from './calendar/calendar.component';
import {AddAnnouncementComponent} from './components/modals/add-announcement/add-announcement.component';
import {UserDatailsContainerComponent} from './components/user-datails-container/user-datails-container.component';
import {UserDetailsModalComponent} from './components/modals/user-details-modal/user-details-modal.component';
import {PersonalGroupComponent} from './components/modals/personal-group/personal-group.component';
import {AbsenceModalComponent} from './components/modals/absence-modal/absence-modal.component';
import {CreateCustomShiftComponent} from './components/create-custom-shift/create-custom-shift.component';
import {UserCalendarComponent} from './user-calendar/user-calendar.component';
import {ShiftTypesComponent} from './components/shift-types/shift-types.component';
import {CreateNewShiftDetailsComponent} from './components/create-new-shift-details/create-new-shift-details.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {CreateShiftTypeModalComponent} from './components/modals/create-shift-type-modal/create-shift-type-modal.component';
import {GroupsComponent} from './groups/groups.component';
import {CreateGroupComponent} from './create-group/create-group.component';
import {SendMessageComponent} from './send-message/send-message.component';
import {DeleteGroupComponent} from './delete-group/delete-group.component';
import {ManageApplicationComponent} from './components/manage-application/manage-application.component';
import {SendMessagePersonalComponent} from './send-message-personal/send-message-personal.component';
import {DeletePersonalComponent} from './delete-personal/delete-personal.component';
import {AddToGroupComponent} from './add-to-group/add-to-group.component';
import {ChangeStatusComponent} from './change-status/change-status.component';
import {UpdateGroupComponent} from './update-group/update-group.component';
import {ShiftDetailsComponent} from './components/shift-details/shift-details.component';
import {ShiftCalendarComponent} from './components/shift-calendar/shift-calendar.component';
import {CreateNewShiftModalComponent} from './components/modals/create-new-shift-modal/create-new-shift-modal.component';
import {ConvertDateMonths} from './shared/pipes/convert-date-months.pipe';
import {TempUserComponent} from './components/modals/temp-user/temp-user.component';
import {LogOutComponent} from './components/modals/log-out/log-out.component';
import {UserCalendarOnlyComponent} from './components/user-calendar-only/user-calendar-only.component';
import {MatRadioModule} from '@angular/material/radio';
import {RemovedPersonalFromGroupComponent} from './removed-personal-from-group/removed-personal-from-group.component';
import {DetailsShiftComponent} from './details-shift/details-shift.component';
import {CustomDateAdapter} from './custom.date.adapter';
import {DateAdapter} from '@angular/material/core';
import {MiniLoaderComponent} from './mini-loader/mini-loader.component';
import {SendPasswordModelComponent} from './send-password-model/send-password-model.component';
import {HttpClientService} from './shared/services/http-client.service';
import {CacheService} from './shared/services/cache.service';
import {LOCALE_ID} from '@angular/core';
import '@angular/common/locales/global/sv';
import {ShiftDatePipe} from './shared/pipes/shiftTime.pipe';
import {ActivityDatePipe} from './shared/pipes/activity-logs-date.pipe';
import {ConvertMonthsPipe} from './shared/pipes/convert-monts.pipe';
import { ConvertDateOnlyPipe } from './shared/pipes/convert-date-only.pipe';
import { WrongRolePopUpComponent } from './wrong-role-pop-up/wrong-role-pop-up.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';


export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    PersonalComponent,
    LoginComponent,
    UserDetailsComponent,
    ClickOutsideDirective,
    UserDetailsUpdateComponent,
    MessagesComponent,
    CustomDatePipe,
    ConvertDate,
    ShiftDatePipe,
    ActivityDatePipe,
    ConvertMonthsPipe,
    CreateShiftDatePipeDate,
    ScrollToBottomDirective,
    RecipientsComponent,
    ActivityLogsComponent,
    ActivityLogsListComponent,
    SwapDialogComponent,
    LoadingComponent,
    WrongPasswordComponent,
    ScheduleComponent,
    CreateUserComponent,
    CreateNewUserComponent,
    ConfirmDialogComponent,
    TooltipShiftsModalsComponent,
    CancelTooltipModalComponent,
    ShiftDeteilsComponent,
    ChatComponent,
    ChatModalComponent,
    ShiftNoteComponent,
    CreateNewShiftComponent,
    DashboardComponent,
    CalendarComponent,
    AddAnnouncementComponent,
    UserDatailsContainerComponent,
    UserDetailsModalComponent,
    PersonalGroupComponent,
    AbsenceModalComponent,
    UserCalendarComponent,
    CreateCustomShiftComponent,
    ShiftTypesComponent,
    CreateNewShiftDetailsComponent,
    CreateShiftTypeModalComponent,
    GroupsComponent,
    CreateGroupComponent,
    SendMessageComponent,
    DeleteGroupComponent,
    ManageApplicationComponent,
    CreateNewShiftModalComponent,
    ManageApplicationComponent,
    DeleteGroupComponent,
    SendMessagePersonalComponent,
    DeletePersonalComponent,
    AddToGroupComponent,
    ChangeStatusComponent,
    UpdateGroupComponent,
    ShiftDetailsComponent,
    ShiftCalendarComponent,
    TempUserComponent,
    ShiftCalendarComponent,
    ConvertDateMonths,
    TempUserComponent,
    LogOutComponent,
    UserCalendarOnlyComponent,
    RemovedPersonalFromGroupComponent,
    DetailsShiftComponent,
    MiniLoaderComponent,
    SendPasswordModelComponent,
    ConvertDateOnlyPipe,
    WrongRolePopUpComponent,
    ForgottenPasswordComponent,
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    InfiniteScrollModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    MatDialogModule,
    MatSnackBarModule,
    NgxMaterialTimepickerModule,
    AngularFireModule.initializeApp(environment.firebase),
    InlineSVGModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'sv',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatIconModule,
    MatRadioModule,
  ],
  providers: [
    FireBaseService,
    HttpClientService,
    CacheService,
    DatePipe,
    ManageApplicationComponent,
    ActivityLogsListComponent,
    ShiftDatePipe,
    ActivityDatePipe,
    ConvertMonthsPipe,
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: LOCALE_ID, useValue: 'sv'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
  ],
  entryComponents: [SwapDialogComponent,
    DeleteGroupComponent,
    SendMessageComponent,
    CreateGroupComponent,
    SendMessagePersonalComponent,
    DeletePersonalComponent,
    AddToGroupComponent,
    ChangeStatusComponent,
    UpdateGroupComponent,
    RemovedPersonalFromGroupComponent,
    DetailsShiftComponent,
    ShiftDetailsComponent,
    SwapDialogComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

