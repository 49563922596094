<section class="dialog" [ngStyle]=" allShifts.length > 5 ? {'height': '315px'} :
       {'height': '220px'}">
  <img class="dialog__image" src="../../../../assets/images/Close.png" (click)="closeModal()" alt=""/>
  <div class="dialog__title">{{ data.title }}</div>
  <div class="dialog__shifts-box">
    <div class="dialog__shifts" *ngFor="let shift of allShifts">
      <div class="dialog__shift">
        <div class="dialog__color-box">
          <div class="dialog__color" *ngIf="shift?.color" [style.backgroundColor]="getPositionColor(shift)"></div>
        </div>
        <div class="dialog__text">{{setActivity}} ({{setActivityShort}})</div>
        <div class="dialog__margin">{{shift.start}} - {{shift.end}}</div>
        <div class="dialog__text" *ngIf="getTime(shift)">
          <div class="dialog__date">{{shiftDate | CreateShiftDate}}</div>
        </div>
      </div>
      <div class=" dialog__button-box
          ">
        <button class="button button--primary" style="margin-right: 10px; width: 100px"
                (click)="closeModal()">{{'cancelButton' | translate}}
        </button>
        <button class="button button--blue" style="width: 100px"
                (click)="createShift()">{{'confirm' | translate}}</button>
      </div>
    </div>
  </div>
</section>
