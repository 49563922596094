import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {LoginModel} from '../../shared/models/login.model';
import {AuthService} from '../../shared/services/auth.service';
import {FireBaseService} from '../../shared/services/fire-base.service';
import {UserService} from '../../shared/services/user.service';
import {AuthConst} from '../../shared/services/const/authConst';
import {HelperService} from '../../shared/services/helper.service';
import {version} from '../../../../package.json';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  logInForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: [''],
  });
  changeInput = false;
  err = false;
  errorEmail: string | undefined;
  usersRole = '';
  groups: any[] = [];
  version: string = version;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private fireBase: FireBaseService,
    private userService: UserService,
    private helper: HelperService
  ) {
  }

  //

  ngOnInit(): void {
    console.log("START LOGIN");
  }

  changeInputs(): void {
    if (this.logInForm.controls.email.valid) {
      const check = {
        langType: 'LANG_SV',
        password: '',
        userRole: 'EMPLOYER',
        username: this.logInForm.value.email,
      };
      this.authService.checkEmail(check).subscribe(
        (data) => {
          this.changeInput = true;

          const roles = data.userAuthorities?.roles;

          if (roles) {
            if (roles.EMPLOYER) {
              this.usersRole = "ROLE_EMPLOYER";
            } else {
              if (roles.COORDINATOR) {
                this.usersRole = "ROLE_COORDINATOR";
              } else {
                if (roles.ROSTER) {
                  this.usersRole = "ROLE_ROSTER";
                }

              }
            }

          }
        },
        (error) => {
          console.log(error);
          this.err = true;
          if (error.status == 411) {
            this.errorEmail = "För många misslyckade inloggningsförsök. Din IP-adress har temporärt blockerats. Vänligen försök igen om 5 minuter.";
          } else {
            this.errorEmail = this.logInForm.value.email;
          }
        }
      );
    }
  }

  findRole(role: any): string {
    if (role === 'ROLE_COORDINATOR') {
      return 'COORDINATOR';
    } else if (role === 'ROLE_EMPLOYER') {
      return 'EMPLOYER';
    } else if (role === 'ROLE_ROSTER') {
      return 'ROSTER';
    } else {
      return '';
    }
  }

  login(): void {
    const user: LoginModel = {
      langType: 'LANG_EN',
      userRole: this.findRole(this.usersRole),
      password: this.logInForm?.value.password,
      username: this.logInForm?.value.email,
      token: '',
    };

    this.authService.login(user).subscribe(
      (response) => {
        localStorage.setItem(AuthConst.token, response.token);
        this.userService.getLoggedInUser().subscribe((loggedUser) => {
          localStorage.setItem(AuthConst.userId, loggedUser.id.toString());
          localStorage.setItem('gapUserRole', this.findRole(this.usersRole));
          // @ts-ignore
          localStorage.setItem('changeWorkSiteDate', new Date());
          loggedUser.userAuthorities?.roles?.EMPLOYER?.forEach((g) => {
            g.role = 'ROLE_EMPLOYER';
            this.groups.push(g);

            this.groups.sort(
              (name1: { groupTitle: string }, name2: { groupTitle: string }) =>
                name1.groupTitle !== name2.groupTitle
                  ? name1.groupTitle < name2.groupTitle
                  ? -1
                  : 1
                  : 0
            );
          });
          loggedUser.userAuthorities?.roles?.COORDINATOR?.forEach((g) => {
            g.role = 'ROLE_COORDINATOR';
            this.groups.push(g);
          });
          loggedUser.userAuthorities?.roles?.ROSTER?.forEach((g) => {
            g.role = 'ROLE_ROSTER';
            this.groups.push(g);
          });

          const groupId = this.groups[0]?.groupId;

          if (groupId) {
            localStorage.setItem(AuthConst.gruopId, groupId.toString());
          }

          this.fireBase.requestPermission();
          this.fireBase.receiveMessage();
          if (this.findRole(this.usersRole) == 'ROSTER') {
            // @ts-ignore
            localStorage.setItem(AuthConst.scheduleDate, new Date());
            this.router.navigate(['/schedule']);
          } else {
            // @ts-ignore
            localStorage.setItem(AuthConst.scheduleDate, new Date());
            this.router.navigate(['/schedule']);
            const obj = {start: new Date()};
            this.helper.sendAssignment.next(obj);
          }

        });
      },
      () => {
        this.err = true;
        this.errorEmail = null;
      }
    );
  }

  closeModal(event: any): void {
    this.logInForm.reset();
    this.changeInput = false;
    this.err = false;
  }

  openSupport(): void {
    window.open("https://www.gapstaff.com/support", "_blank");
  }

  openForgottenPassword(): void {
    this.router.navigate(['/forgotten-password']);
  }
}
