<section>
  <section class="section-header">
    <div class="section-header__title-box">
      <div class="section-header__title noCapitalize">
        {{'createNewShift' | translate}}
      </div>
    </div>
    <div class="section-header__button-box">
      <button *ngIf="mode !== 4" class="button back-button button--primary header__button"
              (click)="backToSchedule()">{{'backButton' | translate}}</button>
      <button *ngIf="mode === 4" class="button button--primary header__button margin"
              (click)="getBack('')">{{'cancelButton' | translate}}</button>
      <button *ngIf="mode === 4" [disabled]="!changeColor" class="button back-button button--primary header__button"
              [class.button--blue]="changeColor"
              (click)="create()">{{'createNewShift' | translate}} <span *ngIf="changeColor">({{shiftNumber}})</span>
      </button>
    </div>
  </section>
  <section class="inner-container">
    <ng-template [ngIf]="mode === 0">
      <section class="main-details main-details--margin">
        <div class="main-details__user-detail">
          <div class="main-details__title">{{'shiftDetails' | translate}}
          </div>
        </div>
        <div class="main-details__info-box">
          <div class="main-details__info-title">{{'selectShiftType' | translate}}</div>
          <div class="main-details__info-subtitle">{{'selectShift' | translate}}</div>
        </div>
        <div class="main-details__select-box">
          <div class="input main-details__select-input form__input--arrow"
               (click)="showAllAssignment()" (clickOutside)="showAssignment = false"
          >
            <div *ngIf="AssignmentName === ''">{{'select' | translate}}</div>
            <div class="main-details__option">
              <div class="main-details__shift-color" [ngStyle]="{'background-color':AssignmentColor}"></div>
              <div class="main-details__shift-text">{{AssignmentName}}</div>
            </div>
          </div>
          <div class="dropdown" *ngIf="showAssignment">
            <div class="option" *ngFor="let assignment of sortAssignments(assignments)"
                 (click)="getAssignment(assignment)">
              <div class="main-details__option">
                <div *ngIf="assignment?.color" class="main-details__shift-color specificCircle"
                     [style.backgroundColor]="getPositionColor(assignment)"></div>
                <div class="main-details__shift-text"
                >{{assignment.name}} </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ng-template>

    <ng-template [ngIf]="mode === 1">
      <app-create-custom-shift [custom]="custom" (close)="getBack('')"></app-create-custom-shift>
    </ng-template>

    <ng-template [ngIf]="mode === 2">
      <app-create-custom-shift [new]="new" (close)="getBack('')"></app-create-custom-shift>
    </ng-template>

    <ng-template [ngIf]="mode === 3">
      <app-shift-types (close)="getBack('mode')"></app-shift-types>
    </ng-template>

    <ng-template [ngIf]="mode === 4">
      <app-create-new-shift-details [assignment]="createdAssignment" [customTwo]="custom"
                                    (close)="getBack('')">
      </app-create-new-shift-details>
    </ng-template>

    <app-user-calendar
      [hide]='hideCalendar' [assignment]="createdAssignment" *ngIf="!shiftType"
      style="margin-top: 20px">
    </app-user-calendar>
  </section>

  <app-manage-application *ngIf="mode === 5"></app-manage-application>
</section>
