<div class="calendar">
  <div class="date">
    <div class="change">
      <div (click)="previous()" style="cursor: pointer">
        <img src="assets/images/schedule/Arrow_Sort left.svg" alt="">
      </div>
      <h3  style="margin: 0 25px 16px 25px;">{{fullMonth | titlecase}} {{date.toDateString().slice(10, 16)}} </h3>
      <div style="cursor: pointer" (click)="next()">
        <img src="assets/images/schedule/Arrow_Sort right.svg" alt="">
      </div>
    </div>
    <table class="table" style="width:100%">
      <thead style="width:100%">
      <tr style="width:100%; display:flex; justify-content: space-between">
        <th scope="col">Mon</th>
        <th scope="col">Tue</th>
        <th scope="col">Wed</th>
        <th scope="col">Thu</th>
        <th scope="col">Fri</th>
        <th scope="col">Sat</th>
        <th scope="col">Sun</th>
      </tr>
      </thead>
      <tbody>

      </tbody>
    </table>
  </div>
  <div class="wrap">
    <div class="box" *ngFor="let datem of dateTime"
         [ngStyle]="ifSameDate(datem) ? {'border': '1px solid black'} : {'border': '1px solid #e6e6e6'}"
         (click)="chooseDay(datem)">
      <div class="border">
        <div class="date-number" [ngClass]="{'silver' : datem.getMonth() != currentMonth }">
          <div class="date-number-outline"
               [ngClass]="{ 'selected_number' : toDay.substring(0,4) == datem.toISOString().substring(0, 4) &&  toDay.substring(5,7) ==datem.toISOString().substring(5, 7) && toDay.substring(8,10) == datem.toString().substring(8, 10) }">{{datem.getDate()}}</div>
        </div>
      </div>
      <div class="for-circles">
        <div class="position-row" *ngFor="let p of distinctPositions(findOpenShifts(datem))">
          <div class="shifts-circles">
            <div *ngFor="let shift of findOpenShifts(datem)">
              <div class="shifts-circle" *ngIf="shift.position == p"
                   [style.backgroundColor]="getColor(shift)">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="chosenDate && findOpenShifts(chosenDate)" class="calendar-dropdown">
      <div class="calendar-dropdown__link" [ngClass]="{'actualShift': selectedShift?.id == s.id && selectedShifts.length == 0}"
           *ngFor="let s of findOpenShifts(chosenDate)">
        <div class="calendar-dropdown__activity"  (click)="getShift(s)" style="cursor: pointer; width: 500px"
             [ngClass]="{'disabledShift': checkIfShiftOverlaps(s)}">
          <div class="calendar-dropdown__circle" [style.background-color]="getPositionColor(s.positionColor)"></div>
          <div>{{getActivityShortName(s)}}</div>
          <div class="calendar-dropdown__time">{{getDropdownTIme(s)}}</div>
        </div>
        <mat-checkbox class="cal-checkbox orange-checkbox" (change)="selectShift(s)" [checked]="isChecked(s)"
                      [disabled]="!canSelect || checkIfShiftOverlaps(s)"></mat-checkbox>
      </div>
    </div>
  </div>
</div>
