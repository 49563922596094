import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from '@angular/router';
import { UserService } from '../../../shared/services/user.service';
import { EmployeesService } from '../../../shared/services/employees.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Competence, Contract } from '../../../shared/models/attribute.model';
import { UserRoleModel } from '../../../shared/models/userRole.model';
import { AuthConst } from '../../../shared/services/const/authConst';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
  mode = 0;
  user: any;
  textEmail: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  userEmail: string | undefined;
  groupId: number;
  userImage: any;
  userId: number;
  userImageUrl: any = "";

  filter: { competence?: Competence; contract?: Contract; sort: 1 } = {
    competence: null,
    contract: null,
    sort: 1,
  };

  checkWorkSites: {
    EMPLOYEE?: UserRoleModel[];
    ADMIN?: UserRoleModel[];
    COORDINATOR?: UserRoleModel[];
    EMPLOYER?: UserRoleModel[];
    ROSTER?: UserRoleModel[];
    SUPER_ADMIN?: UserRoleModel[];
  };
  @Output() readonly close = new EventEmitter();

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private employeesService: EmployeesService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {}

  ngOnInit(): void {}

  closeModal(): void {
    this.close.emit();
  }

  goBack(): void {
    this.mode = 0;
  }

  checkEmail(email: string): void {
    this.textEmail = email;
    const checkUser = {
      langType: 'LANG_SV',
      password: '',
      userRole: 'EMPLOYER',
      username: email,
      onlyManagers: false
    };

    this.authService.checkEmail(checkUser).subscribe(
      (UserEmail) => {
        this.userId = UserEmail.id;
        this.firstName = UserEmail.firstName;
        this.lastName = UserEmail.lastName;
        this.userEmail = UserEmail.email;

        this.userService.getEmployeeById(this.userId).subscribe((user) => {
          this.user = user;
          this.checkWorkSites = user.userAuthorities.roles;
          this.groupId = Number(localStorage.getItem(AuthConst.gruopId));
          this.workSites();
        }, (err) => {
          localStorage.setItem('unCheckedEmail', 'false');
          // localStorage.setItem('emailForNew', checkUser.username);
          this.mode = 2;
        });
      },
      (err) => {
        if (err.status == 404) {
          localStorage.setItem('unCheckedEmail', 'false');
          // localStorage.setItem('emailForNew', checkUser.username);
          this.mode = 2;
        }
      }
    );
  }

  workSites(): void {
    if (this.checkWorkSites) {
      this.mode = 1;
      // Check EMPLOYEE worksite groupId
      if (this.checkWorkSites.EMPLOYEE && this.checkWorkSites.EMPLOYEE.length > 0) {
        this.checkWorkSites.EMPLOYEE.forEach((role) => {
          if (role.groupId && role.groupId === this.groupId) {
            this.mode = 3;
          } else if (this.mode !== 3) {
            this.mode = 1;
          }
        });
      }

      // Check ADMIN worksite groupId
      if (this.checkWorkSites.ADMIN && this.checkWorkSites.ADMIN.length > 0) {
        this.checkWorkSites.ADMIN.forEach((role) => {
          if (role.groupId === this.groupId) {
            this.mode = 3;
          } else if (this.mode !== 3) {
            this.mode = 1;
          }
        });
      }

      // Check COORDINATOR worksite groupId
      if (this.checkWorkSites.COORDINATOR && this.checkWorkSites.COORDINATOR.length > 0) {
        this.checkWorkSites.COORDINATOR.forEach((role) => {
          if (role.groupId === this.groupId) {
            this.mode = 3;
          } else if (this.mode !== 3) {
            this.mode = 1;
          }
        });
      }

      // Check EMPLOYER worksite groupId
      if (this.checkWorkSites.EMPLOYER && this.checkWorkSites.EMPLOYER.length > 0) {
        this.checkWorkSites.EMPLOYER.forEach((role) => {
          if (role.groupId === this.groupId) {
            this.mode = 3;
          } else if (this.mode !== 3) {
            this.mode = 1;
          }
        });
      }

      // Check ROSTER worksite groupId
      if (this.checkWorkSites.ROSTER && this.checkWorkSites.ROSTER.length > 0) {
        this.checkWorkSites.ROSTER.forEach((role) => {
          if (role.groupId === this.groupId) {
            this.mode = 3;
          } else if (this.mode !== 3) {
            this.mode = 1;
          }
        });
      }

      // Check SUPER_ADMIN worksite groupId
      if (this.checkWorkSites.SUPER_ADMIN && this.checkWorkSites.SUPER_ADMIN.length > 0) {
        this.checkWorkSites.SUPER_ADMIN.forEach((role) => {
          if (role.groupId === this.groupId) {
            this.mode = 3;
          } else if (this.mode !== 3) {
            this.mode = 1;
          }
        });
      }
    }
  }

  noEmail(): void {
    const email = this.generateRandomString(7).toLowerCase() + '@gapstaff.com';
    localStorage.setItem(AuthConst.emailForNew, email);
    localStorage.setItem('unCheckedEmail', 'true');
    this.router.navigate(['/personal/new/user']);
  }

  toCreateNewUserPage(): void {
    localStorage.setItem(AuthConst.userForNew, JSON.stringify(this.user));
    localStorage.setItem('unCheckedEmail', 'false');
    this.router.navigate(['/personal/new/user']);
  }

  noExistingEmail(): void {
    localStorage.setItem(AuthConst.emailForNew, this.textEmail);
    localStorage.setItem('unCheckedEmail', 'true');
    this.router.navigate(['/personal/new/user']);
  }


  generateRandomString(length: number): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
