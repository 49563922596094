import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { shareReplay, tap, map, catchError } from 'rxjs/operators';
import { LoginModel } from '../models/login.model';
import { AuthConst } from './const/authConst';
import { ErrorMessagesService } from './error-messages.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  // @ts-ignore
  private subject = new BehaviorSubject<LoginModel>(null);
  user$: Observable<LoginModel> = this.subject.asObservable();

  isLoggedIn$: Observable<boolean>;
  isLoggedOut: Observable<boolean>;

  constructor(
    private http: HttpClient,
    private errorMessagesService: ErrorMessagesService
  ) {
    this.isLoggedIn$ = this.user$.pipe(map((user) => !!user));
    this.isLoggedOut = this.user$.pipe(map((loggedin) => !loggedin));

    const user = localStorage.getItem(AuthConst.token);
    if (user) {
      // @ts-ignore
      this.subject.next(user);
    }
  }

  apiURL: string = environment.apiURL;

  /**
   *  Login
   */
  login(user: LoginModel): Observable<LoginModel> {
    return this.http.post<LoginModel>(`${this.apiURL}/user/login`, user).pipe(
      tap((loggedInUser) => {
        this.subject.next(loggedInUser);
        localStorage.setItem(AuthConst.token, loggedInUser.token);
      }),
      shareReplay()
    );
  }


  sendForgottenPassword(email: string): any {
    return this.http.post(`${this.apiURL}/send-pass`, {email});
  }

  checkEmailForPass(email: string): any {
    return this.http.post(`${this.apiURL}/send-pass/check`, {email});
  }

  /**
   *  Logout
   */
  logout(): void {
    // @ts-ignore
    this.subject.next(null);
  }

  /**
   *  Logout User
   */
  UserLogout(logout: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiURL}/user/logout`, logout)
      .pipe(shareReplay());
  }

  /**
   * Check Email
   */
  checkEmail(checkEmail: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/user/email/check`, checkEmail).pipe(
      tap((email) => email),
      shareReplay()
    );
  }

  /**
   * Is user signed in?
   */
  isSignedIn(): boolean {
    return localStorage.getItem(AuthConst.token) !== null;
  }

  /**
   * fireBase token
   */
  addDevice(device: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiURL}/user/device`, device)
      .pipe(tap((x) => x));
  }
}
