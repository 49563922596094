import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {EmployeesService} from '../../shared/services/employees.service';
import {TranslateService} from '@ngx-translate/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

// @ts-ignore
// @ts-ignore
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import {FormControl} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {TooltipShiftsModalsComponent} from '../modals/tooltip-shifts-modals/tooltip-shifts-modals.component';
import {CancelTooltipModalComponent} from '../modals/cancel-tooltip-modal/cancel-tooltip-modal.component';
import {ShiftDeteilsComponent} from '../modals/shift-deteils/shift-deteils.component';
import {AuthConst} from '../../shared/services/const/authConst';
import {HelperService} from '../../shared/services/helper.service';
import {ShiftNoteComponent} from '../modals/shift-note/shift-note.component';
import {ShiftsService} from '../../shared/services/shifts.service';
import {AnnouncementModel} from '../../shared/models/announcement.model';
import {AddAnnouncementComponent} from '../modals/add-announcement/add-announcement.component';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../shared/services/user.service';
import {UserDetailsModalComponent} from '../modals/user-details-modal/user-details-modal.component';
import {Position} from '../../shared/models/attribute.model';
import {Observable, Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CancelReasonsModel} from '../../shared/models/cancel-reasons.model';
import {DomSanitizer} from '@angular/platform-browser';

const moment = _rollupMoment || _moment;

class KlasaPozicije {
  position: number;
}

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class ScheduleComponent implements OnInit, OnDestroy, AfterViewInit {
  currentDate: any;
  // @ts-ignore
  date = new FormControl(new Date());
  showLists: boolean;
  week: number;
  year: number;
  showPositions = false;
  showShifts = false;
  assignments: any = [];
  shiftColor: any;
  personId: number;
  cardHover: any = [];
  cardClicked: any = [];
  groupId: string;
  chosenPosition: string | undefined;
  chosenShift: string | undefined;
  chosenList: string | undefined;
  positionsSelect: any[] = [];
  loading = false;
  mode = 1;
  dateMonth: any;
  newShift = false;
  undisclosed: string | undefined;
  disclosed: string | undefined;
  employerNote: string | undefined;
  disclosedNote: string | undefined;
  allAnnouncement: any = [];
  allUnavailable: Array<CancelReasonsModel> = [];
  announcementMessage: string | undefined;
  cancellationReasons: any = [];
  cancellationUsers: any = [];
  userRole = 'ROLE_EMPLOYER';
  checkLoading: any = true;
  calendar = false;
  announcement = false;
  unavailable = false;
  hide = false;
  hideNote = false;

  showMessage = false;
  checkMark = false;
  allShiftsChecked = true;
  allListsChecked = true;
  checkAllPositions = true;

  getAllPositions: any[] = [];

  cancelReasons: any[] = [];
  showCancel = false;

  dayArray: any = [];
  eveningArray: any = [];
  nightArray: any = [];

  hideNotes = true;
  dataGoalsPosition: any[] = [];
  dataGoalsPositionName: any[] = [];
  optionPositions: any = null;
  positions$?: Observable<any>;
  mon: any = null;
  counter: any = 0;
  groupIde: any = localStorage.getItem('gruopId');
  allAssignments: any[] = [];
  weekMonthViewFirst: number;
  weekMonthViewLast: number;
  selectedShiftId: number = null;
  colorArray: any = [];
  clickedArrow = false;
  assignmentSupscription: any;
  aaa: Array<KlasaPozicije> = [];
  shifts = [
    {
      id: 1,
      shift: 'Dagpass',
      code: 'DAY_ASSIGNMENT',
    },
    {
      id: 2,
      shift: 'Kvällspass',
      code: 'EVENING_ASSIGNMENT',
    },
    {
      id: 3,
      shift: 'Nattpass',
      code: 'NIGHT_ASSIGNMENT',
    },
  ];
  assignmentTimings: any[] = [];
  staffingGoalsActiveValue: any = false;
  staffingGoalsActiveValueMonth: any = false;
  selectedPositionId: number = null;

  dayShiftStart: any;
  dayShiftEnd: any;
  eveningShiftStart: any;
  eveningShiftEnd: any;
  nightShiftStart: any;
  nightShiftEnd: any;
  sskDay: any = [];
  uskDay: any = [];
  sskEvening: any = [];
  uskEvening: any = [];
  sskNight: any = [];
  uskNight: any = [];

  pushAllShifts: any[] = [];
  lists: any[] = [];
  selectedLists: any[] = [];

  activities: any = [];
  activityShortTitle: string | undefined;

  findUsers: any = [];
  previousTimeout: any;
  nextTimeout: any;

  dayTimeShift = [
    {name: 'DAY_ASSIGNMENT'},
    {name: 'SWING_1'},
    {name: 'EVENING_ASSIGNMENT'},
    {name: 'SWING_2'},
    {name: 'NIGHT_ASSIGNMENT'},
  ];

  activitySubscription: Subscription;
  positionsSubscription: Subscription;
  sendDateSubscription: Subscription;
  weeksSubscription: Subscription;
  clickedSectionSubscription: Subscription;
  groupChangedSubscription: Subscription;
  actualShift: any = null;
  positions: any[] = [];
  paramDate: any = null;

  public innerWidth: any;

  case = 0;

  scrWidth: any;
  role = '';
  public innerHeight: any;

  constructor(
    private employeesService: EmployeesService,
    public translate: TranslateService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private helper: HelperService,
    private shiftsService: ShiftsService,
    private route: Router,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private activeRoute: ActivatedRoute
  ) {
    const dateParam = this.activeRoute.snapshot.paramMap.get('date');
    if (dateParam) {
      this.currentDate = new Date(dateParam);
      this.paramDate = dateParam;
      console.log('gotDate 1 ', this.currentDate);
    } else {
      this.currentDate = new Date();
      this.currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }
    this.onResize(event);
  }

  ngOnInit(): void {
    const jwt = this.activeRoute.snapshot.paramMap.get('jwt');
    const groupId = this.activeRoute.snapshot.paramMap.get('groupId');


    if (jwt && groupId) {
      console.log('JWT:   ', jwt);
      localStorage.setItem(AuthConst.token, jwt);
      localStorage.setItem(AuthConst.gruopId, groupId);

      console.log("isRel:  ", localStorage.getItem('isReloaded'));
      if (localStorage.getItem('isReloaded')) {
        console.log("should not reload");
        localStorage.removeItem('isReloaded');
        console.log("isRel2:  ", localStorage.getItem('isReloaded'));

      } else {

        console.log("should reload");
        window.location.reload();
        localStorage.setItem('isReloaded', '1');


      }


    }

    this.loading = true;
    this.shiftsService.getLists(localStorage.getItem(AuthConst.gruopId)).subscribe((data: any) => {
      this.lists = data;


      if (localStorage.getItem('dayview-lists') && JSON.parse(localStorage.getItem('dayview-lists')).length > 0) {
        this.selectedLists = JSON.parse(localStorage.getItem('dayview-lists'));
        if (this.selectedLists.length === 0) {
          this.chosenList = this.translate.instant('allLists');
        } else if (this.selectedLists.length > 1) {
          this.chosenList = this.translate.instant('severalOptions');
        } else {
          this.chosenList = '';
          this.selectedLists.forEach((s: any, index: number) => {
            const listIndex = this.lists.findIndex((optionList: any) => optionList.id == s);
            if (listIndex !== -1) {
              this.chosenList = this.chosenList + this.lists[listIndex].title;
            }
          });
        }

      } else {
        this.chosenList = this.translate.instant('allLists');
        this.allListsChecked = true;
      }
    });


    if (localStorage.getItem('dayview-shifts') && JSON.parse(localStorage.getItem('dayview-shifts')).length > 0) {
      this.pushAllShifts = JSON.parse(localStorage.getItem('dayview-shifts'));

      if (this.pushAllShifts.length === 0) {
        this.chosenShift = this.translate.instant('allShifts');
      } else if (this.pushAllShifts.length > 1) {
        this.chosenShift = this.translate.instant('severalOptions');
      } else {
        this.chosenShift = '';
        this.pushAllShifts.forEach((s: any, index: number) => {
          const shiftIndex = this.shifts.findIndex((shift: any) => shift.id == s);
          if (shiftIndex !== -1) {
            this.chosenShift = this.chosenShift + this.shifts[shiftIndex].shift;
          }
        });
      }


      if (this.pushAllShifts.length == this.shifts.length) {
        this.allShiftsChecked = true;
      } else {
        this.allShiftsChecked = false;
      }
    } else {
      this.allShiftsChecked = true;
      this.chosenShift = this.translate.instant('allShifts');
    }

    this.activitySubscription = this.employeesService
      .userActvity()
      .subscribe((x) => {
        x.forEach((group: any) => {
          const groupId = Number(localStorage.getItem(AuthConst.gruopId));
          if (
            group.groupId === groupId
          ) {
            this.activities.push(group);
          }
        });
      });
    if (this.mode === 1) {
      this.getUnavailable();
    }
    this.role = localStorage.getItem('gapUserRole');
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;

    if (!this.checkIfNotRoster()) {
      this.hide = true;
      this.hideNote = true;
    }
    this.employeesService.userPositions().subscribe((x) => {
      x.forEach((group: any) => {
        if (
          group.groupId == this.groupIde &&
          group.groupStatus == 'ACTIVE'
        ) {
          this.positions.push(group);
        }
      });

      if (localStorage.getItem('dayview-positions') && JSON.parse(localStorage.getItem('dayview-positions')).length > 0) {
        this.getAllPositions = JSON.parse(localStorage.getItem('dayview-positions'));

        if (this.getAllPositions.length === 0) {
          this.chosenPosition = this.translate.instant('allPositions');
        } else if (this.getAllPositions.length > 1) {
          this.chosenPosition = this.translate.instant('severalOptions');
        } else {
          this.chosenPosition = '';
          this.getAllPositions.forEach((p: any, index: number) => {
            const posIndex = this.positionsSelect.findIndex(
              (pos: any) => pos.id == p
            );

            if (posIndex !== -1) {
              this.chosenPosition =
                this.chosenPosition + this.positionsSelect[posIndex].title;
            }
          });
        }


        if (this.positions.length == this.getAllPositions.length) {
          this.checkAllPositions = true;
        } else {
          this.checkAllPositions = false;
        }
      } else {
        this.checkAllPositions = true;
        this.chosenPosition = this.translate.instant('allPositions');
      }

    });

    this.helper.sendAssignment$.subscribe((assignmentStartTime) => {
      console.log('assignmentStartTime   ', assignmentStartTime);
      localStorage.setItem(AuthConst.scheduleDate, assignmentStartTime.start.replace(/-/g, '/'));
      this.updateAssignment();
    });
    this.helper.changeDate$.subscribe((response: any) => {
      this.checkLoading = response;
    });
    this.helper.recoveredShift$.subscribe((recovery) => {
      const indexRecovery = this.allAssignments.findIndex(
        // tslint:disable-next-line:no-shadowed-variable
        (shift: { id: number }) => shift.id === recovery.id
      );
      if (indexRecovery !== -1) {
        this.allAssignments[indexRecovery].status = recovery.status;
        this.allAssignments[indexRecovery].cancelReasonId = null;
      }
    });
    if (localStorage.getItem(AuthConst.userRole)) {
      this.userRole = localStorage.getItem(AuthConst.userRole);
    }
    if (this.userRole == 'ROLE_COORDINATOR') {
      this.hide = true;
    }

    //
    // const halfMonthAgoDate = new Date();
    // halfMonthAgoDate.setDate(halfMonthAgoDate.getDate() - 15);
    //
    // const halfMonthLaterDate = new Date();
    // halfMonthLaterDate.setDate(halfMonthLaterDate.getDate() + 15);
    //
    // const halfMonthAgo = this.datePipe.transform(
    //   new Date(halfMonthAgoDate),
    //   'yyyy-MM-dd'
    // );
    //
    // const halfMonthLater = this.datePipe.transform(
    //   new Date(halfMonthLaterDate),
    //   'yyyy-MM-dd'
    // );
    // this.employeesService.getAssignments(halfMonthAgo, halfMonthLater).subscribe(
    //   (data: any) => {
    //     const groupIndex = data.groups.findIndex(
    //       (g: any) => g.group.id == this.groupId
    //     );
    //     if (groupIndex !== -1) {
    //       const allAssignm = data.groups[groupIndex].dayAssignments;
    //       allAssignm.forEach((assignmentDay: any) => {
    //         //this.saveAssignmentsToLocalStorage(assignmentDay.assignments);
    //       });
    //
    //     }
    //   }
    // );

    this.sendDateSubscription = this.helper.sendDate$.subscribe(
      (dateTime: any) => {
        this.allAnnouncement = [];
        if (dateTime) {
          this.staffingGoalsActiveValueMonth = false;
          this.currentDate = new Date(dateTime);
          console.log('gotDate 2 ', this.currentDate);

          this.mode = 1;
          if (localStorage.getItem('dayview-lists') && JSON.parse(localStorage.getItem('dayview-lists')).length > 0) {
            this.selectedLists = JSON.parse(localStorage.getItem('dayview-lists'));
            if (this.selectedLists.length === 0) {
              this.chosenList = this.translate.instant('allLists');
            } else if (this.selectedLists.length > 1) {
              this.chosenList = this.translate.instant('severalOptions');
            } else {
              this.chosenList = '';
              this.selectedLists.forEach((s: any, index: number) => {
                const listIndex = this.lists.findIndex((optionList: any) => optionList.id == s);
                if (listIndex !== -1) {
                  this.chosenList = this.chosenList + this.lists[listIndex].title;
                }
              });
            }
          } else {
            this.allListsChecked = true;
            this.chosenList = this.translate.instant('allLists');
          }

          if (localStorage.getItem('dayview-shifts') && JSON.parse(localStorage.getItem('dayview-shifts')).length > 0) {
            this.pushAllShifts = JSON.parse(localStorage.getItem('dayview-shifts'));

            if (this.pushAllShifts.length === 0) {
              this.chosenShift = this.translate.instant('allShifts');
            } else if (this.pushAllShifts.length > 1) {
              this.chosenShift = this.translate.instant('severalOptions');
            } else {
              this.chosenShift = '';
              this.pushAllShifts.forEach((s: any, index: number) => {
                const shiftIndex = this.shifts.findIndex((shift: any) => shift.id == s);
                if (shiftIndex !== -1) {
                  this.chosenShift = this.chosenShift + this.shifts[shiftIndex].shift;
                }
              });
            }
          }

          if (localStorage.getItem('dayview-positions') && JSON.parse(localStorage.getItem('dayview-positions')).length > 0) {
            this.getAllPositions = JSON.parse(localStorage.getItem('dayview-positions'));
          }
          this.getAnnouncement();
          if (this.mode === 1) {
            this.getUnavailable();
          }

          this.filterAssignments();
          this.sortAssignments();

          const dateCurrent = this.datePipe.transform(
            this.currentDate,
            'yyyy-MM-dd'
          );


          this.loading = true;
          // this.findAssignmentsFromLocalStorage(dateCurrent);
          this.employeesService.assignment(dateCurrent).subscribe((x) => {
            console.log(x);
            const groupIndex = x.groups.findIndex(
              (g: any) => g.group.id == this.groupId
            );
            if (groupIndex !== -1) {
              this.allAssignments =
                x.groups[groupIndex].dayAssignments[0]?.assignments;
              this.allAssignments?.forEach((a: any) => {
                // this.shiftsService.getInvitationsForShift(a.id).subscribe(
                //   async (invitations: any[]) => {
                //     a.invitationCount = invitations.length;
                //     await new Promise((resolve) => setTimeout(resolve, 200));
                //   }
                // );
              });
              if (this.hideNote) {
                this.assignments = this.allAssignments.filter((a: any) => {
                  return a.status != 'CANCELED';
                });
                this.filterAssignments();
                this.sortAssignments();
              } else {
                this.assignments = this.allAssignments;

                this.filterAssignments();
                this.sortAssignments();
              }
              this.findUsers = [];
              this.assignments?.forEach((user: {
                contractTitle: any;
                positionShortTitle: any;
                assignedToId: number
              }) => {
                if (user) {
                  // this.userService
                  //   .getEmployeeById(user.assignedToId)
                  //   .subscribe((users) => {
                  //     if (users && users?.userAuthorities?.roles?.EMPLOYEE) {
                  //       user.positionShortTitle = users?.userAuthorities?.roles?.EMPLOYEE[0].position?.positionShortTitle;
                  //       user.contractTitle = users?.contractTitle;
                  //       this.findUsers?.push(
                  //         users?.userAuthorities?.roles?.EMPLOYEE[0]
                  //       );
                  //     }
                  //   });
                }
              });

              this.assignmentTimings =
                x.groups[groupIndex].dayAssignments[0]?.assignmentTimings;
              this.getStaff(this.assignmentTimings, this.assignments);
              let index = 0;
              if (this.allAssignments) {
                this.allAssignments?.forEach((a) => {
                  if (
                    a.assignedToProfilePicture &&
                    a.assignedToProfilePicture != ''
                  ) {
                  }
                  index = index + 1;
                });
              }
            }
          });

          this.shiftsService
            .shiftColor(dateCurrent, dateCurrent)
            .subscribe((response: any) => {
              const resp = response;
              for (const oneOf of response.groups) {
                if (oneOf.group.id == this.groupId) {
                  this.colorArray = oneOf.dayAssignments;
                }
              }
            });

          this.calculateWeek(dateTime);

        }
      }
    );
    this.groupId = localStorage.getItem(AuthConst.gruopId);
    this.weeksSubscription = this.helper.weeks$.subscribe((value) => {
      this.weekMonthViewFirst = value.firstWeekSend;
      this.weekMonthViewLast = value.lastWeekSend;
    });
    this.getAnnouncement();
    if (this.mode === 1) {
      this.getUnavailable();
    }
    if (!this.paramDate) {
      this.currentDate = new Date();
    }
    console.log('gotDate 3 ', this.currentDate);


    const scheduleDate1 = localStorage.getItem(AuthConst.scheduleDate);
    if (scheduleDate1) {
      if (!this.paramDate) {
        this.currentDate = scheduleDate1;
      }
      console.log('gotDate 4 ', this.currentDate);

    }
    const dateCurrent = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    localStorage.setItem(AuthConst.currentDate, this.currentDate);
    this.positionsSelect = [];
    this.chosenPosition = '';
    this.chosenShift = '';
    this.loading = true;
    // this.findAssignmentsFromLocalStorage(dateCurrent);
    this.employeesService.assignment(dateCurrent).subscribe((x) => {
      console.log(x);
      console.log(this.groupId);
      const groupIndex = x.groups.findIndex(
        (g: any) => g.group.id == this.groupId
      );
      if (groupIndex !== -1) {
        this.allAssignments =
          x.groups[groupIndex].dayAssignments[0]?.assignments;

        if (this.hideNote) {
          this.assignments = this.allAssignments.filter((a: any) => {
            return a.status != 'CANCELED';
          });
          this.assignmentTimings =
            x.groups[groupIndex].dayAssignments[0]?.assignmentTimings;

          this.filterAssignments();
          this.sortAssignments();
        } else {
          this.assignmentTimings =
            x.groups[groupIndex].dayAssignments[0]?.assignmentTimings;
          this.assignments = this.allAssignments;
          this.positionsSubscription = this.employeesService
            .userPositions()
            .subscribe((x) => {
              this.positionsSelect = [];
              x.forEach((group: any) => {
                if (
                  group.groupId === Number(this.groupId) &&
                  group.groupStatus === 'ACTIVE' &&
                  group.rowStatus === 'ACTIVE'
                ) {
                  this.positionsSelect.push(group);
                  // this.getAllPositions.push(group.id);
                }
              });
              if (this.getAllPositions.length === 0) {
                this.chosenPosition = this.translate.instant('allPositions');
              } else if (this.getAllPositions.length > 1) {
                this.chosenPosition = this.translate.instant('severalOptions');
              } else {
                this.chosenPosition = '';
                this.getAllPositions.forEach((p: any, index: number) => {
                  const posIndex = this.positionsSelect.findIndex(
                    (pos: any) => pos.id == p
                  );

                  if (posIndex !== -1) {
                    this.chosenPosition =
                      this.chosenPosition + this.positionsSelect[posIndex].title;
                  }
                });
              }
              if (localStorage.getItem('dayview-shifts') && JSON.parse(localStorage.getItem('dayview-shifts')).length > 0) {
                this.pushAllShifts = JSON.parse(localStorage.getItem('dayview-shifts'));
                if (this.pushAllShifts.length === 0) {
                  this.chosenShift = this.translate.instant('allShifts');
                } else if (this.pushAllShifts.length > 1) {
                  this.chosenShift = this.translate.instant('severalOptions');
                } else {
                  this.chosenShift = '';
                  this.pushAllShifts.forEach((s: any, index: number) => {
                    const shiftIndex = this.shifts.findIndex((shift: any) => shift.id == s);
                    if (shiftIndex !== -1) {
                      this.chosenShift = this.chosenShift + this.shifts[shiftIndex].shift;
                    }
                  });
                }
              } else {
                this.allShiftsChecked = true;
                this.chosenShift = this.translate.instant('allShifts');
              }

              if (localStorage.getItem('dayview-lists') && JSON.parse(localStorage.getItem('dayview-lists')).length > 0) {
                this.selectedLists = JSON.parse(localStorage.getItem('dayview-lists'));
                this.allListsChecked = false;
                if (this.selectedLists.length === 0) {
                  this.chosenList = this.translate.instant('allLists');
                } else if (this.selectedLists.length > 1) {
                  this.chosenList = this.translate.instant('severalOptions');
                } else {
                  this.chosenList = '';
                  this.selectedLists.forEach((s: any, index: number) => {
                    const listIndex = this.lists.findIndex((optionList: any) => optionList.id == s);
                    if (listIndex !== -1) {
                      this.chosenList = this.chosenList + this.lists[listIndex].title;
                    }
                  });
                }
              } else {
                this.chosenList = this.translate.instant('allLists');
                this.allListsChecked = true;
              }

              this.filterAssignments(); // on app start 2x   + on menu select 2x
              this.sortAssignments();
            });


        }
        this.findUsers = [];
        this.assignments?.forEach((user: {
          contractTitle: any;
          positionShortTitle: any;
          assignedToId: number
        }) => {
          if (user) {
            // this.userService
            //   .getEmployeeById(user.assignedToId)
            //   .subscribe((users) => {
            //     if (users && users?.userAuthorities?.roles?.EMPLOYEE) {
            //       user.positionShortTitle = users?.userAuthorities?.roles?.EMPLOYEE[0].position?.positionShortTitle;
            //       user.contractTitle = users?.contractTitle;
            //       this.findUsers?.push(
            //         users?.userAuthorities?.roles?.EMPLOYEE[0]
            //       );
            //     }
            //   });
          }
        });

        this.assignmentTimings =
          x.groups[groupIndex].dayAssignments[0]?.assignmentTimings;
        this.getStaff(this.assignmentTimings, this.assignments);
        let index = 0;
        if (this.allAssignments) {
          this.allAssignments?.forEach((a) => {
            if (
              a.assignedToProfilePicture &&
              a.assignedToProfilePicture != ''
            ) {
            }
            index = index + 1;
          });
        }
      }

      this.assignments.forEach((assignment: { activity1Clinical: boolean; activity2Clinical: boolean; clinical: boolean; }) => {
          if (assignment.activity1Clinical || assignment.activity2Clinical) {
            assignment.activity1Clinical = true;
            assignment.activity2Clinical = true;
            assignment.clinical = true;
          }
        });
    });

    const date = new Date(); /// TODO:remove before production
    this.dateMonth = date;
    this.year = date.getFullYear();
    if (!this.paramDate) {
      this.currentDate = new Date(this.currentDate);
    }
    console.log('gotDate 5 ', this.currentDate);

    this.calculateWeek(this.currentDate);


    this.groupId = localStorage.getItem(AuthConst.gruopId);
    // @ts-ignore
    this.positionsSubscription = this.employeesService
      .userPositions()
      .subscribe((x) => {
        this.positionsSelect = [];
        x.forEach((group: any) => {
          if (
            group.groupId === Number(this.groupId) &&
            group.groupStatus === 'ACTIVE' &&
            group.rowStatus === 'ACTIVE'
          ) {
            this.positionsSelect.push(group);
            // this.getAllPositions.push(group.id);
          }
        });
        if (this.getAllPositions.length === 0) {
          this.chosenPosition = this.translate.instant('allPositions');
        } else if (this.getAllPositions.length > 1) {
          this.chosenPosition = this.translate.instant('severalOptions');
        } else {
          this.chosenPosition = '';
          this.getAllPositions.forEach((p: any, index: number) => {
            const posIndex = this.positionsSelect.findIndex(
              (pos: any) => pos.id == p
            );

            if (posIndex !== -1) {
              this.chosenPosition =
                this.chosenPosition + this.positionsSelect[posIndex].title;
            }
          });
        }
      });

    if (this.mon !== new Date(this.currentDate).getMonth()) {
      this.dataGoalsPosition = [];
      this.dataGoalsPosition = [];
      this.optionPositions = null;
      this.counter = 0;
      this.mon = new Date(this.currentDate).getMonth();
      this.positions$ = this.employeesService.userPositions();

      this.positions$.subscribe((positions: Position[]) => {
        // @ts-ignore
        // @ts-ignore
        this.optionPositions = positions.filter(
          (x) => x.groupId == this.groupIde && x.groupStatus == 'ACTIVE'
        );
        this.allEmployees(dateCurrent, dateCurrent);
      });
    }

    this.helper.updateUserPersonalSchedule$.subscribe((x) => {
      const updateUser = x?.userAuthorities.roles.EMPLOYEE[0];

      const findUserIndex = this.findUsers.findIndex(
        (user: any) => user.userId === updateUser.userId
      );

      if (findUserIndex !== -1) {
        this.findUsers[findUserIndex].contractTitle = updateUser.contractTitle;
        this.findUsers[findUserIndex].position.positionShortTitle =
          updateUser.position.positionShortTitle;
      }
    });

    this.clickedSectionSubscription = this.helper.clickedSection.subscribe(
      (page) => {
        if (page && page == '/schedule') {
          this.getAnnouncement();
          this.getUnavailable();
          this.currentDate = new Date();
          console.log('gotDate 6 ', this.currentDate);

          // tslint:disable-next-line:no-shadowed-variable
          // const scheduleDate = localStorage.getItem(AuthConst.scheduleDate);
          // if (scheduleDate) {
          //   this.currentDate = scheduleDate;
          // }
          const dateCurrent = this.datePipe.transform(
            this.currentDate,
            'yyyy-MM-dd'
          );
          localStorage.setItem(AuthConst.currentDate, this.currentDate);
          this.positionsSelect = [];
          this.chosenPosition = '';
          this.chosenShift = '';
          this.loading = true;
          this.employeesService.assignment(dateCurrent).subscribe((x) => {
            const groupIndex = x.groups.findIndex(
              (g: any) => g.group.id == this.groupId
            );
            if (groupIndex !== -1) {
              this.allAssignments =
                x.groups[groupIndex].dayAssignments[0]?.assignments;
              this.allAssignments?.forEach((a: any) => {
                // this.shiftsService.getInvitationsForShift(a.id).subscribe(
                //   async (invitations: any[]) => {
                //     a.invitationCount = invitations.length;
                //     await new Promise((resolve) => setTimeout(resolve, 200));
                //   }
                // );
              });
              if (this.hideNote) {
                this.assignments = this.allAssignments.filter((a: any) => {
                  return a.status != 'CANCELED';
                });
                this.assignmentTimings =
                  x.groups[groupIndex].dayAssignments[0]?.assignmentTimings;
                this.filterAssignments();
                this.sortAssignments();
              } else {
                this.assignmentTimings =
                  x.groups[groupIndex].dayAssignments[0]?.assignmentTimings;
                this.assignments = this.allAssignments;
                this.filterAssignments();
                this.sortAssignments();
              }

              this.getStaff(this.assignmentTimings, this.assignments);
              let index = 0;
              if (this.allAssignments) {
                this.allAssignments?.forEach((a) => {
                  if (
                    a.assignedToProfilePicture &&
                    a.assignedToProfilePicture != ''
                  ) {
                  }
                  index = index + 1;
                });
              }
            }
          });

          const date = new Date(); /// TODO:remove before production
          this.dateMonth = date;
          this.year = date.getFullYear();
          const now = new Date();
          this.calculateWeek(now);


          this.groupId = localStorage.getItem(AuthConst.gruopId);
          this.employeesService.userPositions().subscribe((x) => {
            this.positionsSelect = [];
            x.forEach((group: any) => {
              if (
                group.groupId == Number(this.groupId) &&
                group.groupStatus == 'ACTIVE'
              ) {
                this.positionsSelect.push(group);
                // this.getAllPositions.push(group.id);
              }
            });
            if (this.getAllPositions.length === 0) {
              this.chosenPosition = this.translate.instant('allPositions');
            } else if (this.getAllPositions.length > 1) {
              this.chosenPosition = this.translate.instant('severalOptions');
            } else {
              this.chosenPosition = '';
              this.getAllPositions.forEach((p: any, index: number) => {
                const posIndex = this.positionsSelect.findIndex(
                  (pos: any) => pos.id == p
                );

                if (posIndex !== -1) {
                  this.chosenPosition =
                    this.chosenPosition + this.positionsSelect[posIndex].title;
                }
              });
            }
          });

          if (this.mon !== new Date(this.currentDate).getMonth()) {
            this.dataGoalsPosition = [];
            this.dataGoalsPosition = [];
            this.optionPositions = null;
            this.counter = 0;
            this.mon = new Date(this.currentDate).getMonth();
            this.positions$ = this.employeesService.userPositions();

            this.positions$.subscribe((positions: Position[]) => {
              // @ts-ignore
              // @ts-ignore
              this.optionPositions = positions.filter(
                (x) => x.groupId == this.groupIde && x.groupStatus == 'ACTIVE'
              );
              this.allEmployees(dateCurrent, dateCurrent);
            });
          }
        }
      }
    );

    this.groupChangedSubscription = this.helper.groupChanged$.subscribe(
      (data) => {
        if (data) {
          this.refresh();
          this.getAnnouncement();
          this.getUnavailable();
          this.positionsSelect = [];
          this.dataGoalsPositionName = [];
          const currentDate = this.datePipe.transform(
            this.currentDate,
            'yyyy-MM-dd'
          );
          this.allEmployees(currentDate, currentDate);
          this.sendDateSubscription = this.helper.sendDate$.subscribe(
            (date: any) => {
              if (date) {
                this.staffingGoalsActiveValueMonth = false;
                this.currentDate = new Date(date);
                console.log('gotDate 7 ', this.currentDate);

                this.mode = 1;
                if (localStorage.getItem('dayview-lists')) {
                  this.selectedLists = JSON.parse(localStorage.getItem('dayview-lists'));
                  this.selectedLists.forEach((s: any, index: number) => {
                    const listIndex = this.lists.findIndex((optionList: any) => optionList.id == s);
                    if (listIndex !== -1) {
                      this.chosenList = this.chosenList + this.lists[listIndex].title;
                    }
                  });
                } else {
                  this.allListsChecked = true;
                  this.chosenList = this.translate.instant('allLists');
                }

                if (localStorage.getItem('dayview-shifts') && JSON.parse(localStorage.getItem('dayview-shifts')).length > 0) {
                  this.pushAllShifts = JSON.parse(localStorage.getItem('dayview-shifts'));
                  if (this.pushAllShifts.length === 0) {
                    this.chosenShift = this.translate.instant('allShifts');
                  } else if (this.pushAllShifts.length > 1) {
                    this.chosenShift = this.translate.instant('severalOptions');
                  } else {
                    this.chosenShift = '';
                    this.pushAllShifts.forEach((s: any, index: number) => {
                      const shiftIndex = this.shifts.findIndex((shift: any) => shift.id == s);
                      if (shiftIndex !== -1) {
                        this.chosenShift = this.chosenShift + this.shifts[shiftIndex].shift;
                      }
                    });
                  }
                } else {
                  this.chosenShift = this.translate.instant('allShift');
                  this.allShiftsChecked = true;
                }

                if (localStorage.getItem('dayview-positions') && JSON.parse(localStorage.getItem('dayview-positions')).length > 0) {
                  this.getAllPositions = JSON.parse(localStorage.getItem('dayview-positions'));
                  if (this.getAllPositions.length === 0) {
                    this.chosenPosition = this.translate.instant('allPositions');
                  } else if (this.getAllPositions.length > 1) {
                    this.chosenPosition = this.translate.instant('severalOptions');
                  } else {
                    this.chosenPosition = '';
                    this.getAllPositions.forEach((p: any, index: number) => {
                      const posIndex = this.positionsSelect.findIndex(
                        (pos: any) => pos.id == p
                      );

                      if (posIndex !== -1) {
                        this.chosenPosition =
                          this.chosenPosition + this.positionsSelect[posIndex].title;
                      }
                    });
                  }
                } else {
                  this.checkAllPositions = true;
                  this.chosenPosition = this.translate.instant('allPositions');
                }
                this.getAnnouncement();
                this.getUnavailable();
                this.filterAssignments();
                this.sortAssignments();


                const scheduleDate = localStorage.getItem(
                  AuthConst.scheduleDate
                );
                if (scheduleDate) {
                  this.currentDate = scheduleDate;
                  console.log('gotDate 8 ', this.currentDate);

                }
                const dateCurrent = this.datePipe.transform(
                  this.currentDate,
                  'yyyy-MM-dd'
                );
                this.loading = true;
                this.employeesService.assignment(dateCurrent).subscribe((x) => {
                  const groupIndex = x.groups.findIndex(
                    (g: any) => g.group.id == this.groupId
                  );
                  if (groupIndex !== -1) {
                    this.allAssignments =
                      x.groups[groupIndex].dayAssignments[0]?.assignments;
                    this.allAssignments?.forEach((a: any) => {
                      // this.shiftsService.getInvitationsForShift(a.id).subscribe(
                      //   async (invitations: any[]) => {
                      //     a.invitationCount = invitations.length;
                      //     await new Promise((resolve) => setTimeout(resolve, 200));
                      //   }
                      // );
                    });
                    if (this.hideNote) {
                      this.assignments = this.allAssignments.filter(
                        (a: any) => {
                          return a.status != 'CANCELED';
                        });
                      this.assignmentTimings =
                        x.groups[groupIndex].dayAssignments[0]?.assignmentTimings;
                      this.filterAssignments();
                      this.sortAssignments();
                    } else {
                      this.assignmentTimings =
                        x.groups[groupIndex].dayAssignments[0]?.assignmentTimings;
                      this.assignments = this.allAssignments;
                      this.filterAssignments();
                      this.sortAssignments();
                    }

                    this.getStaff(this.assignmentTimings, this.assignments);
                    let index = 0;
                    if (this.allAssignments) {
                      this.allAssignments?.forEach((a) => {
                        if (
                          a.assignedToProfilePicture &&
                          a.assignedToProfilePicture != ''
                        ) {
                        }
                        index = index + 1;
                      });
                    }
                  }
                });
                const now1 = new Date();
                const start1 = new Date(now1.getFullYear(), 0, 1);
                const date1 = new Date(date);
                this.calculateWeek(date1);


              }
            }
          );

          this.groupChangedSubscription = this.helper.groupChanged$.subscribe(
            (data) => {
              if (data) {
                this.refresh();
                this.getAnnouncement();
                this.getUnavailable();
                this.positionsSelect = [];
                this.dataGoalsPositionName = [];
                const currentDate = this.datePipe.transform(
                  this.currentDate,
                  'yyyy-MM-dd'
                );
                this.allEmployees(currentDate, currentDate);

                const scheduleDate = localStorage.getItem(
                  AuthConst.scheduleDate
                );
                if (scheduleDate) {
                  this.currentDate = scheduleDate;
                  console.log('gotDate 9 ', this.currentDate);

                }
                const dateCurrent = this.datePipe.transform(
                  this.currentDate,
                  'yyyy-MM-dd'
                );

                this.loading = true;
                this.employeesService.assignment(dateCurrent).subscribe((x) => {
                  const groupIndex = x.groups.findIndex(
                    (g: any) => g.group.id == this.groupId
                  );
                  if (groupIndex !== -1) {
                    this.allAssignments =
                      x.groups[groupIndex].dayAssignments[0]?.assignments;
                    this.allAssignments?.forEach((a: any) => {
                      // this.shiftsService.getInvitationsForShift(a.id).subscribe(
                      //   async (invitations: any[]) => {
                      //     a.invitationCount = invitations.length;
                      //     await new Promise((resolve) => setTimeout(resolve, 200));
                      //   }
                      // );
                    });
                    if (this.hideNote) {
                      this.assignments = this.allAssignments.filter(
                        (a: any) => {
                          return a.status != 'CANCELED';
                        });
                      this.sortAssignments();
                    } else {
                      this.assignments = this.allAssignments;
                      this.sortAssignments();
                    }
                    this.assignmentTimings =
                      x.groups[groupIndex].dayAssignments[0]?.assignmentTimings;
                    this.getStaff(this.assignmentTimings, this.assignments);
                    let index = 0;
                    if (this.allAssignments) {
                      this.allAssignments?.forEach((a) => {
                        if (
                          a.assignedToProfilePicture &&
                          a.assignedToProfilePicture != ''
                        ) {
                        }
                        index = index + 1;
                      });
                    }
                  }
                });

                const date = new Date(); /// TODO:remove before production
                this.dateMonth = date;
                this.year = date.getFullYear();
                const now = new Date();
                this.calculateWeek(now);


                this.groupId = localStorage.getItem(AuthConst.gruopId);
                this.employeesService.userPositions().subscribe((x) => {
                  this.positionsSelect = [];
                  x.forEach((group: any) => {
                    if (
                      group.groupId == Number(this.groupId) &&
                      group.groupStatus === 'ACTIVE'
                    ) {
                      const positionIndex = this.positionsSelect.findIndex(id => id.id === group.id);
                      if (positionIndex === -1) {
                        this.positionsSelect.push(group);
                      }
                      // this.getAllPositions.push(group.id);
                    }
                  });
                  if (this.getAllPositions.length === 0) {
                    this.chosenPosition = this.translate.instant('allPositions');
                  } else if (this.getAllPositions.length > 1) {
                    this.chosenPosition = this.translate.instant('severalOptions');
                  } else {
                    this.chosenPosition = '';
                    this.getAllPositions.forEach((p: any, index: number) => {
                      const posIndex = this.positionsSelect.findIndex(
                        (pos: any) => pos.id == p
                      );

                      if (posIndex !== -1) {
                        this.chosenPosition =
                          this.chosenPosition + this.positionsSelect[posIndex].title;
                      }
                    });
                  }
                });

                if (this.mon !== new Date(this.currentDate).getMonth()) {
                  this.dataGoalsPosition = [];
                  this.dataGoalsPosition = [];
                  this.optionPositions = null;
                  this.counter = 0;
                  this.mon = new Date(this.currentDate).getMonth();
                  this.positions$ = this.employeesService.userPositions();

                  this.positions$.subscribe((positions: Position[]) => {
                    // @ts-ignore
                    // @ts-ignore
                    this.optionPositions = positions.filter(
                      (x) =>
                        x.groupId == this.groupIde && x.groupStatus == 'ACTIVE'
                    );
                    this.allEmployees(dateCurrent, dateCurrent);
                  });
                }
              }
            }
          );

          this.getAnnouncement();
          if (this.allUnavailable.length == 0) {
            this.getUnavailable();
          }
          localStorage.setItem(AuthConst.currentDate, this.currentDate);
          const scheduleDate = localStorage.getItem(AuthConst.scheduleDate);
          if (scheduleDate) {
            this.currentDate = scheduleDate;
            console.log('gotDate 10 ', this.currentDate);

          }
          const dateCurrent = this.datePipe.transform(
            this.currentDate,
            'yyyy-MM-dd'
          );
          this.loading = true;
          this.employeesService.assignment(dateCurrent).subscribe((x) => {
            const groupIndex = x.groups.findIndex(
              (g: any) => g.group.id == this.groupId
            );
            if (groupIndex !== -1) {
              this.allAssignments =
                x.groups[groupIndex].dayAssignments[0]?.assignments;
              if (this.hideNote) {
                this.assignments = this.allAssignments.filter((a: any) => {
                  return a.status != 'CANCELED';
                });
                this.assignmentTimings =
                  x.groups[groupIndex].dayAssignments[0]?.assignmentTimings;
                this.filterAssignments();
                this.sortAssignments();
              } else {
                this.assignmentTimings =
                  x.groups[groupIndex].dayAssignments[0]?.assignmentTimings;
                this.assignments = this.allAssignments;
                this.filterAssignments();
                this.sortAssignments();
              }

              this.getStaff(this.assignmentTimings, this.assignments);
              let index = 0;
              if (this.allAssignments) {
                this.allAssignments?.forEach((a) => {
                  if (
                    a.assignedToProfilePicture &&
                    a.assignedToProfilePicture != ''
                  ) {
                  }
                  index = index + 1;
                });
              }
            }
          });

          const date = new Date(); /// TODO:remove before production
          this.dateMonth = date;
          this.year = date.getFullYear();
          const now = new Date();
          this.calculateWeek(now);


          this.groupId = localStorage.getItem(AuthConst.gruopId);
          this.employeesService.userPositions().subscribe((x) => {

            this.positionsSelect = [];
            x.forEach((group: any) => {
              if (
                group.groupId == Number(this.groupId) &&
                group.groupStatus == 'ACTIVE'
              ) {
                const positionIndex = this.positionsSelect.findIndex(id => id.id === group.id);
                if (positionIndex === -1) {
                  this.positionsSelect.push(group);
                }
                // this.getAllPositions.push(group.id);
              }
            });
            if (this.getAllPositions.length === 0) {
              this.chosenPosition = this.translate.instant('allPositions');
            } else if (this.getAllPositions.length > 1) {
              this.chosenPosition = this.translate.instant('severalOptions');
            } else {
              this.chosenPosition = '';
              this.getAllPositions.forEach((p: any, index: number) => {
                const posIndex = this.positionsSelect.findIndex(
                  (pos: any) => pos.id == p
                );

                if (posIndex !== -1) {
                  this.chosenPosition =
                    this.chosenPosition + this.positionsSelect[posIndex].title;
                }
              });
            }
          });

          if (this.mon !== new Date(this.currentDate).getMonth()) {
            this.dataGoalsPosition = [];
            this.dataGoalsPosition = [];
            this.optionPositions = null;
            this.counter = 0;
            this.mon = new Date(this.currentDate).getMonth();
            this.positions$ = this.employeesService.userPositions();

            this.positions$.subscribe((positions: Position[]) => {
              // @ts-ignore
              // @ts-ignore
              this.optionPositions = positions.filter(
                (x) => x.groupId == this.groupIde && x.groupStatus == 'ACTIVE'
              );
              this.allEmployees(dateCurrent, dateCurrent);
            });
          }
        }
      }
    );
    const dateForApi = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      this.currentDate.getDate() + 1
    )
      .toISOString()
      .substring(0, 10);
    this.shiftsService
      .shiftColor(dateForApi, dateForApi)
      .subscribe((response: any) => {
        const resp = response;
        for (const oneOf of response.groups) {
          if (oneOf.group.id == this.groupId) {
            this.colorArray = oneOf.dayAssignments;
          }
        }
      });

    const scheduleDate = localStorage.getItem(AuthConst.scheduleDate);
    if (scheduleDate) {
      if (!this.paramDate) {
        this.currentDate = scheduleDate;
      }
      console.log('gotDate 11 ', this.currentDate);

    }
    this.getCancelReasons();
    if (this.allUnavailable.length == 0) {
      this.getUnavailable();
    }
  }

  notifyPuppeteer() {
    const event = new CustomEvent('appReadyForPDF');
    window.dispatchEvent(event);
    window.dispatchEvent(new Event('appReadyForPDF'));
    console.log("appReadyForPDF 2");
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  ngAfterViewInit(): void {

  }

  getCancelReasons(): void {
    this.shiftsService
      .getActiveCancelReasons()
      .subscribe((reasons: CancelReasonsModel[]) => {
        reasons.forEach((x: any) => {
          if (x.groupId === Number(this.groupId)) {
            this.cancelReasons.push(x);
          }
        });
      });
  }

  getPersonShortName(person: any): string {
    const position = person?.position;
    let positionName = '';
    this.positions?.forEach((p: any) => {
      if (p?.id == position) {
        positionName = p?.shortTitle;
      }
    });
    return positionName;
  }

  getContractTitle(person: any): string {
    return person.assignedToContract;
  }

  getActivityShortName(person: any): string {
    let value = '';
    let value2 = '';
    this.activities.forEach((activity: { id: any; shortTitle: string }) => {
      if (person.activity1Id === activity.id) {
        if (activity.shortTitle) {
          value = activity.shortTitle;
        }
      }

      if (person.activity2Id === activity.id) {
        if (activity.shortTitle) {
          value2 = activity.shortTitle;
        }
      }
    });

    if (value2 == '') {
      return value;
    } else {
      return value + ', ' + value2;
    }
  }

  allEmployees(firstDay: any, lastDay: any): void {
    this.optionPositions.forEach((e: any) => {
      // this.shiftsService
      //   .staffingGoals(e.id, firstDay, lastDay)
      //   .subscribe((response: any) => {
      //     this.dataGoalsPosition.push({
      //       position: this.counter,
      //       data: response,
      //     });
      //
      //     this.dataGoalsPositionName.push({
      //       position: this.counter,
      //       data: e.shortTitle,
      //     });
      //   });
    });
  }

  checkDateAnnouncement(): void {
    this.allAnnouncement.forEach((x: { date: any; text: string }) => {
      const currentDate = this.datePipe.transform(
        this.currentDate,
        'yyyy-MM-dd'
      );
      if (x.date === currentDate) {
        this.showMessage = true;
        this.announcementMessage = x.text;
      }
    });
  }

  removeAssignmentFromLocalStorage(assignment: any): void {

    let existingAssignments: any = localStorage.getItem('assignments');

    if (existingAssignments) {
      existingAssignments = JSON.parse(existingAssignments);

      const index = existingAssignments.findIndex((a: any) => {
        return a.id == assignment.id;
      });
      if (index !== -1) {
        existingAssignments.splice(index, 1);
        try {
          localStorage.setItem('assignments', JSON.stringify(existingAssignments));
        } catch (e) {

        }
      }
    }

  }

  saveAssignmentsToLocalStorage(assignments: any): void {

    let existingAssignments: any = localStorage.getItem('assignments');

    if (existingAssignments) {
      existingAssignments = JSON.parse(existingAssignments);
    } else {
      existingAssignments = [];
    }

    assignments.forEach((a: any) => {

      const assignmentIndex = existingAssignments.findIndex((assignment: any) => {
        return assignment.id == a.id;
      });

      if (assignmentIndex !== -1) {
        existingAssignments[assignmentIndex] = a;
      } else {
        existingAssignments.push(a);
      }

      try {
        localStorage.setItem('assignments', JSON.stringify(existingAssignments));
      } catch (e) {

      }

    });


  }

  findAssignmentsFromLocalStorage(date: any): any {

    if (!date) {
      date = new Date();
    }

    let existingAssignments: any = localStorage.getItem('assignments');

    if (existingAssignments) {
      existingAssignments = JSON.parse(existingAssignments);
      existingAssignments = existingAssignments.filter((a: any) => {
        return new Date(a.start).getDate() == new Date(date).getDate() &&
          new Date(a.start).getMonth() == new Date(date).getMonth() && this.groupId == a.groupId;
      });
      this.allAssignments = existingAssignments;
      if (this.hideNote) {
        this.assignments = this.allAssignments.filter((a: any) => {
          return a.status !== 'CANCELED';
        });
      } else {
        this.assignments = this.allAssignments;
      }
      this.sortAssignments();

    }
  }

  getAnnouncement(): void {
    const groupId = Number(localStorage.getItem(AuthConst.gruopId));
    this.shiftsService.getAnnouncements(groupId).subscribe((announcements) => {
      announcements.forEach((x: { groupId: number }) => {
        if (x.groupId == groupId) {
          this.allAnnouncement.push(x);
          this.allAnnouncement.sort((n1: any, n2: any) => {
            // @ts-ignore
            return new Date(n2.date) - new Date(n1.date);
          });
        }
        this.checkDateAnnouncement();
      });
    });
  }

  getUnavailable(): void {
    if (this.mode === 1) {
      let dateCurrent: string;
      const scheduleDate = localStorage.getItem(AuthConst.scheduleDate);
      if (scheduleDate) {
        dateCurrent = this.datePipe.transform(scheduleDate.replace(/-/g, '/'), 'yyyy-MM-dd');
      } else if (!scheduleDate) {
        const currentDate: any = localStorage.getItem(AuthConst.currentDate);
        if (!currentDate) {
        }
        let time;
        if (localStorage.getItem(AuthConst.changeWorkSiteDate)) {
          time = localStorage.getItem(AuthConst.changeWorkSiteDate);
        } else {
          time = localStorage.getItem(AuthConst.scheduleDate);
        }
        if (time) {
          dateCurrent = this.datePipe.transform(new Date(time.replace(/-/g, '/')), 'yyyy-MM-dd');
        }
      }

      const groupId = Number(localStorage.getItem(AuthConst.gruopId));
      if (this.cancellationReasons.length == 0) {
        this.shiftsService
          .getActiveCancelReasons()
          .subscribe((reasons: CancelReasonsModel[]) => {
            reasons.forEach((reason: CancelReasonsModel) => {
              if (
                reason.groupId === groupId &&
                reason.groupStatus === 'ACTIVE' &&
                reason.rowStatus === 'ACTIVE'
              ) {
                this.cancellationReasons.push(reason);
              }
            });
          });
      }
      this.employeesService
        .getUnavailableEmployees(groupId, dateCurrent)
        .subscribe((unavailableUsers) => {
          this.allUnavailable = [];
          unavailableUsers.forEach((unavailableUser: any) => {
            this.cancellationReasons.forEach(
              (cancelReason: { id: any; title: any }) => {
                if (cancelReason.id === unavailableUser.cancelReasonId) {
                  unavailableUser.cancelTitle = cancelReason.title;
                  const userIndex = this.allUnavailable.findIndex(
                    (x) => unavailableUser.appliedById === x.appliedById
                  );
                  if (userIndex === -1) {
                    this.allUnavailable.push(unavailableUser);
                  }
                }
              }
            );
          });
        });
    }
  }

  updateAssignment(): void {
    this.currentDate = localStorage.getItem(AuthConst.scheduleDate);
    console.log('gotDate 12 ', this.currentDate);

    this.getAnnouncement();
    this.getUnavailable();


    if (localStorage.getItem('dayview-lists') && JSON.parse(localStorage.getItem('dayview-lists')).length > 0) {
      this.selectedLists = JSON.parse(localStorage.getItem('dayview-lists'));
      this.allListsChecked = false;
      if (this.selectedLists.length === 0) {
        this.chosenList = this.translate.instant('allLists');
      } else if (this.selectedLists.length > 1) {
        this.chosenList = this.translate.instant('severalOptions');
      } else {
        this.chosenList = '';
        this.selectedLists.forEach((s: any, index: number) => {
          const listIndex = this.lists.findIndex((optionList: any) => optionList.id == s);
          if (listIndex !== -1) {
            this.chosenList = this.chosenList + this.lists[listIndex].title;
          }
        });
      }
    } else {
      this.chosenList = this.translate.instant('allLists');
      this.allListsChecked = true;
    }

    if (localStorage.getItem('dayview-shifts') && JSON.parse(localStorage.getItem('dayview-shifts')).length > 0) {
      this.pushAllShifts = JSON.parse(localStorage.getItem('dayview-shifts'));

      if (this.pushAllShifts.length === 0) {
        this.chosenShift = this.translate.instant('allShifts');
      } else if (this.pushAllShifts.length > 1) {
        this.chosenShift = this.translate.instant('severalOptions');
      } else {
        this.chosenShift = '';
        this.pushAllShifts.forEach((s: any, index: number) => {
          const shiftIndex = this.shifts.findIndex((shift: any) => shift.id == s);
          if (shiftIndex !== -1) {
            this.chosenShift = this.chosenShift + this.shifts[shiftIndex].shift;
          }
        });
      }
    } else {
      this.chosenShift = this.translate.instant('allShifts');
      this.allShiftsChecked = true;
    }

    if (localStorage.getItem('dayview-positions') && JSON.parse(localStorage.getItem('dayview-positions')).length > 0) {
      this.getAllPositions = JSON.parse(localStorage.getItem('dayview-positions'));
      if (this.getAllPositions.length === 0) {
        this.chosenPosition = this.translate.instant('allPositions');
      } else if (this.getAllPositions.length > 1) {
        this.chosenPosition = this.translate.instant('severalOptions');
      } else {
        this.chosenPosition = '';
        this.getAllPositions.forEach((p: any, index: number) => {
          const posIndex = this.positionsSelect.findIndex(
            (pos: any) => pos.id == p
          );

          if (posIndex !== -1) {
            this.chosenPosition =
              this.chosenPosition + this.positionsSelect[posIndex].title;
          }
        });
      }
    } else {
      this.checkAllPositions = true;
      this.chosenPosition = this.translate.instant('allPositions');
    }


    const dateCurrent = this.datePipe.transform(this.currentDate.replace(/-/g, '/'), 'yyyy-MM-dd');
    this.loading = true;
    this.employeesService.assignment(dateCurrent).subscribe((x) => {
      const groupIndex = x.groups.findIndex(
        (g: any) => g.group.id == this.groupId
      );
      if (groupIndex !== -1) {
        this.allAssignments =
          x.groups[groupIndex].dayAssignments[0]?.assignments;
        this.allAssignments?.forEach((a: any) => {
          // this.shiftsService.getInvitationsForShift(a.id).subscribe(
          //   async (invitations: any[]) => {
          //     a.invitationCount = invitations.length;
          //     await new Promise((resolve) => setTimeout(resolve, 200));
          //   }
          // );
        });
        if (this.hideNote) {
          this.assignments = this.allAssignments.filter((a: any) => {
            return a.status != 'CANCELED';
          });
          this.filterAssignments();
          this.sortAssignments();
        } else {
          this.assignments = this.allAssignments;
          this.filterAssignments();
          this.sortAssignments();
        }
        this.assignmentTimings =
          x.groups[groupIndex].dayAssignments[0]?.assignmentTimings;
        this.getStaff(this.assignmentTimings, this.assignments);
        this.getUnavailable();
        let index = 0;
        if (this.allAssignments) {
          this.allAssignments?.forEach((a) => {
            if (
              a.assignedToProfilePicture &&
              a.assignedToProfilePicture != ''
            ) {
            }
            index = index + 1;
          });
        }
      }
    });
  }

  getTimeFromLocal(event: any): void {
    localStorage.setItem(AuthConst.scheduleDate, event);
    this.currentDate = event;
    console.log('gotDate 13 ', this.currentDate);
    this.updateAssignment();
    this.getAnnouncement();
  }

  delete(announcement: AnnouncementModel, index: number): void {
    const confirmDialog = this.dialog.open(TooltipShiftsModalsComponent, {
      disableClose: true,
      width: '290px',
      height: '190px',
      panelClass: 'defaultDialog',
      position: {
        top: '150px',
      },
      data: {
        announcement,
        title: this.translate.instant('deleteAnnouncement'),
        subtitle: this.translate.instant('attention'),
        message: this.translate.instant('deleteShiftsText'),
        mode: 'announcement',
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result && result !== false) {
        this.allAnnouncement.splice(index, 1);
        const findCurrentDateIndex = this.allAnnouncement.findIndex(
          (x: any) => {
            return x.date == this.currentDate;
          }
        );
        if (findCurrentDateIndex == -1) {
          this.showMessage = false;
          this.helper.dateValue.next(this.currentDate);
        } else {
          this.showMessage = true;
        }
      }
    });
  }

  editAnnouncement(announcement: any): void {
    const confirmDialog = this.dialog.open(AddAnnouncementComponent, {
      disableClose: true,
      width: '290px',
      height: 'auto',
      panelClass: 'defaultDialog',
      position: {
        top: '150px',
      },
      data: {
        announcement,
        title: this.translate.instant('publicAnnouncement'),
        inputTitle: this.translate.instant('date'),
        inputSubtitle: this.translate.instant('dateForAnnouncement'),
        messageTitle: this.translate.instant('text'),
        messageSubtitle: this.translate.instant('Addtext'),
        mode: 'update',
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result && result !== false) {
        this.currentDate = this.datePipe.transform(
          this.currentDate,
          'yyyy-MM-dd'
        );
        console.log('gotDate 14 ', this.currentDate);


        const announcementIndex = this.allAnnouncement.findIndex(
          (a: { id: any }) => a.id == result.id
        );

        if (announcementIndex !== -1) {
          this.allAnnouncement.forEach(
            (x: { id: any; date: any; text: any }) => {
              if (x.id == result.id) {
                x.date = result.date;
                x.text = result.text;
              }
            }
          );
          this.allAnnouncement.sort((n1: any, n2: any) => {
            // @ts-ignore
            return new Date(n2.date) - new Date(n1.date);
          });
          this.allAnnouncement = [...this.allAnnouncement];
        }

        const announcementDateIndex = this.allAnnouncement.findIndex(
          (announcements: { date: any }) =>
            announcements.date == this.currentDate
        );

        if (announcementDateIndex !== -1) {
          this.showMessage = true;
          this.announcementMessage = this.allAnnouncement[
            announcementDateIndex
            ].text;
        } else {
          this.showMessage = false;
        }
      }
    });
  }

  getHours(start: any) {
    return new Date(start).getHours();
  }


  sortAssignments(): void {
    if (this.activities.length > 0) {
      if (this.assignments) {
        this.assignments = this.assignments.sort((a1: any, a2: any) => {
            if (new Date(a1.start.replace(/-/g, '/')).getTime() > new Date(a2.start.replace(/-/g, '/')).getTime()) {
              return 1;
            } else if (new Date(a2.start.replace(/-/g, '/')).getTime() > new Date(a1.start.replace(/-/g, '/')).getTime()) {
              return -1;
            } else {
              if (this.calculateMinutes(new Date(a2.end.replace(/-/g, '/')).getHours() + ':' + new Date(a2.end.replace(/-/g, '/')).getMinutes())
                < this.calculateMinutes(new Date(a1.end.replace(/-/g, '/')).getHours() + ':' + new Date(a1.end.replace(/-/g, '/')).getMinutes())) {
                if (new Date(a1.end.replace(/-/g, '/')).getHours() < 12 && new Date(a2.end.replace(/-/g, '/')).getHours() < 12) {
                  return 1;
                } else {
                  return 1;
                }
              } else if (this.calculateMinutes(new Date(a2.end.replace(/-/g, '/')).getHours() + ':' + new Date(a2.end.replace(/-/g, '/')).getMinutes())
                > this.calculateMinutes(new Date(a1.end.replace(/-/g, '/')).getHours() + ':' + new Date(a1.end.replace(/-/g, '/')).getMinutes())) {
                if (new Date(a1.end).getHours() < 12 && new Date(a2.end.replace(/-/g, '/')).getHours() < 12) {
                  return -1;
                } else {
                  return -1;
                }
              } else {
                if (a1.positionName.localeCompare(a2.positionName) > 0) {
                  return 1;
                } else if (a1.positionName.localeCompare(a2.positionName) < 0) {
                  return -1;
                } else {
                  if (a1.clinical == a2.clinical) {
                    if (a1.status > a2.status) {
                      return 1;
                    } else if (a2.status > a1.status) {
                      return -1;
                    } else {
                      if (a1.assignedToName && a2.assignedToName) {
                        const a1FirstName = a1.assignedToName.split(' ')[0];
                        const a2FirstName = a2.assignedToName.split(' ')[0];
                        if (a1FirstName.localeCompare(a2FirstName, 'sv') > 0) {
                          return 1;
                        } else if (a1FirstName.localeCompare(a2FirstName, 'sv') < 0) {
                          return -1;
                        } else {
                          return 0;
                        }

                      } else {
                        return 0;
                      }
                    }
                  } else if (a1.clinical) {
                    return -1;
                  } else {
                    return 1;
                  }
                }
              }
            }
          }
        );

        this.assignments = [...this.assignments];
        this.assignments.forEach((a: any, index: any) => {
          a.activitiesString = this.getActivityShortName(a);
          this.positions?.forEach((p: any) => {
            if (p?.id == a.position) {
              a.positionShortName = p?.shortTitle;
            }
          });
        });
      }
      // this.saveAssignmentsToLocalStorage(this.assignments);
      this.loading = false;
      this.notifyPuppeteer();
    } else {

      this.activitySubscription = this.employeesService
        .userActvity()
        .subscribe((x) => {
          x.forEach((group: any) => {
            const groupId = Number(localStorage.getItem(AuthConst.gruopId));
            if (
              group.groupId === groupId
            ) {
              this.activities.push(group);
            }
          });


          if (this.assignments) {
            this.assignments = this.assignments.sort((a1: any, a2: any) => {
                if (new Date(a1.start.replace(/-/g, '/')).getTime() > new Date(a2.start.replace(/-/g, '/')).getTime()) {
                  return 1;
                } else if (new Date(a2.start.replace(/-/g, '/')).getTime() > new Date(a1.start.replace(/-/g, '/')).getTime()) {
                  return -1;
                } else {
                  if (this.calculateMinutes(new Date(a2.end.replace(/-/g, '/')).getHours() + ':' + new Date(a2.end.replace(/-/g, '/')).getMinutes())
                    < this.calculateMinutes(new Date(a1.end.replace(/-/g, '/')).getHours() + ':' + new Date(a1.end.replace(/-/g, '/')).getMinutes())) {
                    if (new Date(a1.end.replace(/-/g, '/')).getHours() < 12 && new Date(a2.end.replace(/-/g, '/')).getHours() < 12) {
                      return 1;
                    } else {
                      return 1;
                    }
                  } else if (this.calculateMinutes(new Date(a2.end.replace(/-/g, '/')).getHours() + ':' + new Date(a2.end.replace(/-/g, '/')).getMinutes())
                    > this.calculateMinutes(new Date(a1.end.replace(/-/g, '/')).getHours() + ':' + new Date(a1.end.replace(/-/g, '/')).getMinutes())) {
                    if (new Date(a1.end).getHours() < 12 && new Date(a2.end.replace(/-/g, '/')).getHours() < 12) {
                      return -1;
                    } else {
                      return -1;
                    }
                  } else {
                    if (a1.positionName.localeCompare(a2.positionName) > 0) {
                      return 1;
                    } else if (a1.positionName.localeCompare(a2.positionName) < 0) {
                      return -1;
                    } else {
                      if (a1.clinical == a2.clinical) {
                        if (a1.status > a2.status) {
                          return 1;
                        } else if (a2.status > a1.status) {
                          return -1;
                        } else {
                          if (a1.assignedToName && a2.assignedToName) {
                            const a1FirstName = a1.assignedToName.split(' ')[0];
                            const a2FirstName = a2.assignedToName.split(' ')[0];
                            if (a1FirstName.localeCompare(a2FirstName, 'sv') > 0) {
                              return 1;
                            } else if (a1FirstName.localeCompare(a2FirstName, 'sv') < 0) {
                              return -1;
                            } else {
                              return 0;
                            }

                          } else {
                            return 0;
                          }
                        }
                      } else if (a1.clinical) {
                        return -1;
                      } else {
                        return 1;
                      }
                    }
                  }
                }
              }
            );

            this.assignments = [...this.assignments];
            this.assignments.forEach((a: any, index: any) => {
              a.activitiesString = this.getActivityShortName(a);
              this.positions?.forEach((p: any) => {
                if (p?.id == a.position) {
                  a.positionShortName = p?.shortTitle;
                }
              });
            });
          }
          // this.saveAssignmentsToLocalStorage(this.assignments);
          this.loading = false;
          this.notifyPuppeteer();

        });
    }
  }

  toggleCreateShift(): void {
    this.newShift = true;
    localStorage.setItem(AuthConst.scheduleDate, this.currentDate);
    setTimeout(() => {
      this.helper.showCalendar2$.next(true);
    }, 100);
  }

  showAllPositions(): void {
    this.showPositions = !this.showPositions;
  }

  positionIsChecked(positionId: number): boolean {
    const index = this.getAllPositions.findIndex((p) => p === positionId);
    if (this.getAllPositions.length === this.positionsSelect.length) {
      return !(index === -1) && !this.checkAllPositions;
    } else {
      return !(index === -1) && !this.checkAllPositions;
    }
  }

  getPositions(position: any, event: any, all: string): void {
    event.preventDefault();
    if (position.id == -1) {
      this.checkAllPositions = true;
      this.getAllPositions = [];
    } else {
      this.checkAllPositions = false;
      const index = this.getAllPositions.findIndex((p) => p === position.id);
      if (index === -1) {
        this.getAllPositions.push(position.id);
      } else {
        this.getAllPositions.splice(index, 1);
      }
      this.selectedPositionId = position.id;
      if (this.getAllPositions.length === this.positionsSelect.length) {
        this.checkAllPositions = false;
      }
    }

    if (this.getAllPositions.length === 0) {
      this.getAllPositions = [];
      this.checkAllPositions = true;
    }


    // if (this.getAllPositions.length >= this.positionsSelect.length) {
    //   this.chosenPosition = this.translate.instant('allPositions');
    //   this.checkAllPositions = true;
    // } else
    if (this.getAllPositions.length === 0) {
      this.chosenPosition = this.translate.instant('allPositions');
    } else if (this.getAllPositions.length > 1) {
      this.chosenPosition = this.translate.instant('severalOptions');
    } else {
      this.chosenPosition = '';
      this.getAllPositions.forEach((p: any, index: number) => {
        const posIndex = this.positionsSelect.findIndex(
          (pos: any) => pos.id == p
        );

        if (posIndex !== -1) {
          this.chosenPosition =
            this.chosenPosition + this.positionsSelect[posIndex].title;
        }
      });
    }
    localStorage.setItem('dayview-positions', JSON.stringify(this.getAllPositions));
    this.filterAssignments();
    this.sortAssignments();
  }

  isCheckedShift(shift: any): boolean {
    const index = this.pushAllShifts.findIndex((s: any) => s == shift.id);
    if (this.pushAllShifts.length === this.shifts.length) {
      return !(index === -1) && !this.allShiftsChecked;
    } else {
      return !(index === -1) && !this.allShiftsChecked;
    }
  }

  isCheckedList(list: any): boolean {
    const index = this.selectedLists.findIndex((s: any) => s == list.id);
    if (this.selectedLists.length === this.lists.length) {
      return !(index === -1) && !this.allListsChecked;
    } else {
      return !(index === -1) && !this.allListsChecked;
    }
  }


  calculateMinutes(time: string): number {
    const hours = Number(time.replace(/-/g, '/').split(':')[0]);
    const minutes = Number(time.replace(/-/g, '/').split(':')[1]);

    return hours * 60 + minutes;
  }

  getList(list: any, event: any, all: string): void {
    event.preventDefault();
    if (this.allListsChecked && list.id == -1) {
      this.selectedLists = [];
      this.lists.forEach((s: any) => {
        this.selectedLists.push(s.id);
      });
    } else {
      if (list.id == -1) {
        this.selectedLists = [];
      } else {
        this.allListsChecked = false;
        const index = this.selectedLists.findIndex((s: any) => {
          return s == list.id;
        });

        if (index === -1) {
          this.selectedLists.push(list.id);
          if (this.selectedLists.length === this.lists.length) {
            this.allListsChecked = false;
          }
        } else {
          this.selectedLists.splice(index, 1);
        }
      }
    }
    if (this.selectedLists.length === 0) {
      this.selectedLists = [];
      this.allListsChecked = true;
    }

    if (this.selectedLists.length === 0) {
      this.chosenList = this.translate.instant('allLists');
    } else if (this.selectedLists.length > 1) {
      this.chosenList = this.translate.instant('severalOptions');
    } else {
      this.chosenList = '';
      this.selectedLists.forEach((s: any, index: number) => {
        const listIndex = this.lists.findIndex((optionList: any) => optionList.id == s);
        if (listIndex !== -1) {
          this.chosenList = this.chosenList + this.lists[listIndex].title;
        }
      });
    }
    localStorage.setItem('dayview-lists', JSON.stringify(this.selectedLists));
    if (this.mode == 1) {
      this.filterAssignments();
      this.sortAssignments();

    } else {
      this.helper.monthDataCalculate.next();
      this.mode = 2;
    }
  }

  getShift(shift: any, event: any, all: string): void {
    event.preventDefault();
    if (this.allShiftsChecked && shift.id == -1) {
      this.pushAllShifts = [];
      this.shifts.forEach((s: any) => {
        this.pushAllShifts.push(s.id);
      });
    } else {
      if (shift.id == -1) {
        this.pushAllShifts = [];
      } else {
        this.allShiftsChecked = false;
        const index = this.pushAllShifts.findIndex((s: any) => {
          return s == shift.id;
        });

        if (index === -1) {
          this.pushAllShifts.push(shift.id);
          if (this.pushAllShifts.length === this.shifts.length) {
            this.allShiftsChecked = false;
          }
        } else {
          this.pushAllShifts.splice(index, 1);
        }
      }
    }
    if (this.pushAllShifts.length === 0) {
      this.pushAllShifts = [];
      this.allShiftsChecked = true;
    }

    if (this.pushAllShifts.length === 0) {
      this.chosenShift = this.translate.instant('allShifts');
    } else if (this.pushAllShifts.length > 1) {
      this.chosenShift = this.translate.instant('severalOptions');
    } else {
      this.chosenShift = '';
      this.pushAllShifts.forEach((s: any, index: number) => {
        const shiftIndex = this.shifts.findIndex((shift: any) => shift.id == s);
        if (shiftIndex !== -1) {
          this.chosenShift = this.chosenShift + this.shifts[shiftIndex].shift;
        }
      });
    }
    localStorage.setItem('dayview-shifts', JSON.stringify(this.pushAllShifts));
    this.filterAssignments(); // on reload after change made 2x
    this.sortAssignments();

  }


  checkAssignmentTypes(code: any, type: any): boolean {
    if (type == 'SWING_1') {
      if (code == 'DAY_ASSIGNMENT') {
        return true;
      }
    }

    if (type == 'SWING_2') {
      if (code == 'EVENING_ASSIGNMENT') {
        return true;
      }
    }

    return code == type;
  }

  filterAssignments(): void {
    let selectedPositions: any[] = [];
    let selectedShifts: any[] = [];
    let selectedLists: any[] = [];


    if (this.allShiftsChecked) {
      this.shifts.forEach((s: any) => {
        selectedShifts.push(s.id);
      });
    } else {
      selectedShifts = this.pushAllShifts;
    }

    if (this.allListsChecked) {
      this.lists.forEach((s: any) => {
        selectedLists.push(s.id);
      });
    } else {
      selectedLists = this.selectedLists;
    }


    if (this.checkAllPositions) {
      this.positionsSelect.forEach((p: any) => {
        selectedPositions.push(p.id);
      });
    } else {
      selectedPositions = this.getAllPositions;
    }

    this.assignments = [];
    if (!this.allShiftsChecked || !this.checkAllPositions || !this.allListsChecked) {
      if (this.allShiftsChecked) {
        this.allAssignments?.forEach((a: any) => {
          if (
            selectedPositions.includes(a.position)
            && (selectedLists.includes(a.listId) || selectedLists.length === 0 || this.allListsChecked)
          ) {
            if (this.hideNote) {
              if (a.status != 'CANCELED') {
                this.assignments.push(a);
                this.sortAssignments();
              }
            } else {
              this.assignments.push(a);
              this.sortAssignments();
            }
          }
        });
      } else {
        this.allAssignments?.forEach((a: any) => {
          selectedShifts.forEach((shiftId: any) => {
            const shiftIndex = this.shifts.findIndex((s: any) => s.id == shiftId);
            if (shiftIndex != -1) {
              this.assignmentTimings?.forEach((t: any) => {
                if (t.start.replace(/-/g, '/') <= t.end.replace(/-/g, '/')) {
                  if (this.checkAssignmentTypes(this.shifts[shiftIndex].code, t.type)) {
                    const date = new Date(a.start.replace(/-/g, '/')).toDateString();
                    if (
                      this.calculateMinutes(
                        new Date(a.start.replace(/-/g, '/')).getHours() +
                        ':' +
                        new Date(a.start.replace(/-/g, '/')).getMinutes()
                      ) >=
                      this.calculateMinutes(
                        new Date(date.replace(/-/g, '/') + ' ' + t.start.replace(/-/g, '/')).getHours() +
                        ':' +
                        new Date(date.replace(/-/g, '/') + ' ' + t.start.replace(/-/g, '/')).getMinutes()
                      ) &&
                      this.calculateMinutes(
                        new Date(a.start.replace(/-/g, '/')).getHours() +
                        ':' +
                        new Date(a.start.replace(/-/g, '/')).getMinutes()
                      ) <=
                      this.calculateMinutes(
                        new Date(date.replace(/-/g, '/') + ' ' + t.end.replace(/-/g, '/')).getHours() +
                        ':' +
                        new Date(date.replace(/-/g, '/') + ' ' + t.end.replace(/-/g, '/')).getMinutes()
                      ) &&
                      selectedPositions.includes(a.position)
                      && (selectedLists.includes(a.listId) || selectedLists.length === 0 || this.allListsChecked)
                    ) {
                      if (this.hideNote) {
                        if (a.status != 'CANCELED') {
                          this.assignments.push(a);
                          this.sortAssignments();
                        }
                      } else {
                        this.assignments.push(a);
                        this.sortAssignments();
                      }
                    }
                  }
                } else {
                  if (this.checkAssignmentTypes(this.shifts[shiftIndex].code, t.type)) {
                    const date = new Date(a.start).toDateString();
                    if (
                      this.calculateMinutes(
                        new Date(a.start).getHours() +
                        ':' +
                        new Date(a.start).getMinutes()
                      ) >=
                      this.calculateMinutes(
                        new Date(date + ' ' + t.start).getHours() +
                        ':' +
                        new Date(date + ' ' + t.start).getMinutes()
                      ) &&
                      selectedPositions.includes(a.position)
                      && (selectedLists.includes(a.listId) || selectedLists.length === 0 || this.allListsChecked)
                    ) {
                      if (this.hideNote) {
                        if (a.status != 'CANCELED') {
                          this.assignments.push(a);
                          this.sortAssignments();
                        }
                      } else {
                        this.assignments.push(a);
                        this.sortAssignments();
                      }
                    }
                  }
                }
              });
            }
          });
        });
      }
    } else {
      if (this.hideNote) {
        this.assignments = this.allAssignments.filter((a: any) => {
          return a.status != 'CANCELED';
        });
        this.sortAssignments();
      } else {
        this.assignments = this.allAssignments;
        this.sortAssignments();
      }
    }
  }

  showAllShifts(): void {
    this.showShifts = !this.showShifts;
  }

  showAllLists(): void {
    this.showLists = !this.showLists;
  }

  refresh(): void {
    if (this.mode === 1) {
      window.location.reload();
      this.currentDate = localStorage.getItem(AuthConst.scheduleDate);
      console.log('gotDate 15 ', this.currentDate);

    } else if (this.mode === 2) {
      this.helper.loadingTime.next(false);
      this.helper.dateValue.next(this.currentDate);
    }
  }

  monthlyView(): void {
    if (this.mode === 1) {
      setTimeout(() => {
        this.helper.dateValue.next(this.currentDate);
      }, 250);

      this.mode = 2;
    } else if (this.mode === 2) {
      this.mode = 1;
      this.filterAssignments();
      this.sortAssignments();

    }
  }

  previousDay(displayedDate: any): void {
    this.staffingGoalsActiveValue = false;
    this.assignmentSupscription?.unsubscribe();
    clearTimeout(this.previousTimeout);
    if (
      this.staffingGoalsActiveValueMonth === true
    ) {
      this.assignments = [];
      this.allAssignments = [];
      this.currentDate = new Date(displayedDate);
      console.log('gotDate 16 ', this.currentDate);

      this.helper.loadingTime.next(false);
      const toDay = new Date();
      this.currentDate = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() - 1,
        1
      );
      console.log('gotDate 17 ', this.currentDate);


      localStorage.setItem(AuthConst.scheduleDate, this.currentDate);

      if (
        this.currentDate.getMonth() === toDay.getMonth() &&
        this.currentDate.getFullYear() === toDay.getFullYear()
      ) {
        this.currentDate = toDay;
        console.log('gotDate 18 ', this.currentDate);

      }
      const dateForApi = this.datePipe.transform(
        this.currentDate,
        'yyyy-MM-dd'
      );

      this.previousTimeout = setTimeout(() => {
        if (!this.clickedArrow) {
          // this.clickedArrow = true;
          // this.findAssignmentsFromLocalStorage(dateForApi);
          if (this.mode === 1) {
            this.assignmentSupscription = this.employeesService.assignment(dateForApi).subscribe(
              (res) => {
                const groupIndex = res.groups.findIndex(
                  (g: any) => g.group.id == this.groupId
                );
                if (groupIndex !== -1) {
                  this.allAssignments =
                    res.groups[groupIndex].dayAssignments[0]?.assignments;
                  this.findUsers = [];
                  this.allAssignments?.forEach((user: {
                    contractTitle: any;
                    positionShortTitle?: any;
                    assignedToId: number
                  }) => {
                    if (user) {
                      // this.userService
                      //   .getEmployeeById(user.assignedToId)
                      //   .subscribe((users) => {
                      //     if (users && users?.userAuthorities?.roles?.EMPLOYEE) {
                      //       user.positionShortTitle = users?.userAuthorities?.roles?.EMPLOYEE[0].position?.positionShortTitle;
                      //       user.contractTitle = users?.contractTitle;
                      //       this.findUsers?.push(
                      //         users?.userAuthorities?.roles?.EMPLOYEE[0]
                      //       );
                      //     }
                      //   });
                    }
                  });
                  this.allAssignments?.forEach((a: any) => {
                    // this.shiftsService.getInvitationsForShift(a.id).subscribe(
                    //   async (invitations: any[]) => {
                    //     a.invitationCount = invitations.length;
                    //     await new Promise((resolve) => setTimeout(resolve, 200));
                    //   }
                    // );
                  });
                  // if (this.hideNote) {
                  //   this.assignments = this.allAssignments.filter((a: any) => {
                  //     return a.status != 'CANCELED';
                  //   });
                  // } else {
                  //   this.assignments = this.allAssignments;
                  // }

                  this.assignmentTimings =
                    res.groups[groupIndex].dayAssignments[0]?.assignmentTimings;
                  this.filterAssignments();
                  this.sortAssignments();
                  this.getStaff(this.assignmentTimings, this.assignments);
                  this.sortedStaff();
                }


                // this.clickedArrow = false;
              },
              () => {
              }
            );
          }
          const start = new Date(this.currentDate?.getFullYear(), 0, 1);
          this.helper.dateValue.next(this.currentDate);
          this.calculateWeek(this.currentDate);

          this.helper.dateValue.next(this.currentDate);
          this.getUnavailable();
          this.sortedStaff();

        }
      }, 500);
    } else {
      this.assignments = [];
      this.allAssignments = [];
      this.currentDate = new Date(displayedDate);
      console.log('gotDate 19 ', this.currentDate);

      this.currentDate.setDate(this.currentDate.getDate() - 1);
      localStorage.setItem(AuthConst.scheduleDate, this.currentDate);

      const dateForApi = this.datePipe.transform(
        this.currentDate,
        'yyyy-MM-dd'
      );

      const dateIndex = this.allAnnouncement.findIndex(
        (announcements: { date: string }) => announcements.date === dateForApi
      );

      if (dateIndex !== -1) {
        this.showMessage = true;
        this.announcementMessage = this.allAnnouncement[dateIndex].text;
      } else {
        this.showMessage = false;
      }

      this.previousTimeout = setTimeout(() => {
        this.loading = true;
        if (!this.clickedArrow) {
          // this.clickedArrow = true;
          // this.findAssignmentsFromLocalStorage(dateForApi);
          this.assignmentSupscription = this.employeesService.assignment(dateForApi).subscribe(
            (res) => {
              // this.clickedArrow = false;
              const groupIndex = res.groups.findIndex(
                (g: any) => g.group.id == this.groupId
              );
              if (groupIndex !== -1) {
                this.allAssignments =
                  res.groups[groupIndex].dayAssignments[0]?.assignments;
                this.findUsers = [];
                this.allAssignments?.forEach((user: {
                  contractTitle: any;
                  positionShortTitle: any;
                  assignedToId: number
                }) => {
                  if (user) {
                    // this.userService
                    //   .getEmployeeById(user.assignedToId)
                    //   .subscribe((users) => {
                    //     if (users && users?.userAuthorities?.roles?.EMPLOYEE) {
                    //       user.positionShortTitle = users?.userAuthorities?.roles?.EMPLOYEE[0].position?.positionShortTitle;
                    //       user.contractTitle = users?.contractTitle;
                    //       this.findUsers?.push(
                    //         users?.userAuthorities?.roles?.EMPLOYEE[0]
                    //       );
                    //     }
                    //   });
                  }
                });
                this.allAssignments?.forEach((a: any) => {
                  // this.shiftsService.getInvitationsForShift(a.id).subscribe(
                  //   async (invitations: any[]) => {
                  //     a.invitationCount = invitations.length;
                  //     await new Promise((resolve) => setTimeout(resolve, 200));
                  //   }
                  // );
                });

                // if (this.hideNote) {
                //   this.assignments = this.allAssignments.filter((a: any) => {
                //     return a.status != 'CANCELED';
                //   });
                // } else {
                //   this.assignments = this.allAssignments;
                // }

                this.assignmentTimings =
                  res.groups[groupIndex].dayAssignments[0]?.assignmentTimings;
                this.filterAssignments();
                this.sortAssignments();
                this.getStaff(this.assignmentTimings, this.assignments);
              }
            },
            () => {

              // this.clickedArrow = false;
            }
          );
          const start = new Date(this.currentDate?.getFullYear(), 0, 1);
          this.helper.dateValue.next(this.currentDate);
          this.calculateWeek(this.currentDate);

          if (dateIndex !== -1) {
            this.showMessage = true;
            this.announcementMessage = this.allAnnouncement[dateIndex].text;
          } else {
            this.showMessage = false;
          }

          const callDateForApiPre = new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth(),
            this.currentDate.getDate() + 1
          )
            .toISOString()
            .substring(0, 10);
          // this.shiftsService
          //   .shiftColor(callDateForApiPre, callDateForApiPre)
          //   .subscribe((response: any) => {
          //     const resp = response;
          //     for (const oneOf of response.groups) {
          //       if (oneOf.group.id == this.groupId) {
          //         this.colorArray = oneOf.dayAssignments;
          //       }
          //     }
          //   });
          this.getUnavailable();
          this.sortedStaff();

        }
      }, 500);
    }

  }

  nextDay(displayedDate: any): void {
    this.staffingGoalsActiveValue = false;
    clearTimeout(this.nextTimeout);
    this.assignmentSupscription?.unsubscribe();
    if (
      this.staffingGoalsActiveValueMonth === true
    ) {
      this.assignments = [];
      this.allAssignments = [];
      this.currentDate = new Date(displayedDate);
      console.log('gotDate 20 ', this.currentDate);

      this.helper.loadingTime.next(false);
      const toDay = new Date();
      this.currentDate = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() + 1,
        1
      );
      console.log('gotDate 21 ', this.currentDate);


      if (
        this.currentDate.getMonth() === toDay.getMonth() &&
        this.currentDate.getFullYear() === toDay.getFullYear()
      ) {
        this.currentDate = toDay;
        console.log('gotDate 22 ', this.currentDate);

      }

      const dateForApi = this.datePipe.transform(
        this.currentDate,
        'yyyy-MM-dd'
      );
      this.nextTimeout = setTimeout(() => {
        // this.findAssignmentsFromLocalStorage(dateForApi);
        if (this.mode === 1) {
          this.assignmentSupscription = this.employeesService.assignment(dateForApi).subscribe(
            (res) => {
              const groupIndex = res.groups.findIndex(
                (g: any) => g.group.id == this.groupId
              );
              if (groupIndex !== -1) {
                this.allAssignments =
                  res.groups[groupIndex].dayAssignments[0]?.assignments;
                this.findUsers = [];
                this.assignmentTimings =
                  res.groups[groupIndex].dayAssignments[0]?.assignmentTimings;
                this.filterAssignments();
                this.sortAssignments();
                this.getStaff(this.assignmentTimings, this.assignments);
              }
            },
            () => {
            }
          );
        }
        this.calculateWeek(this.currentDate);


        this.helper.dateValue.next(this.currentDate);
        if (this.mode === 1) {
          this.getUnavailable();
        }
      }, 500);
    } else {

      this.assignments = [];
      this.allAssignments = [];
      this.currentDate = new Date(displayedDate);
      console.log('gotDate 23 ', this.currentDate);

      this.currentDate.setDate(this.currentDate.getDate() + 1);
      localStorage.setItem(AuthConst.scheduleDate, this.currentDate);
      const dateForApi = this.datePipe.transform(
        this.currentDate,
        'yyyy-MM-dd'
      );

      const dateIndex = this.allAnnouncement.findIndex(
        (announcements: { date: string }) => announcements.date === dateForApi
      );

      if (dateIndex !== -1) {
        this.showMessage = true;
        this.announcementMessage = this.allAnnouncement[dateIndex].text;
      } else {
        this.showMessage = false;
      }

      this.nextTimeout = setTimeout(() => {
        this.loading = true;
        // this.findAssignmentsFromLocalStorage(dateForApi);
        this.assignmentSupscription = this.employeesService.assignment(dateForApi).subscribe(
          (res) => {
            const groupIndex = res.groups.findIndex(
              (g: any) => g.group.id == this.groupId
            );
            if (groupIndex !== -1) {
              this.allAssignments =
                res.groups[groupIndex].dayAssignments[0]?.assignments;
              this.findUsers = [];
              this.allAssignments?.forEach((user: {
                contractTitle: any;
                positionShortTitle: any;
                assignedToId: number
              }) => {
                if (user) {
                  // this.userService
                  //   .getEmployeeById(user.assignedToId)
                  //   .subscribe((users) => {
                  //     if (users && users?.userAuthorities?.roles?.EMPLOYEE) {
                  //       user.positionShortTitle = users?.userAuthorities?.roles?.EMPLOYEE[0].position?.positionShortTitle;
                  //       user.contractTitle = users?.contractTitle;
                  //       this.findUsers?.push(
                  //         users?.userAuthorities?.roles?.EMPLOYEE[0]
                  //       );
                  //     }
                  //   });
                }
              });
              this.allAssignments?.forEach((a: any) => {
                // this.shiftsService.getInvitationsForShift(a.id).subscribe(
                //   async (invitations: any[]) => {
                //     a.invitationCount = invitations.length;
                //     await new Promise((resolve) => setTimeout(resolve, 200));
                //   }
                // );
              });
              this.assignmentTimings =
                res.groups[groupIndex].dayAssignments[0]?.assignmentTimings;
              this.filterAssignments();
              this.sortAssignments();
              this.getStaff(this.assignmentTimings, this.assignments);
              this.sortedStaff();
            }
          },
          () => {
          }
        );

        this.calculateWeek(this.currentDate);


        const callDateForApi = new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth(),
          this.currentDate.getDate() + 1
        )
          .toISOString()
          .substring(0, 10);
        // this.shiftsService
        //   .shiftColor(callDateForApi, callDateForApi)
        //   .subscribe((response: any) => {
        //     const resp = response;
        //     for (const oneOf of response.groups) {
        //       if (oneOf.group.id == this.groupId) {
        //         this.colorArray = oneOf.dayAssignments;
        //       }
        //     }
        //   });
        if (this.mode === 1) {

          this.getUnavailable();
        }
      }, 500);
    }

  }

  // tslint:disable-next-line:typedef
  getMargin(person: any) {
    let boxWidth;
    if (this.innerWidth < 1271) {
      boxWidth = 490;
    } else {
      boxWidth = 621;
    }
    const minut = 1570; // 24 h + 30 min
    const oneMinutWidth = boxWidth / minut;

    const startTimeHour = Number(moment(new Date(person.start.replace(/-/g, '/'))).format('k'));
    const startTimeMinute = Number(moment(new Date(person.start.replace(/-/g, '/'))).format('m'));

    const timeInMinutes = Number(startTimeHour) * 60 + Number(startTimeMinute);

    return oneMinutWidth * (timeInMinutes - 7 * 60); // minus 7 * 60 ( 300 min) bc box starts at 7 and closes at 7 next day (not 0-24)
  }

  calculateWeek(currentDate: any) {
    // const start = new Date(currentDate?.getFullYear(), 0, 1);
    // this.helper.dateValue.next(currentDate);
    //
    // this.week = Math.ceil(
    //   ((currentDate - start.getTime()) / 86400000 + start.getDay() - 1) /
    //   7 -
    //   1
    // );

    // const date = new Date(currentDate);
    // const startDate = new Date(date.getFullYear(), 0, 1);
    // const days = Math.floor((currentDate - startDate.getTime()) /
    //   (24 * 60 * 60 * 1000));

    // this.week = Math.ceil(days / 7);

    this.week = moment(currentDate).isoWeek();

    console.log(currentDate, "currentDate")
    // console.log(weeknumber);
  }

  calculateShiftLength(shift: any): number {
    const start = new Date(shift.start);
    const end = new Date(shift.end);

    return Math.round(Math.abs(start.getTime() - end.getTime()) / 36e5);
  }

  getInfoMargin(shift: any): number {
    if (this.calculateShiftLength(shift) < 2) {
      return this.getWidth(shift) + 20;
    } else {
      return 0;
    }
  }

  getColors(person: any): { background: string; border: string; font: string } {
    const activityColor = person.activityColor;
    let open = false;
    const clinical = person.clinical;
    person.status === 'PUBLISHED' ? (open = true) : (open = false);
    const positionColor = '#' + person.positionColor;
    const obj: { background: string; border: string; font: string } = {
      background: positionColor,
      border: positionColor,
      font: 'white',
    };

    if (activityColor == null || activityColor == '') {
      // no activity color

      if (!open) {
        if (clinical) {
          if (person.status === 'CANCELED') {
            obj.background = positionColor;
            obj.border = positionColor;
            obj.font = 'red';

          } else {
            obj.background = positionColor;
            obj.border = positionColor;
            obj.font = 'white';
          }
        } else {
          obj.background = '#F4F4F4';
          obj.border = '#F4F4F4';
          obj.font = positionColor;
        }
      } else {
        if (person.id == 789862) {
          console.log('IS OPEN');
        }
        obj.background = 'white';
        obj.border = positionColor;
        obj.font = positionColor;
      }
    } else {
      // has activityColor
      if (!open) {
        // if (clinical) {
        //   obj.background = positionColor;
        //   obj.border = positionColor;
        //   obj.font = 'white';
        // } else {
        //   obj.background = activityColor;
        //   obj.border = activityColor;
        //   obj.font = 'white';
        // }
        if (person.status === 'CANCELED') {
          obj.background = activityColor;
          obj.border = activityColor;
          obj.font = 'red';
        } else {
          obj.background = activityColor;
          obj.border = activityColor;
          obj.font = 'white';
        }

      } else {
        if (clinical) {
          obj.background = 'white';
          obj.border = positionColor;
          obj.font = positionColor;
        } else {
          obj.background = 'white';
          obj.border = activityColor;
          obj.font = activityColor;
        }
      }
    }
    if (obj.background.startsWith('##')) {
      obj.background = obj.background.substring(1);
    }

    if (obj.border.startsWith('##')) {
      obj.border = obj.border.substring(1);
    }

    if (obj.font.startsWith('##')) {
      obj.font = obj.font.substring(1);
    }

    if (person.status === 'CANCELED') {
      obj.font = 'red';
    }

    return obj;
  }

  // tslint:disable-next-line:typedef
  getWidth(person: any) {
    let boxWidth;
    if (this.innerWidth < 1271) {
      boxWidth = 490;
    } else {
      boxWidth = 621;
    }
    const minut = 1570; // 24h + 30min
    const oneMinutWidth = boxWidth / minut;

    const startTimeHour = moment(new Date(person.start.replace(/-/g, '/'))).format('k');
    const startTimeMinute = moment(new Date(person.start.replace(/-/g, '/'))).format('m');

    const endTimeHour = moment(new Date(person.end.replace(/-/g, '/'))).format('k');
    const endTimeMinute = moment(new Date(person.end.replace(/-/g, '/'))).format('m');

    const startInMinutes = Number(startTimeHour) * 60 + Number(startTimeMinute);
    let endTimeInMinutes = Number(endTimeHour) * 60 + Number(endTimeMinute);

    let timeDiff = 0;


    if (endTimeInMinutes < startInMinutes) {
      endTimeInMinutes = endTimeInMinutes + 1440;
    }

    timeDiff = Math.abs(startInMinutes - endTimeInMinutes);


    const width = timeDiff * oneMinutWidth;
    return width;
  }

  shouldCloseTooltip(person: number): void {
    if (this.cardClicked[person]) {
      this.cardHover[person] = false;
      this.cardClicked[person] = false;
      this.personId = undefined;
    }
  }

  assignShift(person: any): void {
    const shiftObj = {
      id: person.id,
      name: '',
      groupId: person.groupId,
      start: this.datePipe.transform(person.start.replace(/-/g, '/'), 'HH:mm').toString(),
      end: this.datePipe.transform(person.end.replace(/-/g, '/'), 'HH:mm').toString(),
      positionId: person.position,
      activityId: person.activity1Id,
      competenceId: person.competence1Id,
      undisclosedNote: person.description,
      disclosedNote: person.employerNote,
      activity2Id: person.activity2Id,
      start2: person.activity2Start
        ? this.datePipe.transform(person.activity2Start.replace(/-/g, '/'), 'HH:mm').toString()
        : '',
      end2: person.activity2End
        ? this.datePipe.transform(person.activity2End.replace(/-/g, '/'), 'HH:mm').toString()
        : '',
      color: person.positionColor,
      clinical: person.clinical,
      applicationsCount: person.nrOfAppliedApplications,
      nrOfAppliedApplications: person.nrOfAppliedApplications,
      type: 2,
      status: person.status,
      date: this.datePipe.transform(person.start.replace(/-/g, '/'), 'yyyy-MM-dd').toString(),
    };
    localStorage.setItem('selectedShift', JSON.stringify(shiftObj));
    this.route.navigate(['assignment/details']);
  }

  /**
   *
   *  Open inbox
   */
  getPerson(person: any): void {
    let topPosition = '100px';
    if (this.innerHeight < 738) {
      topPosition = '20px';
    }

    if (this.innerHeight <= 638) {
      topPosition = '0px';
    }
    this.actualShift = person;
    const confirmDialog = this.dialog.open(ShiftDeteilsComponent, {
      disableClose: true,
      width: '560px',
      height: '638px',
      panelClass: 'defaultDialog',
      position: {
        top: topPosition
      },
      data: {
        person,
      },
    });
    confirmDialog.afterClosed().subscribe((result: any) => {
      this.actualShift = null;

      // setTimeout(() => {
      //   this.helper.recoveredShift$.subscribe((x) => x);
      // }, 500);

      if (result && result.status === 'PUBLISHED') {
        const index = this.allAssignments.findIndex((x) => x.id === result.id);
        if (index !== -1) {
          this.allAssignments[index] = result;
        }
      } else if (result && result.status === 'CANCELED') {
        const index = this.allAssignments.findIndex((x) => x.id === result.id);
        if (index !== -1) {
          // this.allAssignments.splice(index, 1);
        }
      }
    });
  }

  cardTooltip(person: number): void {
    setTimeout(() => {
      this.cardClicked[person] = true;
      this.cardHover[person] = true;
      if (this.personId) {
        this.personId = undefined;
      } else {
        this.personId = person;
      }
    }, 300);
  }

  openCopyModal(person: any): void {
    this.actualShift = person;
    this.shouldCloseTooltip(person.id);
    this.actualShift = person;
    const confirmDialog = this.dialog.open(TooltipShiftsModalsComponent, {
      disableClose: true,
      width: '290px',
      height: '190px',
      panelClass: 'defaultDialog',
      position: {
        top: '150px',
      },
      data: {
        title: this.translate.instant('copyShifts'),
        message: this.translate.instant('copyShiftsText'),
        mode: 'copy',
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      this.actualShift = null;
      if (result) {
        const shiftToSend = JSON.parse(JSON.stringify(person));
        shiftToSend.status = 1;
        shiftToSend.assignedToId = null;
        shiftToSend.cancelReasonId = null;
        shiftToSend.disclosedNote = null;
        shiftToSend.description = null;
        shiftToSend.employerNote = null;
        shiftToSend.positionId = shiftToSend.position;
        shiftToSend.id = null;
        this.shiftsService
          .createNewShift(shiftToSend)
          .subscribe((data: any) => {
            // this.saveAssignmentsToLocalStorage([data]);
            window.location.reload();
            //   'LOKALSTORAGE',
            //   localStorage.getItem(AuthConst.scheduleDate)
            // );
            setTimeout(() => {
              this.snackBar.open(this.translate.instant('copied'), 'OK', {
                duration: 3000,
              });
              this.allAssignments.push(data);
            }, 300);

            // this.assignments.push(data);
          });
      }
    });
  }

  findStatus(status: string): number {
    if (status === 'PUBLISHED') {
      return 1;
    } else if (status === 'UNPUBLISHED') {
      return 2;
    } else if (status === 'ASSIGNED') {
      return 3;
    } else if (status === 'CANCELED') {
      return 4;
    } else {
      return 1;
    }
  }

  calculateTop(): number {
    if (!this.actualShift) {
      return 0;
    } else {
      let amount = 41;
      const index = this.assignments.findIndex(
        (x: any) => x.id == this.actualShift?.id
      );
      if (index !== -1) {
        amount = amount + index * 41; // calculate which line is active, that's why 40px * number of previous lines
      }
      return amount;
    }
  }

  openCancelModal(person: any): void {
    this.actualShift = person;
    const shiftForSend = JSON.parse(JSON.stringify(person));
    shiftForSend.disclosedNote = shiftForSend.employerNote;
    shiftForSend.employerNote = shiftForSend.description;
    this.shouldCloseTooltip(person.id);
    let height = '482px';
    if (this.role == 'COORDINATOR') {
      height = '372px';
    }
    const confirmDialog = this.dialog.open(CancelTooltipModalComponent, {
      disableClose: true,
      width: '290px',
      height,
      panelClass: 'defaultDialog',
      position: {
        top: '150px',
      },
      data: {
        person: shiftForSend,
        title: this.translate.instant('cancelShifts'),
        subtitleCancellation: this.translate.instant('CancellationReasons'),
        textCancellation: this.translate.instant('CancellationReasonsText'),
        undisclosed: this.translate.instant('undisclosedNote'),
        undisclosedText: this.translate.instant('undisclosedNoteText'),
        disclosed: this.translate.instant('disclosedNote'),
        disclosedText: this.translate.instant('disclosedNoteText'),
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      this.actualShift = null;
      if (result) {
        if (result.checkBox === true) {
          const shiftToSend = JSON.parse(JSON.stringify(person));
          shiftToSend.status = 1;
          shiftToSend.rowStatus = 'PUBLISHED';
          shiftToSend.assignedToId = null;
          shiftToSend.cancelReasonId = null;
          shiftToSend.disclosedNote = null;
          shiftToSend.employerNote = null;
          shiftToSend.positionId = shiftToSend.position;
          shiftToSend.id = null;
          this.shiftsService
            .createNewShift(shiftToSend)
            .subscribe((data: any) => {
              // this.saveAssignmentsToLocalStorage([data]);
              window.location.reload();
              setTimeout(() => {
                this.snackBar.open(
                  this.translate.instant('ShiftCancelledAndNewCreated'),
                  'OK',
                  {
                    duration: 3000,
                  }
                );
                this.allAssignments.push(data);
              }, 300);

              // this.assignments.push(data);
            });
        }

        const shiftIndex = this.assignments.findIndex(
          (a: any) => a.id === result.assignmentId
        );
        if (shiftIndex !== -1) {
          this.assignments[shiftIndex].description = result.employerNote;
          this.assignments[shiftIndex].disclosedNote = result.employerNote;
          this.assignments[shiftIndex].employerNote = result.disclosedNote;
          this.assignments[shiftIndex].cancelReasonId = result.cancelReasonId;
          this.assignments[shiftIndex].status = 'CANCELED';
          // this.saveAssignmentsToLocalStorage([this.assignments[shiftIndex]]);
          this.showCancel = false;
          this.getUndisclosed(result.cancelReasonId);
          if (this.mode === 1) {
            this.getUnavailable();
          }
        }
      }
    });
  }

  // tslint:disable-next-line:typedef
  getCancelReasonsForShifts(person: any): string {
    let cancelReasons = '';
    // @ts-ignore
    // tslint:disable-next-line:no-shadowed-variable
    this.assignments.forEach((shift: any) => {
      if (shift.status) {
        this.cancelReasons.forEach((cancelReason) => {
          if (
            cancelReason.id === person.cancelReasonId &&
            person.status === 'CANCELED'
          ) {
            cancelReasons = 'Avbokat pass: ' + ' ' + cancelReason.title;
          }
        });
      }
    });
    return cancelReasons;
  }

  getUndisclosed(person: any): string {
    let employerNote = '';
    employerNote = person.description;
    return employerNote;
  }

  // @ts-ignore
  getDisclosed(person: any): string {
    let disclosedNote = '';
    if (this.role != 'COORDINATOR') {
      disclosedNote = person.employerNote;
    }
    return disclosedNote;
  }

  openDeleteModal(person: any): void {
    this.actualShift = person;
    this.shouldCloseTooltip(person.id);
    this.actualShift = person;
    const confirmDialog = this.dialog.open(TooltipShiftsModalsComponent, {
      disableClose: true,
      width: '290px',
      height: '190px',
      panelClass: 'defaultDialog',
      position: {
        top: '150px',
      },
      data: {
        person,
        title: this.translate.instant('deleteBookedShift'),
        subtitle: this.translate.instant('attention'),
        message: this.translate.instant('deleteCanceledShiftsText'),
        mode: 'delete',
      },
    });
    confirmDialog.afterClosed().subscribe((res) => {
      this.actualShift = null;
      if (res) {
        this.removeAssignmentFromLocalStorage(person);
        window.location.reload();
        const shiftIndex = this.assignments.findIndex((a: any) => a.id === res);
        if (shiftIndex !== -1) {
          this.assignments.splice(shiftIndex, 1);
        }
      }
    });
  }

  shouldLeaveCard(person: any): void {
    if (!this.cardClicked[person.id]) {
      this.cardHover[person.id] = false;
    }
  }

  backFromCreateSchedule(): void {
    this.newShift = false;
  }

  openShiftNoteModal(person: any): void {

    const width = '290px';
    let height = '360px';

    if (this.hide) {
      height = '249px';
    }


    this.actualShift = person;
    let showDisclosed = true;
    if (this.role == 'COORDINATOR' || this.role == 'ROSTER') {
      showDisclosed = false;
    }

    const confirmDialog = this.dialog.open(ShiftNoteComponent, {
      disableClose: true,
      width,
      height,
      panelClass: 'defaultDialog',
      position: {
        top: '150px',
      },

      data: {
        person,
        title: this.translate.instant('shiftNote'),
        undisclosed: this.translate.instant('undisclosedNote'),
        undisclosedText: this.translate.instant('undisclosedNoteText'),
        disclosed: this.translate.instant('disclosedNote'),
        disclosedText: this.translate.instant('disclosedNoteText'),
        showDisclosed,
        showUndisclosed: true,
        toSave: true,
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      this.actualShift = null;
      if (result && result !== false) {
        const findNoteIndex = this.assignments.findIndex(
          // tslint:disable-next-line:no-shadowed-variable
          (shift: { id: any }) => shift.id === result.id
        );

        if (findNoteIndex !== -1) {
          this.assignments[findNoteIndex].employerNote = result.employerNote;
          this.assignments[findNoteIndex].description = result.description;
        }
      }
    });
  }

  openAddAnnouncementModal(): void {
    const confirmDialog = this.dialog.open(AddAnnouncementComponent, {
      disableClose: true,
      width: '290px',
      height: 'auto',
      panelClass: 'defaultDialog',
      position: {
        top: '150px',
      },
      data: {
        title: this.translate.instant('publicAnnouncement'),
        inputTitle: this.translate.instant('date'),
        inputSubtitle: this.translate.instant('dateForAnnouncement'),
        messageTitle: this.translate.instant('text'),
        messageSubtitle: this.translate.instant('Addtext'),
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result && result !== false) {
        this.allAnnouncement.push(result);
        this.allAnnouncement.sort((n1: any, n2: any) => {
          // @ts-ignore
          return new Date(n2.date) - new Date(n1.date);
        });
        this.currentDate = this.datePipe.transform(
          this.currentDate,
          'yyyy-MM-dd'
        );
        console.log('gotDate 24 ', this.currentDate);

        if (result.date === this.currentDate) {
          this.showMessage = true;
          this.announcementMessage = result.text;
        }
        this.helper.dateValue.next(this.currentDate);
      }
    });
  }

  changeBackgroundCalendar(): void {
    this.calendar = !this.calendar;
    this.announcement = false;
    this.unavailable = false;
    this.staffingGoalsActiveValue = false;
  }

  changeBackgroundAnnouncement(): void {
    this.announcement = !this.announcement;
    this.unavailable = false;
    this.calendar = false;
    this.staffingGoalsActiveValue = false;
  }

  changeBackgroundHideNote(): void {
    if (this.mode == 1) {
      this.hideNote = !this.hideNote;
      this.hide = !this.hide;
      this.announcement = false;
      this.unavailable = false;
      this.calendar = false;
      this.mode = 1;
      this.staffingGoalsActiveValue = false;

      this.filterAssignments();
      this.sortAssignments();
    }
  }

  changeBackgroundUnavailable(): void {
    if (this.mode == 1) {
      if (this.allUnavailable.length == 0) {
        if (this.mode === 1) {
          this.getUnavailable();
        }
      }
      this.unavailable = !this.unavailable;
      this.calendar = false;
      this.mode = 1;
      this.announcement = false;
      this.staffingGoalsActiveValue = false;
    }
  }

  openUserDetails(person: any): void {
    if (person.status !== 'PUBLISHED' && this.checkIfNotRoster()) {
      let topPosition = '100px';
      if (this.innerHeight < 738) {
        topPosition = '20px';
      }

      if (this.innerHeight <= 638) {
        topPosition = '0px';
      }
      this.userService.getEmployeeById(person.assignedToId).subscribe((x) => {
        const userDetails = x;
        const confirmDialog = this.dialog.open(UserDetailsModalComponent, {
          disableClose: true,
          width: '560px',
          height: '650px',
          panelClass: 'userDialog',
          position: {
            top: topPosition,
          },
          data: {
            userDetails,
            isActive: JSON.stringify(person.isActiveUser),
            mode: 'fromDayView',
          },
        });
        confirmDialog.afterClosed().subscribe(() => {
        });
      });
    }
  }

  public staffingGoalsActive(): any {
    if (this.mode == 1) {
      if (this.staffingGoalsActiveValue === true) {
        this.staffingGoalsActiveValue = false;
      } else if (this.staffingGoalsActiveValue === false) {
        this.staffingGoalsActiveValue = true;
        this.hide = false;
        this.announcement = false;
        this.unavailable = false;
        this.calendar = false;

        this.sortedStaff();
      }
    }
  }

  public staffingGoalsActiveMonth(): any {
    if (this.staffingGoalsActiveValueMonth === true) {
      this.staffingGoalsActiveValueMonth = false;
      const dateCurrent = this.datePipe.transform(
        this.currentDate,
        'yyyy-MM-dd'
      );
      // this.shiftsService
      //   .shiftColor(dateCurrent, dateCurrent)
      //   .subscribe((response: any) => {
      //     const resp = response;
      //     for (const oneOf of response.groups) {
      //       if (oneOf.group.id == this.groupId) {
      //         this.colorArray = oneOf.dayAssignments;
      //       }
      //     }
      //   });
    } else if (this.staffingGoalsActiveValueMonth === false) {
      this.staffingGoalsActiveValueMonth = true;
    }
  }

  checkIfNotRoster(): boolean {
    if (localStorage.getItem('gapUserRole')) {
      if (localStorage.getItem('gapUserRole') == 'ROSTER') {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  calculateValue(position: any, type: any, dateTiming: any): any {
    dateTiming = new Date(
      dateTiming.getFullYear(),
      dateTiming.getMonth(),
      dateTiming.getDate()
    );
    dateTiming.setDate(dateTiming.getDate() + 1);

    let counterDay = 0;
    let counterEvening = 0;
    let counterNight = 0;
    let swing1 = 0;
    let swing2 = 0;
    if (this.colorArray.length != 0) {
      for (const colors of this.colorArray) {
        if (colors.day == dateTiming.toJSON().substring(0, 10)) {
          for (const col of colors.assignments) {
            if (
              col.position == position &&
              col.status == 'PUBLISHED' &&
              col.clinical == true
            ) {
              for (const time of colors.assignmentTimings) {
                if (
                  this.calculateMinutes(time.end) >
                  this.calculateMinutes(time.start)
                ) {
                  if (
                    this.calculateMinutes(col.end.substring(11, 16)) <=
                    this.calculateMinutes(time.end) &&
                    this.calculateMinutes(col.end.substring(11, 16)) >=
                    this.calculateMinutes(time.start)
                  ) {
                    if (time.type == type) {
                      if (type == 'DAY_ASSIGNMENT') {
                        counterDay++;
                      } else if (type == 'EVENING_ASSIGNMENT') {
                        counterEvening++;
                      } else if (type == 'NIGHT_ASSIGNMENT') {
                        counterNight++;
                      } else if (type == 'SWING_1') {
                        swing1++;
                      } else if (type == 'SWING_2') {
                        swing2++;
                      }
                    }
                  }
                } else {
                  if (
                    this.calculateMinutes(col.start.substring(11, 16)) >=
                    this.calculateMinutes(time.start)
                  ) {
                    if (time.type == type) {
                      if (type == 'DAY_ASSIGNMENT') {
                        counterDay++;
                      } else if (type == 'EVENING_ASSIGNMENT') {
                        counterEvening++;
                      } else if (type == 'NIGHT_ASSIGNMENT') {
                        counterNight++;
                      } else if (type == 'SWING_1') {
                        swing1++;
                      } else if (type == 'SWING_2') {
                        swing2++;
                      }
                    }
                  }
                }
              }

              // if( this.calculateMinutes(col.end.substring(11,16)) <= this.calculateMinutes( colors.assignmentTimings[0].end)  && this.calculateMinutes(col.end.substring(11,16)) >= this.calculateMinutes( colors.assignmentTimings[0].start)){
              //   if(colors.assignmentTimings[0].type == type){
              //     counterDay++;
              //   }
              // }else if(  this.calculateMinutes(col.end.substring(11,16)) >= this.calculateMinutes( colors.assignmentTimings[1].end)  &&  this.calculateMinutes(col.end.substring(11,16)) <= this.calculateMinutes( colors.assignmentTimings[2].end) ){
              //   if(colors.assignmentTimings[1].type == type){
              //     counterEvening++;
              //   }
              // }else if( ( this.calculateMinutes(col.end.substring(11,16)) >= this.calculateMinutes( colors.assignmentTimings[2].end) && ( this.calculateMinutes(col.end.substring(11,16)) <= this.calculateMinutes("23:59") ))  ||  (this.calculateMinutes("00:00") <= this.calculateMinutes(col.end.substring(11,16)) && this.calculateMinutes(col.end.substring(11,16)) <= this.calculateMinutes( colors.assignmentTimings[0].end))){
              //   if(colors.assignmentTimings[2].type == type){
              //     counterNight++;
              //   }
              // }
            }
          }
        }
      }
    }
    if (counterDay != 0) {
      return counterDay;
    } else if (counterEvening != 0) {
      return counterEvening;
    } else if (counterNight != 0) {
      return counterNight;
    } else if (swing1 != 0) {
      return swing1;
    } else if (swing2 != 0) {
      return swing2;
    } else {
      return 0;
    }
  }

  getStaff(staff: any, assignments: any): void {
    this.dayArray = [];
    this.eveningArray = [];
    this.nightArray = [];

    const assignedAssignments = assignments?.filter(
      (x: { status: string }) => x?.status === 'ASSIGNED'
    );

    assignedAssignments?.forEach((x: any) => {
      const staffShiftStartTime = this.calculateMinutes(
        new Date(x.start).getHours() + ':' + new Date(x.start).getMinutes()
      );

      const dayIndex = staff.findIndex(
        (day: { type: string }) => day.type === 'DAY_ASSIGNMENT'
      );
      if (dayIndex !== -1) {
        this.dayShiftStart = this.calculateMinutes(staff[dayIndex].start);
        this.dayShiftEnd = this.calculateMinutes(staff[dayIndex].end);
        if (
          staffShiftStartTime > this.dayShiftStart &&
          staffShiftStartTime < this.dayShiftEnd
        ) {
          this.dayArray = [...this.dayArray];
          this.dayArray.push(x);
        }
      }
      const eveningIndex = staff.findIndex(
        (evening: { type: string }) => evening.type === 'EVENING_ASSIGNMENT'
      );
      if (eveningIndex !== -1) {
        this.eveningShiftStart = this.calculateMinutes(
          staff[eveningIndex].start
        );
        this.eveningShiftEnd = this.calculateMinutes(staff[eveningIndex].end);
        if (
          staffShiftStartTime > this.eveningShiftStart &&
          staffShiftStartTime < this.eveningShiftEnd
        ) {
          this.eveningArray = [...this.eveningArray];
          this.eveningArray.push(x);
        }
      }

      const nightIndex = staff.findIndex(
        (night: { type: string }) => night.type === 'NIGHT_ASSIGNMENT'
      );
      if (nightIndex !== -1) {
        this.nightShiftStart = this.calculateMinutes(staff[nightIndex].start);
        this.nightShiftEnd = this.calculateMinutes(staff[nightIndex].end);
        if (
          staffShiftStartTime > this.nightShiftStart &&
          staffShiftStartTime < this.nightShiftEnd
        ) {
          this.nightArray = [...this.nightArray];
          this.nightArray.push(x);
        }
      }
    });
  }

  findStaffForPosition(p: any, day: any): any {
    const index = day.positions?.findIndex((d: any) => d.positionId === p.id);

    if (day.positions && index !== -1) {
      return day.positions[index];
    } else {
      return 0;
    }
  }

  sortPositionSelect(): any[] {
    return this.positionsSelect.sort((a: any, b: any) => {
      return a.shortTitle.localeCompare(b.shortTitle);
    });
  }

  sortPositionTitleSelect(): any[] {
    return this.positionsSelect.sort((a: any, b: any) => {
      return a.title.localeCompare(b.title);
    });
  }

  checkIfNotesAreEclipsed(shiftId: number): boolean {
    const undisclosedNote = document.getElementById('undisclosed-note-' + shiftId);
    const disclosedNote = document.getElementById('disclosed-note-' + shiftId);

    if (!undisclosedNote && !disclosedNote) {
      return false;
    } else if (undisclosedNote && disclosedNote) {
      if (undisclosedNote.offsetWidth < undisclosedNote.scrollWidth || disclosedNote.offsetWidth < disclosedNote.scrollWidth) {
        return true;
      } else {
        return false;
      }
    } else if (undisclosedNote) {
      if (undisclosedNote.offsetWidth < undisclosedNote.scrollWidth) {
        return true;
      } else {
        return false;
      }
    } else if (disclosedNote) {
      if (disclosedNote.offsetWidth < disclosedNote.scrollWidth) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }


  }

  sortDataGoals(): any[] {
    return this.dataGoalsPositionName.sort((a: any, b: any) => {
      return a.data.localeCompare(b.data);
    });
  }


  sortShiftByName(): any[] {
    return this.shifts.sort((a: any, b: any) => {
      return a.shift.localeCompare(b.shift);
    });
  }

  sortedStaff(): void {

    let selectedLists: any[] = [];
    if (this.allListsChecked) {
      this.lists.forEach((s: any) => {
        selectedLists.push(s.id);
      });
    } else {
      selectedLists = this.selectedLists;
    }

    let assignments: any[] = [];

    if (selectedLists.length > 0) {
      assignments = this.allAssignments.filter((a: any) => {
        return selectedLists.includes(a.listId) || this.allListsChecked;
      });
    } else {
      assignments = this.allAssignments;
    }

    const positionIds: any[] = [];
    this.positionsSelect.forEach((p: any) => {
      const obj = {positionId: p.id, booked: 0, open: 0};
      positionIds.push(obj);
    });

    this.dayTimeShift?.forEach((d: any, index: number) => {
      d.positions = positionIds;


      this.assignmentTimings?.forEach((t: any) => {
        if (d.name == t.type) {
          positionIds.forEach((p: any) => {
            const open = assignments.filter((a: any) => {
              const date = new Date(a.start.replace(/-/g, '/')).toDateString();
              if (t.start <= t.end) {
                return (
                  a.status == 'PUBLISHED' && a.clinical &&
                  a.position == p.positionId &&
                  this.calculateMinutes(
                    new Date(date + ' ' + t.start.replace(/-/g, '/')).getHours() +
                    ':' +
                    new Date(date + ' ' + t.start.replace(/-/g, '/')).getMinutes()
                  ) <=
                  this.calculateMinutes(
                    new Date(a.start.replace(/-/g, '/')).getHours() +
                    ':' +
                    new Date(a.start.replace(/-/g, '/')).getMinutes()
                  ) &&
                  this.calculateMinutes(
                    new Date(date + ' ' + t.end.replace(/-/g, '/')).getHours() +
                    ':' +
                    new Date(date + ' ' + t.end.replace(/-/g, '/')).getMinutes()
                  ) >=
                  this.calculateMinutes(
                    new Date(a.start.replace(/-/g, '/')).getHours() +
                    ':' +
                    new Date(a.start.replace(/-/g, '/')).getMinutes()
                  )
                );
              } else {
                return (
                  a.status == 'PUBLISHED' && a.clinical &&
                  a.position == p.positionId &&
                  this.calculateMinutes(
                    new Date(date + ' ' + t.start.replace(/-/g, '/')).getHours() +
                    ':' +
                    new Date(date + ' ' + t.start.replace(/-/g, '/')).getMinutes()
                  ) <=
                  this.calculateMinutes(
                    new Date(a.start.replace(/-/g, '/')).getHours() +
                    ':' +
                    new Date(a.start.replace(/-/g, '/')).getMinutes()
                  )
                );
              }

            }).length;
            const booked = assignments.filter((a: any) => {
              const date = new Date(a.start.replace(/-/g, '/')).toDateString();
              if (t.start <= t.end) {
                return (
                  a.status == 'ASSIGNED' && a.clinical &&
                  a.position == p.positionId &&
                  this.calculateMinutes(
                    new Date(date + ' ' + t.start.replace(/-/g, '/')).getHours() +
                    ':' +
                    new Date(date + ' ' + t.start.replace(/-/g, '/')).getMinutes()
                  ) <=
                  this.calculateMinutes(
                    new Date(a.start.replace(/-/g, '/')).getHours() +
                    ':' +
                    new Date(a.start.replace(/-/g, '/')).getMinutes()
                  ) &&
                  this.calculateMinutes(
                    new Date(date + ' ' + t.end.replace(/-/g, '/')).getHours() +
                    ':' +
                    new Date(date + ' ' + t.end.replace(/-/g, '/')).getMinutes()
                  ) >=
                  this.calculateMinutes(
                    new Date(a.start.replace(/-/g, '/')).getHours() +
                    ':' +
                    new Date(a.start.replace(/-/g, '/')).getMinutes()
                  )
                );
              } else {
                return (
                  a.status == 'ASSIGNED' && a.clinical &&
                  a.position == p.positionId &&
                  this.calculateMinutes(
                    new Date(date + ' ' + t.start.replace(/-/g, '/')).getHours() +
                    ':' +
                    new Date(date + ' ' + t.start.replace(/-/g, '/')).getMinutes()
                  ) <=
                  this.calculateMinutes(
                    new Date(a.start.replace(/-/g, '/')).getHours() +
                    ':' +
                    new Date(a.start.replace(/-/g, '/')).getMinutes()
                  )
                );
              }
            }).length;
            const positionIndex = d.positions.findIndex((pos: any) => {
              return pos.positionId == p.positionId;
            });
            if (positionIndex !== -1) {
              const obj = {positionId: p.positionId, open, booked};
              d.positions[positionIndex] = obj;
              d.positions = [...d.positions];
              this.dayTimeShift[index] = d;
              this.dayTimeShift = [...this.dayTimeShift];
            }
          });
        }
      });
    });
  }

  // getAllPositionsCheckMarks(position: any, event: any): void {
  // }


  getAllShiftsCheckMarks(shifts: any, event: any): void {
  }

  ngOnDestroy(): void {
    this.activitySubscription?.unsubscribe();
    this.positionsSubscription?.unsubscribe();
    this.sendDateSubscription?.unsubscribe();
    this.weeksSubscription?.unsubscribe();
    this.clickedSectionSubscription?.unsubscribe();
    this.groupChangedSubscription?.unsubscribe();
    this.assignmentSupscription?.unsubscribe();
  }
}
