import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'shiftDate'})
export class ShiftDatePipe implements PipeTransform {
  transform(value: string): string {

    const HH = value.substr(10, 6);
    const date = `${HH}`;

    return `${date}`;
  }
}
