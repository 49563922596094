import {AfterViewChecked, AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../shared/services/auth.service';
import {AuthConst} from '../../shared/services/const/authConst';
import {Observable} from 'rxjs';
import {User} from '../../shared/models/user.model';
import {UserService} from '../../shared/services/user.service';
import {ActivityListResponse, ActivityLogCount} from '../../shared/models/activitylogs.model';
import {ActivitylogsService} from '../../shared/services/activitylogs.service';
import {HelperService} from '../../shared/services/helper.service';
import {MatDialog} from '@angular/material/dialog';
import {LogOutComponent} from '../modals/log-out/log-out.component';
import {MessagesService} from '../../shared/services/messages.service';
import {EmployeesService} from '../../shared/services/employees.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivityLogsListComponent} from '../activityLogs/activity-logs-list/activity-logs-list.component';
import {version} from '../../../../package.json';
import { Title } from '@angular/platform-browser';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
  user$: Observable<User> | undefined;
  firstName: string | undefined;
  lastname: string | undefined;
  groups: any[] = [];
  selectedGroupName = '';
  activityCount: ActivityLogCount = {unread: 0, unseen: 0};
  showWorksiteList = false;
  unreadConversationsCount = 0;
  messages$: any;
  mode = 0;
  interval: any;
  messages: any;
  user: any;
  version: string = version;
  role = "";
  logCount: any;



  constructor(
    private router: Router,
    public authService: AuthService,
    private userService: UserService,
    private activityService: ActivitylogsService,
    private helper: HelperService,
    private dialog: MatDialog,
    private messagesService: MessagesService,
    private employeesService: EmployeesService,
    private sanitizer: DomSanitizer,
    private activityLogList: ActivityLogsListComponent,
    private title: Title,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
  }
  ngOnInit(): void {
    this.userService
      .getLoggedInUser()
      .subscribe((x) => {
        },
        (error) => {
        console.log("ERROR   ", error);
          if (error.status == 411) {
            if (!this.router.url.includes('schedule-backup')) {
            localStorage.removeItem(AuthConst.token);
            localStorage.removeItem(AuthConst.fbToken);
            localStorage.removeItem(AuthConst.userId);
            localStorage.removeItem(AuthConst.gruopId);
            localStorage.removeItem(AuthConst.groupName);
            localStorage.removeItem('assignments');
            localStorage.removeItem('employees');
            localStorage.removeItem('logs');
            localStorage.removeItem('savedImages');
            localStorage.removeItem('dayview-lists');
            localStorage.removeItem('dayview-shifts');
            localStorage.removeItem('dayview-positions');
            this.snackBar.open(this.translate.instant('sessionExpired'), 'OK', {
              duration: 3000,
              panelClass: ['custom-snack-bar'],
            });
            this.authService.logout();
            console.log('redirect 4');
              this.router.navigate(['/login']);
            }
          }
        });
    window.addEventListener('blur', () => {
      clearInterval(this.interval);
    });
    window.addEventListener('focus', () => {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.checkNewConversations();
        this.checkNewActivities();
      }, 40000);


    });
    this.role = localStorage.getItem('gapUserRole');
    //load activity logs
    this.activityLogList.getActivityLogs(true, false);
    this.checkNewActivities();


    window.navigator.serviceWorker.addEventListener('message', event => {
      if (event['data']['firebase-messaging-msg-data']) {
        this.handleNotification(event['data']['firebase-messaging-msg-data'], 'back');
      } else {
        this.handleNotification(event['data'], 'in');
      }
    });

    this.helper.refreshChatCount.subscribe(
      (data: any) => {
        if (this.unreadConversationsCount > 0) {
          this.unreadConversationsCount = this.unreadConversationsCount - 1;
        }
      }
    );
    const isSingIn = this.authService.isSignedIn();
    if (!localStorage.getItem(AuthConst.token)) {
      if (!this.router.url.includes('schedule-backup')) {
        this.router.navigate(['/login']);
      }
    }
    this.activityService
      .getActivityLogCount()
      .subscribe((data: ActivityLogCount) => {
        this.activityCount = data;
      });

    this.logCount = this.helper.refreshLogCount.subscribe((d: any) => {
      if (d) {
        setTimeout(() => {
          this.checkNewActivities();
        }, 1000);

      }
    });
    this.messages$ = this.messagesService.getAllConversations();
    // @ts-ignore
    this.messages$.subscribe((x: any[]) => {
      this.unreadConversationsCount = 0;
      // @ts-ignore
      if (x.length > 0) {
        const groupIndex = x.findIndex((m) => m.groupId == localStorage.getItem(AuthConst.gruopId));
        if (groupIndex != -1) {
          x[groupIndex]?.messageThreads.group.forEach((m: any) => {
            if (m.headerStatus == 'UNSEEN') {
              this.unreadConversationsCount += 1;
            }
          });
        }
      }
    });
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.checkNewConversations();
      this.checkNewActivities();
    }, 40000);

    this.preload();
  }

  ngAfterViewInit(): void {
    if (!this.user) {
      this.userService.getLoggedInUser().subscribe((user) => {
        if (user.id) {
          this.user = user;
          localStorage.setItem(AuthConst.userId, user.id.toString());
          localStorage.setItem('loggedInUser', JSON.stringify(user));
          this.firstName = user.firstName;
          this.lastname = user.lastName;
          let groupId = 0;
          user.userAuthorities?.roles?.EMPLOYER?.forEach((g) => {
            g.role = 'ROLE_EMPLOYER';
            this.groups.push(g);

            this.groups.sort(
              (name1: { groupTitle: string }, name2: { groupTitle: string }) =>
                name1.groupTitle !== name2.groupTitle
                  ? name1.groupTitle < name2.groupTitle
                  ? -1
                  : 1
                  : 0
            );
          });
          user.userAuthorities?.roles?.COORDINATOR?.forEach((g) => {
            g.role = 'ROLE_COORDINATOR';
            this.groups.push(g);
          });
          user.userAuthorities?.roles?.ROSTER?.forEach((g) => {
            g.role = 'ROLE_ROSTER';
            this.groups.push(g);
          });
          if (localStorage.getItem(AuthConst.groupName)) {
            this.selectedGroupName = localStorage.getItem(AuthConst.groupName);
          } else {
            this.selectedGroupName = this.groups[0]?.groupTitle;
            localStorage.setItem(AuthConst.userRole, this.groups[0]?.role);
            groupId = this.groups[0]?.groupId;
            if (groupId) {
              localStorage.setItem(AuthConst.gruopId, groupId.toString());
            }
          }
        } else {
          console.log('redirect 2');
          this.router.navigate(['/login']);
        }
      });
    }
  }

  checkNewConversations(): void {
    this.messages$ = this.messagesService.getAllConversations();
    // @ts-ignore
    this.messages$.subscribe((x: any[]) => {
      this.unreadConversationsCount = 0;
      // @ts-ignore
      if (x.length > 0) {
        const groupIndex = x.findIndex((m) => m.groupId == localStorage.getItem(AuthConst.gruopId));
        if (groupIndex != -1) {
          x[groupIndex]?.messageThreads.group.forEach((m: any) => {
            if (m.headerStatus == 'UNSEEN') {
              this.unreadConversationsCount +=  1;
            }
          });
        }
      }
    });
  }

  checkNewActivities(): void {
    this.activityService
      .getActivityLogCount()
      .subscribe((data: ActivityLogCount) => {
        this.activityCount = data;
      });
  }

  checkIfNotRoster(): boolean {
    if (localStorage.getItem('gapUserRole')) {
      if (localStorage.getItem('gapUserRole') == 'ROSTER') {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  onBlur() {

  }

  onFocus() {

  }

  checkIfNotCoordinator(): boolean {
    if (localStorage.getItem('gapUserRole')) {
      if (localStorage.getItem('gapUserRole') == 'COORDINATOR') {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  handleNotification(data: any, mode: any) {

  }

  logOut(): void {
    const dialogRef = this.dialog.open(LogOutComponent, {
      width: '290px',
      height: '190px',
      panelClass: 'defaultDialog2',
      backdropClass: 'backdropBackground',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === 'logout') {
        const logoutUser = {
          token: localStorage.getItem(AuthConst.fbToken),
          type: 'EMPLOYER',
          platform: 'WEB',
          userId: localStorage.getItem(AuthConst.userId),
        };

        this.authService.UserLogout(logoutUser).subscribe();
        localStorage.removeItem(AuthConst.token);
        localStorage.removeItem(AuthConst.fbToken);
        localStorage.removeItem(AuthConst.userId);
        localStorage.removeItem(AuthConst.gruopId);
        localStorage.removeItem(AuthConst.groupName);
        localStorage.removeItem('assignments');
        localStorage.removeItem('employees');
        localStorage.removeItem('logs');
        localStorage.removeItem('savedImages');
        localStorage.removeItem('dayview-lists');
        localStorage.removeItem('dayview-shifts');
        localStorage.removeItem('dayview-positions');
        this.authService.logout();
        console.log('redirect 3');
        this.router.navigate(['/login']);
      }
    });
  }

  goTo(route: string): void {
    this.helper.clickedSection.next(route);

    this.checkNewConversations();

    this.checkNewActivities();

    if (route === '/schedule') {
      localStorage.removeItem(AuthConst.scheduleDate);
      localStorage.removeItem('dayview-shifts');
      localStorage.removeItem('dayview-positions');
    }
    if (this.router.url == route && this.router.url != '/activity') {
      // localStorage.removeItem("currentDate");
      window.location.reload();
    } else {
      this.router.navigate([route]);
    }
  }

  isActive(route: string): boolean {
    return this.router.url.startsWith(route);
  }

  selectGroup(group: any): void {
    localStorage.setItem(AuthConst.gruopId, group.groupId.toString());
    this.selectedGroupName = group.groupTitle;
    this.helper.groupChanged.next(group.groupId);
    localStorage.setItem(AuthConst.userRole, group.role);
    localStorage.setItem(AuthConst.groupName, group.groupTitle);
    localStorage.removeItem('assignments');
    localStorage.removeItem('employees');
    localStorage.removeItem('logs');
    localStorage.removeItem('savedImages');
    this.router.navigate(['/schedule']);
    this.showWorksiteList = false;
  }

  preload(): void {
    this.getMessages();
  }

  getMessages(): void {
    this.messages$ = this.messagesService.getAllConversations();
    // @ts-ignore
    this.messages$.subscribe((x: any[]) => {
      // @ts-ignore
      if (x.length > 0) {
        const groupId = Number(localStorage.getItem(AuthConst.gruopId));

        const groupIndex = x.findIndex((m) => m.groupId == groupId);
        if (groupIndex != -1) {
          // @ts-ignore
          this.messages = x[groupIndex]?.messageThreads.group;

          let index = 0;
          this.setMessagesToLocaleStorage(this.messages);
        }
      }
    });
  }

  setMessagesToLocaleStorage(messages: any): void {
    const groupId = Number(localStorage.getItem(AuthConst.gruopId));
    const obj = {
      groupId, messages
    };

    let existingMessages: any = localStorage.getItem('messages');
    if (existingMessages) {
      existingMessages = JSON.parse(existingMessages);
      const groupIndex = existingMessages.findIndex((m: any) => {
        return m.groupId == groupId;
      });

      if (groupIndex == -1) {
        existingMessages.push(obj);
      } else {
        existingMessages[groupIndex] = obj;
      }

    } else {
      existingMessages = [obj];
    }

    localStorage.setItem('messages', JSON.stringify(existingMessages));
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.logCount.unsubscribe();
  }
}
