import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private shiftsSubject = new BehaviorSubject<any[]>([]);
  public shifts$ = this.shiftsSubject.asObservable();

  constructor() {}

  setShifts(data: any[]): void {
    this.shiftsSubject.next(data);
  }

  getShifts(): any[] {
    return this.shiftsSubject.getValue();
  }
}
