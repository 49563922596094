import { Injectable } from '@angular/core';
import { Theme, main } from '../../theme/theme';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private active: Theme = main;

  setActiveTheme(theme: Theme): void {
    this.active = theme;

    Object.keys(this.active.properties).forEach((property) => {
      document.documentElement.style.setProperty(
        property,
        this.active.properties[property]
      );
    });
  }
}
