import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({name: 'convertMonths'})
export class ConvertMonthsPipe implements PipeTransform {
  month: string | undefined;

  transform(value: any): any {
    const split = value.substring(0, value.indexOf(','));
    const hours = value.split(',', 2)[1];
    this.month = split.split(' ', 2)[1];
    const day = split.split(' ', 2)[0];

    return day + ' ' + this.translate.instant(this.month) + ',' + ' ' + hours;
  }

  constructor(
    private translate: TranslateService,
  ) {
  }
}
