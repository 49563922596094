import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MessagesService } from '../../shared/services/messages.service';
import { RecipientsModel } from '../../shared/models/recipients.model';
import { Conversations } from '../../shared/models/conversations.model';
import { HelperService } from '../../shared/services/helper.service';
import { UserService } from '../../shared/services/user.service';
import { AuthConst } from '../../shared/services/const/authConst';
import { ShiftsService } from '../../shared/services/shifts.service';
import {EmployeesService} from "../../shared/services/employees.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-recipients',
  templateUrl: './recipients.component.html',
  styleUrls: ['./recipients.component.scss'],
})
export class RecipientsComponent implements OnInit, OnDestroy {
  recipientsGroup?: RecipientsModel | undefined;
  recipients$: Observable<RecipientsModel> | undefined;
  letter: string | undefined;
  showChat = false;
  messages$: Observable<Conversations> | undefined;
  messages: any;
  message: {} = {};
  checkedRecipient: number | undefined;
  groups: any[] = [];
  groups$: Observable<any> | undefined;
  messagesSubscription: Subscription;
  recipientsSubscription: Subscription;
  groupsSubscription: Subscription;
  groupId: any;

  constructor(
    private messagesService: MessagesService,
    private helperService: HelperService,
    private userService: UserService,
    private shiftService: ShiftsService,
    private employeesService: EmployeesService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.groupId = localStorage.getItem('gruopId');
    this.messages$ = this.messagesService.getAllConversations();
    this.messagesSubscription = this.messages$.subscribe((x) => {
      this.messages = x;
    });
    this.recipients$ = this.messagesService.getAllRecipients();
    // @ts-ignore
    this.recipientsSubscription = this.recipients$.subscribe((x: any[]) => {
      const groupIndex = x.findIndex((g: any) => g.groupId == this.groupId);
      if (groupIndex != -1) {
        this.recipientsGroup = x[groupIndex];
      } else {
        this.recipientsGroup = null;
      }
    });
    this.groups$ = this.userService.getUserGroups(
      Number(localStorage.getItem(AuthConst.userId))
    );
    this.groupsSubscription =  this.shiftService.getAllGroup(Number(localStorage.getItem(AuthConst.gruopId))).subscribe((groups: any) => {
      this.groups = groups;

    });
  }
  sortGroups(): any {
    return this.groups.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  // @ts-ignore
  /**
   *
   * Sorting contacts by first name
   */
  shouldShowDate(i: number, name: string): boolean {
    if (i === 0) {
      return true;
    } else {
      const firstName = name.split(' ')[0];
      const firstLetter = firstName.charAt(0).toLocaleUpperCase();
      const previousName = this.recipientsGroup?.recipients[i - 1].name.split(
        ' '
      )[0];
      // @ts-ignore
      const previousLetter = previousName.charAt(0).toLocaleUpperCase();
      if (previousLetter === firstLetter) {
        return false;
      } else {
        this.letter = firstLetter;
        return true;
      }
    }
  }

  /**
   *
   * Get ID of user by checking check-box
   */
  getRecipient(recipient: any, event: any): void {
    this.checkedRecipient = recipient.id;

    setTimeout(() => {
      // @ts-ignore
      this.helperService.multiMessage.next({ recipient });
    }, 200);

    setTimeout(() => {
      this.showChat = true;
    }, 90);
  }

  back(): void {
    this.showChat = false;
    this.checkedRecipient = undefined;
  }

  /**
   *
   *  Click outside directive
   */
  shouldCloseChat(): void {
    if (this.showChat) {
      this.showChat = false;
      this.checkedRecipient = undefined;
    }
  }

  ngOnDestroy(): void {
    this.messagesSubscription?.unsubscribe();
    this.recipientsSubscription?.unsubscribe();
    this.groupsSubscription?.unsubscribe();
  }
}
