<div class="main-container-background">
  <div class="section-header background">
    <div class="section-header__title-box">
      <div class="section-header__title">{{'activityLog' | translate}}</div>
    </div>
  </div>
  <div class="section-select">
    <div class="section-select__first">
      <div class="section-select__input-box">
        <input class="input section-select__input" placeholder="{{'search' | translate}}" [(ngModel)]="searchText"
               (ngModelChange)="changedValue()"/>
        <img class="section-select__input section-select__input--position" src="../assets/images/Search.svg"/>
      </div>
      <div class="section-select__select-box" (clickOutside)="openType = false;">
        <input class="section-select__select paddingSelect" (click)="showAllEvents()"
               [(ngModel)]="filterTypes"
               readonly
               placeholder="{{'allEvents' | translate}}"
        />
        <div class="dropdown dropdown--shifts" *ngIf="openType">
          <div class="option" (click)="setType({id: -1, name:'allEvents'}, $event)">{{'allEvents' | translate}}</div>
          <mat-checkbox class="checkBox-input" (change)="setType({id: -1, name:'allEvents'}, $event)"
                        [checked]="allEventsChecked"></mat-checkbox>
          <div class="option" *ngFor="let type of types"
               (click)="setType(type, $event)">
            <div class="wrapped-text">{{type.name | translate}}</div>
            <mat-checkbox class="checkBox-input" [checked]="typeIsChecked(type)"></mat-checkbox>
          </div>
        </div>
      </div>
      <!--      <div class="for-type-select" (clickOutside)="openType = false;">-->
      <!--        <div class="section-select__select-box" (click)="openType = true;">-->
      <!--          <input class="input inputImage" placeholder=" {{findType() | translate}}"-->
      <!--                 style="width: 200px;cursor: pointer"/>-->
      <!--        </div>-->
      <!--        <div class="form__select" *ngIf="openType">-->
      <!--          <div class="form__select-option" *ngFor="let type of types"-->
      <!--               (click)="setType(type)">-->
      <!--            <div class="event-links">{{type.name | translate}}</div>-->
      <!--            <mat-checkbox class="checkBox-input" [checked]="typeIsChecked(type)"></mat-checkbox>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="section-select__select-box">
        <input class="section-select__select" (click)="showAllSorts()"
               (clickOutside)="showSort = false"
               [(ngModel)]="sortName"
               readonly
        />
        <div class="dropdown dropdown--shifts" *ngIf="showSort">
          <div class="option" *ngFor="let s of sort"
               (click)="selectSort(s)">
            <div>{{s.name | translate}}</div>
            <img *ngIf="s.id === sortId || s.name === sortName" class="shift-check-mark"
                 src="../../../assets/images/Checkmark.svg"/>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="main" *ngIf="!loading || showableLogs.length > 0">
    <div class="whiteGround">
      <div class="board main-flex">
        <div class="first_header">{{'type' | translate | uppercase}}</div>
        <div class="secound_header">{{'eventDetails' | translate | uppercase}}</div>
        <div class="third_header">{{'date' | translate | uppercase}}</div>
        <div class="forth_header">{{'action' | translate | uppercase}}</div>
      </div>

      <div class="board  main-flex" *ngFor="let l of showableLogs">


        <div class="first_header2">
          <div class="image-flex">
            <div class="image-border" (click)="getL(l)">
              <img src="assets/images/cancel.svg" alt=""
                   *ngIf="isCancelled(l) " class="arrow">
              <img src="assets/images/swap.svg" alt="" *ngIf="l.targetType == 'SWAP' " class="arrow">
              <img src="assets/images/true.svg" alt=""
                   *ngIf="(l.targetType == 'EMPLOYEE_INVITATIONS' || l.targetType == 'DETAILS') && !isCancelled(l)"
                   class="arrow">
            </div>
            <div class="margin-min">
              <img class="awwatar" *ngIf="!l.profilePicture || l.profilePicture == ''"
                   [src]="'./assets/images/profile_white.svg'">
              <img class="awwatar" *ngIf="l.profilePicture && l.profilePicture != ''" [src]="l.profilePicture">
            </div>
          </div>
        </div>
        <div class="secound_header2">

          <div class="for-text">
            <div class="text one-part">
          <span class="text-part" *ngFor="let h of l.header" [ngClass]="{'bold-text': h.style == 'BOLD'}">
            {{h.text}}
          </span>
            </div>
            <div class="text two-part">
              {{l.body}}
            </div>
          </div>
        </div>
        <div class="third_header2 date-column"><span class="date_time">{{l.date | activityDate }}</span>
        </div>
        <div class="forth_header2">
          <button type="button" [ngClass]="{'manage': l.buttonColor == 'orange', 'goToDate': l.buttonColor == 'regular'}" (click)="logClick(l)">
            {{l.buttonText}}
          </button>
        </div>
      </div>

    </div>


    <!--PAGINATION-->
    <div class="for-pagination">
      <div class="shop-pagination" *ngIf="!loading || showableLogs.length > 0">
        <div class="pagination-button arrow-pagination" [ngClass]="{'not-cliccable-pagination': page == 0}"
             (click)="openPage(page - 1)">
          <mat-icon>arrow_left</mat-icon>
        </div>


        <div class="pagination-button next" *ngIf="page - 1 >= 1" (click)="openPage(page - 2)">
          <!-- productPage bc productPage starts with 0  -->
          {{page - 1}}
        </div>

        <div class="pagination-button next" *ngIf="page - 1 >= 0" (click)="openPage(page - 1)">
          <!-- productPage bc productPage starts with 0  -->
          {{page}}
        </div>

        <div class="pagination-button actual-pagination" (click)="openPage(page)">
          <!-- +1 bc productPage is 0  -->
          {{page + 1}}
        </div>

        <div class="pagination-button next" *ngIf="getLastPage() - page > 1" (click)="openPage(page + 1)">
          <!-- +2 bc productPage is 0  -->
          {{page + 2}}
        </div>

        <div class="pagination-button next" *ngIf="getLastPage() - page > 2" (click)="openPage(page + 2)">
          <!-- +3 bc productPage is 0  -->
          {{page + 3}}
        </div>
        <div class="pagination-dots" *ngIf="getLastPage() - page > 4">
          <mat-icon class="pagination-dots-icon">more_horiz</mat-icon>
        </div>
        <div class="pagination-button" *ngIf="getLastPage() - page > 3" (click)="openPage(getLastPage() - 1)">
          {{getLastPage()}}
        </div>


        <!-- productPage == getLastPage() - 1  ( -1 bc we start with 0)-->
        <div class="pagination-button arrow-pagination"
             [ngClass]="{'not-cliccable-pagination': page == getLastPage() - 1}" (click)="openPage(page + 1)">
          <mat-icon>arrow_right</mat-icon>
        </div>
      </div>
    </div>

  </div>

  <app-loading class="spinner" *ngIf="loading && showableLogs.length == 0"></app-loading>

</div>
