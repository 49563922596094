import { Component, OnDestroy, OnInit } from '@angular/core';
import { EmployeesService } from '../../shared/services/employees.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../modals/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import {
  Competence,
  Contract,
  Position,
} from '../../shared/models/attribute.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HelperService } from '../../shared/services/helper.service';
import { AuthConst } from '../../shared/services/const/authConst';
import { DomSanitizer } from '@angular/platform-browser';
import { TooltipShiftsModalsComponent } from '../modals/tooltip-shifts-modals/tooltip-shifts-modals.component';
import { UserAuthorities } from '../../shared/models/userAuthorities.model';
import { User } from '../../shared/models/user.model';
import { Group } from '../../shared/models/group.model';

@Component({
  selector: 'app-create-new-user',
  templateUrl: './create-new-user.component.html',
  styleUrls: ['./create-new-user.component.scss'],
})
export class CreateNewUserComponent implements OnInit, OnDestroy {
  userProfileForm: FormGroup = this.fb.group({
    firstname: [''],
    lastname: [''],
    email: [''],
    mobile: [''],
    note: [''],
    position: [''],
    competence: [''],
    contract: [''],
    status: [''],
    role: [''],
  });

  showCompetence = false;
  showPositions = false;
  showContract = false;
  showRoll = false;
  showStatus = false;
  positions$?: Observable<any>;
  position: string | undefined;
  competences$?: Observable<any>;
  competence: string | undefined;
  contracts$?: Observable<any>;
  contract: string | undefined;
  userId: number | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  status: string | undefined;
  rollStatus: string | undefined;
  userImage: any;
  role?: any;
  newUserId: number;
  positionId: number | undefined;
  userPosition: any;
  userCompetence: any;
  userContract: any;
  groupId: number;
  toOpenDialog = true;
  positions: Position[] = [];
  competences: Competence[] = [];
  competenceId: number;
  contracts: Contract[] = [];
  contractId: number;
  rolesFromForm: any[] = [];

  userCompetences: Competence[] = [];

  userAuthorities: UserAuthorities;
  user: User;
  selectedCompetences: Competence[] = [];
  selectedStatuses: any[] = [];
  group: Group = { id: 0, title: '', description: '' };
  userRoles: any[] = [];
  userRole = ['EMPLOYEE', 'EMPLOYER', 'ROSTER', 'ADMIN', 'COORDINATOR'];

  rowStatus: string | undefined;
  userFornew: any;
  emailForNew: any;
  sub = '';

  statusSelect = [
    {
      id: 1,
      status: 'ACTIVE',
    },
    {
      id: 2,
      status: 'INACTIVE',
    },
  ];

  rolls = [
    {
      id: 1,
      roll: 'EMPLOYEE',
      name: 'Medarbetare',
    },
    {
      id: 2,
      roll: 'EMPLOYER',
      name: 'Admin',
    },
    {
      id: 5,
      roll: 'COORDINATOR',
      name: 'Jour admin',
    }
  ];
  userExists = false;

  groupChangedSubscription: Subscription;

  constructor(
    private employeesService: EmployeesService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private translate: TranslateService,
    public snackBar: MatSnackBar,
    public router: Router,
    private helper: HelperService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.userId = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    this.groupId = Number(localStorage.getItem('gruopId'));

    this.emailForNew = localStorage.getItem(AuthConst.emailForNew);
    if (this.emailForNew !== undefined) {

      this.userProfileForm.patchValue({
        email: this.emailForNew,
      });
      localStorage.removeItem(AuthConst.emailForNew);
    }

    if (localStorage.getItem('unCheckedEmail') === 'false') {
      const userForNew = localStorage.getItem(AuthConst.userForNew);
      if (userForNew !== undefined) {
        this.userFornew = JSON.parse(userForNew);
        this.userId = this.userFornew.id;
        this.userExists = true;

        this.userProfileForm.patchValue({
          email: this.userFornew?.email,
          firstname: this.userFornew?.firstName,
          lastname: this.userFornew?.lastName,
          mobile: this.userFornew?.tel1,
        });

        this.newUserId = this.userFornew?.id;
        this.firstName = this.userProfileForm.value.firstname;
        this.lastName = this.userProfileForm.value.lastname;
        this.userImage = this.userFornew.profilePicture;
        this.userProfileForm.get('email').disable();
        this.userProfileForm.get('firstname').disable();
        this.userProfileForm.get('lastname').disable();
        this.userProfileForm.get('mobile').disable();

        localStorage.removeItem(AuthConst.userForNew);
      }
    }

    if (localStorage.getItem('unCheckedEmail')) {
      if (localStorage.getItem('unCheckedEmail') == 'false') {
        this.toOpenDialog = false;
      } else {
        this.toOpenDialog = true;
      }
      localStorage.removeItem('unCheckedEmail');
    }

    if (this.userId) {
      this.userInfo(this.userId);
    } else {
      this.getDropDownData();
    }

    this.groupChangedSubscription = this.helper.groupChanged$.subscribe(
      (data) => {
        if (data) {
          this.groupId = data;
          if (this.userId) {
            this.userService.getEmployeeById(this.userId).subscribe((x) => {
              this.firstName = x.firstName;
              this.lastName = x.lastName;
              this.status = x.rowStatus;

              // @ts-ignore
              this.userRole = Object.keys(x.userAuthorities?.roles);
              // @ts-ignore

              // tslint:disable-next-line:no-shadowed-variable
              this.rolls.find((x) => {
                // @ts-ignore
                if (x.roll === this.userRole[0]) {
                  this.userProfileForm.patchValue({
                    role: x.roll,
                  });
                }
              });



              if (x.userAuthorities?.roles.EMPLOYEE) {
                this.role = x.userAuthorities?.roles.EMPLOYEE;

                this.positionId = this.role[0].position.positionId;
                const status = this.role[0].status;
                // tslint:disable-next-line:no-shadowed-variable
                this.statusSelect.find((x) => {
                  if (x.status === status) {
                    this.userProfileForm.patchValue({
                      status: x.status,
                    });
                  }
                });

                this.employeesService
                  .userPositions()
                  .subscribe((newPositions: Position[]) => {
                    this.positions = newPositions.filter((x) => {
                      return (
                        x.groupId === this.groupId &&
                        x.groupStatus === 'ACTIVE' &&
                        x.rowStatus === 'ACTIVE'
                      );
                    });
                  });
                // tslint:disable-next-line:no-shadowed-variable
                const positions = this.positions;
                this.userPosition = positions.filter(
                  // @ts-ignore
                  (x: { id: number | undefined }) => x.id === this.positionId
                );
                this.userProfileForm.patchValue({
                  position: this.userPosition[0].title,
                });

                this.employeesService
                  .userCompetences()
                  .subscribe((competences: Competence[]) => {
                    this.competences = competences.filter((x) => {
                      return (
                        x.groupId === this.groupId &&
                        x.groupStatus === 'ACTIVE' &&
                        x.rowStatus === 'ACTIVE'
                      );
                    });
                  });
                // tslint:disable-next-line:no-shadowed-variable
                this.competences$.subscribe((x) => {
                  const competences = x;
                  this.userCompetence = competences.filter(
                    // tslint:disable-next-line:no-shadowed-variable
                    (x: { id: number | undefined }) => x.id === this.positionId
                  );
                  this.userProfileForm.patchValue({
                    competence: this.userCompetence[0].title,
                  });
                });

                this.employeesService
                  .userContracts()
                  .subscribe((contracts: Contract[]) => {
                    this.contracts = contracts.filter((contract) => {
                      return (
                        contract.groupId === this.groupId &&
                        contract.groupStatus === 'ACTIVE' &&
                        contract.rowStatus === 'ACTIVE'
                      );
                    });
                  });
                // tslint:disable-next-line:no-shadowed-variable
                this.contracts$.subscribe((x) => {
                  const contracts = x;
                  this.userContract = contracts.filter(
                    // tslint:disable-next-line:no-shadowed-variable
                    (x: { id: number | undefined }) => x.id === this.positionId
                  );
                  this.userProfileForm.patchValue({
                    contract: this.userContract[0].title,
                  });
                });
              }
              else if (x.userAuthorities?.roles.EMPLOYER) {
                this.role = x.userAuthorities?.roles.EMPLOYER;

                this.positionId = this.role[0].position.positionId;
                const status = this.role[0].status;
                // tslint:disable-next-line:no-shadowed-variable
                this.statusSelect.find((x) => {
                  if (x.status === status) {
                    this.userProfileForm.patchValue({
                      status: x.status,
                    });
                  }
                });

                this.employeesService
                  .userPositions()
                  .subscribe((newPositions: Position[]) => {
                    this.positions = newPositions.filter((x) => {
                      return (
                        x.groupId === this.groupId &&
                        x.groupStatus === 'ACTIVE' &&
                        x.rowStatus === 'ACTIVE'
                      );
                    });
                  });
                // tslint:disable-next-line:no-shadowed-variable
                const positions = this.positions;
                this.userPosition = positions.filter(
                  // @ts-ignore
                  (x: { id: number | undefined }) => x.id === this.positionId
                );
                this.userProfileForm.patchValue({
                  position: this.userPosition[0].title,
                });

                this.employeesService
                  .userCompetences()
                  .subscribe((competences: Competence[]) => {
                    this.competences = competences.filter((x) => {
                      return (
                        x.groupId === this.groupId &&
                        x.groupStatus === 'ACTIVE' &&
                        x.rowStatus === 'ACTIVE'
                      );
                    });
                  });
                // tslint:disable-next-line:no-shadowed-variable
                this.competences$.subscribe((x) => {
                  const competences = x;
                  this.userCompetence = competences.filter(
                    // tslint:disable-next-line:no-shadowed-variable
                    (x: { id: number | undefined }) => x.id === this.positionId
                  );
                  this.userProfileForm.patchValue({
                    competence: this.userCompetence[0].title,
                  });
                });

                this.employeesService
                  .userContracts()
                  .subscribe((contracts: Contract[]) => {
                    this.contracts = contracts.filter((contract) => {
                      return (
                        contract.groupId === this.groupId &&
                        contract.groupStatus === 'ACTIVE' &&
                        contract.rowStatus === 'ACTIVE'
                      );
                    });
                  });
                // tslint:disable-next-line:no-shadowed-variable
                this.contracts$.subscribe((x) => {
                  const contracts = x;
                  this.userContract = contracts.filter(
                    // tslint:disable-next-line:no-shadowed-variable
                    (x: { id: number | undefined }) => x.id === this.positionId
                  );
                  this.userProfileForm.patchValue({
                    contract: this.userContract[0].title,
                  });
                });
              }
              else if (x.userAuthorities?.roles.ADMIN) {
                this.role = x.userAuthorities?.roles.ADMIN;

                this.positionId = this.role[0].position.positionId;
                const status = this.role[0].status;
                // tslint:disable-next-line:no-shadowed-variable
                this.statusSelect.find((x) => {
                  if (x.status === status) {
                    this.userProfileForm.patchValue({
                      status: x.status,
                    });
                  }
                });

                this.employeesService
                  .userPositions()
                  .subscribe((newPositions: Position[]) => {
                    this.positions = newPositions.filter((x) => {
                      return (
                        x.groupId === this.groupId &&
                        x.groupStatus === 'ACTIVE' &&
                        x.rowStatus === 'ACTIVE'
                      );
                    });
                  });
                // tslint:disable-next-line:no-shadowed-variable
                const positions = this.positions;
                this.userPosition = positions.filter(
                  // @ts-ignore
                  (x: { id: number | undefined }) => x.id === this.positionId
                );
                this.userProfileForm.patchValue({
                  position: this.userPosition[0].title,
                });

                this.employeesService
                  .userCompetences()
                  .subscribe((competences: Competence[]) => {
                    this.competences = competences.filter((x) => {
                      return (
                        x.groupId === this.groupId &&
                        x.groupStatus === 'ACTIVE' &&
                        x.rowStatus === 'ACTIVE'
                      );
                    });
                  });
                // tslint:disable-next-line:no-shadowed-variable
                this.competences$.subscribe((x) => {
                  const competences = x;
                  this.userCompetence = competences.filter(
                    // tslint:disable-next-line:no-shadowed-variable
                    (x: { id: number | undefined }) => x.id === this.positionId
                  );
                  this.userProfileForm.patchValue({
                    competence: this.userCompetence[0].title,
                  });
                });

                this.employeesService
                  .userContracts()
                  .subscribe((contracts: Contract[]) => {
                    this.contracts = contracts.filter((contract) => {
                      return (
                        contract.groupId === this.groupId &&
                        contract.groupStatus === 'ACTIVE' &&
                        contract.rowStatus === 'ACTIVE'
                      );
                    });
                  });
                // tslint:disable-next-line:no-shadowed-variable
                this.contracts$.subscribe((x) => {
                  const contracts = x;
                  this.userContract = contracts.filter(
                    // tslint:disable-next-line:no-shadowed-variable
                    (x: { id: number | undefined }) => x.id === this.positionId
                  );
                  this.userProfileForm.patchValue({
                    contract: this.userContract[0].title,
                  });
                });
              }
              else if (x.userAuthorities?.roles.SUPER_ADMIN) {
                this.role = x.userAuthorities?.roles.SUPER_ADMIN;

                this.positionId = this.role[0].position.positionId;
                const status = this.role[0].status;
                // tslint:disable-next-line:no-shadowed-variable
                this.statusSelect.find((x) => {
                  if (x.status === status) {
                    this.userProfileForm.patchValue({
                      status: x.status,
                    });
                  }
                });

                this.employeesService
                  .userPositions()
                  .subscribe((newPositions: Position[]) => {
                    this.positions = newPositions.filter((x) => {
                      return (
                        x.groupId === this.groupId &&
                        x.groupStatus === 'ACTIVE' &&
                        x.rowStatus === 'ACTIVE'
                      );
                    });
                  });
                // tslint:disable-next-line:no-shadowed-variable
                const positions = this.positions;
                this.userPosition = positions.filter(
                  // @ts-ignore
                  (x: { id: number | undefined }) => x.id === this.positionId
                );
                this.userProfileForm.patchValue({
                  position: this.userPosition[0].title,
                });

                this.employeesService
                  .userCompetences()
                  .subscribe((competences: Competence[]) => {
                    this.competences = competences.filter((x) => {
                      return (
                        x.groupId === this.groupId &&
                        x.groupStatus === 'ACTIVE' &&
                        x.rowStatus === 'ACTIVE'
                      );
                    });
                  });
                // tslint:disable-next-line:no-shadowed-variable
                this.competences$.subscribe((x) => {
                  const competences = x;
                  this.userCompetence = competences.filter(
                    // tslint:disable-next-line:no-shadowed-variable
                    (x: { id: number | undefined }) => x.id === this.positionId
                  );
                  this.userProfileForm.patchValue({
                    competence: this.userCompetence[0].title,
                  });
                });

                this.employeesService
                  .userContracts()
                  .subscribe((contracts: Contract[]) => {
                    this.contracts = contracts.filter((contract) => {
                      return (
                        contract.groupId === this.groupId &&
                        contract.groupStatus === 'ACTIVE' &&
                        contract.rowStatus === 'ACTIVE'
                      );
                    });
                  });
                // tslint:disable-next-line:no-shadowed-variable
                this.contracts$.subscribe((x) => {
                  const contracts = x;
                  this.userContract = contracts.filter(
                    // tslint:disable-next-line:no-shadowed-variable
                    (x: { id: number | undefined }) => x.id === this.positionId
                  );
                  this.userProfileForm.patchValue({
                    contract: this.userContract[0].title,
                  });
                });
              }
              else if (x.userAuthorities?.roles.COORDINATOR) {
                this.role = x.userAuthorities?.roles.COORDINATOR;
                this.positionId = this.role[0].position.positionId;

                const status = this.role[0].status;
                // tslint:disable-next-line:no-shadowed-variable
                this.statusSelect.find((x) => {
                  if (x.status === status) {
                    this.userProfileForm.patchValue({
                      status: x.status,
                    });
                  }
                });

                this.employeesService
                  .userPositions()
                  .subscribe((newPositions: Position[]) => {
                    this.positions = newPositions.filter((x) => {
                      return (
                        x.groupId === this.groupId &&
                        x.groupStatus === 'ACTIVE' &&
                        x.rowStatus === 'ACTIVE'
                      );
                    });
                  });
                // tslint:disable-next-line:no-shadowed-variable
                const positions = this.positions;
                this.userPosition = positions.filter(
                  // @ts-ignore
                  (x: { id: number | undefined }) => x.id === this.positionId
                );
                this.userProfileForm.patchValue({
                  position: this.userPosition[0].title,
                });

                this.employeesService
                  .userCompetences()
                  .subscribe((competences: Competence[]) => {
                    this.competences = competences.filter((x) => {
                      return (
                        x.groupId === this.groupId &&
                        x.groupStatus === 'ACTIVE' &&
                        x.rowStatus === 'ACTIVE'
                      );
                    });
                  });
                // tslint:disable-next-line:no-shadowed-variable
                this.competences$.subscribe((x) => {
                  const competences = x;
                  this.userCompetence = competences.filter(
                    // tslint:disable-next-line:no-shadowed-variable
                    (x: { id: number | undefined }) => x.id === this.positionId
                  );
                  this.userProfileForm.patchValue({
                    competence: this.userCompetence[0].title,
                  });
                });

                this.employeesService
                  .userContracts()
                  .subscribe((contracts: Contract[]) => {
                    this.contracts = contracts.filter((contract) => {
                      return (
                        contract.groupId === this.groupId &&
                        contract.groupStatus === 'ACTIVE' &&
                        contract.rowStatus === 'ACTIVE'
                      );
                    });
                  });
                // tslint:disable-next-line:no-shadowed-variable
                this.contracts$.subscribe((x) => {
                  const contracts = x;
                  this.userContract = contracts.filter(
                    // tslint:disable-next-line:no-shadowed-variable
                    (x: { id: number | undefined }) => x.id === this.positionId
                  );
                  this.userProfileForm.patchValue({
                    contract: this.userContract[0].title,
                  });
                });
              }
              else if (x.userAuthorities?.roles.ROSTER) {
                this.role = x.userAuthorities?.roles.ROSTER;

                this.positionId = this.role[0].position.positionId;
                const status = this.role[0].status;
                // tslint:disable-next-line:no-shadowed-variable
                this.statusSelect.find((x) => {
                  if (x.status === status) {
                    this.userProfileForm.patchValue({
                      status: x.status,
                    });
                  }
                });

                this.employeesService
                  .userPositions()
                  .subscribe((newPositions: Position[]) => {
                    this.positions = newPositions.filter((x) => {
                      return (
                        x.groupId === this.groupId &&
                        x.groupStatus === 'ACTIVE' &&
                        x.rowStatus === 'ACTIVE'
                      );
                    });
                  });
                // tslint:disable-next-line:no-shadowed-variable
                const positions = this.positions;
                this.userPosition = positions.filter(
                  // @ts-ignore
                  (x: { id: number | undefined }) => x.id === this.positionId
                );
                this.userProfileForm.patchValue({
                  position: this.userPosition[0].title,
                });

                this.employeesService
                  .userCompetences()
                  .subscribe((competences: Competence[]) => {
                    this.competences = competences.filter((x) => {
                      return (
                        x.groupId === this.groupId &&
                        x.groupStatus === 'ACTIVE' &&
                        x.rowStatus === 'ACTIVE'
                      );
                    });
                  });
                // tslint:disable-next-line:no-shadowed-variable
                this.competences$.subscribe((x) => {
                  const competences = x;
                  this.userCompetence = competences.filter(
                    // tslint:disable-next-line:no-shadowed-variable
                    (x: { id: number | undefined }) => x.id === this.positionId
                  );
                  this.userProfileForm.patchValue({
                    competence: this.userCompetence[0].title,
                  });
                });

                this.employeesService
                  .userContracts()
                  .subscribe((contracts: Contract[]) => {
                    this.contracts = contracts.filter((contract) => {
                      return (
                        contract.groupId === this.groupId &&
                        contract.groupStatus === 'ACTIVE' &&
                        contract.rowStatus === 'ACTIVE'
                      );
                    });
                  });
                // tslint:disable-next-line:no-shadowed-variable
                this.contracts$.subscribe((x) => {
                  const contracts = x;
                  this.userContract = contracts.filter(
                    // tslint:disable-next-line:no-shadowed-variable
                    (x: { id: number | undefined }) => x.id === this.positionId
                  );
                  this.userProfileForm.patchValue({
                    contract: this.userContract[0].title,
                  });
                });
              }

              this.userProfileForm.patchValue({
                firstname: x.firstName,
                lastname: x.lastName,
                email: x.email,
                mobile: x.tel1,
                status: this.statusSelect.forEach(
                  (status) => status.status === 'ACTIVE'
                ),
              });
            });
          } else {
            this.getDropDownData();
          }
        }
      }
    );
    this.getStatus(  {
      id: 1,
      status: 'ACTIVE',
    });
  }

  getDropDownData(): void {
    this.employeesService.userPositions().subscribe((positions: Position[]) => {
      this.positions = positions.filter((x) => {
        return (
          x.groupId === this.groupId &&
          x.groupStatus === 'ACTIVE' &&
          x.rowStatus === 'ACTIVE'
        );
      });
    });
    this.employeesService
      .userCompetences()
      .subscribe((competences: Competence[]) => {
        this.competences = competences.filter((x) => {
          return (
            x.groupId === this.groupId &&
            x.groupStatus === 'ACTIVE' &&
            x.rowStatus === 'ACTIVE'
          );
        });
      });
    this.employeesService.userContracts().subscribe((contracts: Contract[]) => {
      this.contracts = contracts.filter((x) => {
        return (
          x.groupId === this.groupId &&
          x.groupStatus === 'ACTIVE' &&
          x.rowStatus === 'ACTIVE'
        );
      });
    });
  }

  userInfo(userId: number): void {
    setTimeout(() => {
      this.helper.userId.next(this.userId);
    }, 500);
    this.userService.getEmployeeById(userId).subscribe((x) => {
      this.user = x;

      if (this.user.userAuthorities.roles.COORDINATOR) {
        this.rowStatus = this.user.userAuthorities.roles.COORDINATOR[0]?.status;
      }

      if (this.user.userAuthorities.roles.EMPLOYER) {
        this.rowStatus = this.user.userAuthorities.roles.EMPLOYER[0]?.status;
      }

      if (this.user.userAuthorities.roles.EMPLOYEE) {
      this.rowStatus = this.user.userAuthorities.roles.EMPLOYEE[0]?.status;
      }





      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.status = this.user.rowStatus;
      this.userProfileForm.patchValue({
        note: this.user.note,
      });
      this.helper.userName.next(this.firstName + ' ' + this.lastName);

      this.userAuthorities = this.user.userAuthorities;
      this.userRole = Object.keys(this.user.userAuthorities?.roles);

      this.getDropDownData();

      this.userProfileForm.patchValue({
        firstname: this.user.firstName,
        lastname: this.user.lastName,
        email: this.user.email,
        mobile: this.user.tel1,
      });
    });
  }

  /**
   * Display Competence dropdown
   */
  displayCompetence(): void {
    this.showCompetence = !this.showCompetence;
  }

  /**
   * Display Positions dropdown
   */
  displayPositions(): void {
    this.showPositions = !this.showPositions;
  }

  /**
   * Display Contract dropdown
   */
  displayContract(): void {
    this.showContract = !this.showContract;
  }

  /**
   * Display Roll dropdown
   */
  displayRoll(): void {
    this.showRoll = !this.showRoll;
  }

  /**
   * Display Status dropdown
   */
  displayStatus(): void {
    this.showStatus = !this.showStatus;
  }

  getPosition(position: any): void {
    if (position !== null) {
      this.position = position.title;
      this.positionId = position.id;
      this.userProfileForm.patchValue({
        position: this.position,
      });
    } else {
      this.positionId = null;
      this.userProfileForm.patchValue({
        position: this.translate.instant('select'),
      });
    }
  }

  getCompetence(competence: Competence): void {
      if (competence == null) {
        this.selectedCompetences = [];
      } else {
        competence.competenceId = competence.id;
        const index = this.selectedCompetences.findIndex((c: any) => c.competenceId == competence.id);
        if (index != -1) {
          this.selectedCompetences.splice(index, 1);
        } else {
          this.selectedCompetences.push(competence);
        }
      }


      let competencesString = '';
      if (this.selectedCompetences.length > 0) {
        this.selectedCompetences.forEach((c) => {
          let name = '';
          if (c.competenceShortTitle) {
            name = c.competenceShortTitle;
          } else {
            name = c.shortTitle;
          }
          if (competencesString === '') {
            competencesString = competencesString + name;
          } else {
            competencesString =
              competencesString + ', ' + name;
          }
        });
      } else {
        competencesString = this.translate.instant('select');
      }

      this.userProfileForm.patchValue({
        competence: competencesString,
      });

  }

  competenceSet(competence: any): boolean {
    const index = this.selectedCompetences.findIndex((c: any) => c.competenceId == competence.id);

    return index != -1;
  }

  getSub(data: {
    position: { positionShortTitle: string };
    contractTitle: string;
  }): void {
    let competenceString = '';
    competenceString = data.position.positionShortTitle;
    this.sub = competenceString + ', ' + data.contractTitle;
  }

  getContract(contract: any): void {
    if (contract !== null) {
      this.contract = contract.title;
      this.contractId = contract.id;
      this.userProfileForm.patchValue({
        contract: this.contract,
      });
    } else {
      this.contractId = null;
      this.userProfileForm.patchValue({
        contract: this.translate.instant('select'),
      });
    }
  }

  getStatus(status: { id: number; status: string }): void {
    if (status !== null) {
      this.rowStatus = status.status;
      this.userProfileForm.patchValue({
        status: this.translate.instant(status.status.toString().toLocaleLowerCase()),
      });
    } else {
      this.rowStatus = undefined;
      this.userProfileForm.patchValue({
        status: this.translate.instant('select'),
      });
    }
  }

  getRealStatus(status: string): string {

    if (status.toLowerCase() == "aktiv") {
      return "Active";
    } else {
      return "Inactive";
    }
  }

  roleIsSelected(status: string): boolean {
    const statusIndex = this.selectedStatuses.findIndex((s: any) => {
      return s === status;
    });

    return statusIndex !== -1;
  }

  getRoll(status: string): void {
      const statusIndex = this.selectedStatuses.findIndex((s: any) => {
        return s === status;
      });

      if (statusIndex === -1) {
        this.selectedStatuses.push(status);
        this.rollStatus = this.selectedStatuses[0];
      } else {
        this.selectedStatuses.splice(statusIndex, 1);
      }

      let statusString = '';

      this.selectedStatuses.forEach((s) => {
        if (s == 'EMPLOYER') {
          s = 'Admin';
        } else if (s == 'EMPLOYEE') {
          s = 'Medarbetare';
        } else if (s == 'ROSTER') {
          s = 'Avdelningsschema';
        } else if (s == 'COORDINATOR') {
          s = 'Jour admin';
        }
        if (statusString === '') {
          statusString = statusString + s.toString().toLowerCase();
        } else {
          statusString = statusString + ', ' + s.toString().toLowerCase();
        }
      });
      this.userProfileForm.patchValue({
        role: statusString,
      });
  }

  findRole(r: string): string {
    let role = '';
    if (r == 'EMPLOYEE') {
      role = 'ROLE_EMPLOYEE';
    } else if (r == 'EMPLOYER') {
      role = 'ROLE_EMPLOYER';
    } else if (r == 'COORDINATOR') {
      role = 'ROLE_COORDINATOR';
    } else if (r == 'ROSTER') {
      role = 'ROLE_ROSTER';
    } else if (r == 'ADMIN') {
      role = 'ROLE_ADMIN';
    } else if (r == 'SUPER_ADMIN') {
      role = 'ROLE_SUPER_ADMIN';
    } else if (r == 'COORDINATOR') {
      role = 'ROLE_COORDINATOR';
    }
    return role;
  }

  create(): void {
    let subtitle = '';
    let message = '';
    let title = '';
    let showCheckbox = false;

    title = this.translate.instant('saveButton');
    subtitle = this.translate.instant('sendLoginDetails');
    message = this.translate.instant('connectUserToWorkSite');
    showCheckbox = true;

    if (
      this.userProfileForm.controls.firstname.value === '' ||
      this.userProfileForm.controls.lastname.value === '' ||
      this.userProfileForm.controls.position.value === '' ||
      this.userProfileForm.controls.contract.value === '' ||
      this.userProfileForm.controls.role.value === ''
    ) {
      this.snackBar.open(this.translate.instant('fillAllFields'));
    } else {
      let comp: any = ConfirmDialogComponent;
      if (this.userExists) {
        comp = TooltipShiftsModalsComponent;
        title = this.translate.instant('saveNewUserTitle');
        subtitle = this.translate.instant('saveNewUserSubtitle');
        message = this.translate.instant('saveNewUserText');
      }

      const confirmDialog = this.dialog.open(comp, {
        disableClose: true,
        width: '400px',
        height: '256px',
        panelClass: 'updatePopUp',
        position: {
          top: '150px',
        },
        data: {
          title,
          subtitle,
          message,
          showCheckbox,
          isCreateUser: true
        },
      });
      confirmDialog.afterClosed().subscribe((result) => {
        if (result) {
          if (result && this.emailForNew !== null) {

            let selectedCompetence: Competence = null;
            const competenceIndex = this.competences.findIndex((x) => {
              return x.title == this.userProfileForm.value.competence;
            });

            if (competenceIndex !== -1) {
              selectedCompetence = this.competences[competenceIndex];
            }

            let selectedPosition: Position = null;
            const positionIndex = this.positions.findIndex((x) => {
              return x.title == this.userProfileForm.value.position;
            });

            let contractId = 0;

            let selectedContract: Contract = null;
            const contractIndex = this.contracts.findIndex((x) => {
              return x.title == this.userProfileForm.value.contract;
            });

            if (contractIndex !== -1) {
              selectedContract = this.contracts[contractIndex];
              contractId = this.contracts[contractIndex].id;
              selectedContract.id = this.contracts[contractIndex].id;
            }

            if (positionIndex !== -1) {
              selectedPosition = this.positions[positionIndex];
            }
            let enabled = 0;
            this.userProfileForm.value.status == 'ACTIVE'
              ? (enabled = 1)
              : (enabled = 0);

            const create = {
              // id: undefined,
              firstName: this.userProfileForm.controls.firstname.value,
              lastName: this.userProfileForm.controls.lastname.value,
              username: this.userProfileForm.controls.email.value,
              email: this.userProfileForm.controls.email.value,
              tel1: this.userProfileForm.controls.mobile.value,
              note: this.userProfileForm.value.note,
              status: this.userProfileForm.value.status,
              enabled,
              password: 'gapstaff',
              // role: this.userProfileForm.value.role,
              // roles: [this.userProfileForm.value.role],
            };


            this.employeesService.createUser(create).subscribe((data) => {
              const count = 0;
              const authorities: any[] = [];
              const roles = [];

              // this.selectedStatuses.forEach((r: any) => {
              //   const obj: any = {};
              //   obj.role = this.findRole(r);
              //   if (r == 'MANAGER') {
              //     r = 'EMPLOYER';
              //   }
              //   roles.push(r);
              //   obj.groups = [];
              //   const group: any = {};
              //   group.group = this.groupId;
              //   group.contract = selectedContract.id;
              //   group.positions = [selectedPosition];
              //   group.position = selectedPosition;
              //   group.salaryModel = 0;
              //   group.groupStatus = this.userProfileForm.value.status;
              //   obj.groups.push(group);
              //   authorities.push(obj);
              // });
              let rowStatus = 1;

              if (this.getRealStatus(this.userProfileForm.value.status).toUpperCase() != 'ACTIVE') {
                rowStatus = 2;
              }

              const fineData = {
                groupId: this.groupId,
                competences: this.selectedCompetences,
                position: selectedPosition,
                roles: this.selectedStatuses,
                contractId,
                contractTitle: selectedContract?.title,
                status: this.getRealStatus(this.userProfileForm.value.status).toUpperCase(),
                rowStatus
              };
              authorities.push(fineData);



              this.userService
                .updateUserAuthorities(authorities, data.id)
                .subscribe(async () => {
                  if (result.checked) {
                    const usr = data;
                    usr.rowStatus = String(1);
                    this.userService.sendLoginInformation(usr).subscribe(
                      (data: any) => {
                      }
                    );
                  }



                  this.snackBar.open(this.translate.instant('saved'), 'OK', {
                      duration: 3000,
                    });


                  this.router.navigate(['/personal/user/', data.id]);
                });
            });
          } else {
            this.saveExisting();
            //
            // const update = {
            //   firstName: this.userProfileForm.controls.firstname.value,
            //   lastName: this.userProfileForm.controls.lastname.value,
            //   email: this.userProfileForm.controls.email.value,
            //   tel1: this.userProfileForm.controls.mobile.value,
            //   note: this.userProfileForm.controls.note.value,
            //   position: this.positionId,
            //   competence: this.competenceId,
            //   contract: this.contractId,
            //   rowStatus: 1,
            //   id: this.userId,
            // };
            //
          }
        }
      });
    }
  }

  saveExisting(): void {
    // this.selectedStatuses = this.rolesFromForm;

    // find contract
    const contractIndex = this.contracts.findIndex((x) => {
      return x.title === this.userProfileForm.value.contract;
    });

    if (contractIndex === -1) {
      this.snackBar.open(this.translate.instant('checkSelectedValues'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
      return;
    }

    const selectedContract = this.contracts[contractIndex];

    // find position

    const positionIndex = this.positions.findIndex((p) => {
      return p.title === this.userProfileForm.value.position;
    });

    if (positionIndex === -1) {
      this.snackBar.open(this.translate.instant('checkSelectedValues'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
      return;
    }

    const selectedPosition = this.positions[positionIndex];
    selectedPosition.position = selectedPosition.id;
    selectedPosition.competenceIds = [];
    this.competences.forEach((p) => {
      selectedPosition.competenceIds.push(p.competenceId);
    });

    let selectedRole = '';
    let selectedRoleString = '';
    const authorities: any[] = [];
    this.userAuthorities.roles.EMPLOYEE?.forEach((r) => {
      const groups = [];
      const obj = {group: r.groupId, contract: r.contractId, groupStatus: 'active', position: r.position, positions: [r.position], salaryModel: 0};

      const competences: any[] = [];
      obj.position?.competences.forEach((c: any) => {
        competences.push(c.competenceId);
      });
      obj.position.competenceIds = competences;

      obj.positions.forEach((p: any) => {
        const posComp: any[] = [];
        p.competences.forEach((c: any) => {
          posComp.push(c.competenceId);
        });
        p.competenceIds = posComp;
      });
      // @ts-ignore
      obj.position.id = obj.position.positionId;
      // @ts-ignore
      obj.position.position = obj.position.positionId;
      groups.push(obj);
      const groupObj = {role: 'ROLE_EMPLOYEE', groups};
      authorities.push(groupObj);
    });


    this.userAuthorities.roles.EMPLOYER?.forEach((r) => {
      const groups = [];
      const obj = {group: r.groupId, contract: r.contractId, groupStatus: 'active', position: r.position, positions: [r.position], salaryModel: 0};

      const competences: any[] = [];
      obj.position.competences.forEach((c: any) => {
        competences.push(c.competenceId);
      });
      obj.position.competenceIds = competences;

      obj.positions.forEach((p: any) => {
        const posComp: any[] = [];
        p.competences.forEach((c: any) => {
          posComp.push(c.competenceId);
        });
        p.competenceIds = posComp;
      });
      // @ts-ignore
      obj.position.id = obj.position.positionId;
      // @ts-ignore
      obj.position.position = obj.position.positionId;
      groups.push(obj);
      const groupObj = {role: 'ROLE_EMPLOYER', groups};
      authorities.push(groupObj);
    });

    this.userAuthorities.roles.COORDINATOR?.forEach((r) => {
      const groups = [];
      const obj = {group: r.groupId, contract: r.contractId, groupStatus: 'active', position: r.position, positions: [r.position], salaryModel: 0};


      const competences: any[] = [];
      obj.position.competences.forEach((c: any) => {
        competences.push(c.competenceId);
      });
      obj.position.competenceIds = competences;

      obj.positions.forEach((p: any) => {
        const posComp: any[] = [];
        p.competences.forEach((c: any) => {
          posComp.push(c.competenceId);
        });
        p.competenceIds = posComp;
      });
      // @ts-ignore
      obj.position.id = obj.position.positionId;
      // @ts-ignore
      obj.position.position = obj.position.positionId;
      groups.push(obj);
      const groupObj = {role: 'ROLE_COORDINATOR', groups};
      authorities.push(groupObj);
    });

    this.userAuthorities.roles.ROSTER?.forEach((r) => {
      const groups = [];
      const obj = {group: r.groupId, contract: r.contractId, groupStatus: 'active', position: r.position, positions: [r.position], salaryModel: 0};



      const competences: any[] = [];
      obj.position.competences.forEach((c: any) => {
        competences.push(c.competenceId);
      });
      obj.position.competenceIds = competences;

      obj.positions.forEach((p: any) => {
        const posComp: any[] = [];
        p.competences.forEach((c: any) => {
          posComp.push(c.competenceId);
        });
        p.competenceIds = posComp;
      });
      // @ts-ignore
      obj.position.id = obj.position.positionId;
      // @ts-ignore
      obj.position.position = obj.position.positionId;
      groups.push(obj);
      const groupObj = {role: 'ROLE_ROSTER', groups};
      authorities.push(groupObj);
    });

    this.userAuthorities.roles.ADMIN?.forEach((r) => {
      const groups = [];
      const obj = {group: r.groupId, contract: r.contractId, groupStatus: 'active', position: r.position, positions: [r.position], salaryModel: 0};


      const competences: any[] = [];
      obj.position.competences.forEach((c: any) => {
        competences.push(c.competenceId);
      });

      obj.positions.forEach((p: any) => {
        const posComp: any[] = [];
        p.competences.forEach((c: any) => {
          posComp.push(c.competenceId);
        });
        p.competenceIds = posComp;
      });

      obj.position.competenceIds = competences;

      // @ts-ignore
      obj.position.id = obj.position.positionId;
      // @ts-ignore
      obj.position.position = obj.position.positionId;
      groups.push(obj);
      const groupObj = {role: 'ROLE_ADMIN', groups};
      authorities.push(groupObj);
    });

    if (this.userProfileForm.value.role === 'Employee') {
      if (!this.userAuthorities.roles.EMPLOYEE) {
        this.userAuthorities.roles.EMPLOYEE = [];
      }
      selectedRole = 'ROLE_EMPLOYEE';
      selectedRoleString = 'EMPLOYEE';
      this.userAuthorities.roles.EMPLOYEE.forEach((r) => {
        if (r.groupId === this.groupId) {
          r.contractId = selectedContract.id;
          r.contractTitle = selectedContract.title;
          r.contractDescription = selectedContract.description;
          r.position = selectedPosition;
          r.competences = this.selectedCompetences;
        }
      });
    } else if (this.userProfileForm.value.role === 'Employer') {
      if (!this.userAuthorities.roles.EMPLOYER) {
        this.userAuthorities.roles.EMPLOYER = [];
      }
      selectedRole = 'ROLE_EMPLOYER';
      selectedRoleString = 'EMPLOYER';
      this.userAuthorities.roles.EMPLOYER.forEach((r) => {
        if (r.groupId === this.groupId) {
          r.contractId = selectedContract.id;
          r.contractTitle = selectedContract.title;
          r.contractDescription = selectedContract.description;
          r.position = selectedPosition;
          r.competences = this.selectedCompetences;
        } else {
          r.roles = ['ROLE_EMPLOYER'];
          authorities.push(r);
        }
      });
    } else if (this.userProfileForm.value.role === 'Admin') {
      selectedRole = 'ROLE_ADMIN';
      selectedRoleString = 'ADMIN';
      if (!this.userAuthorities.roles.ADMIN) {
        this.userAuthorities.roles.ADMIN = [];
      }
      this.userAuthorities.roles.ADMIN.forEach((r) => {
        if (r.groupId === this.groupId) {
          r.contractId = selectedContract.id;
          r.contractTitle = selectedContract.title;
          r.contractDescription = selectedContract.description;
          r.position = selectedPosition;
          r.competences = this.selectedCompetences;
        } else {
          r.roles = ['ROLE_ADMIN'];
          authorities.push(r);
        }
      });
    } else if (this.userProfileForm.value.role === 'Roster') {
      selectedRole = 'ROLE_ROSTER';
      selectedRoleString = 'ROSTER';
      if (!this.userAuthorities.roles.ADMIN) {
        this.userAuthorities.roles.ADMIN = [];
      }
      this.userAuthorities.roles.ADMIN.forEach((r) => {
        if (r.groupId === this.groupId) {
          r.contractId = selectedContract.id;
          r.contractTitle = selectedContract.title;
          r.contractDescription = selectedContract.description;
          r.position = selectedPosition;
          r.competences = this.selectedCompetences;
        } else {
          r.roles = ['ROLE_ROSTER'];
          authorities.push(r);
        }
      });
    } else if (this.userProfileForm.value.role === 'Super_admin') {
      selectedRole = 'ROLE_SUPER_ADMIN';
      selectedRoleString = 'SUPER_ADMIN';
      if (!this.userAuthorities.roles.ADMIN) {
        this.userAuthorities.roles.ADMIN = [];
      }
      this.userAuthorities.roles.ADMIN.forEach((r) => {
        if (r.groupId === this.groupId) {
          r.contractId = selectedContract.id;
          r.contractTitle = selectedContract.title;
          r.contractDescription = selectedContract.description;
          r.position = selectedPosition;
          r.competences = this.selectedCompetences;
        } else {
          r.roles = ['ROLE_SUPER_ADMIN'];
          authorities.push(r);
        }
      });
    } else if (this.userProfileForm.value.role === 'Coordinator') {
      selectedRole = 'ROLE_COORDINATOR';
      selectedRoleString = 'COORDINATOR';
      if (!this.userAuthorities.roles.ADMIN) {
        this.userAuthorities.roles.ADMIN = [];
      }
      this.userAuthorities.roles.ADMIN.forEach((r) => {
        if (r.groupId === this.groupId) {
          r.contractId = selectedContract.id;
          r.contractTitle = selectedContract.title;
          r.contractDescription = selectedContract.description;
          r.position = selectedPosition;
          r.competences = this.selectedCompetences;
        } else {
          r.roles = ['ROLE_COORDINATOR'];
          authorities.push(r);
        }
      });
    }

    const roles: any[] = [];

    this.selectedStatuses.forEach((r: any) => {
      const obj: any = {};
      obj.role = this.findRole(r);
      roles.push(r);
      obj.groups = [];
      const group: any = {};
      group.group = this.groupId;
      group.contract = selectedContract.id;
      group.positions = [selectedPosition];
      group.position = selectedPosition;
      group.salaryModel = 0;
      group.groupStatus = this.userProfileForm.value.status;
      obj.groups.push(group);
      authorities.push(obj);
    });

    const update = {
      firstName: this.userProfileForm.controls.firstname.value,
      lastName: this.userProfileForm.controls.lastname.value,
      username: this.userProfileForm.controls.email.value,
      email: this.userProfileForm.controls.email.value,
      tel1: this.userProfileForm.controls.mobile.value,
      note: this.userProfileForm.value.note,
      position: this.userProfileForm.controls.position.value,
      competence: this.userProfileForm.controls.competence.value,
      contract: this.userProfileForm.controls.contract.value,
      rowStatus: 1,
      id: this.userId,
      roles,
      authorities,
    };

    this.employeesService.updateUser(update).subscribe((user) => {
      this.snackBar.open(this.translate.instant('saved'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
      this.router.navigate(['/personal/user/', user.id]);
      this.helper.updateUserPersonalSchedule.next(user);
    });
  }

  ngOnDestroy(): void {
    this.groupChangedSubscription?.unsubscribe();
  }
}
