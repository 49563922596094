import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../../shared/services/user.service';
import { AuthConst } from '../../../shared/services/const/authConst';

@Component({
  selector: 'app-personal-group',
  templateUrl: './personal-group.component.html',
  styleUrls: ['./personal-group.component.scss'],
})
export class PersonalGroupComponent implements OnInit {
  groups: any[] = [];
  customGroupId: number | undefined;
  groupName: string | undefined;
  description: string | undefined;
  status: string | undefined;

  constructor(
    public dialogRef: MatDialogRef<PersonalGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.getUserGroups(this.data.userId).subscribe((groups) => {
      groups.forEach((group: { groupId: number, isDefault: any, status: any }) => {
        const currentGroup = Number(localStorage.getItem(AuthConst.gruopId));
        if (group.groupId === currentGroup) {
          if (group.isDefault == 1) { // if group is default, show only active
            if (group.status == 1) {
              this.groups.push(group);
            }
          } else {
            this.groups.push(group);
          }

        }
      });
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  checked(group: any): void {
    if (group.isDefault == 0) {
      if (group.status === 1) {
        group.status = 0;
        this.status = group.status;
      } else if (group.status === 0) {
        group.status = 1;
        this.status = group.status;
      }
      this.customGroupId = group.id;
      this.groupName = group.name;
      this.description = group.description;
    }
  }

  save(): void {
    const save = {
      id: this.data.userId,
      groupId: this.customGroupId,
      name: this.groupName,
      description: this.description,
      status: this.status,
    };

    this.userService.updateGroup(save).subscribe(() => {
      this.closeModal();
    });
  }
}
