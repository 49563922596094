export class AuthConst {
  static readonly token: string = 'token';
  static readonly fbToken: string = 'fbToken';
  static readonly userId: string = 'userId';
  static readonly gruopId: string = 'gruopId';
  static readonly messageChat: string = 'messageChat';
  static readonly currentDate: string = 'currentDate';
  static readonly userRole: string = 'userRole';
  static readonly groupName: string = 'groupName';
  static readonly scheduleDate: string = 'scheduleDate';
  static readonly emailForNew: string = 'emailForNew';
  static readonly userForNew: any = 'userForNew';
  static readonly changeWorkSiteDate: any = 'changeWorkSiteDate';
}
