import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { CancelReasonsModel } from '../models/cancel-reasons.model';
import { Filter } from '../models/filter.model';
import { AnnouncementModel } from '../models/announcement.model';
import {group} from "@angular/animations";

export interface CancelReasonShift {
  assignmentId: number;
  cancelReasonId: number;
  employerNote?: string;
  disclosedNote?: string;
}

@Injectable({ providedIn: 'root' })
export class ShiftsService {
  constructor(private http: HttpClient) {}

  apiUrl: string = environment.apiURL;

  /**
   * Gel all cancel reasons
   */
  getActiveCancelReasons(): Observable<CancelReasonsModel[]> {
    return this.http
      .get<CancelReasonsModel[]>(`${this.apiUrl}/cancel-reasons/active`)
      .pipe(
        map((reasons: CancelReasonsModel[]) => reasons),
        shareReplay(1)
      );
  }

  /**
   * cancel chosen sssignment
   */
  cancelAssignment(
    cancellation: CancelReasonShift
  ): Observable<CancelReasonsModel> {
    return this.http
      .post<CancelReasonsModel>(
        `${this.apiUrl}/assignment/cancel`,
        cancellation
      )
      .pipe(
        map((reasons) => reasons),
        shareReplay(1)
      );
  }

  /**
   * delete shift
   */
  deleteAssignment(assignmentId: number): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/assignment/${assignmentId}/delete`)
      .pipe(shareReplay(1));
  }

  /**
   * delete Assignment type
   */
  deleteAssignmentType(assignmentId: number): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/assignment_type/${assignmentId}/delete`)
      .pipe(shareReplay(1));
  }

  /**
   * Assignment update notes
   */
  updateNotes(changes: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrl}/assignment/update/notes`, changes)
      .pipe(
        tap((assignment) => assignment),
        shareReplay(1)
      );
  }

  /**
   * Get Announcement
   */
  getAnnouncements(groupId: number): Observable<AnnouncementModel[]> {
    return this.http
      .get<AnnouncementModel[]>(
        `${this.apiUrl}/announcement/group/${groupId}`
      )
      .pipe(
        map((announcement) => announcement),
        shareReplay(1)
      );
  }

  /**
   * Create Announcement
   */
  createAnnouncements(create: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrl}/announcement/create`, create)
      .pipe(
        map((newAnnouncement) => newAnnouncement),
        shareReplay(1)
      );
  }

  /**
   * update Announcement
   */
  updateAnnouncements(update: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrl}/announcement/update`, update)
      .pipe(
        map((newAnnouncement) => newAnnouncement),
        shareReplay(1)
      );
  }

  /**
   * Delete Announcement
   */
  deleteAnnouncements(id: number): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/announcement/delete/${id}`)
      .pipe(shareReplay(1));
  }

  // tslint:disable-next-line:typedef
  staffingGoals(position: any, firstDate: any, secoundDate: any) {
    return this.http.get(
      `${this.apiUrl}/api/v2/employer/staffing-goals/position/` +
        position +
        `/from/` +
        firstDate +
        `/to/` +
        secoundDate
    ).pipe(shareReplay(1));
  }

  /**
   * Dashboard data
   */

  // tslint:disable-next-line:typedef
  getDashboard(filter: Filter) {
    return this.http.post(`${this.apiUrl}` + '/dashboard', filter).pipe(shareReplay(1));
  }

  allAssignmentTypes(groupId: number): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/assignment_types/group/${groupId}/get`)
      .pipe(shareReplay(1));
  }

  getLists(groupId: any): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/manager/list/group/${groupId}`)
      .pipe(shareReplay(1));
  }

  createAssignmentType(shiftType: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrl}/assignment_type/create`, shiftType)
      .pipe(shareReplay(1));
  }

  sendInviteOrRequest(data: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrl}/assignment/invite`, data)
      .pipe(shareReplay(1));
  }

  assignAssignment(data: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrl}/assignment/` +  data.assignmentId +  `/assign`, data)
      .pipe(shareReplay(1));
  }

  updateAssignmentType(shiftType: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrl}/assignment_type/update`, shiftType)
      .pipe(shareReplay(1));
  }

  // tslint:disable-next-line:typedef
  getAllGroup(groupId: any) {
    return this.http.get(
      `${this.apiUrl}` + '/custom-groups/group/' + groupId + '/get',
      groupId
    ).pipe(shareReplay(1));
  }


  getCustomGroupsUsers(groupId: any): any {
    return this.http.get(
      `${this.apiUrl}` + '/api/v1/custom_group/' + groupId + '/users/get',
      groupId
    ).pipe(shareReplay(1));
  }


  addToGroup(object: any): any{
    return this.http.get(`${this.apiUrl}` + '/custom-group/user/' + object.userId + '/group/' + object.groupId + '/status/1/changeStatus' , object).pipe(shareReplay(1));
  }

  removeFromGroup(object: any): any{
    return this.http.get(`${this.apiUrl}` + '/custom-group/user/' + object.userId + '/group/' + object.groupId + '/status/0/changeStatus' , object).pipe(shareReplay(1));
  }

  activeStatus(object: any): any{
    return this.http.get(`${this.apiUrl}` + '/user/' + object.id + '/group/' + object.groupId + '/status/1/changeStatus').pipe(shareReplay(1));
  }

  inActiveStatus(object: any): any{
    return this.http.get(`${this.apiUrl}` + '/user/' + object.id + '/group/' + object.groupId + '/status/0/changeStatus');
  }

  deleteGroup(groupId: any): any {
    return this.http.get(
      `${this.apiUrl}` + '/custom-group/' + groupId.id + '/delete'
    ).pipe(shareReplay(1));
  }

  deletePersonel(personelId: any, groupId: any): any{
    return this.http.get(`${this.apiUrl}` + '/user/' + personelId + '/group/' + groupId + '/removeAuthority').pipe(shareReplay(1));
  }
  perosnalSendMessage(object: any): any{
    return this.http.post(`${this.apiUrl}` + '/api/v1/user/mail/send', object).pipe(shareReplay(1));
  }
  createGroup(value: any): any{
    return this.http.post(`${this.apiUrl}` + '/custom-group/create', value).pipe(shareReplay(1));
  }

  editAssignment(value: any): any{
    return this.http.post(`${this.apiUrl}` + '/assignment/update', value).pipe(shareReplay(1));
  }

  updateGroup(value: any): any{
    return this.http.post(`${this.apiUrl}` + '/custom-group/update', value).pipe(shareReplay(1));
  }

  groupPeronals(value: any , localGroupId: any): any{
    return this.http.get(`${this.apiUrl}` + '/custom-group/' + value.id + '/users', value).pipe(shareReplay(1));
  }

  /**
   * Schedule
   */

  createNewShift(shift: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrl}/assignment/create`, shift)
      .pipe(shareReplay(1));
  }

  getApplications(shiftId: number): Observable<any[]> {
    return this.http
      .get<any[]>(`${this.apiUrl}/assignment/` + shiftId + `/applications`)
      .pipe(
        shareReplay(1)
      );
  }

  shiftColor(dateOne: any , dateTwo: any): any{
    return this.http.get(`${this.apiUrl}` + '/assignments/start/' + dateOne + '/end/' + dateTwo).pipe(shareReplay(1));
  }

  getMonthData(dateOne: any , dateTwo: any, groupId: any): any{
    return this.http.get(`${this.apiUrl}` + '/month/assignments/start/' + dateOne + '/end/' + dateTwo + '/group/' + groupId ).pipe(shareReplay(1));
  }






  getAssignmentById(shiftId: number): Observable<any[]> {
    return this.http
      .get<any[]>(`${this.apiUrl}/assignment/get/` + shiftId )
      .pipe(
        shareReplay()
      );
  }

  getInvitations(shiftId: number): Observable<any[]> {
    return this.http
      .get<any[]>(`${this.apiUrl}/assignment/` + shiftId + `/invitations`)
      .pipe(
        shareReplay()
      );
  }

  getInvitationsForShift(shiftId: number): any {
    return this.http
      .get<any[]>(`${this.apiUrl}/assignment/` + shiftId + `/invitations`);
  }

  confirmApplication(application: any): Observable<any> {
    return this.http
      .get<any[]>(`${this.apiUrl}/application/`  + application.id + `/confirm`)
      .pipe(
        shareReplay()
      );
  }

  declineApplication(application: any): Observable<any> {
    return this.http
      .get<any[]>(`${this.apiUrl}/application/` + application.id + `/decline`)
      .pipe(
        shareReplay()
      );
  }
}
