import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import set = Reflect.set;

@Injectable({ providedIn: 'root' })
export class ErrorMessagesService {
  public errSubject = new Subject<string>();
  errors$: Observable<string> = this.errSubject.asObservable();

  constructor() {}

  showErrors(error: string): void {
    // setTimeout(() => {
    this.errSubject.next(error);
    // }, 500);
  }
}
