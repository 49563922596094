<ng-template [ngIf]="goToEdit">
  <section class="new-shift-details" [ngClass]="{'canceled-section': assignment.status == 'CANCELED'}">
    <div class="new-shift-details__title">{{'shiftDetails' | translate}}</div>
    <div class="new-shift-details__info" *ngIf="assignment.type != 2">
      <div class="new-shift-details__info-title">{{'shiftType' | translate}}</div>
      <div class="new-shift-details__info-subtitle">{{'selectShift' | translate}}</div>
    </div>
    <div class="more-assignments" *ngIf="otherAssignments.length > 1" (click)="openNextShift()">
      {{'shift' | translate | titlecase}} {{findMyShiftIndex()}} / {{otherAssignments.length}}
    </div>
    <div class="for-more-data" *ngIf="assignment.type == 2">
      <div class="for-image" *ngIf="!assignment.assignedToProfilePicture">
      </div>
      <img class="for-image real-image" *ngIf="assignment.assignedToProfilePicture"
           [src]="assignment.assignedToProfilePicture" alt="">
      <div class="for-text">
        <div class="assigned-to-text" *ngIf="!assignment.assignedToName">
          {{'notAssigned' | translate}}
        </div>
        <div class="assigned-to-text real-name" *ngIf="assignment.assignedToName">
          {{assignment.assignedToName}}
        </div>
        <span *ngIf="!assignment.assignedToName">
        <div class="application-text" *ngIf="assignment.applicationsCount && assignment.applicationsCount > 0">
          {{'pendingApplications' | translate}} ({{assignment.applicationsCount}})
        </div>
             <div class="application-text inactive-text"
                  *ngIf="(!assignment.applicationsCount || assignment.applicationsCount == 0) && assignment.invitationCount > 0">
          {{'pendingInvitations' | translate}}

        </div>
        <div class="application-text inactive-text"
             *ngIf="(!assignment.applicationsCount || assignment.applicationsCount == 0) && assignment.invitationCount == 0">
          {{'noApplications' | translate}}
        </div>
          </span>
        <div class="application-text canceled-text" *ngIf="assignment.status == 'CANCELED'">
          {{'cancelled' | translate}}
        </div>
      </div>
    </div>
    <div class="new-shift-details__select-box" *ngIf="assignment.type != 2">
      <div class="new-shift-details__select">
        <div class="new-shift-details__shift-info">
          <div *ngIf="shiftType?.color" class="new-shift-details__color-box">
            <div class="new-shift-details__shift-color" *ngIf="!shiftType.clientUpdate"
                 [style.backgroundColor]="getPositionColor(assignment)">
            </div>

          </div>
          <div class="new-shift-details__shift-text">{{shiftType?.name}}</div>
        </div>
      </div>
    </div>
    <div class="shift-links" [ngClass]="{'canceled-shift': assignment.status == 'CANCELED'}">
      <div class="shift-links__shift-link">
        <div class="shift-links__attribute">{{'time' | translate}}</div>
        <div *ngIf='!shiftType' class="shift-links__attribute-data">{{assignment.start}} - {{assignment.end}}</div>
        <div *ngIf='shiftType && !shiftType.startFake && !shiftType.endFake'
             class="shift-links__attribute-data">{{shiftType.start}} - {{shiftType.end}}</div>
        <div *ngIf='shiftType && shiftType.startFake && shiftType.endFake'
             class="shift-links__attribute-data">{{shiftType.startFake}} - {{shiftType.endFake}}</div>

      </div>
      <div class="shift-links__shift-link">
        <div class="shift-links__attribute">{{'position' | translate}}</div>
        <div class="shift-links__attribute-data">{{setPosition}}</div>
      </div>
      <div class="shift-links__shift-link">
        <div class="shift-links__attribute">{{'activity' | translate}}</div>
        <div class="shift-links__attribute-data">{{setActivity}}</div>
      </div>

      <div class="shift-links__shift-link" *ngIf="setCompetence">
        <div class="shift-links__attribute">{{'competence' | translate}}</div>
        <div class="shift-links__attribute-data">{{setCompetence}}</div>
      </div>

      <div class="shift-links__shift-link" *ngIf="undisclosedNote">
        <div class="shift-links__attribute">{{'undisclosedNote' | translate}}</div>
        <div class="shift-links__attribute-data">{{undisclosedNote}}</div>
      </div>

      <div class="shift-links__shift-link" *ngIf="disclosedNote && role != 'COORDINATOR'">
        <div class="shift-links__attribute">{{'disclosedNote' | translate}}</div>
        <div class="shift-links__attribute-data">{{disclosedNote}}</div>
      </div>

      <div class="shift-links__shift-link" *ngIf="secondActivity">
        <div class="shift-links__attribute">{{'secondActivity' | translate}}</div>
        <div *ngIf='!shiftType' class="shift-links__attribute-data"> {{secondActivity}} {{assignment.start2}}
          - {{assignment.end2}}</div>
        <div *ngIf='shiftType' class="shift-links__attribute-data">
          <div>{{secondActivity}},&nbsp;</div>
          <span
            *ngIf='shiftType.start2'>
            {{shiftType.start2}} - {{shiftType.end2}}
          </span>
          <span
            *ngIf='shiftType.activity2Start'>
            {{transferToHours(shiftType.activity2Start)}} - {{transferToHours(shiftType.activity2End)}}
          </span>
        </div>
      </div>

    </div>
    <div class="images-box" *ngIf="assignment.status != 'CANCELED'">
<!--      <div class="images-box__container images-box__container&#45;&#45;margin">-->
<!--        <figure class="images-box__image-box">-->
<!--          <img src="assets/images/shift-type/Shift%20type_Day.svg" alt=""/>-->
<!--        </figure>-->
<!--        <div class="images-box__text-box">-->
<!--          <div class="images-box__text">{{'day' | translate}}</div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="images-box__container" *ngIf="getClinicalShift(shiftType)">
        <figure class="images-box__image-box">
          <img src="assets/images/shift-type/Shift%20type_Clinical.svg" alt=""/>
        </figure>
        <div class="images-box__text-box">
          <div class="images-box__text">{{'clinical' | translate}}</div>
        </div>
      </div>
    </div>
<!--    <div class="new-shift-details__info" *ngIf="assignment.status == 'PUBLISHED'">-->
<!--      <div class="new-shift-details__info-title">{{'publish' | translate}}</div>-->
<!--      <div class="new-shift-details__info-subtitle">{{'publishToggle' | translate}}</div>-->
<!--    </div>-->
<!--    <div class="options__toggle-button" *ngIf="assignment.status == 'PUBLISHED'">-->
<!--      <div class="options__toggle-field" [class.changeBackground]="publish">-->
<!--        <div class="options__toggle-circle" [class.changePosition]="publish">-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <span *ngIf="assignment.status != 'CANCELED'">
    <div class="shift-button" *ngIf="this.custom !== 'realShift'">
      <button style="width:100px" (click)="editShift()" class="button button--primary">{{'edit' | translate}}</button>
    </div>
    <div class="shift-button" *ngIf="this.custom === 'realShift'">
      <button style="width:100px" (click)="openEditModal()"
              class="button button--primary">{{'edit' | translate}}</button>
    </div>
      </span>
    <div *ngIf="assignment.status == 'CANCELED'" class="cancelled-buttons">
      <button class="button button--red" style="margin-right: 10px; width: 100px"
              (click)="deleteShift(assignment)">{{'delete' | translate}}
      </button>
      <button class="button button--primary" style="width: 100px" (click)="recoveryShift(assignment)"
      >{{'recoverShift' | translate}}</button>
    </div>
  </section>
</ng-template>
<ng-template [ngIf]="!goToEdit">
  <app-create-custom-shift [edit]="edit" [assignment]="assignment" [assignment]="shiftType" [custom]="custom"
                           (close)="getBack()"></app-create-custom-shift>
</ng-template>
