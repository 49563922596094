<ng-template [ngIf]="mode === 0">
  <section class="shift-type">
    <div class="shift-type__title">{{'ShiftTypes' | translate}}</div>
    <div class="shift-type__container">
      <div class="shift-type__box" *ngFor="let assignment of assignments; let i = index">
        <div class="shift-type__select-box">
          <div class="shift-type__select">
            <div class="shift-type__shift-info">
              <div *ngIf="assignment?.color" class="shift-type__color-box">
                <div class="shift-type__shift-color"
                     [style.backgroundColor]="getPositionColor(assignment)">
                </div>
              </div>
              <div class="shift-type__shift-text">{{assignment.name}}</div>
            </div>
          </div>
        </div>
        <div class="shift-type__image-box">
          <figure class="shift-type__figure">
            <img class="shift-type__image" (click)="getAssignment(assignment)" src="../../../assets/images/Pen.svg"/>
          </figure>
          <figure class="shift-type__figure">
            <img class="shift-type__image shift-type__image--width" (click)="openDeleteModal(assignment)"
                 src="../../../assets/images/Trash_Outline.svg"/>
          </figure>
        </div>
      </div>
    </div>
    <div class="shift-type__button-box">
      <button class="button button--primary shift-type__button"
              (click)="closeModal()">{{'cancelButton' | translate}}</button>
    </div>
  </section>
</ng-template>
<ng-template [ngIf]="mode === 1">
  <app-create-custom-shift [assignment]="assignment" [edit]="edit" (close)="getBack()"></app-create-custom-shift>
</ng-template>
