import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {EmployeesService} from '../../shared/services/employees.service';
import {HelperService} from '../../shared/services/helper.service';
import {Subscription} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';
import {ShiftDeteilsComponent} from '../modals/shift-deteils/shift-deteils.component';
import {MatDialog} from '@angular/material/dialog';
import {TooltipShiftsModalsComponent} from '../modals/tooltip-shifts-modals/tooltip-shifts-modals.component';
import {TranslateService} from '@ngx-translate/core';
import {ShiftsService} from '../../shared/services/shifts.service';

@Component({
  selector: 'app-create-new-shift-details',
  templateUrl: './create-new-shift-details.component.html',
  styleUrls: ['./create-new-shift-details.component.scss'],
})
export class CreateNewShiftDetailsComponent implements OnInit, OnDestroy {
  goToEdit = true;
  edit = 'edit';
  clientUpdate = true;
  shiftType: any;
  publish = true;
  shiftForUpdate: any;

  positions: any = [];
  competences: any = [];
  activities: any = [];

  setPosition: string | undefined;
  setCompetence: string | undefined;
  setActivity: string | undefined;
  undisclosedNote: string | undefined;
  disclosedNote: string | undefined;
  secondActivity: string | undefined;
  custom: string | undefined;
  new: string | undefined;
  otherAssignments: any[] = [];

  @Input() assignment: any;
  @Input() customTwo: string;

  updateShiftSubscription: Subscription;
  newShiftSubscription: Subscription;
  customShiftSubscription: Subscription;
  shiftCalendarSubscription: Subscription;
  role = "";

  constructor(
    private employeesService: EmployeesService,
    private helperService: HelperService,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private translate: TranslateService,
    private shiftService: ShiftsService
  ) {
  }

  ngOnInit(): void {
    this.role = localStorage.getItem('gapUserRole');
    this.helperService.hasMoreShifts.subscribe(
      (data: any) => {
        if (data) {
          // this.otherAssignments = data.sort((a: any, b: any) => {
          //   return b.status.localeCompare(a.status) ? new Date(a.start).getTime() > new Date(b.start).getTime() ? -1 : -2 : 2;
          //
          // });
          this.otherAssignments = data;
          this.assignment = this.otherAssignments[0];
          this.shiftType = this.otherAssignments[0];
          this.undisclosedNote = this.assignment.employerNote;
          this.disclosedNote = this.assignment.disclosedNote;
          this.getUserPosition();
          this.getUserActivity();
          this.getUserCompetence();
          // if (this.otherAssignments.length > 1) {
          //   const index = this.otherAssignments.findIndex((x: any) => x.id == this.assignment.id);
          //   this.otherAssignments.splice(index, 1);
          //   this.otherAssignments.unshift(this.assignment);
          // }
        }
      }
    );
    this.undisclosedNote = this.assignment.undisclosedNote;
    this.disclosedNote = this.assignment.disclosedNote;
    this.shiftCalendarSubscription = this.helperService.shiftCalendarShift$.subscribe(
      (shift) => {
        this.fetchSubject();
        setTimeout(() => {
          this.assignment.applicationsCount = shift.nrOfAppliedApplications;
          this.shiftService.getInvitationsForShift(shift.id).subscribe(
            async (invitations: any[]) => {
              this.assignment.invitationCount = invitations.length;
            }
          );
          this.shiftType = shift;
        }, 300);
      }
    );

    if (this.customTwo) {
      this.custom = this.customTwo;
    }
    this.helperService.showCalendar$.next(true);
    if (this.assignment) {
      this.shiftType = this.assignment;
      this.edit = 'edit';
    }


    this.helperService.userOpenShift.subscribe(
      (data: any) => {
        if (data) {
          this.shiftType = data;
          this.assignment = data;
          this.undisclosedNote = this.assignment.employerNote;
          this.disclosedNote = this.assignment.disclosedNote;
          this.getUserPosition();
          this.getUserActivity();
          this.getUserCompetence();


        }
      }
    );

    this.newShiftSubscription = this.helperService.newShiftsType$.subscribe(
      (createdNewShift) => {
        const newShift = {
          activity2Id:
            createdNewShift.activity2Id === 0
              ? null
              : createdNewShift.activity2Id,
          activityId: createdNewShift.activityId,
          color: createdNewShift.color,
          competenceId: createdNewShift.competenceId,
          disclosedNote: createdNewShift.disclosedNote,
          end: createdNewShift.end,
          end2: createdNewShift.end2,
          groupId: createdNewShift.groupId,
          id: createdNewShift.id,
          name: createdNewShift.name,
          positionId: createdNewShift.positionId,
          start: createdNewShift.start,
          start2: createdNewShift.start2,
          undisclosedNote: createdNewShift.undisclosedNote,
        };

        this.edit = 'edit';
        this.custom = undefined;
        this.shiftType = newShift;
        this.goToEdit = true;
        this.fetchSubject();
      }
    );

    this.updateShiftSubscription = this.helperService.updateShiftsType$.subscribe(
      (updateShift) => {
        this.positions.forEach((position: any) => {
          if (position.id === updateShift.positionId) {
            updateShift.positionColor = position.color;
            updateShift.color = position.color;
          }
        });
        if (this.custom === 'realShift') {
          this.shiftType = {
            id: updateShift.id,
            name: updateShift.name,
            groupId: updateShift.groupId,
            start: this.datePipe.transform(updateShift.start, 'HH:mm'),
            end: this.datePipe.transform(updateShift.end, 'HH:mm'),
            positionId: updateShift.position,
            activityId: updateShift.activity1Id,
            competenceId: updateShift.competence1Id,
            undisclosedNote: updateShift.undisclosedNote,
            disclosedNote: updateShift.disclosedNote,
            activity2Id: updateShift.activity2Id,
            start2: updateShift.activity2Start
              ? this.datePipe
                .transform(updateShift.activity2Start, 'HH:mm')
                .toString()
              : '',
            end2: updateShift.activity2End
              ? this.datePipe
                .transform(updateShift.activity2End, 'HH:mm')
                .toString()
              : '',
            color: updateShift.positionColor,
            clinical: updateShift.clinical,
            applicationsCount: updateShift.applications,
            type: 2,
            status: updateShift.status,
            date: this.datePipe.transform(updateShift.start, 'yyyy-MM-dd'),
          };
        } else {
          this.shiftType = updateShift;
        }
        this.goToEdit = true;
        this.fetchSubject();
      }
    );

    this.customShiftSubscription = this.helperService.customShiftsType$.subscribe(
      (customShift) => {
        this.shiftType = customShift;
        this.goToEdit = true;
        if (this.shiftType.name === 'Nytt pass') {
          this.edit = 'edit';
          this.custom = undefined;
        }
        this.custom = undefined;
        this.edit = 'edit';
        this.fetchSubject();
      }
    );

    this.getUserPosition();
    this.getUserActivity();
    this.getUserCompetence();

    if (this.assignment) {
      if (this.assignment.employerNote) {
        this.undisclosedNote = this.assignment.employerNote;
      }
      if (this.assignment.undisclosedNote) {
        this.undisclosedNote = this.assignment.undisclosedNote;
      }
      this.disclosedNote = this.assignment.disclosedNote;
    }
    this.assignment.applicationsCount = this.assignment.nrOfAppliedApplications;
    if (this.assignment.id) {
      this.shiftService.getInvitationsForShift(this.assignment.id).subscribe(
        async (invitations: any[]) => {
          this.assignment.invitationCount = invitations.length;
        }
      );
    }
  }

  openNextShift(): void {
    const index = this.otherAssignments.findIndex((x: any) => x.id == this.assignment.id);

    if (index < this.otherAssignments.length - 1) {
      this.assignment = this.otherAssignments[index + 1];
    } else {

      this.assignment = this.otherAssignments[0];
    }
    this.undisclosedNote = this.assignment.employerNote;
    this.disclosedNote = this.assignment.disclosedNote;
    this.getUserPosition();
    this.getUserPosition();
    this.getUserActivity();
    this.getUserCompetence();

    this.shiftType.startFake = this.datePipe.transform(this.assignment.start, 'HH:mm');
    this.shiftType.endFake = this.datePipe.transform(this.assignment.end, 'HH:mm');
  }

  findMyShiftIndex(): number {
    const index = this.otherAssignments.findIndex((x: any) => x.id == this.assignment.id);
    return index + 1;
  }

  /**
   * User position
   */
  getUserPosition(): void {
    if (this.positions.length === 0) {
      this.employeesService.userPositions().subscribe((x) => {
        x.forEach((group: any) => {
          if (
            group.groupId === this.assignment.groupId &&
            group.groupStatus === 'ACTIVE'
          ) {
            this.positions.push(group);
            this.positions.forEach((position: any) => {
              if (position.id === this.assignment?.positionId) {
                this.setPosition = position.title;
              }
            });
          }
        });
      });
    } else {
      this.positions.forEach((position: any) => {
        if (position.id === this.assignment?.positionId) {
          this.setPosition = position.title;
        }
      });

    }
  }

  /**
   * User activity
   */
  getUserActivity(): void {
    if (this.activities.length === 0) {
      this.employeesService.userActvity().subscribe((x) => {
        x.forEach((group: any) => {
          if (
            group.groupId === this.assignment.groupId &&
            group.groupStatus === 'ACTIVE'
          ) {
            this.activities.push(group);
            this.activities.forEach((activity: any) => {
              if (activity.id === this.assignment?.activityId) {
                if (activity.shortTitle) {
                  this.setActivity =
                    activity.title + ' (' + activity.shortTitle + ')';
                } else {
                  this.setActivity = activity.title;
                }
              }
              if (activity.id === this.assignment?.activity2Id) {
                if (activity.shortTitle) {
                  this.secondActivity =
                    activity.title + ' (' + activity.shortTitle + ')';
                } else {
                  this.secondActivity = activity.title;
                }
              }
            });
          }
        });
      });
    } else {
      this.activities.forEach((activity: any) => {
        if (activity.id === this.assignment?.activityId) {
          if (activity.shortTitle) {
            this.setActivity =
              activity.title + ' (' + activity.shortTitle + ')';
          } else {
            this.setActivity = activity.title;
          }
        }
        if (activity.id === this.assignment?.activity2Id) {
          if (activity.shortTitle) {
            this.secondActivity =
              activity.title + ' (' + activity.shortTitle + ')';
          } else {
            this.secondActivity = activity.title;
          }
        }
      });
    }
    if (!this.assignment?.activity2Id || this.assignment?.activity2Id == '') {
      this.secondActivity = '';
    }
  }

  /**
   * User competence
   */
  getUserCompetence(): void {
    if (this.competences.length === 0) {
      this.employeesService.userCompetences().subscribe((x) => {
        x.forEach((group: any) => {
          if (
            group.groupId === this.assignment.groupId &&
            group.groupStatus === 'ACTIVE'
          ) {
            this.competences.push(group);
            this.competences.forEach((competence: any) => {
              if (competence.id === this.assignment?.competenceId) {
                this.setCompetence = competence.title;
              }
            });
          }
        });
      });
    } else {
      this.competences.forEach((competence: any) => {
        if (competence.id === this.assignment?.competenceId) {
          this.setCompetence = competence.title;
        }
      });
    }
  }

  fetchSubject(): void {
    setTimeout(() => {
      this.employeesService.userPositions().subscribe((posiions) => {
        posiions.forEach((group: any) => {
          if (
            group.groupId === this.shiftType.groupId &&
            group.groupStatus === 'ACTIVE'
          ) {
            this.positions.push(group);
            this.positions.forEach((position: any) => {
              if (position.id === this.shiftType?.positionId) {
                this.setPosition = position.title;
              }
            });
          }
        });
      });
      this.employeesService.userActvity().subscribe((activities) => {
        activities.forEach((group: any) => {
          if (
            group.groupId === this.shiftType.groupId &&
            group.groupStatus === 'ACTIVE'
          ) {
            this.activities.push(group);
            this.activities.forEach((activity: any) => {
              if (activity.id === this.shiftType?.activityId) {
                if (activity.shortTitle) {
                  this.setActivity =
                    activity.title + ' (' + activity.shortTitle + ')';
                } else {
                  this.setActivity = activity.title;
                }
              }
              if (activity.id === this.shiftType?.activity2Id) {
                if (activity.shortTitle) {
                  this.secondActivity =
                    activity.title + ' (' + activity.shortTitle + ')';
                } else {
                  this.secondActivity = activity.title;
                }
              }
            });
          }
        });
      });
      this.employeesService.userCompetences().subscribe((competences) => {
        competences.forEach((group: any) => {
          if (
            group.groupId === this.shiftType.groupId &&
            group.groupStatus === 'ACTIVE'
          ) {
            this.competences.push(group);
            this.competences.forEach((competence: any) => {
              if (competence.id === this.shiftType?.competenceId) {
                this.setCompetence = competence.title;
              }
            });
          }
        });
      });

      this.undisclosedNote = this.assignment.employerNote;
      this.disclosedNote = this.assignment.disclosedNote;
    }, 300);
  }

  editShift(): void {
    this.goToEdit = false;
  }

  openEditModal(): void {
    const shift = JSON.parse(JSON.stringify(this.assignment));


    const onlyDate = this.datePipe.transform(new Date(shift.date), 'yyyy-MM-dd');

    if (!shift.start.includes(onlyDate)) {
      shift.start = shift.date + ' ' + shift.start;
      shift.end = shift.date + ' ' + shift.end;
      if (shift.start2 && shift.start2 != undefined && shift.start2 != '') {
        shift.start2 = shift.date + ' ' + shift.start2;
      }

      if (shift.start2 && shift.end2 != undefined && shift.end2 != '') {
        shift.end2 = shift.date + ' ' + shift.end2;
      }
    }

    shift.position = shift.positionId;
    shift.employerNote = this.disclosedNote;
    shift.description = this.undisclosedNote;

    if(shift.status === "PUBLISHED") {
      shift.dateChangeable = false;
    }

    console.log(shift);

    const confirmDialog = this.dialog.open(ShiftDeteilsComponent, {
      disableClose: true,
      width: '560px',
      height: '638px',
      panelClass: 'defaultDialog',
      position: {
        top: '100px',
      },
      data: {
        person: shift,
        showIcons: false,
        refresh: true
      }
    });
    confirmDialog.afterClosed().subscribe((updateShift: any) => {
      if (updateShift) {
        // this.assignment = updateShift;
        this.assignment = updateShift;
        this.assignment.id = updateShift.id;
        this.assignment.date = this.datePipe.transform(updateShift.start, 'yyyy-MM-dd');

        this.assignment.name = updateShift.name;
        this.assignment.groupId = updateShift.groupId;
        // this.assignment.start = this.datePipe.transform(updateShift.start, 'HH:mm');
        // this.assignment.end = this.datePipe.transform(updateShift.end, 'HH:mm');
        this.shiftType.startFake = this.datePipe.transform(updateShift.start, 'HH:mm');
        this.shiftType.endFake = this.datePipe.transform(updateShift.end, 'HH:mm');
        this.assignment.positionId = updateShift.position;
        this.assignment.activityId = updateShift.activity1Id;
        this.assignment.competenceId = updateShift.competence1Id;
        this.assignment.undisclosedNote = updateShift.undisclosedNote;
        this.assignment.disclosedNote = updateShift.disclosedNote;
        this.assignment.activity2Id = updateShift.activity2Id;
        this.assignment.start2 = updateShift.activity2Start
          ? this.datePipe
            .transform(updateShift.activity2Start, 'HH:mm')
            .toString()
          : '';
        this.assignment.end2 = updateShift.activity2End
          ? this.datePipe
            .transform(updateShift.activity2End, 'HH:mm')
            .toString()
          : '';
        this.shiftType.start2 = this.assignment.start2;
        this.shiftType.end2 = this.assignment.end2;
        this.assignment.color = updateShift.positionColor;
        this.assignment.clinical = updateShift.clinical;
        this.assignment.applicationsCount = updateShift.applications;
        this.assignment.assignedToId = updateShift.assignedToId;
        this.assignment.assignedToProfilePicture = updateShift.assignedToProfilePicture;
        this.assignment.assignedToName = updateShift.assignedToName;
        this.assignment.type = 2;
        this.assignment.status = updateShift.status;
        this.activities.forEach((activity: any) => {
          if (activity.id === this.assignment?.activityId) {
            if (activity.shortTitle) {
              this.setActivity =
                activity.title + ' (' + activity.shortTitle + ')';
            } else {
              this.setActivity = activity.title;
            }
          }
          if (activity.id === this.assignment?.activity2Id) {
            if (activity.shortTitle) {
              this.secondActivity =
                activity.title + ' (' + activity.shortTitle + ')';
            } else {
              this.secondActivity = activity.title;
            }
          }
        });
        this.competences.forEach((competence: any) => {
          if (competence.id === this.assignment?.competenceId) {
            this.setCompetence = competence.title;
          }
        });
        this.positions.forEach((position: any) => {
          if (position.id === this.assignment?.positionId) {
            this.setPosition = position.title;
          }
        });
      }
    });
  }

  getBack(): void {
    this.goToEdit = true;
    const message = 'clear-calendar';
    this.helperService.dateToDefault$.next(message);
  }

  getClinicalShift(shift: any): boolean {
    // let clinical = '';
    // console.log(shift);
    // this.activities.forEach((activity: any) => {
    //   console.log(activity.id, '-' , shift.activityId);
    //   if (activity.id === shift.activityId) {
    //     if (activity.clinical) {
    //       clinical = activity.clinical;
    //     }
    //   }
    // });
    // return !!clinical;

    return shift.clinical;
  }

  getPositionColor(color: any): any {
    let colorOfShift: string;

    colorOfShift = color.color;

    if (colorOfShift?.startsWith('##')) {
      return colorOfShift.substring(1);
    }
    if (colorOfShift?.startsWith('#')) {
      return colorOfShift;
    } else {
      return '#' + colorOfShift;
    }
  }

  transferToHours(dateTime: any) {
    return this.datePipe.transform(dateTime, 'HH:mm');
  }

  deleteShift(shift: any): void {
    const confirmDialog = this.dialog.open(TooltipShiftsModalsComponent, {
      disableClose: true,
      width: '290px',
      height: '190px',
      panelClass: 'defaultDialog',
      position: {
        top: '150px',
      },
      data: {
        person: shift,
        title: this.translate.instant('deleteBookedShift'),
        subtitle: this.translate.instant('attention'),
        message: this.translate.instant('deleteShiftsText'),
        mode: 'delete',
      },
    });
    confirmDialog.afterClosed().subscribe(() => {
      this.helperService.refreshUserCalendar.next();
      this.helperService.removeAssignment.next('OK');
    });
  }

  recoveryShift(shift: any): void {
    let selectedPosition: null;
    const positionIndex = this.positions.findIndex(
      (p: any) => p.title == shift.position
    );

    if (positionIndex !== -1) {
      selectedPosition = this.positions[positionIndex].id;
    } else {
      selectedPosition = null;
    }


    const selectedActivity1 = shift.activity1Id;

    const selectedActivity2 = shift.activity2Id;
    // const activity1Index2 = this.activities.findIndex(
    //   (a: any) => a.title == this.shiftsForm.value.secondActivity
    // );
    // if (activity1Index2 !== -1) {
    //   selectedActivity2 = this.activities[activity1Index2].id;
    // } else {
    //   selectedActivity2 = null;
    // }

    let selectedCompetence: null;
    const competenceIndex = this.competences.findIndex(
      (c: any) => c.title == shift.competence1Id
    );
    if (competenceIndex !== -1) {
      selectedCompetence = this.competences[competenceIndex].id;
    } else {
      selectedCompetence = null;
    }

    const date =
      new Date(shift.start.replace(/-/g, '/')).getUTCFullYear() +
      '-' +
      Number(Number(new Date(shift.start.replace(/-/g, '/')).getMonth()) + 1) +
      '-' +
      new Date(shift.start.replace(/-/g, '/')).getDate();

    const newShift = {
      id: shift.id,
      date,
      start: this.setCorrectFormat(shift.start),
      end: this.setCorrectFormat(shift.end),
      positionId: selectedPosition,
      activity1Id: selectedActivity1,
      competence1Id: selectedCompetence,
      description: shift.disclosedNote,
      employerNote: shift.undisclosedNote,
      status: 3,
      cancelReasonId: shift.cancelReasonId
        ? null
        : shift.cancelReasonId,
      activity2Id: selectedActivity2 ? selectedActivity2 : null,
      activity2Start: shift.start2
        ? this.setCorrectFormat(shift.start2)
        : null,
      activity2End: shift.end2
        ? this.setCorrectFormat(shift.end2)
        : null,
    };

    const confirmDialog = this.dialog.open(TooltipShiftsModalsComponent, {
      disableClose: true,
      width: '290px',
      height: '190px',
      panelClass: 'defaultDialog',
      position: {
        top: '150px',
      },
      data: {
        recovery: newShift,
        title: this.translate.instant('copyShifts'),
        message: this.translate.instant('copyShiftsText'),
        mode: 'recovery',
      },
    });
    confirmDialog.afterClosed().subscribe(() => {
      this.helperService.refreshUserCalendar.next();
      this.helperService.removeAssignment.next('OK');
    });
  }

  // FORMATTING DATE FOR IOS
  setCorrectFormat(time: string): any {
    return this.datePipe.transform(time.replace(/-/g, '/'), 'yyyy-MM-dd HH:mm Z').toString();
  }

  ngOnDestroy(): void {
    this.newShiftSubscription?.unsubscribe();
    this.updateShiftSubscription?.unsubscribe();
    this.customShiftSubscription?.unsubscribe();
    this.shiftCalendarSubscription?.unsubscribe();
  }
}
