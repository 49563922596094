<div class="colorForBackground">
  <div class="padding-for-dialog">
    <img _ngcontent-gfy-c97="" src="../../../../assets/images/Close.png" (click)="cancel()" class="create-user__image locationX">

    <p class="send_message">{{"edit_group" | translate}}</p>
    <p class="add_text">
      {{"group_name" | translate}} <br>
      <span class="text_after">  {{"silver_text" | translate}}</span>
    </p>
    <input name="name_area" id="name_area" cols="30" [(ngModel)]="objectValues.name" value="{{  this.object.objectGroup.name}}" rows="10"  class="name_area">

    <p class="add_text">  <br>
      {{"description" | translate}} <br>
      <span class="text_after">  {{"silver_text" | translate}}</span>
    </p>
    <textarea name="" id="text_area_message" cols="30" [(ngModel)]="objectValues.description" value="{{  this.object.objectGroup.name}}" rows="10"  class="text_area_message"></textarea>

    <div class="message_buttons">
      <button class="message_cancel" (click)="cancel()"> {{"cancel" | translate}}</button>
      <button class="send_messages" (click)="send()"> {{"save" | translate}}</button>
    </div>
  </div>
</div>
