<div class="schedule-main">
  <ng-container class="shedule-containerl" *ngIf="!newShift;else emailExist">
    <section>
      <div class="header">
        <div class="header__date-box">
          <div class="header__left-arrow" (click)="previousDay(currentDate)" *ngIf="mode == 1 ">
          </div>
          <div class="header__left-arrow" (click)="previousDay(currentDate)" *ngIf="mode == 2 ">
          </div>
          <div class="header__date-toggle" *ngIf="staffingGoalsActiveValueMonth == false">
            {{currentDate | convertDate}}
            <div class="header__week" *ngIf="staffingGoalsActiveValueMonth == false">
              {{'week' | translate}} {{week}} , {{year}}
            </div>
          </div>
          <div class="header__date-toggle" *ngIf="staffingGoalsActiveValueMonth == true">
            {{currentDate | convertDateMonths}}
            <div class="header__week" *ngIf="staffingGoalsActiveValueMonth == true">
              {{'week' | translate}} {{weekMonthViewFirst}} - {{weekMonthViewLast}}
            </div>
          </div>

          <div class="header__right-arrow" *ngIf="mode == 1 " (click)="nextDay(currentDate)"></div>

          <div class="header__right-arrow" *ngIf="mode == 2 "
               (click)="nextDay(currentDate)"></div>
          <mat-form-field class="header__date">
            <input matInput [matDatepicker]="dp" [formControl]="date"/>
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
            L
          </mat-form-field>
        </div>
        <div class="header__body">
          <div class="header__inputs">
            <div class="section-select__select-box" (clickOutside)="showPositions = false">
              <input class="section-select__select"
                     [class.disabledInput]="staffingGoalsActiveValueMonth"
                     (click)="showAllPositions()"
                     [(ngModel)]="chosenPosition"
                     readonly
                     placeholder="{{'allPositions' | translate}}"
              />
              <div class="dropdown" *ngIf="showPositions && mode == 1">
                <div class="option" (click)="getPositions({id: -1},  $event, 'all')">
                  <div>
                    <span>{{'allPositions' | translate}}</span>
                  </div>
                  <mat-checkbox class="checkBox-input" [checked]="checkAllPositions"
                                (change)="getPositions({id: -1}, $event, 'all')">
                  </mat-checkbox>
                </div>
                <div class="option" *ngFor="let position of sortPositionTitleSelect()"
                     (click)="getPositions(position, $event, 'check')">
                  <div>
                    <span>{{position.title}}</span>
                  </div>
                  <mat-checkbox [checked]="positionIsChecked(position.id)" class="checkBox-input"
                                (change)="getPositions(position, $event, 'check')">
                  </mat-checkbox>
                </div>
              </div>
            </div>

            <div class="section-select__select-box" (clickOutside)="showShifts = false">
              <input class="section-select__select"
                     [class.disabledInput]="staffingGoalsActiveValueMonth"
                     (click)="showAllShifts()"
                     [(ngModel)]="chosenShift"
                     readonly
                     placeholder="{{'allShifts' | translate}}"
              />
              <div class="dropdown dropdown--shifts" *ngIf="showShifts  && mode == 1"
                   style="max-height: 187px !important;">
                <div class="option" (click)="getShift({id: -1}, $event, 'all')">
                  <div>
                    <span>{{'allShifts' | translate}}</span>
                  </div>
                  <mat-checkbox class="checkBox-input" (change)="getShift({id: -1},$event, 'all')"
                                [checked]="allShiftsChecked">
                  </mat-checkbox>
                </div>
                <div class="option" *ngFor="let shift of sortShiftByName()"
                     (click)="getShift(shift,  $event, 'check')">
                  <div>
                    <span>{{shift.shift}}</span>
                  </div>
                  <mat-checkbox class="checkBox-input" (change)="getShift(shift, $event, 'check')"
                                [checked]="isCheckedShift(shift)">
                  </mat-checkbox>
                </div>
              </div>
            </div>

            <div class="section-select__select-box lists-only" (clickOutside)="showLists = false">
              <input class="section-select__select lists-only"
                     (click)="showAllLists()"
                     [(ngModel)]="chosenList"
                     readonly
                     placeholder="{{'allLists' | translate}}"
              />
              <div class="dropdown dropdown--shifts lists-only" *ngIf="showLists"
                   style="max-height: 187px !important;">
                <div class="option" (click)="getList({id: -1}, $event, 'all')">
                  <div>
                    <span>{{'allLists' | translate}}</span>
                  </div>
                  <mat-checkbox class="checkBox-input" (change)="getList({id: -1},$event, 'all')"
                                [checked]="allListsChecked">
                  </mat-checkbox>
                </div>
                <div class="option" *ngFor="let list of lists"
                     (click)="getList(list,  $event, 'check')">
                  <div>
                    <span>{{list.title}}</span>
                  </div>
                  <mat-checkbox class="checkBox-input" (change)="getList(list, $event, 'check')"
                                [checked]="isCheckedList(list)">
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="section-header__header-end">
            <div class="section-header__image-wrapper">
              <div class="section-header__section-one">
                <div class="section-header__image-box"
                     (click)="changeBackgroundCalendar();monthlyView();staffingGoalsActiveMonth()"
                     [ngStyle]="staffingGoalsActiveValueMonth == true? { 'background': '#2C3E50' } : {  'background': '#fff' }">
                  <img class="section-header__image section-header__image--month"
                       src="../assets/images/schedule/Month view.svg"
                       [ngStyle]="staffingGoalsActiveValueMonth == true? { filter: ' invert(100%) sepia(100%) saturate(3867%) hue-rotate(160deg) brightness(200%) contrast(200%)' } : { filter: 'invert(100%) sepia(0%) saturate(0%) hue-rotate(288deg) brightness(0%) contrast(102%)' }"/>
                </div>
                <div class="section-header__image-box" (click)="refresh()">
                  <img class='section-header__image section-header__image--refresh'
                       src="../assets/images/schedule/Refresh.svg"/>
                </div>
                <div class="section-header__image-box" [class.icon-background]="announcement" *ngIf="checkIfNotRoster()"
                     (click)="changeBackgroundAnnouncement()">
                  <img class="section-header__image" src="../assets/images/schedule/PA message.svg"
                       [class.icon-image]="announcement"/>
                </div>
              </div>
              <div class="section-header__section-two">
                <div class="section-header__image-box" [class.icon-background]="hide"
                     *ngIf="userRole != 'ROLE_COORDINATOR' && checkIfNotRoster()"
                     (click)="changeBackgroundHideNote()"
                     [class.not-allowed]="staffingGoalsActiveValueMonth"
                >
                  <img class="section-header__image section-header__image--hide"
                       src="../assets/images/schedule/Hide.svg"
                       [class.icon-image]="hide"
                       [class.changeImageColor]="staffingGoalsActiveValueMonth"
                  />
                </div>
                <div class="section-header__image-box" (click)="changeBackgroundUnavailable()"
                     [class.not-allowed]="staffingGoalsActiveValueMonth"
                     [class.icon-background]="unavailable">
                  <img class="section-header__image section-header__image--user"
                       src="../assets/images/schedule/User_Blocked.svg"
                       [class.icon-image]="unavailable"
                       [class.changeImageColor]="staffingGoalsActiveValueMonth"
                  />
                </div>
                <div class="section-header__image-box" (click)="staffingGoalsActive()"
                     [ngStyle]="staffingGoalsActiveValue == true && staffingGoalsActiveValueMonth == false ? { 'background': '#2C3E50'  } : {  'background': '#fff' }"
                     [class.not-allowed]="staffingGoalsActiveValueMonth"
                >
                  <img class="section-header__image" src="../assets/images/schedule/Staffing goals.svg"
                       [class.changeImageColor]="staffingGoalsActiveValueMonth"
                       [ngStyle]="staffingGoalsActiveValue == true && staffingGoalsActiveValueMonth == false ? { filter: ' invert(100%) sepia(100%) saturate(3867%) hue-rotate(160deg) brightness(200%) contrast(200%)' } : { filter: '' }"/>
                </div>
              </div>
            </div>
            <div>
              <button class="button button--blue section-header__button" (click)="toggleCreateShift()"
                      *ngIf="checkIfNotRoster()">
                {{'createNewShift' | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <app-loading class="spinner" *ngIf="loading"></app-loading>

    <article class="announcement" *ngIf="announcement" [class.loading]="loading">
      <div class="announcement__select announcement__select--upperCase">{{'publicAnnouncement' | translate}}</div>
      <div class="announcement__select announcement__select--upperCase">
        <div class="announcement__info-box">
          <div class="announcement__title">{{'addAnnouncement' | translate}}</div>
          <div class="announcement__subtitle">YYYY-MM-DD</div>
        </div>
        <div class="announcement__button-box">
          <button class="button button--primary" style="width: 80px; height: 24px;font-size: 10px;
    letter-spacing: 0.17px;"
                  (click)="openAddAnnouncementModal()">{{'add' | translate}}</button>
        </div>
      </div>
      <div class="announcement__select announcement__select--info"
           *ngFor="let announcement of allAnnouncement; let i = index">
        <div style="width: 97% ;cursor:pointer" (click)="editAnnouncement(announcement)">
          <div class="announcement__title announcement__title--black">{{announcement.text}}</div>
          <div class="announcement__subtitle">{{announcement.date}}</div>
        </div>
        <div class="announcement__delete" (click)="delete(announcement,i)">
          <img src="../assets/images/Delete.svg"/>
        </div>
      </div>
    </article>
    <article class="announcement" *ngIf="unavailable" [class.loading]="loading">
      <div class="announcement__select announcement__select--upperCase">{{'staffNotAvailable' | translate}}</div>
      <div *ngFor="let person of allUnavailable">
        <div class="unavailable" [routerLink]="['../personal/user/', person.appliedById]">
          <img class="expand-arrow" src="../../../assets/images/Arrow_Expand.svg">
          <div class="unavailable__image-box">
            <img class="personal__image"
                 *ngIf="!person.image || person.image == ''"
                 src="../assets/images/profile_white.svg"/>
            <img class="personal__image real-image"
                 *ngIf="person.image && person.image != ''"
                 [src]="person.image" (error)="person.image = '../assets/images/profile_white.svg'"
                 (load)="person.image = '../assets/images/profile_white.svg'"
            />
          </div>
          <div class="unavailable__info-box">
            <div class="unavailable__name">{{person.firstName}} {{person.lastName}}</div>
            <div class="unavailable__description">
              <!--            <span *ngIf="person.disclosedNote">-->
              <!--              {{person.disclosedNote}}-->
              <!--            </span>-->
              <span>
               {{person.cancelTitle}}
            </span>

            </div>
          </div>
        </div>
      </div>
    </article>
    <section class="staff-shifts" *ngIf="!loading && mode == 1">

      <article class="staff" *ngIf="staffingGoalsActiveValue == true">
        <div class="staff__title-box staff__title-box--border">
          <div class="staff__select staff__select--upperCase">
            <div class="staff__inBoxName">{{'shift type' | translate}}</div>
          </div>
          <div class="staff__title-end">
            <div class="staff__select staff__select--upperCase" *ngFor="let position of sortPositionSelect()">
              <div class="staff__name">
                <div class="staff__inBoxName">{{position.shortTitle}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="staff__day-time" *ngFor="let day of dayTimeShift">
          <div class="text-position">{{day.name | translate}}</div>
          <div class="staff__title-end">
            <ng-container>
              <div class="staff__color-container" *ngFor="let positionColor of sortPositionSelect()"
                   [ngStyle]="{'background-color':positionColor?.color}">
                <div>{{findStaffForPosition(positionColor, day)?.booked}}</div>
                <div class="staff__numberOfOpenShifts" *ngIf="findStaffForPosition(positionColor, day)?.open > 0"
                     [ngStyle]="{'color':positionColor?.color, 'border': '1px solid'+positionColor?.color}">
                  {{findStaffForPosition(positionColor, day)?.open}}
                </div>
              </div>
            </ng-container>

          </div>
        </div>
      </article>


      <div class="personal">
        <div class="day-announcement" [class.height]="showMessage">
          <input *ngIf="showMessage" class="personal__announcement-input" [(ngModel)]="announcementMessage" readonly/>
        </div>
        <label class="personal__label personal__label--personal">{{'users' | translate}}</label>
        <div style="padding: 0 ">
          <div class="personal__box" *ngFor="let person of assignments" style="padding-left: 20px"
               [ngClass]="{'low-cancel': person.status == 'CANCELED', 'not-clicable': person.status == 'PUBLISHED'}">
            <div class="personal__info" [ngClass]="{'not-clicable': person.status == 'PUBLISHED'}">
              <div class="personal__image-box usrImage" [ngClass]="{'not-clicable': person.status == 'PUBLISHED'}">
                <img class="personal__image"
                     *ngIf="!person.assignedToProfilePicture || person.assignedToProfilePicture == ''"
                     src="../assets/images/profile_white.svg"/>
                <img class="personal__image real-image"
                     *ngIf="person.assignedToProfilePicture && person.assignedToProfilePicture != ''"
                     [src]="person.assignedToProfilePicture"
                     (error)="person.assignedToProfilePicture = '../assets/images/profile_white.svg'"
                />
              </div>
              <div style="cursor: pointer" class="person-box" (click)="openUserDetails(person)"
                   [ngClass]="{'not-clicable': person.status !== 'PUBLISHED'}"
              >
                <div class="personal__name" style="line-height: 1.2 !important;"
                     [style.color]="person.status === 'PUBLISHED' ? '#929090' : '#333333'">
                <span class='personal__personal-name' *ngIf="person?.assignedToId">
                {{person?.assignedToName}}
              </span>
                  <span class='personal__personal-name' *ngIf="!person?.assignedToId">
                {{"unassigned" | translate}}
              </span>
                </div>
                <div class="personal__subtitle" style="line-height: 1.6 !important;">
                <span *ngIf="!person?.isActiveUser && person?.assignedToId"
                      [ngClass]="{'main-details__sub__red': !person?.isActiveUser}">
                  Användaren raderad
                </span>
                  <span *ngIf="person?.isActiveUser">
                <span *ngIf="person?.assignedToId">{{person.positionShortName}}</span>
                <span *ngIf="person?.assignedToId">,&nbsp;{{person.assignedToContract}}</span>
                  <span *ngIf="person?.isTemp === true">{{ "tempUser" | translate}}</span>
                     </span>
                  <span *ngIf="!person?.assignedToId">
                <span
                  *ngIf="person?.nrOfAppliedApplications == 0 && person?.internalEmployeeInvitations.length== 0  "> {{'noApplications' | translate}}</span>
                <span
                  *ngIf="person?.internalEmployeeInvitations.length > 0 && person?.nrOfAppliedApplications == 0">{{'pendingInvitations' | translate}}</span>
                 <span class="pendingApplications"
                       *ngIf="person?.nrOfAppliedApplications > 0"> {{'pendingApplications' | translate}}
                   ({{person?.nrOfAppliedApplications}})</span>
              </span>
                </div>
              </div>
              <!--            HIDDEN PER REQUEST OF CLIENT, MIGHT NEED TO BE INTRODUCED      -->
              <!--            <div class="for-competences" *ngIf="person.status != 'PUBLISHED'">-->
              <!--              <div class="competence-line" *ngFor="let c of person.assignedToCompetences"-->
              <!--                   [style.backgroundColor]="c.color">-->
              <!--              </div>-->
              <!--            </div>-->
            </div>
          </div>
          <div class="personal__box">
            <div class="personal__info"></div>
          </div>
        </div>
      </div>
      <div class="shifts" style="z-index: 200">
        <div class="day-announcement" [class.height]="showMessage"></div>
        <label class="personal__label"></label>
        <div class="personal__box personal__box--shifts" *ngFor="let person of assignments"
             [ngClass]="{'low-cancel': person.status == 'CANCELED'}">
          <div class="shifts__card"
               [style.margin-left.px]="getMargin(person)"
               [style.width.px]="getWidth(person)"
               [style.backgroundColor]="getColors(person).background"
               [ngClass]="{'low-cancel-card': person.status == 'CANCELED', 'shifts__card--short': calculateShiftLength(person) < 4, 'shifts__card--ultra-short': calculateShiftLength(person) < 2}"
               [style.borderColor]="getColors(person).border"
               [style.color]="getColors(person).font"
               (mouseenter)="cardHover[person.id] = true"
               (mouseleave)="shouldLeaveCard(person)">
            <div class="shifts__info" [style.margin-left.px]="getInfoMargin(person)"  [style.color]="getColors(person).font">
              <div class="shifts__info--date">{{person?.start | shiftDate}}</div>
              <div class="shifts__info--dash">-</div>
              <div class="shifts__info--date">{{person?.end | shiftDate}}</div>
            </div>
            <div class="shifts__info--activities" [style.margin-left.px]="getInfoMargin(person)"  [style.color]="getColors(person).font">
            <span>
              {{person.activitiesString}}
            </span>
            </div>
            <div class="shifts__hover-card"
                 [ngStyle]="!cardHover[person.id]? { display: 'none' } : { display: 'block' }"
                 *ngIf="this.checkIfNotRoster()">
              <div class="shifts__title" (click)="getPerson(person)">Detaljer</div>
              <div class="shifts__dots" *ngIf="person.status != 'CANCELED'"
                   (click)="cardTooltip(person.id)">
                <div [inlineSVG]="'../../assets/images/Menu.svg'"></div>
              </div>
            </div>

            <div class="shifts__tooltip" *ngIf="person.id === personId" (clickOutside)="shouldCloseTooltip(person.id)"
                 [class.position]=" person.status === 'PUBLISHED'">
              <div class="shifts__tooltip-link" *ngIf="person.status === 'PUBLISHED'"
                   (click)="assignShift(person)">{{'assignedShift' | translate}}</div>
              <div class="shifts__tooltip-link" (click)="openCopyModal(person)">{{'copy' | translate}}</div>
              <div class="shifts__tooltip-link" *ngIf="person.status === 'ASSIGNED'"
                   (click)="openCancelModal(person)">{{'cancel' | translate}}</div>
              <div class="shifts__tooltip-link" (click)="openDeleteModal(person)">{{'delete' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="personal__box personal__box--shifts"></div>
      </div>
      <div class="shift-note">
        <div class="day-announcement" [class.height]="showMessage"></div>
        <label class="personal__label"></label>
        <div style="padding: 0 ;">
          <div *ngFor="let person of assignments" style="padding-right: 20px;"
               [ngClass]="{'low-cancel': person.status == 'CANCELED'}">
            <div class="shift-note__links"
                 (click)="openShiftNoteModal(person)">
              <div class="shift-note__note">

                <div class="shift-note__info-text" *ngIf="getCancelReasonsForShifts(person)">
                  {{getCancelReasonsForShifts(person)}}
                </div>
                <div class="shift-note__info-text shift-note__info-text--color" [id]="'undisclosed-note-'+ person.id"
                     *ngIf="person.status !=='CANCELED'"
                     [ngStyle]="hideNote === true ? {'position': 'absolute'} : {'top': '4px'}">
                  {{getUndisclosed(person)}}
                </div>
                <div class="shift-note__info-text" [id]="'disclosed-note-'+ person.id" *ngIf="!hideNote">
                  {{getDisclosed(person)}}
                </div>
              </div>
              <!--            {{getUndisclosed(person)}}-->
              <div
                *ngIf="!checkIfNotesAreEclipsed(person.id) && (person.status != 'CANCELED' || getUndisclosed(person)?.length < 1 )"
                class="shift-note__option"
                [inlineSVG]="'../../../assets/images/schedule/Note.svg'"></div>
              <span
                *ngIf="checkIfNotesAreEclipsed(person.id) || (person.status == 'CANCELED' && getUndisclosed(person)?.length > 0 )"
                class="shift-note__more">mer</span>

            </div>
          </div>
        </div>
      </div>
      <div class="actual-shift" *ngIf="actualShift" [style.top.px]="calculateTop()"></div>
    </section>
    <section class="monthly" *ngIf="!loading && mode == 2">
      <app-calendar></app-calendar>
    </section>
  </ng-container>
  <ng-template #emailExist>
    <app-create-new-shift (back)="backFromCreateSchedule()" (updateAssignment)="updateAssignment()"
                          (time)="getTimeFromLocal($event)" (time1)="getTimeFromLocal($event)"></app-create-new-shift>
  </ng-template>

</div>
