<section class="cancel-dialog">
  <img class="cancel-dialog__image" src="../../../../assets/images/Close.png" (click)="closeModal()"/>
  <div class="cancel-dialog__title">{{ data.title }}</div>
  <div class="modal-section">
    <div class="modal-section__title">{{data.subtitleCancellation}}</div>
    <div class="modal-section__subtitle">{{data.textCancellation}}</div>
    <input class="section-select__select modal-input" readonly (click)="openDropdown()"
           (clickOutside)="openReasons = false" placeholder="{{'select' | translate}}" [(ngModel)]="reasonTitle"/>
    <div class="form__select form__select--reasons" *ngIf="openReasons">
      <div class="form__select-option" *ngFor="let reason of  reasons" (click)="getReasons(reason)"
      >
        <div>{{reason.title}}</div>
      </div>
    </div>
  </div>
  <div class="modal-section">
    <div class="modal-section__title">{{data.undisclosed}}</div>
    <div class="modal-section__subtitle">{{data.undisclosedText}}</div>
    <textarea class="form__text-area" #undisclosedText [(ngModel)]="data.person.employerNote"></textarea>
  </div>
  <div class="modal-section modal-section--bottom" *ngIf="role != 'COORDINATOR'">
    <div class="modal-section__title">{{data.disclosed}}</div>
    <div class="modal-section__subtitle">{{data.disclosedText}}</div>
    <textarea class="form__text-area form__text-area--text-color" [(ngModel)]="data.person.disclosedNote" #disclosedText></textarea>
  </div>
  <div class="modal-checkBox">
    <mat-checkbox class="modal-checkBox__checkbox"  (change)="checkBoxCheck()" ></mat-checkbox>
    <div class="modal-checkBox__subtitle">{{'cancelModalCheckBox' | translate}}</div>
  </div>
  <div class="buttons">
    <button class="button button--primary" style="margin-right: 10px; width: 100px"
            (click)="closeModal()">{{'cancelButton' | translate}}
    </button>
    <button class="button button--red btn" style="width: 100px"
            (click)="cancelAssignment()">{{'cancel' | translate}}
    </button>
  </div>
</section>
