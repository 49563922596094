<section class="container">
  <div class="section-header">
    <div class="section-header__title-box">
      <div class="section-header__title" *ngIf="section != 1">{{title | translate}}</div>
      <div class="section-header__title" *ngIf="section == 1">{{'personalGroupPageName' | translate}}</div>
    </div>
    <div class="section-header__header-end" *ngIf="hide == 0 && checkIfNotRoster()">
      <div class="section-header__image-box" (click)="section = 0"
           [ngClass]="{'section-header__image-box--background': section == 0}">
        <div class="section-header__single-user">
          <img class='section-header__image section-header__image--single'
               src="../assets/images/personal/User_Single.svg"/>
        </div>
      </div>
      <div class="section-header__image-box" (click)="multiplePersonal()"
           [ngClass]="{'section-header__image-box--background': section == 1}">
        <div style="width:20px; height: 16px">
          <img class="section-header__image section-header__image--group"
               src="../assets/images/personal/User_Group_Outline.svg"/>
        </div>
      </div>
      <div>
        <button class="button button--blue section-header__button" *ngIf="!newUser && section == 0"
                (click)="createUser()">
          {{'addUser' | translate}}
        </button>
        <button class="button button--blue section-header__button" *ngIf="!newUser && section == 1"
                (click)="createGroup()">
          {{'addGroup' | translate}}
        </button>
      </div>
    </div>

    <div class="section-header__header-end" *ngIf="hide == 1">

      <button class=" button-size" *ngIf="!newUser && section == 0 " (click)="backClicked()">
        {{'backButton' | translate}}
      </button>

    </div>

  </div>
  <section class="main" *ngIf="section == 0">
    <div class="section-select">
      <div class="section-select__first">
        <div class="section-select__input-box">
          <input class="input section-select__input" placeholder="{{'search' | translate}}" [(ngModel)]="searchText"
                 (ngModelChange)="searchEmployees($event)"/>
          <div class="section-select__input section-select__input--position">
            <img src="../assets/images/Search.svg"/>
          </div>
        </div>
        <div class="for-type-select z_index_max changeMaxHeight" (clickOutside)="openPositions = false">
          <div class="section-select__select-box">
            <div class="section-select__select right-padding section-select__value" style="padding: 0">
              <input class="position-input" readonly (click)="toggleDropdown()"
                     [(ngModel)]="selectedPositionDropdownTitle"
                     placeholder="{{'allPositions' | translate}}"/>
            </div>
          </div>
          <div class="form__select z_index_max" *ngIf="openPositions">
            <div class="form__select-option" style="display:flex; justify-content: space-between"
                 (click)="selectPosition({id: -1}, $event)">
              {{'allUser' | translate}}
              <mat-checkbox class="example-margin" style="margin-right: 13px; z-index: 100"
                            (change)="selectPosition({id: -1}, $event)"
                            [checked]="allPositionsChecked">
              </mat-checkbox>
            </div>
            <div class="form__select-option" style="display:flex; justify-content: space-between"
                 *ngFor="let p of arrayForAllFilter"
                 (click)="selectPosition(p, $event)">
              <span>{{p.name}}</span>
              <mat-checkbox class="example-margin" style="margin-right: 13px; z-index: 100"
                            (change)="selectPosition(p, $event)"
                            [checked]="isCheckedMultiple(p)">
              </mat-checkbox>
            </div>
          </div>
        </div>

        <div class="for-type-select z_index_max" (clickOutside)="openType = false;">
          <div class="section-select__select-box" (click)="openType = true;">
            <div class="section-select__select  section-select__value" style="padding: 0 0 0 14px">
              {{findType() | translate}}
            </div>
          </div>
          <div class="form__select position_for_search" *ngIf="openType">
            <div class="form__select-option between" *ngFor="let type of types"
                 (click)="setType(type)">
              <span>  {{type.name | translate}} </span> <span class="material-icons checkSelect"
                                                              *ngIf="checkSelect == type.id">done</span>
            </div>
          </div>
        </div>
      </div>

      <div class="for-type-select right-select z_index_max" (clickOutside)="openActions = false;"
           *ngIf="title != 'personalPageName'">
        <div class="section-select__select-box " (click)="toOpenActions()">
          <div class="section-select__select  section-select__value">
              <span [ngClass]="{'disabled-select': allChecks.length == 0}">
               {{'selectAction' | translate}}
              </span>
          </div>
        </div>
        <div class="form__select " *ngIf="openActions">
          <div class="form__select-option" *ngFor="let a of actionsGroupsPersonals"
               (click)="selectAction(a)">
            {{a.name | translate}}
          </div>
        </div>
      </div>

      <div class="for-type-select right-select z_index_max" (clickOutside)="openActions = false;"
           *ngIf="title == 'personalPageName' && checkIfNotRoster()">
        <div class="section-select__select-box" (click)="toOpenActions()">
          <div class="section-select__select  section-select__value">
              <span [ngClass]="{'disabled-select': allChecks.length == 0}">
             &nbsp;{{selectAct | translate}}
              </span>
          </div>
        </div>
        <div class="changeFormSelect" *ngIf="openActions">
          <div class="form__select-option" *ngFor="let a of actions"
               (click)="selectAction(a)">
            {{a.name | translate}}
          </div>

        </div>
      </div>
    </div>
    <app-loading class="spinner" *ngIf="loading && this.showableUsers.length == 0"></app-loading>
    <!--    {{showableUsers.length}}-->
    <div class="personal-main" *ngIf="!loading || this.showableUsers.length > 0">
      <div class="personal-main__box z_index_min" infiniteScroll>
        <table class="table-cal">
          <thead>
          <tr class="table-cal__tr-th">
            <th class="table-cal__image-th"></th>
            <th style="width: 201px; padding-left: 20px">{{'name' | translate}}</th>
            <th style="width: 162px;">{{'position' | translate}}</th>
            <th style="width: 150px;">{{'competence' | translate}}</th>
            <th style="width: 160px;">{{'roll' | translate}}</th>
            <th style="width: 170px;">{{'contract' | translate}}</th>
            <th>{{'status' | translate}}</th>
            <th></th>
            <td class="checkBox">
              <mat-checkbox class="example-margin" (click)="setAll()"></mat-checkbox>
            </td>
          </tr>
          </thead>
          <tbody>
          <tr class="tr" *ngFor="let employee of showableUsers; let i = index">
            <td class="user-image-td">
              <img class="user-image" src="assets/images/profile_white.svg"
                   *ngIf="!employee.profilePicture || employee.profilePicture == ''">
              <img class="user-image" *ngIf="employee.profilePicture && employee.profilePicture != ''"
                   [src]="employee.profilePicture">
            </td>
            <td class="table__name-td" style="padding-left: 20px" (click)="openUser(employee)">{{employee.name}}</td>
            <td class="table__positions-td"
                (click)="openUser(employee)">{{eraseCommaa(employee.positions)}}</td>
            <td class="table__competences-td"
                (click)="openUser(employee)">
              <div class="specifics-only" [title]="shortTitle(employee.competences)">
                {{shortTitle(employee.competences)}}
              </div>
            </td>
            <td class="table__roles-td"
                (click)="openUser(employee)">
              <div class="specifics-only" [title]="getAndTranslateRoles(employee.roles)">
                {{getAndTranslateRoles(employee.roles)}}
              </div>
            </td>
            <td class="table__contracts-td"
                (click)="openUser(employee)">{{eraseCommaa(employee.contracts)}}</td>
            <td class="table__rowStatus-td" (click)="openUser(employee)">
              <span *ngIf="employee.groupStatus != null">  {{getStatus(employee) | titlecase}}</span>
            </td>
            <td class="table-end">

            </td>
            <td class="table__checkbox-box checkBox ">
              <mat-checkbox class="example-margin"
                            (change)="selection(employee.id)" [checked]="isChecked(employee.id)"></mat-checkbox>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <!--PAGINATION-->
      <div class="for-pagination">
        <div class="for-pag">
          <div class="shop-pagination" *ngIf="!loading">
            <div class="pagination-button arrow-pagination" [ngClass]="{'not-cliccable-pagination': page == 0}"
                 (click)="openPage(page - 1)">
              <mat-icon>arrow_left</mat-icon>
            </div>

            <div class="pagination-button next" *ngIf="page - 1 >= 1" (click)="openPage(page - 2)">
              <!-- productPage bc productPage starts with 0  -->
              {{page - 1}}
            </div>

            <div class="pagination-button next" *ngIf="page - 1 >= 0" (click)="openPage(page - 1)">
              <!-- productPage bc productPage starts with 0  -->
              {{page}}
            </div>

            <div class="pagination-button actual-pagination" (click)="openPage(page)">
              <!-- +1 bc productPage is 0  -->
              {{page + 1}}
            </div>

            <div class="pagination-button next" *ngIf="getLastPage() - page > 1" (click)="openPage(page + 1)">
              <!-- +2 bc productPage is 0  -->
              {{page + 2}}
            </div>

            <div class="pagination-button next" *ngIf="getLastPage() - page > 2" (click)="openPage(page + 2)">
              <!-- +3 bc productPage is 0  -->
              {{page + 3}}
            </div>
            <div class="pagination-dots" *ngIf="getLastPage() - page > 4">
              <mat-icon class="pagination-dots-icon">more_horiz</mat-icon>
            </div>
            <div class="pagination-button" *ngIf="getLastPage() - page > 3" (click)="openPage(getLastPage() - 1)">
              {{getLastPage()}}
            </div>

            <!-- productPage == getLastPage() - 1  ( -1 bc we start with 0)-->
            <div class="pagination-button arrow-pagination"
                 [ngClass]="{'not-cliccable-pagination': page == getLastPage() - 1}" (click)="openPage(page + 1)">
              <mat-icon>arrow_right</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="main" *ngIf="section == 1">
    <app-groups></app-groups>
  </section>

  <app-create-user (close)="newUser = false" *ngIf="newUser" class="show-modal"></app-create-user>
</section>


