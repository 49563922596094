<section class="cancel-dialog">
  <img class="cancel-dialog__image" src="../../../../assets/images/Close.png" (click)="closeModal()"/>
  <div class="cancel-dialog__title">{{ data.title }}</div>
  <div class="modal-section" *ngIf="data.showUndisclosed">
    <div class="modal-section__title">{{data.undisclosed}}</div>
    <div class="modal-section__subtitle">{{data.undisclosedText}}</div>
    <textarea class="form__text-area"[(ngModel)]="undisclosed"></textarea>
  </div>
  <div class="modal-section modal-section--bottom" *ngIf="!hide && data.showDisclosed">
    <div class="modal-section__title">{{data.disclosed}}</div>
    <div class="modal-section__subtitle">{{data.disclosedText}}</div>
    <textarea class="form__text-area form__text-area--red"
              [(ngModel)]="disclosed"></textarea>
  </div>
  <div *ngIf="data.hasCheckbox"  class="modal-checkBox">
      <mat-checkbox class="modal-checkBox__checkbox"  [(ngModel)]="this.overWrite" ></mat-checkbox>
      <div class="modal-checkBox__subtitle" *ngIf="data.showDisclosed">{{'overwritePrivateNotes' | translate}}</div>
    <div class="modal-checkBox__subtitle" *ngIf="data.showUndisclosed">{{'overwritePublicNotes' | translate}}</div>
  </div>
  <div class="buttons">
    <button class="button button--primary" style="margin-right: 10px; width: 100px"
            (click)="closeModal()">{{'cancelButton' | translate}}
    </button>
    <button class="button button--blue" style="width: 100px"
            (click)="updateNote()">{{'saveButton' | translate}}</button>
  </div>
</section>
