import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-log-out',
  templateUrl: './log-out.component.html',
  styleUrls: ['./log-out.component.scss'],
})
export class LogOutComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<LogOutComponent>) {}

  ngOnInit(): void {}

  closeModal(): void {
    this.dialogRef.close();
  }

  logout(): void {
    this.dialogRef.close('logout');
  }
}
