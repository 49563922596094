import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-temp-user',
  templateUrl: './temp-user.component.html',
  styleUrls: ['./temp-user.component.scss'],
})
export class TempUserComponent implements OnInit {
  firstName = '';
  lastName = '';
  company = '';
  mobile = '';
  constructor(
    public dialogRef: MatDialogRef<TempUserComponent>,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}

  closeModal(id: any): void {
    this.dialogRef.close(id);
  }

  generateRandomString(length: number): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  confirm(): void {
    if (this.firstName === '') {
      this.snackBar.open(
        this.translate.instant('pleaseChooseFirstName'),
        'OK',
        { duration: 3000 }
      );
      return;
    }
    if (this.lastName === '') {
      this.snackBar.open(
        this.translate.instant('pleaseChooseLastName'),
        'OK',
        { duration: 3000 }
      );
      return;
    }
    const email = this.generateRandomString(7).toLowerCase() + '@gapstaff.com';
    const userObj = {
      username: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      email: '',
      tel1: '',
      tel2: '',
      tel3: '',
      note: '',
      company: '',
      externalUserId: '',
      rowStatus: 1,
      enabled: 1,
      name: '',
    };
    userObj.username = email;
    userObj.firstName = this.firstName;
    userObj.lastName = this.lastName !== '' ? this.lastName : 'Temporal';
    userObj.dateOfBirth = null;
    userObj.email = email;
    userObj.tel1 = this.mobile;
    userObj.tel2 = null;
    userObj.tel3 = null;
    userObj.note = null;
    userObj.company = this.company;
    userObj.externalUserId = null;
    userObj.name = userObj.firstName + ' ' + userObj.lastName;
    this.dialogRef.close(userObj);
  }
}
