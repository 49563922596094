<section class="conversation-container">
  <div class="header">
    <div class="header__title">{{'messages' | translate}}</div>
    <div class="header__button-box">
      <button class="button button--blue" [ngClass]="{'cantSend' : canSendMessages === false}"
              (click)="openNewMessages()">{{'newMessage' | translate}}</button>
    </div>
  </div>
  <section class="main-conversation">
    <app-loading class="spinner" *ngIf="loading && messages.length == 0"></app-loading>
    <div class="conversation" *ngIf="!loading || messages.length > 0">
      <div class="conversation__header">
        <div class="conversation__title">{{'conversations' | translate}}</div>
        <div class="conversation__line" [class.invisibleLine]="isFirst()"></div>
      </div>
      <div class="messages__messages-box" *ngFor="let message of messages " (click)="showChatBox(message)"
           [class.singleChatbackground]="message.id === singleChat"
      >
        <div class="conversation__single-chat">
          <div class="conversation__image-box">
            <img class="personal-image" *ngIf="!message.headerImage || message.headerImage == ''"
                 src="../assets/images/profile_white.svg"/>
            <img class="personal-image real-image" *ngIf="message.headerImage && message.headerImage != ''"
                 [src]="message.headerImage"/>
            <div class=" conversation__login"></div>
          </div>
          <div class="conversation__chat">
            <div class="conversation__chat-name">{{message.headerChatName}}</div>
            <div class="conversation__chat-message">{{message.headerMessage}}</div>
          </div>
          <div class="conversation__notification" *ngIf="message.headerStatus == 'UNSEEN'"></div>
        </div>
        <div class="conversation__line" [class.invisibleLine]="isVisibleLine(message)" ></div>
      </div>
      <div class="conversation__line" [class.invisibleLine]="isLast()"></div>
    </div>
    <app-chat class="chat-conversation"  *ngIf="showChat" (clickOutside)="shouldCloseChat()"></app-chat>
  </section>
</section>
