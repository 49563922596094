import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ShiftsService } from '../shared/services/shifts.service';

@Component({
  selector: 'app-add-to-group',
  templateUrl: './add-to-group.component.html',
  styleUrls: ['./add-to-group.component.scss'],
})
export class AddToGroupComponent implements OnInit {
  message = '';
  openActions = false;
  allChecks: Array<any> = [];
  groupId = localStorage.getItem('gruopId');
  allGroups: any = [];
  idSlectGroup: any = null;
  checkSelect: any = -1;
  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<any>,
    private shiftsService: ShiftsService
  ) {}

  ngOnInit(): void {
    this.shiftsService.getAllGroup(this.groupId).subscribe((response: any) => {
      this.allChecks = response.filter((g: any) => {
        return g.isDefault != 1;
      });
    });
  }

  selectGroups(id: any): any {
    this.openActions = false;
    this.idSlectGroup = id;
    this.checkSelect = id.id;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  send(): any {
    this.dialogRef.close(this.idSlectGroup.id);
  }

  toOpenActions(): void {
    if (this.allChecks.length > 0) {
      this.openActions = true;
    }
  }
}
