<section class="chat-box">
  <img class="shift-image" src="../../../../assets/images/Close.png" *ngIf="chatForModal"
       [ngClass]="{'from-modal': chatForModal}" (click)="backToUser()"/>
  <div class="chat-box__header" *ngIf="!modalChat">{{'conversationWith' | translate}}
    <span style="color:#3498d8;margin-left: 5px;outline:none">
      <span *ngFor="let r of selectedRecipients; let i= index;">
        <span (click)="openRecipient(r)" style="cursor: pointer">{{r.name}}</span><span
        *ngIf="i != selectedRecipients.length - 1">, </span>
      </span>
    </span>
  </div>
  <div class="chat-box__body" #scrollMe
       infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollUpDistance]="5.5"
       [infiniteScrollThrottle]="100"
       (scrolledUp)="onScroll()"
       [scrollWindow]=false
  >
    <div class="chat-box__date-box" *ngFor="let date of chat?.messages; let i = index">
      <div *ngIf="shouldShowDate(i, date.createdDate)">
        <div class="chat-box__date">
          {{date.createdDate | convertDateOnly}}
        </div>
        <div class="chat-box__line"></div>
      </div>
      <div class="chat-box__message-box">
        <div class="chat-box__image-box">
          <img class="personal-image" *ngIf="!date.authorProfilePicture || date.authorProfilePicture == ''"
               src="../assets/images/profile_white.svg"/>
          <img class="personal-image real-image"
               *ngIf="date.authorProfilePicture && date.authorProfilePicture != ''"
               [src]="date.authorProfilePicture"/>
        </div>
        <div>
          <div class="chat-box__name-box">
            <div class="chat-box__name">{{date?.authorName}}</div>
            <div class="chat-box__hours">{{date.createdDate | shiftDate}}</div>
          </div>
          <div class="chat-box__text">{{date?.message}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="chat-box__input-box">
    <input class="input chat-box__input" type="text" placeholder="{{'writeMessage' | translate}}" #textInput
           (keyup.enter)="sendMessage(textInput.value)"
           [(ngModel)]="inputValue"/>
    <button class="button--primary chat-box__button" [title]="getTitle()" [ngClass]="{'disabledSend': isSending}"
            (click)="sendMessage(textInput.value)">{{'sendButton' | translate}}</button>
  </div>
</section>
