<section class="conversation-container">
  <div class="header">
    <div class="header__title">{{'messages' | translate}}</div>
    <div class="header__button-box">
      <button class="button--primary  header__button"
              (click)="back()" [routerLink]="['../']">{{'backButton' | translate}}</button>
    </div>
  </div>
  <section class="main-conversation">
    <div class="conversation">
      <div class="conversation__recipients-box">
        <div class="conversation__header">
          <div class="conversation__title">{{'groups' | translate}}</div>
          <div class="conversation__line"></div>
        </div>
        <div class="for-groups" *ngFor="let g of sortGroups()">
          <div class="conversation__single-chat">
            <div class="conversation__image-box">
              <div class="group-image-div">
                <img class="group-image" src="assets/images/Logo.png">
                <div class="group-count">{{g.userCount}}</div>
              </div>
            </div>
            <div class="conversation__chat">
              <div class="conversation__chat-name">{{g?.name}}</div>
              <div class="conversation__chat-message">{{g?.description}}</div>
            </div>
            <mat-checkbox class="example-margin"
                          (change)="getRecipient(g, $event)">
            </mat-checkbox>
          </div>
          <div class="conversation__line"></div>
        </div>
        <div *ngFor="let recipient of recipientsGroup?.recipients; let i = index"
        >
          <div class="conversation__header" *ngIf="shouldShowDate(i, recipient.name)">
            <div class="conversation__title">{{recipient.name.charAt(0).toLocaleUpperCase()}}</div>
            <div class="conversation__line"></div>
          </div>
          <div class="conversation__single-chat">
            <div class="conversation__image-box">
              <img class="personal-image" *ngIf="!recipient.profilePicture || recipient.profilePicture == ''"
                   src="../assets/images/profile_white.svg"/>
              <img class="personal-image real-image" *ngIf="recipient.profilePicture && recipient.profilePicture != ''"
                   [src]="recipient.profilePicture"/>
            </div>
            <div class="conversation__chat">
              <div class="conversation__chat-name">{{recipient?.name}}</div>
              <div class="conversation__chat-message">{{recipient?.body}}</div>
            </div>
            <mat-checkbox class="example-margin"
                          (change)="getRecipient(recipient, $event)">
            </mat-checkbox>
          </div>
          <div class="conversation__line"></div>
        </div>
      </div>
    </div>
    <app-chat class="chat-conversation" *ngIf="showChat"></app-chat>
  </section>
</section>
