import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { AuthConst } from '../../../shared/services/const/authConst';
import { ShiftsService } from '../../../shared/services/shifts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-announcement',
  templateUrl: './add-announcement.component.html',
  styleUrls: ['./add-announcement.component.scss'],
})
export class AddAnnouncementComponent implements OnInit {
  date = new Date();
  currentDate: any;
  message: string | undefined;
  update: boolean;
  allAnnouncement: any = [];

  constructor(
    public dialogRef: MatDialogRef<AddAnnouncementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    private shiftsService: ShiftsService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.data.mode === 'update') {
      this.update = true;
    }
    this.currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    if (this.update) {
      this.message = this.data.announcement?.text;
      this.currentDate = this.data.announcement?.date;
    }
    this.getAnnouncement();
  }

  getAnnouncement(): void {
    const groupId = Number(localStorage.getItem(AuthConst.gruopId));
    this.shiftsService.getAnnouncements(groupId).subscribe((announcements) => {
      announcements.forEach((x: { groupId: number }) => {
        if (x.groupId === Number(groupId)) {
          this.allAnnouncement.push(x);
        }
      });
    });
  }

  addEvent(event: any): void {
    this.currentDate = `${event.value}`;
    this.currentDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
  }

  sendAnnouncement(message: string): void {
    const createAnnouncement = {
      groupId: Number(localStorage.getItem(AuthConst.gruopId)),
      date: this.currentDate,
      text: message,
    };

    if (createAnnouncement.text !== '') {
      const findIndex = this.allAnnouncement.findIndex((x: any) => {
        return x.date === createAnnouncement.date;
      });
      if (findIndex === -1) {
        this.shiftsService
          .createAnnouncements(createAnnouncement)
          .subscribe((y) => {
            this.dialogRef.close(y);
          });
      } else {
        this.snackBar.open(this.translate.instant('currentDayExist'));
      }
    } else {
      this.snackBar.open(this.translate.instant('emptyInput'));
    }
  }

  updateAnnouncement(message: string): void {
    const updateAnnouncement = {
      id: this.data.announcement.id,
      groupId: Number(localStorage.getItem(AuthConst.gruopId)),
      date: this.currentDate,
      text: message,
    };
    if (updateAnnouncement.text !== '') {
      const announcementIndex = this.allAnnouncement.findIndex((x: any) => {
        return (
          x.date === updateAnnouncement.date && x.id !== updateAnnouncement.id
        );
      });

      if (announcementIndex === -1) {
        this.shiftsService
          .updateAnnouncements(updateAnnouncement)
          .subscribe(() => {
            this.dialogRef.close(updateAnnouncement);
          });
      } else {
        this.snackBar.open(this.translate.instant('currentDayExist'));
      }
    } else {
      this.snackBar.open(this.translate.instant('emptyInput'));
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
