import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-update-group',
  templateUrl: './update-group.component.html',
  styleUrls: ['./update-group.component.scss'],
})
export class UpdateGroupComponent implements OnInit {
  object: any;
  objectValues = {
    description: '',
    name: '',
  };

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA)
    public data: { objectGroup: object }
  ) {}

  ngOnInit(): void {
    this.object = this.data;
    this.objectValues.name = this.object.objectGroup.name;
    this.objectValues.description = this.object.objectGroup.description;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  send(): any {
    this.object.objectGroup.name = this.objectValues.name;
    this.object.objectGroup.description = this.objectValues.description;
    this.dialogRef.close(this.object);
  }
}
