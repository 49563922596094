<section class="group">
  <img class="group__image" src="../../../../assets/images/Close.png" (click)="closeModal()"/>
  <div class="group__title">{{'group' |  translate}}</div>
  <div class="group__container">
    <div class="group__box" *ngFor="let group of groups">
      <div class="group__single-box">
        <div class="group__group-info" [ngClass]="{'disabled-group': group.isDefault == 1}">
          <div class="group__info-title" [ngClass]="{'disabled-group': group.isDefault == 1}">{{group.name}}</div>
          <div class="group__info-subtitle" [ngClass]="{'disabled-group': group.isDefault == 1}">{{group.description}}</div>
        </div>
        <div class="options__toggle-button">
          <div class="options__toggle-field" [class.changeBackground]="group.status === 1"  [ngClass]="{'disabled-toggle': group.isDefault == 1}"
               (click)="checked(group)" [class.selected-option]="group.status === 1">
            <div class="options__toggle-circle"
                 [class.selected-circle]="group.status === 1"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="button-row">
    <button class="button button--primary" style="width: 100px; margin-right: 10px"
            (click)="closeModal()">{{'cancelButton' | translate}}</button>
    <button class="button button--blue" style="width: 100px"
            (click)="save()">{{'confirm' | translate}}</button>
  </div>
</section>
