import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmployeesService } from '../../shared/services/employees.service';
import { AuthConst } from '../../shared/services/const/authConst';
import { MatDialog } from '@angular/material/dialog';
import { CreateShiftTypeModalComponent } from '../modals/create-shift-type-modal/create-shift-type-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../shared/services/helper.service';
import { ShiftsService } from '../../shared/services/shifts.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-create-custom-shift',
  templateUrl: './create-custom-shift.component.html',
  styleUrls: ['./create-custom-shift.component.scss'],
})
export class CreateCustomShiftComponent implements OnInit {
  shiftsForm: FormGroup = this.fb.group({
    name: [''],
    firstStartTime: ['', Validators.required],
    firstEndTime: ['', Validators.required],
    position: ['', Validators.required],
    firstActivity: ['', Validators.required],
    competence: [''],
    undisclosedNote: [''],
    disclosedNote: [''],
    secondActivity: [''],
    secondStartTime: [''],
    secondEndTime: [''],
  });

  createdShift: string;
  startTime: string;
  endTime: string;
  chatId: number;
  positions: any = [];
  competences: any = [];
  activities: any = [];

  setPosition: string | undefined;
  setCompetence: string | undefined;
  setActivity: string | undefined;
  setActivity2: string | undefined;
  color: string | undefined;

  positionTitle: string | undefined;

  positionId: number | undefined;
  competenceId: number | undefined;
  activityId: number | undefined;
  setActivity2Id: number | undefined;
  groupIdOfCurrentUser: number | undefined;

  displayPositions = false;
  displayCompetence = false;
  displayActivities = false;
  secondActivities = false;
  displayChat = false;
  disabledName = false;
  hideName = true;

  // tslint:disable-next-line:no-output-native
  @Output() close = new EventEmitter();
  @Input() custom: string | undefined;
  @Input() new: string | undefined;
  @Input() edit: string | undefined;
  @Input() assignment: any;

  constructor(
    private fb: FormBuilder,
    private employeesService: EmployeesService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private helperService: HelperService,
    private shiftService: ShiftsService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.helperService.showCalendar$.next(false);
    if (this.edit === 'edit') {
      this.disabledName = true;
      this.hideName = false;
    }
    this.groupIdOfCurrentUser = Number(localStorage.getItem(AuthConst.gruopId));
    this.employeesService.userPositions().subscribe((x) => {
      x.forEach((group: any) => {
        if (
          group.groupId === this.groupIdOfCurrentUser &&
          group.groupStatus === 'ACTIVE'
        ) {
          this.positions.push(group);
          this.positions.forEach((position: any) => {
            if (position.id === this.assignment?.positionId) {
              this.positionId = position.id;
              this.setPosition = position.title;
              this.shiftsForm.patchValue({
                position: this.setPosition,
              });
            }
          });
        }
      });
    });

    this.employeesService.userActvity().subscribe((x) => {
      x.forEach((group: any) => {
        if (
          group.groupId === this.groupIdOfCurrentUser &&
          group.groupStatus === 'ACTIVE'
        ) {
          this.activities.push(group);
          this.activities.forEach((activity: any) => {
            if (activity.id === this.assignment?.activityId) {
              this.activityId = activity.id;
              if (activity.shortTitle) {
                this.setActivity =
                  activity.title + ' (' + activity.shortTitle + ')';
              } else {
                this.setActivity = activity.title;
              }
              this.shiftsForm.patchValue({
                firstActivity: this.setActivity,
              });
            }
            if (activity.id === this.assignment?.activity2Id) {
              this.setActivity2Id = activity.id;
              if (activity.shortTitle) {
                this.setActivity2 =
                  activity.title + ' (' + activity.shortTitle + ')';
              } else {
                this.setActivity2 = activity.title;
              }
              this.shiftsForm.patchValue({
                secondActivity: this.setActivity2,
              });
            }
          });
        }
      });
    });

    this.employeesService.userCompetences().subscribe((x) => {
      x.forEach((group: any) => {
        if (
          group.groupId === this.groupIdOfCurrentUser &&
          group.groupStatus === 'ACTIVE'
        ) {
          this.competences.push(group);
          this.competences.forEach((competence: any) => {
            if (competence.id === this.assignment?.competenceId) {
              this.competenceId = competence.id;
              this.setCompetence = competence.title;
              this.shiftsForm.patchValue({
                competence: this.setCompetence,
              });
            }
          });
        }
      });
    });

    if (this.assignment) {
      this.shiftsForm.patchValue({
        name: this.assignment?.name,
        firstStartTime: this.assignment?.start,
        firstEndTime: this.assignment?.end,
        undisclosedNote: this.assignment?.undisclosedNote,
        disclosedNote: this.assignment?.disclosedNote,
        firstActivity: this.setActivity,
        secondActivity: this.setActivity2,
        secondStartTime: this.assignment?.start2,
        secondEndTime: this.assignment?.end2,
      });
    }

    if (!this.shiftsForm.value.secondActivity) {
      this.shiftsForm.get('secondStartTime').disable();
      this.shiftsForm.get('secondEndTime').disable();
    }
  }

  getPositions(): void {
    this.displayPositions = !this.displayPositions;
  }

  chosePosition(position: any): void {
    if (position !== null) {
      this.color = '#' + position.color;
      this.positionId = position.id;
      this.positionTitle = position.title;
      this.shiftsForm.patchValue({
        position: this.positionTitle,
      });
    } else {
      this.positionId = null;
      this.shiftsForm.patchValue({
        position: this.translate.instant('select'),
      });
    }
  }

  getActivities(): void {
    this.displayActivities = !this.displayActivities;
  }

  choseActivity(activities: any): void {
    if (activities !== null) {
      if (activities.shortTitle) {
        this.setActivity =
          activities.title + ' (' + activities.shortTitle + ')';
      } else {
        this.setActivity = activities.title;
      }
      this.activityId = activities.id;
      this.shiftsForm.patchValue({
        firstActivity: this.setActivity,
      });
    } else {
      this.activityId = null;
      this.shiftsForm.patchValue({
        firstActivity: this.translate.instant('select'),
      });
    }
  }

  getCompetences(): void {
    this.displayCompetence = !this.displayCompetence;
  }

  choseCompetence(competence: any): void {
    if (competence !== null) {
      this.competenceId = competence.id;
      this.shiftsForm.patchValue({
        competence: competence.title,
      });
    } else {
      this.competenceId = null;
      this.shiftsForm.patchValue({
        competence: this.translate.instant('select'),
      });
    }
  }

  secondActivity(): void {
    this.secondActivities = !this.secondActivities;
  }

  getSecondActivity(activity: any): void {
    if (activity !== null) {
      this.setActivity2Id = activity.id;
      this.shiftsForm.get('secondStartTime').enable();
      this.shiftsForm.get('secondEndTime').enable();
      this.shiftsForm.patchValue({
        secondActivity: activity.title,
      });
    } else {
      this.setActivity2Id = null;
      this.shiftsForm.get('secondStartTime').disable();
      this.shiftsForm.get('secondEndTime').disable();
      this.shiftsForm.patchValue({
        secondActivity: this.translate.instant('select'),
      });
    }
  }

  calculateTimeLimit(type: string): any { //type can be min or max
    if (type == 'min') {
      if (this.shiftsForm.value.firstStartTime < this.shiftsForm.value.firstEndTime) {
        return this.shiftsForm.value.firstStartTime;
      } else {
        return null;
      }
    } else { // max
      if (this.shiftsForm.value.firstStartTime < this.shiftsForm.value.firstEndTime) {
        return this.shiftsForm.value.firstEndTime;
      } else {
        return null;
      }
    }
  }

  createCustomShift(): void {

    let color: string;
    const positionIndex = this.positions.findIndex((p: any) => p.id == this.positionId);
    if (positionIndex != -1) {
      color = this.positions[positionIndex].color;
    }

    if  (this.shiftsForm.value.secondStartTime < this.shiftsForm.value.firstStartTime || this.shiftsForm.value.secondEndTime > this.shiftsForm.value.firstEndTim ) {
      this.snackBar.open(this.translate.instant('selectLimit'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
      return;
    }

    if (this.setActivity2Id != null && (this.shiftsForm.value.secondStartTime == null || this.shiftsForm.value.secondEndTime == null)) {
      this.snackBar.open(this.translate.instant('selectActivityTime'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
      return;
    }


    if (this.shiftsForm.invalid) {
      this.snackBar.open(this.translate.instant('fillAllFields'));
    } else {
      const createCustom = {
        groupId: Number(localStorage.getItem(AuthConst.gruopId)),
        start: this.shiftsForm.value.firstStartTime,
        end: this.shiftsForm.value.firstEndTime,
        start2: this.shiftsForm.value.secondStartTime,
        end2: this.shiftsForm.value.secondEndTime,
        positionId: this.positionId,
        activityId: this.activityId,
        activity2Id: this.setActivity2Id,
        competenceId: this.competenceId,
        undisclosedNote: this.shiftsForm.value.undisclosedNote,
        disclosedNote: this.shiftsForm.value.disclosedNote,
        employerNote: this.shiftsForm.value.undisclosedNote,
        color,
        nrOfAppliedApplications: this.shiftsForm.value.nrOfAppliedApplications,
        name: 'Nytt pass',
      };
      if (
        createCustom.positionId !== null &&
        createCustom.activityId !== null
      ) {
        this.helperService.mode$.next(4);
        setTimeout(() => {
          this.helperService.customShift$.next(createCustom);
          this.helperService.sendShiftToCalendar.next(createCustom);
          this.helperService.showCalendar$.next(true);
        }, 300);
      } else {
        this.snackBar.open(this.translate.instant('fillAllFields'), 'OK', {
          duration: 3000,
          panelClass: ['custom-snack-bar'],
        });
      }
    }
  }

  updateShift(): void {

    if  (this.shiftsForm.value.secondStartTime < this.shiftsForm.value.firstStartTime || this.shiftsForm.value.secondEndTime > this.shiftsForm.value.firstEndTim ) {
      this.snackBar.open(this.translate.instant('selectLimit'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
      return;
    }

    if (this.setActivity2Id != null && (this.shiftsForm.value.secondStartTime == null || this.shiftsForm.value.secondEndTime == null)) {
      this.snackBar.open(this.translate.instant('selectActivityTime'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
      return;
    }


    let selectedPosition = null;
    const positionIndex = this.positions.findIndex(
      (p: any) => p.title == this.shiftsForm.value.position
    );

    if (positionIndex !== -1) {
      selectedPosition = this.positions[positionIndex].id;
    } else {
      selectedPosition = null;
    }

    let selectedActivity1 = null;
    const activity1Index = this.activities.findIndex((a: any) => {
      return a.title.includes(this.shiftsForm.value.firstActivity);
    });
    if (activity1Index !== -1) {
      selectedActivity1 = this.activities[activity1Index];
    }

    let selectedActivity2 = null;
    const activity1Index2 = this.activities.findIndex(
      (a: any) => a.title == this.shiftsForm.value.secondActivity
    );
    if (activity1Index2 !== -1) {
      selectedActivity2 = this.activities[activity1Index2];
    }

    let selectedCompetence = null;
    const competenceIndex = this.competences.findIndex(
      (c: any) => c.title == this.shiftsForm.value.competence
    );
    if (competenceIndex !== -1) {
      selectedCompetence = this.competences[competenceIndex];
    }

    // const date =
    //   new Date(this.assignment.start).getUTCFullYear() +
    //   '-' +
    //   new Date(this.assignment.end).getMonth() +
    //   1 +
    //   '-' +
    //   new Date(this.assignment.start).getDate();

    const date = this.datePipe.transform(
      new Date(this.assignment.date),
      'yyyy-MM-dd'
    );

    const update = {
      id: this.assignment.id,
      date: this.assignment.date,
      start: this.datePipe
        .transform(
          new Date(date + ' ' + this.shiftsForm.value.firstStartTime),
          'yyyy-MM-dd HH:mm Z'
        )
        .toString(),
      end: this.datePipe
        .transform(
          new Date(date + ' ' + this.shiftsForm.value.firstEndTime),
          'yyyy-MM-dd HH:mm Z'
        )
        .toString(),
      positionId: selectedPosition,
      activity1Id: this.activityId,
      competence1Id: selectedCompetence?.id,
      description: this.shiftsForm.value.disclosedNote,
      employerNote: this.shiftsForm.value.undisclosedNote,
      activity2Id: selectedActivity2 ? selectedActivity2.id : null,
      activity2Start: this.shiftsForm.value.secondStartTime
        ? this.datePipe
            .transform(
              new Date(date + ' ' + this.shiftsForm.value.secondStartTime),
              'yyyy-MM-dd HH:mm Z'
            )
            .toString()
        : null,
      activity2End: this.shiftsForm.value.secondEndTime
        ? this.datePipe
            .transform(
              new Date(date + ' ' + this.shiftsForm.value.secondEndTime),
              'yyyy-MM-dd HH:mm Z'
            )
            .toString()
        : null,
    };
    if (update.positionId !== null && update.activity1Id !== null) {
      const confirmDialog = this.dialog.open(CreateShiftTypeModalComponent, {
        disableClose: true,
        width: '290px',
        height: '190px',
        panelClass: 'shiftType',
        position: {
          top: '100px',
        },
        data: {
          updateType: update,
          mode: 'update',
        },
      });
      confirmDialog.afterClosed().subscribe((result) => {
        if (result && result !== false) {
          this.helperService.updateShift$.next(result);
        }
      });
    } else {
      this.snackBar.open(this.translate.instant('fillAllFields'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
    }
  }

  createNewShiftType(): void {


    if  (this.shiftsForm.value.secondStartTime < this.shiftsForm.value.firstStartTime || this.shiftsForm.value.secondEndTime > this.shiftsForm.value.firstEndTim ) {
      this.snackBar.open(this.translate.instant('selectLimit'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
      return;
    }

    if (this.setActivity2Id != null && (this.shiftsForm.value.secondStartTime == null || this.shiftsForm.value.secondEndTime == null)) {
      this.snackBar.open(this.translate.instant('selectActivityTime'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
      return;
    }


    if (this.shiftsForm.invalid) {
      this.snackBar.open(this.translate.instant('fillAllFields'));
    } else {
      const create = {
        name: this.shiftsForm.value.name,
        groupId: Number(localStorage.getItem(AuthConst.gruopId)),
        start: this.shiftsForm.value.firstStartTime,
        end: this.shiftsForm.value.firstEndTime,
        start2: this.shiftsForm.value.secondStartTime,
        end2: this.shiftsForm.value.secondEndTime,
        positionId: this.positionId,
        activityId: this.activityId,
        activity2Id: this.setActivity2Id,
        competenceId: this.competenceId,
        undisclosedNote: this.shiftsForm.value.undisclosedNote,
        disclosedNote: this.shiftsForm.value.disclosedNote,
      };

      if (create.positionId !== null && create.activityId !== null) {
        const confirmDialog = this.dialog.open(CreateShiftTypeModalComponent, {
          disableClose: true,
          width: '290px',
          height: '190px',
          panelClass: 'shiftType',
          position: {
            top: '100px',
          },
          data: {
            createNewShift: create,
            mode: 'create_new_shift',
          },
        });
        confirmDialog.afterClosed().subscribe((result) => {
          this.helperService.newShift$.next(result);
          this.helperService.showCalendar$.next(true);
        });
      } else {
        this.snackBar.open(this.translate.instant('fillAllFields'), 'OK', {
          duration: 3000,
          panelClass: ['custom-snack-bar'],
        });
      }
    }
  }

  editCustomShift(): void {


    if  (this.shiftsForm.value.secondStartTime < this.shiftsForm.value.firstStartTime || this.shiftsForm.value.secondEndTime > this.shiftsForm.value.firstEndTim ) {
      this.snackBar.open(this.translate.instant('selectLimit'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
      return;
    }

    if (this.setActivity2Id != null && (this.shiftsForm.value.secondStartTime == null || this.shiftsForm.value.secondEndTime == null)) {
      this.snackBar.open(this.translate.instant('selectActivityTime'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
      return;
    }


    if (this.shiftsForm.invalid) {
      this.snackBar.open(this.translate.instant('fillAllFields'));
    } else {
      const update = {
        name: this.shiftsForm.value.name,
        groupId: Number(localStorage.getItem(AuthConst.gruopId)),
        start: this.shiftsForm.value.firstStartTime,
        end: this.shiftsForm.value.firstEndTime,
        start2: this.shiftsForm.value.secondStartTime,
        end2: this.shiftsForm.value.secondEndTime,
        positionId: this.positionId,
        activityId: this.activityId,
        activity2Id: this.setActivity2Id,
        competenceId: this.competenceId,
        undisclosedNote: this.shiftsForm.value.undisclosedNote,
        disclosedNote: this.shiftsForm.value.disclosedNote,
        id: this.assignment.id,
      };
      const confirmDialog = this.dialog.open(CreateShiftTypeModalComponent, {
        disableClose: true,
        width: '290px',
        height: '190px',
        panelClass: 'shiftType',
        position: {
          top: '100px',
        },
        data: {
          editType: update,
          edit: this.edit,
        },
      });
      confirmDialog.afterClosed().subscribe((result) => {
        if (result && result !== false) {
          this.helperService.updateShift$.next(result);
          this.helperService.showCalendar$.next(true);
          this.helperService.backToEditShiftPage.next(true);
        }
      });
    }
  }

  clientUpdate(): void {

    if  (this.shiftsForm.value.secondStartTime < this.shiftsForm.value.firstStartTime || this.shiftsForm.value.secondEndTime > this.shiftsForm.value.firstEndTim ) {
      this.snackBar.open(this.translate.instant('selectLimit'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
      return;
    }

    if (this.setActivity2Id != null && (this.shiftsForm.value.secondStartTime == null || this.shiftsForm.value.secondEndTime == null)) {
      this.snackBar.open(this.translate.instant('selectActivityTime'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
      return;
    }


    const clientUpdate = {
      name: 'Nytt pass',
      groupId: Number(localStorage.getItem(AuthConst.gruopId)),
      start: this.shiftsForm.value.firstStartTime,
      end: this.shiftsForm.value.firstEndTime,
      start2: this.shiftsForm.value.secondStartTime,
      end2: this.shiftsForm.value.secondEndTime,
      positionId: this.positionId,
      activityId: this.activityId,
      activity2Id: this.setActivity2Id,
      competenceId: this.competenceId,
      undisclosedNote: this.shiftsForm.value.undisclosedNote,
      disclosedNote: this.shiftsForm.value.disclosedNote,
      id: this.assignment.id,
    };

    if (clientUpdate.competenceId === undefined) {
      clientUpdate.competenceId = null;
    }
    if (clientUpdate.activity2Id === undefined) {
      clientUpdate.activity2Id = null;
    }
    if (clientUpdate.start2 === undefined) {
      clientUpdate.start2 = '';
    }
    if (clientUpdate.end2 === undefined) {
      clientUpdate.end2 = '';
    }

    // const confirmDialog = this.dialog.open(CreateShiftTypeModalComponent, {
    //   disableClose: true,
    //   width: '290px',
    //   height: '190px',
    //   panelClass: 'shiftType',
    //   position: {
    //     top: '100px',
    //   },
    //   data: {
    //     editType: clientUpdate,
    //     mode: 'update-client',
    //   },
    // });
    // confirmDialog.afterClosed().subscribe((result) => {
    const res = {
      activity2Id: clientUpdate.activity2Id,
      activityId: clientUpdate.activityId,
      competenceId: clientUpdate.competenceId,
      disclosedNote: clientUpdate.disclosedNote,
      end: clientUpdate.end,
      end2: clientUpdate.end2,
      groupId: clientUpdate.groupId,
      id: clientUpdate.id,
      name: clientUpdate.name,
      positionId: clientUpdate.positionId,
      start: clientUpdate.start,
      start2: clientUpdate.start2,
      undisclosedNote: clientUpdate.undisclosedNote,
      clientUpdate: true,
    };

    this.helperService.updateShift$.next(res);
    this.helperService.showCalendar$.next(true);
    this.helperService.updateClient(res);
  }

  sortPositions(): any[] {
    const sortedPositions = this.positions.sort((a: any, b: any) => {
      return a.title.localeCompare(b.title);
    });
    return sortedPositions;
  }

  sortActivities(): any[] {
    const sortedActivites = this.activities.sort((a: any, b: any) => {
      return a.title.localeCompare(b.title);
    });
    return sortedActivites;
  }

  sortCompetences(): any[] {
    const sortedCompetences = this.competences.sort((a: any, b: any) => {
      return a.title.localeCompare(b.title);
    });
    return sortedCompetences;
  }

  closeModal(): void {
    this.close.emit();
  }
}
