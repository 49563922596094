import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShiftsService } from '../shared/services/shifts.service';
import { HelperService } from '../shared/services/helper.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SendMessageComponent } from '../send-message/send-message.component';
import { DeleteGroupComponent } from '../delete-group/delete-group.component';
import { UpdateGroupComponent } from '../update-group/update-group.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent implements OnInit, OnDestroy {
  constructor(
    private shiftsService: ShiftsService,
    private helper: HelperService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  groupId: any = localStorage.getItem('gruopId');
  allGroups: any = [];
  selectAllGroups = false;
  private selectAll: number[] = [];
  allChecks: Array<any> = [];
  openType = false;
  openTypeAction = false;
  firstSortName: any = 'name';
  firstActionName: any = 'select_action';
  checkSelect = 1;
  page = 0;
  total = 50;
  // tslint:disable-next-line:max-line-length
  sort: any = [
    { id: 2, name: 'number of staff' },
    { id: 1, name: 'name' },
  ];
  disabledFail: any = 0;
  // sort: any = [
  //   { id: 1, name: 'name' },
  //   { id: 2, name: 'number of staff' },
  //   { id: 3, name: 'description' },
  // ];
  actions: any = [
    { id: 3, name: 'delete group' },
    // Hide this option for now
    // { id: 1, name: 'send message' },

  ];
  newGroup: any = null;

  groupValueSubscription: Subscription;

  ngOnInit(): void {
    this.groupId = Number(localStorage.getItem('gruopId'));
    this.shiftsService.getAllGroup(this.groupId).subscribe((response: any) => {
      this.allGroups = response;
      this.sortValues(1);
      // this.allGroups.forEach((g: any) => {
      //     this.shiftsService.groupPeronals({id: g.id}, g.groupId).subscribe(async (data: any[]) => {
      //       g.userCount = data.length;
      //       await new Promise((resolve) => setTimeout(resolve, 200));
      //     });
      // });
    });

    this.groupValueSubscription = this.helper.groupValue$.subscribe(
      (response: any) => {
        this.newGroup = null;
        this.newGroup = {
          id: response.id,
          groupId: response.groupId,
          description: response.description,
          name: response.name,
          userCount: 0,
        };
        this.allGroups.push(this.newGroup);
      }
    );
    setTimeout(() => {
      this.allGroups.sort((a: any, b: any) => a.name.localeCompare(b.name));
    }, 100);
  }

  getLastPage() {
    return Math.ceil(this.total / 50);
  }

  openPage(page: any): void {

  }

  setAll(): void {
    this.disabledFail = 0;
    this.selectAllGroups = !this.selectAllGroups;
    if (this.selectAllGroups) {
      this.allGroups.forEach((group: any) => {
        // @ts-ignore
        this.selectAll.push(group.id);
      });
      this.allChecks = this.selectAll;
    } else {
      this.selectAll.splice(0);
      this.allChecks = [];
    }

    for (const check of this.allChecks ){
      for (const group of this.allGroups){
        if (group.id == check && group.isDefault == 1){
          this.disabledFail++;
        }
      }
    }
  }

  selection(groupId: number): void {
    this.disabledFail = 0;
    this.allChecks = [...this.allChecks];
    if (this.allChecks.includes(groupId)) {
      this.allChecks = this.allChecks.filter((e) => e !== groupId);
      return;
    }
    this.allChecks.push(groupId);

    for (const check of this.allChecks ){
      for (const group of this.allGroups){
        if (group.id == check && group.isDefault == 1){
            this.disabledFail++;
        }
      }
    }
  }

  sortValues(id: any): any {
    // tslint:disable-next-line:no-conditional-assignment
    if (id == 1) {
      this.allGroups.sort((a: any, b: any) => a.name.localeCompare(b.name));
      this.checkSelect = 1;
    }
    // tslint:disable-next-line:no-conditional-assignment
    else if (id == 2) {
      this.allGroups.sort((a: any, b: any) =>
        a.userCount.toString().localeCompare(b.userCount.toString())
      );
      this.checkSelect = 2;
    }
    // tslint:disable-next-line:no-conditional-assignment
    else if (id == 3) {
      this.allGroups.sort((a: any, b: any) =>
        a.description.localeCompare(b.description)
      );
      this.checkSelect = 3;
    }

    for (const s of this.sort) {
      if (s.id == id) {
        this.firstSortName = s.name;
      }
    }
    this.openType = false;
  }

  sortActionValues(id: any): any {
    // tslint:disable-next-line:no-conditional-assignment
    if (id == 1) {
      // // @ts-ignore
      // @ts-ignore
      const confirmDialog = this.dialog.open(SendMessageComponent, {
        disableClose: true,
        position: {top: '100px'},

        // width: '290px',
        // height: '240px',
        // position: {
        //   top: '150px',
        // },
        panelClass: 'custom-dialog-container-message',
      });
      confirmDialog.afterClosed().subscribe((result) => {
        this.firstActionName = 'select_action';
        if (result) {
          this.allChecks = [];
        }
      });
    }
    // tslint:disable-next-line:no-conditional-assignment
    else if (id == 2) {
    }
    // tslint:disable-next-line:no-conditional-assignment
    else if (id == 3 ) {
      if (this.disabledFail == 0){
      // @ts-ignore
      const confirmDialog = this.dialog.open(DeleteGroupComponent, {
        disableClose: true,
        position: {top: '100px'},
        // width: '290px',
        // height: '190px',
        // border: '0.5px solid #DDDDDD',
        // position: {
        //   top: '150px',
        // },
        panelClass: 'custom-dialog-container',
      });
      confirmDialog.afterClosed().subscribe((result) => {
        this.firstActionName= 'select_action';
        if (result) {
          for (const g of this.allChecks) {
            const convertToObjectForDelete = { id: g };
            this.shiftsService
              .deleteGroup(convertToObjectForDelete)
              .subscribe(() => {
                this.allGroups = this.allGroups.filter((item: any): any => {
                  return item.id !== convertToObjectForDelete.id;
                });
              });
          }
          this.snackBar.open(this.translate.instant('deleted'), 'OK', {
            duration: 3000,
            panelClass: ['custom-snack-bar'],
          });

          setTimeout(() => {
            this.allChecks = [];
            this.firstActionName= 'select_action';
          });

        }
      });
      this.openTypeAction = false;
      for (const a of this.actions) {
          if (a.id == id) {
            this.firstActionName = a.name;
          }
        }


    }else{
        this.openTypeAction = true;
        this.firstActionName = 'select_action';
      }
    }


  }

  updateOneUser(object: any): any {
    if (object.isDefault == 0){
    // @ts-ignore
    const confirmDialog = this.dialog.open(UpdateGroupComponent, {
      disableClose: true,

      data: { objectGroup: object },
      position: {
        top: '100px'
      },
      // width: '290px',
      // height: '190px',
      // border: '0.5px solid #DDDDDD',
      // position: {
      //   top: '150px',
      // },
      panelClass: 'custom-dialog-container',
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.shiftsService.updateGroup(result.objectGroup).subscribe(() => {
          this.snackBar.open(this.translate.instant('success'), 'OK', {
            duration: 3000,
            panelClass: ['custom-snack-bar'],
          });
        });
      }
    });
  }}

  getPersonalofGroup(group: any): any {
    this.helper.group.next(group);
  }

  ngOnDestroy(): void {
    this.groupValueSubscription?.unsubscribe();
  }
}
