import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {EmployeesService} from "../../../shared/services/employees.service";
import {UserService} from "../../../shared/services/user.service";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  checked = false;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public employeesService: EmployeesService,
    public userService: UserService
  ) {}

  ngOnInit(): void {}

  closeModal(): void {
    this.dialogRef.close(false);
  }

  saveAndUpdate(): void {
    this.loading = true;
    if (!this.data.isCreateUser || this.data.isCreateUser == false) {
      if (this.checked) {
        this.data.user.rowStatus = 1;
      }
      this.employeesService.updateUser(this.data.user).subscribe((user: any) => {
        if (this.checked) {
          this.userService.sendLoginInformation(this.data.user).subscribe();
        }
        this.loading = false;
        this.dialogRef.close(user);
      }, (err) => {
        this.loading = false;
      });

    } else {
      this.dialogRef.close({checked: this.checked});
    }

  }

  checkBox(): void {
    this.checked = !this.checked;
  }
}
