<div class="details scrollable">
  <div class="section-header">
    <div class="section-header__title-box">
      <div class="section-header__title">
        {{firstName}} {{lastName}}
      </div>
    </div>
    <div class="section-header__button-box">
      <button class="button back-button button--primary header__button"
              (click)="goBack()">{{'backButton' | translate}}</button>
    </div>
  </div>
  <div class="inner-container">
  <app-user-details *ngIf="selectedShifts.length == 0 && !shiftView"></app-user-details>
    <div class="shifts-list" *ngIf="selectedShifts.length > 0">
      <div class="shifts-list-title">
        {{'selectedShifts' | translate}}
      </div>
      <div class="shifts-subtitle">
        {{'bulkShiftSubtitle' | translate}}
      </div>
      <div class="shifts-minititle">
        {{'bulkShiftMinititle' | translate}}
      </div>

      <div class="section-select__select-box">
        <input class="section-select__select" (click)="showActions = true"
               (clickOutside)="showActions = false"
               readonly
               placeholder="{{'selectAction' | translate}}"
        />
        <div class="dropdown" style="max-height: 125px;" *ngIf="showActions">
          <div class="option" *ngFor="let action of actions"
               (click)="selectAction(action)">
            {{action.name | translate}}
          </div>
        </div>
      </div>

      <div class="shifts-only">
        <div class="shift" *ngFor="let s of selectedShifts">
          <div class="for-circle-and-activity">
            <div class="shift-circle" [style.backgroundColor]="s.positionColor"></div>
            <div class="shift-activity">{{s.activity1Title}}</div>
          </div>
          <div class="time">
            {{formatTime(s.start)}} - {{formatTime(s.end)}}
          </div>
          <div class="date">
            {{formatDate(s.start)}}
          </div>
          <mat-icon class="close" (click)="remove(s)">close</mat-icon>
        </div>
      </div>
      <div class="shift-button">
        <button style="width:100px" (click)="cancel()" class="button button--primary">{{'cancelButton' | translate}}</button>
      </div>
    </div>
    <app-create-new-shift-details [customTwo]="'realShift'" [assignment]="shift" *ngIf="shiftView && selectedShifts.length == 0"></app-create-new-shift-details>
    <app-user-calendar-only style="margin-top: 20px"></app-user-calendar-only>
  </div>
</div>
