import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {shareReplay, map} from 'rxjs/operators';
import {Employees} from '../models/employees.model';
import {User} from '../models/user.model';
import {DatePipe} from "@angular/common";

@Injectable({
  providedIn: 'root',
})
export class EmployeesService {
  constructor(private http: HttpClient, private datePipe: DatePipe) {
  }

  apiUrl: string = environment.apiURL;

  /**
   *  Get all employees
   */
  getEmployees(groupId: number): Observable<Employees[]> {
    return this.http.get<Employees[]>(`${this.apiUrl}/user/group/` + groupId + '/employees').pipe(
      map((employees) => employees),
      shareReplay(1)
    );
  }

  /**
   *  Get all active employees
   */
  getEmployeesActive(groupId: number): Observable<Employees[]> {
    return this.http.get<Employees[]>(`${this.apiUrl}/user/group/` + groupId + '/employees/active').pipe(
      map((employees) => employees),
      shareReplay(1)
    );
  }

  /**
   *  Get positions of user
   */
  userPositions(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/positions/active`).pipe(
      map((position) => position),
      shareReplay(1)
    );
  }

  /**
   *  Get competences of user
   */
  userCompetences(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/competences/active`).pipe(
      map((position) => position),
      shareReplay(1)
    );
  }

  /**
   *  Get Contracts of user
   */
  userContracts(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/contracts/active`).pipe(
      map((position) => position),
      shareReplay(1)
    );
  }

  /**
   *  Get Activities of user
   */
  userActvity(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/activities/active`).pipe(
      map((activity) => activity),
      shareReplay(1)
    );
  }

  /**
   *  Update user
   */
  updateUser(update: {
    competence?: string;
    firstName?: string;
    contract?: string;
    tel1?: any;
    roles?: any;
    textarea?: string;
    position?: string;
    id?: number | undefined;
    email?: string;
    lastName?: string;
    status?: string;
  }): Observable<any> {
    return this.http
      .post<User>(`${this.apiUrl}/user/update`, update)
      .pipe(shareReplay(1));
  }

  /**
   *  Create user
   */
  createUser(create: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrl}/user/create`, create)
      .pipe(shareReplay(1));
  }

  createExternalUser(create: any, asssignemntId: number): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrl}/external-staff/assignment/` + asssignemntId, create)
      .pipe(shareReplay(1));
  }

  /**
   *  Get Assignment
   */
  assignment(date: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/assignments/start/${date}/end/${date}`
      )
      .pipe(
        map((assignment) => assignment),
        shareReplay(1)
      );
  }

  userMonthAssignments(data: any): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/assignments/employee/from/${data.start}/to/${data.end}?userId=${data.userId}`
      )
      .pipe(
        shareReplay(1)
      );
  }


  /**
   *  Get Assignments
   */
  getAssignments(start: string, end: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/assignments/start/${start}/end/${end}`
      )
      .pipe(
        map((assignment) => assignment),
        shareReplay(1)
      );
  }


  /**
   *  Get Assignment
   */
  assignments(start: string, end: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/assignments/start/${start}/end/${end}`
      )
      .pipe(
        map((assignment) => assignment),
        shareReplay(1)
      );
  }

  getUnavailableEmployees(groupId: number, date: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/user/group/` + groupId + '/date/' + date + '/unavailable-employees')
      .pipe(
        map((unavailable) => unavailable),
        shareReplay(1)
      );
  }

  getMessageThreadId(groupId: number, userId: number): any {
    return this.http
      .get(this.apiUrl + '/messages/group/' + groupId + '/user/' + userId + '/thread').pipe(shareReplay(1));
  }

  resetPassword(data: any): any {
    return this.http
      .post(this.apiUrl + '/user/send/passwordResetRequest', data).pipe(shareReplay(1));
  }

  findAvailability(id: any): any {
    return this.http.get(this.apiUrl + '/get/availability/' + id).pipe(shareReplay(1));
  }

  getImage(url: string): Observable<any> {
    return this.http
      .get(url, {responseType: 'blob'})
      .pipe(
        map((unavailable) => unavailable),
        shareReplay(1)
      );
  }


  getEmployeeSchedule(userId: number, start: any, dayNumber: any): any {
    return this.http.get(this.apiUrl + "/api/v3/employer/assignment/user/" + userId + "/from/" + start + "/days/" + dayNumber).pipe(shareReplay(1));
  }

  getEmployeeAbsences(groupId: any, start: any, end: any, userId: any): any {
    start = this.datePipe.transform(start, 'yyyy-MM-dd').toString();
    end = this.datePipe.transform(end, 'yyyy-MM-dd').toString();
    return this.http.get(this.apiUrl + "/unavailable/group/" + groupId + "/user/" + userId + "/start/" + start + "/end/" + end + "/get").pipe(shareReplay(1));
  }

}
