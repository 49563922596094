<div class="colorForBackground">
  <div class="padding-for-dialog">

    <p class="send_group">{{'remove user' | translate}}</p>
    <p class="add_text">
      {{"attention" | translate}}!<br>
      <span class="text_after">{{'removeText' | translate}}</span>
    </p>

    <div class="groups_buttons">

      <button class="group_cancel btn-nofocus" (click)="cancel()">{{"cancelButton" | translate}}</button>
      <button class="delete_group btn-nofocus" (click)="deleteFunction()" >{{"remove" | translate}}</button>
    </div>
  </div>
</div>
