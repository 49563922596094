
<app-loading class="spinner" style="position: absolute; top:50%; left:50%;" *ngIf="!loading"></app-loading>

<div class="personal-main" *ngIf="loading">
  <table class="table table-bordered" style="width: 100%">
    <thead>
    <tr>
      <th scope="col" class="first_th"></th>
      <th scope="col">Mån</th>
      <th scope="col">Tis</th>
      <th scope="col">Ons</th>
      <th scope="col">Tor</th>
      <th scope="col">Fre</th>
      <th scope="col">Lör</th>
      <th scope="col">Sön</th>
    </tr>
    </thead>
    <tbody>

    </tbody>
  </table>

  <div class="wraper">
    <div>
      <div class="component-small" *ngFor="let week of weekArray" [style.height.px]="weekHeight">
        <p>v.{{week}}</p>
      </div>
    </div>

    <div class="wrap" #boxHeight>
      <div *ngFor="let date of dateTime ; index as i" class="component" (click)="getDateForSchedule(date.date)"
           [ngClass]="{'border_day': date.date.getMonth() == saveMyDate.getMonth() && date.date.getDate() == saveMyDate.getDate() &&
     date.date.getFullYear() == saveMyDate.getFullYear() && hover == false,
     'border_two' :  date.date.getMonth() != saveMyDate.getMonth() || date.date.getDate() != saveMyDate.getDate() ||
     date.date.getFullYear() != saveMyDate.getFullYear()} ">

        <div class="number"
             *ngIf="date.date.getMonth() == withNewDate.getMonth() && date.date.getDate() == withNewDate.getDate() && date.date.getFullYear() == withNewDate.getFullYear()">
          <div *ngFor="let t of announcementsValue" class="announcements_val_first"
               [ngStyle]="{'display':t.date.substring(0,4) != date.date.getFullYear() || t.date.substring(5,7) != date.date.getMonth() + 1 ||   t.date.substring(8,10) != date.date.getDate() ? 'none' : 'block' }">
            <p class="announcements_val"
               *ngIf="t.date.substring(0,4) == date.date.getFullYear() && t.date.substring(5,7) == date.date.getMonth() + 1 &&   t.date.substring(8,10) == date.date.getDate()">{{t.text}}</p>
          </div>
          <div style="height: 24px; width: 24px; position: relative;">
            <div class="black_back"
                 style="color:#ffffff;">
              <div class="month-day-black">{{date.date.getDate()}}</div>
            </div>
          </div>
        </div>
        <div class="number"
             *ngIf="date.date.getMonth() != withNewDate.getMonth() || date.date.getDate() != withNewDate.getDate() || date.date.getFullYear() != withNewDate.getFullYear()">
          <div *ngFor="let t of announcementsValue" class="announcements_val_first"
               [ngStyle]="{'display':t.date.substring(0,4) != date.date.getFullYear() || t.date.substring(5,7) != date.date.getMonth() + 1 ||   t.date.substring(8,10) != date.date.getDate() ? 'none' : 'block' }">
            <p class="announcements_val"
               *ngIf="t.date.substring(0,4) == date.date.getFullYear() && t.date.substring(5,7) == date.date.getMonth() + 1 &&   t.date.substring(8,10) == date.date.getDate()">{{t.text}}</p>
          </div>
          <div style="height: 24px; width: 24px; position: relative;">
            <div class="white_back " [ngClass]="{'silver' : date.date.getMonth() != correctMonth }">{{date.date.getDate()}}</div>
          </div>

        </div>

        <div style="margin-top:12px;">
          <div class="specific_table" style="position: absolute; bottom: 2px;
  left: 9px;">
            <table class="try">
              <thead></thead>
              <tbody>
              <tr *ngFor="let position of date.positions; index as c">
                <td class="position_name">{{position.shortTitle}}</td>
                <td style="padding:0px !important; margin-left: 2px; margin-top: 2px" class="for-numbers">
                      <div *ngFor="let goal of position.goals" class="number_box">
             <span class="nerad"
                   [style.backgroundColor]="goal.colorBackground"
                   [style.borderColor]="goal.colorBackground"
                   [style.color]="goal.colorNumber">
               {{goal.open}}
             </span>
                        <span class="rad"
                              [style.backgroundColor]="goal.colorBackground"
                              [style.borderColor]="goal.colorBackground"
                              [style.color]="goal.colorNumber" style="border-radius: 50%;">
                          {{goal.taken}}
                        </span>
                      </div>
                </td>
              </tr>

              </tbody>
            </table>
          </div>
        </div>


      </div>
    </div>

  </div>
</div>
