import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-wrong-role-pop-up',
  templateUrl: './wrong-role-pop-up.component.html',
  styleUrls: ['./wrong-role-pop-up.component.scss']
})
export class WrongRolePopUpComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<WrongRolePopUpComponent>
  ) {}
  ngOnInit(): void {
  }

}
