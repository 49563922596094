<div class="confirm-dialog">
  <img class="confirm-dialog__image" src="../../../../assets/images/Close.png" (click)="closeModal()"/>
  <span class="confirm-dialog__title">{{ data.title }}</span>
  <div>
    <div class="confirm-dialog__subtitle">{{ data.subtitle }}</div>
    <div class="confirm-dialog__text">{{ data.message }}</div>
  </div>
  <div style="display: flex; align-items: center;" *ngIf="data.showCheckbox">
    <div class='custom-checkBox' *ngIf="!checked" (click)="checkBox()"></div>
    <img src="../../../../assets/images/Checked%20box.svg" class='custom-checkBox custom-checkBox--border'
         *ngIf="checked"
         (click)="checkBox()"/>
    <div class="custom-checkBox__subtitle">{{ data.subtitle }}</div>
  </div>
  <div class="confirm-dialog__modal-box">
    <button class="button button--primary" style="margin-right: 10px; width: 100px"
            (click)="closeModal()">{{'declined' | translate}}
    </button>
    <button class="button button--blue" style="width: 100px" *ngIf="!loading"
            (click)="saveAndUpdate()">{{'confirmed' | translate}}</button>
    <button class="button button--blue loader-button" style="width: 100px" *ngIf="loading"
            > <app-mini-loader class="miniLoader"></app-mini-loader></button>
  </div>
</div>
