import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PersonalComponent } from './components/personal/personal.component';
import { LoginComponent } from './components/login/login.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { MessagesComponent } from './components/messages/messages.component';
import { RecipientsComponent } from './components/recipients/recipients.component';
import { MainComponent } from './components/main/main.component';
import { ActivityLogsComponent } from './components/activityLogs/activity-logs/activity-logs.component';
import { ActivityLogsListComponent } from './components/activityLogs/activity-logs-list/activity-logs-list.component';

import { ScheduleComponent } from './components/schedule/schedule.component';
import { CreateNewUserComponent } from './components/create-new-user/create-new-user.component';
import { ChatModalComponent } from './components/modals/chat-modal/chat-modal.component';
import { CreateNewShiftComponent } from './components/create-new-shift/create-new-shift.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CalendarComponent } from './calendar/calendar.component';
import {UserDatailsContainerComponent} from './components/user-datails-container/user-datails-container.component';
import {ShiftDetailsComponent} from "./components/shift-details/shift-details.component";
import {ForgottenPasswordComponent} from "./forgotten-password/forgotten-password.component";

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'personal',
        component: PersonalComponent,
      },
      {
        path: 'activity',
        component: ActivityLogsComponent,
        children: [
          {
            path: '',
            component: ActivityLogsListComponent,
          },
        ],
      },
      {
        path: 'personal/user/:id',
        component: UserDatailsContainerComponent,
      },
      {
        path: 'personal/new/user',
        component: CreateNewUserComponent,
      },
      {
        path: 'personal/user/:id/:update',
        component: UserDetailsComponent,
      },
      {
        path: 'conversations',
        component: MessagesComponent,
      },

      {
        path: 'conversations/create',
        component: RecipientsComponent,
      },
      {
        path: 'schedule',
        component: ScheduleComponent,
      },
      {
        path: 'schedule-backup/:date/:jwt/:groupId',
        component: ScheduleComponent,
      },
      {
        path: 'schedule-backup/:date',
        component: ScheduleComponent,
      },
      {
        path: 'calendar',
        component: CalendarComponent,
      },
      {
        path: 'create-shift',
        component: CreateNewShiftComponent,
      },
      {
        path: 'create-new-user',
        component: CreateNewUserComponent,
      },
      {
        path: '',
        redirectTo: 'schedule',
        pathMatch: 'prefix',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },

      {
        path: 'chat',
        component: ChatModalComponent,
      },
      {
        path: 'assignment/details',
        component: ShiftDetailsComponent,
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgotten-password',
    component: ForgottenPasswordComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
