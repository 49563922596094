import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'activityDate'})
export class ActivityDatePipe implements PipeTransform {
  transform(date: any, args?: any): any {
    const d = new Date(date.replace(/-/g, '/'));
    return moment(d).format('DD MMMM, HH:mm');
  }
}
