<div class="details">
  <div class="header">
    <div class="header__title-box">
      <div class="header__title">
        {{firstName}} {{lastName}}
      </div>
    </div>
    <div class="header__button-box">
      <button class="button button--primary header__button"
              [routerLink]="['../../../']">{{'backButton' | translate}}</button>

    </div>
  </div>
  <div class="main-details">
    <div class="main-details__title">{{'userProfile' | translate}}</div>
    <div class="main-details__user-detail">
      <div class="main-details__left-detail">
        <div class="main-details__image-box main-details__image-box--margin">
          <img class="main-details__image" src="../assets/images/profile_white.svg"/>
        </div>
        <div class="main-details__text-box">
          <div class="main-details__name">{{firstName}} {{lastName}}</div>
          <div class="main-details__sub">sub</div>
        </div>
      </div>
    </div>
    <form (ngSubmit)="create()" [formGroup]="updateEmployer">
      <div class="form">
        <div class="main-details__image-box main-details__image-box--margin">
          <div class="main-details__info-text">{{'infoAboutUserProfile' | translate}}</div>
          <img class="main-details__image-info" src="../assets/images/Info symbol.svg"/>
        </div>
        <div class="form__boxes">
          <div class="form__inputs-boxes">
            <div class="form__inputs-box">
              <label class="form__label">{{'firstName' | translate}}</label>
              <input class="input form__input" type="text" formControlName="firstname">
            </div>
          </div>
          <div class="form__inputs-boxes">
            <div class="form__inputs-box">
              <label class="form__label-down">{{'email' | translate}}</label>
              <input class="input form__input" type="text" formControlName="email"
                     placeholder="{{'email' | translate}}">
            </div>
          </div>
          <div class="form__inputs-boxes form__inputs-boxes--end">
            <div class="form__inputs-box">
              <label class="form__label">{{'lastName' | translate}}</label>
              <input class="input form__input" type="text" formControlName="lastname">
            </div>
          </div>
          <div class="form__inputs-boxes form__inputs-boxes--end">
            <div class="form__inputs-box">
              <label class="form__label-down">{{'Mobil' | translate}}</label>
              <input class="input form__input" type="text" formControlName="mobile">
            </div>
          </div>
        </div>
      </div>
      <div class="form">
        <div class="main-details__image-box">
          <div class="main-details__info-text">{{'userQualifications' |translate}}</div>
          <img class="main-details__image-info" src="../assets/images/Info symbol.svg"/>
        </div>
        <div class="form__boxes">
          <div class="form__inputs-boxes">
            <div class="form__inputs-box">
              <label class="form__label">{{'position' | translate}}</label>
              <input class="input form__input form__input--arrow" (click)="displayPositions()"
                     (clickOutside)="showPositions = false"
                     formControlName="position"
                     placeholder="{{'position' | translate}}"
                     readonly
              />
              <div class="form__select form__select--positions" *ngIf="showPositions">
                <div class="form__select-option" *ngFor="let position of positions$ | async"
                     (click)="getPosition(position.title)">
                  {{position.title}}
                </div>
              </div>
            </div>
          </div>
          <div class="form__inputs-boxes">
            <div class="form__inputs-box">
              <label class="form__label-down form__label-down--down">{{'competence' | translate}}</label>
              <input class="input form__input form__input--arrow" (click)="displayCompetence()"
                     (clickOutside)="showCompetence = false"
                     formControlName="competence"
                     placeholder="{{'competence' | translate}}"
                     readonly
              />
              <div class="form__select form__select--competence" *ngIf="showCompetence">
                <div class="form__select-option" *ngFor="let competence of competences$ | async"
                     (click)="getCompetence(competence.title)">
                  {{competence.title}}
                </div>
              </div>
            </div>
          </div>
          <div class="form__inputs-boxes form__inputs-boxes--textArea">
            <label class="form__label form__label--padding">{{'notes' | translate}}</label>
            <div class="form__inputs-boxes form__inputs-boxes--end">
              <div class="form__inputs-box">
                  <textarea class="form__text-area" formControlName="note"
                            placeholder="{{'notes' | translate}}"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form">
        <div class="main-details__image-box">
          <div class="main-details__info-text">{{'userDepartmentSettings' |translate}}</div>
          <img class="main-details__image-info" src="../assets/images/Info symbol.svg"/>
        </div>
        <div class="form__boxes">
          <div class="form__inputs-boxes">
            <div class="form__inputs-box">
              <label class="form__label">{{'contract' | translate}}</label>
              <input class="input form__input form__input--arrow" (click)="displayContract()"
                     (clickOutside)="showContract = false"
                     formControlName="contract"
                     placeholder="{{'contract' | translate}}"
                     readonly
              />
              <div class="form__select form__select--positions" *ngIf="showContract">
                <div class="form__select-option" *ngFor="let contract of contracts$ | async"
                     (click)="getContract(contract.title)">
                  {{contract.title}}
                </div>
              </div>
            </div>
          </div>
          <div class="form__inputs-boxes">
            <div class="form__inputs-box">
              <label class="form__label-down">{{'roll' | translate}}</label>
              <input class="input form__input form__input--arrow" (click)="displayRoll()"
                     (clickOutside)="showRoll = false"
                     formControlName="role"
                     placeholder="{{'roll' | translate}}"
                     readonly
              />
              <div class="form__select form__select--roll" *ngIf="showRoll">
                <div class="form__select-option" *ngFor="let roll of rolls" (click)="getRoll(roll)">
                  {{roll.roll}}
                </div>
              </div>
            </div>
          </div>
          <div class="form__inputs-boxes form__inputs-boxes--end">
            <div class="form__inputs-box">
              <label class="form__label">{{'status' | translate}}</label>
              <input class="input form__input form__input--arrow" (click)="displayStatus()"
                     (clickOutside)="showStatus = false"
                     formControlName="status"
                     readonly
              />
              <div class="form__select form__select--status" *ngIf="showStatus">
                <div class="form__select-option" *ngFor="let status of statusSelect" (click)="getStatus(status)">
                  {{status.status}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-box">
        <div class="form__button-box">
          <button style="width: 100px; margin-right: 10px; " type="button" class="button button--primary"
                  [routerLink]="['../../../user/', userId]">{{'cancelButton' | translate}}
          </button>
        </div>
        <div class="form__button-box">
          <button style="width: 100px" type="submit" class="button button--blue">{{'saveButton' | translate}}</button>
        </div>
      </div>
    </form>
  </div>
</div>
