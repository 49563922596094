import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-removed-personal-from-group',
  templateUrl: './removed-personal-from-group.component.html',
  styleUrls: ['./removed-personal-from-group.component.scss']
})
export class RemovedPersonalFromGroupComponent implements OnInit {
  remove = false;

  constructor( private dialog: MatDialog,
               private dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
  }
  cancel(): void {
    this.dialogRef.close();
  }

  deleteFunction(): any {
    this.remove = true;
    this.dialogRef.close(this.remove);
  }
}
