<section class="copy-dialog">
  <img class="copy-dialog__image" src="../../../../assets/images/Close.png" (click)="closeModal()"/>
  <div class="copy-dialog__title" *ngIf="data.mode === 'create_new_shift'">{{ 'CreateNewShiftType' | translate }}</div>
  <div class="copy-dialog__message"
       *ngIf="data.mode === 'create_new_shift'">{{ 'CreateNewShiftTypeText' | translate }}</div>
  <div class="copy-dialog__title" *ngIf="data.mode !== 'create_new_shift'">{{ 'saveChanges' | translate }}</div>
  <div class="copy-dialog__message" *ngIf="data.mode !== 'create_new_shift'">{{ 'saveChangesText' | translate }}</div>
  <div class="copy-dialog__button-box">
    <button class="button button--primary" style="margin-right: 10px; width: 100px"
            (click)="closeModal()">{{'cancelButton' | translate}}
    </button>
    <button class="button button--blue" style="width: 100px" *ngIf="!create"
            (click)="editShiftType()">{{'confirmed' | translate}}</button>
    <button class="button button--blue" style="width: 100px" *ngIf="create"
            (click)="createShiftType()">{{'confirmed' | translate}}</button>
    <!--    <button class="button button&#45;&#45;blue" style="width: 100px" *ngIf="clientUpdate"-->
    <!--            (click)="editClient()">{{'confirmed' | translate}}</button>-->
  </div>
</section>
