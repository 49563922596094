<section class="custom" [ngStyle]="{'height': edit === 'edit' || custom ? '521px' : '580px' }">
  <div class="custom__profile">{{'shiftDetails' | translate}}</div>
  <form class="shift-modal" [formGroup]="shiftsForm">
    <div *ngIf="!custom && hideName" class="shift-modal__input-section">
      <label class="shift-modal__label">{{'name' | translate}}</label>
      <input class="input shift-modal__input" formControlName="name" [readonly]="disabledName"/>
    </div>
    <div class="shift-modal__input-section shift-modal__input-section--flex">
      <div class="shift-modal__input-section">
        <label class="shift-modal__label">{{'start' | translate}}</label>
        <input class="input shift-modal__date shift-modal__date--margin"
               formControlName="firstStartTime" placeholder="- - : - -" [format]=24 [ngxTimepicker]="pickerStart"
               readonly/>
        <ngx-material-timepicker #pickerStart [enableKeyboardInput]=true></ngx-material-timepicker>
      </div>
      <div class="shift-modal__input-section">
        <label class="shift-modal__label">{{'end' | translate}}</label>
        <input class="input shift-modal__date" formControlName="firstEndTime" placeholder="- - : - -"
               [format]=24 [ngxTimepicker]="pickerEnd" readonly/>
        <ngx-material-timepicker #pickerEnd [enableKeyboardInput]=true></ngx-material-timepicker>
      </div>
    </div>
    <div class="shift-modal__input-section">
      <label class="shift-modal__label">{{'position' | translate}}</label>
      <input class="input shift-modal__input shift-modal__input--arrow" formControlName="position"
             (click)="getPositions()"
             (clickOutside)="displayPositions = false"
             [ngStyle]="positionId === null ? {'color': '#929090'} : {'color': '#333333'}"
             placeholder="{{'select' | translate}}"/>
      <div class="shift-modal__dropdown" *ngIf="displayPositions">
        <div class="shift-modal__option" (click)="chosePosition(null)"
        >{{'select' | translate}}</div>

        <div class="shift-modal__option" *ngFor="let position of sortPositions()"
             (click)="chosePosition(position)">
          <div>{{position?.title}}</div>
          <img *ngIf="position?.id === positionId" class="shift-modal__check-mark"
               src="../../../assets/images/Checkmark.svg" alt=""/>
        </div>
      </div>
    </div>
    <div class="shift-modal__input-section">
      <label class="shift-modal__label">{{'activity' | translate}}</label>
      <input class="input shift-modal__input shift-modal__input--arrow" formControlName="firstActivity"
             (click)="getActivities()"
             (clickOutside)="displayActivities = false"
             [ngStyle]="activityId === null ? {'color': '#929090'} : {'color': '#333333'}"
             placeholder="{{'select' | translate}}"/>
      <div class="shift-modal__dropdown" *ngIf="displayActivities">
        <div class="shift-modal__option" (click)="choseActivity(null)"
        >{{'select' | translate}}</div>
        <div class="shift-modal__option" *ngFor="let activity of sortActivities()"
             (click)="choseActivity(activity)">
          <div>{{activity?.title}} ({{(activity?.shortTitle)}})</div>
          <img *ngIf="activity?.id === activityId" class="shift-modal__check-mark"
               src="../../../assets/images/Checkmark.svg" alt=""/>
        </div>
      </div>
    </div>
    <div class="shift-modal__input-section">
      <label class="shift-modal__label">{{'competence' | translate}}</label>
      <input class="input shift-modal__input shift-modal__input--arrow" formControlName="competence"
             (click)="getCompetences()"
             (clickOutside)="displayCompetence = false"
             [ngStyle]="competenceId === null ? {'color': '#929090'} : {'color': '#333333'}"
             placeholder="{{'select' | translate}}"/>
      <div class="shift-modal__dropdown" *ngIf="displayCompetence">
        <div class="shift-modal__option" (click)="choseCompetence(null)"
        >{{'select' | translate}}</div>
        <div class="shift-modal__option" *ngFor="let competences of sortCompetences()"
             (click)="choseCompetence(competences)">
          <div>{{competences?.title}}</div>
          <img *ngIf="competences?.id === competenceId" class="shift-modal__check-mark"
               src="../../../assets/images/Checkmark.svg" alt=""/>
        </div>
      </div>
    </div>
    <div class="shift-modal__text-box">
      <div class="shift-modal__text-section shift-modal__text-section--first">
        <label class="shift-modal__label">{{'undisclosedNote' | translate}}</label>
        <textarea class="form__text-area" formControlName="undisclosedNote"></textarea>
      </div>
      <div class="shift-modal__text-section">
        <label class="shift-modal__label">{{'disclosedNote' | translate}}</label>
        <textarea class="form__text-area" formControlName="disclosedNote"></textarea>
      </div>
    </div>
    <div class="shift-modal__input-section shift-modal__input-section--space-between">
      <div class="shift-modal__input-section">
        <label class="shift-modal__label">{{'secondActivity' | translate}}</label>
        <input class="input shift-modal__input shift-modal__input--arrow" formControlName="secondActivity"
               (click)="secondActivity()"
               (clickOutside)="secondActivities = false"
               [ngStyle]="setActivity2Id === null ? {'color': '#929090'} : {'color': '#333333'}"
               placeholder="{{'select' | translate}}"/>
        <div class="shift-modal__dropdown shift-modal__dropdown--height" *ngIf="secondActivities">
          <div class="shift-modal__option" (click)="getSecondActivity(null)"
          >{{'select' | translate}}</div>
          <div class="shift-modal__option" *ngFor="let activity of sortActivities()"
               (click)="getSecondActivity(activity)">
            <div>{{activity?.title}} ({{(activity?.shortTitle)}})</div>
            <img *ngIf="activity?.id === setActivity2Id" class="shift-modal__check-mark"
                 src="../../../assets/images/Checkmark.svg" alt=""/>
          </div>
        </div>
      </div>
      <div class="shift-modal__input-section shift-modal__input-section--flex">
        <div class="shift-modal__input-section">
          <label class="shift-modal__label">{{'start' | translate}}</label>
          <input class="input shift-modal__date shift-modal__date--margin-second"
                 placeholder="- - : - -" formControlName="secondStartTime"
                 [format]=24 [ngxTimepicker]="pickerStart1" readonly
                 [min]="calculateTimeLimit('min')" [max]="calculateTimeLimit('max')"/>
          <ngx-material-timepicker #pickerStart1 [enableKeyboardInput]=true
          ></ngx-material-timepicker>
        </div>
        <div class="shift-modal__input-section">
          <label class="shift-modal__label">{{'end' | translate}}</label>
          <input class="input shift-modal__date shift-modal__date--padding"
                 placeholder="- - : - -" formControlName="secondEndTime" [format]=24 [ngxTimepicker]="pickerEnd1"
                 readonly [min]="calculateTimeLimit('min')" [max]="calculateTimeLimit('max')"/>
          <ngx-material-timepicker #pickerEnd1 [enableKeyboardInput]=true></ngx-material-timepicker>
        </div>
      </div>
    </div>
    <div class="shift-modal__buttons">
      <button class="button button--primary cancel-btn"
              (click)="closeModal()">{{'cancelButton' | translate}}
      </button>
      <button *ngIf="custom === 'custom'" type="button" class="button button--blue save-button custom-button"
              (click)="createCustomShift()">{{'saveButton' | translate}}</button>
      <button *ngIf="custom === 'realShift' " type="button" class="button button--blue save-button real-button"
              (click)="updateShift()">{{'saveButton' | translate}}</button>
      <button *ngIf="new === 'new'" type="button" class="button button--blue save-button new-button"
              (click)="createNewShiftType()">{{'saveButton' | translate}}</button>
      <button *ngIf="edit === 'edit-shift-type'" type="button" class="button button--blue save-button edit-button"
              (click)="editCustomShift()">{{'saveButton' | translate}}</button>
      <button *ngIf="edit === 'edit' && !custom && !new" type="button" class="button button--blue save-button edit2-button"
              (click)="clientUpdate()">{{'saveButton' | translate}}</button>
    </div>
  </form>
</section>
