<div class="main-container">
  <img class="log-out-image" src="../../../../assets/images/Close.png" (click)="closeModal()"/>

  <div class="header-title">
    {{'logOut' | translate}}
  </div>

  <div class="dialog-text">
    {{'logoutDialogText' | translate}}
  </div>

  <div class="buttons">
    <button class="button button--primary" style="margin-right: 10px; width: 100px; align-items: center;"
            (click)="closeModal()">{{'cancelButton' | translate}}
    </button>
    <button class="button button--blue" style="width: 100px; align-items: center;"
            (click)="logout()">{{'logOut' | translate}}
    </button>
  </div>
</div>
