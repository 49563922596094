import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../shared/services/user.service';
import { ShiftsService } from '../../../shared/services/shifts.service';
import { CancelReasonsModel } from '../../../shared/models/cancel-reasons.model';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {AuthConst} from "../../../shared/services/const/authConst";

@Component({
  selector: 'app-absence-modal',
  templateUrl: './absence-modal.component.html',
  styleUrls: ['./absence-modal.component.scss'],
})
export class AbsenceModalComponent implements OnInit {
  reasons: Array<CancelReasonsModel> = [];
  groupId: number | undefined;
  openReasons = false;
  reasonTitle: string | undefined;
  cancelReasonId: number | undefined;
  startDate: string | undefined;
  start = false;
  endDate: string | undefined;
  end = false;
  cancelReason: any;
  toDelete = false;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<AbsenceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private shiftsService: ShiftsService,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.startDate = this.translate.instant('selectStartDate');
    this.endDate = this.translate.instant('selectEndDate');
    this.userService.getEmployeeById(this.data.userId).subscribe((user) => {
      this.groupId = Number(localStorage.getItem(AuthConst.gruopId));
      this.shiftsService
        .getActiveCancelReasons()
        .subscribe((reasons: CancelReasonsModel[]) => {
          reasons.forEach((x: any) => {
            if (
              x.groupId === this.groupId &&
              x.groupStatus === 'ACTIVE' &&
              x.rowStatus === 'ACTIVE'
            ) {
              this.reasons.push(x);
            }
          });
        });
    });
  }

  openDropdown(): void {
    this.openReasons = !this.openReasons;
  }

  getReasons(reason: CancelReasonsModel): void {
    this.cancelReason = {
      id: reason.id,
      shortCode: reason.shortCode,
      title: reason.title,
      description: reason.description,
      available: reason.available,
    };
    this.reasonTitle = reason.title;
    this.cancelReasonId = reason.id;
  }

  addStartEvent(event: any): void {
    this.start = true;
    this.startDate = `${event.value}`;
    this.startDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
  }

  addEndEvent(event: any): void {
    this.end = true;
    this.endDate = `${event.value}`;
    this.endDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
  }

  async sendAbsence(text: string): Promise<void> {
    const start = new Date(this.startDate);
    const end = new Date(this.endDate);

    const formattedStart = this.datePipe.transform(start, 'yyyy-MM-dd');
    const formattedEnd = this.datePipe.transform(end, 'yyyy-MM-dd');
    if (!this.start) {
      this.snackBar.open(this.translate.instant('PleaseSelectStart'));
    } else if (!this.end) {
      this.snackBar.open(this.translate.instant('PleaseSelectEnd'));
    } else if (this.cancelReasonId === undefined) {
      this.snackBar.open(
        this.translate.instant('PleaseSelectCancelationReason')
      );
    } else {
      const absences: any = [];
      end.setHours(12);
      for (const d = start; d <= end; d.setDate(d.getDate() + 1)) {
        this.loading = true;
        const data = new Date(d);
        const currentDate = this.datePipe.transform(data, 'yyyy-MM-dd');

        const send = {
          appliedById: this.data.userId,
          groupId: this.groupId,
          shift: 'CANCEL',
          disclosedNote: text,
          cancelReason: this.cancelReason,
          cancelReasonId: this.cancelReasonId,
          date: currentDate,
          toDelete: this.toDelete,
          start: formattedStart,
          end: formattedEnd
        };
        absences.push(send);
      }

      this.userService.createMultiAbsence(absences).subscribe(
          (dat: any) => {
            this.loading = false;
            this.dialogRef.close();
          }, (err: any) => {
            this.loading = false;
            this.dialogRef.close();
          }
        );
      }
  }

  sortReasons(): any[] {
    const sortedReasons = this.reasons.sort((a: any, b: any) => {
      return a.title.localeCompare(b.title);
    });
    return sortedReasons;
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
