<section>
  <section class="main-details">
    <img class="shift-image" src="../../../../assets/images/Close.png" (click)="closeModal()"/>
    <div class="main-details__profile">
      <div class="main-details__title">{{'shiftDetails' | translate}}</div>
      <div class="main-details__user-detail">
        <div class="main-details__left-detail">
          <div class="main-details__image-box main-details__image-box--margin">
            <img class="main-details__image" src="../assets/images/profile_white.svg"
                 *ngIf="!data.person?.assignedToProfilePictureUrl || data.person?.assignedToProfilePictureUrl == ''"/>
            <img class="main-details__image user-real-image" [src]="data.person?.assignedToProfilePictureUrl"
                 *ngIf="data.person?.assignedToProfilePictureUrl && data.person?.assignedToProfilePictureUrl != ''"/>
          </div>
          <div class="for-text">
            <div class="assigned-to-text" *ngIf="!data.person.assignedToName">
              {{'notAssigned' | translate}}
            </div>
            <div class="assigned-to-text real-name" *ngIf="data.person.assignedToName">
              {{data.person.assignedToName}}
            </div>
            <div class="personal-subtitle" *ngIf="data.person.status === 'ASSIGNED' && data.person?.isActiveUser">
              {{getPersonShortName()}},&nbsp;
              <span *ngIf="data.person.assignedToId">{{data.person?.assignedToContract}}</span>
            </div>
            <div class="personal-subtitle" *ngIf="data.person.status === 'ASSIGNED' && !data.person?.isActiveUser"
                 style="color: red">
              Användaren raderad
            </div>

            <div class="application-text application-text--cancel"
                 *ngIf="this.data.person.status === 'CANCELED'">{{capitalize(this.data.person.status)}}</div>
            <div *ngIf="!data.person.assignedToName">

              <div class="application-text"
                   *ngIf="data.person.nrOfAppliedApplications && data.person.nrOfAppliedApplications > 0">
                <span>{{'pendingApplications' | translate}} ({{data.person.nrOfAppliedApplications}})</span>
              </div>
              <div class="application-text inactive-text"
                   *ngIf="data.person.invitationCount > 0 && (!data.person.nrOfAppliedApplications || data.person.nrOfAppliedApplications === 0)">
                {{'pendingInvitations' | translate}}
              </div>
              <div class="application-text inactive-text"
                   *ngIf="(!data.person.nrOfAppliedApplications || data.person.nrOfAppliedApplications === 0) && (data.person.invitationCount == 0)">
                {{'noApplications' | translate}}
              </div>
            </div>
          </div>
        </div>
        <div class="buttons">
          <button *ngIf="data.person.status ==='PUBLISHED'" class="button button--primary assigned-button"
                  (click)="assignShift(data.person)">{{'assignShift' | translate}}</button>

          <div *ngIf="data.person.status ==='ASSIGNED' && data.showIcons != false" style="display: flex;">
            <div class="buttons__image-box" (click)="routeToUser($event)">
              <img class='buttons__image' src="../assets/images/personal/User_Single.svg"/>
            </div>
            <div class="buttons__image-box buttons__image-box&#45;&#45;margin" (click)="openChat($event)"
                 *ngIf="role != 'COORDINATOR' && data.person?.isActiveUser">
              <img class='buttons__image' src="../assets/images/Chat_Outline.svg"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <form class="shift-modal" [formGroup]="shiftsForm">
    <div class="shift-modal__input-section forDatePicker">
      <label class="shift-modal__label">{{'date' | translate}}</label>
        <div (click)="dateChangable ? picker.open() : null" class="input shift-modal__input date-picker" [class.cancelTextGray]="data.person.status ==='CANCELED'">
          {{createdShift}}
        </div>
      <input class="invisiblePicker" [(ngModel)]="newDate" [ngModelOptions]="{standalone: true}" (dateChange)="changedDate($event)" [matDatepicker]="picker" />

    </div>
      <mat-datepicker #picker></mat-datepicker>
    <div class="shift-modal__input-section shift-modal__input-section--flex">
      <div class="shift-modal__input-section shift-modal__input-section--margin">
        <label class="shift-modal__label">{{'start' | translate}}</label>
        <input class="input shift-modal__date shift-modal__date--margin dateModal"
               formControlName="firstStartTime" readonly [disableClick]="data.person.status ==='CANCELED'"
               [class.cancelTextGray]="data.person.status ==='CANCELED'" [format]=24
               [ngxTimepicker]="pickerStart"
               onfocus="blur()"
               inputmode='none'/>
        <ngx-material-timepicker #pickerStart [enableKeyboardInput]=true></ngx-material-timepicker>
      </div>
      <div class="shift-modal__input-section shift-modal__input-section--margin">
        <label class="shift-modal__label">{{'end' | translate}}</label>
        <input class="input shift-modal__date dateModal" formControlName="firstEndTime" [format]=24
               [ngxTimepicker]="pickerEnd"
               readonly [disableClick]="data.person.status ==='CANCELED'"
               [class.cancelTextGray]="data.person.status ==='CANCELED'"
               onfocus="blur()"
               inputmode='none'/>
        <ngx-material-timepicker #pickerEnd [enableKeyboardInput]=true></ngx-material-timepicker>
      </div>
    </div>
    <div class="shift-modal__input-section">
      <label class="shift-modal__label">{{'position' | translate}}</label>
      <input class="input shift-modal__input shift-modal__input--arrow" formControlName="position"
             (click)="getPositions()"
             inputmode='none'
             (clickOutside)="displayPositions = false"
             placeholder="{{'select' | translate}}"
             [class.cancelTextGray]="data.person.status ==='CANCELED' || positionId === null"
      />
      <div class="shift-modal__dropdown" *ngIf="displayPositions">
        <div class="shift-modal__option" (click)="chosePosition(null)"
        >{{'select' | translate}}</div>
        <div class="shift-modal__option" *ngFor="let position of sortPositions()"
             (click)="chosePosition(position)"
             [class.cancelTextGray]="data.person.status ==='CANCELED'">
          <div>{{position?.title}}</div>
          <img *ngIf="position?.id === positionId" class="shift-modal__check-mark"
               src="../../../assets/images/Checkmark.svg"/>
        </div>
      </div>
    </div>
    <div class="shift-modal__input-section">
      <label class="shift-modal__label">{{'activity' | translate}}</label>
      <input class="input shift-modal__input shift-modal__input--arrow" formControlName="firstActivity"
             (click)="getActivities()"
             inputmode='none'
             (clickOutside)="displayActivities = false"
             placeholder="{{'select' | translate}}"
             [class.cancelTextGray]="data.person.status ==='CANCELED' || activityId === null"/>
      <div class="shift-modal__dropdown" *ngIf="displayActivities">
        <div class="shift-modal__option" (click)="choseActivity(null)"
        >{{'select' | translate}}</div>
        <div class="shift-modal__option" *ngFor="let activity of sortActivities()"
             (click)="choseActivity(activity)"
             [class.cancelTextGray]="data.person.status ==='CANCELED' || activityId === null">
          <div>{{activity?.title}} ({{(activity?.shortTitle)}})</div>
          <img *ngIf="activity?.id === activityId" class="shift-modal__check-mark"
               src="../../../assets/images/Checkmark.svg"/>
        </div>
      </div>
    </div>
    <div class="shift-modal__input-section">
      <label class="shift-modal__label">{{'competence' | translate}}</label>
      <input class="input shift-modal__input shift-modal__input--arrow" formControlName="competence"
             (click)="getCompetences()"
             inputmode='none'
             (clickOutside)="displayCompetence = false"
             placeholder="{{'select' | translate}}"
             [class.cancelTextGray]="data.person.status ==='CANCELED' || competenceId === null"/>
      <div class="shift-modal__dropdown" *ngIf="displayCompetence">
        <div class="shift-modal__option" (click)="choseCompetence(null)"
        >{{'select' | translate}}</div>
        <div class="shift-modal__option" *ngFor="let competences of sortCompetences()"
             (click)="choseCompetence(competences)">
          <div
            [class.cancelTextGray]="data.person.status ==='CANCELED' || competenceId === null">
            {{competences?.title}}
            <img *ngIf="competences?.id === competenceId" class="shift-modal__check-mark"
                 src="../../../assets/images/Checkmark.svg"/>
          </div>
        </div>
      </div>
    </div>
    <div class="shift-modal__input-section shift-modal__input-section--space-between">
      <div class="shift-modal__input-section shift-modal__input-section--height">
        <label class="shift-modal__label">{{'undisclosedNote' | translate}}</label>
        <textarea class="form__text-area" formControlName="undisclosedNote"
                  [readonly]="data.person.status ==='CANCELED'"
                  [class.cancelTextGray]="data.person.status ==='CANCELED'"></textarea>
      </div>
      <div class="shift-modal__input-section shift-modal__input-section--height" *ngIf="role != 'COORDINATOR'">
        <label class="shift-modal__label">{{'disclosedNote' | translate}}</label>
        <textarea class="form__text-area disclosed-note" formControlName="disclosedNote"
                  [readonly]="data.person.status ==='CANCELED'"
                  [class.cancelTextGray]="data.person.status ==='CANCELED'"></textarea>
      </div>
    </div>
    <div class="shift-modal__input-section shift-modal__input-section--time2">
      <div class="shift-modal__input-section shift-modal__input-section--margin">
        <label class="shift-modal__label">{{'secondActivity' | translate}}</label>
        <input class="input shift-modal__input shift-modal__input--arrow" formControlName="secondActivity"
               (click)="secondActivity($event)"
               inputmode='none'
               (clickOutside)=" secondActivities = false"
               placeholder="{{'select' | translate}}"
               [class.cancelTextGray]="data.person.status ==='CANCELED' || setActivity2Id === null"/>
        <div class="shift-modal__dropdown shift-modal__dropdown--height" *ngIf="secondActivities">
          <div class="shift-modal__option" (click)="getSecondActivity(null)"
          >{{'select' | translate}}</div>
          <div class="shift-modal__option" *ngFor="let activity of sortActivities()"
               (click)="getSecondActivity(activity)"
               [class.cancelTextGray]="data.person.status ==='CANCELED' || setActivity2Id === null">
            <div>{{activity?.title}} ({{(activity?.shortTitle)}})</div>
            <img *ngIf="activity?.id === setActivity2Id" class="shift-modal__check-mark"
                 src="../../../assets/images/Checkmark.svg"/>
          </div>
        </div>
      </div>
      <div class="shift-modal__input-section shift-modal__input-section--flex">
        <div class="shift-modal__input-section shift-modal__input-section--margin">
          <label class="shift-modal__label">{{'start' | translate}}</label>
          <input class="input shift-modal__date shift-modal__date--margin-second dateModal"
                 placeholder="- - : - -" formControlName="secondStartTime"
                 [format]=24 [ngxTimepicker]="pickerStart1"
                 [min]="calculateTimeLimit('min')" [max]="calculateTimeLimit('max')"
                 readonly
                 onfocus="blur()"
                 inputmode='none'
                 [disableClick]="data.person.status ==='CANCELED' || setActivity2Id === null"
                 [class.cancelTextGray]="data.person.status ==='CANCELED'"/>
          <ngx-material-timepicker #pickerStart1
                                   [enableKeyboardInput]=true></ngx-material-timepicker>
        </div>
        <div class="shift-modal__input-section shift-modal__input-section--margin">
          <label class="shift-modal__label">{{'end' | translate}}</label>
          <input class="input shift-modal__date shift-modal__date--padding dateModal"
                 placeholder="- - : - -" formControlName="secondEndTime" [format]=24 [ngxTimepicker]="pickerEnd1"
                 [disableClick]="data.person.status ==='CANCELED'"
                 [class.cancelTextGray]="data.person.status ==='CANCELED'"
                 inputmode='none'
                 onfocus="blur()"
                 [min]="calculateTimeLimit('min')" [max]="calculateTimeLimit('max')"
          />
          <ngx-material-timepicker #pickerEnd1
                                   [enableKeyboardInput]=true></ngx-material-timepicker>
        </div>
      </div>
    </div>
    <div class="shift-modal__buttons" *ngIf="data.person.status !=='CANCELED'">
      <button class="button button--primary" style="margin-right: 10px; width: 100px"
              (click)="closeModal()">{{'cancelButton' | translate}}
      </button>
      <button class="button button--blue" style="width: 100px" (click)="save()"
      >{{'save' | translate}}</button>
    </div>
    <div class="shift-modal__buttons" *ngIf="data.person.status ==='CANCELED'">
      <button class="button button--red" style="margin-right: 10px; width: 100px"
              (click)="deleteShift()">{{'delete' | translate}}
      </button>
      <button class="button button--primary" style="width: 100px" (click)="recoveryShift()"
      >{{'recoverShift' | translate}}</button>
    </div>
  </form>
</section>
