import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { Conversations } from '../models/conversations.model';
import { ConversationMessagesModel } from '../models/conversation-messages.model';
import { RecipientsModel } from '../models/recipients.model';

@Injectable({ providedIn: 'root' })
export class MessagesService {
  apiUrl: string = environment.apiURL;

  public messagesSubject = new BehaviorSubject(null);
  messages$: Observable<any> = this.messagesSubject.asObservable();
  public updateLastMessage: Subject<any> = new Subject();

  constructor(private http: HttpClient) {}

  /**
   *  Get all conversations
   */
  getAllConversations(): Observable<Conversations> {
    return this.http
      .get<Conversations>(
        `${this.apiUrl}/messages/start/0/nr/20/get`
      )
      .pipe(
        map((chats) => chats),
        shareReplay()
      );
  }

  /**
   *  Messages from conversation
   */

  singleConversation(
    chatId?: number,
    minMessage?: number
  ): Observable<ConversationMessagesModel> {
    let start = 0;
    if (minMessage) {
      start = minMessage;
    }
    return this.http
      .get<ConversationMessagesModel>(
        `${this.apiUrl}/messages/thread/` + chatId + `/start/` + start + `/nr/20/get`
      )
      .pipe(
        map((msg) => msg),
        shareReplay()
      );
  }

  /**
   * Get Recipients
   */
  getAllRecipients(): Observable<RecipientsModel> {
    // @ts-ignore
    return this.http
      .get<RecipientsModel>(`${this.apiUrl}/messages/recipients`)
      .pipe(
        map((x) => x),
        shareReplay()
      );
  }

  /**
   * Send Message
   */
  createMessage(message: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrl}/messages/add`, message)
      .pipe(shareReplay());
  }

  /**
   * Mark message thread as read
   */

    markThreadAsRead(id: any): any {
      return this.http.post(this.apiUrl + "/messages/thread/" + id + "/read", {});
  }

}
