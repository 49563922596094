import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Swap } from '../../../shared/models/swap.model';
import { ActivitylogsService } from '../../../shared/services/activitylogs.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { EmployeesService } from '../../../shared/services/employees.service';
import { Observable } from 'rxjs';
import { Conversations } from '../../../shared/models/conversations.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MessagesService } from '../../../shared/services/messages.service';
class DialogData {
  // @ts-ignore
  public swap: Swap;
}
@Component({
  selector: 'app-swap-dialog',
  templateUrl: './swap-dialog.component.html',
  styleUrls: ['./swap-dialog.component.scss'],
})
export class SwapDialogComponent implements OnInit {
  messages$: Observable<Conversations> | undefined;
  messages: any[] = [];
  // tslint:disable-next-line:max-line-length
  constructor(
    private employeesService: EmployeesService,
    private activityLogService: ActivitylogsService,
    public dialogRef: MatDialogRef<SwapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private messagesService: MessagesService
  ) {}

  ngOnInit(): void {
  }


  closeDialog(): void {
    this.dialogRef.close(null);
  }

  getUserData(index: number, property: string): any {
    switch (property) {
      case 'image': {
        let img = '/assets/images/profile_white.svg';
        if (
          this.data?.swap?.users &&
          this.data?.swap?.users[index] &&
          this.data?.swap?.users[index].profilePicture
        ) {
          // @ts-ignore
          img = this.data.swap.users[index].profilePicture;
        }
        return img;
      }
    }
  }

  manipulateText(text: string): any {
    return  text.split(',').join(', <br>').split('.').join('. <br>');
  }

  confirmSwap(): void {
    if (this.data.swap.button.enabled) {
      this.activityLogService
        .confirmActivitySwap(this.data.swap.assignmentTransferId)
        .subscribe(
        (data: any) => {
          this.dialogRef.close("refresh");
        }
      );
    }
  }

  declineSwap(): void {
    if (this.data.swap.button.enabled) {
      this.activityLogService
        .declineActivitySwap(this.data.swap.assignmentTransferId)
        .subscribe(
          (data: any) => {
            this.dialogRef.close("refresh");
          }
        );
    }
  }

}
