<section class="cancel-dialog">
  <img class="cancel-dialog__image" src="../../../../assets/images/Close.png" (click)="closeModal()"/>
  <h1 class="cancel-dialog__title">{{ data.title }}</h1>
  <div class="modal-section">
    <div class="modal-section__title">{{data.inputTitle}}</div>
    <div class="modal-section__subtitle normalText">{{data.inputSubtitle}}</div>
    <div class="modal-section__date" (click)="showAnnouncement.open()">
      <span class="dateCapitalize"> {{currentDate | date: "EEEE d MMM. y"}} </span>
      <input class="section-select__select modal-input" readonly
             [matDatepicker]="showAnnouncement" [(ngModel)]="currentDate" (dateChange)="addEvent($event)"/>
    </div>
    <mat-datepicker #showAnnouncement></mat-datepicker>
  </div>
  <div class="modal-section modal-section--margin">
    <div class="modal-section__title">{{data.messageTitle}}</div>
    <div class="modal-section__subtitle normalText">{{data.messageSubtitle}}</div>
    <textarea class="form__text-area" placeholder="{{'clickToWrite' | translate}}"
              [(ngModel)]="message"
              #messageAnnouncement></textarea>
  </div>
  <div class="buttons">
    <button class="button button--primary" style="margin-right: 10px; width: 100px"
            (click)="closeModal()">{{'cancelButton' | translate}}
    </button>
    <button *ngIf="!update" class="button button--blue" style="width: 100px"
            (click)="sendAnnouncement(messageAnnouncement.value)"
    >{{'save' | translate}}
    </button>
    <button *ngIf="update" class="button button--blue" style="width: 100px"
            (click)="updateAnnouncement(messageAnnouncement.value)"
    >{{'save' | translate}}
    </button>
  </div>
</section>
