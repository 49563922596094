import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import {ShiftsService} from '../../shared/services/shifts.service';
import {AuthConst} from '../../shared/services/const/authConst';
import {HelperService} from '../../shared/services/helper.service';
import {MatDialog} from '@angular/material/dialog';
import {CreateNewShiftModalComponent} from '../modals/create-new-shift-modal/create-new-shift-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

export class Shift {
  active: boolean;
  date: string;
  dayNumber: number;
  activity2Id: number;
  activityId: number;
  color: string;
  competenceId: number;
  disclosedNote: string;
  end: string;
  end2: string;
  groupId: number;
  id: number;
  name: string;
  positionId: number;
  start: string;
  start2: string;
  undisclosedNote: string;
}

// @ts-ignore
@Component({
  selector: 'app-create-new-shift',
  templateUrl: './create-new-shift.component.html',
  styleUrls: ['./create-new-shift.component.scss'],
})
export class CreateNewShiftComponent implements OnInit, OnDestroy {
  @Output() readonly back = new EventEmitter();
  @Output() readonly updateAssignment = new EventEmitter();
  @Output() readonly time = new EventEmitter();
  @Output() readonly time1 = new EventEmitter();

  assignments: any = [];
  showAssignment = false;
  AssignmentName = '';
  AssignmentColor: any;
  clicked = false;
  mode = 0;
  custom: string | undefined;
  new: string | undefined;
  shiftType = false;
  createdAssignment: any;
  ShowCalendar: boolean;
  hideCalendar: boolean;
  changeColor: boolean;
  shifts: any[];
  fullShift: Shift;
  shiftsForCreate: any = [];
  allShifts: any = [];
  shiftNumber = 0;

  shiftTypes = [
    {
      name: 'Nytt pass',
      cliccable: 5
    },
    {
      name: 'Skapa ett standardpass',
      cliccable: 5
    },
    {
      name: 'Ändra standardpass',
      cliccable: 5
    },
  ];

  customShiftSubscription: Subscription;
  newShiftSubscription: Subscription;
  updateShiftSubscription: Subscription;
  deleteShiftSubscription: Subscription;
  modeSubscription: Subscription;
  selectedShiftsSubscription: Subscription;
  chosenShiftsSubscription: Subscription;
  shiftCalendarSubscription: Subscription;

  constructor(
    private shiftsService: ShiftsService,
    private helperService: HelperService,
    private dialog: MatDialog,
    public translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    const groupId = Number(localStorage.getItem(AuthConst.gruopId));
    this.shiftsService.allAssignmentTypes(groupId).subscribe((x: any[]) => {
      this.assignments = x;
      this.sortByDateAndName();
      this.shiftTypes.forEach((shift) => {
        this.assignments.push(shift);
      });
    });

    this.customShiftSubscription = this.helperService.customShiftsType$.subscribe(
      (customShift) => {
        this.createdAssignment = customShift;
        this.shiftType = false;
      }
    );

    this.newShiftSubscription = this.helperService.newShiftsType$.subscribe(
      (newShift) => {
        this.createdAssignment = newShift;
        this.sortByDateAndName();
        const shiftIndex = this.assignments.findIndex(
          (shiftWithId: { id: any }) => shiftWithId.id === undefined
        );

        if (newShift) {
          this.assignments.splice(shiftIndex, 0, newShift);
        }
      }
    );

    setTimeout(() => {
      this.updateShiftSubscription = this.helperService.updateShiftsType$.subscribe(
        (x) => {
          if (x && x.clientUpdate !== true) {
            const fndShiftUpdateIndex = this.assignments.findIndex(
              (shift: { id: number }) => shift.id === x.id
            );

            if (fndShiftUpdateIndex !== -1) {
              this.assignments[fndShiftUpdateIndex] = x;
            }
            this.sortByDateAndName();
          }
        }
      );
    }, 300);
    this.deleteShiftSubscription = this.helperService.deleteType$.subscribe(
      (deleteShift) => {
        const assingmentIndex = this.assignments.findIndex(
          (x: { id: any }) => x.id === deleteShift
        );
        if (assingmentIndex !== -1) {
          this.assignments.splice(assingmentIndex, 1);
        }
      }
    );

    this.modeSubscription = this.helperService.changeMode$.subscribe((mode) => {
      this.mode = mode;
      this.showAssignment = false;
      this.shiftType = false;
      this.AssignmentName = '';
    });

    this.selectedShiftsSubscription = this.helperService.selectedShifts$.subscribe(
      (shifts: number) => {
        this.shiftNumber = 0;
        if (shifts) {
          this.changeColor = true;
          this.shiftNumber = shifts;
        } else {
          this.changeColor = false;
        }
      }
    );

    this.shiftCalendarSubscription = this.helperService.shiftCalendarShift$.subscribe(
      (shift) => {
        this.mode = 4;
        this.custom = 'realShift';
        this.createdAssignment = shift;
      }
    );

    this.helperService.editClientShift$.subscribe((x: any) => {
      if (x) {
        this.createdAssignment = x;
      }
    });
  }

  getPositionColor(color: any): any {
    let colorOfShift = '';
    const indexColor = this.assignments.findIndex(
      (findColor: any) => findColor.id === color.id
    );
    if (indexColor !== -1) {
      colorOfShift = color.color;
    }
    if (colorOfShift?.startsWith('##')) {
      return colorOfShift.substring(1);
    }
    if (colorOfShift?.startsWith('#')) {
      return colorOfShift;
    } else {
      return '#' + colorOfShift;
    }
  }

  // tslint:disable-next-line:typedef
  sortByDateAndName() {

    this.assignments.sort(
      (name1: { name: string, cliccable: any }, name2: { name: string, cliccable: any }) =>
        name1.name !== name2.name ? (name1.cliccable < name2.cliccable ? name1.name < name2.name ? -1 : 1 : 1) : 0
    );

  }


  sortAssignments(assignments: any[]): any[] {
    return assignments.sort(
      (name1: { name: string, cliccable: any }, name2: { name: string, cliccable: any }) =>
        name1.name !== name2.name ? (name1.cliccable < name2.cliccable ? name1.name < name2.name ? -1 : 1 : 1) : 0
    );
  }


  backToSchedule(): void {
    this.back.emit();
  }

  getAssignment(assignment: any): void {
    this.clicked = true;
    this.AssignmentName = assignment.assignmentText;
    this.AssignmentColor = assignment.assignmentColor;
    this.createdAssignment = assignment;

    if (assignment.name === 'Nytt pass') {
      this.mode = 1;
      this.custom = 'custom';
      this.helperService.showCalendar$.next(true);
    } else if (assignment.name === 'Skapa ett standardpass') {
      this.mode = 2;
      this.new = 'new';
    } else if (assignment.name === 'Ändra standardpass') {
      this.mode = 3;
      this.shiftType = true;
    } else if (assignment.id) {
      this.mode = 4;
      this.helperService.showCalendar$.next(true);
      this.helperService.sendShiftToCalendar.next(this.createdAssignment);
    } else {
    }
  }

  create(): void {
    this.chosenShiftsSubscription = this.helperService.chosenShifts$.subscribe(
      (x) => {
        // tslint:disable-next-line:no-shadowed-variable
        this.shifts = x.filter((shift) => shift.active === true);
      }
    );

    this.shiftsForCreate = [];
    this.shifts.forEach((s) => {
      this.fullShift = new Shift();
      this.fullShift.active = s.active;
      this.fullShift.date = s.date;
      this.fullShift.dayNumber = s.dayNumber;
      this.fullShift.color = this.createdAssignment.color;
      this.fullShift.activity2Id = this.createdAssignment.activity2Id;
      this.fullShift.activityId = this.createdAssignment.activityId;
      this.fullShift.competenceId = this.createdAssignment.competenceId;
      this.fullShift.disclosedNote = this.createdAssignment.disclosedNote;
      this.fullShift.end = this.createdAssignment.end;
      this.fullShift.end2 = this.createdAssignment.end2;
      if (this.fullShift.end2 === null) {
        this.fullShift.end2 = '';
      }
      this.fullShift.groupId = this.createdAssignment.groupId;
      this.fullShift.id = this.createdAssignment.id;
      this.fullShift.name = this.createdAssignment.name;
      this.fullShift.positionId = this.createdAssignment.positionId;
      this.fullShift.start = this.createdAssignment.start;
      this.fullShift.start2 = this.createdAssignment.start2;
      if (this.fullShift.start2 === null) {
        this.fullShift.start2 = '';
      }
      this.fullShift.undisclosedNote = this.createdAssignment.undisclosedNote;
      this.shiftsForCreate.push(this.fullShift);
    });

    const confirmDialog = this.dialog.open(CreateNewShiftModalComponent, {
      disableClose: true,
      width: '560px',
      maxHeight: '350px',
      minHeight: '220px',
      panelClass: 'defaultDialog',
      position: {
        top: '100px',
      },
      data: {
        title: this.translate.instant('createNewShift'),
        shifts: this.shiftsForCreate,
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result && result !== false) {
        this.updateAssignment.emit();
        this.back.emit();
        if (result.length > 1) {
          const time = localStorage.getItem(AuthConst.scheduleDate);
          this.time.emit(time);
        } else if (result.length === 1) {
          const time = result[0].date;
          this.time1.emit(time);
        }
      } else {
        this.helperService.selectedShifts.next(0);
        const message = 'clear-calendar';
        this.helperService.dateToDefault$.next(message);
      }
    });
  }

  getBack(mode: string): void {
    this.AssignmentName = '';
    this.mode = 0;
    const message = 'back';
    this.helperService.dateToDefault$.next(message);
    this.helperService.emptyCalendarDropDown.next(true);
    if (mode === 'mode') {
      this.shiftType = false;
    }
    this.hideCalendar = true;
    this.sortByDateAndName();
  }

  showAllAssignment(): void {
    this.showAssignment = !this.showAssignment;
  }

  ngOnDestroy(): void {
    this.customShiftSubscription?.unsubscribe();
    this.newShiftSubscription?.unsubscribe();
    this.updateShiftSubscription?.unsubscribe();
    this.deleteShiftSubscription?.unsubscribe();
    this.modeSubscription?.unsubscribe();
    this.selectedShiftsSubscription?.unsubscribe();
    this.chosenShiftsSubscription?.unsubscribe();
    this.shiftCalendarSubscription?.unsubscribe();
  }
}
