import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {shareReplay, tap, map} from 'rxjs/operators';
import {ActivityListResponse, ActivityLogCount} from '../models/activitylogs.model';
import {Swap} from "../models/swap.model";
import {Filter} from "../models/filter.model";

@Injectable({
  providedIn: 'root',
})
export class ActivitylogsService {
  constructor(private http: HttpClient) {
  }

  apiUrl: string = environment.apiURL;



  getActivityLogs(page: number, filter: Filter, groupId: any, readLogs: any): Observable<ActivityListResponse> {
    const start = page * 20;
    const amount = 20;
    let toRead = 0;
    if (readLogs) {
      toRead = 1;
    }
    return this.http.post<ActivityListResponse>(this.apiUrl + '/activity-logs/filter/see/' + toRead + '/group/' + groupId + '/start/' + start + '/nr/' + amount, filter).pipe(
      map((log) => log),
      shareReplay()
    );
  }



  public getTotal(filter: any , groupId: any){
    // @ts-ignore
    return this.http.post<any>(this.apiUrl + '/activity-logs/filter/group/' + groupId + '/total', filter).pipe(
      map((log) => log),
      shareReplay()
    );
  }



  public getAssingmentsForAll(date1: string, date2: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/assignments/start/${date1}/end/${date2}`
      )
      .pipe(
        map((assignment) => assignment),
        shareReplay()
      );
  }



  getActivitySwap(id: number): Observable<Swap> {
    return this.http.get<Swap>(this.apiUrl + '/swap/activity-log/get/' + id)
      .pipe(map(
        (swap) => swap
        ),
        shareReplay());
  }

  markActivityLogAsRead(id: number): Observable<number> {
    return this.http.post<number>(this.apiUrl + '/activity-log/' + id + '/read', JSON.parse('{}'))
      .pipe(map(
        (swap) => swap
        ),
        shareReplay());
  }

  confirmActivitySwap(id: number): Observable<Swap> {
    return this.http.get<Swap>(this.apiUrl + '/swap/' + id + '/confirm')
      .pipe(map(
        (swap) => swap
        ),
        shareReplay());
  }

  declineActivitySwap(id: number): Observable<Swap> {
    return this.http.get<Swap>(this.apiUrl + '/swap/' + id + '/decline')
      .pipe(map(
        (swap) => swap
        ),
        shareReplay());
  }

  getActivityLogCount(): Observable<ActivityLogCount> {
    return this.http.get<ActivityLogCount>(this.apiUrl + '/activity-log/employer/unread')
      .pipe(map(
        (swap) => swap
        ),
        shareReplay());
  }


}
