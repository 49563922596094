import { Component, OnDestroy, OnInit } from '@angular/core';
import { Filter } from '../shared/models/filter.model';
import { ShiftsService } from '../shared/services/shifts.service';
import { DashboardData } from '../shared/models/user.model';
import { HelperService } from '../shared/services/helper.service';
import { AuthConst } from '../shared/services/const/authConst';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  usersDate = new Date();
  activityStartDate = new Date(
    this.usersDate.getTime() - 1000 * 60 * 60 * 24 * 7
  );
  activityEndDate = new Date();
  groupId: number;
  usersFilter: Filter = {
    group: { id: -1, title: 'allWorksites' },
    type: 1,
    sort: '',
    startDate: this.usersDate.toISOString(),
    endDate: new Date().toISOString(),
  };
  activityFilter: Filter = {
    group: { id: -1, title: 'allWorksites' },
    type: 2,
    sort: '',
    startDate: this.activityStartDate.toISOString(),
    endDate: this.activityEndDate.toISOString(),
  };
  userData: DashboardData = {
    userCount: 0,
    uskCount: 0,
    sskCount: 0,
    ulCount: 0,
    fullTimeCount: 0,
    fullTimePercentage: 0,
    partTimeCount: 0,
    partTimePercentage: 0,
    externalCount: 0,
    externalPercentage: 0,
    internalEngagement: 0,
    externalEngagement: 0,
  };
  activityData: DashboardData = {
    openShiftsCount: 0,
    assignedShiftsCount: 0,
    cancelledShiftsCount: 0,
    temporaryShiftsCount: 0,
    swapsPercentage: 0,
    swapsCount: 0,
    invitesCount: 0,
    invitesPercentage: 0,
    externalCount: 0,
    externalPercentage: 0,
    requestsCount: 0,
    requestsPercentage: 0,
  };

  groupChangedSubsrciption: Subscription;
  clickedSectionSubsrciption: Subscription;

  constructor(
    public shiftService: ShiftsService,
    private helper: HelperService
  ) {}

  ngOnInit(): void {
    this.groupId = Number(localStorage.getItem('gruopId'));
    localStorage.removeItem(AuthConst.scheduleDate);

    this.groupChangedSubsrciption = this.helper.groupChanged$.subscribe(
      (data) => {
        if (data) {
          this.groupId = data;
          this.usersFilter.group.id = this.groupId;
          this.activityFilter.group.id = this.groupId;
          this.getUsersData(this.usersFilter);
          this.getActivityData(this.activityFilter);
        }
      }
    );

    this.clickedSectionSubsrciption = this.helper.clickedSection.subscribe(
      (page) => {
        if (page && page == '/dashboard') {
          this.groupId = Number(localStorage.getItem('gruopId'));
          this.usersFilter.group.id = this.groupId;
          this.activityFilter.group.id = this.groupId;
          this.usersDate = new Date();
          this.activityStartDate = new Date(
            this.usersDate.getTime() - 1000 * 60 * 60 * 24 * 7
          );
          this.activityEndDate = new Date();
          this.getUsersData(this.usersFilter);
          this.getActivityData(this.activityFilter);
        }
      }
    );

    this.usersFilter.group.id = this.groupId;
    this.activityFilter.group.id = this.groupId;
    this.getUsersData(this.usersFilter);
    this.getActivityData(this.activityFilter);
  }

  getUsersData(filter: Filter): void {
    this.shiftService.getDashboard(filter).subscribe(
      // @ts-ignore
      (data: DashboardData[]) => {
        if (data.length >= 1) {
          this.userData = data[0];
        } else {
          this.userData = {
            userCount: 0,
            uskCount: 0,
            sskCount: 0,
            ulCount: 0,
            fullTimeCount: 0,
            fullTimePercentage: 0,
            partTimeCount: 0,
            partTimePercentage: 0,
            externalCount: 0,
            externalPercentage: 0,
            internalEngagement: 0,
            externalEngagement: 0,
          };
        }
      }
    );
  }

  getActivityData(filter: Filter): void {
    this.shiftService.getDashboard(filter).subscribe(
      // @ts-ignore
      (data: DashboardData[]) => {
        if (data.length >= 1) {
          this.activityData = data[0];
        } else {
          this.activityData = {
            openShiftsCount: 0,
            assignedShiftsCount: 0,
            cancelledShiftsCount: 0,
            temporaryShiftsCount: 0,
            swapsPercentage: 0,
            swapsCount: 0,
            invitesCount: 0,
            invitesPercentage: 0,
            externalCount: 0,
            externalPercentage: 0,
            requestsCount: 0,
            requestsPercentage: 0,
          };
        }
      }
    );
  }

  changedUsersDate(event: any): void {
    this.usersFilter.startDate = this.usersDate.toISOString();
    this.usersFilter.endDate = this.usersDate.toISOString();
    this.getActivityData(this.activityFilter);
  }

  changedActivityStart(event: any): void {
    this.activityFilter.startDate = this.activityStartDate.toISOString();
    this.getActivityData(this.activityFilter);
  }

  changedActivityEnd(event: any): void {
    this.activityFilter.endDate = this.activityEndDate.toISOString();
    this.getActivityData(this.activityFilter);
  }

  ngOnDestroy(): void {
    this.groupChangedSubsrciption?.unsubscribe();
    this.clickedSectionSubsrciption?.unsubscribe();
  }
}
