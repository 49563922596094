import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-change-status',
  templateUrl: './change-status.component.html',
  styleUrls: ['./change-status.component.scss'],
})
export class ChangeStatusComponent implements OnInit {
  message = '';
  openActions = false;
  allChecks: Array<any> = [];
  idSlectStatus: any = null;
  checkSelect = 1;
  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<any>
  ) {}

  ngOnInit(): void {
    this.allChecks = [
      { id: 1, name: 'Aktiv' },
      { id: 0, name: 'Pausad' },

    ];
  }

  cancel(): void {
    this.dialogRef.close();
  }

  send(): any {
    this.dialogRef.close(this.idSlectStatus);
  }
  selectStatus(id: any): any {
    this.openActions = false;
    this.idSlectStatus = id;
    this.checkSelect = id.id;
  }
  toOpenActions(): void {
    if (this.allChecks.length > 0) {
      this.openActions = true;
    }
  }
}
