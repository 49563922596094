import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface SelectedShifts {
  shifts: any[];
}

interface UpdateClientShift {
  shift: any;
}

@Injectable({ providedIn: 'root' })
export class HelperService implements OnDestroy {
  constructor() {}

  unsubscriber = new Subject();

  private data: SelectedShifts = {
    shifts: [],
  };

  private update: UpdateClientShift = {
    shift: [],
  };

  public personChat = new Subject();
  personChat$: Observable<any> = this.personChat.asObservable();

  public personChatRecipient = new Subject();
  recipientChat$: Observable<any> = this.personChatRecipient.asObservable();

  public multiMessage = new Subject();
  multiMessage$: Observable<any> = this.multiMessage.asObservable();

  public dateValue = new Subject();
  dateValue$: Observable<any> = this.dateValue.asObservable();

  public monthDataCalculate = new Subject();
  monthDataCalculate$: Observable<any> = this.monthDataCalculate.asObservable();



  public userId = new Subject();
  userId$: Observable<any> = this.userId.asObservable();

  public userName = new Subject();
  userName$: Observable<any> = this.userName.asObservable();

  public changeDate = new Subject();
  changeDate$: Observable<any> = this.changeDate.asObservable();

  public groupValue = new Subject();
  groupValue$: Observable<any> = this.groupValue.asObservable();

  public mode$: Subject<number> = new Subject();
  readonly changeMode$: Observable<number> = this.mode$.asObservable();

  public closeCopy: Subject<any> = new Subject();
  readonly closeCopy$: Observable<any> = this.closeCopy.asObservable();

  public updateShift$: Subject<any> = new Subject();
  readonly updateShiftsType$: Observable<any> = this.updateShift$.asObservable();

  public sendShiftToCalendar: Subject<any> = new Subject();
  readonly sendShiftToCalendar$: Observable<any> = this.sendShiftToCalendar.asObservable();

  public newShift$: Subject<any> = new Subject();
  readonly newShiftsType$: Observable<any> = this.newShift$.asObservable();

  public customShift$: Subject<any> = new Subject();
  readonly customShiftsType$: Observable<any> = this.customShift$.asObservable();

  public dateToDefault$: Subject<any> = new Subject();
  readonly defaultDate$: Observable<any> = this.dateToDefault$.asObservable();

  public showCalendar$: Subject<boolean> = new Subject();
  readonly calendar$: Observable<boolean> = this.showCalendar$.asObservable();

  public showCalendar2$: Subject<boolean> = new Subject();
  readonly calendar2$: Observable<boolean> = this.showCalendar2$.asObservable();

  public emptyCalendarDropDown: Subject<boolean> = new Subject();
  readonly emptyCalendarDropDown$: Observable<boolean> = this.emptyCalendarDropDown.asObservable();

  public removeShift$: Subject<any> = new Subject();
  readonly deleteType$: Observable<any> = this.removeShift$.asObservable();

  public sendAssignment: Subject<any> = new Subject();
  readonly sendAssignment$: Observable<any> = this.sendAssignment.asObservable();

  public weeks: Subject<any> = new Subject();
  readonly weeks$: Observable<any> = this.weeks.asObservable();

  public shiftCalendarShift: Subject<any> = new Subject();
  readonly shiftCalendarShift$: Observable<any> = this.shiftCalendarShift.asObservable();

  public backToEditShiftPage: Subject<boolean> = new Subject();
  readonly backToEditShiftPage$: Observable<boolean> = this.backToEditShiftPage.asObservable();

  public group: Subject<any> = new Subject();
  readonly group$: Observable<any> = this.group.asObservable();

  public selectedDate: Subject<any> = new Subject();
  readonly selectCalDate$: Observable<any> = this.selectedDate.asObservable();

  public hasManyShifts: Subject<any> = new Subject();
  readonly hasManyShifts$: Observable<any> = this.hasManyShifts.asObservable();

  public canSelect: Subject<any> = new Subject();
  readonly canSelect$: Observable<any> = this.canSelect.asObservable();

  public updateUserPersonalSchedule: Subject<any> = new Subject();
  readonly updateUserPersonalSchedule$: Observable<any> = this.updateUserPersonalSchedule.asObservable();

  public loadingTime: Subject<any> = new Subject();
  readonly loadingTime$: Observable<any> = this.loadingTime.asObservable();

  public shiftDates: Subject<any[]> = new Subject();
  readonly shiftDates$: Observable<any[]> = this.shiftDates.asObservable();

  public messageChanged: Subject<any> = new Subject();
  readonly messageChanged$: Observable<any> = this.messageChanged.asObservable();

  public groupChanged: Subject<any> = new Subject();
  readonly groupChanged$: Observable<any> = this.groupChanged.asObservable();

  public sendDate: Subject<any> = new Subject();
  readonly sendDate$: Observable<any> = this.sendDate.asObservable();

  public createdTempUser: Subject<any> = new Subject();
  readonly createdTempUser$: Observable<any> = this.createdTempUser.asObservable();

  public getApplications: Subject<any> = new Subject();
  readonly getApplications$: Observable<any> = this.getApplications.asObservable();

  public recoveredShift: Subject<any> = new Subject();
  readonly recoveredShift$: Observable<any> = this.recoveredShift.asObservable();

  private destroy$: Subject<void> = new Subject();
  private selectDateSubject$: BehaviorSubject<any[]> = new BehaviorSubject(
    this.data.shifts
  );

  public changedApplicationData: Subject<any> = new Subject();
  readonly changedApplicationData$: Observable<any> = this.changedApplicationData.asObservable();

  public confirmApplication: Subject<any> = new Subject();
  readonly confirmApplication$: Observable<any> = this.confirmApplication.asObservable();

  public confirmedApplication: Subject<any> = new Subject();
  readonly confirmedApplication$: Observable<any> = this.confirmedApplication.asObservable();

  public declineApplication: Subject<any> = new Subject();
  readonly declineApplication$: Observable<any> = this.declineApplication.asObservable();

  public declinedApplication: Subject<any> = new Subject();
  readonly declinedApplication$: Observable<any> = this.declinedApplication.asObservable();

  public getInvitations: Subject<any> = new Subject();
  readonly getInvitations$: Observable<any> = this.getInvitations.asObservable();

  private updateClient$: BehaviorSubject<any[]> = new BehaviorSubject(
    this.update.shift
  );

  readonly editClientShift$: Observable<any[]> = this.updateClient$.pipe(
    takeUntil(this.destroy$)
  );

  readonly chosenShifts$: Observable<any[]> = this.selectDateSubject$.pipe(
    takeUntil(this.destroy$)
  );

  public selectedShifts: Subject<any> = new Subject();
  public openShift: Subject<any> = new Subject();
  readonly selectedShifts$: Observable<any> = this.selectedShifts.asObservable();

  public clickedSection: Subject<string> = new Subject();
  public selectedShiftsCalendar: Subject<any> = new Subject();
  public selectedShiftsCalendarBack: Subject<any> = new Subject();
  public userOpenShift: Subject<any> = new Subject();
  public removeUserOpenShift: Subject<any> = new Subject();
  public userPosition: Subject<any> = new Subject();
  public refreshUserCalendar: Subject<any> = new Subject();
  public removeAssignment: Subject<any> = new Subject();
  public hasMoreShifts: Subject<any[]> = new Subject();
  public foundDayAssignments: Subject<any[]> = new Subject();
  public clearShift: Subject<any> = new Subject();
  public resetChatSubscriptions: Subject<any> = new Subject();
  public refreshChatCount: Subject<any> = new Subject();
  public refreshLogCount: Subject<any> = new Subject();
  public assignChangedDate: Subject<any> = new Subject();
  public sendLoading: Subject<any> = new Subject();


  private save(): void {
    this.selectDateSubject$.next(this.data.shifts);
  }

  getSelectedShifts(shifts: any): void {
    this.data.shifts = shifts;
    this.save();
  }

  private saveClient(): void {
    this.updateClient$.next(this.update.shift);
  }

  updateClient(shifts: any): void {
    this.update.shift = shifts;
    this.saveClient();
  }

  ngOnDestroy(): void {
    this.destroy$?.next();
    this.destroy$?.complete();
  }
}
