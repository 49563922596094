<div class="colorForBackground">
  <div class="padding-for-dialog">
    <img _ngcontent-gfy-c97="" src="../../../../assets/images/Close.png" (click)="dialogRef.close(0)" class="create-user__image locationX">

    <p class="send_group">{{'wrongRoleTitle' | translate}}</p>
    <p class="add_text">
      {{"attention" | translate}}!<br>
      <span class="text_after">{{'wrongRoleText' | translate}}  </span>
    </p>

    <div class="groups_buttons">

      <button class="group_cancel" (click)="dialogRef.close(0)">{{"cancelButton" | translate}}</button>
      <button class="delete_group" (click)="dialogRef.close(1)" >{{"continue" | translate}}</button>
    </div>
  </div>
</div>
