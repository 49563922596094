import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges,} from '@angular/core';
import {DatePipe} from '@angular/common';
import {ActivitylogsService} from '../shared/services/activitylogs.service';
import {HelperService} from '../shared/services/helper.service';
import * as moment from 'moment';
import {EmployeesService} from '../shared/services/employees.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-user-calendar',
  templateUrl: './user-calendar.component.html',
  styleUrls: ['./user-calendar.component.scss'],
})
export class UserCalendarComponent implements OnInit, OnDestroy, OnChanges {
  @Input() hide: any;
  @Input() assignment: any;
  data: any = null;
  weekArray: any = [];
  daysArray: any = [];
  dateTime: any = [];
  maxDay: any = 0;
  minDay: any = 0;
  userId: any = null;
  shifts: any[] = [];
  openShifts: any[] = [];
  chosenDate: string | undefined;
  chosenDateArray: any[] = [];
  selectedDate: any = [];
  show: boolean;
  groupId: number;
  show2: boolean;
  positionsSelect: any[] = [];
  activities: any[] = [];
  selectedShifts: any[] = [];
  getClickedShifts: any[] = [];
  actualDate = '';
  shiftType: any = [];
  correctMonth: any = null;
  fullMonth: string | undefined;
  getSentShift: any[] = [];
  dayNumberCalendar = 0;
  isLoading = true;

  userSubscription: Subscription;
  defaultDateSubscription: Subscription;
  calendarSubscription: Subscription;
  calendar2Subscription: Subscription;
  updateShiftSubscription: Subscription;

  constructor(
    private datePipe: DatePipe,
    private activityLogs: ActivitylogsService,
    private helper: HelperService,
    private employeesService: EmployeesService
  ) {
  }

  date: any = new Date(Date()); // TODO return to development
  toDay = new Date(Date()).toISOString().substring(0, 10);

  ngOnInit(): void {
    this.helper.emptyCalendarDropDown$.subscribe((x) => {
      if (x == true) {
        this.getClickedShifts = [];
      }
    });
    this.fullMonth = this.datePipe.transform(this.date, 'MMMM');
    this.groupId = Number(localStorage.getItem('gruopId'));
    this.userSubscription = this.helper.userId$.subscribe((data) => {
      this.userId = data;
      this.getCalendarFields(this.date);
    });
    this.date = new Date(this.date.setMonth(this.date.getMonth(), 15));

    this.getCalendarFields(this.date);

    this.defaultDateSubscription = this.helper.defaultDate$.subscribe(
      (back) => {
        if (back === 'back') {
          this.selectedDate = [];
          this.chosenDate = undefined;
          this.show = false;
        } else if (back === 'clear-calendar') {
          this.selectedDate = [];
          this.chosenDate = undefined;
          this.show = true;
        }
      }
    );

    this.calendarSubscription = this.helper.calendar$.subscribe((show) => {
      this.show = show;
    });

    this.calendar2Subscription = this.helper.calendar2$.subscribe((show) => {
      this.show2 = show;
    });

    this.updateShiftSubscription = this.helper.updateShiftsType$.subscribe(
      (shift) => {
        this.shiftType = shift;
        const foundDateIndex = this.getClickedShifts?.findIndex(
          (s: any) => s.id === this.shiftType.id
        );
        if (foundDateIndex !== -1) {
          this.getClickedShifts[foundDateIndex] = this.shiftType;
        }
      }
    );

    this.helper.sendShiftToCalendar$.subscribe((sentShift) => {
      const shift = {
        activity2Id: sentShift.activity2Id,
        activity1Id: sentShift.activityId,
        positionColor: sentShift.color,
        competence1Id: sentShift.competenceId,
        disclosedNote: sentShift.disclosedNote,
        start: sentShift.start,
        activity2Start: sentShift.start2,
        end: sentShift.end,
        activity2End: sentShift.end2,
        groupId: sentShift.groupId,
        id: sentShift.id,
        position: sentShift.positionId,
        employerNote: sentShift.undisclosedNote,
        shiftToCreate: true,
      };
      // this.getSentShift.push(shift); commented, not sure it should be this way
      this.getSentShift = [shift];
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.employeesService.userPositions().subscribe((positions) => {
      positions.forEach((group: any) => {
        if (
          group.groupId === Number(this.groupId) &&
          group.groupStatus === 'ACTIVE' &&
          group.rowStatus === 'ACTIVE'
        ) {
          this.positionsSelect.push(group);
        }
      });
    });

    this.employeesService.userActvity().subscribe((x) => {
      x.forEach((group: any) => {
        if (
          group.groupId === Number(this.groupId) &&
          group.groupStatus === 'ACTIVE'
        ) {
          this.activities.push(group);
        }
      });
    });
    this.hideCalendar();
  }

  private hideCalendar(): void {
    this.chosenDate = undefined;
  }

  // tslint:disable-next-line:typedef
  public previous() {
    // tslint:disable-next-line:no-unused-expression
    this.isLoading = true;
    console.log('Start: ', this.isLoading);
    this.date = new Date(this.date.setMonth(this.date.getMonth() - 1));
    this.fullMonth = this.datePipe.transform(this.date, 'MMMM');
    this.getCalendarFields(this.date);

    this.chosenDateArray.forEach((clickedDayInCalendar) => {
      this.isLoading = true;
      const clickedDays = this.datePipe.transform(
        clickedDayInCalendar,
        'yyyy-MM-dd'
      );
      const findClickedDays = this.shifts.findIndex(
        (day) => day.day === clickedDays
      );

      if (findClickedDays !== -1) {
        const chosenDate = this.shifts[findClickedDays]?.assignments[0]?.start;

        this.chosenDate = this.datePipe.transform(chosenDate, 'yyyy-MM-dd');
      }
      // this.isLoading = false;
    });
    // this.isLoading = false;
  }

  // tslint:disable-next-line:typedef
  public next() {
    // tslint:disable-next-line:no-unused-expression
    this.isLoading = true;
    this.date = new Date(this.date.setMonth(this.date.getMonth() + 1));
    this.fullMonth = this.datePipe.transform(this.date, 'MMMM');
    this.getCalendarFields(this.date);

    this.chosenDateArray.forEach((clickedDayInCalendar) => {
      this.isLoading = true;
      const clickedDays = this.datePipe.transform(
        clickedDayInCalendar,
        'yyyy-MM-dd'
      );
      const findClickedDays = this.shifts.findIndex(
        (day) => day.day === clickedDays
      );

      if (findClickedDays !== -1) {
        const chosenDate = this.shifts[findClickedDays]?.assignments[0]?.start;

        this.chosenDate = this.datePipe.transform(chosenDate, 'yyyy-MM-dd');
      }
      // this.isLoading = false
    });
    // this.isLoading = false;
  }

  // tslint:disable-next-line:typedef
  public getCalendarFields(date: any) {
    this.data = date;
    this.weekArray = [];
    this.daysArray = [];
    this.dateTime = [];
    this.maxDay = 0;
    this.minDay = 0;

    let firstDay: any = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay: any = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let monthStartsOnSunday = false;

    if (firstDay.getDate() == 1 && firstDay.getDay() == 0) { // if month starts on sunday
      monthStartsOnSunday = true;
    }
    this.correctMonth = firstDay.getMonth();
    const firstWeek = this.datePipe.transform(firstDay, 'w');
    const lastWeek = this.datePipe.transform(
      new Date(lastDay.setDate(lastDay.getDate() - 1)),
      'w'
    );
    this.minDay = firstDay.getMonth();
    this.maxDay = lastDay.getMonth();
    let numberBack = 0;
    let numberOf = 0;

    for (let i = 1; i < 10; i++) {
      let dates = firstDay.setDate(firstDay.getDate() - i);
      // tslint:disable-next-line:max-line-length
      if (
        this.datePipe.transform(new Date(dates), 'w') ===
        this.datePipe.transform(
          new Date(date.getFullYear(), date.getMonth(), 1),
          'w'
        )
      ) {
        numberBack = i;
        dates = firstDay.setDate(firstDay.getDate() + i);
      }
    }

    if (monthStartsOnSunday) {
      numberBack = 7;
    }

    for (let i = 1; i < 10; i++) {
      let dates = lastDay.setDate(lastDay.getDate() + i);
      // tslint:disable-next-line:max-line-length
      if (
        this.datePipe.transform(new Date(dates), 'w') ===
        this.datePipe.transform(
          new Date(date.getFullYear(), date.getMonth() + 1, 0),
          'w'
        )
      ) {
        numberOf = i;
        dates = lastDay.setDate(lastDay.getDate() - i);
      }
    }
    if (Number(firstWeek) < Number(lastWeek)) {
      this.weekArray = [];
      for (let i = Number(firstWeek); i <= Number(lastWeek); i++) {
        this.weekArray.push(i);
      }
    }
    if (Number(firstWeek) > Number(lastWeek)) {
      this.weekArray = [];
      this.weekArray.push(firstWeek);
      for (let i = 1; i <= Number(lastWeek); i++) {
        this.weekArray.push(i);
      }
    }
    firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    for (let i = numberBack - 1; i >= 1; i--) {
      const date = new Date(firstDay.setDate(firstDay.getDate() - i));
      this.dateTime.push(date);
      firstDay = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    }
    firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const allDays = this.getDates(firstDay, lastDay);

    for (const i of allDays) {
      // tslint:disable-next-line:no-shadowed-variable
      const date = new Date(i);
      this.dateTime.push(date);
    }

    for (let i = 1; i <= numberOf + 1; i++) {
      const date = new Date(lastDay.setDate(lastDay.getDate() + i));
      if (monthStartsOnSunday) {
        if (i <= numberOf) {
          this.dateTime.push(date);
        }
      } else {
        if (this.dateTime.length / this.weekArray.length < 7) {
          this.dateTime.push(date);
        }
      }

      lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    }
    firstDay = new Date(
      this.date.getFullYear(),
      this.date.getMonth() - 1,
      -numberBack + 2
    )
      .toJSON()
      .substring(0, 10);
    lastDay = new Date(
      this.date.getFullYear(),
      this.date.getMonth() + 2,
      numberOf + 1
    )
      .toJSON()
      .substring(0, 10);

    const now = moment(date);
    const startOfMonth = now.clone().startOf('month');
    const endOfMonth = now.clone().endOf('month');

    const firstVisibleDay = startOfMonth.clone().startOf('isoWeek');
    const lastVisibleDay = endOfMonth.clone().endOf('isoWeek');

    firstDay = firstVisibleDay.format('YYYY-MM-DD');
    lastDay = lastVisibleDay.format('YYYY-MM-DD');

    console.log('First Day of Month:', firstDay);
    console.log('Last Day of Month:', lastDay);


    this.activityLogs
      .getAssingmentsForAll(firstDay, lastDay)
      .subscribe((response: any) => {
        console.log('Start: ', this.isLoading);
        this.shifts = [];
        const groupIndex = response.groups.findIndex(
          (g: any) => g.group.id === this.groupId
        );
        if (groupIndex !== -1) {
          this.shifts = response.groups[groupIndex].dayAssignments;
        }
        this.isLoading = false;
        console.log('End: ', this.isLoading);
      });
  }

  findOpenShifts(date: string): any[] {
    date = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.openShifts = [];
    const foundDateIndex = this.shifts?.findIndex((s: any) => s.day === date);
    if (foundDateIndex && foundDateIndex !== -1) {
      this.openShifts = this.shifts[foundDateIndex].assignments.filter(
        (a: any) => {
          return a.status === 'PUBLISHED';
        }
      );
    }
    return this.openShifts;
  }

  distinctPositions(shifts: any[]): any[] {
    return [...new Set(shifts.map((item) => item.position))].sort(
      (n1, n2) => n1 - n2
    );
  }

  // tslint:disable-next-line:typedef
  public getDates(startDate: any, stopDate: any) {
    const dateArray = [];
    const currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  }

  // tslint:disable-next-line:typedef
  // @ts-ignore
  // tslint:disable-next-line:typedef
  public geColor(date: any) {
    let found = false;
    const color = '';
    if (this.shifts) {
      for (const shift of this.shifts) {
        const year = moment(date).format('YYYY');
        const month = moment(date).format('MM');
        const dan = moment(date).format('DD');

        // tslint:disable-next-line:max-line-length
        if (
          shift.day.substring(0, 4) === year &&
          shift.day.substring(5, 7) === month &&
          shift.day.substring(8, 10) === dan
        ) {
          // tslint:disable-next-line:radix

          // tslint:disable-next-line:radix max-line-length
          for (const i of shift.assignmentTimings) {
            // tslint:disable-next-line:radix max-line-length
            if (
              parseInt(shift.assignments[0]?.end.substring(11, 13)) * 60 +
              parseInt(shift.assignments[0]?.end.substring(14, 16)) <=
              parseInt(i.end.substring(0, 2)) * 60 +
              parseInt(i.end.substring(3, 5))
            ) {
              found = true;
              if (this.show2) {
                return false;
              } else {
                return '#' + i.color;
              }
            }
          }
        }
      }
    } else {
      return 'white';
    }
  }

  chooseDay(datem: any, dayNumber: number): void {
    if (dayNumber === 0 || dayNumber === 1) {
      this.dayNumberCalendar = 1;
    }
    if (this.actualDate != datem) { // if new date remove previous
      this.getSentShift = [this.getSentShift[0]];
    }

    let getClickedShifts: any[];
    getClickedShifts = this.findOpenShifts(datem);
    let specificArray: any[];
    specificArray = this.getSentShift;
    if (dayNumber <= 0) {
      const alreadyExistingShifts = this.selectedDate.filter((d: any) => {
        return new Date(d.date).getTime() == new Date(datem).getTime();
      });

      if (alreadyExistingShifts.length > 0) {
        this.dayNumberCalendar = alreadyExistingShifts[0].dayNumber;
        for (let i = 1; i < alreadyExistingShifts[0].dayNumber; i++) {
          specificArray.push(this.getSentShift[0]);
        }
      }

    }
    const newStandardShift = specificArray.length;
    if (newStandardShift === this.dayNumberCalendar) { // newStandardShift is how many shifts are already selected, dayNumber how many shifts for that day are selected
      this.getClickedShifts = getClickedShifts.concat(this.getSentShift);


      this.getClickedShifts = [...this.getClickedShifts];
    } else {
    }

    this.actualDate = datem;
    if (this.show) {
      this.chosenDate = this.datePipe.transform(datem, 'yyyy-MM-dd');
      this.chosenDateArray.push(this.chosenDate);
      const calendar = {
        date: datem,
        dayNumber: 1,
        active: true,
      };
      const findDateIndex = this.selectedDate.findIndex(
        (date: { active: boolean; date: string }) => {
          return (
            new Date(date.date).getTime() == new Date(datem).getTime() &&
            date.active != false
          );
        }
      );
      if (findDateIndex === -1) {
        this.selectedDate.push(calendar);

        this.selectedDate.forEach((day: { date: any }) => {
          const date = this.datePipe.transform(day.date, 'yyyy-MM-dd');
        });
      } else {
        if (this.selectedDate[findDateIndex].active === false) {
          this.selectedDate[findDateIndex].active = true;
          this.selectedDate[findDateIndex].dayNumber = 1;
        }
      }
    }

    setTimeout(() => {
      let shiftAmount = 0;
      this.selectedDate.forEach((s: any) => {
        if (s.active != false) {
          for (let i = 0; i < s.dayNumber; i++) {
            // @ts-ignore
            shiftAmount = shiftAmount + 1;
          }
        }
      });
      this.helper.selectedShifts.next(shiftAmount);
    }, 60);
    this.helper.getSelectedShifts(this.selectedDate);
    this.dayNumberCalendar = 0;
  }

  previousNumber(dateObj: any): void {
    setTimeout(() => {
      if (dateObj.dayNumber > 0) {
        dateObj.dayNumber = dateObj.dayNumber - 1;
        if (this.dayNumberCalendar > 0) {
          this.dayNumberCalendar = this.dayNumberCalendar - 1;
        }

        this.getClickedShifts.splice(-1, 1);

        if (dateObj.dayNumber === 0) {
          const clearDropDown = this.getClickedShifts.findIndex(
            (a) => a.shiftToCreate
          );
          if (clearDropDown !== -1) {
            this.getClickedShifts.splice(clearDropDown, 1);
          }

          const deleteDateIndex = this.selectedDate.findIndex(
            (date: { active: boolean; date: string; shiftToCreate: boolean }) =>
              date.date === dateObj.date && date.active === true
          );
          if (deleteDateIndex !== -1) {
            this.selectedDate[deleteDateIndex].active = false;
            this.chosenDate = undefined;
            this.getClickedShifts.splice(0);
            this.getSentShift.splice(1);
          }
        }
      }
    }, 50);
  }

  isSelected(date: string): boolean {
    const index = this.selectedDate.findIndex(
      (x: any) =>
        new Date(x.date).toDateString() === new Date(date).toDateString() &&
        x.active !== false
    );

    if (index === -1) {
      return false;
    } else {
      return true;
    }
  }

  shouldHaveBorder(date: any): boolean {
    if (
      this.datePipe.transform(this.actualDate, 'yyyy-MM-dd') ===
      this.datePipe.transform(date, 'yyyy-MM-dd') ||
      this.datePipe.transform(this.chosenDate, 'yyyy-MM-dd') ===
      this.datePipe.transform(date, 'yyyy-MM-dd')
    ) {
      return true;
    } else {
      return false;
    }
  }

  shouldHaveNumberField(date: any, filteredDate: any): boolean {
    if (
      this.datePipe.transform(filteredDate, 'yyyy-MM-dd') ===
      this.datePipe.transform(date, 'yyyy-MM-dd') ||
      this.datePipe.transform(this.chosenDate, 'yyyy-MM-dd') ===
      this.datePipe.transform(date, 'yyyy-MM-dd')
    ) {
      return true;
    } else {
      return false;
    }
  }

  filterSelectedDates(date: any): any[] {
    const hasOne = this.selectedDate.findIndex((x: any) => {
      return (
        x.active !== false &&
        new Date(date).getTime() == new Date(x.date).getTime()
      );
    });
    if (hasOne !== -1) {
      return [this.selectedDate[hasOne]];
    } else {
      return [];
    }
  }

  nextNumber(dateObj: any): void {
    this.dayNumberCalendar = dateObj.dayNumber + 1;
    if (dateObj.dayNumber < 10) {
      dateObj.dayNumber = dateObj.dayNumber + 1;
      this.getSentShift.push(this.getSentShift[0]);
    }
  }

  getColor(color: string): string {
    if (color.startsWith('#')) {
      return color;
    } else {
      return '#' + color;
    }
  }

  getPersonColor(shifts: any): string {
    let color = '';
    this.positionsSelect.forEach((person) => {
      if (person.id === shifts.position) {
        if (person.id) {
          color = '#' + person.color;
        }
      }
    });
    return color;
  }

  getActivityShortName(activityName: any): string {
    let shortName = '';
    this.activities.forEach((activity) => {
      if (activity?.id === activityName?.activity1Id) {
        if (activity?.id) {
          if (activity?.shortTitle !== null) {
            shortName = activity?.title + ' (' + activity?.shortTitle + ')';
          } else {
            shortName = activity?.title;
          }
        }
      }
    });
    return shortName;
  }

  getDropdownTIme(shifts: any): string {
    let startTime = '';
    let endTime = '';
    this.positionsSelect.forEach((person) => {
      if (person?.id === shifts?.position) {
        if (shifts?.shiftToCreate === true) {
          startTime = shifts?.start.replace(/-/g, '/');
          endTime = shifts?.end.replace(/-/g, '/');
        } else {
          startTime = this.datePipe.transform(shifts?.start.replace(/-/g, '/'), 'HH:mm');
          endTime = this.datePipe.transform(shifts?.end.replace(/-/g, '/'), 'HH:mm');
        }
      }
    });
    return startTime + ' - ' + endTime;
  }

  getShift(person: any): void {
    const shiftObj = {
      id: person.id,
      groupId: person.groupId,
      start: this.datePipe.transform(person.start.replace(/-/g, '/'), 'HH:mm').toString(),
      end: this.datePipe.transform(person.end.replace(/-/g, '/'), 'HH:mm').toString(),
      positionId: person.position,
      activityId: person.activity1Id,
      competenceId: person.competence1Id,
      undisclosedNote: person.undisclosedNote,
      disclosedNote: person.disclosedNote,
      activity2Id: person.activity2Id,
      start2: person.activity2Start
        ? this.datePipe.transform(person.activity2Start.replace(/-/g, '/'), 'HH:mm').toString()
        : '',
      end2: person.activity2End
        ? this.datePipe.transform(person.activity2End.replace(/-/g, '/'), 'HH:mm').toString()
        : '',
      color: person.positionColor,
      clinical: person.clinical,
      applicationsCount: person.applications,
      type: 2,
      status: person.status,
      date: this.datePipe.transform(person.start.replace(/-/g, '/'), 'yyyy-MM-dd').toString(),
    };

    localStorage.setItem('selectedShift', JSON.stringify(shiftObj));
    this.helper.shiftCalendarShift.next(shiftObj);
  }

  getPositionColor(color: any): any {
    let colorOfShift = '';
    const indexColor = this.getClickedShifts.findIndex(
      (findColor: any) => findColor?.id === color?.id
    );
    if (indexColor !== -1) {
      colorOfShift = color?.positionColor;
    }
    if (colorOfShift?.startsWith('##')) {
      return colorOfShift.substring(1);
    }
    if (colorOfShift?.startsWith('#')) {
      return colorOfShift;
    } else {
      return '#' + colorOfShift;
    }
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
    this.defaultDateSubscription?.unsubscribe();
    this.calendarSubscription?.unsubscribe();
    this.calendar2Subscription?.unsubscribe();
  }
}
