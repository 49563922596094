<div class="main-container">
  <div class="section-header">
    <div class="section-header__title-box">
      <div class="section-header__title noCapitalize">
        <span *ngIf="shift.status == 'PUBLISHED'">
        {{"openShift" | translate}}
          </span>

        <span *ngIf="shift.status == 'ASSIGNED'">
        {{shift.assignedToName}}
          </span>
        <span *ngIf="shift.status == 'CANCELED'">
        {{"canceledShift" | translate}}
          </span>
      </div>
    </div>
    <div class="section-header__button-box for-buttons">
      <button class="button back-button button--primary header__button" *ngIf="selectedShifts.length == 0"
              [routerLink]="['/schedule']">{{'backButton' | translate}}</button>
    <button class="button back-button button--primary header__button" *ngIf="multipleOptions && selectedShifts.length > 0" (click)="cancel()">{{'cancelButton' | translate}}</button>
    <button class="button back-button button--primary header__button continue-button" *ngIf="selectedShifts.length > 0 && !step2"  (click)="continue()">{{'continue' | translate}} ({{selectedShifts.length}})</button>
     <button class="button back-button button--primary header__button continue-button" [ngClass]="{'disabledButton': employees.length == 0}"  *ngIf="selectedAppType == 1" (click)="assignShift()">
       {{'assignShift' | translate}} <span *ngIf="selectedShifts.length > 0">({{selectedShifts.length}})</span>
     </button>
      <button class="button back-button button--primary header__button continue-button" [ngClass]="{'disabledButton': employees.length == 0}"  *ngIf="selectedAppType == 2" (click)="sendInvite()">
        {{'sendInvite' | translate}} <span *ngIf="selectedShifts.length > 0">({{selectedShifts.length}})</span>
      </button>
      <button class="button back-button button--primary header__button continue-button" [ngClass]="{'disabledButton': employees.length == 0}" *ngIf=" selectedAppType == 3" (click)="sendRequest()">
        {{'sendRequest' | translate}} <span *ngIf="selectedShifts.length > 0">({{selectedShifts.length}})</span>
      </button>
    </div>
  </div>
  <div class="main-structure">
  <div class="upper-part">
    <app-create-new-shift-details [customTwo]="'realShift'" [assignment]="shift" *ngIf="!multipleOptions"></app-create-new-shift-details>
    <div class="shifts-list" *ngIf="multipleOptions">
    <div class="shifts-list-title">
     <span *ngIf="selectedShifts.length > 0"> {{'selectedShifts' | translate}} </span>
      <span *ngIf="selectedShifts.length == 0"> {{'noShiftsSelected' | translate}} </span>
    </div>
    <div class="shifts-only">
      <div class="shift" *ngFor="let s of sortSelectedShifts()">
        <div class="for-circle-and-activity partForText">
        <div class="shift-circle" [style.backgroundColor]="s.positionColor.startsWith('#') ? s.positionColor : '#' + s.positionColor"></div>
          <div class="shift-activity">{{s.activity1Title}}</div>
        </div>
        <div class="time partForText">
          {{formatTime(s.start)}} - {{formatTime(s.end)}}
        </div>
        <div class="date partForText">
          {{formatDate(s.start)}}
        </div>
        <mat-icon class="close" (click)="remove(s)">close</mat-icon>
      </div>
    </div>
<!--      <div class="shift-button">-->
<!--        <button style="width:100px" (click)="cancel()" class="button button&#45;&#45;primary">{{'cancel' | translate}}</button>-->
<!--      </div>-->
    </div>

    <div class="calendar-container" style="position:relative;">
        <app-loading class="spinner overlay" *ngIf="isLoading"></app-loading>
        <app-shift-calendar (dataLoaded)="dataLoadedHandler()" (dataLoadSave)="dataLoaderShow()" class="calendar"></app-shift-calendar>
    </div>
    </div>
  <div class="for-bottom-part" *ngIf="!hideManageApplications && (!multipleOptions || step2)">
    <app-manage-application  [shift]="shift"  *ngIf="shift.status == 'PUBLISHED'"></app-manage-application>
  </div>

</div>
</div>
