import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShiftsService } from '../../../shared/services/shifts.service';
import { HelperService } from '../../../shared/services/helper.service';

@Component({
  selector: 'app-create-shift-type-modal',
  templateUrl: './create-shift-type-modal.component.html',
  styleUrls: ['./create-shift-type-modal.component.scss'],
})
export class CreateShiftTypeModalComponent implements OnInit {
  create = false;
  update = true;
  // clientUpdate = false;
  stayOnEdit: string | undefined;

  constructor(
    public dialogRef: MatDialogRef<CreateShiftTypeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shiftService: ShiftsService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    if (this.data.mode === 'create_new_shift') {
      this.create = true;
    } else if (this.data.mode === 'update-client') {
      // this.clientUpdate = true;
    }
    this.stayOnEdit = this.data.edit;
  }

  createShiftType(): void {
    const create = {
      name: this.data.createNewShift.name,
      groupId: this.data.createNewShift.groupId,
      start: this.data.createNewShift.start,
      end: this.data.createNewShift.end,
      color: this.data.createNewShift.color,
      start2: this.data.createNewShift.start2,
      end2: this.data.createNewShift.end2,
      positionId: this.data.createNewShift.positionId,
      activityId: this.data.createNewShift.activityId,
      activity2Id: this.data.createNewShift.activity2Id,
      competenceId: this.data.createNewShift.competenceId,
      undisclosedNote: this.data.createNewShift.undisclosedNote,
      disclosedNote: this.data.createNewShift.disclosedNote,
      id: this.data.createNewShift.id,
    };
    this.shiftService.createAssignmentType(create).subscribe((result) => {
      if (result && result !== false) {
        this.dialogRef.close(result);
        this.helperService.mode$.next(3);
      }
    });
  }

  editShiftType(): void {
    if (this.data.mode === 'update') {
      this.shiftService
        .editAssignment(this.data.updateType)
        .subscribe((data: any) => {
          this.dialogRef.close(data);
          this.helperService.updateShift$.next(data);
        });
    } else {
      const edit = {
        name: this.data.editType.name,
        groupId: this.data.editType.groupId,
        start: this.data.editType.start,
        end: this.data.editType.end,
        color: this.data.editType.color,
        start2: this.data.editType.start2,
        end2: this.data.editType.end2,
        positionId: this.data.editType.positionId,
        activityId: this.data.editType.activityId,
        activity2Id: this.data.editType.activity2Id,
        competenceId: this.data.editType.competenceId,
        undisclosedNote: this.data.editType.undisclosedNote,
        disclosedNote: this.data.editType.disclosedNote,
        id: this.data.editType.id,
      };

      this.shiftService.updateAssignmentType(edit).subscribe((result) => {
        if (result && result !== false) {
          if (!this.stayOnEdit) {
            const mode = 4;
            this.helperService.mode$.next(mode);
          }
          this.dialogRef.close(result);
          setTimeout(() => {
            this.helperService.showCalendar2$.next(true);
          }, 100);
        }
      });
    }
  }

  editClient(): void {
    this.dialogRef.close(this.data.editType);
  }

  closeModal(): void {
    setTimeout(() => {
      this.helperService.showCalendar$.next(false);
    }, 300);
    this.dialogRef.close();
  }
}
