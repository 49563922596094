<div class="colorForBackground">
  <div class="padding-for-dialog">
    <img _ngcontent-gfy-c97="" src="../../../../assets/images/Close.png" (click)="cancel()" class="create-user__image locationX">

    <p class="create_new_group">{{'addGroup' | translate}}</p>
    <p class="group_name">{{'group_name' | translate}} <br>
    <span>{{'newGroupText' | translate}}</span></p>
    <input type="text" class="form-name" [(ngModel)]="groupValue.name">


    <p class="group_description">{{'description' | translate}} <br>
      <span>{{'group_description' | translate}}</span>
    </p>
    <textarea name="" class="description_textarea" id="" cols="30" rows="10" [(ngModel)]="groupValue.description"></textarea>

    <div class="two_buttons">
      <button class="cancel" (click)="cancel()">{{'cancelButton' | translate}}</button>
      <button class="save" (click)="create()">{{'save' | translate}}</button>
    </div>
  </div>
</div>
