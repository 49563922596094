<section class="copy-dialog">
  <img class="copy-dialog__image" src="../../../../assets/images/Close.png" (click)="closeModal(null)"/>
  <div class="copy-dialog__title">{{ "assignShift" | translate }}</div>
  <div  class="copy-dialog__subtitle">{{ "tempUser" | translate }}</div>
  <div class="copy-dialog__message">{{ "tempUserText" | translate }}</div>

  <div class="for-form">
    <div class="form__inputs-boxes form__inputs-boxes--end">
      <div class="form__inputs-box">
        <label class="form__label">{{'firstName' | translate}}</label>
        <input class="input form__input" type="text" [(ngModel)]="firstName">
      </div>
    </div>
    <div class="form__inputs-boxes form__inputs-boxes--end">
      <div class="form__inputs-box">
        <label class="form__label">{{'lastName' | translate}}</label>
        <input class="input form__input" type="text" [(ngModel)]="lastName">
      </div>
    </div>
    <div class="form__inputs-boxes form__inputs-boxes--end">
      <div class="form__inputs-box">
        <label class="form__label">{{'company' | translate}}</label>
        <input class="input form__input" type="text" [(ngModel)]="company" placeholder="{{'optional' | translate}}">
      </div>
    </div>
    <div class="form__inputs-boxes form__inputs-boxes--end">
      <div class="form__inputs-box">
        <label class="form__label">{{'mobile' | translate}}</label>
        <input class="input form__input" type="text" [(ngModel)]="mobile" placeholder="{{'optional' | translate}}">
      </div>
    </div>
  </div>

<div class="for-buttons">
  <button class="button button--primary" style="margin-right: 10px; width: 100px"
          (click)="closeModal(null)">{{'cancelButton' | translate}}
  </button>
  <button  class="button button--blue" style="width: 100px" (click)="confirm()"
  >{{'confirm' | translate}}</button>
</div>
</section>
