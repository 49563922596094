<section class="copy-dialog">
  <img class="copy-dialog__image" src="../../../../assets/images/Close.png" (click)="closeModal(null)"/>
  <div class="copy-dialog__title">{{ data.title }}</div>
  <div *ngIf="delete || deleteShow || data.isCreateUser" class="copy-dialog__subtitle">{{ data.subtitle }}</div>
  <div class="copy-dialog__message">{{ data.message }}</div>
  <div class="copy-dialog__button-box" [ngClass]="{'delete': delete}">
    <button class="button button--primary" style="margin-right: 10px; width: 100px" *ngIf="!data.isCreateUser"
            (click)="closeModal(null)">{{'cancelButton' | translate}}
    </button>
    <button *ngIf="copy" class="button button--blue" style="width: 100px" (click)="confirmCopy()"
    >{{'confirm' | translate}}</button>
    <button *ngIf="delete === true || shift=== true" class="button button--red" style="width: 100px"
            (click)="deleteAssignmentShift()">{{'delete' | translate}}</button>
    <button *ngIf="deleteShow === true" class="button button--red" style="width: 100px"
            (click)="justCloseDelete()">{{'confirm' | translate}}</button>
    <button *ngIf="announcement === true" class="button button--red" style="width: 100px"
            (click)="deleteAnnouncement()">{{'confirm' | translate}}</button>
    <button *ngIf="assign === true" class="button button--blue" style="width: 100px"
            (click)="confirmAssignment()">{{'confirmed' | translate}}</button>
    <button *ngIf="invite === true" class="button button--blue" style="width: 100px"
            (click)="confirmInvite()">{{'confirm' | translate}}</button>
    <button *ngIf="request === true" class="button button--blue" style="width: 100px"
            (click)="confirmRequest()">{{'confirm' | translate}}</button>
    <button *ngIf="acceptApp === true" class="button button--blue" style="width: 100px"
            (click)="confirmAcceptApp()">{{'accept' | translate}}</button>
    <button *ngIf="declineApp === true" class="button button--red" style="width: 100px"
            (click)="confirmDeclineApp()">{{'decline' | translate}}</button>
    <button *ngIf="recovery === true" class="button button--blue" style="width: 100px"
            (click)="recoveryShift()">{{'confirm' | translate}}</button>
    <button *ngIf="updateProfile === true && !loading" class="button button--blue" style="width: 100px"
            (click)="updateProfileShift()">{{'confirm' | translate}}</button>
    <button *ngIf="loading" class="button button--blue loader-button" style="width: 100px">
      <app-mini-loader class="miniLoader"></app-mini-loader>
    </button>

    <span class="for-right-buttons" *ngIf="data.isCreateUser">
          <button class="button button--primary" style="margin-right: 10px; width: 100px"
                  (click)="closeModal(null)">{{'cancelButton' | translate}}
    </button>
          <button *ngIf="data.isCreateUser == true" class="button button--blue" style="width: 100px"  (click)="createNewUser()">{{'confirmed' | translate}}</button>
    </span>
  </div>
</section>

