<div class="details">
  <img class="close-modal" src="../../../../assets/images/Close.png" *ngIf="this.person?.id"
       (click)="backToUser()"/>
  <div class="main-details" [ngStyle]="{'margin': this.person?.id ? '0' : '20px' }"
       [class.background]="!toggleChat"
       [class.height]="!fromDayView"
  >
    <ng-container *ngIf="toggleChat; else chat">
      <div class="main-details__profile"
           [class.profile-height]="fromDayView">
        <div class="main-details__title">{{'userProfile' | translate}}</div>
        <div class="main-details__user-detail">
          <div class="main-details__left-detail">
            <div class="main-details__image-box">
              <img class="main-details__image" src="../assets/images/profile_white.svg"
                   *ngIf="!user?.profilePicture || user?.profilePicture == ''"/>
              <img class="main-details__image user-real-image" [src]="user?.profilePicture"
                   *ngIf="user?.profilePicture && user?.profilePicture != ''"/>
            </div>
            <div class="main-details__text-box">
              <div class="main-details__name" [class.ios-name]="!fromDayView">{{firstName}} {{lastName}}</div>
              <div class="main-details__sub" [ngClass]="{'main-details__sub__red': sub === 'Användaren raderad'}" >{{sub}}</div>
            </div>
          </div>
          <ng-container *ngIf="hideIcons === false && !userDeleted">
            <div class="buttons" *ngIf="update == false">
              <div class="buttons__image-box" *ngIf="loggedRole != 'COORDINATOR'">
                <img class='buttons__image' (click)="openAbsence()" src="../assets/images/schedule/User_Blocked.svg"/>
              </div>
              <div class="buttons__image-box" (click)="openGroup()">
                <img class='buttons__image' src="../assets/images/users_svg.svg"/>
              </div>
              <div class="buttons__image-box buttons__image-box--margin" (click)="switchToChat()" *ngIf="loggedRole != 'COORDINATOR'">
                <img class='buttons__image' src="../assets/images/chat_svg.svg"/>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="hideIcons === true && toHideIcons != true">
            <div class="buttons" *ngIf="update == false">
              <div class="buttons__image-box" (click)="routeToUser()" style="margin-right: 0 !important;">
                <img class='buttons__image' src="../assets/images/personal/User_Single.svg"/>
              </div>
<!--              <div class="buttons__image-box buttons__image-box&#45;&#45;margin" (click)="openChat()">-->
<!--                <img class='buttons__image' src="../assets/images/chat_svg.svg"/>-->
<!--              </div>-->

            </div>
          </ng-container>
        </div>
      </div>
      <form [formGroup]="editEmployer">
        <div class="form" style="margin-bottom: 25px;">
          <div class="main-details__form-box">
            <div class="main-details__info-text">{{'infoAboutUserProfile' | translate}}</div>
            <div *ngIf="update" style="height: 100%; width: 12px;margin: 2px 0 0 6px;">
              <img class="main-details__image-info" src="../assets/images/Info symbol.svg"/>
            </div>
          </div>
          <div class="form__boxes" [class.ios-boxes]="!fromDayView">
            <div class="form__inputs-boxes">
              <div class="form__inputs-box">
                <label class="form__label" [class.ios-label]="!fromDayView">{{'firstName' | translate}}</label>
                <input [readonly]="isLoggedIn || !update || userDeleted" class="input form__input" [class.ios-input]="!fromDayView"
                       [ngStyle]="update && isLoggedIn ? {'color':'#C2C2C2'} : {'color':'#333333'}"
                       type="text"
                       formControlName="firstname">
              </div>
            </div>
            <div class="form__inputs-boxes">
              <div class="form__inputs-box">
                <label class="form__label-down">{{'email' | translate}}</label>
                <input class="input form__input" [class.ios-input]="!fromDayView"
                       [ngStyle]="update && isLoggedIn || userDeleted ? {'color':'#C2C2C2'} : {'color':'#333333'}"
                       type="text" formControlName="email"
                       placeholder="{{'email' | translate}}" [readonly]="isLoggedIn || !update">
              </div>
            </div>
            <div class="form__inputs-boxes form__inputs-boxes--end" [class.ios-input-boxes-end]="!fromDayView">
              <div class="form__inputs-box">
                <label class="form__label" [class.ios-label]="!fromDayView">{{'lastName' | translate}}</label>
                <input [readonly]="isLoggedIn || !update || userDeleted" class="input form__input" [class.ios-input]="!fromDayView"
                       [ngStyle]="update && isLoggedIn ? {'color':'#C2C2C2'} : {'color':'#333333'}"
                       type="text" formControlName="lastname">
              </div>
            </div>
            <div class="form__inputs-boxes form__inputs-boxes--end" [class.ios-input-boxes-end]="!fromDayView">
              <div class="form__inputs-box">
                <label class="form__label-down">{{'Mobil' | translate}}</label>
                <input [readonly]="isLoggedIn || !update || userDeleted" class="input form__input" [class.ios-input]="!fromDayView"
                       [ngStyle]="update && isLoggedIn ? {'color':'#C2C2C2'} : {'color':'#333333'}"
                       type="text" formControlName="mobile">
              </div>
            </div>
          </div>
        </div>
        <div class="form" style="margin-bottom: 25px;">
          <div class="main-details__form-box">
            <div class="main-details__info-text">{{'userQualifications' |translate}}</div>
            <div *ngIf="update" style="height: 100%; width: 12px;margin: 2px 0 0 6px;">
              <img class="main-details__image-info" src="../assets/images/Info symbol.svg"/>
            </div>
          </div>
          <div class="form__boxes" [class.ios-boxes]="!fromDayView">
            <div class="form__inputs-boxes">
              <div class="form__inputs-box">
                <label class="form__label" [class.ios-label]="!fromDayView">{{'position' | translate}}</label>
                <input class="input form__input form__input--arrow" [class.ios-input-arrow]="!fromDayView"
                       [class.ios-input]="!fromDayView"
                       (click)="displayPositions()"
                       (clickOutside)="showPositions = false"
                       [readOnly]="userDeleted"
                       formControlName="position"
                       readonly
                />
                <div class="form__select form__select--positions"
                     [class.mediaLg-positions]="!fromDayView" *ngIf="showPositions">
                  <div class="form__select-option" *ngFor="let position of sortPositions()"
                       (click)="getPosition(position)">
                    <div>{{position.title}}</div>
                    <img *ngIf="position?.id === positionId" class="check-mark"
                         src="../../../assets/images/Checkmark.svg"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="form__inputs-boxes">
              <div class="form__inputs-box">
                <label class="form__label-down">{{'competence' | translate}}</label>
                <input class="input form__input form__input--arrow" [class.ios-input-arrow]="!fromDayView"
                       [class.ios-input]="!fromDayView"
                       [readOnly]="userDeleted"
                       (click)="displayCompetence()"
                       (clickOutside)="showCompetence = false"
                       formControlName="competence"
                       readonly
                />
                <div class="form__select form__select--competence"
                     [class.mediaLg-competence]="!fromDayView" *ngIf="showCompetence">
                  <div class="form__select-option" (click)="getCompetence(null)">
                    {{'noCompetence' | translate}}
                  </div>
                  <div class="form__select-option" *ngFor="let competence of sortCompetences()"
                       (click)="getCompetence(competence)">
                    <div>{{competence.title}}</div>
                    <img *ngIf="competenceSelected(competence)" class="check-mark"
                         src="../../../assets/images/Checkmark.svg"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="form__inputs-boxes form__inputs-boxes--textArea"  *ngIf="loggedRole != 'COORDINATOR'">
              <label class=" form__label form__label--padding"
                     [class.ios-label-padding]="!fromDayView"
                     [class.ios-label]="!fromDayView">{{'notes' | translate}}</label>
              <div class="form__inputs-boxes form__inputs-boxes--end" [class.ios-input-boxes-end]="!fromDayView">
                <div class="form__inputs-box">
                  <textarea class="form__text-area note" formControlName="note"
                            [readOnly]="!update || userDeleted"
                            [class.users-text-area]="!fromDayView"

                  >
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form">
          <div class="main-details__form-box">
            <div class="main-details__info-text">{{'userDepartmentSettings' |translate}}</div>
            <div *ngIf="update" style="height: 100%; width: 12px;margin: 2px 0 0 6px;">
              <img class="main-details__image-info" src="../assets/images/Info symbol.svg"/>
            </div>
          </div>
          <div class="form__boxes" [class.ios-boxes]="!fromDayView">
            <div class="form__inputs-boxes">
              <div class="form__inputs-box">
                <label class="form__label">{{'contract' | translate}}</label>
                <input class="input form__input form__input--arrow" [class.ios-input-arrow]="!fromDayView"
                       [class.ios-input]="!fromDayView"
                       (click)="displayContract()"
                       (clickOutside)="showContract = false"
                       [readOnly]="userDeleted"
                       formControlName="contract"
                       readonly
                />
                <div class="form__select form__select--positions "
                     [class.mediaLg-contract]="!fromDayView" *ngIf="showContract">
                  <div class="form__select-option" *ngFor="let contract of sortContracts()"
                       (click)="getContract(contract)">
                    <div>{{contract.title}}</div>
                    <img *ngIf="contract?.id === contractId" class="check-mark"
                         src="../../../assets/images/Checkmark.svg"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="form__inputs-boxes">
              <div class="form__inputs-box">
                <label class="form__label-down">{{'roll' | translate}}</label>
                <input class="titlecase input form__input form__input--arrow" [class.ios-input-arrow]="!fromDayView"
                       [class.ios-input]="!fromDayView"
                       (click)="displayRoll()"
                       (clickOutside)="showRoll = false"
                       [readOnly]="userDeleted"
                       formControlName="role"
                       readonly
                />
                <div class="form__select form__select--roll"
                     [class.mediaLg-roll]="!fromDayView" *ngIf="showRoll">
                  <div class="form__select-option" [ngClass]="{'not-selectable': !isSelectable(roll.roll)}" *ngFor="let roll of rolls" (click)="getRole(roll.roll)"
                  >
                    <div>{{roll.name | titlecase}}</div>
                    <img *ngIf="isSelectedRole(roll)" class="check-mark"
                         src="../../../assets/images/Checkmark.svg"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="form__inputs-boxes form__inputs-boxes--end" [class.ios-input-boxes-end]="!fromDayView">
              <div class="form__inputs-box">
                <label class="form__label" [class.ios-label]="!fromDayView">{{'status' | translate}}</label>
                <input class="titlecase input form__input form__input--arrow" [class.ios-input-arrow]="!fromDayView"
                       [class.ios-input]="!fromDayView"
                       (click)="displayStatus()"
                       (clickOutside)="showStatus = false"
                       [value]="status"
                       [readOnly]="userDeleted"
                       formControlName="status"
                       readonly
                />
                <div class="form__select form__select--status"
                     [class.mediaLg-status]="!fromDayView" *ngIf="showStatus">
                  <div class="form__select-option" *ngFor="let status of statusSelect" (click)="getStatus(status)">
                    <div>{{status.status | translate | titlecase}}</div>
                    <img *ngIf="status?.status === rowStatus" class="check-mark"
                         src="../../../assets/images/Checkmark.svg"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form__button-box" *ngIf="!update && toHideIcons != true && !userDeleted">
          <button class="button form__button" [class.ios-button]="!fromDayView" type="button"
                  (click)="update = true">{{'change' | translate}}</button>
        </div>
        <div class="button-row">
          <div class="form__button-box" *ngIf="update">
            <button class="button form__button" [class.ios-button]="!fromDayView" type="button"
                    (click)="cancelUpdate()">{{'cancelButton' | translate}}</button>
          </div>
          <div class="form__button-box" *ngIf="update">
            <button class="button form__button save-button" [class.ios-button]="!fromDayView" type="button"
                    (click)="save()">{{'save' | translate}}</button>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-template #chat>
      <app-chat (back)="closeChat()"></app-chat>
    </ng-template>
  </div>
</div>


