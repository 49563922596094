import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthConst } from './services/const/authConst';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `${localStorage.getItem(AuthConst.token)}`,
        'worksiteId': `${localStorage.getItem(AuthConst.gruopId)}`,
        'Access-Control-Allow-Origin': '*'
      },
      withCredentials: true
    });

    return next.handle(request);
  }
}
