import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {
  ActivityListResponse,
  ActivityLog,
} from '../../../shared/models/activitylogs.model';
import {ActivitylogsService} from '../../../shared/services/activitylogs.service';
import {SwapDialogComponent} from '../swap-dialog/swap-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Swap} from '../../../shared/models/swap.model';
import {Filter} from '../../../shared/models/filter.model';
import {ShiftsService} from '../../../shared/services/shifts.service';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
import {HelperService} from '../../../shared/services/helper.service';
import {EmployeesService} from '../../../shared/services/employees.service';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthConst} from '../../../shared/services/const/authConst';
import {ShiftNoteComponent} from '../../modals/shift-note/shift-note.component';
import {DetailsShiftComponent} from '../../../details-shift/details-shift.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-activity-logs-list',
  templateUrl: './activity-logs-list.component.html',
  styleUrls: ['./activity-logs-list.component.scss'],
})
export class ActivityLogsListComponent implements OnInit, OnDestroy {
  logResponse: ActivityListResponse = {dayActivityLogs: []};
  activityLogs: ActivityLog[] = [];
  page = 0;
  filter: Filter = {searchText: '', type: 0, types: []};
  searchText = '';
  isEmpty = false;
  isInTimeout = false;
  loading = false;
  openType = false;
  showSort = false;
  sortId: number;
  filterTypes = '';
  targetDates: any[] = [];
  allLogs: ActivityLog[] = [];

  // selectedSort = this.translate.instant('sortByAvailability');
  types = [
    // { id: 0, name: 'allEvents' },
    {id: 1, name: 'applicationsRequests'},
    {id: 3, name: 'cancellations'},
    {id: 4, name: 'swaps'},
    {id: 2, name: 'assignedShifts'},


  ];
  showableLogs: ActivityLog[] = [];
  total = 0;

  allEventsChecked = true;
  selectedEvents = '';

  sort = [
    {
      id: 1,
      name: 'Sortera på datum',
    },
    {
      id: 2,
      name: 'Sortera på status',
    },
  ];

  sortName = this.sort[0].name;

  getPositionColorSubscription: Subscription;

  constructor(
    private employeesService: EmployeesService,
    private sanitizer: DomSanitizer,
    private helper: HelperService,
    private router: Router,
    private datePipe: DatePipe,
    private activityLogService: ActivitylogsService,
    private dialog: MatDialog,
    private shiftService: ShiftsService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('logs')) {
      const messagesForAll: any[] = JSON.parse(localStorage.getItem('logs'));
      messagesForAll.forEach((data: any) => {
        if (data.groupId == Number(localStorage.getItem(AuthConst.gruopId))) {
          this.showableLogs = data.logs;
        }
      });

    }
    localStorage.removeItem(AuthConst.scheduleDate);
    this.loading = true;
    this.getActivityLogs(true);

    this.getPositionColorSubscription = this.helper.clickedSection.subscribe(
      (page) => {
        if (page && page == '/activity') {
          this.page = 0;
          this.searchText = '';
          this.filter.searchText = '';
          this.filter.type = 0;
          this.getActivityLogs(true);
        }
      }
    );
  }

  findType(): string {
    const index = this.types.findIndex((t) => t.id === this.filter.type);

    if (index != -1) {
      return this.types[index].name;
    } else {
      return '';
    }
  }

  setType(type: { id: number; name: string }, event: any): void {
    event.preventDefault();
    this.filter.type = type.id;

    if (type.id === -1) {
      this.allEventsChecked = true;
      this.filter.types = [];
      this.showableLogs = this.showableLogs;
    } else {
      this.allEventsChecked = false;
    }

    const selectedCheckedIndex = this.filter.types.findIndex(
      (c: any) => type.id === c
    );
    if (selectedCheckedIndex === -1) {
      if (type.id !== -1) {
        this.filter.types.push(type.id);
      }
    } else {
      // this.showableLogs = [];
      this.filter.types.splice(selectedCheckedIndex, 1);
      if (this.filter.types.length === 0) {
        this.allEventsChecked = true;
        this.showableLogs = this.showableLogs;
        this.filter.types = [];
      }
    }
    this.filterTypes = '';
    this.filter.types.forEach((t: any, tipIndex: number) => {
      const typeIndex = this.types.findIndex((tip: any) => tip.id == t);
      if (typeIndex !== -1) {
        if (this.filterTypes.length === 0) {
          this.filterTypes = this.filterTypes + this.translate.instant(this.types[typeIndex].name);
        } else if (this.filter.types.length === this.types.length) {
          this.filterTypes = this.translate.instant('severalOptions');
          this.allEventsChecked = false;
        } else if (this.filterTypes.length > 1) {
          this.filterTypes = this.translate.instant('severalOptions');
        }
      }
    });

    this.getActivityLogs(true);
    // this.openType = false;
  }

  isCancelled(log: any): boolean {
    if (log.header[1] && log.header[1].text && log.header[1].text.includes('har avbokat') && log.header[3] && log.header[3].text && log.header[3].text.includes('pass')) {
      return true;
    } else {
      return false;
    }
  }

  changedValue(): void {
    if (!this.isInTimeout) {
      this.isInTimeout = true;
      setTimeout(() => {
        this.page = 0;

        if (this.total == 0) {
          this.loading = false;
          this.isEmpty = true;
        }
        this.filter.searchText = this.searchText;

        this.getActivityLogs(true);
        this.isInTimeout = false;
      }, 1000);
    }
  }

  setActivityLogsToLocaleStorage(logs: any): void {
    const groupId = Number(localStorage.getItem(AuthConst.gruopId));
    const obj = {
      groupId, logs
    };

    let existingLogs: any = localStorage.getItem('logs');
    if (existingLogs) {
      existingLogs = JSON.parse(existingLogs);
      const groupIndex = existingLogs.findIndex((m: any) => {
        return m.groupId == groupId;
      });

      if (groupIndex == -1) {
        existingLogs.push(obj);
      } else {
        existingLogs[groupIndex] = obj;
      }

    } else {
      existingLogs = [obj];
    }

    localStorage.setItem('logs', JSON.stringify(existingLogs));
  }

  getActivityLogs(isNew: boolean, readLogs: boolean = true): void {
    this.loading = true;
    const groupId = localStorage.getItem('gruopId');
    this.activityLogService
      .getTotal(this.filter, groupId)
      .subscribe((response: any) => {
        this.total = response;
      });

    this.activityLogService
      .getActivityLogs(this.page, this.filter, groupId, readLogs)
      .subscribe((data: ActivityListResponse) => {
        this.logResponse = null;
        this.logResponse = data;


        this.loading = true;
        this.showableLogs = [];


        // @ts-ignore
        this.logResponse.dayActivityLogs.forEach((dayLog) => {
          // @ts-ignore
          dayLog.activityLogs.forEach((log) => {
            log.date = dayLog.date + ' ' + log.time;
            this.showableLogs.push(log);
          });

          if (this.page == 0) {
            this.setActivityLogsToLocaleStorage(this.showableLogs);
          }
        });

        if (this.showableLogs.length == 0) {
          this.loading = false;
          this.isEmpty = true;
        }

        if (this.filter.sort == '2') {
          this.showableLogs.sort((a1: any, a2: any) => a2.buttonPriority - a1.buttonPriority);
        }
        this.loading = false;


      });
  }

  onScroll(): void {
    this.page++;
    this.getActivityLogs(false);
  }

  findDeclinedLog(targetId: any): boolean {
    let value = false;
    this.allLogs.forEach((l: any) => {
      if (l.targetId == targetId) {
        l.header.forEach((h: any) => {
          if (h.text == ' har nekat ') {
            value = true;
          }
        });
      }
    });
    return value;
  }

  openLog(log: ActivityLog): void {
    this.activityLogService.markActivityLogAsRead(log.id).subscribe();
    log.status = 'READ';
    // tslint:disable-next-line:triple-equals
    if (log.targetType == 'SWAP') {
      // @ts-ignore
    }
  }

  public openPage(page: number): void {
    if (page < 0) {
      page = 0;
    }

    if (page != this.page && page + 1 <= this.getLastPage()) {
      this.page = page;

      this.getActivityLogs(true);
    }
  }

  public getLastPage(): number {
    //divided by 16 bc we show 9 orders per page
    return Math.ceil(this.total / 20);
  }

  openShift(log: any): void {
    if (log.targetType === 'EMPLOYEE_INVITATIONS' || log.targetType == 'DETAILS') {
      this.shiftService
        .getAssignmentById(log.targetId)
        .subscribe((data: any) => {
          const shiftObj = {
            id: data.id,
            name: '',
            groupId: data.groupId,
            start: this.datePipe.transform(data.start.replace(/-/g, '/'), 'HH:mm').toString(),
            end: this.datePipe.transform(data.end.replace(/-/g, '/'), 'HH:mm').toString(),
            positionId: data.position,
            activityId: data.activity1Id,
            competenceId: data.competence1Id,
            undisclosedNote: data.undisclosedNote,
            disclosedNote: data.disclosedNote,
            activity2Id: data.activity2Id,
            start2: data.activity2Start
              ? this.datePipe.transform(data.activity2Start.replace(/-/g, '/'), 'HH:mm').toString()
              : '',
            end2: data.activity2End
              ? this.datePipe.transform(data.activity2End.replace(/-/g, '/'), 'HH:mm').toString()
              : '',
            color: data.positionColor,
            clinical: data.clinical,
            applicationsCount: data.applications,
            type: 2,
            status: data.status,
            date: this.datePipe.transform(data.start.replace(/-/g, '/'), 'yyyy-MM-dd').toString(),
          };
          localStorage.setItem('selectedShift', JSON.stringify(shiftObj));
          this.router.navigate(['assignment/details']);
        });
    } else if (log.targetType == 'SWAP') {
      this.activityLogService
        .getActivitySwap(log.id)
        .subscribe((swapData: Swap) => {
          const dialogRef = this.dialog.open(SwapDialogComponent, {
            backdropClass: 'backdropBackground',
            panelClass: 'swap-dialog-container',
            disableClose: true,
            width: '400px',
            height: '300px',
            position: {
              top: '100px',
            },
            data: {swap: swapData},
          });

          dialogRef.afterClosed().subscribe((res: any) => {
            if (res == 'refresh') {
              this.getActivityLogs(true);
            }
          });
        });
    }
  }

  goToSchedule(log: any): void {
    this.shiftService
      .getAssignmentById(log.targetId)
      .subscribe((data: any) => {
        localStorage.removeItem('dayview-lists');
        localStorage.removeItem('dayview-shifts');
        localStorage.removeItem('dayview-positions');
        const dateFromLog = new Date(data.start.replace(/-/g, '/'));
        // @ts-ignore
        localStorage.setItem(AuthConst.scheduleDate, dateFromLog);
        this.router.navigate(['/schedule']);
      });
  }

  calculateButton(type: any, id: number): any {
    return false;
  }

  showShift(shift: any): any {

    this.shiftService
      .getAssignmentById(shift.targetId)
      .subscribe((response: any) => {
        const shiftDate = new Date(response.start);
        // @ts-ignore
        localStorage.setItem(AuthConst.scheduleDate, shiftDate);
        this.router.navigate(['/schedule']);
      });

    // disclosed note
    // if (shift.targetType == 'EMPLOYEE_INVITATIONS') {
    //   // @ts-ignore
    //   const confirmDialog = this.dialog.open(DetailsShiftComponent, {
    //     disableClose: true,
    //     panelClass: 'custom-dialog-container',
    //     backdropClass: 'backdropBackground',
    //     data: { shifts: shift, mode: 'assigned' },
    //   });
    // } else if (
    //   shift.targetType != 'EMPLOYEE_INVITATIONS' &&
    //   shift.targetType != 'SWAP'
    // ) {
    //   const confirmDialog = this.dialog.open(DetailsShiftComponent, {
    //     panelClass: 'custom-dialog-container',
    //     backdropClass: 'backdropBackground',
    //     disableClose: true,
    //     data: { shifts: shift, mode: 'canceled' },
    //   });
    // } else if (shift.targetType == 'SWAP') {
    //   this.activityLogService
    //     .getActivitySwap(shift.targetId)
    //     .subscribe((swapData: Swap) => {
    //       // @ts-ignore
    //       const dialogRef = this.dialog.open(SwapDialogComponent, {
    //         panelClass: 'custom-dialog-container',
    //         backdropClass: 'backdropBackground',
    //         disableClose: true,
    //         mode: 'canceled',
    //         data: { swap: swapData, mode: 'assigned' },
    //       });
    //
    //       dialogRef.afterClosed().subscribe(() => {});
    //     });
    // }
  }

  showAllSorts(): void {
    this.showSort = !this.showSort;
  }

  showAllEvents(): void {
    this.openType = !this.openType;
  }

  selectSort(sort: any): void {
    this.sortId = sort.id;
    this.sortName = sort.name;
    // this.selectedSort = this.translate.instant(sort.title);

    this.filter.type = sort.id;
    if (this.filter.type === 2) {

      // this.showableLogs = this.showableLogs.sort((a, b) =>
      //   a.name.localeCompare(b.name)
      // );

      this.page = 0;
      this.filter.sort = '2';
      this.getActivityLogs(true);
    }
    if (this.filter.type === 1) {
      // this.showableLogs = this.showableLogs.sort((a, b) =>
      //   a.name.localeCompare(b.name)
      // );

      this.page = 0;
      this.filter.sort = '1';
      this.getActivityLogs(true);
    }
  }

  typeIsChecked(type: any): boolean {
    const index = this.filter.types.findIndex((p: any) => p === type.id);
    if (this.filter.types.length === this.types.length) {
      return !(index === -1) && !this.allEventsChecked;
    } else {
      return !(index === -1) && !this.allEventsChecked;
    }
  }

  getL(l: any): void {
  }

  logClick(l: any): void {
    this.activityLogService.markActivityLogAsRead(l.id).subscribe();
    this.helper.refreshLogCount.next(1);
    if (l.buttonType == 'dayview') {
      this.goToSchedule(l);
    } else {
      this.openShift(l);
    }
  }

  ngOnDestroy(): void {
    this.getPositionColorSubscription?.unsubscribe();
  }
}
