<section class="absence">
  <img class="absence__image" src="../../../../assets/images/Close.png" (click)="closeModal()"/>
  <div class="absence__title">{{'absence' |  translate}}</div>
  <div class="absence__info-box">
    <div class="absence__info-title">{{'dateInterval' | translate}}</div>
    <div class="absence__info-subtitle">{{'dateIntervalDescription' | translate}}</div>
  </div>
  <div style="position: relative" (click)="showStartDate.open()">
    <div class="start-date" *ngIf="!start">{{startDate}}</div>
    <div class="start-date dateCapitalize" *ngIf="start">{{startDate | date: "EEEE d MMMM" }}</div>
    <input class="input absence__input" readonly [matDatepicker]="showStartDate" [(ngModel)]="startDate"
           (dateChange)="addStartEvent($event)"/>
    <mat-datepicker  #showStartDate></mat-datepicker>
  </div>
  <div style="position: relative" (click)="showEndDate.open()">
    <div class="start-date" *ngIf="!end">{{endDate}}</div>
    <div class="start-date" *ngIf="end">{{endDate | date: "EEEE d MMMM"	}}</div>
    <input class="input absence__input absence__input--margin" readonly [matDatepicker]="showEndDate"
           [(ngModel)]="endDate"
           (dateChange)="addEndEvent($event)"/>
    <mat-datepicker  #showEndDate></mat-datepicker>
  </div>
  <div class="absence__info-box">
    <div class="absence__info-title">{{'CancellationReasons' | translate}}</div>
    <div class="absence__info-subtitle">{{'CancellationReasonsText' | translate}}</div>
  </div>
  <div class="absence__cancel">
    <input class="input absence__input absence__input--margin" (click)="openDropdown()"
           (clickOutside)="openReasons = false" placeholder="{{'select' | translate}}" [(ngModel)]="reasonTitle"
           readonly/>
    <div class="form__select form__select--reasons" *ngIf="openReasons">
      <div class="form__select-option" *ngFor="let reason of  sortReasons()" (click)="getReasons(reason)"
      >
        <div>{{reason.title}}</div>
      </div>
    </div>
  </div>
  <div class="absence__info-box">
    <div class="absence__info-title">{{'addNote' | translate}}</div>
    <div class="absence__info-subtitle">{{'addNoteText' | translate}}</div>
  </div>
  <textarea class="form__text-area" #optional></textarea>
  <div class="modal-checkBox">
    <mat-checkbox class="modal-checkBox__checkbox" [(ngModel)]="toDelete"></mat-checkbox>
    <div class="modal-checkBox__subtitle">{{'absenceModalCheckBox' | translate}}</div>
  </div>
  <div class="buttons">
    <button class="button button--primary" style="margin-right: 10px; width: 100px"
            (click)="closeModal()">{{'cancelButton' | translate}}
    </button>
    <button class="button button--red" style="width: 100px" *ngIf="!loading"
            (click)="sendAbsence(optional.value)">{{'cancel' | translate}}
    </button>
    <button class="button button--red loader-button" style="width: 100px" *ngIf="loading">
      <app-mini-loader class="miniLoader"></app-mini-loader>
    </button>
  </div>
</section>
