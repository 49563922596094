<div class="details">
  <div class="header background">
    <div class="header__title-box">
      <div class="header__title">
        <span *ngIf="!firstName  && !lastName">
            {{'newUser' | translate}}
        </span>
        <span *ngIf="firstName || lastName">
            {{firstName}} {{lastName}}
        </span>
      </div>
      <!--      <div class="header__title">-->
      <!--        {{firstName}} {{lastName}}-->
      <!--      </div>-->
    </div>
    <div class="header__button-box">
      <button class="button button--primary header__button"
              [routerLink]="['../../../personal']">{{'backButton' | translate}}</button>
    </div>
  </div>
  <div class="main-details">
    <div class="main-details__title">{{'userProfile' | translate}}</div>
    <div class="main-details__user-detail" *ngIf="userFornew !== undefined">
      <div class="main-details__left-detail">
        <div class="main-details__image-box main-details__image-box--margin">
          <img class="main-details__image" [src]="userImage" *ngIf="userImage !== undefined"/>
          <div *ngIf="userImage === undefined" class="no-image"></div>
        </div>
        <div class="main-details__text-box">
          <div class="main-details__name">{{firstName}}&nbsp;{{lastName}}</div>
          <div class="main-details__sub">{{'pending' | translate}}</div>
        </div>
      </div>
    </div>
    <form [formGroup]="userProfileForm">
      <div class="form">
        <div class="group-label group-label__margin">
          <div class="main-details__info-text">{{'infoAboutUserProfile' | translate}}</div>
          <img class="main-details__image-info" src="../assets/images/Info icon outlined.png"/>
        </div>
        <div class="form__boxes">
          <div class="form__inputs-boxes">
            <div class="form__inputs-box">
              <label class="form__label">{{'firstName' | translate}}</label>
              <input class="input form__input" type="text" formControlName="firstname"
                     [ngStyle]="{'color': userFornew !== undefined ? '#c2c2c2' : '#333333' }">
            </div>
          </div>
          <div class="form__inputs-boxes">
            <div class="form__inputs-box">
              <label class="form__label-down">{{'email' | translate}}</label>
              <input class="input form__input" type="text" formControlName="email" readonly
                     placeholder="example@gapstaff.com"
                     [ngStyle]="{'color': userFornew !== undefined ? '#c2c2c2' : '#333333' }">
            </div>
          </div>
          <div class="form__inputs-boxes form__inputs-boxes--end">
            <div class="form__inputs-box">
              <label class="form__label">{{'lastName' | translate}}</label>
              <input class="input form__input" type="text" formControlName="lastname"
                     [ngStyle]="{'color': userFornew !== undefined ? '#c2c2c2' : '#333333' }">
            </div>
          </div>
          <div class="form__inputs-boxes form__inputs-boxes--end">
            <div class="form__inputs-box">
              <label class="form__label-down">{{'Mobil' | translate}}</label>
              <input class="input form__input" type="text" formControlName="mobile"
                     [ngStyle]="{'color': userFornew !== undefined ? '#c2c2c2' : '#333333' }">
            </div>
          </div>
        </div>
      </div>
      <div class="form">
        <div class="group-label">
          <div class="main-details__info-text">{{'userQualifications' |translate}}</div>
          <img class="main-details__image-info" src="../assets/images/Info icon outlined.png"/>
        </div>
        <div class="form__boxes">
          <div class="form__inputs-boxes">
            <div class="form__inputs-box">
              <label class="form__label">{{'position' | translate}}</label>
              <input class="input form__input form__input--arrow" (click)="displayPositions()"
                     (clickOutside)="showPositions = false"
                     formControlName="position"
                     placeholder="{{'select' | translate}}"
                     [ngStyle]="{'color': positionId === null ? '#929090' : '#333333'}"
              />
              <div class="form__select form__select--positions" *ngIf="showPositions">
                <div class="form__select-option" *ngFor="let position of positions"
                     (click)="getPosition(position)">
                  <div>{{position.title}}</div>
                  <img *ngIf="position?.id === positionId" class="check-mark"
                       src="../../../assets/images/Checkmark.svg"/>
                </div>
              </div>
            </div>
          </div>
          <div class="form__inputs-boxes">
            <div class="form__inputs-box">
              <label class="form__label-down">{{'competence' | translate}}</label>
              <input class="input form__input form__input--arrow" (click)="displayCompetence()"
                     (clickOutside)="showCompetence = false"
                     formControlName="competence"
                     placeholder="{{'select' | translate}}"
                     [ngStyle]="{'color': competenceId === null ? '#929090' : '#333333'}"
                     readonly
              />
              <div class="form__select form__select--competence" *ngIf="showCompetence">
                <div class="form__select-option" (click)="getCompetence(null)"
                >{{'noCompetence' | translate}}</div>
                <div class="form__select-option" *ngFor="let competence of competences"
                     (click)="getCompetence(competence)">
                  <div>{{competence.title}}</div>
                  <img *ngIf="competenceSet(competence)" class="check-mark"
                       src="../../../assets/images/Checkmark.svg"/>
                </div>
              </div>
            </div>
          </div>
          <div class="form__inputs-boxes form__inputs-boxes--textArea">
            <label class="form__label form__label--padding">{{'notes' | translate}}</label>
            <div class="form__inputs-boxes form__inputs-boxes--end">
              <div class="form__inputs-box">
                  <textarea class="form__text-area" formControlName="note"
                            placeholder="{{'notes' | translate}}"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form">
        <div class="group-label">
          <div class="main-details__info-text">{{'userDepartmentSettings' |translate}}</div>
          <img class="main-details__image-info" src="../assets/images/Info icon outlined.png"/>
        </div>
        <div class="form__boxes">
          <div class="form__inputs-boxes">
            <div class="form__inputs-box">
              <label class="form__label">{{'contract' | translate}}</label>
              <input class="input form__input form__input--arrow" (click)="displayContract()"
                     (clickOutside)="showContract = false"
                     formControlName="contract"
                     placeholder="{{'select' | translate}}"
                     [ngStyle]="{'color': contractId === null ? '#929090' : '#333333'}"
                     readonly
              />
              <div class="form__select form__select--positions" *ngIf="showContract">
                <div class="form__select-option" *ngFor="let contract of contracts"
                     (click)="getContract(contract)">
                  <div>{{contract.title}}</div>
                  <img *ngIf="contract?.id === contractId" class="check-mark"
                       src="../../../assets/images/Checkmark.svg"/>
                </div>
              </div>
            </div>
          </div>
          <div class="form__inputs-boxes">
            <div class="form__inputs-box">
              <label class="form__label-down">{{'roll' | translate}}</label>
              <input class="titlecase input form__input form__input--arrow" style="text-transform: capitalize;" (click)="displayRoll()"
                     (clickOutside)="showRoll = false"
                     formControlName="role"
                     placeholder="{{'select' | translate}}"
                     [ngStyle]="{'color': rollStatus === undefined ? '#929090' : '#333333'}"
                     readonly
              />
              <div class="form__select form__select--roll" *ngIf="showRoll">
                <div class="form__select-option" *ngFor="let roll of rolls" (click)="getRoll(roll.roll)"
                >
                  <div>{{roll.name | titlecase}}</div>
                  <img *ngIf="roleIsSelected(roll.roll)" class="check-mark"
                       src="../../../assets/images/Checkmark.svg"/>
                </div>
              </div>
            </div>
          </div>
          <div class="form__inputs-boxes form__inputs-boxes--end">
            <div class="form__inputs-box">
              <label class="form__label">{{'status' | translate}}</label>
              <input class="titlecase input form__input form__input--arrow " style="text-transform: capitalize" (click)="displayStatus()"
                     (clickOutside)="showStatus = false"
                     [value]="status"
                     formControlName="status"
                     placeholder="{{'select' | translate}}"
                     [ngStyle]="{'color': rowStatus === undefined ? '#929090' : '#333333'}"
                     readonly
              />
              <div class="form__select form__select--status" *ngIf="showStatus">
                <div class="form__select-option" *ngFor="let status of statusSelect" (click)="getStatus(status)">
                  <div>{{status.status | lowercase | translate | titlecase}}</div>
                  <img *ngIf="status?.status === rowStatus" class="check-mark"
                       src="../../../assets/images/Checkmark.svg"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-box">
        <div class="form__button-box">
          <button style="width: 100px; margin-right: 10px; " type="button" class="button button--primary"
                  [routerLink]="['../../../personal']">{{'cancelButton' | translate}}
          </button>
        </div>
        <div class="form__button-box">
          <button style="width: 100px" type="submit" class="button button--blue"
                  (click)="create()">{{'saveButton' | translate}}</button>
        </div>
      </div>
    </form>
  </div>
</div>

