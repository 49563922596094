import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { UserService } from '../../shared/services/user.service';
import { HelperService } from '../../shared/services/helper.service';
import { DatePipe } from '@angular/common';
import { TooltipShiftsModalsComponent } from '../modals/tooltip-shifts-modals/tooltip-shifts-modals.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ShiftsService } from '../../shared/services/shifts.service';
import { CancelTooltipModalComponent } from '../modals/cancel-tooltip-modal/cancel-tooltip-modal.component';
import { ShiftNoteComponent } from '../modals/shift-note/shift-note.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-datails-container',
  templateUrl: './user-datails-container.component.html',
  styleUrls: ['./user-datails-container.component.scss'],
})
export class UserDatailsContainerComponent implements OnInit, OnDestroy {
  userId: number | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  update = false;
  selectedShifts: any = [];
  shiftView = false;
  actions: any[] = [
    { id: 1, name: 'cancelShift' },
    { id: 2, name: 'deleteShift' },
    { id: 3, name: 'addDisclosedNote' },
    { id: 4, name: 'addUndisclosedNote' },
  ];
  showActions = false;
  shift: any;

  selectedShiftsCalendarSubscription: Subscription;
  role = "";
  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private helper: HelperService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private shiftService: ShiftsService,
    private router: Router,
    private shiftsService: ShiftsService
  ) {}

  ngOnInit(): void {
    this.role = localStorage.getItem('gapUserRole');
    if (this.role == "COORDINATOR") {
      this.actions.splice(3, 1);
    }
    this.helper.clearShift.subscribe((data: any) => {
      if (data == 'yes') {
        this.shiftView = false;
        this.shift = null;
      }
    });

    this.helper.removeAssignment.subscribe(
      (data: any) => {
        if (data) {
          this.shiftView = false;
        }
      }
    );
    this.helper.userOpenShift.subscribe((data: any) => {
      if (data) {
        this.shiftView = true;
        this.shift = data;
      }
    });
    this.selectedShiftsCalendarSubscription = this.helper.selectedShiftsCalendar.subscribe(
      (data: any[]) => {
        if (data != null) {
          this.selectedShifts = data;
        }
      }
    );
    const isSingIn = this.authService.isSignedIn();

    if (isSingIn) {
      this.userId = Number(this.activatedRoute.snapshot.paramMap.get('id'));
      const update = this.activatedRoute.snapshot.paramMap.get('update');
      if (update === 'update') {
        this.update = true;
      }

      if (this.userId) {
        this.userService.getEmployeeById(this.userId).subscribe((x) => {
          this.firstName = x.firstName;
          this.lastName = x.lastName;
        });
      }
    }
  }

  formatTime(time: string): any {
    return this.datePipe.transform(time.replace(/-/g, '/'), 'HH:mm').toString();
  }

  formatDate(date: string): any {

    return this.datePipe.transform(new Date(date.replace(/-/g, '/')), );
  }

  remove(shift: any): void {
    const index = this.selectedShifts.findIndex((s: any) => s.id == shift.id);

    if (index !== -1) {
      this.selectedShifts.splice(index, 1);
    }

    this.helper.selectedShiftsCalendarBack.next(this.selectedShifts);
  }

  cancel(): void {
    this.selectedShifts = [];
    this.helper.selectedShiftsCalendarBack.next(this.selectedShifts);
  }

  goBack(): void {
    this.router.navigate(['../personal']);
    this.helper.removeUserOpenShift.next(true);
  }


  removeAssignmentFromLocalStorage(assignment: any): void {

    let existingAssignments: any = localStorage.getItem('assignments');

    if (existingAssignments) {
      existingAssignments = JSON.parse(existingAssignments);

      const index = existingAssignments.findIndex((a: any) => {
        return a.id == assignment.id;
      });
      if (index != -1) {
        existingAssignments.splice(index, 1);
        try {
          localStorage.setItem('assignments', JSON.stringify(existingAssignments));
        } catch (e) {

        }
      }
    }

  }

  saveAssignmentsToLocalStorage(assignments: any): void {

    let existingAssignments: any = localStorage.getItem('assignments');

    if (existingAssignments) {
      existingAssignments = JSON.parse(existingAssignments);
    } else {
      existingAssignments = [];
    }

    assignments.forEach((a: any) => {

      const assignmentIndex = existingAssignments.findIndex((assignment: any) => {
        return assignment.id == a.id;
      });

      if (assignmentIndex != -1) {
        existingAssignments[assignmentIndex] = a;
      } else {
        existingAssignments.push(a);
      }

      try {
        localStorage.setItem('assignments', JSON.stringify(existingAssignments));
      } catch (e) {

      }

    });


  }

  selectAction(action: any): void {
    if (action.id == 1) {
      let height = '482px';
      if (this.role == 'COORDINATOR') {
        height = '372px';
      }
      // cancel shift
      const confirmDialog = this.dialog.open(CancelTooltipModalComponent, {
        disableClose: true,
        width: '290px',
        height,
        panelClass: 'defaultDialog',
        position: {
          top: '150px',
        },
        data: {
          person: this.selectedShifts[0],
          title: this.translate.instant('cancelShifts'),
          subtitleCancellation: this.translate.instant('CancellationReasons'),
          textCancellation: this.translate.instant('CancellationReasonsText'),
          undisclosed: this.translate.instant('undisclosedNote'),
          undisclosedText: this.translate.instant('undisclosedNoteText'),
          disclosed: this.translate.instant('disclosedNote'),
          disclosedText: this.translate.instant('disclosedNoteText'),
          toCancel: false,
        },
      });
      confirmDialog.afterClosed().subscribe((cancelObj: any) => {
        if (cancelObj) {
          let index = 0;
          this.selectedShifts.forEach(async (s: any) => {
            const cancel = {
              assignmentId: s.id,
              cancelReasonId: cancelObj.cancelReasonId,
              employerNote: cancelObj.employerNote,
              disclosedNote: cancelObj.disclosedNote,
            };

            this.shiftService.cancelAssignment(cancel).subscribe((data: any) => {
              this.saveAssignmentsToLocalStorage([data]);
            });

            if (cancelObj.checked == true) {
              const shiftToSend = JSON.parse(JSON.stringify(s));
              shiftToSend.status = 1;
              shiftToSend.rowStatus = 'PUBLISHED';
              shiftToSend.assignedToId = null;
              shiftToSend.cancelReasonId = null;
              shiftToSend.disclosedNote = null;
              shiftToSend.employerNote = null;
              shiftToSend.positionId = shiftToSend.position;
              shiftToSend.id = null;
              this.shiftsService
                .createNewShift(shiftToSend)
                .subscribe((data: any) => {
                });
            }
            if (index == this.selectedShifts.length - 1) {
              this.helper.userId.next(this.userId);
              this.selectedShifts = [];
              this.helper.selectedShiftsCalendarBack.next(this.selectedShifts);
              this.snackBar.open(this.translate.instant('saved'), 'OK', {
                duration: 3000,
              });
              this.selectedShifts = [];
              this.helper.selectedShiftsCalendarBack.next([]);
            }
            index = index + 1;
            await new Promise((resolve) => setTimeout(resolve, 1000));
          });
        }
      });
    } else if (action.id == 2) {
      // delete shift
      const confirmDialog = this.dialog.open(TooltipShiftsModalsComponent, {
        disableClose: true,
        width: '290px',
        height: '190px',
        panelClass: 'defaultDialog',
        position: {
          top: '150px',
        },
        data: {
          person: this.selectedShifts[0],
          title: this.translate.instant('deleteBookedShift'),
          subtitle: this.translate.instant('attention'),
          message: this.translate.instant('deleteShiftsText'),
          mode: 'deleteShow',
        },
      });
      confirmDialog.afterClosed().subscribe((res) => {
        if (res) {
          this.selectedShifts.forEach(async (s: any) => {
            this.shiftService.deleteAssignment(s.id).subscribe();
            this.removeAssignmentFromLocalStorage(s);
            await new Promise((resolve) => setTimeout(resolve, 1000));
          });
          this.selectedShifts = [];
          this.helper.selectedShiftsCalendarBack.next(this.selectedShifts);
          this.snackBar.open(
            this.translate.instant('deletedSelectedShifts'),
            'OK',
            { duration: 3000 }
          );
        }
      });
    } else if (action.id == 3) {
      // undisclosed note
      const confirmDialog = this.dialog.open(ShiftNoteComponent, {
        disableClose: true,
        width: '290px',
        height: '274px',
        panelClass: 'defaultDialog',
        position: {
          top: '150px',
        },

        data: {
          person: this.selectedShifts[0],
          title: this.translate.instant('shiftNote'),
          undisclosed: this.translate.instant('undisclosedNote'),
          undisclosedText: this.translate.instant('undisclosedNoteText'),
          disclosed: this.translate.instant('disclosedNote'),
          disclosedText: this.translate.instant('disclosedNoteText'),
          showDisclosed: false,
          showUndisclosed: true,
          hasCheckbox: true,
          toSave: false,
        },
      });
      confirmDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.selectedShifts.forEach(async (s: any) => {
            const changes = {
              id: s.id,
              description: result.text,
              overwrite: result.overwrite,
              partial: true,
              disclosedNote: '',
            };

            this.shiftService.updateNotes(changes).subscribe();
            await new Promise((resolve) => setTimeout(resolve, 1000));
          });
          this.selectedShifts = [];
          this.helper.selectedShiftsCalendarBack.next(this.selectedShifts);
          this.snackBar.open(this.translate.instant('saved'), 'OK', {
            duration: 3000,
          });
        }
      });

    } else if (action.id == 4) {
      // disclosed note
      const confirmDialog = this.dialog.open(ShiftNoteComponent, {
        disableClose: true,
        width: '290px',
        height: '274px',
        panelClass: 'defaultDialog',
        position: {
          top: '150px',
        },

        data: {
          person: this.selectedShifts[0],
          title: this.translate.instant('shiftNote'),
          undisclosed: this.translate.instant('undisclosedNote'),
          undisclosedText: this.translate.instant('undisclosedNoteText'),
          disclosed: this.translate.instant('disclosedNote'),
          disclosedText: this.translate.instant('disclosedNoteText'),
          showDisclosed: true,
          showUndisclosed: false,
          hasCheckbox: true,
          toSave: false,
        },
      });
      confirmDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.selectedShifts.forEach(async (s: any) => {
            const changes = {
              id: s.id,
              description: '',
              employerNote: result.text,
              partial: true,
              overwrite: result.overwrite
            };

            this.shiftService.updateNotes(changes).subscribe();
            await new Promise((resolve) => setTimeout(resolve, 1000));
          });
          this.selectedShifts = [];
          this.helper.selectedShiftsCalendarBack.next(this.selectedShifts);
          this.snackBar.open(this.translate.instant('saved'), 'OK', {
            duration: 3000,
          });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.selectedShiftsCalendarSubscription?.unsubscribe();
  }
}
