import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter, Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {MessagesService} from '../../shared/services/messages.service';
import {UserService} from '../../shared/services/user.service';
import {HelperService} from '../../shared/services/helper.service';
import {Subscription} from 'rxjs';
import {AuthConst} from '../../shared/services/const/authConst';
import {share, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {EmployeesService} from '../../shared/services/employees.service';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent implements OnInit, OnDestroy {
  @Output() back = new EventEmitter();
  @Input() shouldShowX: boolean;
  // @Input() personChat: any;
  chat?: any | undefined;
  currentUserId: number | undefined;
  inputValue = '';
  downLimitMessages = 0;
  chatForModal = false;
  modalChat = false;
  selectedRecipients: any[] = [];
  toUserProfile: number;
  hasMultipleRecipients = false;

  multiSubscription: Subscription;
  message$: Subscription;
  chat$: Subscription;
  recipient$: Subscription;
  messageSent = false;
  isSending = false;
  user: any;
  messageThreadId: any;
  intervalForChat: any;

  @ViewChild('scrollMe', {static: true}) scrollMe: any;

  constructor(
    private messagesService: MessagesService,
    private userService: UserService,
    private helperService: HelperService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private helper: HelperService,
    private employeesService: EmployeesService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('loggedInUser')) {
      this.user = JSON.parse(localStorage.getItem('loggedInUser'));
    }

    if (this.shouldShowX) {
      this.chatForModal = true;
    }
    this.chat = [];
    this.chat.groupId = Number(localStorage.getItem('gruopId'));
    this.chat.headerName = '';
    this.chat.messages = [];

    this.helper.resetChatSubscriptions.subscribe(
      (data: any) => {
        if (data) {

          this.message$?.unsubscribe();
          this.chat$?.unsubscribe();
          this.recipient$?.unsubscribe();
          this.multiSubscription.unsubscribe();
          this.selectedRecipients = [];
        }
      }
    );

    this.multiSubscription = this.helperService.multiMessage$.subscribe(
      (data) => {
        if (data) {
          if (!this.hasMultipleRecipients) {
            this.selectedRecipients = [];
            this.hasMultipleRecipients = true;
          }
          if (this.messageSent) {
            this.chat.messages = [];
          }
          if (data && data.recipient) {
            const indexAlreadyExists = this.selectedRecipients.findIndex(
              (r) => r.id == data.recipient.id
            );
            if (indexAlreadyExists === -1) {
              this.selectedRecipients.push(data.recipient);
            } else {
              this.selectedRecipients.splice(indexAlreadyExists, 1);
            }

            let count = 1;
            this.chat.headerName = '';
            this.selectedRecipients.forEach((r) => {
              this.chat.headerName = this.chat.headerName + r.name;

              if (count < this.selectedRecipients.length) {
                this.chat.headerName = this.chat.headerName + ', ';
              }

              count++;
            });
          }
        }
      }
    );

    // @ts-ignore
    this.chat$ = this.helperService.personChat$.subscribe((modalChatId) => {
      this.modalChat = true;
      localStorage.removeItem(AuthConst.messageChat);
      this.messagesService
        //// ToDo change id with "modalChatId"
        .singleConversation(modalChatId)
        .pipe(take(1), share())
        .subscribe((modalChat) => {
          this.chatForModal = true;
          this.chat = modalChat;
          const chatId = this.chat?.recipientId;
          if (this.chat?.recipientId !== chatId?.toString()) {
            this.inputValue = '';
            this.downLimitMessages = 20;
          }
        });
    });

    // @ts-ignore
    this.recipient$ = this.helperService.recipientChat$.subscribe((x) => {
      this.toUserProfile = 1;
      localStorage.removeItem(AuthConst.messageChat);
      if (this.chat.id !== x?.id) {
        this.messagesService
          .singleConversation(x?.id)
          .pipe(take(1))
          .subscribe((recipientChat?) => {
            this.chat = recipientChat;
            const chatId = this.chat?.recipientId;
            if (this.chat?.recipientId !== chatId?.toString()) {
              this.inputValue = '';
              this.downLimitMessages = 20;
            }
          });
      }
    });
    // @ts-ignore
    const msgLocal = localStorage.getItem(AuthConst.messageChat);
    if (msgLocal) {
      localStorage.removeItem(AuthConst.messageChat);
      this.message$ = this.messagesService.messages$.subscribe((id) => {
        this.messageThreadId = id;
        if (this.chat.length === 0 && id && msgLocal == id) {
          if (!this.hasMultipleRecipients) {
            this.messagesService
              .singleConversation(id)
              .pipe(take(1))
              .subscribe((msgChat) => {
                this.chat = msgChat;
                const index = this.selectedRecipients.findIndex((r: any) => {
                  return r.id == this.chat.recipientId;
                });

                if (this.selectedRecipients.length == 0) {
                  this.selectedRecipients.push({
                    name: this.chat.headerName,
                    id: this.chat.recipientId,
                  });
                }


                const chatId = this.chat?.recipientId;
                if (this.chat?.recipientId !== chatId?.toString()) {
                  this.inputValue = '';
                  this.downLimitMessages = 20;
                }

              });
          }

          this.intervalForChat = setInterval(() => {
            if (!this.hasMultipleRecipients) {
              this.messagesService
                .singleConversation(id)
                .pipe(take(1))
                .subscribe((msgChat) => {
                  this.chat = msgChat;
                  const lastMessage = this.chat.messages[this.chat.messages.length - 1];
                  this.messagesService.updateLastMessage.next({
                    messageThreadId: this.messageThreadId,
                    message: lastMessage
                  });
                });
            }
          }, 5000);
        }
      });
    }


    this.userService
      .getLoggedInUser()
      .subscribe((x) => (this.currentUserId = x.id));
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterContentChecked(): void {
    if (this.scrollMe) {
      if (this.chat === undefined || this.chat?.messages?.length <= 21) {
        this.scrollMe.nativeElement.scrollTop = this.scrollMe.nativeElement.scrollHeight;
      }
    }

    this.chat = this.chat;
    this.cd.detectChanges();
  }

  /**
   *
   * Sorting chat by date
   */
  // @ts-ignore
  shouldShowDate(i: number, date: string): boolean {
    if (i === 0) {
      return true;
    } else {
      const thisMessageDate = new Date(this.chat.messages[i].createdDate);
      const lastMessageDate = new Date(this.chat.messages[i - 1].createdDate);
      const thisFullDate =
        thisMessageDate.getFullYear() +
        '-' +
        thisMessageDate.getMonth() +
        '-' +
        thisMessageDate.getDate();
      const lastFullDate =
        lastMessageDate.getFullYear() +
        '-' +
        lastMessageDate.getMonth() +
        '-' +
        lastMessageDate.getDate();
      if (thisFullDate === lastFullDate) {
        return false;
      } else {
        return true;
      }
    }
  }

  sendMessage(textInput: string): void {
    if (!this.isSending) {
      const sentToUsers: any = [];
      this.isSending = true;
      const sentMessage = {
        authorName: this.user.firstName + ' ' + this.user.lastName,
        authorProfilePicture: this.user.profilePicture,
        authorProfilePictureUrl: this.user.profilePicture,
        message: textInput,
        iAmSender: true
      };
      this.chat.messages.push(sentMessage);
      this.chat.messages = [...this.chat.messages];
      this.inputValue = '';
      this.isSending = false;
      this.messagesService.updateLastMessage.next({messageThreadId: this.messageThreadId, message: sentMessage});
      if (this.selectedRecipients.length >= 1) {
        const sentMessage: any = null;
        const countSent = 0;
        this.selectedRecipients.forEach((r) => {
          if (r.isGroup) {
            const userCount = 0;
            r.users.forEach((u: any) => {

              const alradySentToUserIndex = sentToUsers.findIndex((x: any) => x == u.id);

              if (alradySentToUserIndex == -1) {
                sentToUsers.push(u.id);
                let message2: {
                  currentUserId: number | undefined;
                  messageThreadId: null;
                  groupId: any;
                  recipientId: any;
                  message: string;
                  web: true;
                };
                message2 = {
                  recipientId: u.id,
                  currentUserId: this.currentUserId,
                  groupId: this.chat.groupId,
                  message: textInput,
                  messageThreadId: null,
                  web: true
                };

                this.messagesService.createMessage(message2).subscribe((msg) => {

                });
              }
            });
          } else {
            const alradySentToUserIndex = sentToUsers.findIndex((x: any) => x == r.id);

            if (alradySentToUserIndex == -1) {
              sentToUsers.push(r.id);
              let message: {
                currentUserId: number | undefined;
                messageThreadId: null;
                groupId: any;
                recipientId: any;
                message: string;
                web: true;
              };
              message = {
                recipientId: r.id,
                currentUserId: this.currentUserId,
                groupId: this.chat.groupId,
                message: textInput,
                messageThreadId: null,
                web: true
              };

              this.messagesService.createMessage(message).subscribe((msg) => {

              });
            }
          }
        });
      } else {
        // @ts-ignore
        const message = {
          messageThreadId: this.chat.id,
          recipientId: this.chat.recipientId,
          currentUserId: this.currentUserId,
          groupId: this.chat.groupId,
          message: textInput,
          web: true
        };
        this.messagesService.createMessage(message).subscribe((msg) => {
        });
      }
    }
  }

  uniqueArray(array: any): any {
    const arr: any[] = [...new Set(array.map((item: any) => item.id))];
    return arr;
  }

  /**
   *
   * Scroll directive
   */
  onScroll(): void {
    const getNewMessages = this.messagesService.singleConversation(
      this.chat.id,
      this.downLimitMessages
    );
    this.scrollMe = false;
    getNewMessages.subscribe((x) => {
      x.messages.forEach((msg: any, index: number) => {
        this.chat.messages.unshift(msg);
      });
      // @ts-ignore
      this.downLimitMessages += 20;
    }, (err) => {
      this.chat.messages = [];
      this.downLimitMessages += 20;
    });
  }

  backToUser(): void {
    this.back.emit();
  }

  routing(): void {
    this.router.navigate([`/personal/user/${this.chat.recipientId}`]);
  }

  openRecipient(r: any): void {
    if (r.isGroup) {
      this.router.navigate(['/personal']);
      setTimeout(() => {
        this.helper.group.next(r);
      }, 100);
    } else {
      this.router.navigate([`/personal/user/${r.id}`]);
    }
  }

  getTitle() {
    if (this.isSending) {
      return this.translate.instant('messageIsBeingSent');
    } else {
      return '';
    }
  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    this.message$?.unsubscribe();
    this.chat$?.unsubscribe();
    this.recipient$?.unsubscribe();
    this.multiSubscription.unsubscribe();
    this.selectedRecipients = [];
    clearInterval(this.intervalForChat);
  }
}
