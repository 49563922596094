import {Component, OnDestroy, OnInit} from '@angular/core';
import {HelperService} from '../shared/services/helper.service';
import {DatePipe} from '@angular/common';
import {ShiftsService} from '../shared/services/shifts.service';
import {Observable, Subscription} from 'rxjs';
import {Position} from '../shared/models/attribute.model';
import {EmployeesService} from '../shared/services/employees.service';
import {finalize} from 'rxjs/operators';
import {AfterViewInit, ElementRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthConst} from '../shared/services/const/authConst';
import {MonthData} from "../shared/models/group.model";

// @ts-ignore
// @ts-ignore
// @ts-ignore
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, AfterViewInit, OnDestroy {
  data: any = null;
  days = 0;
  maxDay = 0;
  minDay = 0;
  weekArray: any = [];
  daysArray: any = [];
  dateTime: any = [];
  dataGoalsPosition: any[] = [];
  dataGoalsPositionName: any[] = [];
  optionPositions: any = null;
  positions$?: Observable<any>;
  dataCalculate: any;
  group: any;
  groupId: any = localStorage.getItem('gruopId');
  mon: any = null;
  loading = false;
  someDate: any = null;
  counter = 0;
  hover: any = false;
  announcementsValue: any;
  correctMonth: any = null;
  withNewDate: any = new Date();
  contentHeight: number;
  colorArray: any[] = [];
  @ViewChild('boxHeight') boxChild: ElementRef;
  weekHeight: any;
  changeText = false;
  saveMyDate: any = null;
  dateValueSubscription: Subscription;
  positionsSelect: any[] = [];
  finalMonthData: any[] = [];
  assignments: any[] = [];
  assignmentTimings: any[] = [];
  monthDataSubscription: any;
  defaultGoals: any[] = [
    {
      header: "Dagpass",
      type: "DAY_ASSIGNMENT",
      taken: 0,
      open: 0,
      done: false
    },
    {
      header: "",
      type: "SWING_1",
      taken: 0,
      open: 0,
      done: false
    },
    {
      header: "Kvällspass",
      type: "EVENING_ASSIGNMENT",
      taken: 0,
      open: 0,
      done: false
    },
    {
      header: "",
      type: "SWING_2",
      taken: 0,
      open: 0,
      done: false
    },
    {
      header: "Nattpass",
      type: "NIGHT_ASSIGNMENT",
      taken: 0,
      open: 0,
      done: false

    }
  ];

  monthData: MonthData[] = [];

  // tslint:disable-next-line:max-line-length
  constructor(
    private router: Router,
    private helper: HelperService,
    private datePipe: DatePipe,
    private shiftsService: ShiftsService,
    private employeesService: EmployeesService
  ) {
  }

  ngOnInit(): void {
    this.helper.loadingTime$.subscribe((result) => {
      this.loading = result;
    });

    this.groupId = localStorage.getItem(AuthConst.gruopId);
    this.employeesService.userPositions().subscribe((x) => {
      x.forEach((group: any) => {
        if (
          group.groupId == Number(this.groupId) &&
          group.groupStatus == 'ACTIVE'
        ) {
          this.positionsSelect.push(group);
        }
      });
    });

    this.dataCalculate = this.helper.monthDataCalculate$.subscribe(
      (data: any) => {
        this.addPositionToMonthData();
      }
    );

    this.dateValueSubscription = this.helper.dateValue$.subscribe(
      (data: any) => {
        this.monthDataSubscription?.unsubscribe();
        data = new Date(data);

        this.data = data;
        this.saveMyDate = data;
        this.weekArray = [];
        this.daysArray = [];
        this.dateTime = [];
        this.correctMonth = 0;

        let firstDay: any = new Date(data.getFullYear(), data.getMonth(), 1);
        let lastDay: any = new Date(data.getFullYear(), data.getMonth() + 1, 0);

        let monthStartsOnSunday = false;

        if (firstDay.getDate() == 1 && firstDay.getDay() == 0) { //if month starts on sunday
          monthStartsOnSunday = true;
        }


        this.correctMonth = firstDay.getMonth();
        const originalFirstDay = JSON.stringify(firstDay);


        // @ts-ignore
        let numberBack = 0;
        let numberOf = 0;

        for (let i = 1; i < 10; i++) {
          let dates = firstDay.setDate(firstDay.getDate() - i);
          // tslint:disable-next-line:max-line-length
          if (
            this.datePipe.transform(new Date(dates), 'w') ==
            this.datePipe.transform(
              new Date(data.getFullYear(), data.getMonth(), 1),
              'w'
            )
          ) {
            numberBack = i;
            dates = firstDay.setDate(firstDay.getDate() + i);
          }
        }

        if (monthStartsOnSunday) {
          numberBack = 7;
        }

        const realFirstDayOfMonth =  JSON.parse(originalFirstDay);
        const firstDateInView = new Date(realFirstDayOfMonth);
        firstDateInView.setDate(new Date(realFirstDayOfMonth).getDate() - numberBack);
        const firstWeek = this.datePipe.transform(firstDateInView, 'w');
        const lastWeek = this.datePipe.transform(
          new Date(lastDay.setDate(lastDay.getDate() - 1)),
          'w'
        );
        const objWeek = {firstWeekSend: firstWeek, lastWeekSend: lastWeek};
        this.helper.weeks.next(objWeek);



        if (Number(firstWeek) < Number(lastWeek)) {
          this.weekArray = [];
          for (let i = Number(firstWeek); i <= Number(lastWeek); i++) {
            this.weekArray.push(i);
          }
        }
        if (Number(firstWeek) > Number(lastWeek)) {
          this.weekArray = [];
          this.weekArray.push(firstWeek);
          for (let i = 1; i <= Number(lastWeek); i++) {
            this.weekArray.push(i);
          }
        }

        for (let i = 1; i < 10; i++) {
          let dates = lastDay.setDate(lastDay.getDate() + i);
          if (
            this.datePipe.transform(new Date(dates), 'w') ==
            this.datePipe.transform(
              new Date(data.getFullYear(), data.getMonth() + 1, 0),
              'w'
            )
          ) {
            numberOf = i;
            dates = lastDay.setDate(lastDay.getDate() - i);
          }
        }

        firstDay = new Date(data.getFullYear(), data.getMonth(), 1);
        lastDay = new Date(data.getFullYear(), data.getMonth() + 1, 0);

        for (let i = numberBack - 1; i >= 1; i--) {
          const date = new Date(firstDay.setDate(firstDay.getDate() - i));
          this.dateTime.push({date});
          firstDay = new Date(data.getFullYear(), data.getMonth(), 1);
        }
        firstDay = new Date(data.getFullYear(), data.getMonth(), 1);
        lastDay = new Date(data.getFullYear(), data.getMonth() + 1, 0);
        const allDays = this.getDates(firstDay, lastDay);


        for (const i of allDays) {
          const date = new Date(i);
          this.dateTime.push({date});
        }

        for (let i = 1; i <= numberOf + 1; i++) {
          const date = new Date(lastDay.setDate(lastDay.getDate() + i));
          if (monthStartsOnSunday) { //month starts on sunday
            if (i <= numberOf) {
              this.dateTime.push({date});
            }
          } else {
            if (this.dateTime.length / this.weekArray.length < 7) {
              this.dateTime.push({date});
            }
          }


          lastDay = new Date(data.getFullYear(), data.getMonth() + 1, 0);
        }
        firstDay = new Date(
          data.getFullYear(),
          data.getMonth(),
          -numberBack + 1
        )
          .toJSON()
          .substring(0, 10);
        lastDay = new Date(
          data.getFullYear(),
          data.getMonth() + 1,
          numberOf + 1
        )
          .toJSON()
          .substring(0, 10);
        if (this.mon != data.getMonth()) {
          this.dataGoalsPosition = [];
          this.dataGoalsPosition = [];
          this.optionPositions = null;
          this.counter = 0;
          this.mon = data.getMonth();
          this.positions$ = this.employeesService.userPositions();

          this.positions$.subscribe((positions: Position[]) => {
            this.optionPositions = positions.filter(
              // tslint:disable-next-line:no-shadowed-variable
              (x) => x.groupId == this.groupId && x.groupStatus == 'ACTIVE'
            );
            this.allEmployees(firstDay, lastDay);
          });
        }

        const newFirstDay = new Date(
          this.dateTime[0].date.getFullYear(),
          this.dateTime[0].date.getMonth(),
          this.dateTime[0].date.getDate() + 1
        )
          .toISOString()
          .substring(0, 10);
        const newLastDay = new Date(
          this.dateTime[this.dateTime.length - 1].date.getFullYear(),
          this.dateTime[this.dateTime.length - 1].date.getMonth(),
          this.dateTime[this.dateTime.length - 1].date.getDate() + 1
        )
          .toISOString()
          .substring(0, 10);
        // tslint:disable-next-line:radix

        const groupId: number = parseInt(localStorage.getItem('gruopId'));
        this.shiftsService
          .getAnnouncements(groupId)
          .subscribe((announcements) => {
            this.announcementsValue = announcements;
          });

        this.monthDataSubscription =  this.shiftsService
          .getMonthData(newFirstDay, newLastDay, groupId)
          .subscribe((response: any) => {
            this.assignments = response.assignments;
            this.assignmentTimings = response.assignmentTimings;
            setTimeout(() => {
              this.loading = true;
              this.helper.changeDate.next(true);
            }, 1000);
            this.addPositionToMonthData();
          });
      }
    );

    setTimeout(() => {
      const height = this.boxChild?.nativeElement.children[0].offsetHeight;
      // @ts-ignore
      this.weekHeight = height;
    }, 2000);
  }

  addPositionToMonthData(): void {

    const positions = this.positionsSelect.sort((a: any, b: any) => a.id - b.id);

    //add these positions to each day
    this.dateTime.forEach((m: any, mindex: number, monthArray: any[]) => {
      m.positions = [];
      positions.forEach((pos: any) => {
        let position: { id: number; shortTitle: string; goals: any[] };
        position = {id: 0, shortTitle: "", goals: []};
        position.id = pos.id;
        position.shortTitle = pos.shortTitle;
        m.positions.push(position);
      });
      m.positions.forEach((p: any, pindex: number, positionArray: any[]) => {
        p.goals = [];
        this.defaultGoals.forEach((goal: any) => {
          p.goals.push(goal);
        });
          p.goals.forEach((g: any, gindex: any, goalsArray: any[]) => {
              const taken = this.calculateValue(p.id, g.type, m.date);
              const open = this.calculateValueFree(p.id, g.type, m.date);
              const colorBackground = this.getColorBackground(p.id, g.type, m.date);
              const colorNumber = this.getColorNumber(p.id, g.type, m.date);
              this.dateTime[mindex].positions[pindex].goals[gindex] = {taken, open, colorBackground, colorNumber};
          });
        });
      this.loading = true;
    });

  }


  findCorrectDate(date: any): any {
    return this.monthData.filter((m: any) => {
      return m.date == date;
    })[0].positions;
  }

  ngAfterViewInit(): void {
  }

  allEmployees(firstDay: any, lastDay: any): void {
    this.dataGoalsPositionName = [];
    this.dataGoalsPosition = [];
    this.optionPositions.forEach((e: any) => {
      this.dataGoalsPositionName.push({
        position: this.counter,
        data: e.shortTitle,
      });
      // this.shiftsService
      //   .staffingGoals(e.id, firstDay, lastDay)
      //   .subscribe((response: any) => {
      //     this.dataGoalsPosition.push({
      //
      //       position: this.counter,
      //       data: response,
      //     });
      //
      //   });
    });
  }

  getDateForSchedule(date: any): any {
    localStorage.setItem(AuthConst.scheduleDate, date);
    this.helper.sendDate.next(date);
  }

  // tslint:disable-next-line:typedef
  public daysInMonth(month: any, year: any) {
    return new Date(year, month, 0).getDate();
  }

  // tslint:disable-next-line:typedef

  // tslint:disable-next-line:typedef
  public getDates(startDate: any, stopDate: any) {
    const dateArray = [];
    const currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  }

  calculateMinutes(time: string): number {
    const hours = Number(time.split(':')[0]);
    const minutes = Number(time.split(':')[1]);

    return hours * 60 + minutes;
  }

  isSameDate(date1: any, date2: any): boolean {
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    return firstDate.getFullYear() == secondDate.getFullYear() && firstDate.getMonth() == secondDate.getMonth() && firstDate.getDate() == secondDate.getDate();
  }


  getColorBackground(position: any, type: any, dateTiming: any): any {
    dateTiming = new Date(dateTiming);
    dateTiming.setDate(dateTiming.getDate() + 1);
    let lists = [];
    if (localStorage.getItem('dayview-lists')) {
      lists = JSON.parse(localStorage.getItem('dayview-lists'));
    }
    if (this.assignments.length != 0) {
      for (const assignment of this.assignments) {
        if (assignment.date == dateTiming.toJSON().substring(0, 10)) {
            if (assignment.position == position && assignment.clinical) {
              for (const time of this.assignmentTimings) {
               if (lists.length > 0) {
                 if (lists.includes(assignment.listId)) {
                   if (
                     this.calculateMinutes(time.end) >
                     this.calculateMinutes(time.start)
                   ) {
                     if (
                       this.calculateMinutes(time.start) <=
                       this.calculateMinutes(assignment.start.substring(11, 16)) &&
                       this.calculateMinutes(time.end) >=
                       this.calculateMinutes(assignment.start.substring(11, 16))
                     ) {
                       if (time.type == type) {
                         if (type == 'DAY_ASSIGNMENT') {
                           if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                             return '#ff6600';
                           }
                         } else if (type == 'EVENING_ASSIGNMENT') {
                           if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                             return '#ff6600';
                           }
                         } else if (type == 'NIGHT_ASSIGNMENT') {
                           if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                             return '#ff6600';
                           }
                         } else if (type == 'SWING_1') {
                           if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                             return '#ff6600';
                           }
                         } else if (type == 'SWING_2') {
                           if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                             return '#ff6600';
                           }
                         }
                       }
                     }
                   }
                   else {
                     if (
                       this.calculateMinutes(time.start) <=
                       this.calculateMinutes(assignment.start.substring(11, 16))
                     ) {
                       if (time.type == type) {
                         if (type == 'DAY_ASSIGNMENT') {
                           if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                             return '#ff6600';
                           }
                         } else if (type == 'EVENING_ASSIGNMENT') {
                           if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                             return '#ff6600';
                           }
                         } else if (type == 'NIGHT_ASSIGNMENT') {
                           if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                             return '#ff6600';
                           }
                         } else if (type == 'SWING_1') {
                           if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                             return '#ff6600';
                           }
                         } else if (type == 'SWING_2') {
                           if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                             return '#ff6600';
                           }
                         }
                       }
                     }
                   }
                 }
               } else {
                 if (
                   this.calculateMinutes(time.end) >
                   this.calculateMinutes(time.start)
                 ) {
                   if (
                     this.calculateMinutes(time.start) <=
                     this.calculateMinutes(assignment.start.substring(11, 16)) &&
                     this.calculateMinutes(time.end) >=
                     this.calculateMinutes(assignment.start.substring(11, 16))
                   ) {
                     if (time.type == type) {
                       if (type == 'DAY_ASSIGNMENT') {
                         if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                           return '#ff6600';
                         }
                       } else if (type == 'EVENING_ASSIGNMENT') {
                         if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                           return '#ff6600';
                         }
                       } else if (type == 'NIGHT_ASSIGNMENT') {
                         if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                           return '#ff6600';
                         }
                       } else if (type == 'SWING_1') {
                         if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                           return '#ff6600';
                         }
                       } else if (type == 'SWING_2') {
                         if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                           return '#ff6600';
                         }
                       }
                     }
                   }
                 }
                 else {
                   if (
                     this.calculateMinutes(time.start) <=
                     this.calculateMinutes(assignment.start.substring(11, 16))
                   ) {
                     if (time.type == type) {
                       if (type == 'DAY_ASSIGNMENT') {
                         if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                           return '#ff6600';
                         }
                       } else if (type == 'EVENING_ASSIGNMENT') {
                         if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                           return '#ff6600';
                         }
                       } else if (type == 'NIGHT_ASSIGNMENT') {
                         if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                           return '#ff6600';
                         }
                       } else if (type == 'SWING_1') {
                         if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                           return '#ff6600';
                         }
                       } else if (type == 'SWING_2') {
                         if (assignment.nrOfAppliedApplications > 0 && assignment.status === 1) {
                           return '#ff6600';
                         }
                       }
                     }
                   }
                 }
               }
              }

              // if( this.calculateMinutes(col.end.substring(11,16)) >= this.calculateMinutes( colors.assignmentTimings[0].end)  && this.calculateMinutes(col.end.substring(11,16)) <= this.calculateMinutes( colors.assignmentTimings[1].end)){
              //   if(colors.assignmentTimings[0].type == type){
              //     if(col.nrOfAppliedApplications > 0){
              //       return "#ff6600";
              //     }
              //   }
              // }else if(  this.calculateMinutes(col.end.substring(11,16)) >= this.calculateMinutes( colors.assignmentTimings[1].end)  &&  this.calculateMinutes(col.end.substring(11,16)) <= this.calculateMinutes( colors.assignmentTimings[2].end) ){
              //   if(colors.assignmentTimings[1].type == type){
              //     if(col.nrOfAppliedApplications > 0){
              //       return "#ff6600";
              //     }
              //   }
              // }else if( ( this.calculateMinutes(col.end.substring(11,16)) >= this.calculateMinutes( colors.assignmentTimings[2].end) && ( this.calculateMinutes(col.end.substring(11,16)) <= this.calculateMinutes("23:59") ))  ||  (this.calculateMinutes("00:00") <= this.calculateMinutes(col.end.substring(11,16)) && this.calculateMinutes(col.end.substring(11,16)) <= this.calculateMinutes( colors.assignmentTimings[0].end))){
              //   if(colors.assignmentTimings[2].type == type){
              //     if(col.nrOfAppliedApplications > 0){
              //       return "#ff6600";
              //     }
              //   }
              // }
            }
        }
      }

      for (const assignment of this.assignments) {
        if (assignment.date == dateTiming.toJSON().substring(0, 10)) {
            if (assignment.position == position && assignment.status == 1 && assignment.clinical) {
              for (const time of this.assignmentTimings) {
               if (lists.length > 0) {
                 if (lists.includes(assignment.listId)) {
                   if (
                     this.calculateMinutes(time.end) >
                     this.calculateMinutes(time.start)
                   ) {
                     if (
                       this.calculateMinutes(time.start) <=
                       this.calculateMinutes(assignment.start.substring(11, 16)) &&
                       this.calculateMinutes(time.end) >=
                       this.calculateMinutes(assignment.start.substring(11, 16))
                     ) {
                       if (time.type == type) {
                         for (const colorOfCircle of this.positionsSelect) {
                           if (colorOfCircle.id == position) {
                             if (colorOfCircle.color) {
                               if (colorOfCircle.color?.startsWith('##')) {
                                 return colorOfCircle.color.substring(1);
                               }
                               if (colorOfCircle.color?.startsWith('#')) {
                                 return colorOfCircle.color;
                               } else {
                                 return '#' + colorOfCircle.color;
                               }
                             }
                           }
                         }
                       }
                     }
                   } else {
                     if (
                       this.calculateMinutes(time.start) <=
                       this.calculateMinutes(assignment.start.substring(11, 16))
                     ) {
                       if (time.type == type) {
                         for (const colorOfCircle of this.positionsSelect) {
                           if (colorOfCircle.id == position) {
                             if (colorOfCircle.color) {
                               if (colorOfCircle.color?.startsWith('##')) {
                                 return colorOfCircle.color.substring(1);
                               }
                               if (colorOfCircle.color?.startsWith('#')) {
                                 return colorOfCircle.color;
                               } else {
                                 return '#' + colorOfCircle.color;
                               }
                             }
                           }
                         }
                       }
                     }
                   }
                 }
               } else {
                 if (
                   this.calculateMinutes(time.end) >
                   this.calculateMinutes(time.start)
                 ) {
                   if (
                     this.calculateMinutes(time.start) <=
                     this.calculateMinutes(assignment.start.substring(11, 16)) &&
                     this.calculateMinutes(time.end) >=
                     this.calculateMinutes(assignment.start.substring(11, 16))
                   ) {
                     if (time.type == type) {
                       for (const colorOfCircle of this.positionsSelect) {
                         if (colorOfCircle.id == position) {
                           if (colorOfCircle.color) {
                             if (colorOfCircle.color?.startsWith('##')) {
                               return colorOfCircle.color.substring(1);
                             }
                             if (colorOfCircle.color?.startsWith('#')) {
                               return colorOfCircle.color;
                             } else {
                               return '#' + colorOfCircle.color;
                             }
                           }
                         }
                       }
                     }
                   }
                 } else {
                   if (
                     this.calculateMinutes(time.start) <=
                     this.calculateMinutes(assignment.start.substring(11, 16))
                   ) {
                     if (time.type == type) {
                       for (const colorOfCircle of this.positionsSelect) {
                         if (colorOfCircle.id == position) {
                           if (colorOfCircle.color) {
                             if (colorOfCircle.color?.startsWith('##')) {
                               return colorOfCircle.color.substring(1);
                             }
                             if (colorOfCircle.color?.startsWith('#')) {
                               return colorOfCircle.color;
                             } else {
                               return '#' + colorOfCircle.color;
                             }
                           }
                         }
                       }
                     }
                   }
                 }
               }
              }

              // if( this.calculateMinutes(col.end.substring(11,16)) >= this.calculateMinutes( colors.assignmentTimings[0].end)  && this.calculateMinutes(col.end.substring(11,16)) <= this.calculateMinutes( colors.assignmentTimings[1].end)){
              //   if(colors.assignmentTimings[0].type == type){
              //     return "#"+colors.assignmentTimings[0].color;
              //   }
              // }else if(  this.calculateMinutes(col.end.substring(11,16)) >= this.calculateMinutes( colors.assignmentTimings[1].end)  &&  this.calculateMinutes(col.end.substring(11,16)) <= this.calculateMinutes( colors.assignmentTimings[2].end) ){
              //   if(colors.assignmentTimings[1].type == type){
              //     return "#"+colors.assignmentTimings[1].color;
              //   }
              // }else if( ( this.calculateMinutes(col.end.substring(11,16)) >= this.calculateMinutes( colors.assignmentTimings[2].end) && ( this.calculateMinutes(col.end.substring(11,16)) <= this.calculateMinutes("23:59") ))  ||  (this.calculateMinutes("00:00") <= this.calculateMinutes(col.end.substring(11,16)) && this.calculateMinutes(col.end.substring(11,16)) <= this.calculateMinutes( colors.assignmentTimings[0].end))){
              //   if(colors.assignmentTimings[2].type == type){
              //     return "#"+colors.assignmentTimings[2].color;
              //   }
              // }
            }
        }
      }
    }
  }

  getColorNumber(position: any, type: any, dateTiming: any): any {
    dateTiming = new Date(dateTiming);
    dateTiming.setDate(dateTiming.getDate() + 1);
    let lists = [];
    if (localStorage.getItem('dayview-lists')) {
      lists = JSON.parse(localStorage.getItem('dayview-lists'));
    }
    if (this.assignments.length != 0) {
      for (const assignment of this.assignments) {
        if (assignment.date == dateTiming.toJSON().substring(0, 10)) {
            if (
              assignment.position == position && assignment.clinical &&
              (assignment.status == 1 || assignment.nrOfAppliedApplications > 0)
            ) {
              for (const time of this.assignmentTimings) {
              if (lists.length > 0) {
                if (lists.includes(assignment.listId)) {
                  if (
                    this.calculateMinutes(time.end) >
                    this.calculateMinutes(time.start)
                  ) {
                    if (
                      this.calculateMinutes(time.start) <=
                      this.calculateMinutes(assignment.start.substring(11, 16)) &&
                      this.calculateMinutes(time.end) >=
                      this.calculateMinutes(assignment.start.substring(11, 16))
                    ) {
                      if (time.type == type) {
                        return '#ffff';
                      }
                    }
                  }
                  else {
                    if (
                      this.calculateMinutes(time.start) <=
                      this.calculateMinutes(assignment.start.substring(11, 16))
                    ) {
                      if (time.type == type) {
                        return '#ffff';
                      }
                    }
                  }
                }
              } else {
                if (
                  this.calculateMinutes(time.end) >
                  this.calculateMinutes(time.start)
                ) {
                  if (
                    this.calculateMinutes(time.start) <=
                    this.calculateMinutes(assignment.start.substring(11, 16)) &&
                    this.calculateMinutes(time.end) >=
                    this.calculateMinutes(assignment.start.substring(11, 16))
                  ) {
                    if (time.type == type) {
                      return '#ffff';
                    }
                  }
                }
                else {
                  if (
                    this.calculateMinutes(time.start) <=
                    this.calculateMinutes(assignment.start.substring(11, 16))
                  ) {
                    if (time.type == type) {
                      return '#ffff';
                    }
                  }
                }
              }
              }

              // if( this.calculateMinutes(col.end.substring(11,16)) >= this.calculateMinutes( colors.assignmentTimings[0].end)  && this.calculateMinutes(col.end.substring(11,16)) <= this.calculateMinutes( colors.assignmentTimings[1].end)){
              //   if(colors.assignmentTimings[0].type == type){
              //     return "#ffff";
              //   }
              // }else if(  this.calculateMinutes(col.end.substring(11,16)) >= this.calculateMinutes( colors.assignmentTimings[1].end)  &&  this.calculateMinutes(col.end.substring(11,16)) <= this.calculateMinutes( colors.assignmentTimings[2].end) ){
              //   if(colors.assignmentTimings[1].type == type){
              //     return "#ffff";
              //   }
              // }else if( ( this.calculateMinutes(col.end.substring(11,16)) >= this.calculateMinutes( colors.assignmentTimings[2].end) && ( this.calculateMinutes(col.end.substring(11,16)) <= this.calculateMinutes("23:59") ))  ||  (this.calculateMinutes("00:00") <= this.calculateMinutes(col.end.substring(11,16)) && this.calculateMinutes(col.end.substring(11,16)) <= this.calculateMinutes( colors.assignmentTimings[0].end))){
              //   if(colors.assignmentTimings[2].type == type){
              //     return "#ffff";
              //   }
              // }
            }

        }
      }
    }
  }

  calculateValue(position: any, type: any, dateTiming: any): any {
    dateTiming = new Date(dateTiming);
    dateTiming.setDate(dateTiming.getDate() + 1);

    let counterDay = 0;
    let counterEvening = 0;
    let counterNight = 0;
    let swing1 = 0;
    let swing2 = 0;
    let lists = [];
    if (localStorage.getItem('dayview-lists')) {
      lists = JSON.parse(localStorage.getItem('dayview-lists'));
    }
    if (this.assignments.length != 0) {
      for (const assignment of this.assignments) {
        if (assignment.date == dateTiming.toJSON().substring(0, 10)) {
            if (lists.length > 0) {
              if (
                assignment.position == position &&
                assignment.status == 3 && assignment.clinical
                && lists.includes(assignment.listId)
              ) {
                for (const time of this.assignmentTimings) {
                  if (
                    this.calculateMinutes(time.end) >
                    this.calculateMinutes(time.start)
                  ) {

                    if (
                      this.calculateMinutes(time.start) <=
                      this.calculateMinutes(assignment.start.substring(11, 16)) &&
                      this.calculateMinutes(time.end) >=
                      this.calculateMinutes(assignment.start.substring(11, 16))
                    ) {

                      if (time.type == type) {

                        if (type == 'DAY_ASSIGNMENT') {
                          counterDay++;
                        } else if (type == 'EVENING_ASSIGNMENT') {
                          counterEvening++;
                        } else if (type == 'NIGHT_ASSIGNMENT') {
                          counterNight++;
                        } else if (type == 'SWING_1') {
                          swing1++;
                        } else if (type == 'SWING_2') {
                          swing2++;
                        }
                      }
                    }
                  } else {
                    if (
                      this.calculateMinutes(time.start) <=
                      this.calculateMinutes(assignment.start.substring(11, 16))
                    ) {

                      if (time.type == type) {

                        if (type == 'DAY_ASSIGNMENT') {
                          counterDay++;
                        } else if (type == 'EVENING_ASSIGNMENT') {
                          counterEvening++;
                        } else if (type == 'NIGHT_ASSIGNMENT') {
                          counterNight++;
                        } else if (type == 'SWING_1') {
                          swing1++;
                        } else if (type == 'SWING_2') {
                          swing2++;
                        }
                      }
                    }
                  }
                }
              }
            } else {
              if (
                assignment.position == position &&
                assignment.status == 3 && assignment.clinical
              ) {
                for (const time of this.assignmentTimings) {
                  if (
                    this.calculateMinutes(time.end) >
                    this.calculateMinutes(time.start)
                  ) {

                    if (
                      this.calculateMinutes(time.start) <=
                      this.calculateMinutes(assignment.start.substring(11, 16)) &&
                      this.calculateMinutes(time.end) >=
                      this.calculateMinutes(assignment.start.substring(11, 16))
                    ) {

                      if (time.type == type) {

                        if (type == 'DAY_ASSIGNMENT') {
                          counterDay++;
                        } else if (type == 'EVENING_ASSIGNMENT') {
                          counterEvening++;
                        } else if (type == 'NIGHT_ASSIGNMENT') {
                          counterNight++;
                        } else if (type == 'SWING_1') {
                          swing1++;
                        } else if (type == 'SWING_2') {
                          swing2++;
                        }
                      }
                    }
                  } else {
                    if (
                      this.calculateMinutes(time.start) <=
                      this.calculateMinutes(assignment.start.substring(11, 16))
                    ) {

                      if (time.type == type) {

                        if (type == 'DAY_ASSIGNMENT') {
                          counterDay++;
                        } else if (type == 'EVENING_ASSIGNMENT') {
                          counterEvening++;
                        } else if (type == 'NIGHT_ASSIGNMENT') {
                          counterNight++;
                        } else if (type == 'SWING_1') {
                          swing1++;
                        } else if (type == 'SWING_2') {
                          swing2++;
                        }
                      }
                    }
                  }
                }
              }
            }


        }
      }

      if (counterDay != 0) {
        return counterDay;
      } else if (counterEvening != 0) {
        return counterEvening;
      } else if (counterNight != 0) {
        return counterNight;
      } else if (swing1 != 0) {
        return swing1;
      } else if (swing2 != 0) {
        return swing2;
      } else {
        return 0;
      }
    } else {
      return 0;
    }

  }

  calculateValueFree(position: any, type: any, dateTiming: any): any {
    dateTiming = new Date(dateTiming);
    dateTiming.setDate(dateTiming.getDate() + 1);
    let counterDay = 0;
    let counterEvening = 0;
    let counterNight = 0;
    let swing1 = 0;
    let swing2 = 0;
    let lists = [];
    if (localStorage.getItem('dayview-lists')) {
      lists = JSON.parse(localStorage.getItem('dayview-lists'));
    }

    if (this.assignments.length != 0) {
      for (const assignment of this.assignments) {
        if (assignment.date == dateTiming.toJSON().substring(0, 10)) {
            if (lists.length > 0) {
              if (
                assignment.position == position &&
                assignment.status == 1 && assignment.clinical && lists.includes(assignment.listId)
              ) {
                for (const time of this.assignmentTimings) {
                  if (
                    this.calculateMinutes(time.end) >
                    this.calculateMinutes(time.start)
                  ) {
                    if (
                      this.calculateMinutes(time.start) <=
                      this.calculateMinutes(assignment.start.substring(11, 16)) &&
                      this.calculateMinutes(time.end) >=
                      this.calculateMinutes(assignment.start.substring(11, 16))
                    ) {
                      if (time.type == type) {
                        if (type == 'DAY_ASSIGNMENT') {
                          counterDay++;
                        } else if (type == 'EVENING_ASSIGNMENT') {
                          counterEvening++;
                        } else if (type == 'NIGHT_ASSIGNMENT') {
                          counterNight++;
                        } else if (type == 'SWING_1') {
                          swing1++;
                        } else if (type == 'SWING_2') {
                          swing2++;
                        }
                      }
                    }
                  } else {
                    if (
                      this.calculateMinutes(time.start) <=
                      this.calculateMinutes(assignment.start.substring(11, 16))
                    ) {
                      if (time.type == type) {
                        if (type == 'DAY_ASSIGNMENT') {
                          counterDay++;
                        } else if (type == 'EVENING_ASSIGNMENT') {
                          counterEvening++;
                        } else if (type == 'NIGHT_ASSIGNMENT') {
                          counterNight++;
                        } else if (type == 'SWING_1') {
                          swing1++;
                        } else if (type == 'SWING_2') {
                          swing2++;
                        }
                      }
                    }
                  }
                }
              }
            }
            else {
              if (
                assignment.position == position &&
                assignment.status == 1 && assignment.clinical
              ) {
                for (const time of this.assignmentTimings) {
                  if (
                    this.calculateMinutes(time.end) >
                    this.calculateMinutes(time.start)
                  ) {
                    if (
                      this.calculateMinutes(time.start) <=
                      this.calculateMinutes(assignment.start.substring(11, 16)) &&
                      this.calculateMinutes(time.end) >=
                      this.calculateMinutes(assignment.start.substring(11, 16))
                    ) {
                      if (time.type == type) {
                        if (type == 'DAY_ASSIGNMENT') {
                          counterDay++;
                        } else if (type == 'EVENING_ASSIGNMENT') {
                          counterEvening++;
                        } else if (type == 'NIGHT_ASSIGNMENT') {
                          counterNight++;
                        } else if (type == 'SWING_1') {
                          swing1++;
                        } else if (type == 'SWING_2') {
                          swing2++;
                        }
                      }
                    }
                  } else {
                    if (
                      this.calculateMinutes(time.start) <=
                      this.calculateMinutes(assignment.start.substring(11, 16))
                    ) {
                      if (time.type == type) {
                        if (type == 'DAY_ASSIGNMENT') {
                          counterDay++;
                        } else if (type == 'EVENING_ASSIGNMENT') {
                          counterEvening++;
                        } else if (type == 'NIGHT_ASSIGNMENT') {
                          counterNight++;
                        } else if (type == 'SWING_1') {
                          swing1++;
                        } else if (type == 'SWING_2') {
                          swing2++;
                        }
                      }
                    }
                  }
                }
              }
            }


        }
      }
      if (counterDay != 0) {
        return counterDay;
      } else if (counterEvening != 0) {
        return counterEvening;
      } else if (counterNight != 0) {
        return counterNight;
      } else if (swing1 != 0) {
        return swing1;
      } else if (swing2 != 0) {
        return swing2;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  filterGoals(arr: any[]): any[] {
    //reorder goals

    const array = [];
    array.push(arr[0]);
    if (arr[3]) {
      array.push(arr[3]);
    }
    array.push(arr[1]);
    if (arr[4]) {
      array.push(arr[4]);
    }
    array.push(arr[2]);
    return array;
  }

  sortPositions(array: any): any {
    return array.sort((a: any, b: any) => a.data.groups[0].positions[0].positionId - b.data.groups[0].positions[0].positionId);
  }

  ngOnDestroy(): void {
    this.dateValueSubscription?.unsubscribe();
    this.dataCalculate.unsubscribe();
    this.monthDataSubscription?.unsubscribe();

  }

}
