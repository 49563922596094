<div class="silver_background">
  <img class="cancel-image" src="../../../../assets/images/Close.png" (click)="closeModal()"/>
  <h1>
    {{title}}
  </h1>
  <div class="flex-display-details">
    <div class="flex-display-details">
      <div style="min-width: 150px;" class="flex-display-details_circle">
        <div class="shift-circle_details" [ngStyle]="{'background-color':data?.positionColor}"></div>
        <span>{{data?.positionName}}</span>
      </div>
      <div style="min-width:150px; ">
        <span>{{data.start.substring(11, 16)}} - {{data.end.substring(11, 16)}}</span>
      </div>
      <div style="min-width: 150px; ">
        <span>{{dateFormat.toString().substring(0, 15)}}</span>
      </div>

    </div>

  </div>

  <br>
  <div class="buttonPosition">
    <button class="button closeButton" (click)="closeModal()">{{'closeButton' | translate}}</button>
  </div>
</div>

<!--<section class="silver_background" [ngStyle]=" allShifts.length > 5 ? {'height': '315px'} :-->
<!--       {'height': '220px'}">-->
<!--  <img class="dialog__image" src="../../../../assets/images/Close.png" (click)="closeModal()" alt=""/>-->
<!--  &lt;!&ndash;  <div class="dialog__title">{{ data.title }}</div>&ndash;&gt;-->
<!--  <div class="dialog__shifts-box">-->
<!--    <div class="dialog__shifts" *ngFor="let shift of allShifts">-->
<!--      {{shift}}-->
<!--      <div class="dialog__shift">-->
<!--        <div class="dialog__color-box">-->
<!--          <div class="dialog__color" *ngIf="shift?.color" [style.backgroundColor]="getPositionColor(shift)"></div>-->
<!--        </div>-->
<!--        <div class="dialog__text">{{setActivity}} ({{setActivityShort}})</div>-->
<!--        <div class="dialog__margin">{{shift.start}} - {{shift.end}}</div>-->
<!--        <div class="dialog__text" *ngIf="getTime(shift)">-->
<!--          <div class="dialog__date">{{shiftDate | CreateShiftDate}}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class=" dialog__button-box-->
<!--          ">-->
<!--        <button class="button closeButton" (click)="closeModal()">{{'closeButton' | translate}}</button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</section>-->
