<div class="calendar">
  <div class="date">
    <div class="change">
      <div (click)="previous()" style="cursor: pointer" [ngClass]="{'not-cliccable': !datesloaded}">
        <img src="assets/images/schedule/Arrow_Sort left.svg" alt="">
      </div>
      <h3
        style="margin: 0 25px 16px 25px;">{{fullMonth | titlecase}} {{date.toDateString().slice(10, 16)}}</h3>
      <div style="cursor: pointer" (click)="next()" [ngClass]="{'not-cliccable': !datesloaded}">
        <img src="assets/images/schedule/Arrow_Sort right.svg" alt="">
      </div>
    </div>
    <table class="table" style="width:100%">
      <thead style="width:100%">
      <tr style="width:100%; display:flex; justify-content: space-between">
        <th scope="col">Mån</th>
        <th scope="col">Tis</th>
        <th scope="col">Ons</th>
        <th scope="col">Tor</th>
        <th scope="col">Fre</th>
        <th scope="col">Lör</th>
        <th scope="col">Sön</th>
      </tr>
      </thead>
      <tbody>

      </tbody>
    </table>
  </div>
  <div class="wrap">
    <div class="box" [style.background]=datem.color *ngFor="let datem of datesData" (click)="chooseDay(datem.date)"
         [ngStyle]="datem.border || this.actualDay == datem.date ? {'border': '1px solid #3498d8'} : {'border': '1px solid #e6e6e6'}"
         [style.color]="datem.color && datem.color != '#f2f2f2' ? '#fff' : '#333333'"
         [ngClass]="{'hasShift': datem.color || datem.isCanceled || openHover}">
      <div class="border">
        <div class="date-number" [ngClass]="{'silver' : datem.date.getMonth() != correctMonth }">
          <div class="date-number-outline"
               [ngClass]="{ 'selected_number' : isToday(datem.date)}"> {{datem.date.getDate()}}</div>
        </div>
      </div>
      <div class="remove-shift-button" *ngIf="datem.border" (click)="removeShift()"></div>
      <div class="double-arrow" *ngIf="datem.hasDoubleShifts"></div>
      <div class="canceled-box" *ngIf="(datem.hasCanceled && !datem.color) || datem.findAbsence"
           (click)="removeShift()">
        <div class="canceled-arrow" *ngIf="!datem.color && datem.isCanceled"></div>
        <div class="canceled-reason cancelin"
             *ngIf="datem.hasCanceled && !datem.color && !datem.findAbsence && !datem.findUnavailableAbsence">{{datem.hasCanceled.cancelReason.shortCode}}</div>
        <div class="canceled-reason cancelin"
             *ngIf="datem.hasCanceled && !datem.color && !datem.findAbsence && datem.findUnavailableAbsence">{{datem.findUnavailableAbsence.cancelReason.shortCode}}</div>

        <div class="canceled-reason absin" *ngIf="(datem.findAbsence && !datem.color) && !datem.border ">
          {{datem.findAbsence.cancelReason.shortCode}}
        </div>
      </div>
      <div class="radio-button" [class.ios-radio-button]="innerWidth < 1025" (click)="selectShift(datem.date)"
           [ngClass]="{'isSelectedShift': isSelectedShift(datem.date)}"></div>
      <div class="details-button" (click)="openShift(datem.date)">
        {{'details' | translate}}
      </div>
      <div class="shifts-circles" *ngIf="datem.date != chosenDate && !isSelected(datem.date)">
        <div *ngFor="let shift of findOpenShifts(datem.date); let index=index">
          <div [ngClass]="{'white-circle': !datem.color || datem.color == '#f2f2f2'}" class="shifts-circle"
               *ngIf="index <= 4">
          </div>
        </div>
      </div>
      <!--      <ng-container *ngFor="let selDate of filterSelectedDates()">-->
      <!--        <div class="selected-date" *ngIf="datem === selDate.date">-->
      <!--          <figure class="selected-date__image-box">-->
      <!--            <img (click)="previousNumber(selDate)" class='selected-date__image'-->
      <!--                 src="../../assets/images/calendar/Arrow_Sort%20left.svg"/>-->
      <!--          </figure>-->
      <!--          <div class="selected-date__number">{{selDate.dayNumber}}</div>-->
      <!--          <figure class="selected-date__image-box">-->
      <!--            <img (click)="nextNumber(selDate)" class='selected-date__image'-->
      <!--                 src="../../assets/images/calendar/Arrow_Sort%20right.svg"/>-->
      <!--          </figure>-->
      <!--        </div>-->
      <!--      </ng-container>-->
    </div>
    <div class="calendar-dropdown">
      <div class="calendar-dropdown__link" *ngFor="let shifts of getClickedShifts" (click)="getShift(shifts)">
        <div class="calendar-dropdown__activity">
          <div class="calendar-dropdown__circle" [style.background-color]="getPositionColor(shifts)"></div>
          <div>{{getActivityShortName(shifts)}}</div>
          <div class="calendar-dropdown__time">{{getDropdownTIme(shifts)}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
