<div class="dialog-container">
<div class="dialog-header">
  {{'swapTitle' | translate}}
</div>
  <img class="shift-image" src="../../../../assets/images/Close.png" (click)="closeDialog()"/>

  <div class="action-required">
    <span *ngIf="data.swap.button.enabled"> {{'actionRequired' | translate}} </span>
    <span *ngIf="!data.swap.button.enabled"> {{data.swap.header.subtitle}} </span>
  </div>

  <div class="more-text" *ngIf="data.swap.button.enabled">
    {{'swapDialogText' | translate}}
  </div>

  <div class="more-text" *ngIf="!data.swap.button.enabled">
    <span *ngIf="data.swap.status == 4">
    {{'swapDialogTextDeclined' | translate}}
      </span>
    <span *ngIf="data.swap.status == 3">
    {{'swapDialogTextAccepted' | translate}}
      </span>
  </div>

    <div class="swap-inner">
    <div class="user">
      <img class="user-image" [ngClass]="{'whiteImage': getUserData(0, 'image') == '/assets/images/profile_white.svg'}" [src]="getUserData(0, 'image')">
      <div class="user-name">
        {{data.swap.users[0].header}}
      </div>
      <div class="user-body" [innerHTML]="manipulateText(data.swap.users[0].body)">

      </div>
    </div>

      <img class="arrows" src="assets/images/activity-logs/Swap.svg">

      <div class="user">
        <img class="user-image" [ngClass]="{'whiteImage': getUserData(1, 'image') == '/assets/images/profile_white.svg'}" [src]="getUserData(1, 'image')">
        <div class="user-name">
          {{data.swap.users[1].header}}
        </div>
        <div class="user-body" [innerHTML]="manipulateText(data.swap.users[1].body)">
        </div>
      </div>

    </div>


  <div class="for-buttons">
    <div class="button button-deny" [ngClass]="{'disabled-button': !data.swap.button.enabled}" (click)="declineSwap()">
      {{'denySwap' | translate}}
    </div>
    <div class="button button-accept" [ngClass]="{'disabled-button': !data.swap.button.enabled}" (click)="confirmSwap()">
      {{'acceptSwap' | translate}}
    </div>
  </div>
</div>
