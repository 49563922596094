<section class="container">
  <form class="login" [formGroup]="logInForm">
    <img class="login__image" src="./assets/images/login-only-logo.png"/>
    <div class="login__box">
      <div class="login__title">{{'login' | translate}}</div>
      <mat-form-field *ngIf="!changeInput" class="login__form-field"
                      style="margin-bottom: 21px">
        <mat-label class="login__label">{{'emailLabel' | translate}}</mat-label>
        <input (keyup.enter)="changeInputs()" class="login__input" formControlName="email" matInput #emailText>
      </mat-form-field>
      <mat-form-field *ngIf="changeInput" class="login__form-field"
                      style="margin-bottom: 21px">
        <mat-label class="login__label">{{'passwordLabel' | translate}}</mat-label>
        <input (keyup.enter)="login()" class="login__input" formControlName="password" type="password" matInput>
      </mat-form-field>
      <button *ngIf="!changeInput" type="button" class="button login__button"
              (click)="changeInputs()">{{'next' | translate}}
      </button>
      <button *ngIf="changeInput" type="button" class="button login__button"
              (click)="login()">{{'login' | translate}}</button>
      <div *ngIf="!changeInput"  (click)="openForgottenPassword()" class="login__text">{{'forgotPassword' | translate}}</div>
      <div *ngIf="changeInput" class="login__text">{{'forgotPassword' | translate}}</div>
    </div>
    <app-wrong-password [errorEmail]='errorEmail' (closeModal)="closeModal($event)" class="login__err-message"
                        *ngIf="err"></app-wrong-password>
  </form>
  <div class="text">{{'loginText' | translate}}</div>

  <div class="version">
    V {{version}}
  </div>
</section>
