import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShiftsService } from '../../../shared/services/shifts.service';
import { HelperService } from '../../../shared/services/helper.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { EmployeesService } from '../../../shared/services/employees.service';

@Component({
  selector: 'app-tooltip-shifts-modals',
  templateUrl: './tooltip-shifts-modals.component.html',
  styleUrls: ['./tooltip-shifts-modals.component.scss'],
})
export class TooltipShiftsModalsComponent implements OnInit {
  copy: boolean;
  delete: boolean;
  announcement: boolean;
  shift: boolean;
  assign: boolean;
  invite: boolean;
  request: boolean;
  declineApp: boolean;
  acceptApp: boolean;
  deleteShow: boolean;
  recovery: boolean;
  updateProfile: boolean;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<TooltipShiftsModalsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shiftsService: ShiftsService,
    private helperService: HelperService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private employeesService: EmployeesService
  ) {}

  ngOnInit(): void {
    if (this.data.mode === 'copy') {
      this.copy = true;
    } else if (this.data.mode === 'delete') {
      this.delete = true;
    } else if (this.data.mode === 'announcement') {
      this.announcement = true;
    } else if (this.data.mode === 'delete-shift') {
      this.shift = true;
    } else if (this.data.mode === 'assign') {
      this.assign = true;
    } else if (this.data.mode === 'invite') {
      this.invite = true;
    } else if (this.data.mode === 'request') {
      this.request = true;
    } else if (this.data.mode === 'declineApp') {
      this.declineApp = true;
    } else if (this.data.mode === 'acceptApp') {
      this.acceptApp = true;
    } else if (this.data.mode === 'deleteShow') {
      this.deleteShow = true;
    } else if (this.data.mode === 'recovery') {
      this.recovery = true;
    } else if (this.data.mode === 'updateProfile') {
      this.updateProfile = true;
    }
  }

  deleteAssignmentShift(): void {
    if (this.shift) {
      this.shiftsService.deleteAssignmentType(this.data.person.id).subscribe(
        () => {
          this.dialogRef.close(this.data.person.id);
          this.helperService.removeShift$.next(this.data.person.id);
        },
        () => {
          this.dialogRef.close(this.data.person.id);
        }
      );
    } else {
      this.shiftsService.deleteAssignment(this.data.person.id).subscribe(
        () => {
          this.dialogRef.close(this.data.person);
        },
        () => {
          this.dialogRef.close(this.data.person);
        }
      );
    }
  }

  deleteAnnouncement(): void {
    this.shiftsService
      .deleteAnnouncements(this.data.announcement.id)
      .subscribe(() => {
        this.closeModal(this.data.announcement.id);
      });
  }

  createNewUser(): void {
    this.dialogRef.close(true);
  }

  closeModal(id: any): void {
    this.dialogRef.close(id);
  }

  justCloseDelete(): void {
    this.dialogRef.close(true);
  }

  confirmCopy(): void {
    this.dialogRef.close(true);
  }

  confirmAssignment(): void {
    this.dialogRef.close(true);
  }

  confirmInvite(): void {
    this.dialogRef.close(true);
  }

  confirmRequest(): void {
    this.dialogRef.close(true);
  }

  confirmAcceptApp(): void {
    this.dialogRef.close(true);
  }

  confirmDeclineApp(): void {
    this.dialogRef.close(true);
  }

  updateProfileShift(): void {
    this.helperService.sendLoading.next();
    this.loading = true;
    this.employeesService.updateUser(this.data.update).subscribe((user) => {
      this.dialogRef.close(user);
      this.loading = false;
    });
  }

  recoveryShift(): void {
    this.shiftsService
      .editAssignment(this.data.recovery)
      .subscribe((data: any) => {
        this.helperService.recoveredShift.next(data);
        this.dialogRef.close(data);
        this.snackBar.open(this.translate.instant('shiftRecovered'), 'OK', {
          duration: 5000,
        });
      });
  }
}
