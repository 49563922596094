<div class="container" (blur)="onBlur()" (focus)="onFocus()">
  <div class="main--side">
    <ng-template [ngIf]="mode === 1">
    <div class="main--side__links main--side__links--top" (click)="goTo('/dashboard')" *ngIf="checkIfNotRoster()"
         [ngClass]="{'active-route': isActive('/dashboard')}">
      <img class='main--side__image' src="assets/images/main/Dashboard.svg"/>
      <label class="main--side__label">{{'dashboard' | translate}}</label>
    </div>
    </ng-template>
    <div class="main--side__links" (click)="goTo('/schedule')" [ngStyle]="{'margin-top': mode === 0 ? '40px' : '0'}"
         [ngClass]="{'active-route': isActive('/schedule') || isActive('/assignment'), 'main--side__links--top': !checkIfNotRoster()}">
      <img class='main--side__image' src="assets/images/main/Schedule.svg"/>
      <label class="main--side__label">{{'schema' | translate}}</label>
    </div>
    <div class="main--side__links" (click)="goTo('/personal')" [ngClass]="{'active-route': isActive('/personal')}">
      <img class='main--side__image' src="assets/images/main/User_Group_Filled.svg"/>
      <label class="main--side__label">{{'users' | translate}}</label>
    </div>
    <div class="main--side__links" [ngClass]="{'active-route': isActive('/conversations')}" *ngIf="checkIfNotRoster() && checkIfNotCoordinator()"
         (click)="goTo('/conversations')">
      <div class="notification" *ngIf="unreadConversationsCount > 0">
        <span class="notification__count"> {{unreadConversationsCount}} </span>
      </div>
      <img class='main--side__image' src="assets/images/main/Chat_Filled.svg"/>
      <label class="main--side__label">{{'messages' | translate}}</label>
    </div>
    <div class="main--side__links" (click)="goTo('/activity')" [ngClass]="{'active-route': isActive('/activity')}" *ngIf="checkIfNotRoster() && checkIfNotCoordinator()">
      <div class="notification" *ngIf="activityCount.unseen > 0">
        <span class="notification__count"> {{activityCount.unseen}} </span>
      </div>
      <img class='main--side__image' src="assets/images/main/Activity log.svg"/>
      <label class="main--side__label">{{'activityLogs' | translate}}</label>
    </div>
  </div>
  <div class="main--container">
    <div class="main--header">
      <div class="main--header__name-box">
        <div class="label">{{'loggedInAs' | translate}}:</div>
        <div class="main--header__name">{{firstName}} {{lastname}}</div>
      </div>
      <div class="main--header__vertical--line"></div>
      <div class="for-worksites" (click)="showWorksiteList = true" (clickOutside)="showWorksiteList = false">
        <div class="label">{{'section' | translate}}: {{selectedGroupName}}
        </div>
        <mat-icon class="dropdown-icon">arrow_drop_down</mat-icon>
      </div>
      <div class="main--header__vertical--line"></div>
      <div class="label" *ngIf="authService.isLoggedIn$ | async">
        <div class="label__logout" (click)="logOut()">
          <div>
            <img class="main--header__image" src="../../../assets/images/main/padlock.svg"/>
          </div>
          <div class="main--header__logout-text">{{'logOut' | translate}}</div>
        </div>
      </div>
      <div class="label" *ngIf="authService.isLoggedOut | async">
        <div class="label__logout" (click)="goTo('/login')">
          {{'login' | translate}}
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
<div class="for-list" *ngIf="showWorksiteList">
  <div class="worksite" (click)="selectGroup(g)" *ngFor="let g of groups">
    <div>{{g.groupTitle}}</div>
    <img *ngIf="g?.groupTitle === selectedGroupName" class="check-mark"
         src="../../../assets/images/Checkmark.svg"/>
  </div>
</div>
<div class="version">
  V {{version}}
</div>
