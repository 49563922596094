import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ShiftsService } from '../shared/services/shifts.service';
import { HelperService } from '../shared/services/helper.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss'],
})
export class CreateGroupComponent implements OnInit {
  groupId: any = localStorage.getItem('gruopId');
  groupValue: any = { groupId: this.groupId, description: '', name: '' };
  constructor(
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<any>,
    private shift: ShiftsService,
    private helper: HelperService
  ) {}

  ngOnInit(): void {}
  cancel(): void {
    this.dialogRef.close();
  }

  create(): any {
    if (this.groupValue.description == '' || this.groupValue.name == '') {
      this.snackBar.open(
        this.translate.instant('pleaseCompleteAllFields'),
        'OK',
        { duration: 3000 }
      );
      return;
    }
    this.shift.createGroup(this.groupValue).subscribe((response: any) => {
      this.dialogRef.close('save');
      this.helper.groupValue.next(response);
    });
  }
}
