import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'DatePipe' })
export class CustomDatePipe implements PipeTransform {
  private date: any;

  transform(date: Date, day: number): string {
    date.setDate(this.date.getDate() - day);
    // @ts-ignore
    return date;
  }
}
