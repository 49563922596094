import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ShiftsService } from '../shared/services/shifts.service';
import { Swap } from '../shared/models/swap.model';
import { EmployeesService } from '../shared/services/employees.service';
import { TranslateService } from '@ngx-translate/core';

class DialogData {
  // @ts-ignore
  public shifts: any;
  mode: any;
}

@Component({
  selector: 'app-details-shift',
  templateUrl: './details-shift.component.html',
  styleUrls: ['./details-shift.component.scss'],
})
export class DetailsShiftComponent implements OnInit {
  data: any = null;
  dateFormat: any = null;
  allShifts: any[] = [];
  activities: any = [];
  modalShift: any[] = [];
  setActivity: string | undefined;
  setActivityShort: string | undefined;
  shiftDate: string | undefined;
  title: string | undefined;

  constructor(
    private dialog: MatDialog,
    private shiftService: ShiftsService,
    private employeesService: EmployeesService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public shifts: DialogData,
    @Inject(MAT_DIALOG_DATA) public mode: DialogData,
    private dialogRef: MatDialogRef<any>
  ) {}

  ngOnInit(): void {
    if (this.shifts.mode === 'assigned') {
      this.title = this.translate.instant('assignedShifts');
    } else if (this.shifts.mode === 'canceled') {
      this.title = this.translate.instant('cancellations');
    }

    this.shiftService
      .getAssignmentById(this.shifts.shifts.targetId)
      .subscribe((response: any) => {
        this.modalShift = response;
        // this.dateFormat = new Date(this.shifts?.start);
      });

    this.modalShift.forEach((x) => {
      for (let i = 0; i < x.dayNumber; i++) {
        // @ts-ignore
        this.allShifts.push(x);
        this.allShifts.sort(
          (date1: { date: number }, date2: { date: number }) =>
            date1.date !== date2.date ? (date1.date < date2.date ? -1 : 1) : 0
        );
      }
    });

    this.employeesService.userActvity().subscribe((activities) => {
      activities.forEach((group: any) => {
        this.modalShift.forEach((shift) => {
          if (
            group.groupId === shift.groupId &&
            group.groupStatus === 'ACTIVE'
          ) {
            this.activities.push(group);
            this.activities.forEach((activity: any) => {
              if (activity.id === shift?.activityId) {
                this.setActivity = activity.title;
                this.setActivityShort = activity.shortTitle;
              }
            });
          }
        });
      });
    });
  }

  getPositionColor(color: any): any {
    let colorOfShift = '';
    const indexColor = this.allShifts.findIndex(
      (findColor: any) => findColor.id === color.id
    );
    if (indexColor !== -1) {
      colorOfShift = color.color;
    }
    if (colorOfShift?.startsWith('##')) {
      return colorOfShift.substring(1);
    }
    if (colorOfShift?.startsWith('#')) {
      return colorOfShift;
    } else {
      return '#' + colorOfShift;
    }
  }

  getTime(shift: any): boolean {
    this.shiftDate = shift.date;
    if (this.shiftDate) {
      return true;
    } else {
      return false;
    }
  }

  closeModal(): any {
    this.dialogRef.close();
  }
}
