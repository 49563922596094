import { Pipe, PipeTransform } from '@angular/core';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {enGB, sv} from "date-fns/locale";
import {format} from "date-fns";

@Pipe({
  name: 'convertDateOnly'
})
export class ConvertDateOnlyPipe implements PipeTransform {
  constructor(public translateService: TranslateService) {
    this.translateService.currentLang = 'sv';
  }

  // tslint:disable-next-line:typedef
  transform(value: string) {
    const formatter: string =
      this.translateService.currentLang === 'sv'
        ? 'EEEE d MMM.'
        : 'EEEE d MMM.';
    let localeLang = this.translateService.currentLang === 'sv' ? sv : enGB;
    let resultDate = format(new Date(value), 'd MMM', {
      locale: localeLang,
    });

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      localeLang = this.translateService.currentLang === 'sv' ? sv : enGB;
      resultDate = format(new Date(value), 'd MMM.', {
        locale: localeLang,
      });
    });

    return resultDate;
  }


}
