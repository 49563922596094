import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-wrong-password',
  templateUrl: './wrong-password.component.html',
  styleUrls: ['./wrong-password.component.scss'],
})
export class WrongPasswordComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  @Input() errorEmail: string | undefined;
  switchMessages: boolean | undefined;

  constructor() {}

  ngOnInit(): void {
    if (this.errorEmail) {
      this.switchMessages = true;
    } else {
      this.switchMessages = false;
    }
  }

  close(): void {
    this.closeModal.emit();
  }
}
