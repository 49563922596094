import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-personal',
  templateUrl: './delete-personal.component.html',
  styleUrls: ['./delete-personal.component.scss'],
})
export class DeletePersonalComponent implements OnInit {
  delete = false;
  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<any>
  ) {}

  ngOnInit(): void {}
  cancel(): void {
    this.dialogRef.close();
  }

  deleteFunction(): any {
    this.delete = true;
    this.dialogRef.close(this.delete);
  }
}
