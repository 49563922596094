import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormGroup, FormBuilder} from '@angular/forms';
import {AuthService} from '../../shared/services/auth.service';
import {UserService} from '../../shared/services/user.service';
import {EmployeesService} from '../../shared/services/employees.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {User} from '../../shared/models/user.model';
import {Group} from '../../shared/models/group.model';
import {UserAuthorities} from '../../shared/models/userAuthorities.model';
import {
  Competence,
  Contract,
  Position,
} from '../../shared/models/attribute.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ChatModalComponent} from '../modals/chat-modal/chat-modal.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {HelperService} from '../../shared/services/helper.service';
import {PersonalGroupComponent} from '../modals/personal-group/personal-group.component';
import {AbsenceModalComponent} from '../modals/absence-modal/absence-modal.component';
import {DomSanitizer} from '@angular/platform-browser';
import {group} from '@angular/animations';
import {TooltipShiftsModalsComponent} from '../modals/tooltip-shifts-modals/tooltip-shifts-modals.component';
import {ConfirmDialogComponent} from '../modals/confirm-dialog/confirm-dialog.component';
import {WrongRolePopUpComponent} from '../../wrong-role-pop-up/wrong-role-pop-up.component';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
  editEmployer: FormGroup = this.fb.group({
    firstname: [''],
    lastname: [''],
    email: [''],
    mobile: [''],
    textarea: [''],
    position: [''],
    competence: [''],
    contract: [''],
    status: [''],
    role: [''],
    note: [''],
  });
  @Input() person: any | undefined;
  @Input() isActive: string | undefined;
  @Input() toHideIcons: any | undefined;
  @Output() back = new EventEmitter();
  user$: Observable<User> | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  status: string | undefined = 'ACTIVE';
  role?: any;
  userId: number | undefined;
  userAuthorities: UserAuthorities;
  mode: string | undefined;
  showCompetence = false;
  showPositions = false;
  showContract = false;
  showRoll = false;
  showStatus = false;
  position: string | undefined;
  competence: string | undefined;
  competenceId: number;
  contractId: number;
  contract: string | undefined;
  positionId: number | undefined;
  positions$?: Observable<any>;
  competences$?: Observable<any>;
  contracts$?: Observable<any>;
  userPosition: any;
  optionCompetences: Competence[] = [];
  userCompetences: Competence[] = [];
  optionPositions: Position[] = [];
  optionContracts: Contract[] = [];
  userRoles: any[] = [];
  fineRoles: any[] = [];
  hideIcons = false;
  isLoggedIn = true;
  sub = '';
  group: Group = {id: 0, title: '', description: ''};
  update = false;
  groupId = 0;
  toggleChat = true;
  selectedStatuses: any[] = [];
  fromDayView: string | undefined;
  userDeleted = false;

  statusSelect = [
    {
      id: 1,
      status: 'active',
    },
    {
      id: 2,
      status: 'inactive',
    },
  ];

  statusId: number;
  rowStatus: string | undefined;
  rollStatus: string | undefined;
  userRole = ['EMPLOYEE'];
  rolls = [
    {
      id: 1,
      roll: 'EMPLOYEE',
      name: 'Medarbetare',
    },
    {
      id: 2,
      roll: 'EMPLOYER',
      name: 'Admin',
    },
    {
      id: 3,
      roll: 'ROSTER',
      name: 'Avdelningsschema',
    },
    {
      id: 5,
      roll: 'COORDINATOR',
      name: 'Jour admin',
    }
  ];
  user: User;
  selectedCompetences: Competence[] = [];
  loggedRole = '';

  constructor(
    public dialogRef: MatDialogRef<UserDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private employeesService: EmployeesService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog,
    private helperService: HelperService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.loggedRole = localStorage.getItem('gapUserRole');
    if (this.data.mode) {
      this.fromDayView = this.data.mode;
    }
    const isSingIn = this.authService.isSignedIn();
    this.groupId = Number(localStorage.getItem('gruopId'));

    if (isSingIn) {
      this.userId = Number(this.activatedRoute.snapshot.paramMap.get('id'));
      if (!this.userId) {
        this.userId = this.person.id;
        this.hideIcons = true;
      }
      const update = this.activatedRoute.snapshot.paramMap.get('update');
      if (update === 'update') {
        this.update = true;
      }

      if (this.userId) {
        this.userInfo(this.userId);
      } else {
        this.positions$ = this.employeesService.userPositions();
        this.competences$ = this.employeesService.userCompetences();
        this.contracts$ = this.employeesService.userContracts();
      }
    }
  }

  userInfo(userId: number): void {
    setTimeout(() => {
      this.helperService.userId.next(this.userId);
    }, 500);
    this.userService.getEmployeeById(this.userId).subscribe((x) => {
      this.user = x;
      this.isLoggedIn = this.user.loggedIn;
      // this.rowStatus = this.user.userAuthorities.roles.EMPLOYEE[0].status;

      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.status = this.translate.instant(this.user.rowStatus.toLowerCase());
      this.helperService.userName.next(this.firstName + ' ' + this.lastName);
      this.userAuthorities = this.user.userAuthorities;
      this.editEmployer.patchValue({
        note: this.user.note,
      });

      // @ts-ignore
      this.userRole = Object.keys(this.user.userAuthorities?.roles);
      // @ts-ignore

      this.positions$ = this.employeesService.userPositions({
        sort: 'title',
        group: this.group,
      });
      // tslint:disable-next-line:no-shadowed-variable
      this.positions$.subscribe((positions: Position[]) => {
        this.userPosition = positions.filter(
          // tslint:disable-next-line:no-shadowed-variable
          (x) => x.id === this.positionId
        );

        this.optionPositions = positions.filter(
          // tslint:disable-next-line:no-shadowed-variable
          (x) => x.groupId === this.groupId
        );
        this.editEmployer.patchValue({
          position: this.userPosition[0]?.title,
        });
      });

      this.competences$ = this.employeesService.userCompetences();
      // tslint:disable-next-line:no-shadowed-variable
      this.competences$.subscribe((competences: Competence[]) => {
        this.optionCompetences = competences.filter(
          (c) => c.groupId === this.groupId
        );
      });

      this.contracts$ = this.employeesService.userContracts();
      // tslint:disable-next-line:no-shadowed-variable
      this.contracts$.subscribe((contracts: Contract[]) => {
        this.optionContracts = contracts.filter(
          (c) => c.groupId === this.groupId
        );
      });

      // tslint:disable-next-line:no-shadowed-variable
      this.rolls.find((x) => {
        // @ts-ignore
        if (x.roll === this.userRole[0]) {
          // this.editEmployer.patchValue({
          //   role: this.toTitleCase(x.roll),
          // });
        }
      });
      this.selectedStatuses = [];
      if (this.user.userAuthorities?.roles.EMPLOYEE) {
        this.role = this.user.userAuthorities?.roles.EMPLOYEE;
        this.userRoles = this.role.forEach((usRole: any) => {
          if (usRole.groupId == this.groupId) {
            this.status = this.translate.instant(usRole.status.toLowerCase());
            this.rowStatus = usRole.status;
            this.selectedStatuses.push('EMPLOYEE');
            if (this.selectedStatuses) {
              this.rollStatus = this.selectedStatuses[0];
            }
          }
        });
        const groupIndex = this.role.findIndex(
          (g: any) => g.groupId == this.groupId
        );
        if (groupIndex != -1) {
          this.getSub(this.role[groupIndex]);
          this.userCompetences = this.role[groupIndex].competences;
          this.selectedCompetences = this.role[groupIndex]?.competences;
          this.positionId = this.role[groupIndex]?.position?.positionId;
          this.competenceId = this.selectedCompetences[0]?.competenceId;
          this.contractId = this.role[groupIndex].contractId;
          this.editEmployer.patchValue({
            contract: this.role[groupIndex]?.contractTitle,
          });
          this.editEmployer.patchValue({
            status: this.translate.instant(this.role[groupIndex]?.status?.toString().toLocaleLowerCase()),
          });
        } else {
          this.getSub(this.role[0]);
          this.sub = 'Användaren raderad';
          this.userDeleted = true;
          this.userCompetences = this.role[0]?.competences;
          this.selectedCompetences = this.role[0]?.competences;
          this.positionId = this.role[0]?.position?.positionId;
          if (this.selectedCompetences) {
            this.competenceId = this.selectedCompetences[0]?.competenceId;
          }
          this.contractId = this.role[0]?.contractId;
          this.editEmployer.patchValue({
            contract: this.role[0]?.contractTitle,
          });
          this.editEmployer.patchValue({
            status: this.translate.instant(this.role[0]?.status?.toString().toLocaleLowerCase()),
          });
        }
        let competencesString = '';
        if (this.selectedCompetences.length > 0) {
          this.selectedCompetences.forEach((c) => {
            if (competencesString === '') {
              competencesString = competencesString + c.competenceShortTitle;
            } else {
              competencesString =
                competencesString + ', ' + c.competenceShortTitle;
            }
          });
        } else {
          competencesString = this.translate.instant('select');
        }
        this.editEmployer.patchValue({
          competence: competencesString,
        });
      }
      if (this.user.userAuthorities?.roles.EMPLOYER) {
        this.role = this.user.userAuthorities?.roles.EMPLOYER;
        this.role.forEach((usRole: any) => {
          if (usRole.groupId == this.groupId) {
            this.status = this.translate.instant(usRole.status.toLowerCase());
            this.rowStatus = usRole.status;
            this.selectedStatuses.push('MANAGER');
            if (this.selectedStatuses) {
              this.rollStatus = this.selectedStatuses[0];
            }
          }
        });
        const groupIndex = this.role.findIndex(
          (g: any) => g.groupId == this.groupId
        );
        if (groupIndex != -1) {
          this.getSub(this.role[groupIndex]);
          this.userCompetences = this.role[groupIndex].competences;

          this.selectedCompetences = this.role[groupIndex]?.competences;
          this.positionId = this.role[0]?.position?.positionId;
          this.competenceId = this.selectedCompetences[0]?.competenceId;
          this.contractId = this.role[groupIndex].contractId;
          this.editEmployer.patchValue({
            contract: this.role[groupIndex]?.contractTitle,
          });
          this.editEmployer.patchValue({
            status: this.translate.instant(this.role[groupIndex]?.status?.toString().toLocaleLowerCase()),
          });
        } else {
          this.getSub(this.role[0]);
          this.userCompetences = this.role[0].competences;
          this.selectedCompetences = this.role[0]?.competences;
          this.positionId = this.role[0]?.position?.positionId;
          this.competenceId = this.selectedCompetences[0]?.competenceId;
          this.contractId = this.role[groupIndex]?.contractId;
          this.editEmployer.patchValue({
            contract: this.role[0]?.contractTitle,
          });
          this.editEmployer.patchValue({
            status: this.translate.instant(this.role[0]?.status?.toString().toLocaleLowerCase()),
          });
        }
        let competencesString = '';
        if (this.selectedCompetences.length > 0) {
          this.selectedCompetences.forEach((c) => {
            if (competencesString === '') {
              competencesString = competencesString + c.competenceShortTitle;
            } else {
              competencesString =
                competencesString + ', ' + c.competenceShortTitle;
            }
          });
        } else {
          competencesString = this.translate.instant('select');
        }
        this.editEmployer.patchValue({
          competence: competencesString,
        });
      }
      if (this.user.userAuthorities?.roles.ADMIN) {
        this.role = this.user.userAuthorities?.roles.ADMIN;
        this.userRoles = this.role.forEach((usRole: any) => {
          if (usRole.groupId == this.groupId) {
            this.status = this.translate.instant(usRole.status.toLowerCase());
            this.rowStatus = usRole.status;
            this.selectedStatuses.push('ADMIN');
            if (this.selectedStatuses) {
              this.rollStatus = this.selectedStatuses[0];
            }
          }
        });
        // this.editEmployer.patchValue({
        //   role: statusString,
        // });
        const groupIndex = this.role.findIndex(
          (g: any) => g.groupId == this.groupId
        );
        if (groupIndex != -1) {
          this.getSub(this.role[groupIndex]);
          this.userCompetences = this.role[groupIndex].competences;
          this.selectedCompetences = this.role[groupIndex]?.competences;
          this.positionId = this.role[groupIndex]?.position?.positionId;
          this.competenceId = this.selectedCompetences[0]?.competenceId;
          this.contractId = this.role[groupIndex].contractId;
          this.editEmployer.patchValue({
            contract: this.role[groupIndex]?.contractTitle,
          });
          this.editEmployer.patchValue({
            status: this.translate.instant(this.role[groupIndex]?.status?.toString().toLocaleLowerCase()),
          });
        } else {
          this.getSub(this.role[0]);
          this.userCompetences = this.role[0].competences;
          this.selectedCompetences = this.role[0]?.competences;
          this.positionId = this.role[0]?.position?.positionId;
          this.competenceId = this.selectedCompetences[0]?.competenceId;
          this.contractId = this.role[groupIndex].contractId;
          this.editEmployer.patchValue({
            contract: this.role[0]?.contractTitle,
          });

          this.editEmployer.patchValue({
            status: this.translate.instant(this.role[0]?.status?.toString().toLocaleLowerCase()),
          });
        }
        let competencesString = '';
        if (this.selectedCompetences.length > 0) {
          this.selectedCompetences.forEach((c) => {
            if (competencesString === '') {
              competencesString = competencesString + c.competenceShortTitle;
            } else {
              competencesString =
                competencesString + ', ' + c.competenceShortTitle;
            }
          });
        } else {
          competencesString = this.translate.instant('select');
        }
        this.editEmployer.patchValue({
          competence: competencesString,
        });
      }
      if (this.user.userAuthorities?.roles.SUPER_ADMIN) {
        this.role = this.user.userAuthorities?.roles.SUPER_ADMIN;
        this.userRoles = this.role.forEach((usRole: any) => {
          if (usRole.groupId == this.groupId) {
            this.status = this.translate.instant(usRole.status.toLowerCase());
            this.rowStatus = usRole.status;
            this.selectedStatuses.push('SUPER_ADMIN');
            if (this.selectedStatuses) {
              this.rollStatus = this.selectedStatuses[0];
            }
          }
        });
        const groupIndex = this.role.findIndex(
          (g: any) => g.groupId == this.groupId
        );
        if (groupIndex != -1) {
          this.getSub(this.role[groupIndex]);
          this.userCompetences = this.role[groupIndex].competences;
          this.selectedCompetences = this.role[groupIndex]?.competences;
          this.positionId = this.role[groupIndex]?.position?.positionId;
          this.competenceId = this.selectedCompetences[0]?.competenceId;
          this.contractId = this.role[groupIndex].contractId;
          this.editEmployer.patchValue({
            contract: this.role[groupIndex]?.contractTitle,
          });

          this.editEmployer.patchValue({
            status: this.translate.instant(this.role[groupIndex]?.status?.toString().toLocaleLowerCase()),
          });
        } else {
          this.getSub(this.role[0]);
          this.userCompetences = this.role[0].competences;
          this.selectedCompetences = this.role[0]?.competences;
          this.positionId = this.role[0]?.position?.positionId;
          this.competenceId = this.selectedCompetences[0]?.competenceId;
          this.contractId = this.role[groupIndex].contractId;
          this.editEmployer.patchValue({
            contract: this.role[0]?.contractTitle,
          });

          this.editEmployer.patchValue({
            status: this.translate.instant(this.role[0]?.status?.toString().toLocaleLowerCase()),
          });
        }
        let competencesString = '';
        if (this.selectedCompetences.length > 0) {
          this.selectedCompetences.forEach((c) => {
            if (competencesString === '') {
              competencesString = competencesString + c.competenceShortTitle;
            } else {
              competencesString =
                competencesString + ', ' + c.competenceShortTitle;
            }
          });
        } else {
          competencesString = this.translate.instant('select');
        }
        this.editEmployer.patchValue({
          competence: competencesString,
        });
      }
      if (this.user.userAuthorities?.roles.ROSTER) {
        this.role = this.user.userAuthorities?.roles.ROSTER;
        this.userRoles = this.role.forEach((usRole: any) => {
          if (usRole.groupId == this.groupId) {
            this.status = this.translate.instant(usRole.status.toLowerCase());
            this.rowStatus = usRole.status;
            this.selectedStatuses.push('ROSTER');
            if (this.selectedStatuses) {
              this.rollStatus = this.selectedStatuses[0];
            }
          }
        });
        const groupIndex = this.role.findIndex(
          (g: any) => g.groupId == this.groupId
        );
        if (groupIndex != -1) {
          this.getSub(this.role[groupIndex]);
          this.userCompetences = this.role[groupIndex].competences;
          this.selectedCompetences = this.role[groupIndex]?.competences;
          this.positionId = this.role[groupIndex]?.position?.positionId;
          this.competenceId = this.selectedCompetences[0]?.competenceId;
          this.contractId = this.role[groupIndex].contractId;
          this.editEmployer.patchValue({
            contract: this.role[groupIndex]?.contractTitle,
          });

          this.editEmployer.patchValue({
            status: this.translate.instant(this.role[groupIndex]?.status?.toString().toLocaleLowerCase()),
          });
        } else {
          this.getSub(this.role[0]);
          this.userCompetences = this.role[0].competences;
          this.selectedCompetences = this.role[0]?.competences;
          this.positionId = this.role[0]?.position?.positionId;
          this.competenceId = this.selectedCompetences[0]?.competenceId;
          this.contractId = this.role[groupIndex]?.contractId;
          this.editEmployer.patchValue({
            contract: this.role[0]?.contractTitle,
          });

          this.editEmployer.patchValue({
            status: this.translate.instant(this.role[0]?.status?.toString().toLocaleLowerCase()),
          });
        }
        let competencesString = '';
        if (this.selectedCompetences.length > 0) {
          this.selectedCompetences.forEach((c) => {
            if (competencesString === '') {
              competencesString = competencesString + c.competenceShortTitle;
            } else {
              competencesString =
                competencesString + ', ' + c.competenceShortTitle;
            }
          });
        } else {
          competencesString = this.translate.instant('select');
        }
        this.editEmployer.patchValue({
          competence: competencesString,
        });
      }
      if (this.user.userAuthorities?.roles.COORDINATOR) {
        this.role = this.user.userAuthorities?.roles.COORDINATOR;
        this.userRoles = this.role.forEach((usRole: any) => {
          if (usRole.groupId == this.groupId) {
            this.status = this.translate.instant(usRole.status.toLowerCase());
            this.rowStatus = usRole.status;
            this.selectedStatuses.push('COORDINATOR');
            if (this.selectedStatuses) {
              this.rollStatus = this.selectedStatuses[0];
            }
          }
        });
        const groupIndex = this.role.findIndex(
          (g: any) => g.groupId == this.groupId
        );
        if (groupIndex != -1) {
          this.getSub(this.role[groupIndex]);
          this.userCompetences = this.role[groupIndex].competences;
          this.selectedCompetences = this.role[groupIndex]?.competences;
          this.positionId = this.role[groupIndex]?.position?.positionId;
          this.competenceId = this.selectedCompetences[0]?.competenceId;
          this.contractId = this.role[groupIndex].contractId;
          this.editEmployer.patchValue({
            contract: this.role[groupIndex]?.contractTitle,
          });

          this.editEmployer.patchValue({
            status: this.translate.instant(this.role[groupIndex]?.status?.toString().toLocaleLowerCase()),
          });
        } else {
          this.getSub(this.role[0]);
          this.userCompetences = this.role[0].competences;
          this.selectedCompetences = this.role[0]?.competences;
          this.positionId = this.role[0]?.position?.positionId;
          this.competenceId = this.selectedCompetences[0]?.competenceId;
          this.contractId = this.role[groupIndex]?.contractId;
          this.editEmployer.patchValue({
            contract: this.role[0]?.contractTitle,
          });

          this.editEmployer.patchValue({
            status: this.translate.instant(this.role[0]?.status?.toString().toLocaleLowerCase()),
          });
        }
        let competencesString = '';
        if (this.selectedCompetences.length > 0) {
          this.selectedCompetences.forEach((c) => {
            if (competencesString === '') {
              competencesString = competencesString + c.competenceShortTitle;
            } else {
              competencesString =
                competencesString + ', ' + c.competenceShortTitle;
            }
          });
        } else {
          competencesString = this.translate.instant('select');
        }
        this.editEmployer.patchValue({
          competence: competencesString,
        });
      }


      if (!this.user.userAuthorities?.roles.COORDINATOR &&
        !this.user.userAuthorities?.roles.EMPLOYEE &&
        !this.user.userAuthorities?.roles.EMPLOYER &&
        !this.user.userAuthorities?.roles.ROSTER &&
        !this.user.userAuthorities?.roles.SUPER_ADMIN

      ) {
        this.sub = 'Användaren raderad';
        this.userDeleted = true;

      }


      let statusString = '';
      this.selectedStatuses.forEach((s) => {
        if (s == 'EMPLOYER' || s == 'MANAGER') {
          s = 'Admin';
        } else if (s == 'EMPLOYEE') {
          s = 'Medarbetare';
        } else if (s == 'ROSTER') {
          s = 'Avdelningsschema';
        } else if (s == 'COORDINATOR') {
          s = 'Jour admin';
        }

        if (s) {
          if (statusString === '') {
            statusString = statusString + s.toLowerCase();
          } else {
            statusString = statusString + ', ' + s.toLowerCase();
          }
        }
      });
      this.editEmployer.patchValue({
        role: statusString,
      });

      const groupIndex = this.role?.findIndex(
        (g: any) => g.groupId == this.groupId
      );

      // if (groupIndex != -1) {
      //   if (this.role && groupIndex) {
      //   this.editEmployer.patchValue({
      //     status: this.role[groupIndex]?.status?.toString().toLocaleLowerCase(),
      //   });
      //   }
      // } else {
      //   this.editEmployer.patchValue({
      //     status: this.role[0]?.status?.toString().toLocaleLowerCase(),
      //   });
      // }

      this.helperService.userPosition.next(this.positionId);

      this.editEmployer.patchValue({
        firstname: this.user.firstName,
        lastname: this.user.lastName,
        email: this.user.email,
        mobile: this.user.tel1,
      });


    });

  }

  /**
   * Display Positions dropdown
   */
  displayPositions(): void {
    if (this.update) {
      this.showPositions = !this.showPositions;
    }
  }

  getCompetences(): string {
    let competenceString = '';
    this.userCompetences.forEach((c) => {
      if (competenceString === '') {
        competenceString = competenceString + c.competenceShortTitle;
      } else {
        competenceString = competenceString + ', ' + c.competenceShortTitle;
      }
    });
    return competenceString;
  }

  getSub(data: {
    position: { positionShortTitle: string };
    contractTitle: string;
  }): void {
    if (data?.position?.positionShortTitle && data?.contractTitle) {
      let competenceString = '';
      competenceString = data?.position?.positionShortTitle;
      this.sub = competenceString + ', ' + data?.contractTitle;
    } else {
      this.sub = 'Användaren raderad';
    }

  }

  /**
   * Display Competence dropdown
   */
  displayCompetence(): void {
    if (this.update) {
      this.showCompetence = !this.showCompetence;
    }
  }

  /**
   * Display Contract dropdown
   */
  displayContract(): void {
    if (this.update) {
      this.showContract = !this.showContract;
    }
  }

  /**
   * Display Roll dropdown
   */
  displayRoll(): void {
    if (this.update) {
      this.showRoll = !this.showRoll;
    }
  }

  /**
   * Display Status dropdown
   */
  displayStatus(): void {
    if (this.update) {
      this.showStatus = !this.showStatus;
    }
  }

  getContract(contract: any): void {
    if (this.update) {
      this.contract = contract.title;
      this.contractId = contract.id;
      this.editEmployer.patchValue({
        contract: this.contract,
      });
    }
  }

  getRole(status: string): void {
    if (!this.isSelectable(status)) {
      return;
    }
    if (this.update) {
      const statusIndex = this.selectedStatuses.findIndex((s: any) => {
        if (s == 'MANAGER') {
          s = 'EMPLOYER';
        }
        return s === status;
      });

      if (statusIndex === -1) {
        this.selectedStatuses.push(status);
        this.rollStatus = this.selectedStatuses[0];
      } else {
        this.selectedStatuses.splice(statusIndex, 1);
      }

      let statusString = '';

      this.selectedStatuses.forEach((s) => {
        if (s == 'EMPLOYER' || s == 'MANAGER') {
          s = 'Admin';
        } else if (s == 'EMPLOYEE') {
          s = 'Medarbetare';
        } else if (s == 'ROSTER') {
          s = 'Avdelningsschema';
        } else if (s == 'COORDINATOR') {
          s = 'Jour admin';
        }

        if (s) {
          if (statusString === '') {
            statusString = statusString + s.toLowerCase();
          } else {
            statusString = statusString + ', ' + s.toLowerCase();
          }
        }
      });

      this.editEmployer.patchValue({
        role: statusString,
      });
    }
  }

  public isSelectedRole(role: any): boolean {
    const index = this.selectedStatuses.findIndex((r: any) => {
      if (r == 'MANAGER') {
        r = 'EMPLOYER';
      }
      return r == role.roll;
    });

    return index != -1;
  }

  getCompetence(competence: Competence): void {
    if (this.update) {
      if (competence == null) {
        this.selectedCompetences = [];
      } else {
        competence.competenceId = competence.id;
        const index = this.selectedCompetences.findIndex((c: any) => c.competenceId == competence.id);
        if (index != -1) {
          this.selectedCompetences.splice(index, 1);
        } else {
          this.selectedCompetences.push(competence);
        }
      }


      let competencesString = '';
      if (this.selectedCompetences.length > 0) {
        this.selectedCompetences.forEach((c) => {
          let name = '';
          if (c.competenceShortTitle) {
            name = c.competenceShortTitle;
          } else {
            name = c.shortTitle;
          }
          if (competencesString === '') {
            competencesString = competencesString + name;
          } else {
            competencesString =
              competencesString + ', ' + name;
          }
        });
      } else {
        competencesString = this.translate.instant('select');
      }

      this.editEmployer.patchValue({
        competence: competencesString,
      });

    }
  }

  public competenceSelected(competence: any): boolean {
    const index = this.selectedCompetences?.findIndex((c: any) => c.competenceId == competence.id);

    return index != -1;
  }

  getPosition(position: any): void {
    if (this.update) {
      this.position = position.title;
      this.positionId = position.id;
      this.editEmployer.patchValue({
        position: this.position,
      });
    }
  }

  getStatus(status: { id: number; status: string }): void {
    if (this.update) {
      this.rowStatus = status.status;
      this.editEmployer.patchValue({
        status: this.translate.instant(status.status.toString().toLowerCase()),
      });
    }
  }


  getRoll(roll: { roll: string; id: number }): void {
    if (this.update) {
      this.editEmployer.patchValue({
        role: this.toTitleCase(roll.roll),
      });
    }
  }

  toTitleCase(str: string): string {
    str = str.charAt(0)?.toUpperCase() + str.substring(1)?.toLowerCase();
    return str;
  }

  saveLogic(): void {

    // save older authorities
    const authorities: any[] = [];
    this.userAuthorities.roles.EMPLOYEE?.forEach((r) => {
      if (r.groupId != this.groupId) {
        const groups = [];
        const obj = {
          group: r.groupId,
          contract: r.contractId,
          groupStatus: 'active',
          position: r.position,
          positions: [r.position],
          salaryModel: 0
        };

        const competences: any[] = [];
        obj.position?.competences.forEach((c: any) => {
          competences.push(c.competenceId);
        });
        if (obj.position) {
          obj.position.competenceIds = competences;
        }

        obj.positions.forEach((p: any) => {
          const posComp: any[] = [];
          p?.competences.forEach((c: any) => {
            posComp.push(c.competenceId);
          });
          if (p != null) {
            p.competenceIds = posComp;
          }
        });
        // @ts-ignore
        obj.position.id = obj.position.positionId;
        // @ts-ignore
        obj.position.position = obj.position.positionId;
        groups.push(obj);
        const groupObj = {role: 'ROLE_EMPLOYEE', groups};
        authorities.push(groupObj);
      }
    });


    this.userAuthorities.roles.EMPLOYER?.forEach((r) => {
      if (r.groupId != this.groupId) {
        const groups = [];
        const obj = {
          group: r.groupId,
          contract: r.contractId,
          groupStatus: 'active',
          position: r.position,
          positions: [r.position],
          salaryModel: 0
        };

        const competences: any[] = [];
        obj.position.competences.forEach((c: any) => {
          competences.push(c.competenceId);
        });
        obj.position.competenceIds = competences;

        obj.positions.forEach((p: any) => {
          const posComp: any[] = [];
          p.competences.forEach((c: any) => {
            posComp.push(c.competenceId);
          });
          p.competenceIds = posComp;
        });
        // @ts-ignore
        obj.position.id = obj.position.positionId;
        // @ts-ignore
        obj.position.position = obj.position.positionId;
        groups.push(obj);
        const groupObj = {role: 'ROLE_EMPLOYER', groups};
        authorities.push(groupObj);
      }
    });

    this.userAuthorities.roles.COORDINATOR?.forEach((r) => {
      if (r.groupId != this.groupId) {
        const groups = [];
        const obj = {
          group: r.groupId,
          contract: r.contractId,
          groupStatus: 'active',
          position: r.position,
          positions: [r.position],
          salaryModel: 0
        };


        const competences: any[] = [];
        obj.position.competences.forEach((c: any) => {
          competences.push(c.competenceId);
        });
        obj.position.competenceIds = competences;

        obj.positions.forEach((p: any) => {
          const posComp: any[] = [];
          p.competences.forEach((c: any) => {
            posComp.push(c.competenceId);
          });
          p.competenceIds = posComp;
        });
        // @ts-ignore
        obj.position.id = obj.position.positionId;
        // @ts-ignore
        obj.position.position = obj.position.positionId;
        groups.push(obj);
        const groupObj = {role: 'ROLE_COORDINATOR', groups};
        authorities.push(groupObj);
      }
    });

    this.userAuthorities.roles.ROSTER?.forEach((r) => {
      if (r.groupId != this.groupId) {
        const groups = [];
        const obj = {
          group: r.groupId,
          contract: r.contractId,
          groupStatus: 'active',
          position: r.position,
          positions: [r.position],
          salaryModel: 0
        };


        const competences: any[] = [];
        obj.position.competences.forEach((c: any) => {
          competences.push(c.competenceId);
        });
        obj.position.competenceIds = competences;

        obj.positions.forEach((p: any) => {
          const posComp: any[] = [];
          p.competences.forEach((c: any) => {
            posComp.push(c.competenceId);
          });
          p.competenceIds = posComp;
        });
        // @ts-ignore
        obj.position.id = obj.position.positionId;
        // @ts-ignore
        obj.position.position = obj.position.positionId;
        groups.push(obj);
        const groupObj = {role: 'ROLE_ROSTER', groups};
        authorities.push(groupObj);
      }
    });

    this.userAuthorities.roles.ADMIN?.forEach((r) => {
      if (r.groupId != this.groupId) {
        const groups = [];
        const obj = {
          group: r.groupId,
          contract: r.contractId,
          groupStatus: 'active',
          position: r.position,
          positions: [r.position],
          salaryModel: 0
        };


        const competences: any[] = [];
        obj.position.competences.forEach((c: any) => {
          competences.push(c.competenceId);
        });

        obj.positions.forEach((p: any) => {
          const posComp: any[] = [];
          p.competences.forEach((c: any) => {
            posComp.push(c.competenceId);
          });
          p.competenceIds = posComp;
        });

        obj.position.competenceIds = competences;

        // @ts-ignore
        obj.position.id = obj.position.positionId;
        // @ts-ignore
        obj.position.position = obj.position.positionId;
        groups.push(obj);
        const groupObj = {role: 'ROLE_ADMIN', groups};
        authorities.push(groupObj);
      }
    });

    // find contract
    const contractIndex = this.optionContracts.findIndex((x) => {
      return x.title === this.editEmployer.value.contract;
    });

    if (contractIndex === -1) {
      this.snackBar.open(this.translate.instant('checkSelectedValues'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
      return;
    }

    const selectedContract = this.optionContracts[contractIndex];

    // find position

    const positionIndex = this.optionPositions.findIndex((p) => {
      return p.title === this.editEmployer.value.position;
    });

    if (positionIndex === -1) {
      this.snackBar.open(this.translate.instant('checkSelectedValues'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
      return;
    }

    const selectedPosition = this.optionPositions[positionIndex];
    selectedPosition.position = selectedPosition.id;
    selectedPosition.competenceIds = [];
    this.selectedCompetences.forEach((p) => {
      selectedPosition.competenceIds.push(p.competenceId);
    });

    let selectedRole = '';
    let selectedRoleString = '';
    if (this.editEmployer.value.role === 'Employee') {
      if (!this.userAuthorities.roles.EMPLOYEE) {
        this.userAuthorities.roles.EMPLOYEE = [];
      }
      selectedRole = 'ROLE_EMPLOYEE';
      selectedRoleString = 'EMPLOYEE';
      this.userAuthorities.roles.EMPLOYEE.forEach((r) => {
        if (r.groupId === this.groupId) {
          r.contractId = selectedContract.id;
          r.contractTitle = selectedContract.title;
          r.contractDescription = selectedContract.description;
          r.position = selectedPosition;
          r.competences = this.selectedCompetences;
        }
      });
    } else if (this.editEmployer.value.role === 'Employer') {
      if (!this.userAuthorities.roles.EMPLOYER) {
        this.userAuthorities.roles.EMPLOYER = [];
      }
      selectedRole = 'ROLE_EMPLOYER';
      selectedRoleString = 'EMPLOYER';
      this.userAuthorities.roles.EMPLOYER.forEach((r) => {
        if (r.groupId === this.groupId) {
          r.contractId = selectedContract.id;
          r.contractTitle = selectedContract.title;
          r.contractDescription = selectedContract.description;
          r.position = selectedPosition;
          r.competences = this.selectedCompetences;
        }
      });
    } else if (this.editEmployer.value.role === 'Admin') {
      selectedRole = 'ROLE_ADMIN';
      selectedRoleString = 'ADMIN';
      if (!this.userAuthorities.roles.ADMIN) {
        this.userAuthorities.roles.ADMIN = [];
      }
      this.userAuthorities.roles.ADMIN.forEach((r) => {
        if (r.groupId === this.groupId) {
          r.contractId = selectedContract.id;
          r.contractTitle = selectedContract.title;
          r.contractDescription = selectedContract.description;
          r.position = selectedPosition;
          r.competences = this.selectedCompetences;
        }
      });
    }

    const roles: any[] = [];
    this.selectedStatuses.forEach((r: any) => {
      const obj: any = {};
      obj.role = this.findRole(r);
      if (r == 'MANAGER') {
        r = 'EMPLOYER';
      }
      roles.push(r);
      obj.groups = [];
      const group: any = {};
      group.group = this.groupId;
      group.contract = selectedContract.id;
      group.positions = [selectedPosition];
      group.position = selectedPosition;
      group.salaryModel = 0;
      group.groupStatus = this.getRealStatus(this.editEmployer.value.status);
      obj.groups.push(group);


      let foundGroup = -1;
      let foundRole = -1;

      authorities.forEach((a: any, index: any) => {
        if (a.role == obj.role) {
          foundRole = index;
          a.groups.forEach((g: any, groupIndex: any) => {
            if (g.group == this.groupId) {
              foundGroup = groupIndex;
              g = group;
              authorities[index].groups[groupIndex] = group;
            }
          });
        }
      });

      if (foundRole == -1) {
        authorities.push(obj);
      } else {
        if (foundGroup == -1) {
          authorities[foundRole].groups.push(group);
        }
      }


    });


    const create = {
      firstName: this.editEmployer.value.firstname,
      lastName: this.editEmployer.value.lastname,
      email: this.editEmployer.value.email,
      tel1: this.editEmployer.value.mobile,
      note: this.editEmployer.value.note,
      position: this.editEmployer.value.position,
      competence: this.editEmployer.value.competence,
      contract: this.editEmployer.value.contract,
      roles,
      id: this.userId,
      rowStatus: 1,
      authorities,
    };


    if (!this.isLoggedIn) {

      const title2 = this.translate.instant('saveButton');
      const subtitle2 = this.translate.instant('sendLoginDetails');
      const message2 = this.translate.instant('connectUserToWorkSiteUserUpdate');
      const showCheckbox = true;

      const openSavePopup = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        width: '400px',
        height: '256px',
        panelClass: 'updatePopUp',
        position: {
          top: '150px',
        },
        data: {
          user: create,
          title: title2,
          subtitle: subtitle2,
          message: message2,
          showCheckbox,
        },
      });
      openSavePopup.afterClosed().subscribe((res) => {
        if (res) {
          this.update = false;
          this.snackBar.open(this.translate.instant('saved'), 'OK', {
            duration: 3000,
            panelClass: ['custom-snack-bar'],
          });
          this.helperService.updateUserPersonalSchedule.next(res);
        }
      });
    } else {
      const openSavePopup = this.dialog.open(TooltipShiftsModalsComponent, {
        disableClose: true,
        width: '290px',
        height: '190px',
        panelClass: 'defaultDialog',
        position: {
          top: '150px',
        },
        data: {
          update: create,
          title: this.translate.instant('saveChanges'),
          message: this.translate.instant('saveChangesUpdateUserText'),
          mode: 'updateProfile',
        },
      });
      openSavePopup.afterClosed().subscribe((res) => {
        if (res) {
          this.update = false;
          this.snackBar.open(this.translate.instant('saved'), 'OK', {
            duration: 3000,
            panelClass: ['custom-snack-bar'],
          });
          this.helperService.updateUserPersonalSchedule.next(res);
        }
      });
    }
  }

  save(): void {

    if (this.selectedStatuses.length == 0) {
      this.snackBar.open(this.translate.instant('noRolesMsg'), 'OK', {
        duration: 6000,
        panelClass: ['custom-snack-bar'],
      });
      return;
    }

    if (!this.selectedStatuses.includes('EMPLOYEE')) {
      const wrongRoleDialog = this.dialog.open(WrongRolePopUpComponent, {
        panelClass: 'custom-dialog-container-delete'
      });

      wrongRoleDialog.afterClosed().subscribe((result: number) => {
        if (result == 1) {
          this.saveLogic();
        }
      });

    } else {
      this.saveLogic();
    }


  }

  isSelectable(type: string): any {

    if (type == 'EMPLOYER' || type == 'MANAGER') {
      if (this.selectedStatuses.includes('ROSTER') || this.selectedStatuses.includes('COORDINATOR')) {
        return false;
      } else {
        return true;
      }
    } else if (type == 'ROSTER') {
      if (this.selectedStatuses.includes('EMPLOYER') || this.selectedStatuses.includes('COORDINATOR') || this.selectedStatuses.includes('MANAGER')) {
        return false;
      } else {
        return true;
      }
    } else if (type == 'COORDINATOR') {
      if (this.selectedStatuses.includes('EMPLOYER') || this.selectedStatuses.includes('ROSTER') || this.selectedStatuses.includes('MANAGER')) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  isSelected(competence: Competence): boolean {
    const compIndex = this.selectedCompetences.findIndex(
      (c) => c.id === competence.id
    );
    if (compIndex === -1) {
      return false;
    } else {
      return true;
    }
  }

  findRole(r: string): string {
    let role = '';
    if (r == 'EMPLOYEE') {
      role = 'ROLE_EMPLOYEE';
    } else if (r == 'EMPLOYER' || r == 'MANAGER') {
      role = 'ROLE_EMPLOYER';
    } else if (r == 'COORDINATOR') {
      role = 'ROLE_COORDINATOR';
    } else if (r == 'ROSTER') {
      role = 'ROLE_ROSTER';
    }

    return role;
  }

  getRealStatus(status: string): string {

    if (status == 'Aktiv') {
      return 'Active';
    } else {
      return 'Inactive';
    }
  }

  routeToUser(): void {
    this.backToUser();
    this.router.navigate([`personal/user/${this.userId}`]);
  }

  backToUser(): void {
    this.back.emit();
  }

  cancelUpdate(): void {
    this.update = false;
    this.userInfo(this.userId);
  }

  openChat(): void {
    const confirmDialog = this.dialog.open(ChatModalComponent, {
      disableClose: true,
      width: '560px',
      height: '650px',
      panelClass: 'chatDialog',
      position: {
        top: '100px',
      },
      data: {
        person: this.person,
      },
    });
    confirmDialog.afterClosed().subscribe(() => {
    });
  }

  switchToChat(): void {
    this.toggleChat = false;
    this.employeesService
      .getMessageThreadId(this.groupId, this.user.id)
      .subscribe((data: any) => {
        setTimeout(() => {
          // @ts-ignore
          this.helperService.personChat.next(data);
        }, 100);
      });
  }

  openGroup(): void {
    const confirmDialog = this.dialog.open(PersonalGroupComponent, {
      disableClose: true,
      width: '400px',
      height: '310px',
      panelClass: 'personalGroup',
      position: {
        top: '100px',
      },
      data: {
        person: this.person,
        userId: Number(this.activatedRoute.snapshot.paramMap.get('id')),
      },
    });
    confirmDialog.afterClosed().subscribe(() => {
    });
  }

  openAbsence(): void {
    const confirmDialog = this.dialog.open(AbsenceModalComponent, {
      disableClose: true,
      width: '290px',
      height: '492px',
      panelClass: 'personalGroup',
      position: {
        top: '100px',
      },
      data: {
        userId: Number(this.activatedRoute.snapshot.paramMap.get('id')),
      },
    });
    confirmDialog.afterClosed().subscribe(() => {
      setTimeout(() => {
        this.helperService.refreshUserCalendar.next(true);
      }, 2000);
    });
  }

  closeChat(): void {
    this.toggleChat = true;
  }


  sortPositions(): any[] {
    const sortedPositions = this.optionPositions.sort((a: any, b: any) => {
      return a.title.localeCompare(b.title);
    });
    return sortedPositions;
  }


  sortCompetences(): any[] {
    const sortedCompetences = this.optionCompetences.sort((a: any, b: any) => {
      return a.title.localeCompare(b.title);
    });
    return sortedCompetences;
  }

  sortContracts(): any[] {
    const sortedContracts = this.optionContracts.sort((a: any, b: any) => {
      return a.title.localeCompare(b.title);
    });
    return sortedContracts;
  }

  sortRolls(): any[] {
    const sortedContracts = this.optionContracts.sort((a: any, b: any) => {
      return a.title.localeCompare(b.title);
    });
    return sortedContracts;
  }
}
