import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HelperService} from '../../shared/services/helper.service';
import {DatePipe} from '@angular/common';
import {TooltipShiftsModalsComponent} from '../modals/tooltip-shifts-modals/tooltip-shifts-modals.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthConst} from '../../shared/services/const/authConst';
import {ShiftsService} from '../../shared/services/shifts.service';
import {Router} from '@angular/router';
import {EmployeesService} from '../../shared/services/employees.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-shift-details',
  templateUrl: './shift-details.component.html',
  styleUrls: ['./shift-details.component.scss'],
})
export class ShiftDetailsComponent implements OnInit, OnDestroy {
  @Input() isLoading = true;
  shift: any;
  title = '';
  selectedShifts: any[] = [];
  step2 = false;
  selectedAppType = 0;
  employees: any[] = [];
  myId = 0;
  message = '';
  applications: any[] = [];
  invitations: any[] = [];
  multipleOptions = false;

  hasManyShiftsSubscription: Subscription;
  changedApplicationDataSubscription: Subscription;
  messageChangedSubscription: Subscription;
  createdTempUserSubscription: Subscription;
  confirmApplicationSubscription: Subscription;
  declineApplicationSubscription: Subscription;
  hideManageApplications = false;

  constructor(
    private employeesService: EmployeesService,
    private router: Router,
    private shiftService: ShiftsService,
    private helper: HelperService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private translate: TranslateService,
    private snackBar: MatSnackBar
  ) {
  }


  dataLoadedHandler() {
    this.isLoading = false;
  }

  dataLoaderShow() {
     this.isLoading = true;
  }

  ngOnInit(): void {
    this.helper.assignChangedDate.subscribe(
      (data: any) => {
        if (data == 'hide') {
          this.hideManageApplications = true;
        } else if (data == 'show') {
          this.hideManageApplications = false;
        }

      }
    );
    this.myId = Number(localStorage.getItem(AuthConst.userId));
    this.shift = JSON.parse(localStorage.getItem('selectedShift'));
    //localStorage.removeItem("selectedShift");
    localStorage.setItem('selectedDate', this.shift.date);
    this.helper.selectedDate.next(this.shift.date);
    if (this.shift.status == 'PUBLISHED') {
      this.title = 'openShift';
    }

    this.hasManyShiftsSubscription = this.helper.hasManyShifts.subscribe(
      (data) => {
        if (data) {
          this.selectedShifts = data;
          this.multipleOptions = true;
          this.selectedAppType = 0;
          this.employees = [];
        } else {
          this.multipleOptions = false;
        }
      }
    );

    this.changedApplicationDataSubscription = this.helper.changedApplicationData$.subscribe(
      (data: any) => {
        this.selectedAppType = data.typeSend;
        this.employees = data.employees;
      }
    );

    this.messageChangedSubscription = this.helper.messageChanged$.subscribe(
      (data) => {
        if (data) {
          this.message = data;
        }
      }
    );

    this.createdTempUserSubscription = this.helper.createdTempUser$.subscribe(
      (data) => {
        if (data) {
          if (this.selectedShifts.length > 0) {
            let count = 0;
            this.employees = [];
            this.selectedShifts.forEach((s) => {
              const email =
                this.generateRandomString(7).toLowerCase() + '@gapstaff.com';
              data.email = email;
              data.username = email;
              this.employeesService.createExternalUser(data, s.id).subscribe(
                async (res) => {
                  this.employees.push(res);
                  count = count + 1;
                  if (count == this.selectedShifts.length - 1) {
                    this.assignShift();
                  }
                },
                (err) => {
                  this.snackBar.open(err.error.message, 'OK', {
                    duration: 3000,
                  });
                }
              );
            });
          } else {
            this.employeesService
              .createExternalUser(data, this.shift.id)
              .subscribe(
                (res) => {
                  this.employees = [res];
                  this.assignShift();
                },
                (err) => {
                  this.snackBar.open(err.error.message, 'OK', {
                    duration: 3000,
                  });
                }
              );
          }
        }
      }
    );

    if (this.shift) {
      this.shiftService
        .getApplications(this.shift.id)
        .subscribe((data: any[]) => {
          this.applications = data;
          this.helper.getApplications.next(this.applications);
        });

      this.shiftService
        .getInvitations(this.shift.id)
        .subscribe((data: any[]) => {
          this.invitations = data;
          this.helper.getInvitations.next(this.invitations);
        });
    }

    this.confirmApplicationSubscription = this.helper.confirmApplication$.subscribe(
      (data: any) => {
        if (data) {
          this.shiftService.confirmApplication(data).subscribe(() => {
            this.snackBar.open(this.translate.instant('confirmed'), 'OK', {
              duration: 3000,
              panelClass: ['custom-snack-bar'],
            });
            this.router.navigate(['/schedule']);
          });
        }
      }
    );

    this.declineApplicationSubscription = this.helper.declineApplication$.subscribe(
      (data: any) => {
        if (data) {
          this.shiftService.declineApplication(data).subscribe((res: any) => {
            window.location.reload();
            this.helper.declinedApplication.next(res);
          }, (err) => {
            window.location.reload();
          });
        }
      }
    );
  }

  generateRandomString(length: number): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  formatTime(time: string): any {
    return this.datePipe.transform(time.replace(/-/g, '/'), 'HH:mm').toString();
  }

  formatDate(date: string): any {

    const day = this.datePipe.transform(new Date(date.replace(/-/g, '/')), 'EEEE');
    const month = this.datePipe.transform(new Date(date.replace(/-/g, '/')), 'MMMM') + 'short';
    const year = this.datePipe.transform(new Date(date.replace(/-/g, '/')), 'yyyy');
    const dat = this.datePipe.transform(new Date(date.replace(/-/g, '/')), 'dd');

    return day + ' ' + dat + ' ' + this.translate.instant(month.toLowerCase()) + ' ' + year + '.';


  }

  remove(shift: any): void {
    const index = this.selectedShifts.findIndex((s: any) => s.id == shift.id);

    if (index !== -1) {
      this.selectedShifts.splice(index, 1);
    }
  }

  cancel(): void {
    this.step2 = false;
    this.multipleOptions = false;
    this.selectedShifts = [];
    this.helper.hasManyShifts.next(this.selectedShifts);
    this.helper.canSelect.next(true);
  }

  continue(): void {
    this.step2 = true;
    this.helper.canSelect.next(false);
    this.hideManageApplications = false;
  }

  assignShift(): void {
    if (this.employees.length == 0) {
      return;
    }
    const confirmDialog = this.dialog.open(TooltipShiftsModalsComponent, {
      disableClose: true,
      width: '290px',
      height: '190px',
      panelClass: 'defaultDialog',
      position: {
        top: '150px',
      },
      data: {
        title: this.translate.instant('assignShift'),
        message: this.translate.instant('assignShiftText'),
        mode: 'assign',
      },
    });

    confirmDialog.afterClosed().subscribe(async (result) => {
      if (result) {
        const employeeIds: any[] = [];
        let sent = 0;
        let employees = 0;
        for (const e of this.employees) {
          if (this.selectedShifts.length > 0) {
            this.selectedShifts.forEach(async (s) => {
              const obj = {employeeId: e.id, assignmentId: s.id};
              this.shiftService.assignAssignment(obj).subscribe(
                async (data) => {
                  sent = sent + 1;
                  employees = employees + 1;
                  localStorage.removeItem('dayview-lists');
                  localStorage.removeItem('dayview-shifts');
                  localStorage.removeItem('dayview-positions');
                  this.snackBar.open(
                    this.translate.instant('shiftAssigned'),
                    'OK',
                    {duration: 3000}
                  );
                  if (
                    sent == this.selectedShifts.length - 1
                  ) {
                    this.router.navigate(['/schedule']);
                  }
                  await new Promise((resolve) => setTimeout(resolve, 1000));
                },
                async (err) => {
                  await new Promise((resolve) => setTimeout(resolve, 1000));
                  this.snackBar.open(e.name + ': ' + err.error.message, 'OK', {
                    duration: 3000,
                  });
                }
              );
              await new Promise((resolve) => setTimeout(resolve, 1000));
            });
          } else {
            const obj = {employeeId: e.id, assignmentId: this.shift.id};
            this.shiftService.assignAssignment(obj).subscribe(
              (tempUser) => {
                localStorage.removeItem('dayview-lists');
                localStorage.removeItem('dayview-shifts');
                localStorage.removeItem('dayview-positions');
                employees = employees + 1;
                this.snackBar.open(
                  this.translate.instant('shiftAssigned'),
                  'OK',
                  {duration: 3000}
                );
                // if (employees == this.employees.length - 1) {
                this.helper.sendAssignment.next(tempUser);
                this.router.navigate(['/schedule']);
                // }
              },
              (err) => {
                this.snackBar.open(e.name + ': ' + err.error.message, 'OK', {
                  duration: 3000,
                });
              }
            );
          }
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
    });
  }

  sendInvite(): void {
    if (this.employees.length == 0) {
      return;
    }
    const confirmDialog = this.dialog.open(TooltipShiftsModalsComponent, {
      disableClose: true,
      width: '290px',
      height: '190px',
      panelClass: 'defaultDialog',
      position: {
        top: '150px',
      },
      data: {
        title: this.translate.instant('sendInvite'),
        message: this.translate.instant('sendInviteText'),
        mode: 'invite',
      },
    });

    confirmDialog.afterClosed().subscribe(async (result) => {
      if (result) {
        const employeeIds: any[] = [];
        let sent = 0;
        this.employees.forEach((x) => {
          employeeIds.push(x.id);
        });
        if (this.selectedShifts.length > 0) {
          // // @ts-ignore
          // for (let i = 0; i < this.selectedShifts; i++) {
          //   const obj = {
          //     type: 0,
          //     invitationById: this.myId,
          //     invitationToIds: employeeIds,
          //     assignmentId: this.shift.id,
          //     message: this.message,
          //   };
          //
          //   this.shiftService.sendInviteOrRequest(obj).subscribe((res) => {
          //     sent = sent + 1;
          //     setTimeout(() => {
          //       this.helper.sendAssignment.next(res);
          //       // this.router.navigate(['/schedule']);
          //       this.snackBar.open(
          //         this.translate.instant('invitesSent'),
          //         'OK',
          //         {
          //           duration: 3000,
          //         }
          //       );
          //     }, 1000);
          //   });
          // }
          this.selectedShifts.forEach(() => {
            const obj = {
              type: 0,
              invitationById: this.myId,
              invitationToIds: employeeIds,
              assignmentId: this.shift.id,
              message: this.message,
            };

            this.shiftService.sendInviteOrRequest(obj).subscribe((res) => {
              sent = sent + 1;
              if (sent == this.selectedShifts.length - 1) {
                this.helper.sendAssignment.next(res);
                this.router.navigate(['/schedule']);
                this.snackBar.open(
                  this.translate.instant('invitesSent'),
                  'OK',
                  {
                    duration: 3000,
                  }
                );
              }
            });
          });
          await new Promise((resolve) => setTimeout(resolve, 0));
        } else {
          const obj = {
            type: 0,
            invitationById: this.myId,
            invitationToIds: employeeIds,
            assignmentId: this.shift.id,
            message: this.message,
          };
          this.shiftService.sendInviteOrRequest(obj).subscribe((res) => {
            this.helper.sendAssignment.next(res);

            this.router.navigate(['/schedule']);
            this.snackBar.open(this.translate.instant('inviteSent'), 'OK', {
              duration: 3000,
            });
          });
        }
      }
    });
  }

  sendRequest(): void {
    if (this.employees.length == 0) {
      return;
    }
    const confirmDialog = this.dialog.open(TooltipShiftsModalsComponent, {
      disableClose: true,
      width: '290px',
      height: '190px',
      panelClass: 'defaultDialog',
      position: {
        top: '150px',
      },
      data: {
        title: this.translate.instant('sendRequest'),
        message: this.translate.instant('sendRequestText'),
        mode: 'request',
      },
    });

    confirmDialog.afterClosed().subscribe(async (result) => {
      if (result) {
        const employeeIds: any[] = [];
        let sent = 0;
        this.employees.forEach((x) => {
          employeeIds.push(x.id);
        });
        if (this.selectedShifts.length > 0) {
          this.selectedShifts.forEach(() => {
            const obj = {
              type: 1,
              invitationById: this.myId,
              invitationToIds: employeeIds,
              assignmentId: this.shift.id,
              message: this.message,
            };
            this.shiftService.sendInviteOrRequest(obj).subscribe(() => {
              sent = sent + 1;
              if (sent == this.selectedShifts.length - 1) {
                this.router.navigate(['/schedule']);
                this.snackBar.open(
                  this.translate.instant('requestSent'),
                  'OK',
                  {duration: 3000}
                );
              }
            });
          });
          await new Promise((resolve) => setTimeout(resolve, 1000));
        } else {
          const obj = {
            type: 1,
            invitationById: this.myId,
            invitationToIds: employeeIds,
            assignmentId: this.shift.id,
            message: this.message,
          };
          this.shiftService.sendInviteOrRequest(obj).subscribe(() => {
            this.router.navigate(['/schedule']);
            this.snackBar.open(this.translate.instant('requestSent'), 'OK', {
              duration: 3000,
            });
          });
        }
      }
    });
  }

  sortSelectedShifts(): any[] {
    return this.selectedShifts.sort((n1: any, n2: any) => {
      // @ts-ignore
      return new Date(n2.start) - new Date(n1.start);
    });
  }

  ngOnDestroy(): void {
    this.hasManyShiftsSubscription?.unsubscribe();
    this.changedApplicationDataSubscription?.unsubscribe();
    this.messageChangedSubscription?.unsubscribe();
    this.createdTempUserSubscription?.unsubscribe();
    this.confirmApplicationSubscription?.unsubscribe();
    this.declineApplicationSubscription?.unsubscribe();
  }
}
