<div class="colorForBackground">
  <div class="padding-for-dialog">
    <img _ngcontent-gfy-c97="" src="../../../../assets/images/Close.png" (click)="cancel()" class="create-user__image locationX">

    <p class="send_group">{{'delete user' | translate}}</p>
    <p class="add_text">
      {{"attention" | translate}}!<br>
      <span class="text_after">{{'deleteUserText' | translate}}  </span>
    </p>

    <div class="groups_buttons">

      <button class="group_cancel" (click)="cancel()">{{"cancelButton" | translate}}</button>
      <button class="delete_group" (click)="deleteFunction()" >{{"delete" | translate}}</button>
    </div>
   </div>
</div>
