import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { AuthConst } from './const/authConst';

@Injectable()
export class FireBaseService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private authService: AuthService
  ) {}

  // tslint:disable-next-line:typedef
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {

        localStorage.setItem(AuthConst.fbToken, token);
        const type = 'EMPLOYER';
        const platform = 'WEB';
        const userId = localStorage.getItem(AuthConst.userId);
        this.authService
          .addDevice({ token, type, platform, userId })
          .subscribe();
      },
      (err) => {}
    );
  }

  // tslint:disable-next-line:typedef
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      // @ts-ignore
      this.currentMessage.next(payload);

    });
  }
}
