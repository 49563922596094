import {
  Component,
  OnDestroy,
  OnInit,
  Input, HostListener, AfterViewInit, AfterContentInit,
} from '@angular/core';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';
import {ActivitylogsService} from '../../shared/services/activitylogs.service';
import {HelperService} from '../../shared/services/helper.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {EmployeesService} from '../../shared/services/employees.service';
import {first} from "rxjs/operators";

@Component({
  selector: 'app-user-calendar-only',
  templateUrl: './user-calendar-only.component.html',
  styleUrls: ['./user-calendar-only.component.scss'],
})
export class UserCalendarOnlyComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {
  @Input() hide: any;
  positionId: any;
  data: any = null;
  weekArray: any = [];
  daysArray: any = [];
  dateTime: any = [];
  datesloaded = true;
  maxDay: any = 0;
  selectedShift: any;
  minDay: any = 0;
  userId: any = null;
  shifts: any[] = [];
  chosenDate: string | undefined;
  selectedDate: any = [];
  show: boolean;
  groupId: number;
  show2: boolean;
  selectedShifts: any[] = [];
  getClickedShifts: any[] = [];
  correctMonth: any = null;
  fullMonth: string | undefined;
  loadOpenClicks = true;

  selectedShiftsCalendarBackSubscription: Subscription;
  userSubscription: Subscription;
  userNameSubscription: Subscription;
  defaultDateSubscription: Subscription;
  calendarSubscription: Subscription;
  calendar2Subscription: Subscription;
  positionsSelect: any[] = [];
  actualDay: any;
  datesData: any[] = [];
  innerWidth: any;
  openHover = false;

  constructor(
    private datePipe: DatePipe,
    private activityLogs: ActivitylogsService,
    private helper: HelperService,
    private router: Router,
    private employeesService: EmployeesService,
  ) {
    this.onResize(event);
  }

  date: any = new Date(Date());
  userName = '';
  activities: any[] = [];
  absences: any[] = [];

  ngOnInit(): void {
    this.helper.removeAssignment.subscribe(
      (data: any) => {
        if (data) {
          this.selectedShift = null;
        }
      });

    this.fullMonth = this.datePipe.transform(this.date, 'MMMM');
    this.helper.refreshUserCalendar.subscribe((data: any) => {
      if (data) {
        if (!this.datesloaded) {
          return;
        }
        this.getCalendarFields(this.date);
      }
    });
    this.helper.userPosition.subscribe((data: any) => {
      if (data) {
        this.positionId = data;
      }
    });
    this.employeesService.userActvity().subscribe((x) => {
      x.forEach((group: any) => {
        if (
          group.groupId === Number(this.groupId) &&
          group.groupStatus === 'ACTIVE'
        ) {
          this.activities.push(group);
        }
      });
    });
    this.employeesService.userPositions().subscribe((positions) => {
      positions.forEach((group: any) => {
        if (
          group.groupId === Number(this.groupId) &&
          group.groupStatus === 'ACTIVE' &&
          group.rowStatus === 'ACTIVE'
        ) {
          this.positionsSelect.push(group);
        }
      });
    });
    this.helper.removeUserOpenShift.subscribe((data: any) => {
      if (data) {
        this.selectedShift = null;
      }
    });
    this.selectedShiftsCalendarBackSubscription = this.helper.selectedShiftsCalendarBack.subscribe(
      (data: any[]) => {
        if (data) {
          this.selectedShifts = data;
          if (this.innerWidth < 1025) { // if it's ipad reload location
            window.location.reload();
          }
        }
      }
    );
    this.groupId = Number(localStorage.getItem('gruopId'));
    this.userSubscription = this.helper.userId$.subscribe((data: any) => {
      if (this.userId != data) {
        this.userId = data;
        this.getCalendarFields(this.date);
      }
    });

    this.userNameSubscription = this.helper.userName.subscribe((data: any) => {
      if (data) {
        this.userName = data;
      }
    });
    this.date = new Date(this.date.setMonth(this.date.getMonth(), 15));
    this.getCalendarFields(this.date);

    this.defaultDateSubscription = this.helper.defaultDate$.subscribe(
      (back: any) => {
        if (back === 'back') {
          this.selectedDate = [];
          this.chosenDate = undefined;
          this.show = false;
        } else if (back === 'clear-calendar') {
          this.selectedDate = [];
          this.chosenDate = undefined;
          this.show = true;
        }
      }
    );

    this.calendarSubscription = this.helper.calendar$.subscribe((show: any) => {
      this.show = show;
    });

    this.calendar2Subscription = this.helper.calendar2$.subscribe(
      (show: any) => {
        this.show2 = show;
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  ngAfterViewInit(): void {
    //this.getCalendarFields(this.date);
  }

  ngAfterContentInit(): void {
    //this.getCalendarFields(this.date);
  }

  isToday(date: any): boolean {
    const dateForCheck = new Date(date);
    const today = new Date();
    return dateForCheck.getDate() == today.getDate() &&
      dateForCheck.getMonth() == today.getMonth() &&
      dateForCheck.getFullYear() == today.getFullYear();
  }

  getPositionColor(color: any): any {
    let colorOfShift = '';
    const indexColor = this.getClickedShifts.findIndex(
      (findColor: any) => findColor.id === color.id
    );
    if (indexColor !== -1) {
      colorOfShift = color.positionColor;
    }
    if (colorOfShift?.startsWith('##')) {
      return colorOfShift.substring(1);
    }
    if (colorOfShift?.startsWith('#')) {
      return colorOfShift;
    } else {
      return '#' + colorOfShift;
    }
  }

  getPersonColor(shifts: any): string {
    let color = '';
    this.positionsSelect.forEach((person) => {
      if (person.id === shifts.position) {
        if (person.id) {
          color = '#' + person.color;
        }
      }
    });
    return color;
  }

  getShift(data: any): void {
    const shiftObj = {
      id: data.id,
      name: '',
      groupId: data.groupId,
      start: this.datePipe.transform(data.start.replace(/-/g, '/'), 'HH:mm').toString(),
      end: this.datePipe.transform(data.end.replace(/-/g, '/'), 'HH:mm').toString(),
      positionId: data.position,
      activityId: data.activity1Id,
      competenceId: data.competence1Id,
      undisclosedNote: data.undisclosedNote,
      disclosedNote: data.disclosedNote,
      status: data.status,
      activity2Id: data.activity2Id,
      start2: data.activity2Start
        ? this.datePipe.transform(data.activity2Start.replace(/-/g, '/'), 'HH:mm').toString()
        : '',
      end2: data.activity2End
        ? this.datePipe.transform(data.activity2End.replace(/-/g, '/'), 'HH:mm').toString()
        : '',
      color: data.positionColor,
      clinical: data.clinical,
      applicationsCount: data.applications,
      type: 2,
      date: this.datePipe.transform(data.start.replace(/-/g, '/'), 'yyyy-MM-dd').toString(),
    };
    localStorage.setItem('selectedShift', JSON.stringify(shiftObj));
    this.router.navigate(['assignment/details']);
  }

  getActivityShortName(activityName: any): string {
    let shortName = '';
    this.activities.forEach((activity) => {
      if (activity.id === activityName.activity1Id) {
        if (activity.id) {
          if (activity.shortTitle !== null) {
            shortName = activity.title + ' (' + activity.shortTitle + ')';
          } else {
            shortName = activity.title;
          }
        }
      }
    });
    return shortName;
  }

  getDropdownTIme(shifts: any): string {
    let startTime = '';
    let endTime = '';
    this.positionsSelect.forEach((person) => {
      if (person.id === shifts.position) {
        if (person.id) {
          startTime = this.datePipe.transform(shifts.start.replace(/-/g, '/'), 'HH:mm');
          endTime = this.datePipe.transform(shifts.end.replace(/-/g, '/'), 'HH:mm');
        }
      }
    });
    return startTime + ' - ' + endTime;
  }

  private hideCalendar(): void {
    this.chosenDate = undefined;
  }

  // tslint:disable-next-line:typedef
  public previous() {
    // tslint:disable-next-line:no-unused-expression

    if (!this.datesloaded) {
      return;
    }

    this.date = new Date(this.date.setMonth(this.date.getMonth() - 1));
    this.fullMonth = this.datePipe.transform(this.date, 'MMMM');
    this.getCalendarFields(this.date);
  }

  // tslint:disable-next-line:typedef
  public next() {
    // tslint:disable-next-line:no-unused-expression

    if (!this.datesloaded) {
      return;
    }

    this.date = new Date(this.date.setMonth(this.date.getMonth() + 1));
    this.fullMonth = this.datePipe.transform(this.date, 'MMMM');
    this.getCalendarFields(this.date);
  }


  // tslint:disable-next-line:typedef
  public async getCalendarFields(date: any) {
    if (this.userId) {
      this.data = date;
      this.datesloaded = false;
      this.weekArray = [];
      this.daysArray = [];
      this.dateTime = [];
      this.datesData = [];
      this.maxDay = 0;
      this.minDay = 0;
      const localDatesData: any[] = [];
      const localEmptyData: any[] = [];

      let firstDay: any = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay: any = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      const firstDayForAbsences: any = new Date(date.getFullYear(), date.getMonth(), 1).setDate(firstDay.getDate() - 7);
      const lastDayForAbsences: any = new Date(date.getFullYear(), date.getMonth() + 1, 0).setDate(lastDay.getDate() + 7);
      this.correctMonth = firstDay.getMonth();
      const firstWeek = this.datePipe.transform(firstDay, 'w');
      const lastWeek = this.datePipe.transform(
        new Date(lastDay.setDate(lastDay.getDate() - 1)),
        'w'
      );

      if (this.userId != null) {
        this.employeesService.getEmployeeAbsences(this.groupId, firstDayForAbsences, lastDayForAbsences, this.userId).subscribe((data: any) => {
          this.absences = data;
          this.absences = this.absences.sort((a: any, b: any) => {
            return new Date(a.date.replace(/-/g, '/')).getTime() - new Date(b.date.replace(/-/g, '/')).getTime();
          });
        });
      }

      this.minDay = firstDay.getMonth();
      this.maxDay = lastDay.getMonth();
      let numberBack = 0;
      let numberOf = 0;

      for (let i = 1; i < 10; i++) {
        let dates = firstDay.setDate(firstDay.getDate() - i);
        // tslint:disable-next-line:max-line-length
        if (
          this.datePipe.transform(new Date(dates), 'w') ===
          this.datePipe.transform(
            new Date(date.getFullYear(), date.getMonth(), 1),
            'w'
          )
        ) {
          numberBack = i;
          dates = firstDay.setDate(firstDay.getDate() + i);
        }
      }

      for (let i = 1; i < 10; i++) {
        let dates = lastDay.setDate(lastDay.getDate() + i);
        // tslint:disable-next-line:max-line-length
        if (
          this.datePipe.transform(new Date(dates), 'w') ===
          this.datePipe.transform(
            new Date(date.getFullYear(), date.getMonth() + 1, 0),
            'w'
          )
        ) {
          numberOf = i;
          dates = lastDay.setDate(lastDay.getDate() - i);
        }
      }
      if (Number(firstWeek) < Number(lastWeek)) {
        this.weekArray = [];
        for (let i = Number(firstWeek); i <= Number(lastWeek); i++) {
          this.weekArray.push(i);
        }
      }
      if (Number(firstWeek) > Number(lastWeek)) {
        this.weekArray = [];
        this.weekArray.push(firstWeek);
        for (let i = 1; i <= Number(lastWeek); i++) {
          this.weekArray.push(i);
        }
      }
      firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const originalFirstDay = firstDay;
      let monthStartsOnSunday = false;
      lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      if (firstDay.getDate() == 1 && firstDay.getDay() == 0) { // if month starts on sunday
        numberBack = 7;
        monthStartsOnSunday = true;
      }
      for (let i = numberBack - 1; i >= 1; i--) {
        const date = new Date(firstDay.setDate(firstDay.getDate() - i));
        this.dateTime.push(date);
        // @ts-ignore
        const obj: any = {
          date,
          color: undefined,
          border: undefined,
          isCanceled: undefined,
          hasCanceled: undefined,
          hasDoubleShifts: undefined,
          findAbsence: undefined,
          findUnavailableAbsence: undefined
        };
        localDatesData.push(obj);
        localEmptyData.push(obj);
        firstDay = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
      }
      firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      const allDays = this.getDates(firstDay, lastDay);

      for (const i of allDays) {
        // tslint:disable-next-line:no-shadowed-variable
        const date = new Date(i);
        this.dateTime.push(date);
        // @ts-ignore
        const obj: any = {
          date,
          color: undefined,
          border: undefined,
          isCanceled: undefined,
          hasCanceled: undefined,
          hasDoubleShifts: undefined,
          findAbsence: undefined,
          findUnavailableAbsence: undefined
        };
        localDatesData.push(obj);
        localEmptyData.push(obj);
      }

      for (let i = 1; i <= numberOf + 1; i++) {
        const date = new Date(lastDay.setDate(lastDay.getDate() + i));
        if (monthStartsOnSunday) { // if month starts on sunday
          if (i <= numberOf) {
            this.dateTime.push(date);
            // @ts-ignore
            const obj: any = {
              date,
              color: undefined,
              border: undefined,
              isCanceled: undefined,
              hasCanceled: undefined,
              hasDoubleShifts: undefined,
              findAbsence: undefined,
              findUnavailableAbsence: undefined
            };
            localDatesData.push(obj);
            localEmptyData.push(obj);

          }
        } else {
          if (this.dateTime.length / this.weekArray.length < 7) {
            this.dateTime.push(date);
            // @ts-ignore
            const obj: any = {
              date,
              color: undefined,
              border: undefined,
              isCanceled: undefined,
              hasCanceled: undefined,
              hasDoubleShifts: undefined,
              findAbsence: undefined,
              findUnavailableAbsence: undefined
            };
            localDatesData.push(obj);
            localEmptyData.push(obj);
          }
        }
        lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
      }
      this.datesData = localEmptyData;

      firstDay = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        -numberBack + 2
      )
        .toJSON()
        .substring(0, 10);
      lastDay = new Date(
        this.date.getFullYear(),
        this.date.getMonth() + 1,
        numberOf + 1
      )
        .toJSON()
        .substring(0, 10);

      const data = {start: firstDay, end: lastDay, userId: this.userId, groupId: this.groupId};

      this.employeesService
        .userMonthAssignments(data)
        .subscribe((response: any) => {
          this.shifts = null;
          const groupIndex = response.groups.findIndex(
            (g: any) => g.group.id === this.groupId
          );
          if (groupIndex !== -1) {
            this.shifts = response.groups[groupIndex].dayAssignments;
            const temporalDatesData: any[] = [];
            // @ts-ignore
            localDatesData.forEach((datem: any) => {
              // @ts-ignore
              const obj: any = {
                date,
                color: undefined,
                border: undefined,
                isCanceled: undefined,
                hasCanceled: undefined,
                hasDoubleShifts: undefined,
                findAbsence: undefined,
                findUnavailableAbsence: undefined
              };
              obj.color = this.geColor(datem.date);
              obj.border = this.getBorder(datem.date);
              obj.date = datem.date;
              obj.isCanceled = this.isCanceled(datem.date);
              obj.hasCanceled = this.hasCanceled(datem.date);
              obj.hasDoubleShifts = this.hasDoubleShifts(datem.date);
              obj.findAbsence = this.findAbsence(datem.date);
              obj.findUnavailableAbsence = this.findUnavailableAbsence(datem.date);
              temporalDatesData.push(obj);

            });
            this.datesData = temporalDatesData;
            this.datesloaded = true;
          }

        });
    }
  }

  findOpenShifts(date: string): any[] {
    date = this.datePipe.transform(date, 'yyyy-MM-dd');
    let shifts = [];
    const foundDateIndex = this.shifts?.findIndex((s: any) => s.day === date);
    if (foundDateIndex && foundDateIndex !== -1) {
      shifts = this.shifts[foundDateIndex].assignments.filter((a: any) => {
        if (this.positionId) {
          return a.status === 'PUBLISHED' && a.position == this.positionId;
        } else {
          return a.status === 'PUBLISHED';
        }
      });
    }
    return shifts;
  }

  findAbsence(date: string): any {
    const index = this.absences.findIndex((a: any) => {
      return this.correctFormatForAbsence(date, a.date);
    });

    if (index != -1) {
      return this.absences[index];
    }
  }

  correctFormatForAbsence(time1: string, time2: string): any {
    if (this.innerWidth < 1025) {
      return this.datePipe.transform(new Date(this.formatTime(time1)), 'yyyy-MM-dd') == this.datePipe.transform(new Date(this.formatTime(time2)), 'yyyy-MM-dd');
    } else {
      return this.datePipe.transform(new Date(this.formatTime(time1)), 'yyyy-MM-dd') == this.datePipe.transform(new Date(this.formatTime(time2)), 'yyyy-MM-dd');
    }
  }

  formatTime(time: any): any {
    if (time.length >= 11) {
      return time.substr(0, 10);
    } else {
      return time;
    }
  }

  findUnavailableAbsence(date: string): any {
    const index = this.absences.findIndex((a: any) => {
      return this.datePipe.transform(new Date(date), 'yyyy-MM-dd') == this.datePipe.transform(new Date(a.date.replace(/-/g, '/')), 'yyyy-MM-dd');
    });

    if (index != -1) {
      if (this.absences[index].cancelReason.available == true) {
        return this.absences[index];
      }
    }
  }

  // tslint:disable-next-line:typedef
  public getDates(startDate: any, stopDate: any) {
    const dateArray = [];
    const currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  }

  hasCanceled(date: any): any {
    let found = null;
    for (const shift2 of this.shifts) {
      const year = moment(date).format('YYYY');
      const month = moment(date).format('MM');
      const dan = moment(date).format('DD');

      // tslint:disable-next-line:max-line-length
      if (
        shift2.day.substring(0, 4) === year &&
        shift2.day.substring(5, 7) === month &&
        shift2.day.substring(8, 10) === dan
      ) {
        // tslint:disable-next-line:radix
        // tslint:disable-next-line:radix max-line-length

        for (const i of shift2.assignmentTimings) {
          // tslint:disable-next-line:radix max-line-length
          shift2.assignments.forEach((a: any) => {
            if (
              a.assignedToId == this.userId &&
              a.status === 'CANCELED' &&
              parseInt(a.end.substring(11, 13)) * 60 +
              parseInt(a.end.substring(14, 16)) <=
              parseInt(i.end.substring(0, 2)) * 60 +
              parseInt(i.end.substring(3, 5))
            ) {
              found = a;
            }
          });
        }
      }
    }
    return found;
  }

  isCanceled(date: any): any {
    let foundShift: any = null;

    const found = false;
    const color = '';
    if (this.shifts) {
      for (const shift2 of this.shifts) {
        const year = moment(date).format('YYYY');
        const month = moment(date).format('MM');
        const dan = moment(date).format('DD');

        // tslint:disable-next-line:max-line-length
        if (
          shift2.day.substring(0, 4) === year &&
          shift2.day.substring(5, 7) === month &&
          shift2.day.substring(8, 10) === dan
        ) {

          // tslint:disable-next-line:radix
          // tslint:disable-next-line:radix max-line-length

          for (const i of shift2.assignmentTimings) {
            // tslint:disable-next-line:radix max-line-length
            shift2.assignments.forEach((a: any) => {

              if (new Date(a.start).getDate() == new Date(date).getDate()) {

                if (
                  a.assignedToId == this.userId &&
                  (a.status === 'CANCELED') &&
                  this.selectedShift?.id != a.id
                ) {
                  foundShift = a;
                }
              }
            });
          }
        }
      }
    }
    return foundShift;
  }

  calculateMinutes(time: string): number {
    const hours = Number(time.split(':')[0]);
    const minutes = Number(time.split(':')[1]);

    return hours * 60 + minutes;
  }

  // tslint:disable-next-line:typedef
  // @ts-ignore
  // tslint:disable-next-line:typedef
  public geColor(date: any) {
    let found = false;
    let color = '';
    if (this.shifts) {
      for (const shift2 of this.shifts) {
        const year = moment(date).format('YYYY');
        const month = moment(date).format('MM');
        const dan = moment(date).format('DD');

        // tslint:disable-next-line:max-line-length
        if (
          shift2.day.substring(0, 4) === year &&
          shift2.day.substring(5, 7) === month &&
          shift2.day.substring(8, 10) === dan
        ) {
          for (const i of shift2.assignmentTimings.sort((a: any, b: any) => {
            // tslint:disable-next-line:max-line-length
            return new Date(this.datePipe.transform(new Date(date), 'yyyy-MM-dd') + ' ' + b.start.replace(/-/g, '/')).getTime() - new Date(this.datePipe.transform(new Date(date), 'yyyy-MM-dd') + ' ' + a.start.replace(/-/g, '/')).getTime();
          })) {
            // tslint:disable-next-line:radix max-line-length
            shift2.assignments.sort((a: any, b: any) => {
              return new Date(a.start.replace(/-/g, '/')).getTime() - new Date(b.start.replace(/-/g, '/')).getTime();
            }).forEach((a: any) => {

              if (new Date().getDate() == new Date(date).getDate()) {
              }
              if (
                a.assignedToId == this.userId &&
                (a.status === 'ASSIGNED')
                &&
                (this.selectedShift == null || this.datePipe.transform(new Date(this.selectedShift.start), 'yyyy-MM-dd') != this.datePipe.transform(new Date(date), 'yyyy-MM-dd'))
              ) {
                found = true;
                if (a.clinical == false) {
                  color = 'f2f2f2';
                }

                if (
                  this.calculateMinutes(i.end) > this.calculateMinutes(i.start)
                ) {
                  if (
                    this.calculateMinutes(i.start) <=
                    this.calculateMinutes(a.start.substring(11, 16)) &&
                    this.calculateMinutes(i.end) >=
                    this.calculateMinutes(a.start.substring(11, 16))
                  ) {
                    color = i.color;

                  }
                } else {
                  if (
                    this.calculateMinutes(i.start) <=
                    this.calculateMinutes(a.start.substring(11, 16))
                  ) {
                    color = i.color;
                  }
                }


                if (a.clinical == false) {
                  color = 'f2f2f2';
                }
                // color = i.color;
              }
            });
          }
        }
      }
    } else {
      return 'white';
    }


    if (found) {
      return '#' + color;
    }
  }

  hasDoubleShifts(date: any): any {
    let count = 0;
    for (const shift2 of this.shifts) {
      const year = moment(date).format('YYYY');
      const month = moment(date).format('MM');
      const dan = moment(date).format('DD');

      // tslint:disable-next-line:max-line-length
      if (
        shift2.day.substring(0, 4) === year &&
        shift2.day.substring(5, 7) === month &&
        shift2.day.substring(8, 10) === dan
      ) {
        shift2.assignments.forEach((a: any) => {
          if (a.assignedToId == this.userId &&
            (a.status === 'ASSIGNED')) {
            count = count + 1;
          }
        });
      }
    }


    return count > 1;
  }

  getBorder(date: any): boolean {
    if (
      this.selectedShift &&
      new Date(date).getDate() == new Date(this.selectedShift.date).getDate() && new Date(date).getMonth() == new Date(this.selectedShift.date).getMonth()
    ) {
      return true;
    } else {
      return false;
    }
  }

  openShift(date: any): void {

    for (const shift2 of this.shifts) {
      const year = moment(date).format('YYYY');
      const month = moment(date).format('MM');
      const dan = moment(date).format('DD');

      // tslint:disable-next-line:max-line-length
      if (
        shift2.day.substring(0, 4) === year &&
        shift2.day.substring(5, 7) === month &&
        shift2.day.substring(8, 10) === dan
      ) {
        const allShifts: any[] = [];
        shift2.assignments.forEach((a: any) => {
          if (a.assignedToId == this.userId && (a.status === 'ASSIGNED' || a.status === 'CANCELED')) {
            a.date = new Date(a.start.replace(/-/g, '/'));
            a.positionId = a.position;
            a.activityId = a.activity1Id;
            a.competenceId = a.competence1Id;
            a.type = 2;
            try {
              a.startFake = this.datePipe.transform(a.start.replace(/-/g, '/'), 'HH:mm').toString();
              a.endFake = this.datePipe.transform(a.end.replace(/-/g, '/'), 'HH:mm').toString();
            } catch {
            }
            // localStorage.setItem('selectedShift', JSON.stringify(a));
            a.undisclosedNote = a.description;
            a.disclosedNote = a.employerNote;
            a.employerNote = a.description;

            this.selectedShift = a;
            allShifts.push(a);
          }
        });
        if (allShifts.length > 0) {
          allShifts.sort((a: any, b: any) => {
            // return new Date(a.start).getTime() - new Date(b.start).getTime();
            // tslint:disable-next-line:max-line-length
            return b.status.localeCompare(a.status) ? new Date(a.start.replace(/-/g, '/')).getTime() > new Date(b.start.replace(/-/g, '/')).getTime() ? -1 : -2 : 2;
            // if (a.status > b.status ) {
            //   if (new Date(a.start).getTime() >= new Date(b.start).getTime()) {
            //     return -3;
            //   } else {
            //     return -2;
            //   }
            // }
            // if (a.status < b.status) {
            //   if (new Date(a.start).getTime() <= new Date(b.start).getTime()) {
            //     return 3;
            //   } else {
            //     return 2;
            //   }
            // }
            // return 0;
          });
          this.helper.userOpenShift.next(allShifts[0]);
          localStorage.setItem('selectedShift', JSON.stringify(allShifts[0]));

        }
        setTimeout(() => {
          this.helper.hasMoreShifts.next(allShifts);
        }, 1000);

      }
    }
    this.datesData.forEach((dateObj: any) => {
      dateObj.color = this.geColor(dateObj.date);
    });
  }

  selectShift(date: any): void {
    for (const shift2 of this.shifts) {
      const year = moment(date).format('YYYY');
      const month = moment(date).format('MM');
      const dan = moment(date).format('DD');

      // tslint:disable-next-line:max-line-length
      if (
        shift2.day.substring(0, 4) === year &&
        shift2.day.substring(5, 7) === month &&
        shift2.day.substring(8, 10) === dan
      ) {
        shift2.assignments.forEach((a: any) => {
          if (a.assignedToId == this.userId && (a.status === 'ASSIGNED' || a.status === 'CANCELED')) {
            const index = this.selectedShifts.findIndex((s: any) => {
              return a.id == s.id;
            });
            if (index === -1) {
              this.selectedShifts.push(a);
            } else {
              this.selectedShifts.splice(index, 1);
            }
            return;
          }
        });
      }
    }
    this.helper.selectedShiftsCalendar.next(this.selectedShifts);
  }

  isSelectedShift(date: any): any {
    const year = moment(date).format('YYYY');
    const month = moment(date).format('MM');
    const dan = moment(date).format('DD');
    const index = this.selectedShifts.findIndex((s: any) => {
      return (
        s.start.substring(0, 4) === year &&
        s.start.substring(5, 7) === month &&
        s.start.substring(8, 10) === dan
      );
    });

    if (index === -1) {
      return false;
    } else {
      return true;
    }
  }

  removeShift(): void {
    this.selectedShift = null;
    this.actualDay = null;
    this.helper.clearShift.next('yes');
    this.getClickedShifts = [];
    this.loadOpenClicks = false;
    setTimeout(() => {
      this.loadOpenClicks = true;
    }, 500);
  }

  chooseDay(datem: any): void {
    this.openHover = true;
    if (!(this.selectedShift && new Date(datem).getDate() == new Date(this.selectedShift.date).getDate())) {
      this.selectedShift = null;
      this.helper.clearShift.next('yes');
    } else {
    }

    if (this.actualDay == datem) {
      this.actualDay = null;
      this.getClickedShifts = [];
    } else {
      this.actualDay = datem;
      if (this.loadOpenClicks) {
        this.getClickedShifts = this.findOpenShifts(datem);
      }
    }
    // if (this.selectedShift && new Date(datem).getDate() == new Date(this.selectedShift.date).getDate()) {
    // }

    if (this.show) {
      // this.chosenDate = datem;
      const calendar = {
        date: this.chosenDate,
        dayNumber: 1,
        active: true,
      };
      const findDateIndex = this.selectedDate.findIndex(
        (date: { active: boolean; date: string }) =>
          date.date === this.chosenDate && date.active === true
      );
      if (findDateIndex === -1) {
        this.selectedDate.push(calendar);
      } else {
        if (this.selectedDate[findDateIndex].active === false) {
          this.selectedDate[findDateIndex].active = true;
          this.selectedDate[findDateIndex].dayNumber = 1;
        }
      }
    }

    setTimeout(() => {
      let shiftAmount = 0;
      this.selectedDate.forEach((s: any) => {
        for (let i = 0; i < s.dayNumber; i++) {
          // @ts-ignore
          shiftAmount = shiftAmount + 1;
        }
      });
      this.helper.selectedShifts.next(shiftAmount);
    }, 60);
    this.helper.getSelectedShifts(this.selectedDate);
  }

  previousNumber(dateObj: any): void {
    setTimeout(() => {
      if (dateObj.dayNumber > 0) {
        dateObj.dayNumber = dateObj.dayNumber - 1;
        if (dateObj.dayNumber === 0) {
          const deleteDateIndex = this.selectedDate.findIndex(
            (date: { active: boolean; date: string }) =>
              date.date === dateObj.date && date.active === true
          );

          if (deleteDateIndex !== -1) {
            this.selectedDate[deleteDateIndex].active = false;
            this.chosenDate = undefined;
          }
        }
      }
    }, 50);
  }

  isSelected(date: string): boolean {
    const index = this.selectedDate.findIndex(
      (x: any) =>
        new Date(x.date).toDateString() === new Date(date).toDateString() &&
        x.active !== false
    );

    if (index === -1) {
      return false;
    } else {
      return true;
    }
  }

  filterSelectedDates(): any[] {
    return this.selectedDate.filter((x: any) => x.active !== false);
  }

  nextNumber(dateObj: any): void {
    if (dateObj.dayNumber < 10) {
      dateObj.dayNumber = dateObj.dayNumber + 1;
    }
  }

  getColor(color: string): string {
    if (color.startsWith('#')) {
      return color;
    } else {
      return '#' + color;
    }
  }

  ngOnDestroy(): void {
    this.selectedShiftsCalendarBackSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
    this.userNameSubscription?.unsubscribe();
    this.defaultDateSubscription?.unsubscribe();
    this.calendarSubscription?.unsubscribe();
    this.calendar2Subscription?.unsubscribe();
  }
}
