<div class="colorForBackground">
  <div class="padding-for-dialog">
    <img _ngcontent-gfy-c97="" src="../../../../assets/images/Close.png" (click)="cancel()" class="create-user__image locationX">

    <p class="send_message">{{"send_message" | translate}}</p>
    <p class="add_text">
      {{"add_text" | translate}} <br>
      <span class="text_after">  {{"silver_text" | translate}}</span>
    </p>
    <textarea name="" id="" cols="30" rows="10" [(ngModel)]="message" class="text_area_message"></textarea>

    <div class="message_buttons">
      <input  class="message_cancel_personal" type="submit"  (click)="cancel()" value=" {{'cancelButton' | translate}}">
      <button class="send_messages" (click)="send()"> {{"send" | translate}}</button>
    </div>
  </div>
</div>
