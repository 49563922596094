<div class="colorForBackground">
  <div class="padding-for-dialog">
    <img _ngcontent-gfy-c97="" src="../../../../assets/images/Close.png" (click)="cancel()" class="create-user__image locationX">

    <p class="send_group">{{'delete group' | translate}}</p>
    <p class="add_text">
      {{"attention" | translate}}!<br>
      <span class="text_after">{{'deleteGroupText' | translate}}</span>
    </p>

    <div class="groups_buttons_delete">

      <input class="group_cancel_delete"  type="button" (click)="cancel()" value="{{'cancel' | translate}}">
      <button class="delete_group" (click)="deleteFunction()" >{{"delete" | translate}}</button>
    </div>
  </div>
</div>
