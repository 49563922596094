<article>
  <ng-template [ngIf]="mode === 0">
    <article class="create-user">
      <img class="create-user__image" src="../../../../assets/images/Close.png" (click)="closeModal()"/>
      <div class="create-user__title">{{'addNewUser' | translate}}</div>
      <div class="create-user__email-label-container">
        <div class="create-user__email-label">{{'enterEmail' | translate}}</div>
        <div class="create-user__email-sublabel">{{'typeEmailToAddUser' | translate}}</div>
      </div>
      <div class="create-user__input-box">
        <input class="input create-user__input" #emailText [(ngModel)]='textEmail'
               (keyup.enter)="checkEmail(emailText.value)"/>
        <button class="button button--blue create-user__button"
                (click)="checkEmail(emailText.value)">{{'add' | translate}}</button>
      </div>
      <div class="create-user__without-email-box">
        <div
          class="create-user__email-label create-user__email-label--margin">{{'createAccountWithoutEmail' | translate}}</div>
        <div class="create-user__without-email-sublabel">{{'noEmailButton' | translate}}</div>
      </div>
      <button class="button button--primary create-user__second-button"
              (click)="noEmail()">{{'noEmail' | translate}}</button>
    </article>
  </ng-template>

  <ng-template [ngIf]="mode === 1">
    <article class="create-user">
      <img class="create-user__image" src="../../../../assets/images/Close.png" (click)="closeModal()"/>
      <div class="create-user__title">{{'addExistingUser' | translate}}</div>
      <div class="create-user__email-label-box create-user__email-label-box--exist">
        <div class="create-user__exist-email-label">{{'accountExist' | translate}}</div>
        <div class="create-user__exist-email-sublabel">{{'accountExistText' | translate}}</div>
      </div>
      <div class="create-user__user-info-box">
        <div class="create-user__image-box-exist">
          <div class="table__image-td create-user__picture">
            <img class="user-image" [src]="user.profilePicture" *ngIf="user.profilePicture"/>
            <div class="create-user__no-image" *ngIf="!user.profilePicture"></div>
          </div>
        </div>
        <div class="create-user__user-box">
          <div class="create-user__user-name">{{firstName}} {{lastName}}</div>
          <div class="create-user__user-email">{{userEmail}}</div>
        </div>
      </div>
      <div class="create-user__button-box">
        <button
          class="button button--primary create-user__button create-user__button--cancel"
          (click)="goBack()">{{'cancelButton' | translate}}</button>
        <button class="button button--blue create-user__button"
                (click)="toCreateNewUserPage()">{{'confirm' | translate}}</button>
      </div>
    </article>
  </ng-template>

  <ng-template [ngIf]="mode === 2">
    <article class="create-user">
      <img class="create-user__image" src="../../../../assets/images/Close.png" (click)="closeModal()"/>
      <div class="create-user__title">{{'newUser' | translate}}</div>
      <div class="create-user__email-label-box create-user__email-label-box--exist">
        <div class="create-user__exist-email-label">{{'createNewUserAccount' | translate}}</div>
        <div
          class="create-user__exist-email-sublabel">{{textEmail}}&nbsp;{{'createNewUserAccountText' | translate}}</div>
      </div>
      <div class="create-user__button-box">
        <button
          class="button button--primary create-user__button create-user__button--cancel"
          (click)="goBack()">{{'cancelButton' | translate}}</button>
        <button class="button button--blue create-user__button"
                (click)="noExistingEmail()">{{'confirm' | translate}}</button>
      </div>
    </article>
  </ng-template>

  <ng-template [ngIf]="mode === 3">
    <article class="create-user">
      <img class="create-user__image" src="../../../../assets/images/Close.png" (click)="closeModal()"/>
      <div class="create-user__title">{{'userAlreadyConnected' | translate}}</div>
      <div class="create-user__email-label-box create-user__email-label-box--exist">
        <div class="create-user__exist-email-label">{{'accountAlreadyExist' | translate}}</div>
        <div class="create-user__exist-email-sublabel">{{'accountAlreadyExistText' | translate}}</div>
      </div>
      <div class="create-user__user-info-box">
        <div class="create-user__image-box-exist">
          <div class="table__image-td create-user__picture">
            <img class="user-image" [src]="user.profilePicture" *ngIf="user.profilePicture"/>
            <div class="create-user__no-image" *ngIf="!user.profilePicture"></div>
          </div>
        </div>
        <div class="create-user__user-box">
          <div class="create-user__user-name">{{firstName}} {{lastName}}</div>
          <div class="create-user__user-email">{{userEmail}}</div>
        </div>
      </div>
      <div class="create-user__button-box">
        <button class="button button--primary create-user__button"
                (click)="closeModal()">{{'closeButton' | translate}}</button>
      </div>
    </article>
  </ng-template>
</article>
