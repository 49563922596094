import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Employees} from '../../shared/models/employees.model';
import {AuthService} from '../../shared/services/auth.service';
import {EmployeesService} from '../../shared/services/employees.service';
import {Contract, Position} from '../../shared/models/attribute.model';
import {CreateGroupComponent} from '../../create-group/create-group.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SendMessagePersonalComponent} from '../../send-message-personal/send-message-personal.component';
import {AddToGroupComponent} from '../../add-to-group/add-to-group.component';
import {ChangeStatusComponent} from '../../change-status/change-status.component';
import {ShiftsService} from '../../shared/services/shifts.service';
import {HelperService} from '../../shared/services/helper.service';
import {Filter} from '../../shared/models/filter.model';
import {DeletePersonalComponent} from '../../delete-personal/delete-personal.component';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthConst} from '../../shared/services/const/authConst';
import {RemovedPersonalFromGroupComponent} from '../../removed-personal-from-group/removed-personal-from-group.component';
import {Router} from '@angular/router';
import {UserDetailsModalComponent} from '../modals/user-details-modal/user-details-modal.component';
import {ConfirmDialogComponent} from '../modals/confirm-dialog/confirm-dialog.component';
import {SendPasswordModelComponent} from '../../send-password-model/send-password-model.component';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss'],
})
export class PersonalComponent implements OnInit, OnDestroy {
  employees$: Observable<Employees[]> | undefined;
  employees: Array<Employees> = [];
  allChecks: Array<any> = [];
  selectAllUsers = false;
  loading = false;
  newUser = false;
  section = 0;
  private selectAll: number[] = [];
  positions: Position[] = [];
  groupId = 0;
  selectedPosition: Position;
  openPositions = false;
  openSorts = false;
  helpPersonalArray: any = [];
  selectedSort = {id: 1, name: 'name'};
  checkMultipleAll = false;
  hide: any = 0;
  valueOfGroup: any = null;
  arrayForAllFilter: any = [];
  checkSelect = 0;
  competenceId: any;
  reconition: any = true;
  arrayForCheck: any = [];
  selectAct = 'selectAction';
  contracts: any = [];
  @Input() assignment: any;
  sorts = [
    {id: 1, name: 'name'},
    {id: 2, name: 'position'},
    {id: 3, name: 'competence'},
    {id: 4, name: 'role'},
    {id: 5, name: 'contract'},
    {id: 6, name: 'status'},
  ];
  actions = [
    {id: 3, name: 'changeStatus'},
    {id: 2, name: 'addToGroup'},
    {id: 4, name: 'deleteUser'},
    // Hide this option for now
    // { id: 1, name: 'sendEmail' },
    {id: 6, name: 'sendPassword'},
  ];

  actionsGroupsPersonals = [
    // Hide this option for now
    // { id: 1, name: 'sendEmail' },
    {id: 5, name: 'removePersonalFromGroup'},
  ];
  openActions = false;
  oneMoreClick = false;
  total = 0;
  page = 0;
  shiftsForm: any;
  setCompetence: any;
  showableUsers: Employees[] = [];
  allEmployeesSave: Employees[] = [];
  sendMessageUsers: any = [];
  openType = false;
  arrayForSearch: any = [];
  multipleAllChecked: any = [];
  searchText = '';
  actualGroup: any = null;
  selectedPositionDropdown: any = [];
  selectedPositionDropdownTitle: string | undefined;
  allPositionsChecked = true;

  types = [
    {id: 0, name: 'sortByName'},
    {id: 1, name: 'sortByRole'},
    {id: 2, name: 'sortByStatus'},
  ];
  filter: Filter = {searchText: '', type: 0};
  title = 'personalPageName';
  groupIdNew = localStorage.getItem('gruopId');
  competences: any = [];
  clickedSectionSubscription: Subscription;
  groupChangedSubscription: Subscription;
  groupServiceSubscription: Subscription;

  constructor(
    private employeesService: EmployeesService,
    private translate: TranslateService,
    private authService: AuthService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private shiftsService: ShiftsService,
    private helper: HelperService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {
  }

  ngOnInit(): void {

    if (localStorage.getItem('employees')) {
      const employeesData = JSON.parse(localStorage.getItem('employees'));
      employeesData.forEach((data: any) => {
        if (data.groupId == localStorage.getItem('gruopId')) {
          this.employees = data.employees;
          this.calculateTotalAndShowableUsers();
        }
      });
    }

    setTimeout(() => {
      this.arrayForAllFilter.sort((a: { type: string; }, b: { type: any; }) => a.type.localeCompare(b.type));
    }, 1000);
    // this.selectPosition({id: -1}, null);
    this.employeesService.userContracts().subscribe((contracts: Contract[]) => {

      this.contracts = contracts.filter((contract) => {
        return (
          contract.groupId === this.groupId &&
          contract.groupStatus === 'ACTIVE' &&
          contract.rowStatus === 'ACTIVE'
        );
      });

      this.contracts.sort((a: any, b: any) => {
        return a.title.localeCompare(b.title);
      }).forEach((e: any) => {
        const value = {name: e.title, id: e.id, type: '2'};
        this.arrayForAllFilter.push(value);
      });

    });


    localStorage.removeItem(AuthConst.scheduleDate);
    this.employeesService.userCompetences().subscribe((x) => {
      this.competences = [];
      x.sort((a: any, b: any) => {
        return a.title.localeCompare(b.title);
      }).forEach((group: any) => {
        if (group.groupId == this.groupIdNew && group.groupStatus == 'ACTIVE') {
          const value = {name: group.title, id: group.id, type: '3'};
          this.arrayForAllFilter.push(value);
          this.competences.push(group);
        }
      });
    });
    this.clickedSectionSubscription = this.helper.clickedSection.subscribe(
      (page) => {
        if (page && page == '/personal') {
          this.allChecks = [];
          this.searchText = '';
          this.selectedPosition = null;
          this.selectedSort = {id: 1, name: 'name'};
          const isSingIn = this.authService.isSignedIn();
          if (isSingIn) {
            this.allEmployees();

          }
        }
      }
    );

    this.groupId = Number(localStorage.getItem('gruopId'));

    const isSingIn = this.authService.isSignedIn();
    if (isSingIn) {
      this.allEmployees();
      this.getPositions();
    }

    this.groupChangedSubscription = this.helper.groupChanged$.subscribe(
      (data) => {
        if (data) {
          this.groupId = data;
          if (isSingIn) {
            this.allEmployees();

          }
        }
      }
    );

    this.groupServiceSubscription = this.helper.group$.subscribe(
      (data: any) => {
        this.valueOfGroup = data;
        this.shiftsService
          .groupPeronals(data, this.groupIdNew)
          .subscribe((response: any[]) => {
            this.section = 0;
            this.employees = response.filter((r: any) => {
              return r.type == 'INTERNAL';
            });
            let index = 0;
            this.setEmployeesToLocaleStorage(this.employees);
            this.title = data.name;
            this.actualGroup = data;
            this.allEmployeesSave = this.employees;
            this.calculateTotalAndShowableUsers();

          });
        this.hide = 1;
        this.reconition = false;
      }
    );
  }

  getPositions(): void {
    this.employeesService.userPositions().subscribe((x) => {
      x.forEach((group: any) => {
        if (group.groupId === this.groupId && group.groupStatus === 'ACTIVE') {
          this.positions.push(group);
        }
      });

      this.positions.sort((a: any, b: any) => {
        return a.title.localeCompare(b.title);
      }).forEach((e: any) => {
        const value = {name: e.title, id: e.id, type: '1'};
        this.arrayForAllFilter.push(value);
      });

    });
  }

  sortActions(arr: any): any[] {
    return arr.sort((a: any, b: any) => {
      return a.name.localeCompare(b.name);
    });
  }

  // isCheckedMultiple(employee: any): boolean {
  //   const employeeValue = this.arrayForCheck.findIndex((x: any) => x.id == employee.id);
  //   if (employeeValue == -1) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }


  isCheckedMultiple(position: any): boolean {
    const employeeValue = this.selectedPositionDropdown.findIndex((x: any) => x == position.id);
    if (this.selectedPositionDropdown.length === this.arrayForAllFilter.length) {
      return (employeeValue !== -1) && !this.allPositionsChecked;
      ;
    } else {
      return (employeeValue !== -1) && !this.allPositionsChecked;
    }
  }

  selectPosition(position: any, event: any): void {
    event.preventDefault();

    const positionIndex = this.selectedPositionDropdown.findIndex((pos: { id: number }) => pos === position.id);

    if (positionIndex === -1) {
      this.allPositionsChecked = true;
      if (position.id !== -1) {
        this.selectedPositionDropdown.push(position.id);
        this.selectedPositionDropdownTitle = this.translate.instant('allPositions');
        if (this.selectedPositionDropdown.length === this.arrayForAllFilter.length) {
          this.selectedPositionDropdownTitle = this.translate.instant('severalOptions');
          this.allPositionsChecked = false;
        }

        const posIndex = this.arrayForAllFilter.findIndex((pos: { id: number }) => pos.id === position.id);
        if (posIndex !== -1) {
          if (this.selectedPositionDropdown.length === 0) {
            this.selectedPositionDropdownTitle = this.translate.instant('severalOptions');
            this.allPositionsChecked = true;

          } else if (this.selectedPositionDropdown.length === 1) {
            this.selectedPositionDropdownTitle = this.arrayForAllFilter[posIndex].name;
            this.allPositionsChecked = false;
          } else if (this.selectedPositionDropdown.length > 1 && this.selectedPositionDropdown.length < this.arrayForAllFilter.length) {
            this.selectedPositionDropdownTitle = this.translate.instant('severalOptions');
            this.allPositionsChecked = false;
          }
        }
      } else {
        this.allPositionsChecked = true;
        this.selectedPositionDropdownTitle = this.translate.instant('allPositions');
        this.arrayForSearch = [];
        this.arrayForCheck = [];
        this.checkMultipleAll = true;
      }
    } else {
      this.selectedPositionDropdown.splice(positionIndex, 1);
      this.allPositionsChecked = false;
      if (this.selectedPositionDropdown.length > 1 && this.selectedPositionDropdown.length < this.arrayForAllFilter.length) {
        this.selectedPositionDropdownTitle = this.translate.instant('severalOptions');
      } else if (this.selectedPositionDropdown.length === 1) {
        const nameIndex = this.arrayForAllFilter.findIndex((id: { id: any; }) => id.id == this.selectedPositionDropdown[0]);
        this.selectedPositionDropdownTitle = this.arrayForAllFilter[nameIndex].name;
      } else if (this.selectedPositionDropdown.length === 0 || this.selectedPositionDropdown.length === this.arrayForAllFilter.length) {
        this.selectedPositionDropdownTitle = this.translate.instant('allPositions');
        this.allPositionsChecked = true;
      }
    }

    if (this.arrayForAllFilter) {
      if (position.id == -1) {
        this.selectedPositionDropdown = [];

        if (!this.checkMultipleAll) {
          this.arrayForSearch = [];
          this.arrayForAllFilter.forEach((e: any) => {
            this.arrayForSearch.push(e);
          });
          this.arrayForCheck = [];
          this.arrayForCheck.push(position);
          this.checkMultipleAll = true;

        } else {
          this.arrayForSearch = [];
          this.arrayForCheck = [];
          this.checkMultipleAll = false;
        }


      } else {
        this.checkMultipleAll = false;

        const employeeValue = this.arrayForSearch.findIndex(
          (x: any) => x == position
        );

        const employeeValueCheck = this.arrayForCheck.findIndex(
          (x: any) => x == position
        );

        this.arrayForCheck.forEach((e: any, index: any) => {
          if (e.id == -1) {
            this.arrayForCheck.splice(index, 1);
          }
        });


        if (employeeValue == -1) {
          this.arrayForSearch.push(position);
        } else {
          this.arrayForSearch.forEach((valueFromArray: any, index: any) => {
            if (valueFromArray == position) {
              this.arrayForSearch.splice(index, 1);
            }
          });
        }

        if (employeeValueCheck == -1) {
          this.arrayForCheck.push(position);
        } else {
          this.arrayForCheck.forEach((valueFromArray: any, index: any) => {
            if (valueFromArray == position) {
              this.arrayForCheck.splice(index, 1);
            }
          });
        }

        if (this.arrayForCheck.length == 0) {
          if (this.reconition == true) {
            this.allEmployees();
            this.calculateTotalAndShowableUsers();
          } else {
            this.shiftsService
              .groupPeronals(this.valueOfGroup, this.groupIdNew)
              .subscribe((response: any[]) => {
                this.section = 0;
                this.employees = response.filter((r: any) => {
                  return r.type == 'INTERNAL';
                });
                let index = 0;
                this.setEmployeesToLocaleStorage(this.employees);
                this.title = this.valueOfGroup.name;
                this.actualGroup = this.valueOfGroup;
                this.allEmployeesSave = this.employees;
                this.calculateTotalAndShowableUsers();

              });
            this.hide = 1;
            this.reconition = false;
          }
        }
      }
    }


    this.arrayForCheck.forEach((c: any) => {
      this.isCheckedMultiple(c);
    });

    this.openPositionsFunction();
    this.calculateTotalAndShowableUsers();
  }


  openPositionsFunction(): any {
    if (this.checkMultipleAll == true) {
      if (this.reconition == true) {
        this.allEmployees();
        this.calculateTotalAndShowableUsers();
      } else {
        this.shiftsService
          .groupPeronals(this.valueOfGroup, this.groupIdNew)
          .subscribe((response: any[]) => {
            this.section = 0;
            this.employees = response.filter((r: any) => {
              return r.type == 'INTERNAL';
            });
            let index = 0;
            this.setEmployeesToLocaleStorage(this.employees);
            this.title = this.valueOfGroup.name;
            this.actualGroup = this.valueOfGroup;
            this.allEmployeesSave = this.employees;
            this.calculateTotalAndShowableUsers();

          });
        this.hide = 1;
        this.reconition = false;
      }

    } else {
      let withTypeOne: any[] = [];
      let withTypeTwo: any[] = [];
      let withTypeThree: any[] = [];
      this.employees = this.allEmployeesSave;
      this.employees = this.employees.filter((r: any) => {
        return r.type == 'INTERNAL';
      });
      this.arrayForSearch.forEach((s: any) => {
        if (s.type == '1') {
          withTypeOne.push(s);
        }
        if (s.type == '2') {
          withTypeTwo.push(s);
        }
        if (s.type == '3') {
          withTypeThree.push(s);
        }
      });

      if (withTypeThree.length != 0) {
        this.employees = this.employees.filter((e) => {
          return (
            withTypeThree.findIndex((t: any) => {
              return e.competences?.toUpperCase().includes(t.name?.toUpperCase());
            }) !== -1
          );
        });
      }
      if (withTypeOne.length != 0) {
        this.employees = this.employees.filter((e) => {
          return (
            withTypeOne.findIndex((t: any) => {
              return e.positions?.toUpperCase().includes(t.name?.toUpperCase());
            }) !== -1
          );
        });
      }
      if (withTypeTwo.length != 0) {
        this.employees = this.employees.filter((e) => {
          return (
            withTypeTwo.findIndex((t: any) => {
              return e.contracts?.toUpperCase().includes(t.name?.toUpperCase());
            }) !== -1
          );
        });
      }


      this.showableUsers = this.employees;
      this.showableUsers.sort((a, b) => a.name.localeCompare(b.name, 'sv'));
    }

  }

  selectSort(sort: any): void {
    this.selectedSort = sort;
    this.openSorts = false;
  }

  allEmployees(): void {
    this.loading = true;
    this.groupId = Number(localStorage.getItem('gruopId'));
    this.employees$ = this.employeesService.getEmployees(this.groupId);

    this.employees$.subscribe((user) => {

      this.employees = user.filter((r: any) => {
        return r.type == 'INTERNAL';
      });

      let index = 0;
      this.setEmployeesToLocaleStorage(this.employees);
      this.allEmployeesSave = user;
      this.calculateTotalAndShowableUsers();
      this.loading = false;
    });
  }

  calculateTotalAndShowableUsers(): void {
    this.showableUsers = this.employees.sort((a, b) => a.name.localeCompare(b.name, 'sv')).slice(this.page * 50, (this.page + 1) * 50);
    this.total = this.employees.filter((r: any) => {
      return r.type == 'INTERNAL';
    }).length;
  }

  // tslint:disable-next-line:typedef ban-types
  getAndTranslateRoles(role: String) {
    const roles = role.split(',');

    let translations = "";
    // tslint:disable-next-line:no-shadowed-variable
    roles.forEach((role, key: number) => {
      if (role != '' && role != ' ') {
        const translatedRole = this.translate.instant(role);
        if (key == 0) {
          translations += translatedRole;
        } else {
          translations += ", " + translatedRole;
        }

      }
    });
    return translations;
  }

  // tslint:disable-next-line:typedef
  eraseCommaa(data: string) {
    if (data === null) {
      return '';
    } else {
      const eraseComma = data.split(',');
      // tslint:disable-next-line:no-shadowed-variable
      const newData = eraseComma.filter((data) => data !== '');
      return newData;
    }
  }

  multiplePersonal(): void {
    if (this.section == 0) {
      this.section = 1;
    } else if (this.section == 1) {
      this.section = 0;
    }
  }

  /**
   *
   * Get single checkBox
   */
  selection(userId: number): void {
    this.allChecks = [...this.allChecks];

    const userIndex = this.allChecks.findIndex((x) => x == userId);

    if (userIndex == -1) {
      this.allChecks.push(userId);
    } else {
      this.allChecks.splice(userIndex, 1);
    }
  }

  isChecked(userId: number): boolean {
    const userIndex = this.allChecks.findIndex((x) => x == userId);
    if (userIndex == -1) {
      return false;
    } else {
      return true;
    }
  }

  /**
   *
   * Select all checkBox
   */
  setAll(): void {
    this.selectAllUsers = !this.selectAllUsers;
    if (this.selectAllUsers) {
      this.allChecks = [];
      this.employees.forEach((user) => {
        // @ts-ignore
        this.selectAll.push(user.id);
        this.allChecks.push(user.id);
      });
    } else {
      this.selectAll.splice(0);
      this.allChecks = [];
    }
  }

  onScroll(): void {
  }

  createUser(): void {
    this.newUser = true;
  }

  sendOneMessage(user: any): any {
    // // @ts-ignore
    const confirmDialog = this.dialog.open(SendMessagePersonalComponent, {
      disableClose: true,
      // width: '290px',
      // height: '240px',
      // position: {
      //   top: '150px',
      // },
      panelClass: 'custom-dialog-container-message',
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.sendMessageUsers = [];
        this.sendMessageUsers.push(user.email);
        let object = {
          emails: this.sendMessageUsers,
          body: result,
          subject: '',
        };

        this.shiftsService.perosnalSendMessage(object).subscribe(() => {
          this.snackBar.open(this.translate.instant('success'), 'OK', {
            duration: 3000,
            panelClass: ['custom-snack-bar'],
          });
        });
      }
    });
  }

  selectAction(action: any): void {
    if (this.oneMoreClick != true) {
      this.openActions = false;
      this.oneMoreClick = true;
      if (action.id == 1) {
        this.selectAct = action.name;

        // // @ts-ignore
        const confirmDialog = this.dialog.open(SendMessagePersonalComponent, {
          disableClose: true,
          position: {
            top: '100px',
          },
          // width: '290px',
          // height: '240px',
          // position: {
          //   top: '150px',
          // },
          panelClass: 'custom-dialog-container-message',
        });
        confirmDialog.afterClosed().subscribe((result) => {
          this.oneMoreClick = false;

          this.selectAct = 'selectAction';
          if (result) {
            this.sendMessageUsers = [];
            for (let index of this.allChecks) {
              for (let user of this.showableUsers) {
                if (index == user.id) {
                  this.sendMessageUsers.push(user.email);
                }
              }
            }
            let object = {
              emails: this.sendMessageUsers,
              body: result,
              subject: '',
            };

            this.shiftsService.perosnalSendMessage(object).subscribe(() => {
              this.snackBar.open(this.translate.instant('success'), 'OK', {
                duration: 3000,
                panelClass: ['custom-snack-bar'],
              });
            });
            this.allChecks = [];
            this.selectAct = 'selectAction';
          }
        });
      }

      if (action.id === 2) {
        this.selectAct = action.name;
        // // @ts-ignore
        const confirmDialog = this.dialog.open(AddToGroupComponent, {
          disableClose: true,
          position: {
            top: '100px',
          },
          // width: '290px',
          // height: '240px',
          // position: {
          //   top: '150px',
          // },
          panelClass: 'custom-dialog-container-addToGroup2',
        });
        confirmDialog.afterClosed().subscribe((result) => {
          this.oneMoreClick = false;
          if (result) {
            for (let index of this.allChecks) {
              let object = {groupId: result, userId: index};
              this.shiftsService.addToGroup(object).subscribe(() => {
                this.allChecks = [];
              });
            }
            this.snackBar.open(this.translate.instant('success'), 'OK', {
              duration: 3000,
              panelClass: ['custom-snack-bar'],
            });
            this.allChecks = [];
          }

          this.selectAct = 'selectAction';
        });
      }

      if (action.id == 3) {
        this.selectAct = action.name;
        // // @ts-ignore
        const confirmDialog = this.dialog.open(ChangeStatusComponent, {
          disableClose: true,
          position: {
            top: '100px',
          },
          // width: '290px',
          // height: '240px',
          // position: {
          //   top: '150px',
          // },
          panelClass: 'custom-dialog-container-addToGroup',
        });
        confirmDialog.afterClosed().subscribe((result) => {
          this.oneMoreClick = false;
          this.selectAct = 'selectAction';
          if (result) {
            this.loading = true;
            if (result.id == 0) {
              for (let index of this.allChecks) {
                let object = {
                  id: index,
                  groupId: localStorage.getItem(AuthConst.gruopId),
                };
                this.shiftsService.inActiveStatus(object).subscribe(() => {
                  this.allEmployees();
                  this.allChecks = [];
                });
              }
              this.loading = false;

              this.snackBar.open(this.translate.instant('success'), 'OK', {
                duration: 3000,
                panelClass: ['custom-snack-bar'],
              });
            } else if (result.id == 1) {
              this.loading = true;
              for (let index of this.allChecks) {
                let object = {
                  id: index,
                  groupId: localStorage.getItem(AuthConst.gruopId),
                };
                this.shiftsService.activeStatus(object).subscribe(() => {
                  this.allEmployees();
                  this.allChecks = [];
                  this.selectAct = 'selectAction';
                });
              }
              this.loading = false;

              this.snackBar.open(this.translate.instant('success'), 'OK', {
                duration: 3000,
                panelClass: ['custom-snack-bar'],
              });
            }
            this.allChecks = [];
          }

        });
      } else if (action.id == 4) {
        if (this.allChecks.length > 5) {
          this.snackBar.open(this.translate.instant('pleaseSelectMax5'));
          this.oneMoreClick = false;
          return;
        }
        this.selectAct = action.name;
        // @ts-ignore
        const confirmDialog = this.dialog.open(DeletePersonalComponent, {
          disableClose: true,
          position: {
            top: '100px',
          },
          // width: '290px',
          // height: '190px',
          // border: '0.5px solid #DDDDDD',
          // position: {
          //   top: '150px',
          // },
          panelClass: 'custom-dialog-container-delete',
        });
        confirmDialog.afterClosed().subscribe((result) => {
          this.oneMoreClick = false;
          this.selectAct = 'selectAction';
          if (result) {
            this.loading = true;
            for (let g of this.allChecks) {
              this.shiftsService
                .deletePersonel(g, localStorage.getItem(AuthConst.gruopId))
                .subscribe(() => {
                  this.allEmployees();
                  this.allChecks = [];
                  this.selectAct = 'selectAction';
                });
            }
            this.loading = false;
            this.snackBar.open(this.translate.instant('deleted'), 'OK', {
              duration: 3000,
              panelClass: ['custom-snack-bar'],
            });
            this.allChecks = [];
          }
        });
      } else if (action.id == 5) {
        this.selectAct = action.name;
        // @ts-ignore
        const confirmDialog = this.dialog.open(
          RemovedPersonalFromGroupComponent,
          {
            disableClose: true,
            position: {
              top: '100px',
            },
            width: '290px',
            height: '190px',
            // border: '0.5px solid #DDDDDD',
            // position: {
            //   top: '150px',
            // },
            panelClass: 'custom-dialog-container',
          }
        );
        confirmDialog.afterClosed().subscribe((result) => {
          this.oneMoreClick = false;

          if (result) {
            let checkArray = [];
            for (let index of this.allChecks) {
              let object = {groupId: this.actualGroup.id, userId: index};
              this.shiftsService.removeFromGroup(object).subscribe(() => {
                this.allChecks = [];
                this.selectAct = 'selectAction';
              });
            }
            this.showableUsers.forEach((e, index) => {
              this.allChecks.forEach((a) => {
                if (e.id == a) {
                  this.showableUsers.splice(index, 1);
                }
              });
            });
            this.snackBar.open(this.translate.instant('removed'), 'OK', {
              duration: 3000,
              panelClass: ['custom-snack-bar'],
            });
          }
        });
      } else if (action.id == 6) {
        this.selectAct = action.name;
        const confirmDialog = this.dialog.open(SendPasswordModelComponent, {
          disableClose: true,
          position: {
            top: '100px',
          },
          // width: '290px',
          height: '167px',
          // border: '0.5px solid #DDDDDD',
          // position: {
          //   top: '150px',
          // },
          panelClass: 'custom-dialog-container-delete',
        });
        confirmDialog.afterClosed().subscribe((result) => {
          this.oneMoreClick = false;
          if (result) {
            for (let userId of this.allChecks) {

              const foundUser = this.showableUsers.find((u: any) => u.id == userId);
              const object = {username: foundUser.email};
              if (foundUser) {
                this.employeesService.resetPassword(object).subscribe(() => {

                });
              }

            }
            this.snackBar.open(this.translate.instant('success'), 'OK', {
              duration: 3000,
              panelClass: ['custom-snack-bar'],
            });
            this.allChecks = [];
          }

          this.selectAct = 'selectAction';
        });
      }
    }
  }

  toOpenActions(): void {
    if (this.allChecks.length > 0) {
      this.openActions = true;
    }
  }

  searchEmployees(text: string): void {
    if (text.length > 0) {
      this.employees = this.employees.filter(
        (e) =>
          e.name.toLowerCase().includes(text.toLowerCase()) &&
          e.type == 'INTERNAL'
      );
    } else {
      this.employees = this.allEmployeesSave;
      this.employees = this.employees.filter((e) => e.type == 'INTERNAL');
    }

    this.calculateTotalAndShowableUsers();
  }

  public openPage(page: number): void {
    if (page < 0) {
      page = 0;
    }

    if (page != this.page && page + 1 <= this.getLastPage()) {
      this.page = page;
      this.allEmployees();
    }
  }

  public getLastPage(): number {
    return Math.ceil(this.total / 50);
  }

  createGroup(): void {
    // @ts-ignore
    const confirmDialog = this.dialog.open(CreateGroupComponent, {
      disableClose: true,
      position: {
        top: '100px'
      },
      // width: '290px',
      // height: '328px',

      panelClass: 'custom-dialog-container',
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.snackBar.open(this.translate.instant(result), 'OK', {
          duration: 3000,
          panelClass: ['custom-snack-bar'],
        });
      }
    });
  }

  findType(): string {
    const index = this.types.findIndex((t) => t.id == this.filter.type);

    if (index != -1) {
      return this.types[index].name;
    } else {
      return '';
    }
  }

  setType(type: { id: number; name: string }): void {
    this.filter.type = type.id;
    if (type.id == 0) {
      this.showableUsers.sort((a, b) => a.name.localeCompare(b.name, 'sv'));
      this.checkSelect = type.id;
    } else if (type.id == 1) {
      this.showableUsers.sort((a, b) => a.roles.localeCompare(b.roles));
      this.checkSelect = type.id;
    } else if (type.id == 2) {
      this.checkSelect = 2;
      const fn = (a: any, b: any) => {
        if (a.groupStatus != null) {
          return a ? a.groupStatus.localeCompare(b.groupStatus) : 1;
        }
      };      // tslint:disable-next-line:only-arrow-functions

      this.showableUsers.sort(fn);

    }
    this.openType = false;
  }

  shortTitle(competence: any): any {
    const arrayText = competence?.split(',');
    const nizForString: any = [];
    for (const fullTitle of this.competences) {
      if (arrayText) {
        arrayText.forEach((e: any) => {
          if (fullTitle.title == e) {
            nizForString.push(' ' + fullTitle.shortTitle);

          }
        });

        // if(fullTitle.title == arrayText[0]){
        //   return fullTitle.shortTitle;
        // }
      }
    }
    return nizForString.toString();
  }

  getStatus(employee: any): any {
    if (employee.groupStatus) {
      return this.translate.instant(employee.groupStatus.split(',')[0].trim().toLowerCase());
    }
  }

  backClicked(): any {
    this.section = 1;
    this.hide = 0;
    this.title = 'personalPageName';


    localStorage.removeItem(AuthConst.scheduleDate);
    this.employeesService.userCompetences().subscribe((x) => {
      this.competences = [];
      x.forEach((group: any) => {
        if (group.groupId == this.groupIdNew && group.groupStatus == 'ACTIVE') {
          this.competences.push(group);
        }
      });
    });
    this.clickedSectionSubscription = this.helper.clickedSection.subscribe(
      (page) => {
        if (page && page == '/personal') {
          this.allChecks = [];
          this.searchText = '';
          this.selectedPosition = null;
          this.selectedSort = {id: 1, name: 'name'};
          const isSingIn = this.authService.isSignedIn();
          if (isSingIn) {
            this.allEmployees();

          }
        }
      }
    );

    this.groupId = Number(localStorage.getItem('gruopId'));

    const isSingIn = this.authService.isSignedIn();
    if (isSingIn) {
      this.allEmployees();
      this.getPositions();
    }

    this.groupChangedSubscription = this.helper.groupChanged$.subscribe(
      (data) => {
        if (data) {
          this.groupId = data;
          if (isSingIn) {
            this.allEmployees();

          }
        }
      }
    );

    this.groupServiceSubscription = this.helper.group$.subscribe(
      (data: any) => {
        this.shiftsService
          .groupPeronals(data, this.groupIdNew)
          .subscribe((response: any[]) => {
            this.section = 0;
            this.showableUsers = response.filter((r: any) => {
              return r.type == 'INTERNAL';
            });
            this.showableUsers.sort((a, b) => a.name.localeCompare(b.name, 'sv'));
            let index = 0;
            this.title = data.name;
            this.actualGroup = data;

          });
        this.hide = 1;
      }
    );


  }

  openUser(employee: any): void {
    if (this.checkIfNotRoster()) {
      this.router.navigate(['personal/user/', employee.id]);
    } else {
      const confirmDialog = this.dialog.open(UserDetailsModalComponent, {
        disableClose: true,
        width: '560px',
        height: '650px',
        panelClass: 'userDialog',
        position: {
          top: '100px',
        },
        data: {
          userDetails: employee,
          hideIcons: true
        },
      });
      confirmDialog.afterClosed().subscribe(() => {
      });
    }

  }

  checkIfNotRoster(): boolean {
    if (localStorage.getItem('gapUserRole')) {
      if (localStorage.getItem('gapUserRole') == 'ROSTER') {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  toggleDropdown(): void {
    this.openPositions = !this.openPositions;
  }

  setEmployeesToLocaleStorage(employees: any): void {
    const groupId = Number(localStorage.getItem(AuthConst.gruopId));
    const obj = {
      groupId, employees
    };

    let existingEmployees: any = localStorage.getItem('employees');
    if (existingEmployees) {
      existingEmployees = JSON.parse(existingEmployees);
      const groupIndex = existingEmployees.findIndex((m: any) => {
        return m.groupId == groupId;
      });

      if (groupIndex == -1) {
        existingEmployees.push(obj);
      } else {
        existingEmployees[groupIndex] = obj;
      }

    } else {
      existingEmployees = [obj];
    }

    localStorage.setItem("employees", JSON.stringify(existingEmployees));
  }

  ngOnDestroy(): void {
    this.clickedSectionSubscription?.unsubscribe();
    this.groupChangedSubscription?.unsubscribe();
    this.groupServiceSubscription?.unsubscribe();
  }
}
