import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Competence, Contract} from '../../shared/models/attribute.model';
import {EmployeesService} from '../../shared/services/employees.service';
import {TranslateService} from '@ngx-translate/core';
import {HelperService} from '../../shared/services/helper.service';
import {MatDialog} from '@angular/material/dialog';
import {TempUserComponent} from '../modals/temp-user/temp-user.component';
import {TooltipShiftsModalsComponent} from '../modals/tooltip-shifts-modals/tooltip-shifts-modals.component';
import {DomSanitizer} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
import {CancelReasonsModel} from '../../shared/models/cancel-reasons.model';
import {ShiftsService} from '../../shared/services/shifts.service';
import { SharedService } from '../../shared/services/sharedService';

@Component({
  selector: 'app-manage-application',
  templateUrl: './manage-application.component.html',
  styleUrls: ['./manage-application.component.scss'],
})
export class ManageApplicationComponent implements OnInit, OnDestroy {
  typeSend = 0;
  message = '';
  groupId = 0;
  contracts: Contract[] = [];
  competences: Competence[] = [];
  showContracts = false;
  showCompetences = false;
  showSort = false;
  selectedContract = '';
  allSelectedContractTitle: any = [];
  selectedCompetence = '';
  allSelectedCompetencesTitle: any = [];
  multiCheckedContracts: any = [];
  allCompetencesChecked = true;
  allContractsChecked = true;
  loading = false;
  intervalForEmployees: any;
  selectedSort = this.translate.instant('sortByAvailability');
  filter: { competence?: Competence; contract?: Contract; sort: 1 } = {
    competence: null,
    contract: null,
    sort: 1,
  };
  sorts = [
    {id: 1, title: 'sortByAvailability'},
    {id: 2, title: 'sortByName'},
  ];

  chosenSort = {id: 2, title: 'sortByName'};

  allContractsCheck: any;
  allCompetencesCheck: any;
  selectedDropDown: any[] = [];

  competenceDropDown: any[] = [];

  competencesArray: any[] = [];
  contractsArray: any[] = [];
  competenceTitle: string | undefined;

  findCompetenceInShift: any;

  employees: any[] = [];
  allEmployees: any[] = [];
  selectAllUsers = false;
  allChecks: any[] = [];
  selectAll: any[] = [];
  applications: any[] = [];
  invitations: any[] = [];
  arrayForSearchCompetences: any[] = [];
  sortId: number;
  allContractClicked = false;
  @Input() shift: any;

  selectedShiftsLength = 0;
  getApplicationsSubscription: Subscription;
  getInvitationsSubscription: Subscription;
  declinedApplicationSubscription: Subscription;
  assignmentTimings: any[] = [];
  allAssignments: any[] = [];
  absences: any[] = [];
  cancelReasons: any[] = [];
  shifts: any[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private employeesService: EmployeesService,
    private translate: TranslateService,
    private helper: HelperService,
    private datePipe: DatePipe,
    private router: Router,
    private shiftsService: ShiftsService,
    private sharedService: SharedService
  ) {
  }

  ngOnInit(): void {

    this.selectedShiftsLength = this.sharedService.getShifts().length;

    this.shiftsService
      .getActiveCancelReasons()
      .subscribe((reasons: CancelReasonsModel[]) => {
        reasons.forEach((x: any) => {
          if (x.groupId === Number(this.groupId)) {
            this.cancelReasons.push(x);
          }
        });
      });

    this.helper.shiftCalendarShift$.subscribe(
      (shift2: any) => {
        if (shift2) {

          this.loading = true;
          this.shift = shift2;
          this.shift.timing = this.findShiftType(this.shift);
          this.employeesService.getUnavailableEmployees(this.groupId, this.findShiftDate(this.shift)).subscribe((
            (data: any) => {
              this.absences = data;
            }
          ));
          let dayBefore = new Date(
            new Date(this.shift.date.replace(/-/g, '/')).setDate(new Date(this.shift.date.replace(/-/g, '/')).getDate() - 1)
          ).toDateString();
          dayBefore = this.datePipe.transform(dayBefore, 'yyyy-MM-dd');
          let dayNumber = 2;
          // if (this.shift.timing == 'EVENING_ASSIGNMENT' || this.shift.timing == 'NIGHT_ASSIGNMENT') {
          //   dayNumber = 3;
          // }
          let nextDay: any = new Date(new Date(dayBefore).setDate(new Date(dayBefore).getDate() + dayNumber));
          nextDay = this.datePipe.transform(nextDay, 'yyyy-MM-dd');
          this.employeesService.getAssignments(dayBefore, nextDay).subscribe(
            (shiftData: any) => {
              const groupIndex = shiftData.groups.findIndex(
                (g: any) => g.group.id == this.groupId
              );
              if (groupIndex !== -1) {
                const temporalShifts: any = [];
                shiftData.groups[groupIndex].dayAssignments.forEach((dayShift: any) => {
                  dayShift.assignments.forEach((a: any) => {
                    temporalShifts.push(a);
                  });
                });

                this.shifts = temporalShifts;
                this.getApplicationsSubscription = this.helper.getApplications$.subscribe(
                  (data: any[]) => {
                    this.applications = data;
                  }
                );

                this.getInvitationsSubscription = this.helper.getInvitations$.subscribe(
                  (data: any[]) => {
                    this.invitations = data.sort((n1: any, n2: any) => {
                      if (n1.id < n2.id) {
                        return 1;
                      }

                      if (n1.id > n2.id) {
                        return -1;
                      }

                      return 0;
                    });
                  });


                this.declinedApplicationSubscription = this.helper.declinedApplication$.subscribe(
                  (data: any) => {
                    const declinedIndex = this.applications.findIndex(
                      (x) => x.assignmentId == data.id
                    );
                    if (declinedIndex !== -1) {
                      this.applications[declinedIndex].status = 'DECLINED';
                    }
                  }
                );
                this.getEmployees(false);
              }
            }
          );

          this.employeesService.userPositions().subscribe((positions: any[]) => {
            positions.forEach((p: any) => {
              if (p.id == this.shift.positionId) {
                this.shift.positionName = p.title;
              }
            });
          });
        }
      });

    this.helper.foundDayAssignments.subscribe(
      (data: any) => {
        if (data) {
          this.allAssignments = data;
          if (this.shift) {
            const index = data.findIndex((d: any) => d.day == this.datePipe.transform(new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')), 'yyyy-MM-dd').toString());

            if (index) {
              this.assignmentTimings = data[index].assignmentTimings;
              localStorage.setItem('assignmentTimingsAvailability', JSON.stringify(this.assignmentTimings));
              this.shift.timing = this.findShiftType(this.shift);
              this.employeesService.getUnavailableEmployees(this.groupId, this.findShiftDate(this.shift)).subscribe((
                (data: any) => {
                  this.absences = data;
                }
              ));
              let dayBefore = new Date(
                new Date(this.shift.date.replace(/-/g, '/')).setDate(new Date(this.shift.date.replace(/-/g, '/')).getDate() - 1)
              ).toDateString();
              dayBefore = this.datePipe.transform(dayBefore, 'yyyy-MM-dd');
              let dayNumber = 2;
              // if (this.shift.timing == 'EVENING_ASSIGNMENT' || this.shift.timing == 'NIGHT_ASSIGNMENT') {
              //   dayNumber = 3;
              // }
              let nextDay: any = new Date(new Date(dayBefore).setDate(new Date(dayBefore).getDate() + dayNumber));
              nextDay = this.datePipe.transform(nextDay, 'yyyy-MM-dd');
              this.employeesService.getAssignments(dayBefore, nextDay).subscribe(
                (shiftData: any) => {
                  const groupIndex = shiftData.groups.findIndex(
                    (g: any) => g.group.id == this.groupId
                  );
                  if (groupIndex !== -1) {
                    const temporalShifts: any = [];
                    shiftData.groups[groupIndex].dayAssignments.forEach((dayShift: any) => {
                      dayShift.assignments.forEach((a: any) => {
                        temporalShifts.push(a);
                      });
                    });
                    this.shifts = temporalShifts;
                  }
                }
              );
            }
          }
        }
      }
    );
    const shift = localStorage.getItem('selectedShift');
    if (shift) {
      this.shift = JSON.parse(shift);
      this.shift.timing = this.findShiftType(this.shift);
      this.employeesService.getUnavailableEmployees(this.groupId, this.findShiftDate(this.shift)).subscribe((
        (data: any) => {
          this.absences = data;
        }
      ));
      let dayBefore = new Date(
        new Date(this.shift.date.replace(/-/g, '/')).setDate(new Date(this.shift.date.replace(/-/g, '/')).getDate() - 1)
      ).toDateString();
      dayBefore = this.datePipe.transform(dayBefore, 'yyyy-MM-dd');
      let dayNumber = 2;
      // if (this.shift.timing == 'EVENING_ASSIGNMENT' || this.shift.timing == 'NIGHT_ASSIGNMENT') {
      //   dayNumber = 3;
      // }
      let nextDay: any = new Date(new Date(dayBefore).setDate(new Date(dayBefore).getDate() + dayNumber));
      nextDay = this.datePipe.transform(nextDay, 'yyyy-MM-dd');
      this.employeesService.getAssignments(dayBefore, nextDay).subscribe(
        (shiftData: any) => {
          const groupIndex = shiftData.groups.findIndex(
            (g: any) => g.group.id == this.groupId
          );
          if (groupIndex !== -1) {
            const temporalShifts: any = [];
            shiftData.groups[groupIndex].dayAssignments.forEach((dayShift: any) => {
              dayShift.assignments.forEach((a: any) => {
                temporalShifts.push(a);
              });
            });
            this.shifts = temporalShifts;
          }
        }
      );
    }
    this.employeesService.userPositions().subscribe((positions: any[]) => {
      positions.forEach((p: any) => {
        if (p.id == this.shift.positionId) {
          this.shift.positionName = p.title;
        }
      });
    });
    this.groupId = Number(localStorage.getItem('gruopId'));

    this.employeesService.userContracts().subscribe((contracts: Contract[]) => {
      this.contracts = contracts.filter((contract) => {
        return (
          contract.groupId === this.groupId &&
          contract.groupStatus === 'ACTIVE' &&
          contract.rowStatus === 'ACTIVE'
        );
      });
    });

    this.employeesService
      .userCompetences()
      .subscribe((competences: Competence[]) => {
        this.competences = competences.filter((x) => {
          return (
            x.groupId === this.groupId &&
            x.groupStatus === 'ACTIVE' &&
            x.rowStatus === 'ACTIVE'
          );
        });
      });


    this.getApplicationsSubscription = this.helper.getApplications$.subscribe(
      (data: any[]) => {
        this.applications = data;
      }
    );

    this.getInvitationsSubscription = this.helper.getInvitations$.subscribe(
      (data: any[]) => {
        this.invitations = data.sort((n1: any, n2: any) => {
          if (n1.id < n2.id) {
            return 1;
          }

          if (n1.id > n2.id) {
            return -1;
          }

          return 0;
        });
      });


    this.declinedApplicationSubscription = this.helper.declinedApplication$.subscribe(
      (data: any) => {
        const declinedIndex = this.applications.findIndex(
          (x) => x.assignmentId == data.id
        );
        if (declinedIndex !== -1) {
          this.applications[declinedIndex].status = 'DECLINED';
        }
      }
    );
    this.sortId = this.sorts[0].id;

    this.helper.shiftCalendarShift.next(this.shift);
  }

  checkSelectedShifts(): number {
    return this.selectedShiftsLength;
  }

  calculateMinutes(time: string): number {
    const hours = Number(time.split(':')[0]);
    const minutes = Number(time.split(':')[1]);

    return hours * 60 + minutes;
  }

  findShiftDate(shift: any): any {
    let shiftStart = shift.start;
    let shiftEnd = shift.end;
    let onlyDate = '';
    try {
      onlyDate = this.datePipe.transform(new Date(shift.date.replace(/-/g, '/')), 'yyyy-MM-dd');
    } catch (e) {
      onlyDate = this.datePipe.transform(new Date(shift.start.replace(/-/g, '/')), 'yyyy-MM-dd');
    }
    return onlyDate;
  }

  findShiftType(shift: any): any {

    let shiftStart = shift.start;
    let shiftEnd = shift.end;
    let onlyDate = '';
    try {
      onlyDate = this.datePipe.transform(new Date(shift.date.replace(/-/g, '/')), 'yyyy-MM-dd');
    } catch (e) {
      onlyDate = this.datePipe.transform(new Date(shift.start.replace(/-/g, '/')), 'yyyy-MM-dd');
    }


    if (shift.start.length > 5) {
      shiftStart = shift.start.substring(11, 16);
    }



    let type = '';


    for (const time of this.assignmentTimings) {
      if (
        this.calculateMinutes(time.end) >
        this.calculateMinutes(time.start)
      ) {
        if (
          this.calculateMinutes(time.start) <=
          this.calculateMinutes(shiftStart) &&
          this.calculateMinutes(time.end) >=
          this.calculateMinutes(shiftStart)
        ) {
          type = time.type;
        }
      } else {
        if (
          this.calculateMinutes(time.start) <=
          this.calculateMinutes(shiftStart)
        ) {
          type = time.type;
        }
      }
    }
    // if (type == 'SWING_1') {
    //   type = 'DAY_ASSIGNMENT';
    // } else if (type == 'SWING_2') {
    //   type = 'EVENING_ASSIGNMENT';
    // }

    return type;
  }

  checkActiveApplication(person: any): any {
    const applicationIndex = this.applications.findIndex(
      (x) =>
        x.appliedById == person.id && (x.status === 'APPLIED' || x.status == 1)
    );

    if (applicationIndex === -1) {
      return null;
    } else {
      return this.applications[applicationIndex];
    }
  }

  getEmployees(firstTime: boolean): void {
    if (localStorage.getItem('assignmentTimingsAvailability')) {
      this.assignmentTimings = JSON.parse(localStorage.getItem('assignmentTimingsAvailability'));
    }
    this.shift.timing = this.findShiftType(this.shift);
    this.loading = true;
    // if (firstTime) {
    this.employeesService.getUnavailableEmployees(this.groupId, this.findShiftDate(this.shift)).subscribe(
      (abs: any) => {
        this.absences = abs;


        this.employeesService.getEmployeesActive(this.groupId).subscribe((data: any) => {
          let onlyEmplyees: any[] = [];
          data.forEach((e: any) => {
            if (this.shift?.positionName) {
              if (
                e.roles.includes('ROLE_EMPLOYEE') &&
                (e.groupStatus != null && !(e.groupStatus.includes('INACTIVE'))) &&
                e.type === 'INTERNAL' &&
                e.positions
                  ?.toUpperCase()
                  .includes(this.shift?.positionName?.toUpperCase())
              ) {

                const absencesIndex = this.absences.findIndex((a: any) => {
                  return a.appliedById == e.id;
                });

                if (absencesIndex !== -1) {
                  if (this.absences[absencesIndex].cancelReasonId == 0) {
                    this.employeesService.findAvailability(this.absences[absencesIndex].id).subscribe(
                      (data: any) => {
                        this.absences[absencesIndex] = data;
                        const cancelReasonIndex = this.cancelReasons.findIndex((c: any) => c.id == this.absences[absencesIndex].cancelReasonId);
                        if (cancelReasonIndex !== -1) {
                          e.hasAbsence = 1;
                          e.absence = this.cancelReasons[cancelReasonIndex].title;
                        }
                      }
                    );
                  } else {
                    const cancelReasonIndex = this.cancelReasons.findIndex((c: any) => c.id == this.absences[absencesIndex].cancelReasonId);
                    if (cancelReasonIndex !== -1) {
                      e.hasAbsence = 1;
                      e.absence = this.cancelReasons[cancelReasonIndex].title;
                    }
                  }
                }
                onlyEmplyees.push(e);
              }
            } else {
              if (
                e.roles.includes('ROLE_EMPLOYEE') &&
                (e.groupStatus != null && !(e.groupStatus.includes('INACTIVE'))) &&
                e.type === 'INTERNAL'
              ) {
                onlyEmplyees.push(e);
              }
            }
          });
          if (this.shift) {

            if (this.shift.competenceId) {
              let shiftCompetence: any = null;
              const competenceIndex = this.competences.findIndex((x: any) => {
                return x.id == this.shift.competenceId;
              });

              if (competenceIndex !== -1) {
                shiftCompetence = this.competences[competenceIndex];
                const newArray: any[] = [];
                onlyEmplyees.forEach((e: any) => {
                  if (e.competences) {
                    if (
                      e.competences
                        ?.toUpperCase()
                        .includes(shiftCompetence?.title?.toUpperCase())
                    ) {
                      newArray.push(e);
                    }
                  }
                });
                onlyEmplyees = newArray;
              }
            }

            this.employees = onlyEmplyees;
            this.allEmployees = onlyEmplyees;

            const finalEmployeesBeforeShiftCheck = JSON.parse(JSON.stringify(onlyEmplyees));
            this.selectSort({id: 1, title: 'sortByAvailability'}, true);
            finalEmployeesBeforeShiftCheck.forEach((e: any) => {

              const otherShifts: any[] = [];
              const realShifts: any[] = [];

              this.shifts.forEach((s: any) => {
                if (s.assignedToId == e.id && s.status == 'ASSIGNED') {
                  realShifts.push(s);
                }
              });

              e.otherShifts = otherShifts;
              e.shifts = realShifts;
              const specificShifts = [];
              specificShifts[0] = this.isFilled(e, -2);
              specificShifts[1] = this.isFilled(e, -1);
              specificShifts[2] = this.isFilled(e, 1);
              specificShifts[3] = this.isFilled(e, 2);
              specificShifts[-1] = this.isFilled(e, 0);

              e.specificShifts = specificShifts;

              const employeeIndex1 = this.employees.findIndex((u: any) => u.id == e.id);
              if (employeeIndex1 !== -1) {
                this.employees[employeeIndex1].shifts = realShifts;
                this.employees[employeeIndex1].specificShifts = specificShifts;
              }

              const allEmployeeIndex1 = this.allEmployees.findIndex((u: any) => u.id == e.id);
              if (allEmployeeIndex1 !== -1) {
                this.allEmployees[allEmployeeIndex1].shifts = realShifts;
                this.allEmployees[allEmployeeIndex1].specificShifts = specificShifts;
              }

              realShifts.forEach((s: any) => {
                //if actual shift type is the same as shift type and they're same day remove from employees, user is busy
                if (this.shift.timing == this.findShiftType(s)
                  && this.datePipe.transform(new Date(this.shift.date.replace(/-/g, '/')), 'yyyy-MM-dd') == this.datePipe.transform(new Date(s.start.replace(/-/g, '/')), 'yyyy-MM-dd')) {
                  const employeeIndex = this.employees.findIndex((u: any) => u.id == e.id);
                  if (employeeIndex !== -1) {
                    this.employees.splice(employeeIndex, 1);
                  }

                  const allEmployeeIndex = this.allEmployees.findIndex((u: any) => u.id == e.id);
                  if (allEmployeeIndex !== -1) {
                    this.allEmployees.splice(allEmployeeIndex, 1);
                  }
                }
              });

              // otherShifts
              otherShifts.forEach((s: any) => {
                // if actual shift type is the same as shift type and they're same day remove from employees, user is busy
                if (this.shift.timing == this.findShiftType(s)
                  && this.datePipe.transform(new Date(this.shift.date.replace(/-/g, '/')), 'yyyy-MM-dd') == this.datePipe.transform(new Date(s.start.replace(/-/g, '/')), 'yyyy-MM-dd')
                ) {                  const employeeIndex = this.employees.findIndex((u: any) => u.id == e.id);
                  if (employeeIndex !== -1) {
                    this.employees.splice(employeeIndex, 1);
                  }

                  const allEmployeeIndex = this.allEmployees.findIndex((u: any) => u.id == e.id);
                  if (allEmployeeIndex !== -1) {
                    this.allEmployees.splice(allEmployeeIndex, 1);
                  }
                }
              });
              this.selectSort({id: 1, title: 'sortByAvailability'}, true);
              this.loading = false;
              // setTimeout(() => {
              //   this.loading = false;
              // }, 2000);

            });
          }
          // clearInterval(this.intervalForEmployees);

          // this.employees = onlyEmplyees;
          // this.allEmployees = onlyEmplyees;
          // this.selectSort({ id: 1, title: 'sortByAvailability' }, true);

          let index = 0;


        });

      });
    // }
    //   else {
    //     this.employeesService.getEmployeesActive(this.groupId).subscribe((data: any) => {
    //       let onlyEmplyees: any[] = [];
    //       data.forEach((e: any) => {
    //         if (this.shift?.positionName) {
    //           if (
    //             e.roles.includes('ROLE_EMPLOYEE') &&
    //             (e.groupStatus != null && !(e.groupStatus.includes('INACTIVE'))) &&
    //             e.type === 'INTERNAL' &&
    //             e.positions
    //               ?.toUpperCase()
    //               .includes(this.shift?.positionName?.toUpperCase())
    //           ) {
    //             const absencesIndex = this.absences.findIndex((a: any) => {
    //               return a.appliedById == e.id;
    //             });
    //
    //             if (absencesIndex != -1) {
    //               if (this.absences[absencesIndex].cancelReasonId == 0) {
    //                 this.employeesService.findAvailability(this.absences[absencesIndex].id).subscribe(
    //                   (data: any) => {
    //                     this.absences[absencesIndex] = data;
    //                     const cancelReasonIndex = this.cancelReasons.findIndex((c: any) => c.id == this.absences[absencesIndex].cancelReasonId);
    //                     if (cancelReasonIndex != -1) {
    //                       e.hasAbsence = 1;
    //                       e.absence = this.cancelReasons[cancelReasonIndex].title;
    //                     }
    //                   }
    //                 );
    //               } else {
    //                 const cancelReasonIndex = this.cancelReasons.findIndex((c: any) => c.id == this.absences[absencesIndex].cancelReasonId);
    //                 if (cancelReasonIndex != -1) {
    //                   e.hasAbsence = 1;
    //                   e.absence = this.cancelReasons[cancelReasonIndex].title;
    //                 }
    //               }
    //             }
    //             onlyEmplyees.push(e);
    //           }
    //           clearInterval(this.intervalForEmployees);
    //         } else {
    //           if (
    //             e.roles.includes('ROLE_EMPLOYEE') &&
    //             (e.groupStatus != null && !(e.groupStatus.includes('INACTIVE'))) &&
    //             e.type === 'INTERNAL'
    //           ) {
    //             onlyEmplyees.push(e);
    //           }
    //         }
    //       });
    //
    //       if (this.shift) {
    //         //// ToDo:Filtriranje usera prema kompetnsu
    //
    //         if (this.shift.competenceId) {
    //           let shiftCompetence: any = null;
    //           const competenceIndex = this.competences.findIndex((x: any) => {
    //             return x.id == this.shift.competenceId;
    //           });
    //
    //           if (competenceIndex != -1) {
    //             shiftCompetence = this.competences[competenceIndex];
    //             const newArray: any[] = [];
    //             onlyEmplyees.forEach((e: any) => {
    //               if (e.competences) {
    //                 if (
    //                   e.competences
    //                     ?.toUpperCase()
    //                     .includes(shiftCompetence?.title?.toUpperCase())
    //                 ) {
    //                   newArray.push(e);
    //                 }
    //               }
    //             });
    //             onlyEmplyees = newArray;
    //           }
    //         }
    //
    //         this.employees = onlyEmplyees;
    //         this.selectSort({ id: 1, title: 'sortByAvailability' }, true);
    //         let dayBefore = new Date(
    //           new Date(this.shift.date).setDate(new Date(this.shift.date).getDate() - 1)
    //         ).toDateString();
    //         dayBefore = this.datePipe.transform(dayBefore, 'yyyy-MM-dd');
    //         let dayNumber = 2;
    //         if (this.shift.timing == 'EVENING_ASSIGNMENT' || this.shift.timing == 'NIGHT_ASSIGNMENT') {
    //           dayNumber = 3;
    //         }
    //         onlyEmplyees.forEach((e: any) => {
    //           this.employeesService
    //             .getEmployeeSchedule(e.id, dayBefore, dayNumber)
    //             .subscribe((shifts: any) => {
    //               const realShifts: any[] = [];
    //               if (shifts.groups.length > 0) {
    //                 const indexShifts = shifts.groups.findIndex(
    //                   (x: any) => x.group.id == this.groupId
    //                 );
    //                 if (indexShifts != -1) {
    //                   shifts.groups[indexShifts].dayAssignments.forEach(
    //                     (a: any) => {
    //                       a.assignments.forEach((s: any) => {
    //                         if (s.assignedToId == e.id) {
    //                           realShifts.push(s);
    //                         }
    //                       });
    //                     }
    //                   );
    //                 }
    //               }
    //
    //               e.shifts = realShifts;
    //               realShifts.forEach((s: any) => {
    //                 //if actual shift type is the same as shift type and they're same day remove from employees, user is busy
    //                 if (this.shift.timing == this.findShiftType(s) && this.datePipe.transform(new Date(this.shift.date), "yyyy-MM-dd") == this.datePipe.transform(new Date(s.start), "yyyy-MM-dd")){
    //                   const employeeIndex = this.employees.findIndex((u: any) => u.id == e.id);
    //                   if (employeeIndex != -1) {
    //                     this.employees.splice(employeeIndex, 1);
    //                   }
    //
    //                   const allEmployeeIndex = this.allEmployees.findIndex((u: any) => u.id == e.id);
    //                   if (allEmployeeIndex != -1) {
    //                     this.allEmployees.splice(allEmployeeIndex, 1);
    //                   }
    //                 }
    //               });
    //               this.employees = onlyEmplyees;
    //               this.selectSort({ id: 1, title: 'sortByAvailability' }, true);
    //
    //
    //             });
    //         });
    //       }
    //
    //       this.employees = onlyEmplyees;
    //       this.selectSort({ id: 1, title: 'sortByAvailability' }, true);
    //       this.allEmployees = onlyEmplyees;
    //       setTimeout(() => {
    //         this.loading = false;
    //       }, 2000);
    //
    //       let index = 0;
    //       this.allEmployees.forEach((e) => {
    //         if (e.profilePicture && e.profilePicture != '') {
    //           this.downloadImage(e.profilePicture, index);
    //         }
    //         index = index + 1;
    //       });
    //     });
    //   }
  }

  // ifShiftHaveCompetence(): void {
  //   this.competences.forEach((com) => {
  //     if (com.id === this.shift.competenceId) {
  //       this.findCompetenceInShift = com;
  //     }
  //   });
  //   this.emplyees.forEach((e: { competences: string }) => {
  //     if (
  //       e.competences
  //         ?.replace(/,/g, '')
  //         .includes(this.findCompetenceInShift?.title)
  //     ) {
  //       this.employeesWithSpecificCompetence.push(e);
  //     }
  //   });
  //   this.employees = [...this.employeesWithSpecificCompetence];
  // }

  declineApplication(application: any): void {
    const confirmDialog = this.dialog.open(TooltipShiftsModalsComponent, {
      disableClose: true,
      width: '290px',
      height: '190px',
      panelClass: 'defaultDialog',
      position: {
        top: '150px',
      },
      data: {
        title: this.translate.instant('declineApplication'),
        message: this.translate.instant('declineApplicationText'),
        mode: 'declineApp',
      },
    });
    application.status = 1;
    confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.helper.declineApplication.next(application);
      }
    });
  }

  acceptApplication(application: any): void {
    const confirmDialog = this.dialog.open(TooltipShiftsModalsComponent, {
      disableClose: true,
      width: '290px',
      height: '190px',
      panelClass: 'defaultDialog',
      position: {
        top: '150px',
      },
      data: {
        title: this.translate.instant('acceptApplication'),
        message: this.translate.instant('acceptApplicationText'),
        mode: 'acceptApp',
      },
    });
    application.status = 1;
    confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.helper.confirmApplication.next(application);
      }
    });
  }

  // tslint:disable-next-line:typedef
  eraseCommaa(data: string) {
    if (data === null) {
      return '';
    } else {
      const eraseComma = data?.split(',');
      // tslint:disable-next-line:no-shadowed-variable
      return eraseComma?.filter((data) => data !== '');
    }
  }

  showAllContracts(): void {
    this.showContracts = !this.showContracts;
  }

  countOfTrueSpecific(employee: any): any {
    let count = 0;
    count = employee?.specificShifts?.filter((s: any) => {
      return s == true;
    }).length;
    return count;
  }

  contractsIsChecked(contract: any): boolean {
    const index = this.selectedDropDown.findIndex((p) => p.id === contract.id);
    if (this.selectedDropDown.length === this.contracts.length) {
      return !(index === -1) && !this.allContractsChecked;
    } else {
      return !(index === -1) && !this.allContractsChecked;
    }
  }

  selectContract(contract: Contract, event: any): void {
    event.preventDefault();
    if (contract.id == -1) {
      this.allSelectedContractTitle = [];
      this.allContractsChecked = true;
      this.selectedDropDown = [this.translate.instant('allContracts')];
      this.selectedContract = this.selectedDropDown[0];
      this.employees = this.allEmployees;
    } else {
      this.allContractsChecked = false;
    }

    const selectedIndex = this.selectedDropDown.findIndex(
      (x) => contract.title === x.title
    );
    if (selectedIndex === -1) {
      this.selectedDropDown.push(contract);
      this.allSelectedContractTitle.push(contract.title);

      if (this.allSelectedContractTitle.length === 1) {
        this.selectedContract = this.allSelectedContractTitle[0];
      } else if (this.allSelectedContractTitle.length === this.contracts.length) {
        this.allContractsChecked = false;
        this.selectedContract = this.translate.instant('severalOptions');
        this.employees = this.allEmployees;
      } else if (this.allSelectedContractTitle.length > 1) {
        this.selectedContract = this.translate.instant('severalOptions');
      }
    } else {
      this.selectedDropDown.splice(selectedIndex, 1);
      this.allSelectedContractTitle.splice(selectedIndex, 1);
      this.selectedContract = this.translate.instant('severalOptions');
      if (this.selectedDropDown.length == 0) {
        this.allContractsChecked = true;
        this.selectedContract = this.translate.instant('allContracts');
        this.employees = this.allEmployees;
      } else if (this.allSelectedContractTitle.length === 1) {
        this.selectedContract = this.allSelectedContractTitle[0];
      }
    }
    this.filterUsers();
  }

  filterUsers(): void {
    if (this.allContractsChecked && this.allCompetencesChecked) {
      this.employees = this.allEmployees;
    } else if (this.allContractsChecked && !this.allCompetencesChecked) {
      this.employees = [];
      this.allEmployees.forEach((x) => {
        this.competenceDropDown.forEach((t: any) => {
          if (x.competences?.replace(/,/g, '').includes(t.title)) {
            const findCompetenceIndex = this.employees.findIndex(
              (a) => a.id === x.id
            );
            if (findCompetenceIndex === -1) {
              this.employees.push(x);
            }
          }
        });
      });
    } else if (!this.allContractsChecked && this.allCompetencesChecked) {
      this.employees = [];
      this.allEmployees.forEach((x) => {
        this.selectedDropDown.forEach((t: any) => {
          if (x.contracts?.replace(/,/g, '').includes(t.title)) {
            this.employees.push(x);
          }
        });
      });
    } else {
      this.employees = [];
      this.competencesArray = [];
      this.contractsArray = [];
      this.allEmployees.forEach((x) => {
        this.selectedDropDown.forEach((t: any) => {
          if (x.contracts?.replace(/,/g, '').includes(t.title)) {
            this.competencesArray.push(x);
          }
        });
      });
      this.allEmployees.forEach((x) => {
        this.competenceDropDown.forEach((t: any) => {
          if (x.competences?.replace(/,/g, '').includes(t.title)) {
            const findCompetenceIndex = this.contractsArray.findIndex(
              (a) => a.id === x.id
            );
            if (findCompetenceIndex === -1) {
              this.contractsArray.push(x);
            }
          }
        });
      });
      if (this.competencesArray.length === 0) {
        this.allCompetencesChecked = true;
        this.employees = [...this.contractsArray];
      } else if (this.contractsArray.length === 0) {
        this.allContractsChecked = true;
        this.employees = [...this.competencesArray];
      } else {
        this.competencesArray.forEach((c) => {
          this.contractsArray.forEach((contract) => {
            if (c.id === contract.id) {
              this.employees.push(c);
            }
          });
        });
      }
    }
  }

  showAllSorts(): void {
    this.showSort = true;
  }



  sortedEmployees(arr: any): any {
    if (this.chosenSort.id == 1) {
      return arr.sort(
        (a: any, b: any) => {
          // if (a.hasAbsence > b.hasAbsence) {
          //   return -1;
          // } else {
          //   return 0;
          // }
          if (a.absence?.localeCompare(b.absence)) {
            return 5;
          } else {
            if (a.shifts?.length > b.shifts?.length) {
              if (a.name.localeCompare(b.name, 'sv')) {
                return 3;
              } else {
                return 2;
              }
            } else {
              if (a.name.localeCompare(b.name, 'sv')) {
                return -2;
              } else {
                return -1;
              }
            }
          }

        }
      );
    } else {
      return arr.sort((a: any, b: any) =>
        a.name.localeCompare(b.name, 'sv')
      );
    }
  }

  selectSort(sort: any, newSort: any): void {
    if (this.sortId != sort.id || newSort == true) {
      this.sortId = sort.id;
      this.selectedSort = this.translate.instant(sort.title, true);
      this.filter.sort = sort.id;
      if (this.filter.sort == 1) {
        this.employees = this.employees.sort(
          (a: any, b: any) => {
            if (a.absence?.localeCompare(b.absence)) {
              return 5;
            } else {
              if (this.countOfTrueSpecific(a) > this.countOfTrueSpecific(b)) {
                if (a.name.localeCompare(b.name, 'sv')) {
                  return 3;
                } else {
                  return 2;
                }
              } else {
                if (a.name.localeCompare(b.name, 'sv')) {
                  return -2;
                } else {
                  return -1;
                }
              }
            }
          }
        );
      } else if (this.filter.sort == 2) {
        this.employees = this.employees.sort((a, b) =>
          a.name.localeCompare(b.name, 'sv')
        );
      }
    }
  }

  showAllCompetences(): void {
    this.showCompetences = !this.showCompetences;
  }

  competencesIsChecked(competence: any): boolean {
    const index = this.competenceDropDown.findIndex((p) => p.id === competence.id);
    if (this.competenceDropDown.length === this.competences.length) {
      return !(index === -1) && !this.allCompetencesChecked;
    } else {
      return !(index === -1) && !this.allCompetencesChecked;
    }
  }

  selectCoompetence(competence: Competence, event: any): void {
    event.preventDefault();
    if (competence.id == -1) {
      this.allSelectedCompetencesTitle = [];
      this.allCompetencesChecked = true;
      this.competenceDropDown = [this.translate.instant('allCompetences')];
      this.selectedCompetence = this.competenceDropDown[0];
      this.employees = this.allEmployees;
    } else {
      this.allCompetencesChecked = false;
    }

    const selectedCompetenceIndex = this.competenceDropDown.findIndex(
      (c: { title: string }) => competence.title === c.title
    );

    if (selectedCompetenceIndex === -1) {
      this.competenceDropDown.push(competence);
      this.allSelectedCompetencesTitle.push(competence.title);

      if (this.allSelectedCompetencesTitle.length === this.competences.length) {
        this.allCompetencesChecked = false;
        this.selectedCompetence = this.translate.instant('severalOptions');
        this.employees = this.allEmployees;
      } else if (this.allSelectedCompetencesTitle.length === 1) {
        this.selectedCompetence = this.allSelectedCompetencesTitle;
      } else if (this.allSelectedCompetencesTitle.length > 1) {
        this.selectedCompetence = this.translate.instant('severalOptions');
      }
    } else {
      this.competenceDropDown.splice(selectedCompetenceIndex, 1);
      this.allSelectedCompetencesTitle.splice(selectedCompetenceIndex, 1);
      if (this.allSelectedCompetencesTitle.length > 1) {
        this.selectedCompetence = this.translate.instant('severalOptions');
      } else if (this.allSelectedCompetencesTitle.length === 1) {
        this.selectedCompetence = this.allSelectedCompetencesTitle;
      }
      if (this.competenceDropDown.length === 0) {
        this.allCompetencesChecked = true;
        this.selectedCompetence = this.translate.instant('allCompetences');
        this.employees = this.allEmployees;
      }
    }
    this.filterUsers();
  }

  public userIsAvailable(user: any): boolean {
    let available = false;

    if (this.shift.timing == 'DAY_ASSIGNMENT') {
        if (user.specificShifts[1] == false && user.specificShifts[2] == false && user.specificShifts[3] == false ) {
          available = true;
        }
    } else if (this.shift.timing == 'EVENING_ASSIGNMENT') {

      if ((user.specificShifts[0] == false && user.specificShifts[1] == false && user.specificShifts[2] == false && user.specificShifts[3] == false)
          ||
        (user.specificShifts[0] == true && user.specificShifts[1] == false && user.specificShifts[2] == false && user.specificShifts[3] == false)
        ||
        (user.specificShifts[0] == false && user.specificShifts[1] == false && user.specificShifts[2] == false && user.specificShifts[3] == true)
        ||
        (user.specificShifts[0] == true && user.specificShifts[1] == false && user.specificShifts[2] == false && user.specificShifts[3] == true)

      ) {
        available = true;
      }
    } else if (this.shift.timing == 'NIGHT_ASSIGNMENT') {
      if (user.specificShifts[0] == false && user.specificShifts[1] == false && user.specificShifts[2] == false && user.specificShifts[3] == false ) {
        available = true;
      }
    }

    return available;
  }

  /**
   *
   * Select all checkBox
   */
  setAll(event: any): void {
    if (!(this.typeSend == 1 || this.typeSend == 2 || this.typeSend == 3)) {
      event.preventDefault();
    } else if (this.typeSend == 2 || this.typeSend == 3) {
      this.selectAllUsers = !this.selectAllUsers;
      if (this.selectAllUsers) {
        this.allChecks = [];
        this.employees.forEach((user) => {
          if (!user.hasAbsence && this.userIsAvailable(user)) {
            this.selectAll.push(user);
            this.allChecks.push(user);
          }
        });
      } else {
        this.selectAll.splice(0);
        this.allChecks = [];
      }
    }
    const obj = {typeSend: this.typeSend, employees: this.allChecks};
    this.helper.changedApplicationData.next(obj);
  }

  /**
   *
   * Get single checkBox
   */

  shouldPrevent(event: any): void {
    if (!(this.typeSend == 1 || this.typeSend == 2 || this.typeSend == 3)) {
      event.preventDefault();
    }
  }

  selection(user: any): void {
    if (this.typeSend == 1 || this.typeSend == 2 || this.typeSend == 3) {
      this.allChecks = [...this.allChecks];

      const userIndex = this.allChecks.findIndex((x) => x.id == user.id);


      if (userIndex === -1) {
        if (this.typeSend == 1) {
          this.allChecks = [user];
        } else {
          this.allChecks.push(user);
        }
      } else {
        if (this.typeSend == 1) {
          this.allChecks = [];
        } else {

          this.allChecks.splice(userIndex, 1);

        }
      }


      const obj = {typeSend: this.typeSend, employees: this.allChecks};
      this.helper.changedApplicationData.next(obj);
    }
  }

  changedType(type: number): void {
    this.typeSend == type ? (this.typeSend = 0) : (this.typeSend = type);
    if (this.typeSend == 1) {
      this.allChecks = [];
    }
    const obj = {typeSend: this.typeSend, employees: this.allChecks};
    this.helper.changedApplicationData.next(obj);
  }

  isChecked(user: any): boolean {
    const userIndex = this.allChecks.findIndex((x) => x == user);

    if (userIndex === -1) {
      return false;
    } else {
      return true;
    }
  }

  getUserStatus(employee: any): string {
    return 'N / A';
  }

  messageChanged(): void {
    this.helper.messageChanged.next(this.message);
  }


  isFilled(employee: any, diff: any): boolean {
    let value = false;
    const shiftStart = new Date(this.shift.date + ' ' + this.shift.start);
    let shiftEnd = new Date(this.shift.date + ' ' + this.shift.end);
    if (shiftStart.getTime() > shiftEnd.getTime()) {
      shiftEnd = new Date(shiftEnd.getTime() + 60 * 60 * 24 * 1000);
    }

    if (diff == -2 && (this.shift.timing == 'DAY_ASSIGNMENT' || this.shift.timing == 'SWING_1')) {
      employee.shifts?.forEach((s: any) => {
        //if shift is day assignment, if you find shift which is evening and started before this shift
        if (this.findShiftType(s) == 'EVENING_ASSIGNMENT' && new Date(s.date + ' ' + s.start.replace(/-/g, '/')).getTime() < new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')).getTime()) {
          value = true;
        }
      });
    } else if (diff == -1 && (this.shift.timing == 'DAY_ASSIGNMENT' || this.shift.timing == 'SWING_1')) {
      employee.shifts?.forEach((s: any) => {
        //if shift is day assignment, if you find shift which is night and started before this shift
        if (this.findShiftType(s) == 'NIGHT_ASSIGNMENT' && new Date(s.date + ' ' + s.start.replace(/-/g, '/')).getTime() < new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')).getTime()) {
          value = true;
        }
      });
    } else if (diff == 0 && this.shift.timing == 'DAY_ASSIGNMENT' ) {
      employee.shifts?.forEach((s: any) => {
        //if shift is day assignment, if you find shift which is swing 1, started after original shift but on the same day
        if (this.findShiftType(s) == 'SWING_1' &&
          new Date(s.date + ' ' + s.start.replace(/-/g, '/')).getTime() >
          new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')).getTime() &&
          new Date(s.date + ' ' + s.start.replace(/-/g, '/')).getDate() ==
          new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')).getDate()

        ) {
          value = true;
        }
      });
    }
    else if (diff == 0 && this.shift.timing == 'SWING_1') {
      employee.shifts?.forEach((s: any) => {
        //if shift is day assignment, if you find shift which is swing 1, started after original shift but on the same day
        if (this.findShiftType(s) == 'DAY_ASSIGNMENT' &&
          new Date(s.date + ' ' + s.start.replace(/-/g, '/')).getDate() ==
          new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')).getDate()

        ) {
          value = true;
        }
      });
    }
    else if (diff == 1 && (this.shift.timing == 'DAY_ASSIGNMENT' || this.shift.timing == 'SWING_1')) {
      employee.shifts?.forEach((s: any) => {
        //if shift is day assignment, if you find shift which is evening and started after this shift
        if ((this.findShiftType(s) == 'EVENING_ASSIGNMENT' || this.findShiftType(s) == 'SWING_2')  && this.datePipe.transform(new Date(s.date + ' ' + s.start.replace(/-/g, '/')), 'yyyy-MM-dd') == this.datePipe.transform(new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')), 'yyyy-MM-dd')) {
          value = true;
        }
      });
    } else if (diff == 2 && (this.shift.timing == 'DAY_ASSIGNMENT' || this.shift.timing == 'SWING_1')) {
      employee.shifts?.forEach((s: any) => {
        //if shift is day assignment, if you find shift which is night and started after this shift
        if (this.findShiftType(s) == 'NIGHT_ASSIGNMENT' && this.datePipe.transform(new Date(s.date + ' ' + s.start.replace(/-/g, '/')), 'yyyy-MM-dd') == this.datePipe.transform(new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')), 'yyyy-MM-dd')) {
          value = true;
        }
      });
    } else if (diff == -2 && (this.shift.timing == 'EVENING_ASSIGNMENT' || this.shift.timing == 'SWING_2')) {
      employee.shifts?.forEach((s: any) => {
        //if shift is evening assignment, if you find shift which is night and started before this shift
        if (this.findShiftType(s) == 'NIGHT_ASSIGNMENT' && new Date(s.date + ' ' + s.start.replace(/-/g, '/')).getTime() < new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')).getTime()) {
          value = true;
        }
      });
    } else if (diff == -1 && (this.shift.timing == 'EVENING_ASSIGNMENT' || this.shift.timing == 'SWING_2')) {
      employee.shifts?.forEach((s: any) => {

        //if shift is evening assignment, if you find shift which is day and started before this shift
        if ((this.findShiftType(s) == 'DAY_ASSIGNMENT' || this.findShiftType(s) == 'SWING_1') && this.datePipe.transform(new Date(s.date + ' ' + s.start.replace(/-/g, '/')), 'yyyy-MM-dd') == this.datePipe.transform(new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')), 'yyyy-MM-dd')) {
          value = true;
        }
      });
    } else if (diff == 0 && this.shift.timing == 'EVENING_ASSIGNMENT' ) {
      employee.shifts?.forEach((s: any) => {
        //if shift is evening assignment, if you find shift which is swing_2
        if (this.findShiftType(s) == 'SWING_2' &&
          new Date(s.date + ' ' + s.start.replace(/-/g, '/')).getTime() >
          new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')).getTime() &&
          new Date(s.date + ' ' + s.start.replace(/-/g, '/')).getDate() ==
          new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')).getDate()
        ) {
          value = true;
        }
      });
    } else if (diff == 0 &&  this.shift.timing == 'SWING_2') {
      employee.shifts?.forEach((s: any) => {
        //if shift is evening assignment, if you find shift which is swing_2
        if (this.findShiftType(s) == 'EVENING_ASSIGNMENT' &&
          new Date(s.date + ' ' + s.start.replace(/-/g, '/')).getDate() ==
          new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')).getDate()
        ) {
          value = true;
        }
      });
    } else if (diff == 1 && (this.shift.timing == 'EVENING_ASSIGNMENT' || this.shift.timing == 'SWING_2')) {
      employee.shifts?.forEach((s: any) => {

        //if shift is evening assignment, if you find shift which is night and started after this shift
        if (this.findShiftType(s) == 'NIGHT_ASSIGNMENT' && this.datePipe.transform(new Date(s.date + ' ' + s.start.replace(/-/g, '/')), 'yyyy-MM-dd') == this.datePipe.transform(new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')), 'yyyy-MM-dd')) {
          value = true;
        }
      });
    } else if (diff == 2 && (this.shift.timing == 'EVENING_ASSIGNMENT' || this.shift.timing == 'SWING_2')) {
      employee.shifts?.forEach((s: any) => {
        //if shift is evening assignment, if you find shift which is day and started after this shift
        if ((this.findShiftType(s) == 'DAY_ASSIGNMENT' || this.findShiftType(s) == 'SWING_1') && this.datePipe.transform(new Date(s.date + ' ' + s.start.replace(/-/g, '/')), 'yyyy-MM-dd') > this.datePipe.transform(new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')), 'yyyy-MM-dd')) {
          value = true;
        }
      });
    } else if (diff == -2 && this.shift.timing == 'NIGHT_ASSIGNMENT') {
      employee.shifts?.forEach((s: any) => {
        //if shift is night assignment, if you find shift which is day and started before this shift
        if ((this.findShiftType(s) == 'DAY_ASSIGNMENT' || this.findShiftType(s) == 'SWING_1') && this.datePipe.transform(new Date(s.date + ' ' + s.start.replace(/-/g, '/')), 'yyyy-MM-dd') == this.datePipe.transform(new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')), 'yyyy-MM-dd')) {
          value = true;
        }
      });
    } else if (diff == -1 && this.shift.timing == 'NIGHT_ASSIGNMENT') {
      // if (employee.id == 598) {
      // }
      employee.shifts?.forEach((s: any) => {
        //if shift is night assignment, if you find shift which is evening and started before this shift
        if ((this.findShiftType(s) == 'EVENING_ASSIGNMENT' || this.findShiftType(s) == 'SWING_2')  && this.datePipe.transform(new Date(s.date + ' ' + s.start.replace(/-/g, '/')), 'yyyy-MM-dd') == this.datePipe.transform(new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')), 'yyyy-MM-dd')) {
          value = true;
        }
      });
    } else if (diff == 1 && this.shift.timing == 'NIGHT_ASSIGNMENT') {
      employee.shifts?.forEach((s: any) => {
        // if (s.id == 10388) {}
        //if shift is night assignment, if you find shift which is day and started after this shift
        if ((this.findShiftType(s) == 'DAY_ASSIGNMENT' || this.findShiftType(s) == 'SWING_1') && new Date(s.date + ' ' + s.start.replace(/-/g, '/')).getTime() > new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')).getTime()) {
          value = true;
        }
      });
    } else if (diff == 2 && this.shift.timing == 'NIGHT_ASSIGNMENT') {
      // if (employee.id == 604) {
      // }
      employee.shifts?.forEach((s: any) => {
        // if (s.id == 10380 || s.id == 10385) {}
        //if shift is night assignment, if you find shift which is evening and started after this shift
        if ((this.findShiftType(s) == 'EVENING_ASSIGNMENT' || this.findShiftType(s) == 'SWING_2')  && new Date(s.date + ' ' + s.start.replace(/-/g, '/')).getTime() > new Date(this.shift.date.replace(/-/g, '/') + ' ' + this.shift.start.replace(/-/g, '/')).getTime()) {
          value = true;
        }
      });

    }

    return value;
  }


  diffHours(dt2: any, dt1: any): any {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
  }

  tempUser(): void {
    const confirmDialog = this.dialog.open(TempUserComponent, {
      disableClose: true,
      width: '290px',
      height: '424px',
      panelClass: 'defaultDialog',
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.helper.createdTempUser.next(result);
      }
    });
  }

  openUser(id: any): any {
    this.router.navigate(['/personal/user', id]);
  }

  hasStatus(person: any): any {
    const applicationIndex = this.applications.findIndex(
      (x) => x.appliedById == person.id && x.status !== 'APPLIED'
    );

    if (applicationIndex !== -1) {
      if (this.applications[applicationIndex].status === 'DECLINED') {
        return 'applicationDeclined';
      } else if (this.applications[applicationIndex].status === 'CANCELLED') {
        return 'applicationCancelled';
      } else {
        return '';
      }
    } else {
      const invitationIndex = this.invitations.findIndex(
        (x) => x.invitationToId == person.id
      );

      if (invitationIndex !== -1) {
        if (this.invitations[invitationIndex].type !== 'REQUEST') {
          if (this.invitations[invitationIndex].status === 'WAITING') {
            return 'invitationSent';
          } else if (this.invitations[invitationIndex].status === 'DECLINED') {
            return 'invitationDeclined';
          } else if (this.invitations[invitationIndex].status === 'ACCEPTED') {
            return 'invitationAccepted';
          } else if (this.invitations[invitationIndex].status === 'CLOSED') {
            return 'invitationClosed';
          }
        } else {
          if (this.invitations[invitationIndex].status === 'WAITING') {
            return 'requestSent';
          } else if (this.invitations[invitationIndex].status === 'DECLINED') {
            return 'requestDeclined';
          } else if (this.invitations[invitationIndex].status === 'ACCEPTED') {
            return 'requestAccepted';
          } else if (this.invitations[invitationIndex].status === 'CLOSED') {
            return 'requestClosed';
          }
        }
      } else {
        if (person.hasAbsence == 1) {
          return person.absence;
        } else {
          return '';
        }
      }
    }
  }
  ngOnDestroy(): void {
    this.getApplicationsSubscription?.unsubscribe();
    this.getInvitationsSubscription?.unsubscribe();
    this.declinedApplicationSubscription?.unsubscribe();
  }
}
