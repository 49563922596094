import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {EmployeesService} from '../../../shared/services/employees.service';
import {AuthConst} from '../../../shared/services/const/authConst';
import {ShiftsService} from '../../../shared/services/shifts.service';
import {DatePipe} from '@angular/common';
import {HelperService} from '../../../shared/services/helper.service';

interface Shift {
  active: boolean;
  date: string;
  dayNumber: number;
  activity2Id?: number;
  activityId: number;
  color: string;

  competenceId: number;
  disclosedNote: string;
  end: string;
  end2: string;
  groupId: number;
  id: number;
  name: string;
  positionId: number;
  start: string;
  start2: string;
  undisclosedNote: string;
}

interface CreateNewShift {
  assignedToId: null;
  groupId: number;
  start: any;
  externalId: null;
  competence1Id: any;
  activity2Id?: number;
  competence2Id?: null;
  importFileId: null;
  competence3Id?: null;
  staffing: any[];
  activity1Id: any;
  positionId: any;
  rowStatus: string;
  end: any;
  activity2End?: any;
  employerNote: any;
  disclosedNote?: any;
  activity2Start?: any;
  createdById: number;
  status: number;
  description: any;
}

@Component({
  selector: 'app-create-new-shift-modal',
  templateUrl: './create-new-shift-modal.component.html',
  styleUrls: ['./create-new-shift-modal.component.scss'],
})
export class CreateNewShiftModalComponent implements OnInit {
  shifts: Array<Shift> = [];
  allShifts: any[] = [];
  activities: any = [];
  setActivity: string | undefined;
  setActivityShort: string | undefined;
  shiftDate: string | undefined;
  shiftsDate: any = [];
  sending = false;

  constructor(
    public dialogRef: MatDialogRef<CreateNewShiftModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private employeesService: EmployeesService,
    private shiftService: ShiftsService,
    private helperService: HelperService,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit(): void {
    this.shifts = this.data.shifts;
    this.shifts.forEach((x) => {
      for (let i = 0; i < x.dayNumber; i++) {
        // @ts-ignore
        this.allShifts.push(x);
        this.allShifts.sort(
          (date1: { date: number }, date2: { date: number }) =>
            date1.date !== date2.date ? (date1.date < date2.date ? -1 : 1) : 0
        );
      }
    });

    this.employeesService.userActvity().subscribe((activities) => {
      activities.forEach((group: any) => {
        this.shifts.forEach((shift) => {
          if (
            group.groupId === shift.groupId &&
            group.groupStatus === 'ACTIVE'
          ) {
            this.activities.push(group);
            this.activities.forEach((activity: any) => {
              if (activity.id === shift?.activityId) {
                this.setActivity = activity.title;
                this.setActivityShort = activity.shortTitle;
              }
            });
          }
        });
      });
    });
  }

  getTime(shift: any): boolean {
    this.shiftDate = shift.date;
    if (this.shiftDate) {
      return true;
    } else {
      return false;
    }
  }

  createShift(): void {
    if (!this.sending) {
      this.sending = true;
      this.shifts.forEach((shifts, index: number) => {
        if (shifts.date) {
          this.shiftsDate.push(shifts);
        }
        const shiftDate = this.datePipe
          .transform(new Date(shifts.date), `yyyy-MM-dd `).replace(/-/g, '/')
          .toString();

        const shiftDateTomorrow = this.datePipe
          .transform(new Date(new Date(shifts.date).getTime() + 60 * 60 * 24 * 1000), `yyyy-MM-dd `).replace(/-/g, '/')
          .toString();

        let endShift = this.datePipe
          .transform(shiftDate + ' ' + shifts.end, `yyyy-MM-dd HH:mm Z`)
          .toString();

        if (shifts.end < shifts.start) {
          endShift = this.datePipe
            .transform(shiftDateTomorrow + ' ' + shifts.end, `yyyy-MM-dd HH:mm Z`)
            .toString();
        }


        for (let i = 0; i < shifts.dayNumber; i++) {
          const create: CreateNewShift = {
            groupId: Number(localStorage.getItem(AuthConst.gruopId)),
            start: this.datePipe
              .transform(shiftDate + ' ' + shifts.start, `yyyy-MM-dd HH:mm Z`)
              .toString(),
            end: endShift,
            positionId: shifts.positionId,
            activity1Id: shifts.activityId,
            activity2Id: shifts?.activity2Id,
            competence1Id: shifts.competenceId,
            competence2Id: null,
            competence3Id: null,
            employerNote: shifts.disclosedNote,
            description: shifts.undisclosedNote,
            status: 1,
            rowStatus: 'ACTIVE',
            externalId: null,
            staffing: [],
            importFileId: null,
            assignedToId: null,
            createdById: Number(localStorage.getItem(AuthConst.userId)),
          };

          !shifts.competenceId || shifts.competenceId === 0 || shifts.competenceId == undefined
            ? (create.competence1Id = null)
            : (create.competence1Id = shifts.competenceId);

          shifts.start2 !== '' && shifts.start2 != undefined
            ? (create.activity2Start = this.datePipe
              .transform(shiftDate + ' ' + shifts.start2, `yyyy-MM-dd HH:mm Z`)
              .toString())
            : (create.activity2Start = null);


          shifts.end2 !== '' && shifts.end2 != undefined
            ? (create.activity2End = this.datePipe
              .transform(shiftDate + ' ' + shifts.end2, `yyyy-MM-dd HH:mm Z`)
              .toString())
            : (create.activity2End = null);

          if (shifts.start2 !== '' && shifts.start2 != undefined && shifts.end2 !== '' && shifts.end2 != undefined) {
            if (shifts.end2 < shifts.start2) {
              create.activity2End = this.datePipe
                .transform(shiftDateTomorrow + ' ' + shifts.end2, `yyyy-MM-dd HH:mm Z`)
                .toString();
            }
          }

          setTimeout(() => {
            this.shiftService.createNewShift(create).subscribe((data: any) => {
              this.saveAssignmentsToLocalStorage([data]);
              if (index >= this.shifts.length - 1) {
                this.sending = false;
              }
              localStorage.setItem(AuthConst.scheduleDate, this.shiftDate);
              localStorage.removeItem('dayview-lists');
              localStorage.removeItem('dayview-shifts');
              localStorage.removeItem('dayview-positions');
              this.dialogRef.close(this.shiftsDate);
            }, (err: any) => {
              if (index >= this.shifts.length - 1) {
                this.sending = false;
              }
            });
          }, 100);
        }
      });
    }
  }

  saveAssignmentsToLocalStorage(assignments: any): void {

    let existingAssignments: any = localStorage.getItem('assignments');

    if (existingAssignments) {
      existingAssignments = JSON.parse(existingAssignments);
    } else {
      existingAssignments = [];
    }

    assignments.forEach((a: any) => {

      const assignmentIndex = existingAssignments.findIndex((assignment: any) => {
        return assignment.id == a.id;
      });

      if (assignmentIndex != -1) {
        existingAssignments[assignmentIndex] = a;
      } else {
        existingAssignments.push(a);
      }

      try {
        localStorage.setItem('assignments', JSON.stringify(existingAssignments));
      } catch (e) {

      }

    });


  }

  getPositionColor(color: any): any {
    let colorOfShift = '';
    const indexColor = this.allShifts.findIndex(
      (findColor: any) => findColor.id === color.id
    );
    if (indexColor !== -1) {
      colorOfShift = color.color;
    }
    if (colorOfShift?.startsWith('##')) {
      return colorOfShift.substring(1);
    }
    if (colorOfShift?.startsWith('#')) {
      return colorOfShift;
    } else {
      return '#' + colorOfShift;
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
