import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessagesService } from '../../../shared/services/messages.service';
import { HelperService } from '../../../shared/services/helper.service';

@Component({
  selector: 'app-chat-modal',
  templateUrl: './chat-modal.component.html',
  styleUrls: ['./chat-modal.component.scss'],
})
export class ChatModalComponent implements OnInit {
  chat: any;

  constructor(
    public dialogRef: MatDialogRef<ChatModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private messagesService: MessagesService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.chat = this.data.person;
    setTimeout(() => {
      // @ts-ignore
      this.helperService.personChat.next(this.chat.id);
    }, 100);
  }

  backToUser(): void {
    this.dialogRef.close();
  }
}
