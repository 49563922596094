import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[clickOutside]',
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<void>();

  constructor(private elRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  // tslint:disable-next-line:typedef
  public onClick(targetElement: any) {
    const clickedInside =
      targetElement && this.elRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      // @ts-ignore
      this.clickOutside.emit(null);
    }
  }
}
