import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges,} from '@angular/core';
import {DatePipe} from '@angular/common';
import {ActivitylogsService} from '../../shared/services/activitylogs.service';
import {HelperService} from '../../shared/services/helper.service';
import {EmployeesService} from '../../shared/services/employees.service';
import {Subscription} from 'rxjs';
import {ShiftsService} from '../../shared/services/shifts.service';
import moment from 'moment';
import {SharedService} from '../../shared/services/sharedService';

@Component({
  selector: 'app-shift-calendar',
  templateUrl: './shift-calendar.component.html',
  styleUrls: ['./shift-calendar.component.scss'],
})
export class ShiftCalendarComponent implements OnInit, OnDestroy {
  @Input() hide: any;
  @Output() clickedDate = new EventEmitter();
  @Output() dataLoaded = new EventEmitter<void>();
   @Output() dataLoadSave = new EventEmitter<void>();
  data: any = null;
  weekArray: any = [];
  daysArray: any = [];
  dateTime: any = [];
  maxDay: any = 0;
  minDay: any = 0;
  userId: any = null;
  shifts: any = [];
  proba: any = [];
  activities: any = [];
  openShifts: any = [];
  drugaproba: any = [];
  shiftType: any = [];
  chosenDate: string | undefined;
  positionsSelect: any[] = [];
  selectedDate: any = [];
  show: boolean;
  groupId: number;
  selectedShift: any;
  selectedShifts: any[] = [];
  canSelect = true;
  currentMonth: any;
  fullMonth: string | undefined;

  counter = 0;
  isLoading = true;

  positionsSubscription: Subscription;
  updateShiftSubscription: Subscription;
  userSubscription: Subscription;
  defaultDateSubscription: Subscription;
  calendarSubscription: Subscription;
  hasManyShiftsSubscription: Subscription;
  canSelectSubscription: Subscription;

  constructor(
    private datePipe: DatePipe,
    private activityLogs: ActivitylogsService,
    private helper: HelperService,
    private employeesService: EmployeesService,
    private shiftService: ShiftsService,
    private sharedService: SharedService
  ) {
  }

  date: any = new Date(); // TODO return to development
  toDay = new Date().toISOString().substring(0, 10);

  ngOnInit(): void {
    this.fullMonth = this.datePipe.transform(this.date, 'MMMM');

    this.helper.openShift.subscribe((data: any) => {
      if (data) {
        this.selectedShift = JSON.parse(localStorage.getItem('selectedShift'));
        this.groupId = Number(localStorage.getItem('gruopId'));
        this.userSubscription = this.helper.userId$.subscribe((data) => {
          this.userId = data;
          this.getCalendarFields(this.date);
        });
        this.date = new Date(this.date.setMonth(this.date.getMonth(), 15));
        this.fullMonth = this.datePipe.transform(this.date, 'MMMM');
        this.getCalendarFields(this.date);

        this.defaultDateSubscription = this.helper.defaultDate$.subscribe(
          (back) => {
            if (back) {
              this.selectedDate = [];
              this.show = false;
            }
          }
        );
        if (localStorage.getItem('selectedDate')) {
          if (localStorage.getItem('selectedDate') != undefined) {
            this.chosenDate = this.datePipe.transform(
              localStorage.getItem('selectedDate'),
              'yyyy-MM-dd'
            );
          }
        } else {
          this.chosenDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        }

        this.calendarSubscription = this.helper.calendar$.subscribe((show) => {
          this.show = show;
        });

        this.hasManyShiftsSubscription = this.helper.hasManyShifts$.subscribe(
          (data) => {
            if (data) {
              this.selectedShifts = data;
            }
          }
        );

        this.canSelectSubscription = this.helper.canSelect$.subscribe(
          (data) => {
            if (data != undefined && data != null) {
              this.canSelect = data;
            }
          }
        );

        this.updateShiftSubscription = this.helper.updateShiftsType$.subscribe(
          (shift) => {
            this.shiftType = shift;
            let date = this.datePipe.transform(
              new Date(shift.start),
              'yyyy-MM-dd'
            );
            const foundDateIndex = this.shifts?.findIndex(
              (s: any) => s.day == date
            );
            if (foundDateIndex && foundDateIndex !== -1) {
              const shiftIndex = this.shifts[
                foundDateIndex
                ].assignments.findIndex((s: any) => {
                return s.id == shift.id;
              });
              if (shiftIndex !== -1) {
                // @ts-ignore
                this.shifts[foundDateIndex].assignments[shiftIndex] = shift;
                this.shifts = [...this.shifts];
              }
            }
          }
        );
      }
    });
    this.selectedShift = JSON.parse(localStorage.getItem('selectedShift'));
    this.date = new Date(this.selectedShift.date);
    this.fullMonth = this.datePipe.transform(this.date, 'MMMM');
    this.groupId = Number(localStorage.getItem('gruopId'));
    this.userSubscription = this.helper.userId$.subscribe((data) => {
      this.userId = data;
      this.getCalendarFields(this.date);
    });
    this.date = new Date(this.date.setMonth(this.date.getMonth(), 15));
    this.fullMonth = this.datePipe.transform(this.date, 'MMMM');
    this.getCalendarFields(this.date);

    this.defaultDateSubscription = this.helper.defaultDate$.subscribe(
      (back) => {
        if (back) {
          this.selectedDate = [];
          this.show = false;
        }
      }
    );
    if (localStorage.getItem('selectedDate')) {
      if (localStorage.getItem('selectedDate') != undefined) {
        this.chosenDate = this.datePipe.transform(
          localStorage.getItem('selectedDate'),
          'yyyy-MM-dd'
        );
      }
    } else {
      this.chosenDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }

    this.calendarSubscription = this.helper.calendar$.subscribe((show) => {
      this.show = show;
    });

    this.hasManyShiftsSubscription = this.helper.hasManyShifts$.subscribe(
      (data) => {
        if (data) {
          this.selectedShifts = data;
        }
      }
    );

    this.canSelectSubscription = this.helper.canSelect$.subscribe((data) => {
      if (data != undefined && data != null) {
        this.canSelect = data;
      }
    });

    this.updateShiftSubscription = this.helper.updateShiftsType$.subscribe(
      (shift) => {
        this.shiftType = shift;
        let date = this.datePipe.transform(new Date(shift.start), 'yyyy-MM-dd');
        const foundDateIndex = this.shifts?.findIndex(
          (s: any) => s.day == date
        );
        if (foundDateIndex && foundDateIndex !== -1) {
          const shiftIndex = this.shifts[foundDateIndex].assignments.findIndex(
            (s: any) => {
              return s.id == shift.id;
            }
          );
          if (shiftIndex !== -1) {
            // @ts-ignore
            this.shifts[foundDateIndex].assignments[shiftIndex] = shift;
            this.shifts = [...this.shifts];
          }
        }
      }
    );

    this.employeesService.userActvity().subscribe((x) => {
      x.forEach((group: any) => {
        if (
          group.groupId === Number(this.groupId) &&
          group.groupStatus === 'ACTIVE'
        ) {
          this.activities.push(group);
        }
      });
    });
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges): void {
    this.hideCalendar();
  }

  private hideCalendar(): void {
  }

  // tslint:disable-next-line:typedef
  public previous() {
    // tslint:disable-next-line:no-unused-expression
    this.dataLoaded.emit();
    this.date = new Date(this.date.setMonth(this.date.getMonth() - 1));
    this.fullMonth = this.datePipe.transform(this.date, 'MMMM');
    this.getCalendarFields(this.date);
    this.dataLoadSave.emit();
  }

  // tslint:disable-next-line:typedef
  public next() {
    // tslint:disable-next-line:no-unused-expression
    this.dataLoaded.emit();
    this.date = new Date(this.date.setMonth(this.date.getMonth() + 1));
    this.fullMonth = this.datePipe.transform(this.date, 'MMMM');
    this.getCalendarFields(this.date);
    this.dataLoadSave.emit();
  }

  // tslint:disable-next-line:typedef
  public getCalendarFields(date: any) {
    this.data = date;
    this.weekArray = [];
    this.daysArray = [];
    this.dateTime = [];
    this.maxDay = 0;
    this.minDay = 0;

    let firstDay: any = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay: any = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let monthStartsOnSunday = false;

    if (firstDay.getDate() == 1 && firstDay.getDay() == 0) { // if month starts on sunday
      monthStartsOnSunday = true;
    }
    this.currentMonth = firstDay.getMonth();
    const firstWeek = this.datePipe.transform(firstDay, 'w');
    const lastWeek = this.datePipe.transform(
      new Date(lastDay.setDate(lastDay.getDate() - 1)),
      'w'
    );
    this.minDay = firstDay.getMonth();
    this.maxDay = lastDay.getMonth();
    let numberBack = 0;
    let numberOf = 0;

    for (let i = 1; i < 10; i++) {
      let dates = firstDay.setDate(firstDay.getDate() - i);
      // tslint:disable-next-line:max-line-length
      if (
        this.datePipe.transform(new Date(dates), 'w') ===
        this.datePipe.transform(
          new Date(date.getFullYear(), date.getMonth(), 1),
          'w'
        )
      ) {
        numberBack = i;
        dates = firstDay.setDate(firstDay.getDate() + i);
      }
    }

    if (monthStartsOnSunday) {
      numberBack = 7;
    }

    for (let i = 1; i < 10; i++) {
      let dates = lastDay.setDate(lastDay.getDate() + i);
      // tslint:disable-next-line:max-line-length
      if (
        this.datePipe.transform(new Date(dates), 'w') ===
        this.datePipe.transform(
          new Date(date.getFullYear(), date.getMonth() + 1, 0),
          'w'
        )
      ) {
        numberOf = i;
        dates = lastDay.setDate(lastDay.getDate() - i);
      }
    }

    if (Number(firstWeek) < Number(lastWeek)) {
      this.weekArray = [];
      for (let i = Number(firstWeek); i <= Number(lastWeek); i++) {
        this.weekArray.push(i);
      }
    }
    if (Number(firstWeek) > Number(lastWeek)) {
      this.weekArray = [];
      this.weekArray.push(firstWeek);
      for (let i = 1; i <= Number(lastWeek); i++) {
        this.weekArray.push(i);
      }
    }

    firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    for (let i = numberBack - 1; i >= 1; i--) {
      const date = new Date(firstDay.setDate(firstDay.getDate() - i));
      this.dateTime.push(date);
      firstDay = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    }
    firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const allDays = this.getDates(firstDay, lastDay);

    for (const i of allDays) {
      const date = new Date(i);
      this.dateTime.push(date);
    }

    for (let i = 1; i <= numberOf + 1; i++) {
      const date = new Date(lastDay.setDate(lastDay.getDate() + i));
      if (monthStartsOnSunday) {
        if (i <= numberOf) {
          this.dateTime.push(date);
        }
      } else {
        if (this.dateTime.length / this.weekArray.length < 7) {
          this.dateTime.push(date);
        }
      }

      lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    }

    firstDay = new Date(
      this.date.getFullYear(),
      this.date.getMonth() - 1,
      -numberBack + 2
    )
      .toJSON()
      .substring(0, 10);
    lastDay = new Date(
      this.date.getFullYear(),
      this.date.getMonth() + 2,
      numberOf + 1
    )
      .toJSON()
      .substring(0, 10);

    const now = moment(date);
    const startOfMonth = now.clone().startOf('month');
    const endOfMonth = now.clone().endOf('month');

    const firstVisibleDay = startOfMonth.clone().startOf('isoWeek');
    const lastVisibleDay = endOfMonth.clone().endOf('isoWeek');

    firstDay = firstVisibleDay.format('YYYY-MM-DD');
    lastDay = lastVisibleDay.format('YYYY-MM-DD');

    console.log('First Day of Month:', firstDay); // e.g., "2023-06-01"
    console.log('Last Day of Month:', lastDay);   // e.g., "2023-06-30"

    this.activityLogs
      .getAssingmentsForAll(firstDay, lastDay)
      .subscribe((response: any) => {
        this.shifts = null;
        const groupIndex = response.groups.findIndex(
          (g: any) => g.group.id == this.groupId
        );
        if (groupIndex !== -1) {
          this.shifts = response.groups[groupIndex].dayAssignments;
          this.helper.foundDayAssignments.next(this.shifts);
        }
        this.isLoading = false;
        this.dataLoaded.emit();
      });

    this.positionsSubscription = this.employeesService
      .userPositions()
      .subscribe((x) => {
        x.forEach((group: any) => {
          if (
            group.groupId === Number(this.groupId) &&
            group.groupStatus === 'ACTIVE' &&
            group.rowStatus === 'ACTIVE'
          ) {
            this.positionsSelect.push(group);
          }
        });
      });
  }

  // tslint:disable-next-line:typedef
  public getDates(startDate: any, stopDate: any) {
    const dateArray = [];
    const currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  }

  // tslint:disable-next-line:typedef
  // @ts-ignore
  // tslint:disable-next-line:typedef

  previuosNumber(dateObj: any): void {
    if (dateObj.dayNumber > 1) {
      dateObj.dayNumber = dateObj.dayNumber - 1;
    }
  }

  nextNumber(dateObj: any): void {
    if (dateObj.dayNumber < 10) {
      dateObj.dayNumber = dateObj.dayNumber + 1;
    }
  }

  chooseDay(datem: any): void {
    this.chosenDate = this.datePipe.transform(datem, 'yyyy-MM-dd');
    this.helper.assignChangedDate.next('hide');
  }

  findOpenShifts(date: string): any[] {
    date = this.datePipe.transform(date, 'yyyy-MM-dd');
    const foundDateIndex = this.shifts?.findIndex((s: any) => s.day == date);
    if (foundDateIndex !== -1) {
      this.openShifts = this.shifts[foundDateIndex].assignments.filter(
        (a: any) => {
          return a.status === 'PUBLISHED';
        }
      );
    } else {
      this.openShifts = [];
    }
    return this.openShifts;
  }

  distinctPositions(shifts: any[]): any[] {
    return [...new Set(shifts.map((item) => item.position))].sort(
      (n1, n2) => n1 - n2
    );
  }

  getColor(shift: any): string {
    const index = this.selectedShifts.findIndex((x: any) => {
      return x.id == shift.id;
    });

    if (index !== -1) {
      return '#E67E22';
    }

    if (shift.positionColor.startsWith('#')) {
      return shift.positionColor;
    } else {
      return '#' + shift.positionColor;
    }
  }

  selectShift(assignment: any): void {
    if (!this.checkIfShiftOverlaps(assignment)) {
      if (this.canSelect) {
        const index = this.selectedShifts.findIndex(
          (s: any) => s.id == assignment.id
        );

        if (index === -1) {
          this.selectedShifts.push(assignment);
        } else {
          this.selectedShifts.splice(index, 1);
        }

        this.selectedShift = null;

        this.sharedService.setShifts(this.selectedShifts);

        this.helper.hasManyShifts.next(this.selectedShifts);
      }
    }
  }

  isChecked(assignment: any): boolean {
    const index = this.selectedShifts.findIndex(
      (s: any) => s.id == assignment.id
    );
    return index !== -1;
  }

  ifSameDate(date: string): boolean {
    return (
      this.datePipe.transform(date, 'yyyy-MM-dd') ==
      this.datePipe.transform(this.chosenDate, 'yyyy-MM-dd')
    );
  }

  checkIfShiftOverlaps(a: any): boolean {
    let overlaps = false;

    this.selectedShifts.forEach((b) => {
      if (
        (((new Date(a.start).getTime() <= new Date(b.start).getTime() &&
          new Date(b.start).getTime() <= new Date(a.end).getTime()) ||
          (new Date(a.start).getTime() <= new Date(b.end).getTime() &&
            new Date(b.end).getTime() <= new Date(a.end).getTime()) ||
          (new Date(b.start).getTime() < new Date(a.start).getTime() &&
            new Date(a.end).getTime() < new Date(b.end).getTime())) &&
          b.id != a.id) || a.position != b.position
      ) {
        overlaps = true;
      }
    });
    return overlaps;
  }

  getShift(person: any): void {
    if (this.selectedShift?.id != person.id) {
      const shiftObj = {
        id: person.id,
        name: '',
        groupId: person.groupId,
        start: this.datePipe.transform(person.start.replace(/-/g, '/'), 'HH:mm').toString(),
        end: this.datePipe.transform(person.end.replace(/-/g, '/'), 'HH:mm').toString(),
        positionId: person.position,
        activityId: person.activity1Id,
        competenceId: person.competence1Id,
        undisclosedNote: person.undisclosedNote,
        disclosedNote: person.disclosedNote,
        activity2Id: person.activity2Id,
        start2: person.activity2Start
          ? this.datePipe.transform(person.activity2Start.replace(/-/g, '/'), 'HH:mm').toString()
          : '',
        end2: person.activity2End
          ? this.datePipe.transform(person.activity2End.replace(/-/g, '/'), 'HH:mm').toString()
          : '',
        color: person.positionColor,
        clinical: person.clinical,
        applicationsCount: person.applications,
        nrOfAppliedApplications: person.nrOfAppliedApplications,
        type: 2,
        status: person.status,
        date: this.datePipe.transform(person.start.replace(/-/g, '/'), 'yyyy-MM-dd').toString(),
      };
      this.selectedShifts = [];
      this.helper.hasManyShifts.next(null);
      localStorage.setItem('selectedShift', JSON.stringify(shiftObj));
      this.selectedShift = person;
      this.helper.shiftCalendarShift.next(shiftObj);
      this.shiftService
        .getApplications(person.id)
        .subscribe((data: any[]) => {
          this.helper.getApplications.next(data);
        });

      this.shiftService
        .getInvitations(person.id)
        .subscribe((data: any[]) => {
          this.helper.getInvitations.next(data);
        });
    } else {
      this.selectedShift = null;
      this.selectedShifts = [];
      this.helper.hasManyShifts.next(this.selectedShifts);
    }
    this.helper.assignChangedDate.next('show');
  }

  getPositionName(shift: { position: any }): string {
    let shortName = '';
    this.positionsSelect.forEach((person) => {
      if (person.id === shift.position) {
        if (person.shortTitle) {
          shortName = person.shortTitle;
        }
      }
    });
    return shortName;
  }

  getActivityShortName(activityName: any): string {
    let shortName = '';
    this.activities.forEach((activity: any) => {
      if (activity.id === activityName.activity1Id) {
        if (activity.id) {
          if (activity.shortTitle !== null) {
            shortName = activity.title + ' (' + activity.shortTitle + ')';
          } else {
            shortName = activity.title;
          }
        }
      }
    });
    return shortName;
  }

  getDropdownTIme(shifts: any): string {
    let startTime = '';
    let endTime = '';
    this.positionsSelect.forEach((person) => {
      if (person.id === shifts.position) {
        if (person.id) {
          startTime = this.datePipe.transform(shifts.start.replace(/-/g, '/'), 'HH:mm');
          endTime = this.datePipe.transform(shifts.end.replace(/-/g, '/'), 'HH:mm');
        }
      }
    });
    return startTime + ' - ' + endTime;
  }

  getPositionColor(color: string): string {
    if (color.startsWith('#')) {
      return color;
    } else {
      return '#' + color;
    }
  }

  clickOnShift(a: any): void {
    a.date = new Date(a.start.replace(/-/g, '/'));
    a.positionId = a.position;
    a.activityId = a.activity1Id;
    a.competenceId = a.competence1Id;
    a.type = 2;
    a.start = this.datePipe.transform(a.start.replace(/-/g, '/'), 'HH:mm').toString();
    a.end = this.datePipe.transform(a.end.replace(/-/g, '/'), 'HH:mm').toString();
    localStorage.setItem('selectedShift', JSON.stringify(a));
    this.helper.openShift.next(a);
  }

  ngOnDestroy(): void {
    this.positionsSubscription?.unsubscribe();
    this.updateShiftSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
    this.defaultDateSubscription?.unsubscribe();
    this.calendarSubscription?.unsubscribe();
    this.hasManyShiftsSubscription?.unsubscribe();
    this.canSelectSubscription?.unsubscribe();
  }
}
