<div class="calendar" style="position: relative">
  <app-loading class="spinner overlay" *ngIf="isLoading"></app-loading>
  <div class="date">
    <div class="change">
      <div (click)="previous()" style="cursor: pointer">
        <img src="assets/images/schedule/Arrow_Sort left.svg" alt="">
      </div>
      <h3 style="margin: 0 25px 16px 25px;"> {{fullMonth | titlecase}} {{date.toDateString().slice(10, 16)}} </h3>
      <div style="cursor: pointer" (click)="next()">
        <img src="assets/images/schedule/Arrow_Sort right.svg" alt="">
      </div>
    </div>
    <table class="table" style="width:100%">
      <thead style="width:100%">
      <tr style="width:100%; display:flex; justify-content: space-between">
        <th scope="col">Mån</th>
        <th scope="col">Tis</th>
        <th scope="col">Ons</th>
        <th scope="col">Tor</th>
        <th scope="col">Fre</th>
        <th scope="col">Lör</th>
        <th scope="col">Sön</th>
      </tr>
      </thead>
      <tbody>

      </tbody>
    </table>
  </div>
  <div class="wrap">
    <div class="box" [style.background]=geColor(datem) *ngFor="let datem of dateTime"
         (click)="chooseDay(datem, dayNumberCalendar)"
         [ngClass]="{'borderedDate': shouldHaveBorder(datem)}"
         [style.color]="geColor(datem) ? '#fff' : '#333333'">
      <div class="border">
        <div class="date-number" [ngClass]="{'silver' : datem.getMonth() != correctMonth }">
          <div class="date-number-outline"
               [ngClass]="{ 'selected_number' : toDay.substring(0,4) == datem.toISOString().substring(0, 4) &&  toDay.substring(5,7) == datem.toISOString().substring(5, 7) && toDay.substring(8,10) == datem.toString().substring(8, 10) }">
            <div >
              {{datem.getDate()}}
            </div>
          </div>
        </div>
      </div>
      <div class="for-circles" *ngIf="datem != chosenDate && !isSelected(datem)">
        <div *ngFor="let p of distinctPositions(findOpenShifts(datem))" class="position-row">
          <div class="shifts-circles">
            <div *ngFor="let shift of findOpenShifts(datem); let index=index">
              <div class="shifts-circle" *ngIf="shift.position == p && index <= 4"
                   [style.backgroundColor]="getColor(shift.positionColor)">
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngFor="let selDate of filterSelectedDates(datem)">
        <div class="selected-date" *ngIf="shouldHaveNumberField(datem, selDate.date)">
          <figure class="selected-date__image-box">
            <img (click)="previousNumber(selDate)" class='selected-date__image'
                 src="../../assets/images/calendar/Arrow_Sort%20left.svg"/>
          </figure>
          <div class="selected-date__number">{{selDate.dayNumber}}</div>
          <figure class="selected-date__image-box">
            <img (click)="nextNumber(selDate)" class='selected-date__image'
                 src="../../assets/images/calendar/Arrow_Sort%20right.svg"/>
          </figure>
        </div>
      </ng-container>
    </div>
    <div class="calendar-dropdown">
      <div class="calendar-dropdown__link" *ngFor="let shifts of getClickedShifts" (click)="getShift(shifts)">
        <div class="calendar-dropdown__activity">
          <div class="calendar-dropdown__circle" [style.background-color]="getPositionColor(shifts)"></div>
          <div>{{getActivityShortName(shifts)}}</div>
          <div class="calendar-dropdown__time">{{getDropdownTIme(shifts)}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
