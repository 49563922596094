import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AuthConst } from '../../shared/services/const/authConst';
import { ShiftsService } from '../../shared/services/shifts.service';
import { TooltipShiftsModalsComponent } from '../modals/tooltip-shifts-modals/tooltip-shifts-modals.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../shared/services/helper.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-shift-types',
  templateUrl: './shift-types.component.html',
  styleUrls: ['./shift-types.component.scss'],
})
export class ShiftTypesComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line:no-output-native
  @Output() close = new EventEmitter();
  assignments: any = [];
  assignment: any;
  mode = 0;
  edit: string | undefined;

  backToEditShiftPageSubscription: Subscription;
  updateShiftSubscription: Subscription;

  constructor(
    private shiftsService: ShiftsService,
    private dialog: MatDialog,
    public translate: TranslateService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.backToEditShiftPageSubscription = this.helperService.backToEditShiftPage$.subscribe(
      (x) => {
        if (x) {
          this.mode = 0;
        }
      }
    );
    const groupId = Number(localStorage.getItem(AuthConst.gruopId));
    this.shiftsService.allAssignmentTypes(groupId).subscribe((x) => {
      this.assignments = x;

      // tslint:disable-next-line:only-arrow-functions typedef
      this.assignments.sort(
        (name1: { name: string }, name2: { name: string }) =>
          name1.name.localeCompare(name2.name)
      );
      // tslint:disable-next-line:only-arrow-functions typedef
      this.assignments.sort((a: { start: number }, b: { start: number }) =>
        a.start !== b.start ? (a.start < b.start ? -1 : 1) : 0
      );
    });

    this.updateShiftSubscription = this.helperService.updateShift$.subscribe(
      (shift) => {
        if (shift) {
          // @ts-ignore
          this.assignments.forEach((y) => {
            if (y.id === shift.id) {
              y.activity2Id = shift.activity2Id;
              y.activityId = shift.activityId;
              y.color = shift.color;
              y.competenceId = shift.competenceId;
              y.disclosedNote = shift.disclosedNote;
              y.end = shift.end;
              y.end2 = shift.end2;
              y.groupId = shift.groupId;
              y.id = shift.id;
              y.name = shift.name;
              y.positionId = shift.positionId;
              y.start = shift.start;
              y.start2 = shift.start2;
              y.undisclosedNote = shift.undisclosedNote;
            }
          });
        }
      }
    );
  }

  getAssignment(assignment: any): void {
    this.mode = 1;
    this.assignment = assignment;
    this.edit = 'edit-shift-type';
  }

  openDeleteModal(assignment: any): void {
    const confirmDialog = this.dialog.open(TooltipShiftsModalsComponent, {
      disableClose: true,
      width: '290px',
      height: '190px',
      panelClass: 'defaultDialog',
      position: {
        top: '150px',
      },
      data: {
        person: assignment,
        title: this.translate.instant('daleteType'),
        message: this.translate.instant('daleteTypeText'),
        mode: 'delete-shift',
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result && result !== false) {
        const findCurrentDateIndex = this.assignments.findIndex((x: any) => {
          return x.id === result;
        });
        if (findCurrentDateIndex === -1) {
        } else {
          this.assignments.splice(findCurrentDateIndex, 1);
        }
      }
    });
  }

  closeModal(): void {
    this.close.emit();
    setTimeout(() => {
      this.helperService.showCalendar2$.next(true);
    }, 100);
  }

  getBack(): void {
    this.mode = 0;
  }

  ngOnDestroy(): void {
    this.backToEditShiftPageSubscription?.unsubscribe();
    this.updateShiftSubscription?.unsubscribe();
  }

  getPositionColor(color: any): any {
    let colorOfShift = '';
    const indexColor = this.assignments.findIndex(
      (findColor: any) => findColor.id === color.id
    );

    if (indexColor !== -1) {
      colorOfShift = color.color;
    }


    if (colorOfShift?.startsWith('##')) {
      return colorOfShift.substring(1);
    }
    if (colorOfShift?.startsWith('#')) {
      return colorOfShift;
    } else {
      return '#' + colorOfShift;
    }
  }
}
