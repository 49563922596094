import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShiftsService } from '../../../shared/services/shifts.service';
import { CancelReasonsModel } from '../../../shared/models/cancel-reasons.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cancel-tooltip-modal',
  templateUrl: './cancel-tooltip-modal.component.html',
  styleUrls: ['./cancel-tooltip-modal.component.scss'],
})
export class CancelTooltipModalComponent implements OnInit {
  reasons: Array<CancelReasonsModel> = [];
  openReasons = false;
  reasonTitle: string | undefined;
  cancelReasonId: number | undefined;
  checked = false;
  role = "";
  publicNote = "";
  privateNote = "";

  constructor(
    public dialogRef: MatDialogRef<CancelTooltipModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shiftsService: ShiftsService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.role = localStorage.getItem('gapUserRole');
    this.shiftsService
      .getActiveCancelReasons()
      .subscribe((reasons: CancelReasonsModel[]) => {
        reasons.forEach((x: any) => {
          if (x.groupId === this.data.person.groupId) {
            this.reasons.push(x);
          }
        });
      });
  }

  openDropdown(): void {
    this.openReasons = !this.openReasons;
  }

  getReasons(reason: CancelReasonsModel): void {
    this.reasonTitle = reason.title;
    this.cancelReasonId = reason.id;
  }

  checkBoxCheck(): void {
    if (this.checked === false) {
      this.checked = true;
    } else {
      this.checked = false;
    }
  }

  cancelAssignment(): void {
    const publicNote = this.data.person?.employerNote;
    const privateNote = this.data.person?.disclosedNote;
    if (this.cancelReasonId === undefined) {
      this.snackBar.open(
        this.translate.instant('PleaseSelectCancelationReason')
      );
      return;
    }
    if (this.data.toCancel !== false) {
      const cancel = {
        assignmentId: this.data.person.id,
        cancelReasonId: this.cancelReasonId,
        employerNote: publicNote,
        disclosedNote: privateNote,
      };

      this.shiftsService.cancelAssignment(cancel).subscribe(() => {
        if (this.checked !== false) {
          const sendCancel = {
            assignmentId: cancel.assignmentId,
            cancelReasonId: cancel.cancelReasonId,
            disclosedNote: cancel.disclosedNote,
            employerNote: cancel.employerNote,
            checkBox: true,
          };
          this.dialogRef.close(sendCancel);
        } else {
          this.dialogRef.close(cancel);
        }
      });
    } else {
      const cancel = {
        cancelReasonId: this.cancelReasonId,
        employerNote: publicNote,
        disclosedNote: privateNote,
        checked: this.checked
      };
      this.dialogRef.close(cancel);
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
