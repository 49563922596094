import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { User } from '../models/user.model';
import { shareReplay, tap, map, debounceTime } from 'rxjs/operators';
import {group} from "@angular/animations";

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) {}

  private userSubject = new Subject<User>();
  user$: Observable<User> = this.userSubject.asObservable();

  apiUrl: string = environment.apiURL;

  getLoggedInUser(): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/user/get`).pipe(
      tap((user) => this.userSubject.next(user)),
      shareReplay()
    );
  }

  getEmployeeById(userId: number): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/user/get/${userId}`).pipe(
      // map((user) => user),
      shareReplay()
    );
  }

  getIfEmployeeLoggedIn(userId: number): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/api/v1/user/if_logged/${userId}`).pipe(
      // map((user) => user),
      shareReplay()
    );
  }


canUserSendMessage(groupId: number): Observable<any> {
  return this.http.get<User>(`${this.apiUrl}/user/can/message/${groupId}`).pipe(
    shareReplay()
  );
}

  sendLoginInformation(user: any): any {
    return this.http.post(this.apiUrl + "/user/login-information/send", user);
  }

  getUserGroups(userId: number): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/user/${userId}/custom-groups`)
      .pipe(
        map((group) => group),
        shareReplay()
      );
  }
  updateGroup(changes: any): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/custom-group/user/${changes.id}/group/${changes.groupId}/status/${changes.status}/changeStatus`,
        changes
      )
      .pipe(shareReplay());
  }

  createAbsence(absence: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrl}/api/v2/availability`, absence)
      .pipe(shareReplay());
  }

  createMultiAbsence(absences: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrl}/user/multi/unavailabilities`, absences)
      .pipe(shareReplay());
  }

  deleteUser(userId: number): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/api/v1/employer/user/` + userId)
      .pipe(shareReplay());
  }

  updateUserAuthorities(authorities: any, userId: number): any {
    return this.http
      .post(this.apiUrl + '/user/authorities/update/' + userId, authorities)
      .pipe(shareReplay());
  }

  getUnreadCount(userId: any, groupId: any): any {
    return this.http.get(this.apiUrl + "/messages/group/" + groupId + "/user/" + userId + "/unread");
  }
}
