import { Directive, ElementRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[scrollToBottom]',
})
export class ScrollToBottomDirective {
  // tslint:disable-next-line:variable-name
  constructor(private _el: ElementRef) {}

  // tslint:disable-next-line:typedef
  public scrollToBottom() {
    const el: HTMLDivElement = this._el.nativeElement;
    el.scrollTop = Math.max(0, el.scrollHeight - el.offsetHeight);
  }
}
