<div class="main-container">
  <div class="section-header">
    <div class="section-header__title-box">
      <div class="section-header__title">
        {{'dashboard' | translate}}
      </div>
    </div>
    <div class="section-header__button-box">

    </div>
  </div>
  <div class="for-blocks">
    <div class="data-block">
      <div class="data-title">{{'usersTitle' | translate}}</div>
      <div class="data-minor-title">{{'datum' | translate}}</div>
      <div class="data-subtitle">{{'usersSubtitle' | translate}}</div>
      <div class="date-div" (click)="userDate.open()">
        {{usersDate | date : "yyyy-MM-dd"}}
        <input class="invisible-input" [matDatepicker]="userDate" [(ngModel)]="usersDate"
               (ngModelChange)="changedUsersDate($event)">
      </div>
      <mat-datepicker #userDate></mat-datepicker>
      <table class="block-data">

        <tr class="block-row">
          <td class="title-field">
            {{'totalActiveUsers' | translate}}
          </td>
          <td>
            {{userData?.userCount}}
          </td>
        </tr>

        <tr class="block-row">
          <td class="title-field">
            {{'nurses' | translate}}
          </td>
          <td>
            {{userData?.sskCount}}
          </td>
        </tr>


        <tr class="block-row">
          <td class="title-field">
            {{'assistantNurses' | translate}}
          </td>
          <td>
            {{userData?.uskCount}}
          </td>
        </tr>

        <tr class="block-row">
          <td class="title-field">
            {{'position1' | translate}}*
          </td>
          <td>
            {{userData?.fullTimeCount}} ({{userData?.fullTimePercentage}}%)
          </td>
        </tr>

        <tr class="block-row">
          <td class="title-field">
            {{'position2' | translate}}*
          </td>
          <td>
            {{userData?.partTimeCount}} ({{userData?.partTimePercentage}}%)
          </td>
        </tr>

        <tr class="block-row">
          <td class="title-field">
            {{'external' | translate}}*
          </td>
          <td>
            {{userData?.externalCount}} ({{userData?.externalPercentage}})
          </td>
        </tr>

        <tr class="block-row">
          <td class="title-field">
            {{'activityInternal' | translate}}**
          </td>
          <td>
            {{userData?.internalEngagement}}%
          </td>
        </tr>

        <tr class="block-row">
          <td class="title-field">
            {{'activityExternal' | translate}}**
          </td>
          <td>
            {{userData?.externalPercentage}}%
          </td>
        </tr>

      </table>

      <div class="sub-note">
        *{{'shareOftotalContractTypes' | translate}} <br>
        <div style="height: 5px"></div>
        **{{'usersThatLoggedIn' | translate}}
      </div>

    </div>
    <div class="data-block">
      <div class="data-title">{{'activity' | translate}}</div>
      <div class="data-minor-title">{{'dateInterval' | translate}}</div>
      <div class="data-subtitle">{{'activitySubtitle' | translate}}</div>
      <div class="for-dates">
        <div class="date-div" (click)="activityStart.open()">
          {{activityStartDate | date : "yyyy-MM-dd"}}
          <input class="invisible-input" [matDatepicker]="activityStart" [(ngModel)]="activityStartDate"
                 (ngModelChange)="changedActivityStart($event)">
        </div>
        <mat-datepicker #activityStart></mat-datepicker>

        <div class="date-div" (click)="activityEnd.open()">
          {{activityEndDate | date : "yyyy-MM-dd"}}
          <input class="invisible-input" [matDatepicker]="activityEnd" [(ngModel)]="activityEndDate"
                 (ngModelChange)="changedActivityEnd($event)">
        </div>
        <mat-datepicker #activityEnd></mat-datepicker>
      </div>

      <table class="block-data">

        <tr class="block-row">
          <td class="title-field">
            {{'openShifts' | translate}}
          </td>
          <td>
            {{activityData?.openShiftsCount}}
          </td>
        </tr>

        <tr class="block-row">
          <td class="title-field">
            {{'assignedShifts' | translate}}
          </td>
          <td>
            {{activityData?.assignedShiftsCount}}
          </td>
        </tr>

        <tr class="block-row">
          <td class="title-field">
            {{'cancelledShifts' | translate}}
          </td>
          <td>
            {{activityData?.cancelledShiftsCount}}
          </td>
        </tr>

        <tr class="block-row">
          <td class="title-field">
            {{'temporaryShifts' | translate}}
          </td>
          <td>
            {{activityData?.temporaryShiftsCount}}
          </td>
        </tr>

        <tr class="block-row">
          <td class="title-field">
            {{'swaps' | translate}}*
          </td>
          <td>
            {{activityData?.swapsCount}} ({{activityData?.swapsPercentage}}%)
          </td>
        </tr>

        <tr class="block-row">
          <td class="title-field">
            {{'invitationsSent' | translate}}**
          </td>
          <td>
            {{activityData?.invitesCount}} ({{activityData?.invitesPercentage}}%)
          </td>
        </tr>

        <tr class="block-row">
          <td class="title-field">
            {{'external' | translate}}**
          </td>
          <td>
            {{activityData?.externalCount}} ({{activityData?.externalPercentage}}%)
          </td>
        </tr>

        <tr class="block-row">
          <td class="title-field">
            {{'requestsSent' | translate}}**
          </td>
          <td>
            {{activityData?.requestsCount}} ({{activityData?.requestsPercentage}}%)
          </td>
        </tr>
      </table>

      <div class="sub-note">
        *{{'shareOfSwaps' | translate}}
        <div style="height: 5px"></div>
        **{{'shareOfShiftsAssigned' | translate}}
      </div>

    </div>
    <div class="forms-dash data-block">
      <div class="data-title">{{'graph' | translate}}</div>
      <div class="data-minor-title">{{'coming' | translate}}</div>
      <div class="data-subtitle">{{'feature' | translate}}</div>
      <div class="textarea-left">
        <textarea name="" id="" cols="30" rows="10" style="resize: none" disabled></textarea>
      </div>

      <div class="three_forms">
        <input type="text" class="first" placeholder="{{'assis' |translate}}">
        <input type="text" class="secound" placeholder="YY-MM-DD">
        <input type="text" class="secound" placeholder="YY-MM-DD">
      </div>
    </div>
  </div>
</div>

