<div class="colorForBackground">
  <div class="padding-for-dialog">
    <img _ngcontent-gfy-c97="" src="../../../../assets/images/Close.png" (click)="closeModal()" class="create-user__image locationX">

    <p class="send_group">{{'sendPasswordTitle' | translate}}</p>
    <p class="add_text">
      <span class="text_after">{{'sendPasswordDescription' | translate}} </span>
    </p>

    <div class="groups_buttons">

      <button class="group_cancel" (click)="closeModal()">{{"cancelButton" | translate}}</button>
      <button class="delete_group" (click)="saveAndUpdate()" >{{"confirmed" | translate}}</button>
    </div>
  </div>
</div>
