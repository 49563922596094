/** Adapts the native JS Date for use with cdk-based components that work with dates. */
import {NativeDateAdapter} from "@angular/material/core";


export class CustomDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }

}
