<div class="colorForBackground">
  <div class="padding-for-dialog">
    <img _ngcontent-gfy-c97="" src="../../../../assets/images/Close.png" (click)="cancel()" class="create-user__image locationX">
    <p class="send_message">{{"addToGroup" | translate}}</p>
    <p class="add_text">
      {{"selectGroup" | translate}} <br>
      <span class="text_after">  {{"addUserToGroupText" | translate}}</span>
    </p>
    <div class="for-type-select  right-select relative-select " (clickOutside)="openActions = false;">
      <div class="section-select__select-box" (click)="toOpenActions()" >
        <div class="section-select__select padd-title  section-select__value specicial_input" style="width: 250px !important;" >
              <span [ngClass]="{'disabled-select': allChecks.length == 0}"  *ngIf="idSlectGroup == null">
               {{'select' | translate}}
              </span>
               <span   *ngIf="idSlectGroup != null">
               {{ idSlectGroup.name | translate}}
              </span>
        </div>
      </div>
      <div class="form__select groupSelect form__select_two position_for_search_status list_values" *ngIf="openActions">
        <div class="form__select-option between" *ngFor="let a of allChecks"   (click)="selectGroups(a)">
           <span> {{a.name}} </span>  <span class="material-icons checkSelect" *ngIf="checkSelect == a.id">done</span>
        </div>
      </div>
    </div>

    <div class="message_buttons">
      <button class="message_cancel" (click)="cancel()"> {{"cancelButton" | translate}}</button>
      <button class="send_messages" (click)="send()"> {{"save" | translate}}</button>
    </div>
  </div>
</div>
