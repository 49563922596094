import { Component, OnInit } from '@angular/core';
import { EmployeesService } from '../../shared/services/employees.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../shared/services/user.service';

@Component({
  selector: 'app-user-details-update',
  templateUrl: './user-details-update.component.html',
  styleUrls: ['./user-details-update.component.scss'],
})
export class UserDetailsUpdateComponent implements OnInit {
  updateEmployer: FormGroup = this.fb.group({
    firstname: [''],
    lastname: [''],
    email: [''],
    mobile: [''],
    note: [''],
    position: [''],
    competence: [''],
    contract: [''],
    status: [''],
    role: [''],
  });

  showCompetence = false;
  showPositions = false;
  showContract = false;
  showRoll = false;
  showStatus = false;
  positions$?: Observable<any>;
  position: string | undefined;
  competences$?: Observable<any>;
  competence: string | undefined;
  contracts$?: Observable<any>;
  contract: string | undefined;
  userId: number | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  status: string | undefined;
  role?: any;
  positionId: number | undefined;
  userPosition: any;
  userCompetence: any;
  userContract: any;
  groupId = 0;

  statusSelect = [
    {
      id: 1,
      status: 'ACTIVE',
    },
    {
      id: 2,
      status: 'INACTIVE',
    },
  ];

  rolls = [
    {
      id: 1,
      roll: 'EMPLOYEE',
    },
    {
      id: 2,
      roll: 'EMPLOYER',
    },
    {
      id: 3,
      roll: 'ROSTER',
    },
    {
      id: 4,
      roll: 'ADMIN',
    },
  ];

  constructor(
    private employeesService: EmployeesService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userId = Number(this.activatedRoute.snapshot.paramMap.get('id'));

    if (this.userId) {
      this.userService.getEmployeeById(this.userId).subscribe((x) => {
        this.firstName = x.firstName;
        this.lastName = x.lastName;
        this.status = x.rowStatus;

        // @ts-ignore
        this.userRole = Object.keys(x.userAuthorities?.roles);
        // @ts-ignore

        // tslint:disable-next-line:no-shadowed-variable
        this.rolls.find((x) => {
          // @ts-ignore
          if (x.roll === this.userRole[0]) {
            this.updateEmployer.patchValue({
              role: x.roll,
            });
          }
        });

        /**
         * OOBJECT FOR POSITIONS API
         */
        this.positions$ = this.employeesService.userPositions();
        // tslint:disable-next-line:no-shadowed-variable
        this.positions$.subscribe((x) => {
          const positions = x;
          this.userPosition = positions.filter(
            // tslint:disable-next-line:no-shadowed-variable
            (x: { id: number | undefined }) => x.id === this.positionId
          );
          // this.updateEmployer.patchValue({
          //   position: this.userPosition[0].title,
          // });
        });

        this.competences$ = this.employeesService.userCompetences();
        // tslint:disable-next-line:no-shadowed-variable
        this.competences$.subscribe((x) => {
          const competences = x;
          this.userCompetence = competences.filter(
            // tslint:disable-next-line:no-shadowed-variable
            (x: { id: number | undefined }) => x.id === this.positionId
          );
          // this.updateEmployer.patchValue({
          //   competence: this.userCompetence[0].title,
          // });
        });

        this.contracts$ = this.employeesService.userContracts();
        // tslint:disable-next-line:no-shadowed-variable
        this.contracts$.subscribe((x) => {
          const contracts = x;
          this.userContract = contracts.filter(
            // tslint:disable-next-line:no-shadowed-variable
            (x: { id: number | undefined }) => x.id === this.positionId
          );
          // this.updateEmployer.patchValue({
          //   contract: this.userContract[0].title,
          // });
        });

        if (x.userAuthorities?.roles.EMPLOYEE) {
          this.role = x.userAuthorities?.roles.EMPLOYEE;

          this.positionId = this.role[0].position.positionId;
          const status = this.role[0].status;
          // tslint:disable-next-line:no-shadowed-variable
          this.statusSelect.find((x) => {
            if (x.status === status) {
              this.updateEmployer.patchValue({
                status: x.status,
              });
            }
          });
        } else if (x.userAuthorities?.roles.EMPLOYER) {
          this.role = x.userAuthorities?.roles.EMPLOYER;
        } else if (x.userAuthorities?.roles.ADMIN) {
          this.role = x.userAuthorities?.roles.ADMIN;
        } else if (x.userAuthorities?.roles.SUPER_ADMIN) {
          this.role = x.userAuthorities?.roles.SUPER_ADMIN;
        } else if (x.userAuthorities?.roles.ROSTER) {
          this.role = x.userAuthorities?.roles.ROSTER;
        }

        this.updateEmployer.patchValue({
          firstname: x.firstName,
          lastname: x.lastName,
          email: x.email,
          mobile: x.tel1,
        });
      });
    } else {
      // this.positions$ = this.employeesService.userPositions(positionsApi);
      this.competences$ = this.employeesService.userCompetences();
      this.contracts$ = this.employeesService.userContracts();
    }
  }

  /**
   * Display Competence dropdown
   */
  displayCompetence(): void {
    this.showCompetence = !this.showCompetence;
  }

  /**
   * Display Positions dropdown
   */
  displayPositions(): void {
    this.showPositions = !this.showPositions;
  }

  /**
   * Display Contract dropdown
   */
  displayContract(): void {
    this.showContract = !this.showContract;
  }

  /**
   * Display Roll dropdown
   */
  displayRoll(): void {
    this.showRoll = !this.showRoll;
  }

  /**
   * Display Status dropdown
   */
  displayStatus(): void {
    this.showStatus = !this.showStatus;
  }

  getPosition(position: string): void {
    this.updateEmployer.patchValue({
      position,
    });
  }

  getCompetence(competence: string): void {
    this.updateEmployer.patchValue({
      competence,
    });
  }

  getContract(contract: string): void {
    this.updateEmployer.patchValue({
      contract,
    });
  }

  getStatus(status: { id: number; status: string }): void {
    this.updateEmployer.patchValue({
      status: status.status,
    });
  }

  getRoll(roll: { roll: string; id: number }): void {
    this.updateEmployer.patchValue({
      role: roll.roll,
    });
  }

  create(): void {
    const create = {
      firstName: this.updateEmployer.value.firstname,
      lastName: this.updateEmployer.value.lastname,
      email: this.updateEmployer.value.email,
      tel1: this.updateEmployer.value.mobile,
      note: this.updateEmployer.value.note,
      position: this.updateEmployer.value.position,
      competence: this.updateEmployer.value.competence,
      contract: this.updateEmployer.value.contract,
      status: this.updateEmployer.value.status,
      // role: this.updateEmployer.value.role,
      id: this.userId,
      roles: [this.updateEmployer.value.role],
    };
    this.employeesService.updateUser(create).subscribe();
  }
}
