<div class="padding20">
  <div class="inputs">
    <div class="for-type-select" (clickOutside)="openType = false;">
      <div class="section-select__select-box" (click)="openType = true;">
        <div class="section-select__select add_padding section-select__value">
          {{'sortBy' + firstSortName |translate }}
        </div>
      </div>
      <div class="form__select form__select_two position_for_search"  *ngIf="openType">
        <div class="form__select-option between" *ngFor="let s of sort"
            (click)="sortValues(s.id)">
          <span>{{'sortBy' + s.name| translate}} </span> <span class="material-icons checkSelect" *ngIf="checkSelect == s.id">done</span>
        </div>
      </div>
    </div>

    <div class="for-type-select" (clickOutside)="openTypeAction = false;">
      <div class="section-select__select-box" (click)="openTypeAction = true;">
        <div class="section-select__select add_padding section-select__value"  [ngClass]="{'disabled-select': allChecks.length == 0}">
         {{firstActionName | translate}}
        </div>
      </div>
      <div class="form__select form__select_two changeOver" *ngIf="openTypeAction &&  allChecks.length != 0" >
        <div [ngStyle]="a.id == 3 && disabledFail == 1 ? {'color': ' #929090'} : {'color': ''}" class="form__select-option margin-left-lttle" *ngFor="let a of actions"
             (click)="sortActionValues(a.id)" >
          {{a.name | translate}}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="white-backgrounder" *ngIf="allGroups.length > 0">
  <table class="table-cal">
    <thead>
    <tr class="table-cal__tr-th">
      <th class="first">{{'name' | translate}}</th>
      <th class="two">{{'number of staff' | translate}}</th>
      <th class="third">{{'description' | translate}}</th>
      <th class="forth" > <mat-checkbox class="forAll"  (click)="setAll()"></mat-checkbox></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let group of allGroups" >
      <td class="first" (click)="getPersonalofGroup(group)">{{group.name}}</td >
      <td  class="two" (click)="getPersonalofGroup(group)">{{group.userCount}}</td >
      <td  class="third" (click)="getPersonalofGroup(group)" [ngStyle]="group.isDefault == 1 ? {'color': ' #929090'} : {'color': ' '}">
        <span *ngIf="group.isDefault == 1">
          Denna grupp kan inte ändras.
        </span>
        <span *ngIf="group.isDefault == 0">
          {{group.description}}
        </span>
      </td >
      <td class=" forth flexForTwo" >
        <div><input type="button" value="{{'update' | translate}}" class="table-cal__button pointer" (click)="updateOneUser(group)"  [ngStyle]="group.isDefault == 1 ? {'color': ' #929090', 'border': ' 0.5px solid #929090'} : {'color': ' '}" ></div>
        <div style="margin-left: 19px; margin-top:10px;">
          <mat-checkbox class="example-margin" *ngIf="!selectAllGroups"
                            (click)="selection(group.id)"></mat-checkbox>

          <img *ngIf="selectAllGroups" src="../assets/images/Checked box.png"/>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

</div>
<!--PAGINATION-->
<div class="for-pagination">
  <div class="for-pag">
    <div class="shop-pagination" >
      <div class="pagination-button arrow-pagination" [ngClass]="{'not-cliccable-pagination': page == 0}"
           (click)="openPage(page - 1)">
        <mat-icon>arrow_left</mat-icon>
      </div>

      <div class="pagination-button next" *ngIf="page - 1 >= 1" (click)="openPage(page - 2)">
        <!-- productPage bc productPage starts with 0  -->
        {{page - 1}}
      </div>

      <div class="pagination-button next" *ngIf="page - 1 >= 0" (click)="openPage(page - 1)">
        <!-- productPage bc productPage starts with 0  -->
        {{page}}
      </div>

      <div class="pagination-button actual-pagination" (click)="openPage(page)">
        <!-- +1 bc productPage is 0  -->
        {{page + 1}}
      </div>

      <div class="pagination-button next" *ngIf="getLastPage() - page > 1" (click)="openPage(page + 1)">
        <!-- +2 bc productPage is 0  -->
        {{page + 2}}
      </div>

      <div class="pagination-button next" *ngIf="getLastPage() - page > 2" (click)="openPage(page + 2)">
        <!-- +3 bc productPage is 0  -->
        {{page + 3}}
      </div>
      <div class="pagination-dots" *ngIf="getLastPage() - page > 4">
        <mat-icon class="pagination-dots-icon">more_horiz</mat-icon>
      </div>
      <div class="pagination-button" *ngIf="getLastPage() - page > 3" (click)="openPage(getLastPage() - 1)">
        {{getLastPage()}}
      </div>

      <!-- productPage == getLastPage() - 1  ( -1 bc we start with 0)-->
      <div class="pagination-button arrow-pagination"
           [ngClass]="{'not-cliccable-pagination': page == getLastPage() - 1}" (click)="openPage(page + 1)">
        <mat-icon>arrow_right</mat-icon>
      </div>
    </div>
  </div>
</div>
